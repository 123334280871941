import {
  formatDate,
  omit,
  parseDate,
  pick,
} from "@adaptive/design-system/utils";
import type { Option } from "@shared/types/objects";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import type { EnhancedOption, ValuesFilters } from "./types";

const toSnakeCase = (str: string) => str.split(" ").join("_").toLowerCase();

type Value = string | number | undefined | boolean | null;

export type QueryItem = { dataIndex: string; value?: Value };

export type Selections = Record<string, Value | Required<Option>>;

export type QuerySets = Record<string, Set<Value> | Value>;

export type Formatter<T extends QuerySets | QueryItem[]> = (
  realmId: number | null,
  selections: ValuesFilters
) => T;

const enhanceFilterKey = (key?: string) =>
  key === "job" ? "customer" : key === "Job" ? "Customer" : (key ?? "");

const getPrimitiveKeys = (values: ValuesFilters) =>
  Object.entries(values).reduce(
    (acc, [key, value]) =>
      typeof value === "object" ? acc : [...acc, enhanceFilterKey(key)],
    [] as string[]
  );

const pickPrimitiveValues = (values: ValuesFilters) =>
  pick(values, getPrimitiveKeys(values)) as Record<string, Value>;

export const pickOptionValues = (values: ValuesFilters) =>
  omit(values, getPrimitiveKeys(values)) as Record<string, EnhancedOption>;

const getQuerySets = () =>
  ["vendor", "cost_code", "account", "job"].reduce(
    (sets, key) => ({
      ...sets,
      [enhanceFilterKey(key)]: new Set<Value>(),
    }),
    {
      date_after: "",
      date_before: "",
    }
  ) as unknown as QuerySets;

export const setFormatter: Formatter<QuerySets> = (realm, selections) => {
  const filterSets = getQuerySets();

  Object.values(pickOptionValues(selections)).forEach(
    ({ groupLabel, key, value, label }) => {
      if (!groupLabel) return;

      const date = parseDate(value);

      if (!date || !("from" in date && "to" in date)) {
        const enhancedValue = parseRefinementIdFromUrl(value) || value;
        const enhancedKey = toSnakeCase(enhanceFilterKey(key ?? groupLabel));

        if (filterSets[enhancedKey] instanceof Set) {
          (filterSets[enhancedKey] as Set<Value>).add(enhancedValue);
        } else {
          filterSets[enhancedKey] = new Set<Value>([enhancedValue]);
        }
      } else {
        /**
         * To be able to allow filtering by date, we need to use `label` as
         * key because on Filter component, we use `label` as the key for
         * the date picker.
         */
        filterSets[`${label}_after`] = formatDate(date.from, "yyyy-MM-dd");
        filterSets[`${label}_before`] = formatDate(date.to, "yyyy-MM-dd");
      }
    }
  );

  Object.entries(pickPrimitiveValues(selections)).forEach(([key, value]) => {
    filterSets[enhanceFilterKey(key)] = value;
  });

  filterSets.realm = realm || "";

  return filterSets;
};

export const getArrayFormatter: Formatter<QueryItem[]> = (
  realmId,
  selections
) => {
  const tx: QueryItem[] = [];

  Object.values(pickOptionValues(selections)).forEach(
    ({ groupLabel, value, label }) => {
      const date = parseDate(value);

      if (!date || !("from" in date && "to" in date)) {
        return tx.push({
          dataIndex: toSnakeCase(enhanceFilterKey(groupLabel)),
          value: parseRefinementIdFromUrl(value) || value,
        });
      }

      /**
       * To be able to allow filtering by date, we need to use `label` as
       * key because on Filter component, we use `label` as the key for
       * the date picker.
       */
      tx.push({
        dataIndex: `${label}_after`,
        value: formatDate(date.from, "yyyy-MM-dd"),
      });
      tx.push({
        dataIndex: `${label}_before`,
        value: formatDate(date.to, "yyyy-MM-dd"),
      });
    }
  );

  Object.entries(pickPrimitiveValues(selections)).forEach(([key, value]) => {
    tx.push({ dataIndex: enhanceFilterKey(key), value });
  });

  //TODO this realm injection at the thunk level
  tx.splice(0, 0, {
    dataIndex: "realm",
    value: realmId,
  });

  return tx;
};

export const defaultArrayFormatter = getArrayFormatter;
