export const isOverflowing = (el?: HTMLElement | null) => {
  if (!el) return false;

  const currentOverflow = el.style.overflow;

  if (!currentOverflow || currentOverflow === "visible") {
    el.style.overflow = "hidden";
  }

  el.style.overflow = currentOverflow;

  const { width, height } = el.getBoundingClientRect();

  /**
   * We round up because `scrollWidth/scrollHeight` returns rounded up number
   * and `scrollWidth` returns rounded down number, to avoid this issue
   * and calculate it correctly we use `getBoundingClientRect` instead
   * since it return precise number and them we round it up to be consistent
   * with `scrollWidth/scrollHeight` number
   */
  return (
    Math.ceil(width) < el.scrollWidth || Math.ceil(height) < el.scrollHeight
  );
};
