import React, { type ReactNode } from "react";
import { Flex, Loader, Tag } from "@adaptive/design-system";

export type CounterLabelProps = {
  label: ReactNode;
  active?: boolean;
  counter: number;
  loading?: boolean;
};

export const CounterLabel = ({
  label,
  active,
  counter,
  loading,
}: CounterLabelProps) => (
  <Flex gap="md" height="2xl" align="center">
    {label}{" "}
    <Tag size="sm" color={active ? "success" : "neutral"}>
      <Flex width="full" height="2xl" justify="center" minWidth="14px">
        {loading ? <Loader size="sm" /> : counter}
      </Flex>
    </Tag>
  </Flex>
);
