import React, { type ReactNode, useMemo, useState } from "react";
import {
  type ColumnOrderState,
  type ColumnPinningState,
  type VisibilityState,
} from "@tanstack/react-table";

import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";

import { TableContext, type Value } from "./table-context";
import {
  type Column,
  type SetColumnsHandler,
  type SetOrderHandler,
  type SetStickyHandler,
  type SetVisibilityHandler,
} from "./types";

type TableProviderProps = { id: string; children: ReactNode };

export const TableProvider = ({ id, children }: TableProviderProps) => {
  const [sticky, setSticky] = useState<Record<string, ColumnPinningState>>({});

  const [stickyEnabled, setStickyEnabled] = useState<Record<string, boolean>>(
    {}
  );

  const [order, setOrder] = useState<
    Record<string, ColumnOrderState | undefined>
  >({});

  const [, setDescendantsIds] = useState<string[]>([]);

  const [columns, setColumns] = useState<Record<string, Column<any>[]>>({});

  const [visibility, setVisibility] = useState<
    Record<string, VisibilityState | undefined>
  >({});

  const enhancedSetSticky = useEvent<SetStickyHandler>((nextSticky) =>
    setSticky((previousSticky) => ({
      ...previousSticky,
      [id]: is.function(nextSticky)
        ? nextSticky(previousSticky[id])
        : nextSticky,
    }))
  );

  const enhancedSetStickyEnabled = useEvent((enabled: boolean) =>
    setStickyEnabled((previousStickyEnabled) => ({
      ...previousStickyEnabled,
      [id]: enabled,
    }))
  );

  const enhancedSetOrder = useEvent<SetOrderHandler>((nextOrder) =>
    setOrder((previousOrder) => ({
      ...previousOrder,
      [id]: is.function(nextOrder) ? nextOrder(previousOrder[id]) : nextOrder,
    }))
  );

  const enhancedSetColumns = useEvent<SetColumnsHandler>((nextColumns) =>
    setColumns((previousColumns) => ({
      ...previousColumns,
      [id]: is.function(nextColumns)
        ? nextColumns(previousColumns[id])
        : nextColumns,
    }))
  );

  const enhancedSetVisibility = useEvent<SetVisibilityHandler>(
    (nextVisibility) =>
      setVisibility((previousVisibility) => ({
        ...previousVisibility,
        [id]: is.function(nextVisibility)
          ? nextVisibility(previousVisibility[id])
          : nextVisibility,
      }))
  );

  const enhancedSetDescendantsIds = useEvent(
    (cb: (descendantsIds: string[]) => string[]) =>
      setDescendantsIds((previousDescendantsIds) => {
        const nextDescendantsIds = cb(previousDescendantsIds);

        if (nextDescendantsIds.length > 1) {
          // eslint-disable-next-line no-console
          console.warn(
            `TableProvider: ${id} has more than one descendant table. This could cause some unexpected side effects.`
          );
        }

        return nextDescendantsIds;
      })
  );

  const value = useMemo<Value>(
    () => ({
      id,
      order: order[id],
      sticky: sticky[id],
      columns: columns[id],
      setOrder: enhancedSetOrder,
      setSticky: enhancedSetSticky,
      visibility: visibility[id],
      setColumns: enhancedSetColumns,
      stickyEnabled: stickyEnabled[id],
      setVisibility: enhancedSetVisibility,
      setStickyEnabled: enhancedSetStickyEnabled,
      setDescendantsIds: enhancedSetDescendantsIds,
    }),
    [
      id,
      order,
      sticky,
      columns,
      visibility,
      stickyEnabled,
      enhancedSetOrder,
      enhancedSetSticky,
      enhancedSetColumns,
      enhancedSetVisibility,
      enhancedSetStickyEnabled,
      enhancedSetDescendantsIds,
    ]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};
