import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  useEffect,
} from "react";
import cn from "clsx";

import { Button } from "../button";
import { Icon } from "../icon";

import { useDialogContext } from "./dialog-context";
import styles from "./dialog.module.css";

type DefaultComponent = "header";

type Props = ComponentPropsWithoutRef<DefaultComponent>;

export const DialogHeader = forwardRef<ComponentRef<DefaultComponent>, Props>(
  ({ children, className, ...props }, ref) => {
    const { onBack, variant, onClose, setHasHeader, hasContent, hasTabs } =
      useDialogContext();

    useEffect(() => {
      setHasHeader?.(true);

      return () => {
        setHasHeader?.(false);
      };
    }, [setHasHeader]);

    return (
      <>
        {onClose && !["drawer", "multi-step-drawer"].includes(variant!) && (
          <Button
            color="neutral"
            variant="text"
            onClick={() => onClose?.("close")}
            className={styles["close"]}
            aria-label="Close dialog"
            data-skip-focusable=""
          >
            <Icon name="xmark" />
          </Button>
        )}
        <header
          ref={ref}
          className={cn(className, styles["dialog-header"], {
            [styles["-has-tabs"]]: hasTabs,
            [styles["-has-content"]]: hasContent,
          })}
          {...props}
        >
          <div className={styles["content"]}>
            {onBack && (
              <Button
                size="sm"
                color="neutral"
                variant="text"
                onClick={onBack}
                aria-label="Back"
              >
                <Icon size="md" name="arrow-left" />
              </Button>
            )}
            <div>{children}</div>
          </div>
        </header>
      </>
    );
  }
);

DialogHeader.displayName = "DialogHeader";
