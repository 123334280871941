import React, { useEffect, useState } from "react";
import { Alert, AlertContent, Flex, TextField } from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { login } from "@api/auth";
import { z } from "zod";

export type LoginFormInfo = {
  email: string;
  isValid: boolean;
  password: string;
  hasError: boolean;
  isSubmitting: boolean;
};

export type LoginFormProps = {
  id: string;
  onChange?: (info: LoginFormInfo) => void;
  onSuccess?: () => void;
};

const schema = z.object({
  email: z.string().email("An email address is required"),
  password: z.string().min(1, "Your password is required"),
});

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = { email: "", password: "" };

export const LoginForm = ({ id, onChange, onSuccess }: LoginFormProps) => {
  const [hasError, setHasError] = useState(false);

  const form = useForm<Fields>({
    id,
    schema,
    async onSubmit({ email, ...values }) {
      try {
        await login({ username: email, ...values });
        onSuccess?.();
      } catch (e) {
        setHasError(true);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  useEffect(() => {
    setHasError(false);
  }, [form.values]);

  useEffect(() => {
    onChange?.({
      email: form.values.email,
      isValid: form.isValid,
      password: form.values.password,
      hasError,
      isSubmitting: form.isSubmitting,
    });
  }, [
    hasError,
    onChange,
    form.isValid,
    form.isSubmitting,
    form.values.email,
    form.values.password,
  ]);

  return (
    <Flex as="form" direction="column" {...form.props}>
      <TextField
        type="email"
        label="Email"
        required
        autoFocus
        placeholder="Enter your email"
        {...form.register("email")}
        data-testid="username"
      />
      <TextField
        type="password"
        label="Password"
        required
        placeholder="Enter your password"
        {...form.register("password")}
      />
      {hasError && (
        <Alert variant="error">
          <AlertContent>Invalid email or password</AlertContent>
        </Alert>
      )}
    </Flex>
  );
};
