import React, { useEffect } from "react";
import {
  Button,
  ComboBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  TextField,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  type UseDialogReturn,
  useForm,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { z } from "zod";

import { type Account } from "../../../shared/api/accounts";
import { createAccount } from "../../../shared/api/accounts";
import { useClientInfo } from "../../../shared/store/user";

import { type Step } from "./types";

type Props = {
  dialog: UseMultiStepDialogReturn<Step> | UseDialogReturn;
  isCreditCard?: boolean;
  isBankAccount?: boolean;
  accountQuery?: string;
  onCreatedAccountChange?: (account: Account) => void;
  variant?: "default" | "single";
  forceAccountType?: string | null;
  initialAccountType?: string | null;
};

type Fields = z.infer<typeof schema>;

const schema = z.object({
  display_name: z.string().min(1, "Account name is required"),
  nominal_code: z.string(),
  fully_qualified_category: z.string().min(1, "Account category is required"),
});

const CATEGORY_DATA = [
  { label: "Checking", value: "Asset.Bank.Checking" },
  { label: "Savings", value: "Asset.Bank.Savings" },
  { label: "Cash On Hand", value: "Asset.Bank.CashOnHand" },
  { label: "Money Market", value: "Asset.Bank.MoneyMarket" },
  { label: "Credit Card", value: "Liability.CreditCard.CreditCard" },
];

export const AccountCreateForm = ({
  dialog,
  isCreditCard,
  isBankAccount,
  accountQuery,
  onCreatedAccountChange,
  variant,
  forceAccountType,
  initialAccountType,
}: Props) => {
  const { realm } = useClientInfo();

  const initialValues = useDeepMemo(
    () => ({
      display_name: accountQuery || "",
      nominal_code: "",
      fully_qualified_category: forceAccountType || initialAccountType || "",
    }),
    [accountQuery, forceAccountType, initialAccountType]
  );

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      if (realm?.url) {
        try {
          const account = await createAccount({
            realm: realm.url,
            is_credit_card: isCreditCard,
            is_bank_account: isBankAccount,
            ...values,
          });
          if (onCreatedAccountChange) onCreatedAccountChange(account);
          if (variant === "single") {
            dialog.hide();
          } else {
            (dialog as UseMultiStepDialogReturn<Step>).setStep("set-account");
          }
          toast.success("Account created!");
        } catch (error) {
          handleErrors(error);
        }
      } else {
        toast.error("Client is not set");
      }
    },
    initialValues,
  });

  useEffect(() => {
    reset();
  }, [accountQuery, reset]);

  return (
    <>
      <DialogHeader>
        Create a new account in your accounting software
      </DialogHeader>
      <DialogContent>
        <Flex direction="column" gap="sm" as="form" {...form.props}>
          <TextField
            label="Account name"
            required
            {...form.register("display_name")}
          />
          <TextField label="Account code" {...form.register("nominal_code")} />
          <ComboBox
            label="Account type"
            required
            disabled={!!forceAccountType}
            placeholder="Select an account type"
            data={CATEGORY_DATA}
            {...form.register("fully_qualified_category")}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          color="neutral"
          variant="text"
          onClick={
            variant === "single"
              ? dialog.hide
              : (dialog as UseMultiStepDialogReturn<Step>).back
          }
        >
          {variant === "single" ? "Cancel" : "Back"}
        </Button>
        <Button block type="submit" form={form.id} disabled={form.isSubmitting}>
          Create
        </Button>
      </DialogFooter>
    </>
  );
};
