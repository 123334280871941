import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { useRifm } from "rifm";

import { useEvent } from "../../hooks/use-event";
import { formatCurrency } from "../../utils/format-currency";
import { formatNumber } from "../../utils/format-number";
import { mergeRefs } from "../../utils/merge-refs";
import { setSelectionRange } from "../../utils/set-selection-range";
import { Icon } from "../icon";
import { TextField } from "../text-field";

type Ref = ComponentRef<typeof TextField>;

export type CurrencyFieldProps = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  | "prefix"
  | "suffix"
  | "addonAfter"
  | "addonBefore"
  | "onChange"
  | "value"
  | "changeMode"
> & {
  value?: number;
  onChange?: (value: number) => void;
  allowNegative?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export const CurrencyField = forwardRef<Ref, CurrencyFieldProps>(
  (
    {
      size,
      value,
      onBlur,
      onInput,
      onChange,
      allowNegative,
      minimumFractionDigits = 2,
      maximumFractionDigits = 2,
      triggerChangeOnFocusedUnmount = true,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<Ref>(null);
    const [, startTransition] = useTransition();

    const [maskedValue, setMaskedValue] = useState("");

    const enhancedOnChange = useEvent((value: string) =>
      setMaskedValue(formatNumber(value, allowNegative))
    );

    const dispatchChange = useEvent(() => {
      const formattedValue =
        value !== undefined
          ? formatCurrency(value, {
              allowNegative,
              maximumFractionDigits,
              minimumFractionDigits,
            })
          : undefined;

      const formattedMaskedValue = formatCurrency(maskedValue, {
        allowNegative,
        maximumFractionDigits,
        minimumFractionDigits,
      });

      const nextValue = Number(
        formatNumber(formattedMaskedValue, allowNegative)
      );

      onChange?.(nextValue);

      return formattedValue ?? formattedMaskedValue;
    });

    const enhancedOnBlur = useEvent<FocusEventHandler<Ref>>((e) => {
      const nextMaskedValue = dispatchChange();

      onBlur?.(e);

      /**
       * We use startTransition to prevent value
       * flicker when component is controlled.
       */
      startTransition(() =>
        setMaskedValue(nextMaskedValue === "0.00" ? "" : nextMaskedValue)
      );
    });

    const enhancedOnInput = useEvent<FocusEventHandler<Ref>>((e) => {
      rifm.onChange(e);
      onInput?.(e);
    });

    const acceptRegex = allowNegative ? /[\d.-]/g : /[\d.]/g;
    const rifm = useRifm({
      value: maskedValue,
      format: (str) =>
        str === "." || (str === "-" && allowNegative)
          ? str
          : formatCurrency(str, {
              minimumFractionDigits: 0,
              maximumFractionDigits,
              allowNegative,
            }),
      accept: acceptRegex,
      replace: (str) => {
        if (str !== ".") return str;

        setSelectionRange(internalRef.current, 2, 2);

        return "0.";
      },
      onChange: enhancedOnChange,
    });

    useEffect(() => {
      const nextMaskedValue = !value
        ? ""
        : formatCurrency(value, { minimumFractionDigits, allowNegative });

      setMaskedValue(nextMaskedValue === "0.00" ? "" : nextMaskedValue);
    }, [value, allowNegative, minimumFractionDigits]);

    useLayoutEffect(() => {
      const el = internalRef.current;

      return () => {
        if (!el || !triggerChangeOnFocusedUnmount) return;

        if (document.activeElement === el) dispatchChange();
      };
    }, [dispatchChange, triggerChangeOnFocusedUnmount]);

    return (
      <TextField
        ref={mergeRefs(ref, internalRef)}
        size={size}
        value={rifm.value}
        onBlur={enhancedOnBlur}
        onInput={enhancedOnInput}
        inputMode="decimal"
        addonBefore={<Icon size={size} name="dollar-sign" />}
        triggerChangeOnFocusedUnmount={false}
        {...props}
      />
    );
  }
);

CurrencyField.displayName = "CurrencyField";
