import type { UnknownAsyncThunkAction } from "@reduxjs/toolkit/dist/matchers";
import type { AppDispatch } from "@store/types";

type ThunkActionCollection = (
  | UnknownAsyncThunkAction
  | UnknownAsyncThunkAction[]
)[];

export const dispatchedThunkActions = async function* (
  dispatch: AppDispatch,
  actions: ThunkActionCollection
): AsyncGenerator<UnknownAsyncThunkAction, void> {
  for await (const action of actions) {
    Array.isArray(action)
      ? yield* dispatchedThunkActions(dispatch, action)
      : yield dispatch(action);
  }
};

type ThunkCollation = {
  fulfilled: UnknownAsyncThunkAction[];
  pending: UnknownAsyncThunkAction[];
  rejected: UnknownAsyncThunkAction[];
};

export const dispatchAndCollate = async (
  dispatch: AppDispatch,
  actions: ThunkActionCollection
) => {
  const result: ThunkCollation = { fulfilled: [], pending: [], rejected: [] };
  for await (const thunk of dispatchedThunkActions(dispatch, actions)) {
    const key = thunk.meta.requestStatus;
    result[key].push(thunk);
  }
  return result;
};
