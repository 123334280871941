import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@src/shared/utils/schema/converters";
import { api as apiSimplified } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";

import {
  changeCreatePayloadSchema,
  changesGetPayloadSchema,
  changeUpdateSchema,
  getChangePayloadSchema,
} from "./request";
import { getChangeResponseSchema, getChangesResponseSchema } from "./response";
import type {
  ChangeCreatePayload,
  ChangesGetPayload,
  ChangeUpdatePayload,
  GetChangePayload,
  GetChangeResponse,
  GetChangesResponse,
} from "./types";

export const jobsSimplifiedApi = apiSimplified.injectEndpoints({
  endpoints: (builder) => ({
    createChange: builder.mutation<void, ChangeCreatePayload>({
      query: (body) => {
        const payload = handleRequest(body, changeCreatePayloadSchema);

        return {
          url: `customers/${payload.customerId}/changes/`,
          method: "POST",
          body: transformKeysToSnakeCase(payload),
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error
          ? [{ type: "Changes", customerId }, "BudgetLines", "CustomerMarkup"]
          : [],
    }),
    updateChange: builder.mutation<void, ChangeUpdatePayload>({
      query: (body) => {
        const { changeId, customerId, ...payload } = handleRequest(
          body,
          changeUpdateSchema
        );
        return {
          url: `customers/${customerId}/changes/${String(changeId)}/`,
          method: "PUT",
          body: transformKeysToSnakeCase(payload),
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error
          ? [{ type: "Changes", customerId }, "BudgetLines", "CustomerMarkup"]
          : [],
    }),
    deleteChange: builder.mutation<void, GetChangePayload>({
      query: (body) => {
        const payload = handleRequest(body, getChangePayloadSchema);

        return {
          url: `customers/${payload.customerId}/changes/${payload.changeId}/`,
          method: "DELETE",
          body: transformKeysToSnakeCase(payload),
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error
          ? [{ type: "Changes", customerId }, "BudgetLines", "CustomerMarkup"]
          : [],
    }),
    getChanges: builder.query<GetChangesResponse, ChangesGetPayload>({
      query: (args) => {
        const { customerId, ...params } = handleRequest(
          args,
          changesGetPayloadSchema
        );
        return {
          url: `customers/${customerId}/changes/`,
          params: transformKeysToSnakeCase(params),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getChangesResponseSchema
            );
          },
        };
      },
      providesTags: (response, _, { customerId }) =>
        response && response.results
          ? [
              "Changes",
              ...response.results.map(({ id }) => ({
                type: "Change" as const,
                id,
              })),
            ]
          : [{ type: "Changes", customerId }],
    }),
    getChange: builder.query<GetChangeResponse, GetChangePayload>({
      query: (args) => {
        const { customerId, changeId } = handleRequest(
          args,
          getChangePayloadSchema
        );
        return {
          url: `customers/${customerId}/changes/${changeId}/`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getChangeResponseSchema
            );
          },
        };
      },
      providesTags: (_, __, { changeId }) => [{ type: "Change", id: changeId }],
    }),
  }),
});

export const {
  useCreateChangeMutation,
  useGetChangeQuery,
  useGetChangesQuery,
  useLazyGetChangesQuery,
  useUpdateChangeMutation,
  useDeleteChangeMutation,
} = jobsSimplifiedApi;
