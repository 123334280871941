import { api } from "../utils/api";

export type NotificationSettingsPayload = {
  type: string;
  channel: string;
  enabled: boolean;
}[];

export const updateNotificationSettings = (
  payload: NotificationSettingsPayload
) => api.put("/api/notification/settings/", payload);
