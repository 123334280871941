type GenerateUrlByHumanReadableTypeParams = {
  id: string | number;
  humanReadableType:
    | "Bill"
    | "Expense"
    | "Receipt"
    | "Vendor Credit"
    | "Labor"
    | "Invoice"
    | "Purchase Order"
    | (string & {}); // eslint-disable-line @typescript-eslint/ban-types
};

export const generateUrlByHumanReadableType = ({
  id,
  humanReadableType,
}: GenerateUrlByHumanReadableTypeParams) => {
  if (["Bill", "Vendor Credit"].includes(humanReadableType)) {
    return `/bills/${id}`;
  }

  if (["Expense", "Receipt", "Labor"].includes(humanReadableType)) {
    return `/expenses/${id}`;
  }

  if (["Invoice"].includes(humanReadableType)) {
    return `/invoices/${id}`;
  }

  if (["Purchase Order"].includes(humanReadableType)) {
    return `/purchase-orders/${id}`;
  }
};
