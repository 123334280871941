import React, { useCallback, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Badge,
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  Flex,
  Icon,
  Link,
  Loader,
  Pagination,
  Switch,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Tag,
  Text,
  type TextProps,
  Timeline,
  type TimelineData,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import {
  useEvent,
  useLocalStorageState,
  usePagination,
} from "@adaptive/design-system/hooks";
import {
  formatDate,
  parseMention,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import * as analytics from "@utils/analytics";
import { generateUrlByHumanReadableType } from "@utils/generate-url-by-human-readable-type";

import {
  useUpdateNotificationMutation,
  useUpdateNotificationsMutation,
} from "../api/api";
import type { Notification } from "../api/types";
import {
  ALL_TAB_VALUE,
  MENTIONS_TAB_VALUE,
  NOTIFICATION_TAB_STORAGE_KEY,
  NOTIFICATION_TITLE,
  NOTIFICATION_TYPE,
  STRINGS,
  UNREAD_FILTER,
} from "../constants/constants";
import { useNotifications } from "../hooks/use-notifications";
import { useNotificationsDrawer } from "../hooks/use-notifications-drawer";

type CurriedMarkAsReadHandler = (
  notification: Notification
) => () => Promise<void>;
type CurriedOpenNotificationHandler = (props: {
  notification: Notification;
  referenceTo: string;
}) => () => Promise<void>;

export const List = () => {
  const { hide } = useNotificationsDrawer();

  const [unreadFilter, setUnreadFilter] = useState(UNREAD_FILTER.UNREAD_ONLY);

  const pagination = usePagination();

  const sharedQuery = useMemo(() => {
    if (unreadFilter === UNREAD_FILTER.UNREAD_ONLY) {
      return [{ dataIndex: "read", value: "false" }];
    }
    return [];
  }, [unreadFilter]);

  const mentionsQuery = useMemo(() => {
    return [
      ...sharedQuery,
      {
        dataIndex: "notification_type",
        value: NOTIFICATION_TYPE.COMMENT_MENTIONED,
      },
    ];
  }, [sharedQuery]);

  const all = useNotifications({ query: sharedQuery, pagination });

  const mentions = useNotifications({ query: mentionsQuery, pagination });

  const [tab, setTab] = useLocalStorageState(
    NOTIFICATION_TAB_STORAGE_KEY,
    ALL_TAB_VALUE
  );

  const currentInfo = tab === ALL_TAB_VALUE ? all : mentions;

  const [updateNotificationMutation, updateNotificationMutationInfo] =
    useUpdateNotificationMutation();
  const curriedMarkAsRead = useCallback<CurriedMarkAsReadHandler>(
    ({ id, read, referencedObject }: Notification) =>
      async () => {
        analytics.track("notificationsMarkAsRead", {
          trigger: "button",
          notificationId: id,
          referenceObject: referencedObject,
        });
        try {
          await updateNotificationMutation({ id, read: !read }).unwrap();
        } catch (e) {
          handleErrors(e);
        }
      },
    [updateNotificationMutation]
  );

  const [updateNotificationsMutation, updateNotificationsMutationInfo] =
    useUpdateNotificationsMutation();
  const markAllAsRead = async () => {
    analytics.track("notificationsMarkAllAsRead", {
      trigger: "button",
    });
    try {
      await updateNotificationsMutation({ read: true }).unwrap();
    } catch (e) {
      handleErrors(e);
    }
  };

  const curriedOpenNotification = useCallback<CurriedOpenNotificationHandler>(
    (prop: { notification: Notification; referenceTo: string }) => async () => {
      const { id, referencedObject, read } = prop.notification;
      analytics.track("notificationsOpenDetails", {
        to: prop.referenceTo,
        trigger: "button",
        referenceObject: referencedObject,
      });
      hide();
      try {
        await updateNotificationMutation({ id, read: !read }).unwrap();
      } catch (e) {
        handleErrors(e);
      }
    },
    [updateNotificationMutation, hide]
  );

  const onChangeUnreadFilter = useEvent((value: boolean) => {
    analytics.track("notificationsSwitchUnreadFilter", {
      trigger: "button",
      unreadFilter: value,
    });
    setUnreadFilter(value);
  });

  const data = useMemo(
    () =>
      currentInfo.data.map((item) => {
        const content = parseMention({
          value: item.referencedObject.text,
          render: ({ url, mention, trigger }) => {
            let color: TextProps["color"] = "brand-2";

            if (url?.includes("/vendors/")) {
              color = "info-200";
            }

            return `<span style="color: var(--color-${color})">${trigger}${mention}</span>`;
          },
        });

        const author = item.referencedObject.author?.fullName || "Unknown";

        const { icon, template } = NOTIFICATION_TITLE[item.notificationType];

        let referenceTo = generateUrlByHumanReadableType(
          item.referencedObject.parent
        );

        if (referenceTo) {
          referenceTo += `?comment_id=${item.referencedObject.id}`;
        }

        return {
          id: item.id,
          icon,
          title: parseStringCopy(template, {
            author,
            reference: (
              <>
                {item.referencedObject.parent.docNumber
                  ? `${item.referencedObject.parent.humanReadableType} `
                  : null}
                <Wrapper
                  when={!!referenceTo}
                  render={(children) => (
                    <Link
                      as={ReactRouterLink}
                      to={referenceTo!}
                      size="sm"
                      onClick={curriedOpenNotification({
                        notification: item,
                        referenceTo: referenceTo!,
                      })}
                    >
                      {children}
                    </Link>
                  )}
                >
                  {item.referencedObject.parent.docNumber
                    ? `#${item.referencedObject.parent.docNumber}`
                    : item.referencedObject.parent.humanReadableType}
                </Wrapper>
              </>
            ),
          }),
          color: item.read ? "neutral" : "success",
          highlight: item.read,
          extra: item.referencedObject.files?.length
            ? [
                {
                  children: (
                    <Flex gap="md" wrap grow>
                      {item.referencedObject.files.map((file) => (
                        <Tag
                          as="button"
                          key={file.id}
                          type="button"
                          variant="pill"
                          onClick={() => window.open(file.file, "_blank")}
                        >
                          <Flex gap="md">
                            <Icon
                              size="sm"
                              name="file"
                              variant="light"
                              color="neutral-600"
                            />
                            <Text truncate={1}>{file.name}</Text>
                          </Flex>
                        </Tag>
                      ))}
                    </Flex>
                  ),
                  variant: "hollow",
                },
              ]
            : undefined,
          subtitle: item.createdAt ? formatDate(item.createdAt) : "",
          children: !content ? null : (
            <span dangerouslySetInnerHTML={{ __html: content }} />
          ),
          renderHeader: (children) => (
            <Flex
              grow
              justify="space-between"
              gap="md"
              align="center"
              shrink={false}
            >
              <Flex grow basis={0}>
                {children}
              </Flex>
              <Flex gap="sm">
                {item.referencedObject.hasExternalMention ? (
                  <Tooltip
                    message={
                      <Text align="center">
                        This comment thread is visible to people
                        <br />
                        external to your company.{" "}
                        <Text
                          as={Link}
                          size="sm"
                          href="https://help.adaptive.build/en/articles/9216708-sending-messages-to-vendors"
                          target="_blank"
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          Learn more
                        </Text>
                      </Text>
                    }
                  >
                    <Tag color="info">
                      <Flex gap="sm" width="78px">
                        <Icon name="paper-plane-top" size="sm" />
                        <Text>External</Text>
                      </Flex>
                    </Tag>
                  </Tooltip>
                ) : null}
              </Flex>
              <Flex>
                <Tooltip
                  message={
                    item.read
                      ? STRINGS.LIST_NOTIFICATION_MARKED_AS_UNREAD_TOOLTIP
                      : STRINGS.LIST_NOTIFICATION_MARKED_AS_READ_TOOLTIP
                  }
                >
                  <Button
                    size="sm"
                    color="neutral"
                    variant="ghost"
                    disabled={updateNotificationMutationInfo.isLoading}
                    onClick={curriedMarkAsRead(item)}
                  >
                    <Badge value={!item.read || undefined}>
                      <Icon name={item.read ? "envelope-open" : "envelope"} />
                    </Badge>
                  </Button>
                </Tooltip>
              </Flex>
              {referenceTo && (
                <Flex>
                  <Tooltip message={STRINGS.LIST_SHOW_TOOLTIP}>
                    <Button
                      as={ReactRouterLink}
                      to={referenceTo}
                      size="sm"
                      color="neutral"
                      variant="ghost"
                      onClick={curriedOpenNotification({
                        notification: item,
                        referenceTo: referenceTo!,
                      })}
                    >
                      <Icon name="arrow-up-right-from-square" />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          ),
        } as TimelineData;
      }),
    [
      currentInfo.data,
      curriedMarkAsRead,
      curriedOpenNotification,
      updateNotificationMutationInfo,
    ]
  );

  const onTabChange = useEvent((value: string) => {
    setTab(value);
    analytics.track("notificationsTabChange", { tab: value });
    pagination.setPage(0);
  });

  return (
    <Tabs value={tab} onChange={onTabChange}>
      <DialogHeader>
        <Flex justify="space-between">
          <Flex gap="sm" direction="column" align="left">
            <Text weight="bold">{STRINGS.LIST_TITLE}</Text>
            <Text size="md">{STRINGS.LIST_SUBTITLE}</Text>
          </Flex>
          <Switch
            label={STRINGS.LIST_SWITCH}
            checked={unreadFilter === UNREAD_FILTER.UNREAD_ONLY}
            placement="right"
            disabled={all.isLoading || mentions.isLoading}
            onChange={onChangeUnreadFilter}
          />
        </Flex>
        <TabsList>
          <TabsTab value={ALL_TAB_VALUE}>{STRINGS.LIST_ALL_TAB}</TabsTab>
          <TabsTab value={MENTIONS_TAB_VALUE}>
            {STRINGS.LIST_MENTIONS_TAB}
          </TabsTab>
          <Flex
            grow
            justify="flex-end"
            margin={["none", "-13px", "none", "none"]}
          >
            <Button
              size="sm"
              color="neutral"
              variant="text"
              disabled={updateNotificationsMutationInfo.isLoading}
              onClick={markAllAsRead}
            >
              {STRINGS.LIST_MARK_ALL_AS_READ_ACTION}
            </Button>
          </Flex>
        </TabsList>
      </DialogHeader>
      <DialogContent variant="unspaced">
        <TabsPanel as={Flex} minHeight="full">
          {currentInfo.isLoading || updateNotificationMutationInfo.isLoading ? (
            <Flex width="full" align="center" justify="center">
              <Loader />
            </Flex>
          ) : data.length === 0 ? (
            <Flex width="full" align="center" justify="center">
              <EmptyState
                title={STRINGS.LIST_EMPTY_STATE_TITLE}
                subtitle={STRINGS.LIST_EMPTY_STATE_SUBTITLE}
              />
            </Flex>
          ) : (
            <Timeline
              data={data}
              separator
              style={{ "--timeline-item-padding-x": "var(--dialog-gutter)" }}
            />
          )}
        </TabsPanel>
      </DialogContent>
      {data.length === 0 ? null : (
        <DialogFooter>
          <Flex justify="center" grow>
            <Pagination
              page={pagination.page}
              total={tab === ALL_TAB_VALUE ? all.count : mentions.count}
              perPage={pagination.perPage}
              onChange={pagination.setPage}
              disabled={all.isLoading || mentions.isLoading}
            />
          </Flex>
        </DialogFooter>
      )}
    </Tabs>
  );
};
