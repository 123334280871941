import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  type TableColumn,
  TableConfigurationButton,
  type TableEmptyState,
  type TableHeaderAddon,
  type TablePaginationAddon,
  TableProvider,
  type TableSelectAddon,
  type TableSortAddon,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import * as analytics from "@utils/analytics";

import type { CardTransaction } from "../api/types";
import {
  MATCH_STEP_FORM_ID,
  MATCH_STEP_TABLE_ID,
  STRINGS,
  UNMATCHED_CARD_TRANSACTION_FILTERS,
} from "../constants/constants";
import { useCardTransactions } from "../hooks/use-card-transactions";
import { useMatchCardTransaction } from "../hooks/use-match-card-transaction";
import { getConflicts } from "../utils/get-conflicts";
import { transformCostToFilters } from "../utils/transform-cost-to-filters";

import { Filter, LinkedCard } from "./misc";
import type { LinkedCost, MatchingTransactionOnChangeHandler } from "./types";

const COLUMNS: TableColumn<CardTransaction>[] = [
  {
    id: "vendor_merchant_name",
    name: "Vendor description",
    sortable: "asc",
    render: ({ name, vendor }) => (
      <Text truncate>{vendor?.displayName || name}</Text>
    ),
    minWidth: 240,
  },
  {
    id: "transaction_date",
    width: 140,
    sortable: true,
    name: "Date",
    render: ({ date }) => (date ? formatDate(date) : null),
  },
  {
    id: "linked_card",
    width: 155,
    name: "Linked card",
    sortable: "asc",
    render: (row) => <LinkedCard {...row} />,
  },
  {
    id: "payment_account",
    name: "Payment account",
    visibility: "hidden",
    sortable: "asc",
    width: 180,
    render: (row) =>
      row.paymentAccount?.displayName ? (
        <Flex>
          <Tag>{row.paymentAccount.displayName}</Tag>
        </Flex>
      ) : null,
  },
  {
    id: "user",
    name: "Card holder",
    sortable: "asc",
    minWidth: 155,
    render: ({ user }) => (user?.fullName ? <Tag>{user?.fullName}</Tag> : null),
  },
  {
    id: "amount",
    sortable: true,
    name: "Amount",
    width: 145,
    textAlign: "right",
    render: ({ amount }) =>
      formatCurrency(amount || 0, {
        allowNegative: true,
        currencySign: true,
      }),
  },
];

export type MatchingTransactionDialogSelectCardTransactionStepProps = {
  cost: LinkedCost;
  onError: (payload: {
    conflicts: ReturnType<typeof getConflicts>;
    cardTransaction: CardTransaction;
  }) => void;
  onCancel: () => void;
  onSuccess: MatchingTransactionOnChangeHandler;
};

export const MatchingTransactionDialogSelectCardTransactionStep = ({
  cost,
  onError,
  onCancel,
  onSuccess,
}: MatchingTransactionDialogSelectCardTransactionStepProps) => {
  const [enabled, setEnabled] = useState(false);

  const {
    data,
    page,
    count,
    order,
    setPage,
    perPage,
    setOrder,
    isLoading,
    setFilters,
    rawFilters,
    setPerPage,
    clearFilters,
  } = useCardTransactions({
    enabled,
    query: UNMATCHED_CARD_TRANSACTION_FILTERS,
  });

  const hasTagFilters = Object.keys(rawFilters).some(
    (key) => !key.includes("date_")
  );

  const [cardTransaction, setCardTransaction] = useState<
    CardTransaction | undefined
  >();

  const [matchCardTransactionTrigger, matchCardTransactionInfo] =
    useMatchCardTransaction();

  const hasData = !!data.length;

  const sort = useMemo<TableSortAddon>(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData }),
    [hasData]
  );

  const pagination = useMemo<TablePaginationAddon>(
    () => ({
      page,
      total: count,
      perPage,
      onChange: (page) => setPage(page),
      onPerPageChange: (perPage) => {
        setPage(0);
        setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: MATCH_STEP_TABLE_ID,
          limit: perPage,
        });
      },
    }),
    [count, page, perPage, setPerPage, setPage]
  );

  const cardTransactionsTableSelect = useMemo<
    TableSelectAddon<CardTransaction>
  >(
    () => ({
      value: cardTransaction,
      multiple: false,
      onChange: setCardTransaction,
    }),
    [cardTransaction]
  );

  const emptyState = useMemo<TableEmptyState>(
    () => ({
      title:
        STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_TITLE,
      subtitle:
        STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_SUBTITLE,
      action: {
        onClick: clearFilters,
        children:
          STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_ACTION,
      },
    }),
    [clearFilters]
  );

  let date = "";

  if ("transactionDate" in cost) {
    const transactionDate = parseDate(cost.transactionDate, "yyyy-MM-dd");

    if (transactionDate) {
      date = formatDate(transactionDate);
    } else if ("trueCreatedAt" in cost) {
      const trueCreatedAt = parseDate(cost.trueCreatedAt, "iso");

      if (trueCreatedAt) {
        date = formatDate(trueCreatedAt);
      }
    }
  } else {
    date = formatDate(cost.date);
  }

  const onFilterChange = useEvent((filters) => {
    setFilters(filters);
    setPage(0);
  });

  const onSubmit = useEvent(async (e) => {
    e.preventDefault();

    if (!cardTransaction) return;

    try {
      await matchCardTransactionTrigger({
        cost: cost.url ?? cost.id,
        cardTransactionId: cardTransaction.id,
      }).unwrap();

      onSuccess({ cost, cardTransaction });
    } catch (e) {
      const conflicts = getConflicts(e);

      if (!conflicts.length) return handleErrors(e);

      onError({ conflicts, cardTransaction });
    }
  });

  useEffect(() => {
    setFilters(transformCostToFilters(cost));
    setEnabled(true);
  }, [cost, setFilters]);

  if (!enabled) return null;

  return (
    <>
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {
              STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_TITLE
            }
          </Text>
          <Text size="md">
            {date || "Unknown date"}:{" "}
            {formatCurrency(cost.totalAmount || 0, {
              currencySign: true,
              allowNegative: true,
            })}{" "}
            {cost.vendor?.displayName ?? "Unknown vendor"}
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <TableProvider id={MATCH_STEP_TABLE_ID}>
          <Flex direction="column" minWidth="940px" gap="lg" height="450px">
            <div>
              <Filter
                filters={rawFilters}
                onClear={clearFilters}
                onChange={onFilterChange}
              >
                <Flex justify="flex-end" grow>
                  <TableConfigurationButton />
                </Flex>
              </Filter>
            </div>
            <Flex as="form" id={MATCH_STEP_FORM_ID} onSubmit={onSubmit}>
              <Table
                size="sm"
                data={data}
                sort={sort}
                header={header}
                select={cardTransactionsTableSelect}
                loading={isLoading}
                columns={COLUMNS}
                maxHeight={hasTagFilters ? "350px" : "390px"}
                pagination={pagination}
                emptyState={emptyState}
              />
            </Flex>
          </Flex>
        </TableProvider>
      </DialogContent>
      <DialogFooter>
        <Flex width="full">
          <Button block color="neutral" variant="text" onClick={onCancel}>
            {
              STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_CANCEL_ACTION
            }
          </Button>
        </Flex>
        <Tooltip
          as={Flex}
          width="full"
          message={
            !cardTransaction
              ? STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_VALIDATION_ERROR
              : ""
          }
        >
          <Button
            block
            type="submit"
            form={MATCH_STEP_FORM_ID}
            disabled={matchCardTransactionInfo.isLoading || !cardTransaction}
          >
            {matchCardTransactionInfo.isLoading ? (
              <Loader />
            ) : (
              STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_MATCH_ACTION
            )}
          </Button>
        </Tooltip>
      </DialogFooter>
    </>
  );
};
