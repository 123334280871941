import React from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogStep } from "@adaptive/design-system";
import { type UseMultiStepDialogReturn } from "@adaptive/design-system/hooks";
import { userSelector } from "@store/user/slice";

import { ProfileEditDialogPasswordForm } from "./profile-edit-dialog-password-form";
import { ProfileEditDialogUserForm } from "./profile-edit-dialog-user-form";
import type { Step, User } from "./types";

type Props = {
  dialog: UseMultiStepDialogReturn<Step>;
};

export const ProfileEditDialog = ({ dialog }: Props) => {
  const user = useSelector(userSelector) as unknown as User;

  return (
    <Dialog
      step={dialog.step}
      show={dialog.isVisible}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="profile">
        {user.id && <ProfileEditDialogUserForm user={user} dialog={dialog} />}
      </DialogStep>
      <DialogStep name="password" onBack={dialog.back}>
        <ProfileEditDialogPasswordForm dialog={dialog} />
      </DialogStep>
    </Dialog>
  );
};
