import { useCallback, useState } from "react";

import { useDialog } from "../use-dialog";

type UseMultiStepDialogProps<Step> = {
  lazy?: boolean;
  initialStep: Step;
  initialIsVisible?: boolean;
};

export type UseMultiStepDialogReturn<Step> = ReturnType<typeof useDialog> & {
  step: Step;
  back: () => void;
  setStep: (nextStep: Step) => void;
};

export const useMultiStepDialog = <Step>({
  lazy,
  initialStep,
  initialIsVisible,
}: UseMultiStepDialogProps<Step>) => {
  const [stack, setStack] = useState<Step[]>([]);
  const [step, setStep] = useState(initialStep);
  const { show, ...dialog } = useDialog({ lazy, initialIsVisible });

  const enhancedShow = useCallback(() => {
    setStep(initialStep);
    setStack([]);
    return show();
  }, [show, initialStep]);

  const enhancedSetStep = useCallback(
    (nextStep: Step) => {
      setStack((stack) => [...stack, step]);
      setStep(nextStep);
    },
    [step]
  );

  const back = useCallback(() => {
    setStack((stack) => stack.slice(0, -1));
    setStep(stack[stack.length - 1]);
  }, [stack]);

  return {
    ...dialog,
    show: enhancedShow,
    step,
    back,
    setStep: enhancedSetStep,
  };
};
