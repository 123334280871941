import React, { memo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  toast,
} from "@adaptive/design-system";
import { useDialog, useForm } from "@adaptive/design-system/hooks";
import { Comment } from "@components/comments/comment";
import { z } from "zod";

import { useInvoiceActions } from "./invoice-context";

const schema = z.object({ comment: z.string().max(1000) });

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = { comment: "" };

type Props = { disabled?: boolean };

export const InvoiceRejectButton = memo(({ disabled = false }: Props) => {
  const dialog = useDialog({ lazy: true });

  const { evaluateInvoice } = useInvoiceActions();

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await evaluateInvoice({ status: "REJECTED", ...values });
      toast.success("Invoice rejected");
      dialog.hide();
    },
    initialValues: INITIAL_VALUES,
  });

  return (
    <>
      <Button
        size="lg"
        variant="ghost"
        color="error"
        onClick={dialog.show}
        disabled={disabled}
      >
        Reject
      </Button>

      {dialog.isRendered && (
        <Dialog
          variant="dialog"
          show={dialog.isVisible}
          size="sm"
          onClose={dialog.hide}
        >
          <DialogHeader>Reject invoice</DialogHeader>
          <DialogContent>
            <Flex as="form" {...form.props} direction="column">
              <Comment
                label="Reason for rejecting the invoice"
                minHeight={96}
                messageVariant="absolute"
                {...form.register("comment")}
              />
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button block variant="text" color="neutral" onClick={dialog.hide}>
              Cancel
            </Button>
            <Button
              block
              color="error"
              form={form.id}
              type="submit"
              disabled={form.isSubmitting || !form.isValid}
            >
              Reject
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
});

InvoiceRejectButton.displayName = "InvoiceRejectButton";
