import { useCallback } from "react";
import { toast } from "@adaptive/design-system";

import { UNKNOWN_ERROR_MESSAGE } from "../api/handle-errors";
import { changePassword, type ChangePasswordPayload } from "../api/users";

type MutateHandler = (payload: ChangePasswordPayload) => Promise<void>;

export const useChangePasswordMutation = () => {
  const mutate = useCallback<MutateHandler>(async (payload) => {
    try {
      await changePassword(payload);
      window.location.reload();
    } catch (e: any) {
      const errors = e?.response?.data || {};
      const error = Object.keys(errors).length
        ? errors?.[Object.keys(errors)[0]]?.[0]
        : UNKNOWN_ERROR_MESSAGE;
      toast.error(error);

      throw e;
    }
  }, []);

  return { mutate };
};
