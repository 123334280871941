import { useMemo } from "react";

import { useResponsiveProvider } from "../../components/responsive-provider";
import { is } from "../../utils/is";

export type ResponsiveProp<T> = T | Record<string, T>;

export function useResponsiveProp<T>(
  prop: ResponsiveProp<T>,
  fallback?: undefined
): T | undefined;

export function useResponsiveProp<T>(
  prop: ResponsiveProp<T>,
  fallback: T
): Exclude<T, undefined>;

/**
 * @name useResponsiveProp
 * @description
 * Hook to get the responsive value of a prop based on the current breakpoint. As the app uses
 * desktop-first design, the matched breakpoint will be the current or below
 *
 * @example
 * // Fixed
 * const title = useResponsiveProp("Title");
 * // Responsive
 * const title = useResponsiveProp({ mobile: "Mobile Title", desktop: "Desktop Title" });
 */
export function useResponsiveProp<T>(prop: ResponsiveProp<T>, fallback?: T) {
  const { current, matches } = useResponsiveProvider();

  const responsiveProp = useMemo(() => {
    if (!is.object(prop)) return prop ?? fallback;

    const lastMatchedBreakpoint = [...matches]
      .reverse()
      .find((breakpoint) => breakpoint in prop);

    const breakpoint = current in prop ? current : lastMatchedBreakpoint;

    if (!breakpoint) {
      return fallback;
    }

    return prop[breakpoint] ?? fallback;
  }, [current, fallback, matches, prop]);

  return responsiveProp;
}
