import { useMemo, useState } from "react";
import { usePagination } from "@adaptive/design-system/hooks";
import {
  useGetCapitalOSCardsQuery,
  useGetCapitalOSInitiateLoginQuery,
  useUpdateCapitalOSCardMutation,
} from "@api/capital-os-cards";
import { useClientInfo } from "@store/user";

export const useCapitalOSCards = () => {
  const { realmId } = useClientInfo();

  const pagination = usePagination({
    localStorageKey: "capital-os-cards-limit",
  });

  const [ordering, setOrdering] = useState("created_at");

  const sort = useMemo(
    () => ({ value: ordering, onChange: setOrdering }),
    [ordering, setOrdering]
  );

  const {
    data: cards,
    isLoading: cardsIsLoading,
    refetch: refetchCards,
  } = useGetCapitalOSCardsQuery({
    realm: realmId,
    limit: pagination.perPage,
    offset: pagination.offset,
    ordering,
  });

  const {
    data: initiateLoginData,
    isLoading: tokenIsLoading,
    refetch: refetchToken,
    error: tokenError,
  } = useGetCapitalOSInitiateLoginQuery();

  const [updateCard] = useUpdateCapitalOSCardMutation();

  return {
    cards,
    token: initiateLoginData?.token,
    cardsIsLoading,
    tokenIsLoading,
    updateCard,
    pagination,
    sort,
    refetchCards,
    refetchToken,
    tokenError,
  };
};
