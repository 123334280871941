import { useEvent } from "@adaptive/design-system/hooks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { setGlobalSearchOpen } from "@store/ui";

export const useGlobalSearch = () => {
  const dispatch = useAppDispatch();

  const isVisible = useAppSelector((state) => state.ui.globalSearchOpen);

  const hide = useEvent(() => dispatch(setGlobalSearchOpen(false)));

  const show = useEvent(() => dispatch(setGlobalSearchOpen(true)));

  return { hide, show, isVisible };
};
