import React, { forwardRef } from "react";
import { Tooltip } from "@adaptive/design-system";

import QuickBooksSvg from "./quickbooks-icon.svg?react";

type QuickBooksIconProps = {
  size?: number;
};

export const QuickBooksIcon = forwardRef<SVGSVGElement, QuickBooksIconProps>(
  ({ size = 24, ...props }, ref) => (
    <QuickBooksSvg
      ref={ref}
      role="img"
      width={size}
      height={size}
      style={{ verticalAlign: "middle" }}
      aria-label="QuickBooks"
      {...props}
    />
  )
);

QuickBooksIcon.displayName = "QuickBooksIcon";

export const QuickBooksSyncedIcon = forwardRef<
  SVGSVGElement,
  QuickBooksIconProps
>(({ size = 24 }, ref) => (
  <Tooltip
    as={QuickBooksIcon}
    ref={ref}
    size={size}
    message="Synced with QuickBooks"
  />
));

QuickBooksSyncedIcon.displayName = "QuickBooksSyncedIcon";
