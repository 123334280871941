import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "@adaptive/design-system";
import { captureMessage } from "@sentry/browser";
import { useUserInfo } from "@store/user";

import {
  postBankAccount,
  putBankAccount,
  putBankAccountVerificationStatus,
} from "../../shared/api/bank-accounts";

const isManuallyVerifiedAccount = (account) =>
  ["manually_verified"].includes(account.verification_status);

export const PlaidLink = ({
  linkToken,
  currentClient,
  onCreationSuccess,
  onCreationError,
  onLinkExit,
  selectedBankId,
  accountType,
}) => {
  const [openCalled, setOpenCalled] = useState(false);

  const { user } = useUserInfo();

  const config = {
    token: linkToken,
    onExit: async (error) => {
      if (
        error?.error_type === "INVALID_INPUT" &&
        error?.error_code === "MICRODEPOSITS_ALREADY_VERIFIED" &&
        selectedBankId
      ) {
        try {
          await putBankAccountVerificationStatus(selectedBankId, {
            verification_status: "manually_verified",
            client_id: currentClient.id,
          });
          onCreationSuccess();
          toast.success("Bank account verified");
        } catch (error) {
          onCreationError(error.response?.data);
        }
      }
      onLinkExit();
    },
    onSuccess: (public_token, metadata) => {
      const { account } = metadata;
      if (isManuallyVerifiedAccount(account) && selectedBankId) {
        const { verification_status } = account;
        const client_id = currentClient.id;
        return putBankAccountVerificationStatus(selectedBankId, {
          verification_status,
          client_id,
        })
          .then((response) => {
            onCreationSuccess();
            return response;
          })
          .catch((error) => {
            onCreationError(error.response?.data);
          });
      }
      const { accounts: account_balances, institution } = metadata;
      const params = {
        public_token,
        account_balances,
        institution,
        client_id: currentClient.id,
        type: accountType,
      };
      const request = selectedBankId
        ? putBankAccount(selectedBankId, params)
        : postBankAccount(params);

      return request
        .then((response) => {
          onCreationSuccess();
          return response;
        })
        .catch((error) => {
          onCreationError(error.response?.data);
        });
    },
    onEvent: (eventName, metadata) => {
      if (eventName === "ERROR") {
        captureMessage("Plaid link error", {
          user: {
            id: user.id,
            email: user.email,
          },
          tags: {
            link_token: linkToken,
            link_session_id: metadata.link_session_id,
            plaid_error_code: metadata.error_code,
            plaid_error_message: metadata.error_message,
            plaid_error_type: metadata.error_type,
            plaid_institution_id: metadata.institution_id,
            plaid_institution_name: metadata.institution_name,
            client_id: currentClient.id,
            client_name: currentClient.name,
            client_email: currentClient.email,
          },
        });
      }
    },
  };
  const { open, ready } = usePlaidLink(config);
  if (ready && !openCalled) {
    setOpenCalled(true);
    open();
  }
  return null;
};
