import {
  type Formatter,
  getArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";

import { MY_TRANSACTIONS_KEY } from "../constants/constants";

export const cardTransactionsFilterFormatter: Formatter<QueryItem[]> = (
  realmId,
  filters
) => {
  let params = getArrayFormatter(realmId, filters).map((query) => {
    if (query.dataIndex === "linked_card") {
      return { ...query, dataIndex: "card" };
    } else if (query.dataIndex.startsWith("date_")) {
      return {
        ...query,
        dataIndex: query.dataIndex.replace("date_", "transaction_date_"),
      };
    } else if (query.dataIndex === "status") {
      if (query.value === "matched_cost_true") {
        return { dataIndex: "is_linked_to_transaction", value: "true" };
      } else if (query.value === "matched_cost_false") {
        return { dataIndex: "is_linked_to_transaction", value: "false" };
      } else if (query.value === "is_archived") {
        return { dataIndex: "is_archived", value: "true" };
      }
    }

    return query;
  });

  /**
   * This is a small workaround over "user" and "my transactions" filters.
   * Since they conflict with each other, we need to handle in this way:
   * - if "user" is set and "my transactions" is off, we use users filter
   * - if "user" is set and "my transactions" is on, we create an empty state
   * - if "user" is not set and "my transactions" is on, we use my transactions filter
   */
  const hasUser = params.some((query) => query.dataIndex === "user");
  const myTransactionUser = params.find(
    (query) => query.dataIndex === MY_TRANSACTIONS_KEY
  )?.value;
  const hasMyTransactionsUser = !!myTransactionUser;

  if (hasUser && hasMyTransactionsUser) {
    params = params.filter((query) => query.dataIndex !== "user");
    params.push({ dataIndex: "user", value: -1 });
  } else if (!hasUser && hasMyTransactionsUser) {
    params = params.filter((query) => query.dataIndex !== "user");
    params.push({ dataIndex: "user", value: myTransactionUser });
  }

  return params.filter((query) => query.dataIndex !== MY_TRANSACTIONS_KEY);
};
