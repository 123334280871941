import type {
  ACHStatus,
  DocumentStatus,
  DocumentType,
} from "@shared/api/vendors/types";
import type { Option } from "@shared/types/objects";

export const DOCUMENT_TYPE_OPTIONS: Option<DocumentType>[] = [
  { label: "Combined COI", value: "INSURANCE" },
  { label: "Workers compensation COI", value: "WORKERS_COMP" },
  { label: "General liability COI", value: "GENERAL_LIABILITY" },
  { label: "W-9", value: "W9" },
  { label: "Contract", value: "CONTRACT" },
  { label: "Other", value: "OTHER" },
];

export const DOCUMENT_STATUS_OPTIONS: Option<DocumentStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Expired", value: "EXPIRED" },
  { label: "Expiring soon", value: "EXPIRING" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];

export const ACH_STATUS_OPTIONS: Option<ACHStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];

export const STRINGS = {
  EMAIL_CHANGE_CONFIRMATION_DIALOG_TITLE:
    "Are you sure you want to save this email address?",
  EMAIL_CHANGE_CONFIRMATION_DIALOG_MESSAGE:
    "Any outstanding requests that have been sent to other email addresses will be automatically expired. This could include requests for vendor documents, ACH details, or PO signatures.",
  EMAIL_CHANGE_CONFIRMATION_DIALOG_BUTTON: "Add email & expire requests",
  REQUESTS_WERE_EXPIRED_TOAST:
    "All requests previously sent to this vendor were expired",
};
