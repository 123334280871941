import { api } from "../utils/api";
type SaveTeamMembersProps = {
  team_members: TeamMembersProps[];
  client_id: string;
};

type TeamMembersProps = {
  full_name: string;
  email: string;
  role: string;
};

type Client = {
  id: number;
  members: number;
  name: string;
};

type SaveCompanyResponse = {
  id: number;
  company_name: string;
  client: {
    id: string;
    name: string;
    email: string;
  };
};

export type WorkSpacesResponse = {
  id: string;
  joined: boolean;
  client: Client;
};
export const saveCompany = (companyName: string) => {
  const formData = new FormData();
  formData.append("company_name", companyName);

  return api
    .post<SaveCompanyResponse>("/api/realms/", formData)
    .then((response) => response.data);
};

export const saveTeamMembers = (data: SaveTeamMembersProps) => {
  return api.post("/api/user/onboarding/team-members/", data);
};

export const invitePeople = (data: SaveTeamMembersProps) => {
  return api.post("/api/user/invite-people/", data);
};

export const skipTeamMembers = () => {
  return api.post("/api/user/onboarding/team-members/skip/");
};

export const getWorkspaces = () => {
  return api.get<WorkSpacesResponse[]>("/api/user/onboarding/workspaces/");
};

export const joinWorkspaces = (workspaces: string[]) => {
  return api.post<WorkSpacesResponse[]>("/api/user/onboarding/workspaces/", {
    workspaces,
  });
};
