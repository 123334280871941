import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutationObserver } from "@adaptive/design-system/hooks";
import { usePagination } from "@adaptive/design-system/hooks";
import {
  type FidelCard,
  pollFidelCard,
  type PollFidelCardPayload,
  useDeleteFidelCardMutation,
  useGetFidelCardsQuery,
  useUpdateFidelCardMutation,
} from "@api/fidel-cards";
import { useClientInfo } from "@store/user";
import { noop } from "@utils/noop";

type ConnectHandler = (props: {
  onCardEnrolledCallback: (params: { id: string } | undefined) => void;
  onCardVerifiedCallback: (params: { cardId: string } | undefined) => void;
  onCardVerifyFailedCallback: () => void;
}) => Promise<void>;

type VerifyHandler = (props: { fidelCard: FidelCard }) => Promise<void>;

export const useFidelCards = () => {
  const stopRef = useRef(noop);

  const bodyRef = useRef<HTMLElement>(document.body);

  const { realmId } = useClientInfo();

  const pagination = usePagination({
    localStorageKey: "cards-fidel-table-limit",
  });

  const [isLoadingSdk, setIsLoadingSdk] = useState(false);

  const [ordering, setOrdering] = useState("created_at");

  const sort = useMemo(
    () => ({ value: ordering, onChange: setOrdering }),
    [ordering, setOrdering]
  );

  const { data, refetch, isLoading } = useGetFidelCardsQuery({
    realm: realmId,
    limit: pagination.perPage,
    offset: pagination.offset,
    ordering,
  });

  const [updateCard] = useUpdateFidelCardMutation();
  const [deleteCard] = useDeleteFidelCardMutation();

  const pollCard = useCallback(
    async ({ fidelId, verificationStatus }: PollFidelCardPayload) => {
      const { run, stop } = pollFidelCard({ fidelId, verificationStatus });
      stopRef.current = stop;
      await run();
      refetch();
    },
    [refetch]
  );

  const loadSdk = useCallback(
    () =>
      new Promise<void>((resolve) => {
        if (document.getElementById("fidel-sdk")) return resolve();

        const sdkScript = document.createElement("script");
        sdkScript.src = "https://resources.fidel.uk/sdk/js/v3/fidel.js";
        sdkScript.id = "fidel-sdk";
        sdkScript.onload = () => resolve();
        document.body.appendChild(sdkScript);
      }),
    []
  );

  const connect = useCallback<ConnectHandler>(
    async ({
      onCardEnrolledCallback,
      onCardVerifiedCallback,
      onCardVerifyFailedCallback,
    }) => {
      setIsLoadingSdk(true);

      await loadSdk();

      window.Fidel?.openForm({
        sdkKey: window.FIDEL_SDK_KEY,
        metadata: { id: "adaptive-cards", realm_id: realmId! },
        programId: window.FIDEL_PROGRAM_ID,
        companyName: "Adaptive",
        programType: "transaction-stream",
        thirdPartyVerification: {
          isChoiceAvailable: true,
        },
        onCardEnrolledCallback,
        onCardVerifiedCallback,
        onCardVerifyFailedCallback,
      });

      const iframe = document.getElementById("fidel-iframe");

      if (!iframe) return;

      iframe.onload = () => setIsLoadingSdk(false);
    },
    [loadSdk, realmId]
  );

  const verify = useCallback<VerifyHandler>(
    async ({ fidelCard }) => {
      setIsLoadingSdk(true);

      await loadSdk();

      window.Fidel?.verifyCard({
        sdkKey: window.FIDEL_SDK_KEY,
        metadata: { id: "adaptive-cards", realm_id: realmId! },
        programId: window.FIDEL_PROGRAM_ID,
        cardToVerify: {
          id: fidelCard.fidelId,
          consentId: fidelCard.consentId,
          last4Digits: fidelCard.mask,
        },
      });

      const iframe = document.getElementById("fidel-iframe");

      if (!iframe) return;

      iframe.onload = () => setIsLoadingSdk(false);
    },
    [loadSdk, realmId]
  );

  useMutationObserver(bodyRef, (mutations) => {
    mutations.forEach((mutation) => {
      const hasClosed = [...mutation.removedNodes].some(
        (node) =>
          node instanceof HTMLIFrameElement && node.id === "fidel-iframe"
      );

      if (hasClosed) setIsLoadingSdk(false);
    });
  });

  useEffect(() => {
    return () => stopRef.current();
  }, []);

  return {
    data,
    connect,
    verify,
    pollCard,
    isLoading,
    updateCard,
    deleteCard,
    isLoadingSdk,
    pagination,
    sort,
  };
};
