type ParseClockwiseParam<T> = T | [T, T] | [T, T, T] | [T, T, T, T];

type ParseClockwiseReturn<T> = { top: T; right: T; bottom: T; left: T };

export const parseClockwise = <T>(
  prop: ParseClockwiseParam<T>
): ParseClockwiseReturn<T> => {
  if (!Array.isArray(prop)) {
    return { top: prop, right: prop, bottom: prop, left: prop };
  }

  if (prop.length === 4) {
    return { top: prop[0], right: prop[1], bottom: prop[2], left: prop[3] };
  }

  if (prop.length === 3) {
    return { top: prop[0], right: prop[1], bottom: prop[2], left: prop[1] };
  }

  return { top: prop[0], right: prop[1], bottom: prop[0], left: prop[1] };
};
