import React, { useCallback } from "react";
import { Alert, AlertContent } from "@adaptive/design-system";
import {
  billSubTotalSelector,
  billTaxValueSelector,
  camelCaseLinesBillSelector,
  getLineTotals,
  staticBillSubTotalSelector,
  staticBillTaxValueSelector,
  staticLinesBillSelector,
} from "@src/bills/utils";
import { useAppSelector } from "@store/hooks";

export const PurchaseOrdersOverBudgetAlert = () => {
  const lines = useAppSelector(camelCaseLinesBillSelector);

  const taxValue = useAppSelector(billTaxValueSelector);

  const subTotal = useAppSelector(billSubTotalSelector);

  const staticSubTotal = useAppSelector(staticBillSubTotalSelector);

  const staticTaxValue = useAppSelector(staticBillTaxValueSelector);

  const staticLines = useAppSelector(staticLinesBillSelector);

  const getStaticLine = useCallback(
    (lineId: string) => staticLines.find((line) => line.id == lineId),
    [staticLines]
  );

  const overBudgetPurchaseOrders = lines.filter((line) => {
    const staticLine = getStaticLine(line.id!);

    const { purchaseOrderIsOverBudget } = getLineTotals({
      tax: taxValue,
      line,
      subTotal,
      staticTax: staticTaxValue,
      staticLineAmount: staticLine?.amount || 0,
      staticSubTotal,
    });

    return purchaseOrderIsOverBudget;
  });

  if (!overBudgetPurchaseOrders.length) return null;

  return (
    <Alert variant="warning">
      <AlertContent>
        It looks like one of the PO&apos;s linked to this bill is over budget
      </AlertContent>
    </Alert>
  );
};
