import { toast } from "@adaptive/design-system";
import { handleErrors } from "@src/shared/api/handle-errors";
import {
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceSettingsMutation,
} from "@src/shared/api/invoices";
import { useJobInfo } from "@src/shared/store/jobs";

export const useInvoiceSettings = () => {
  const { job } = useJobInfo();
  const { data: invoiceSettings, isLoading } = useGetInvoiceSettingsQuery(
    { customerId: job.id },
    { skip: !job.id, refetchOnMountOrArgChange: true }
  );
  const [update] = useUpdateInvoiceSettingsMutation();

  const updateInvoiceSettings = async (body: {
    defaultTitle?: string;
    defaultDescription?: string;
  }) => {
    try {
      await update({
        customerId: job.id,
        invoiceSettingId: invoiceSettings?.id,
        body,
      });
      toast.success("Draw settings updated");
    } catch (error) {
      handleErrors(error);
    }
  };

  return {
    invoiceSettings,
    isLoading,
    updateInvoiceSettings,
  };
};
