import React, { useEffect } from "react";

import styles from "./staff-warning.module.css";

type StaffWarningProps = { show: boolean };

export const StaffWarning = ({ show }: StaffWarningProps) => {
  const [force, setForce] = React.useState<"show" | "hide" | undefined>();

  useEffect(() => {
    window.StaffWarning = {
      show: () => setForce("show"),
      hide: () => setForce("hide"),
    };
  }, [setForce]);

  return (
    (show || force === "show") &&
    force !== "hide" && <div className={styles["staff-warning"]} />
  );
};
