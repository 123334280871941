export const showOpenFilePicker = async (options: {
  accept?: string;
  multiple?: boolean;
}): Promise<File[]> =>
  new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";

    if (options.accept) input.accept = options.accept;

    if (options.multiple) input.multiple = true;

    input.onchange = (event) => {
      const files = (event.target as HTMLInputElement).files;
      resolve(files ? [...files] : []);
    };

    input.click();

    input.remove();
  });
