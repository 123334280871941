import React, { type ElementType, type ReactNode } from "react";
import cn from "clsx";

import grid from "./grid.module.css";

export const DefaultFooterContainer = ({
  children,
}: {
  children: ReactNode;
}) => <div className={cn(grid["footer"])}>{children}</div>;

interface ContentFragmentProps {
  Stage: ElementType;
  stageProps: Record<string, any>;
  children: ReactNode | ReactNode[];
}

export const StageContentFragment = ({
  Stage,
  stageProps,
  children,
}: ContentFragmentProps) => (
  <>
    {React.isValidElement(Stage) ? Stage : <Stage {...stageProps} />}
    {children}
  </>
);

export const DefaultContentContainer = ({
  children,
}: {
  children: ReactNode;
}) => <div className={cn(grid["contents"])}>{children}</div>;
