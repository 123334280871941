import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Button,
  ComboBox,
  dialog,
  Flex,
  Icon,
  Link,
  Table,
  type TableColumn,
  TableConfigurationButton,
  type TableEmptyState,
  TableProvider,
  type TableRowAddon,
  Tag,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { fuzzySearch, suffixify } from "@adaptive/design-system/utils";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@src/shared/components/sticky";
import { useTwoFactorAuth } from "@store/ui";
import { curriedNoop } from "@utils/noop";

import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "../../../shared/components/main";
import { useRoleAction } from "../../../shared/store/role";
import {
  BasePermissions,
  BaseRoles,
  useUserInfo,
} from "../../../shared/store/user";
import {
  type Person,
  useClientAction,
  usePeopleAction,
  usePeopleInfo,
} from "../../../shared/store/user";

import { InvitePersonDialog } from "./invite-person-dialog";
import { RoleOption } from "./role-option";
import { Roles as RolesDialog } from "./roles";
import type { RoleData, Step } from "./types";

const TABLE_TEST_ID = "people-table";

type PeopleContextType = {
  roles: RoleData[];
  curriedOnDelete: (row: Person) => () => void;
  curriedOnRevoke: (row: Person) => () => void;
  curriedOnResend: (row: Person) => () => void;
  curriedOnChangeRole: (row: Person) => (value: string) => void;
};

const PeopleContext = createContext<PeopleContextType>({
  roles: [],
  curriedOnDelete: curriedNoop,
  curriedOnRevoke: curriedNoop,
  curriedOnResend: curriedNoop,
  curriedOnChangeRole: curriedNoop,
});

const COLUMNS: TableColumn<Person>[] = [
  {
    id: "avatar",
    name: "Avatar",
    width: 77,
    render: (row) => (
      <Flex width="full" justify="center">
        <Avatar
          size="lg"
          name={row.full_name ?? "Unnamed"}
          data-testid={suffixify(TABLE_TEST_ID, "avatar")}
        />
      </Flex>
    ),
  },
  {
    id: "name",
    name: "Name",
    visibility: "always-visible",
    width: "fill",
    render: (row) => (
      <Text data-testid={suffixify(TABLE_TEST_ID, "full-name")}>
        {row.full_name ?? "Unnamed"}
      </Text>
    ),
  },
  {
    id: "phone",
    name: "Phone",
    width: 150,
    render: (row) => (
      <Text as={Link} size="sm" href={`tel:${row.phone_number}`}>
        {row.phone_number}
      </Text>
    ),
  },
  {
    id: "email",
    name: "Email",
    width: 300,
    render: (row) => (
      <Flex width="full">
        <Text truncate as={Link} size="sm" href={`mailto:${row.email}`}>
          {row.email}
        </Text>
      </Flex>
    ),
  },
  {
    id: "role",
    name: "Role",
    width: 300,
    render: (row) => (
      <PeopleContext.Consumer>
        {({ roles, curriedOnChangeRole }) => (
          <ComboBox
            size="sm"
            data={roles}
            required
            onChange={curriedOnChangeRole(row)}
            renderOption={(option) => (
              <RoleOption
                {...option}
                description={option.description as string}
              />
            )}
            messageVariant="hidden"
            value={row.role?.url}
            data-testid={suffixify(TABLE_TEST_ID, "role")}
          />
        )}
      </PeopleContext.Consumer>
    ),
  },
  {
    id: "status",
    name: "Status",
    width: 170,
    render: (row) => (
      <Tag
        color={row.is_user ? "success" : "neutral"}
        data-testid={suffixify(TABLE_TEST_ID, "status")}
      >
        {row.is_user ? "Active" : "Invitation pending"}
      </Tag>
    ),
  },
  {
    id: "action",
    name: "Actions",
    visibility: "always-visible",
    width: 200,
    render: (row) => (
      <PeopleContext.Consumer>
        {({ curriedOnDelete, curriedOnResend, curriedOnRevoke }) =>
          row.is_user ? (
            <Button
              variant="ghost"
              color="error"
              size="sm"
              onClick={curriedOnDelete(row)}
            >
              Delete user
            </Button>
          ) : (
            <Flex gap="md">
              <Button variant="ghost" size="sm" onClick={curriedOnResend(row)}>
                Re-send
              </Button>
              <Button
                variant="ghost"
                size="sm"
                color="error"
                onClick={curriedOnRevoke(row)}
              >
                Revoke
              </Button>
            </Flex>
          )
        }
      </PeopleContext.Consumer>
    ),
  },
];

export const People = () => {
  const [offset, setOffset] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const {
    deleteUser,
    loadPeople,
    updatePerson,
    revokeInvitation,
    resendInvitation,
  } = usePeopleAction();

  const { people } = usePeopleInfo();

  const rolesDialog = useMultiStepDialog<Step>({ initialStep: "role-list" });

  const inviteDialog = useDialog();

  const { currentClient } = useClientAction();

  const [isLoading, setIsLoading] = useState(!!currentClient);

  const { loadRoles, roles } = useRoleAction();

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const { hasPermission, user, role } = useUserInfo();

  const fuzzyInstance = useMemo(
    () => fuzzySearch(people, { keys: ["full_name"], threshold: 0 }),
    [people]
  );

  const roleData: RoleData[] = useMemo(
    () =>
      roles.map((role) => ({
        description: role.description,
        value: role.url || "",
        label: role.name,
      })),
    [roles]
  );

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setOffset(sticky.height);
  });

  const curriedOnChangeRole = useCallback<
    PeopleContextType["curriedOnChangeRole"]
  >(
    (row) => (value) => {
      if (!value || value === row?.role.url) return;

      if (user.id == row.id) {
        const newRole = roleData.find((role) => role.value === value);

        if (
          BaseRoles.BOSS === newRole?.label &&
          BaseRoles.MANAGER === role?.name
        ) {
          return toast.error(
            `Only bosses can upgrade other people to the Boss role`
          );
        }

        return toast.error(
          `You can't change your own role from ${row?.role.name} to ${newRole?.label}, you would lose the permissions`
        );
      }

      updatePerson({
        id: row.id,
        role: value,
        isUser: row.is_user,
        clientId: currentClient?.id || "",
      });
    },
    [currentClient?.id, roleData, updatePerson, user.id, role]
  );

  const curriedOnRevoke = useCallback<PeopleContextType["curriedOnRevoke"]>(
    (row) => () => {
      if (!row?.id || !currentClient?.id) return;

      dialog.confirmation({
        title: (
          <>
            Are you sure you want to <br /> revoke the invitation from{" "}
            {row?.full_name}?
          </>
        ),
        action: {
          primary: {
            color: "error",
            children: "Revoke invitation",
            onClick: () =>
              revokeInvitation({ id: row.id, clientId: currentClient.id }),
          },
        },
      });
    },
    [currentClient?.id, revokeInvitation]
  );

  const curriedOnResend = useCallback<PeopleContextType["curriedOnResend"]>(
    (row) => () => {
      if (!currentClient?.id) return;

      resendInvitation({ id: row.id, clientId: currentClient.id });
    },
    [currentClient?.id, resendInvitation]
  );

  const curriedOnDelete = useCallback<PeopleContextType["curriedOnDelete"]>(
    (row) => () => {
      if (!row?.id || !currentClient?.id) return;

      if (user.id == row?.id) {
        return toast.error(`You can't delete yourself from a client`);
      }

      dialog.confirmation({
        title: (
          <>
            Are you sure you want <br />
            to delete {row?.full_name}?
          </>
        ),
        action: {
          primary: {
            color: "error",
            children: "Delete user",
            onClick: () =>
              deleteUser({ id: row.id, clientId: currentClient.id }),
          },
        },
      });
    },
    [currentClient?.id, deleteUser, user.id]
  );

  const onInvitePeople = useEvent(() => {
    checkTwoFactorAuth(() => {
      inviteDialog.show();
    });
  });

  const searchResults = useDeepMemo(
    () =>
      (searchQuery ? fuzzyInstance.search(searchQuery) : fuzzyInstance.all).map(
        (item) => item.id
      ),
    [searchQuery, fuzzyInstance]
  );

  const row = useMemo<TableRowAddon<Person>>(
    () => ({
      isVisible: (row) => searchResults.some((id) => id === row.id),
    }),
    [searchResults]
  );

  const onClearSearchClick = useEvent(() => setSearchQuery(""));

  const emptyState = useMemo<TableEmptyState>(() => {
    if (searchQuery) {
      return {
        title: "No people match your search",
        subtitle: "Try adjusting your search to find what you're looking for",
        action: {
          children: "Clear search",
          onClick: onClearSearchClick,
        },
      };
    }

    return {
      title: "You currently have no people",
      subtitle: "Invite people to your workspace",
      action: {
        children: "Invite people",
        onClick: onInvitePeople,
      },
    };
  }, [onClearSearchClick, onInvitePeople, searchQuery]);

  useEffect(() => {
    if (currentClient) {
      Promise.all([
        loadPeople(currentClient.id),
        loadRoles(currentClient.id),
      ]).finally(() => setIsLoading(false));
    }
  }, [loadRoles, loadPeople, currentClient]);

  return (
    <Main>
      <MainHeader>
        <Flex align="center" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>People</MainTitle>
            <MainSubtitle>Manage the people in your workspace</MainSubtitle>
          </Flex>

          {hasPermission(BasePermissions.COMPANY_ADMIN) && (
            <Flex gap="xl">
              <Button
                variant="ghost"
                onClick={rolesDialog.show}
                data-skip-focusable=""
              >
                Manage roles
              </Button>
              <Button onClick={onInvitePeople}>Invite people</Button>
            </Flex>
          )}
        </Flex>
      </MainHeader>
      <MainContent>
        <TableProvider id="people-table">
          <StickyProvider onResize={onResize}>
            <Sticky
              style={{
                paddingTop: "var(--spacing-2xl)",
                marginTop: "calc(var(--spacing-2xl) * -1)",
                paddingBottom: "var(--spacing-lg)",
              }}
            >
              <Flex width="full" gap="xl">
                <Flex width="full" maxWidth="350px">
                  <TextField
                    addonAfter={<Icon name="search" />}
                    placeholder="Filter people"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    messageVariant="hidden"
                  />
                </Flex>
                <Flex grow justify="flex-end">
                  <TableConfigurationButton />
                </Flex>
              </Flex>
            </Sticky>
            <StickyMeasurer>
              <PeopleContext.Provider
                value={{
                  roles: roleData,
                  curriedOnDelete,
                  curriedOnRevoke,
                  curriedOnResend,
                  curriedOnChangeRole,
                }}
              >
                <Table
                  row={row}
                  data={people}
                  size="sm"
                  header={{ hide: people.length === 0, sticky: { offset } }}
                  loading={isLoading}
                  emptyState={emptyState}
                  columns={COLUMNS}
                  data-testid={TABLE_TEST_ID}
                />
              </PeopleContext.Provider>
            </StickyMeasurer>
          </StickyProvider>
        </TableProvider>
      </MainContent>
      <InvitePersonDialog roleData={roleData} dialog={inviteDialog} />
      <RolesDialog dialog={rolesDialog} />
    </Main>
  );
};
