import React from "react";
import { useSearchParams } from "react-router-dom";
import { Flex, TabsPanel } from "@adaptive/design-system";
import { ErrorAlert } from "@components/common/error-alert";
import { MainContent } from "@components/main";
import { useJobActions } from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import { BasePermissions, useUserInfo } from "@store/user";

import { Budget } from "./budget";
import { Tab as InvoicesTab } from "./invoices";

export const DetailView = () => {
  const { job } = useJobInfo();

  const [searchParams] = useSearchParams();

  const { refetchJob } = useJobActions();

  const { hasPermission } = useUserInfo();

  const currentTab = searchParams.get("status") ?? "budget";

  const canViewAllInvoices = hasPermission(BasePermissions.VIEW_ALL_INVOICES);

  return (
    <>
      <MainContent>
        <Flex
          gap="xl"
          shrink={false}
          style={{
            /* We need to this calc to compensate margin from MainContent */
            minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
          }}
          direction="column"
        >
          {(job.errors || []).length > 0 && (
            <ErrorAlert
              data={job.errors!}
              onChange={refetchJob}
              objectType="Customer"
            />
          )}
          <div>
            <TabsPanel>
              {currentTab === "budget" && <Budget />}
              {currentTab === "invoice" && canViewAllInvoices && (
                <InvoicesTab />
              )}
            </TabsPanel>
          </div>
        </Flex>
      </MainContent>
    </>
  );
};
