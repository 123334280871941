import React, { useState } from "react";
import {
  Dialog,
  DialogStep,
  Flex,
  Link,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useEvent,
  type useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { generateUrlByHumanReadableType } from "@utils/generate-url-by-human-readable-type";

import type { CardTransaction } from "../api/types";

import { MatchingTransactionDialogEditStep } from "./matching-transaction-dialog-edit-step";
import { MatchingTransactionDialogMatchStep } from "./matching-transaction-dialog-match-step";
import { MatchingTransactionDialogSelectCardTransactionStep } from "./matching-transaction-dialog-select-card-transaction-step";
import { MatchingTransactionDialogSelectCostStep } from "./matching-transaction-dialog-select-cost-step";
import type {
  LinkedCost,
  MatchingTransactionDialogSteps,
  MatchingTransactionOnChangeHandler,
} from "./types";

export type MatchingTransactionDialogProps = {
  cost?: LinkedCost;
  dialog: ReturnType<typeof useMultiStepDialog<MatchingTransactionDialogSteps>>;
  onChange?: MatchingTransactionOnChangeHandler;
  cardTransaction?: CardTransaction;
};

export const MatchingTransactionDialog = ({
  cost,
  dialog,
  onChange,
  cardTransaction,
}: MatchingTransactionDialogProps) => {
  const [match, setMatch] = useState({
    cost: undefined,
    conflicts: [],
    cardTransaction: undefined,
  });

  const hasMatch = !!(cost?.cardTransaction || cardTransaction?.match);

  const onSuccess = useEvent<MatchingTransactionOnChangeHandler>((payload) => {
    const info = payload.cost
      ? dotObject.get(payload.cost, "parent") || payload.cost
      : undefined;

    if (cost) {
      toast.success(
        `Card transaction matched to cost${info.docNumber ? ` #${info.docNumber}` : ""}`
      );
    } else {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Cost{info.docNumber ? ` #${info.docNumber}` : ""} matched to card
            transaction
          </Text>
          {info && (
            <Link href={generateUrlByHumanReadableType(info)} target="_blank">
              View cost
            </Link>
          )}
        </Flex>
      );
    }

    onChange?.(payload);

    dialog.hide();
  });

  const onUnmatch = useEvent(() => {
    if (cost) {
      const info = dotObject.get(cost, "parent") || cost;
      toast.success(
        `Card transaction was unmatched${info?.docNumber ? ` from cost #${info.docNumber}` : ""}`
      );
    } else if (cardTransaction) {
      const info =
        dotObject.get(cardTransaction, "match.parent") || cardTransaction.match;
      toast.success(
        `Cost${info?.docNumber ? ` #${info.docNumber}` : ""} was unmatched from card transaction`
      );
    }

    onChange?.({ cost: undefined, cardTransaction: undefined });

    dialog.hide();
  });

  const onSelectError = useEvent((payload) => {
    setMatch({ cost, cardTransaction, ...payload });
    dialog.setStep("match");
  });

  return (
    <Dialog
      show={dialog.isVisible}
      size="auto"
      step={dialog.step}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="edit">
        <MatchingTransactionDialogEditStep
          cost={cost}
          onUnmatch={onUnmatch}
          onChangeMatch={() => dialog.setStep("select")}
          cardTransaction={cardTransaction}
        />
      </DialogStep>
      <DialogStep name="select" onBack={hasMatch ? dialog.back : undefined}>
        {cost ? (
          <MatchingTransactionDialogSelectCardTransactionStep
            cost={cost}
            onError={onSelectError}
            onCancel={dialog.hide}
            onSuccess={onSuccess}
          />
        ) : cardTransaction ? (
          <MatchingTransactionDialogSelectCostStep
            onError={onSelectError}
            onCancel={dialog.hide}
            onSuccess={onSuccess}
            cardTransaction={cardTransaction}
          />
        ) : null}
      </DialogStep>
      <DialogStep name="match" onBack={dialog.back}>
        <MatchingTransactionDialogMatchStep
          onCancel={dialog.back}
          onSuccess={onSuccess}
          {...match}
        />
      </DialogStep>
    </Dialog>
  );
};
