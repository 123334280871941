export const getBrowserInfo = () => ({
  language: navigator.language,
  userAgent: navigator.userAgent,
  colorDepth: window.screen.colorDepth,
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
  touchEnabled: "ontouchstart" in window,
  webGLEnabled: !!window.WebGLRenderingContext,
  canvasEnabled: !!document.createElement("canvas").getContext,
  cookiesEnabled: navigator.cookieEnabled,
  timezoneOffset: new Date().getTimezoneOffset(),
  fileAPIEnabled:
    !!window.File && !!window.FileReader && !!window.FileList && !!window.Blob,
  fetchAPIEnabled: typeof window.fetch === "function",
  indexedDBEnabled: !!window.indexedDB,
  webSocketEnabled: "WebSocket" in window || "MozWebSocket" in window,
  historyAPIEnabled: !!(window.history && history.pushState),
  webWorkersEnabled: !!window.Worker,
  geolocationEnabled: "geolocation" in navigator,
  languagePreference: navigator.languages || [navigator.language],
  localStorageEnabled: typeof window.localStorage !== "undefined",
  hardwareConcurrency: navigator.hardwareConcurrency,
  sessionStorageEnabled: typeof window.sessionStorage !== "undefined",
});
