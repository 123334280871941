import { formatMention } from "@adaptive/design-system/utils";
import { dotObject } from "@adaptive/design-system/utils";

type GetMessageToVendor = (
  vendor: { id: string | number; displayName: string; url: string },
  message?: string
) => string;

export const getMessageToVendor: GetMessageToVendor = (vendor, message) => {
  let messageToVendor = formatMention({
    label: dotObject.get(
      vendor,
      "display_name",
      dotObject.get(vendor, "displayName", "")
    ),
    value: vendor.url,
  });

  if (message) {
    messageToVendor += `\r\n\r${message}`;
  }

  return messageToVendor;
};
