import { type AccountBalanceV1 } from "@api/bank-accounts";
import { achSchema } from "@api/vendors/response/schema";
import { type ValidationRule } from "@utils/validator";
import { z } from "zod";

import { PAYMENT_METHOD } from "../../../constants";

import { type BillPayment } from "./types";

export const REASONS = {
  MISSING_EMAIL: "Add recipient's email address",
  MISSING_PHONE_NUMBER: "Add recipient's phone number",
  MISSING_ACH: "Add recipient's ACH information",
  MISSING_ADDRESS: "Add or complete recipient's physical address",
  MISSING_BANK_ACCOUNT: "Add bank account",
  BANK_ACCOUNT_NOT_LINKED: "Bank account not linked",
};

const emailableSchema = z.string().email();
const emailableObjectSchema = z.object({ email: z.string().email() });

const phoneNumberSchema = z.object({ phoneNumber: z.string().min(1) });

const achableSchema = achSchema.omit({
  archived: true,
  id: true,
});

export const addressSchema = z.object({
  line1: z.string().min(1),
  line2: z.string().nullish().optional(),
  city: z.string().min(1),
  state: z.string().min(1),
  postalCode: z.string().min(1),
});

export const vendorHasEmailIfPaymentIsVirtualCheck: ValidationRule<
  BillPayment
> = ({ paymentMethod, vendor }: BillPayment) => {
  const result = emailableObjectSchema.safeParse(vendor);

  return {
    ok: paymentMethod !== PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK || result.success,
    reason: REASONS.MISSING_EMAIL,
  };
};

export const vendorHasPhoneNumberIfPaymentIsSmsCheck: ValidationRule<
  BillPayment
> = ({ paymentMethod, vendor }: BillPayment) => {
  const result = phoneNumberSchema.safeParse(vendor);
  return {
    ok: paymentMethod !== PAYMENT_METHOD.VIRTUAL_SMS_CHECK || result.success,
    reason: REASONS.MISSING_PHONE_NUMBER,
  };
};

export const formHasEmailIfPaymentIsAch: ValidationRule<BillPayment> = ({
  paymentMethod,
  vendorEmail,
}: BillPayment) => {
  const result = emailableSchema.safeParse(vendorEmail);
  return {
    ok: paymentMethod !== PAYMENT_METHOD.ACH || result.success,
    reason: REASONS.MISSING_EMAIL,
  };
};

export const vendorHasAchIfPaymentIsAch: ValidationRule<BillPayment> = ({
  paymentMethod,
  vendor,
}: BillPayment) => {
  const validation = achableSchema.safeParse(vendor.banking);
  return {
    ok: paymentMethod !== PAYMENT_METHOD.ACH || validation.success,
    reason: REASONS.MISSING_ACH,
  };
};

export const vendorHasAddressIfPaymentIsMailCheck: ValidationRule<
  BillPayment
> = ({ vendor, paymentMethod }: BillPayment) => {
  const validation = addressSchema.safeParse(vendor?.address || {});
  return {
    ok: paymentMethod !== PAYMENT_METHOD.MAIL_CHECK || validation.success,
    reason: REASONS.MISSING_ADDRESS,
  };
};

export const clientHasBankAccount: ValidationRule<[]> = (
  accountBalances: []
) => {
  return {
    ok: accountBalances.length > 0,
    reason: REASONS.MISSING_BANK_ACCOUNT,
  };
};

export const AccountBalanceIsLinkedtoAccount: ValidationRule<
  AccountBalanceV1
> = (selectedAccountBalance: AccountBalanceV1) => {
  return {
    ok: !!selectedAccountBalance?.payment_account?.url,
    reason: REASONS.BANK_ACCOUNT_NOT_LINKED,
  };
};

export const vendorRules = [
  vendorHasEmailIfPaymentIsVirtualCheck,
  vendorHasAddressIfPaymentIsMailCheck,
  vendorHasPhoneNumberIfPaymentIsSmsCheck,
];

export const formRules = [formHasEmailIfPaymentIsAch];

export const clientRules = [clientHasBankAccount];

export const accountRules = [AccountBalanceIsLinkedtoAccount];
