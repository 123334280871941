type GetCssVarHandle = {
  (name: string): string;
  (el: HTMLElement, name: string): string;
};

export const getCssVar: GetCssVarHandle = (
  elOrName: string | HTMLElement,
  name?: string
) => {
  const enhancedEl =
    typeof elOrName === "string" ? document.documentElement : elOrName;
  const enhancedName = typeof elOrName === "string" ? elOrName : name;

  return getComputedStyle(enhancedEl).getPropertyValue(`--${enhancedName}`);
};
