import { omit } from "@adaptive/design-system/utils";
import {
  type InvoiceEvaluatePayload,
  type InvoiceEvaluateResponse,
  type InvoiceGetResponse,
  type InvoiceLineMutate,
  type InvoicesSyncTransactionsResponse,
  type InvoiceUpdateInfoPayload,
  type InvoiceUpdateLinesPayload,
  type InvoiceUpdateMarkupsPayload,
  type InvoiceUpdateMarkupsResponse,
  type InvoiceUpdatePayload,
  type InvoiceUpdateResponse,
} from "@api/invoices";
import { noop } from "@src/shared/utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import { type Line } from ".";

export type UpdateInvoiceHandler = (
  invoice: Partial<Omit<InvoiceUpdatePayload, "id">>
) => Promise<InvoiceUpdateResponse>;

export type UpdateInvoiceInfoHandler = (
  invoice: Omit<InvoiceUpdateInfoPayload, "id">
) => Promise<InvoiceUpdateResponse>;

/**
 * Based on https://stackoverflow.com/questions/57103834/typescript-omit-a-property-from-all-interfaces-in-a-union-but-keep-the-union-s#answer-57103940
 */
export type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

export type EvaluateInvoiceHandler = (
  payload: DistributiveOmit<InvoiceEvaluatePayload, "invoiceId">
) => Promise<InvoiceEvaluateResponse>;

export type AddInvoiceCommentHandler = (values: {
  text: InvoiceGetResponse["comments"][number]["text"];
  author: InvoiceGetResponse["comments"][number]["author"];
  id: InvoiceGetResponse["comments"][number]["id"];
  url: InvoiceGetResponse["comments"][number]["url"];
  parentCommentUrl?: string;
  files?: InvoiceGetResponse["comments"][number]["files"];
}) => void;

export type UpdateInvoiceMarkupsHandler = (
  markups: InvoiceUpdateMarkupsPayload["markups"]
) => Promise<InvoiceUpdateMarkupsResponse>;

export type DeleteInvoiceHandler = () => Promise<void>;

export type SyncTransactionsHandler = (
  sync: boolean
) => Promise<InvoicesSyncTransactionsResponse>;

export type CreateInvoiceLinesFromTransactionsHandler = (
  invoiceLines: InvoiceLineMutate[]
) => Promise<void>;

export type UpdateInvoiceLinesHandler = (
  payload:
    | { lines: InvoiceUpdateLinesPayload["lines"] }
    | InvoiceUpdateLinesPayload["lines"]
) => Promise<void>;

export type InvoiceContextType = {
  invoice: InvoiceGetResponse;
  permissions: {
    canApproveInvoices: boolean;
    canBypassApprovalWorkflows: boolean;
  };
  invoiceLines: InvoiceGetResponse["lines"];
  invoiceLinesIsLoading: boolean;
  invoiceLinesSelected: Line[];
  setInvoiceLinesSelected: (lines: Line[]) => void;
  updateInvoice: UpdateInvoiceHandler;
  updateInvoiceInfo: UpdateInvoiceInfoHandler;
  deleteInvoice: DeleteInvoiceHandler;
  refetchInvoice: () => Promise<void>;
  invoiceMarkups: InvoiceGetResponse["markups"];
  invoiceMarkupsIsLoading: boolean;
  evaluateInvoice: EvaluateInvoiceHandler;
  invoiceIsLoading: boolean;
  addInvoiceComment: AddInvoiceCommentHandler;
  updateInvoiceLines: UpdateInvoiceLinesHandler;
  updateInvoiceMarkups: UpdateInvoiceMarkupsHandler;
  createInvoiceLinesFromTransactions: CreateInvoiceLinesFromTransactionsHandler;
  syncTransactions: SyncTransactionsHandler;
};

export const InvoiceContext = createContext<InvoiceContextType>({
  invoice: {} as InvoiceGetResponse,
  permissions: {
    canApproveInvoices: false,
    canBypassApprovalWorkflows: false,
  },
  invoiceLines: [],
  invoiceLinesSelected: [],
  invoiceLinesIsLoading: false,
  invoiceMarkupsIsLoading: false,
  setInvoiceLinesSelected: noop,
  invoiceMarkups: [],
  refetchInvoice: noop,
  updateInvoice: noop,
  deleteInvoice: noop,
  evaluateInvoice: noop,
  invoiceIsLoading: false,
  addInvoiceComment: noop,
  updateInvoiceInfo: noop,
  updateInvoiceLines: noop,
  updateInvoiceMarkups: noop,
  createInvoiceLinesFromTransactions: noop,
  syncTransactions: noop,
});

export const useInvoice = <Fields extends (keyof InvoiceGetResponse)[]>(
  fields?: Fields
) =>
  useContextSelector(InvoiceContext, (context) => {
    if (!fields) return context.invoice;

    const invoice = {} as Pick<InvoiceGetResponse, Fields[number]>;

    for (const field of fields) {
      /**
       * @todo adjust typescript type
       */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      invoice[field] = context.invoice[field];
    }

    return invoice;
  });

export const useInvoicePermissions = () =>
  useContextSelector(InvoiceContext, (context) => context.permissions);

export const useInvoiceLines = (
  options: { withTransactionLineUrl?: boolean } = {
    withTransactionLineUrl: true,
  }
) =>
  useContextSelector(InvoiceContext, (context) => {
    let lines = context.invoiceLines;

    if (!options.withTransactionLineUrl) {
      lines = lines.map((invoiceLine) => ({
        ...invoiceLine,
        transactionLine:
          invoiceLine.transactionLine &&
          omit(invoiceLine.transactionLine, ["url"]),
      }));
    }

    return lines;
  });

export const useInvoiceLinesSelected = () =>
  useContextSelector(InvoiceContext, (context) => context.invoiceLinesSelected);

export const useInvoiceMarkups = () =>
  useContextSelector(InvoiceContext, (context) => context.invoiceMarkups);

export const useInvoiceIsLoading = () =>
  useContextSelector(InvoiceContext, (context) => context.invoiceIsLoading);

export const useInvoiceLinesIsLoading = () =>
  useContextSelector(
    InvoiceContext,
    (context) => context.invoiceLinesIsLoading
  );

export const useInvoiceMarkupsIsLoading = () =>
  useContextSelector(
    InvoiceContext,
    (context) => context.invoiceMarkupsIsLoading
  );

export const useInvoiceActions = () => ({
  refetchInvoice: useContextSelector(
    InvoiceContext,
    (context) => context.refetchInvoice
  ),
  updateInvoice: useContextSelector(
    InvoiceContext,
    (context) => context.updateInvoice
  ),
  updateInvoiceInfo: useContextSelector(
    InvoiceContext,
    (context) => context.updateInvoiceInfo
  ),
  deleteInvoice: useContextSelector(
    InvoiceContext,
    (context) => context.deleteInvoice
  ),
  evaluateInvoice: useContextSelector(
    InvoiceContext,
    (context) => context.evaluateInvoice
  ),
  addInvoiceComment: useContextSelector(
    InvoiceContext,
    (context) => context.addInvoiceComment
  ),
  updateInvoiceLines: useContextSelector(
    InvoiceContext,
    (context) => context.updateInvoiceLines
  ),
  updateInvoiceMarkups: useContextSelector(
    InvoiceContext,
    (context) => context.updateInvoiceMarkups
  ),
  createInvoiceLinesFromTransactions: useContextSelector(
    InvoiceContext,
    (context) => context.createInvoiceLinesFromTransactions
  ),
  syncTransactions: useContextSelector(
    InvoiceContext,
    (context) => context.syncTransactions
  ),
  setInvoiceLinesSelected: useContextSelector(
    InvoiceContext,
    (context) => context.setInvoiceLinesSelected
  ),
});
