/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React from "react";
import { useSelector } from "react-redux";
import { ComboBox as BaseComboBox } from "@adaptive/design-system";
import { isEqual } from "@adaptive/design-system/utils";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { type Option } from "@shared/types/objects";
import { asOption } from "@utils/converters/utils";

import type { RootState } from "../../shared/store/types";
import {
  stringExistsWithMessage,
  useCustomValidationComponent,
} from "../../shared/utils/validator";
import { STRINGS } from "../stages/constants";

import type { SelectProps } from "./types";

const defaultValidator = stringExistsWithMessage(STRINGS.ERROR_PMT_ACCT);

export const PaymentAccountComboBox = ({
  validator = defaultValidator,
  selector,
  onChange,
  ...props
}: SelectProps) => {
  const { data, status } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const value: Option | undefined = useSelector<RootState>((s) => {
    //should be a referenceNode
    const value = selector?.(s);
    if (!value) {
      return;
    }

    return asOption<string>(value);
  }, isEqual);

  const ComboBox = useCustomValidationComponent(BaseComboBox, validator);

  return (
    <ComboBox
      data={data}
      value={value || ""}
      onChange={(value, option) => {
        onChange?.(value, option);
      }}
      name="payment-account"
      label={STRINGS.LABEL_PMT_ACCT}
      loading={status === "loading"}
      hintMessage={STRINGS.TOOLTIP_PMT_ACCT}
      placeholder={STRINGS.PLACEHOLDER_PMT_ACCT}
      size="md"
      data-testid="payment-account-combobox"
      {...props}
    />
  );
};
