import { type OBJECT_ORIGIN } from "../constants";
import { api } from "../utils/api";

import type { AccountBalanceV1 } from "./bank-accounts";

type StoreBillPaymentProps = {
  client: string;
  linked_transaction: string;
  realm: string;
  vendor: string;
  account: null;
  total_amount: string;
  account_balance: string;
  internal_method: string;
};

type StoreBillPaymentResponse = {
  id: string;
};

type CancelBillPaymentResponse = {
  id: string;
  account: null;
  account_balance: AccountBalanceV1;
  internal_method: string;
  checks: [];
  created_at: string;
  external_method: null;
  lines: [];
  status: string;
  total_amount: string;
  vendor: string;
  vendor_address: string | null;
  vendor_banking_ach: string | null;
  vendor_email: string;
  vendor_phone_number: string | null;
};

export type BillPayment = {
  id: string;
  url: string;
  doc_number: string | null;
  account: null;
  account_balance: AccountBalanceV1;
  internal_method: string | null;
  checks: Check[];
  true_created_at: string;
  transaction_date: string;
  external_method: null;
  lines: BillPaymentLine[];
  status: string;
  total_amount: string;
  original_total_amount: string;
  vendor: {
    url: string;
    display_name: string;
    email: string;
  } | null;
  vendor_address: string | null;
  vendor_banking_ach: {
    account_number: string;
    id: string;
    routing_number: string;
    string: string;
  } | null;
  vendor_email: string | null;
  vendor_phone_number: string | null;
  amount_for_bill: string;
  is_voided: boolean;
  parent: {
    id: string;
    date: string;
    doc_number: string;
    human_readable_type: string;
  };
  card_transaction: {
    id: string;
    date: string;
    name: string;
    amount: string;
    display_name: string | null;
    url: string;
    card: {
      user: {
        id: string;
        url: string;
        type: string;
        email: string;
        full_name: string;
      };
    };
    paymentAccount: {
      id: string;
      url: string;
      display_name: string;
    } | null;
    pending: boolean;
    description: string | null;
    mask: string | null;
    plaid_account_owner: {
      id: string;
      url: string;
      account_owner: string;
    } | null;
  };
  created_in: (typeof OBJECT_ORIGIN)[keyof typeof OBJECT_ORIGIN];
};

export type BillPaymentLine = {
  links: BillPaymentLineLink[];
};

export type BillPaymentLineLink = {
  linked_transaction: { id: string };
  amount: string;
};

export type Check = {
  id: string;
  check_number: number;
  created_at: string;
};

export const storeBillPayment = (payload: StoreBillPaymentProps) =>
  api
    .post<StoreBillPaymentResponse>("/api/billpayments/", payload)
    .then((response) => response.data);

export const cancelBillPayment = (paymentId: string, billId: number) =>
  api
    .put<CancelBillPaymentResponse>(`/api/billpayments/${paymentId}/cancel/`, {
      bill_id: billId,
    })
    .then((response) => response.data);
