import { useEffect } from "react";
import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetVendorsSimplifiedQuery } from "@store/api-simplified";
import { useAppSelector } from "@store/hooks";
import { useClientInfo } from "@store/user";
import { useVendorAction } from "@store/vendors";

export type UseVendorsSimplifiedProps = {
  enabled?: boolean;
  includeLabel?: boolean;
};

export const useVendorsSimplified = ({
  enabled = true,
  includeLabel = false,
}: UseVendorsSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const { markRefreshHandled } = useVendorAction();
  const needsRefresh = useAppSelector((state) => state.vendors.needsRefresh);

  const query = useGetVendorsSimplifiedQuery(
    { realm: realmId as number },
    { skip: !enabled || !realmId }
  );

  useEffect(() => {
    if (needsRefresh && query.isSuccess) {
      query.refetch();
      markRefreshHandled();
    }
  }, [needsRefresh, markRefreshHandled, query]);

  return useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Vendor" : undefined,
  });
};
