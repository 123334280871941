/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useLayoutEffect, useRef } from "react";

/**
 * A Hook to define an event handler with an always-stable function identity.
 *
 * @todo Replace it as soon as React add it to userland https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export const useEvent = <T extends (...args: any[]) => any>(handler: T) => {
  const handlerRef = useRef<T>();

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any[]) => {
    const fn = handlerRef.current;

    return fn?.(...args);
  }, []) as T;
};
