import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { Options, Placement, Type } from "@zag-js/toast";
import mitt from "mitt";

import { type Text } from "../text";

export type ToastConfig = {
  onClose?: () => void;
  maxWidth?: string | number;
  duration?: number;
  truncate?: ComponentPropsWithoutRef<typeof Text>["truncate"];
};

export type InternalToastConfig = Omit<ToastConfig, "duration"> & {
  message: ReactNode;
};

export type ToastEmitterConfig = Options<string> & {
  render: () => InternalToastConfig;
  duration: number;
  placement: Placement;
  removeDelay: number;
};

export const emitter = mitt<{
  create: ToastEmitterConfig;
  dismiss: string;
  update: ToastEmitterConfig;
}>();

const DURATION = 5000;

const generateId = () => String(Date.now() + Math.floor(Math.random() * 100));

const enhanceConfig = (
  message: ReactNode,
  {
    type,
    onClose,
    duration,
    ...config
  }: ToastConfig & { id: string; type: Type }
) =>
  ({
    ...config,
    type,
    render: () => ({ ...config, message }),
    onStatusChange({ status }) {
      if (status === "dismissing") {
        onClose?.();
      }
    },
    duration: type === "loading" ? Infinity : (duration ?? DURATION),
    placement: "top-end",
    removeDelay: 1000,
  }) as ToastEmitterConfig;

const createToast =
  (type: Type) =>
  (message: ReactNode, config: ToastConfig = {}) => {
    const id = generateId();

    emitter.emit("create", enhanceConfig(message, { id, type, ...config }));

    return {
      dismiss: () => emitter.emit("dismiss", id),
      update: (
        message: ReactNode,
        config: ToastConfig & { type?: Type } = {}
      ) =>
        emitter.emit("update", enhanceConfig(message, { id, type, ...config })),
    };
  };

export const toast = {
  info: createToast("info"),
  error: createToast("error"),
  warning: createToast("custom"),
  loading: createToast("loading"),
  success: createToast("success"),
};
