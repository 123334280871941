import React, { type CSSProperties, type ReactNode } from "react";
import { Button, ComboBox, Flex, Icon, Text } from "@adaptive/design-system";
import { useEvent, type UseFormReturn } from "@adaptive/design-system/hooks";
import { ApproversCombobox } from "@components/approvers-combobox";
import { Circle } from "@components/number/circle";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import cn from "clsx";

import type { Steps, StepTypes } from "./form";
import styles from "./form.module.css";

const TYPES = [
  { label: "Require all", value: "ALL_OF" },
  { label: "Require either", value: "ONE_OF" },
];

const reorderSteps = <List extends unknown[]>(
  list: List,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export type WorkflowStepsProps = {
  form: UseFormReturn<{ steps: Steps }>;
  title: ReactNode;
  subtitle?: ReactNode;
};

export const WorkflowSteps = ({
  form,
  title,
  subtitle,
}: WorkflowStepsProps) => {
  const onAddSteps = useEvent(() => {
    form.append("steps", {
      requirementType: "ALL_OF" as StepTypes,
      approvers: [],
    });
  });

  const curriedOnRemoveLine = (index: number) => () => {
    form.remove("steps", index);
  };

  const onDragEnd = useEvent((result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const steps = reorderSteps(
      form.values.steps,
      result.source.index,
      result.destination.index
    );

    form.setValue("steps", steps);
  });

  return (
    <Flex direction="column" gap="xl" shrink={false}>
      <Flex direction="column">
        <Text size="md" weight="bolder">
          {title}
        </Text>
        {subtitle && (
          <Text size="sm" color="neutral-700">
            {subtitle}
          </Text>
        )}
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="steps">
          {(droppable, snapshot) => (
            <Flex
              ref={droppable.innerRef}
              direction="column"
              className={cn(styles["list"], {
                [styles["-dragging"]]: snapshot.draggingOverWith,
              })}
              {...droppable.droppableProps}
            >
              {form.values.steps.map((_: unknown, index: number) => {
                const isDragDisabled = form.values.steps.length === 1;

                return (
                  <Draggable
                    key={`step-${index}`}
                    index={index}
                    draggableId={`step-${index}`}
                    isDragDisabled={isDragDisabled}
                  >
                    {(
                      {
                        draggableProps: { style, ...draggableProps },
                        ...draggable
                      },
                      snapshot
                    ) => (
                      <Flex
                        key={index}
                        ref={draggable.innerRef}
                        className={cn(styles["item"], {
                          [styles["-draggable"]]: !isDragDisabled,
                          [styles["-dragging"]]: snapshot.isDragging,
                        })}
                        style={style as CSSProperties}
                        {...draggableProps}
                      >
                        <Flex gap="md" align="center" width="full">
                          {!isDragDisabled && (
                            <Icon
                              name="grip-dots-vertical"
                              variant="solid"
                              className={styles["draggable"]}
                              {...draggable.dragHandleProps}
                            />
                          )}
                          <Circle className={styles["circle"]}>
                            {index + 1}
                          </Circle>
                          <Flex width="175px" shrink={false}>
                            <ComboBox
                              data={TYPES}
                              messageVariant="hidden"
                              required
                              {...form.register(
                                `steps.${index}.requirementType`
                              )}
                            />
                          </Flex>
                          <Flex width="full">
                            <ApproversCombobox
                              required
                              messageVariant="hidden"
                              {...form.register({
                                name: `steps.${index}.approvers`,
                                type: "multiple-select",
                              })}
                            />
                          </Flex>
                          <Button
                            variant="ghost"
                            color="neutral"
                            aria-label="Remove step"
                            onClick={curriedOnRemoveLine(index)}
                            disabled={form.values.steps.length === 1}
                          >
                            <Icon name="trash" />
                          </Button>
                        </Flex>
                      </Flex>
                    )}
                  </Draggable>
                );
              })}
              {droppable.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
      <Flex>
        <Button variant="ghost" size="sm" onClick={onAddSteps}>
          Add step
        </Button>
      </Flex>
    </Flex>
  );
};
