import React, { useMemo, useState } from "react";
import { is } from "@adaptive/design-system/utils";
import { DisclosureProvider, useDisclosureStore } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { useAlertContext } from "../alert/alert-context";
import { Flex } from "../flex";

import { CardContext } from "./card-context";
import styles from "./card.module.css";

type Props = {
  size?: ResponsiveProp<"sm" | "md">;
  color?: "neutral" | "success";
  expanded?: boolean;
  onExpand?: (expanded: boolean) => void;
};

const DEFAULT_COMPONENT = "section";

export const Card = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      size: rawSize,
      color = "neutral",
      children,
      expanded,
      onExpand,
      className,
      ...props
    },
    ref
  ) => {
    const [hasHeader, setHasHeader] = useState(false);

    const size = useResponsiveProp(rawSize, "md");

    const disclosureStore = useDisclosureStore({
      open: expanded,
      setOpen: onExpand,
    });

    const isFlex = useMemo<boolean>(() => {
      return Component && !is.string(Component) && Component === Flex;
    }, [Component]);

    /**
     * If the card is children of an alert, we want to
     * adjust some colors to have a better contrast ratio
     */
    const alertContext = useAlertContext();

    return (
      <DisclosureProvider store={disclosureStore}>
        <Component
          ref={ref}
          className={cn(styles["card"], className, {
            [styles[`-${size}`]]: size,
            [styles[`-${color}`]]: color,
            [styles[`-has-header`]]: hasHeader,
            [styles[`-${size}`]]: size,
            [styles["-flex"]]: isFlex,
            [styles[`-alert-child`]]: alertContext !== undefined,
          })}
          {...props}
        >
          <CardContext.Provider value={{ hasHeader, setHasHeader }}>
            {children}
          </CardContext.Provider>
        </Component>
      </DisclosureProvider>
    );
  }
);

Card.displayName = "Card";
