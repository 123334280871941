import {
  getApi,
  getUrlBuilder,
  invalidate5xx,
  transformPayloads,
} from "@utils/api";
import { type AxiosResponse } from "axios";
import { type z } from "zod";

import { type Identifiable } from "../expenses";

import { vendorCreatePayload, vendorPutPayload } from "./request/schema";
import { vendorPartialSchema } from "./response/schema";
import { getBadRequestSchema } from "./response/schema-error";
import type { OutgoingVendorPayload, Vendor } from "./types";

type VendorWithRealm = Vendor & { realm: string };

const urlBuilder = getUrlBuilder("/api/vendors/");

export const api = getApi([invalidate5xx, transformPayloads]);

export const handleResponse = <T extends z.ZodSchema>(
  resp: AxiosResponse,
  schema: T
): z.infer<T> => {
  if (resp.status === 400) {
    const badRequestSchema = getBadRequestSchema(schema as any);
    const parsed = badRequestSchema.parse(resp.data);
    throw parsed;
  }

  return schema.parse(resp.data);
};

export const get = async (opts: Identifiable) => {
  const resp = await api.get<Vendor>(urlBuilder.forItem(opts));
  return handleResponse(resp, vendorPartialSchema);
};

export const put = async (vendor: VendorWithRealm) => {
  const payload = vendorPutPayload.parse(vendor);

  const resp = await api.put<OutgoingVendorPayload>(
    urlBuilder.forItem(vendor),
    payload
  );

  return handleResponse(resp, vendorPartialSchema);
};

export const create = async (vendor: VendorWithRealm) => {
  const payload = vendorCreatePayload.parse(vendor);

  const resp = await api.post<OutgoingVendorPayload>(
    urlBuilder.forCollection(),
    payload
  );

  return handleResponse(resp, vendorPartialSchema);
};

export const vendorApi = {
  get,
  put,
  create,
};
