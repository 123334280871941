/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import type { Option, ReferenceNode } from "@shared/types/objects";

import { parseRefinementIdFromUrl } from "../parse-refinement-id-from-url";

export const asOption = <T>(data: ReferenceNode): Option<T> => {
  return {
    label: data?.displayName,
    value: data?.url,
    groupLabel: data?.groupLabel,
    id: data?.id,
  };
};

export const asReferenceNode = (
  option: Option | undefined | null
): ReferenceNode | null => {
  const id = option?.id || parseRefinementIdFromUrl(option?.value || "");
  const email = option?.email || "";

  return option
    ? {
        displayName: option?.label || "",
        url: option?.value || "",
        groupLabel: option?.groupLabel || "",
        id: id || "",
        ...(email ? { email } : {}),
      }
    : null;
};
