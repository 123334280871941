import React, { useMemo } from "react";
import { Button, Flex, Tooltip } from "@adaptive/design-system";
import { MainHeader, MainTitle } from "@components/main";
import { useDrawerVisibility } from "@store/ui";
import { BasePermissions, useUserInfo } from "@store/user";

export const Heading = () => {
  const { setVisible } = useDrawerVisibility("job");
  const { hasPermission } = useUserInfo();

  const canAddJobs = useMemo(
    () => hasPermission(BasePermissions.MANAGE_JOBS),
    [hasPermission]
  );

  return (
    <MainHeader>
      <Flex height="full" align="center" gap="xl">
        <Flex direction="column" grow>
          <MainTitle>Jobs</MainTitle>
        </Flex>
        <Tooltip
          message={!canAddJobs ? "You don't have permission to do this" : ""}
          placement="left"
        >
          <Button
            onClick={() => {
              setVisible(true);
            }}
            disabled={!canAddJobs}
          >
            New job
          </Button>
        </Tooltip>
      </Flex>
    </MainHeader>
  );
};
