import React, { Fragment } from "react";
import { Alert, AlertContent, AlertTitle, Link } from "@adaptive/design-system";
import { capitalize } from "@utils/capitalize";

import { generateUrlByHumanReadableType } from "../utils/generate-url-by-human-readable-type";

type Transaction = {
  id: string | number;
  humanReadableType: "Bill" | "Expense" | "Receipt" | "Vendor Credit" | "Labor";
};

type DuplicateAlertProps = { data: Transaction[] };

const HUMAN_READABLE_TYPE_TO_OBJECT_TYPE: Record<
  Transaction["humanReadableType"],
  string
> = {
  Bill: "bill",
  Expense: "receipt",
  Receipt: "receipt",
  "Vendor Credit": "bill",
  Labor: "receipt",
};

export const DuplicateAlert = ({ data }: DuplicateAlertProps) => {
  const isSingle = data.length === 1;
  const objectType =
    HUMAN_READABLE_TYPE_TO_OBJECT_TYPE[data[0].humanReadableType];

  return (
    <Alert variant="warning">
      <AlertTitle>Possible {objectType} duplication</AlertTitle>
      <AlertContent>
        {isSingle ? (
          <>
            <Link
              rel="noreferrer"
              href={generateUrlByHumanReadableType(data[0])}
              target="_blank"
            >
              We found this {objectType}
            </Link>{" "}
            with the same vendor name and ref number.
          </>
        ) : (
          <>
            We found multiple {objectType}s with the same vendor name and ref
            number:{" "}
            {data.map((item, i) => (
              <Fragment key={item.id}>
                {i > 0 && ", "}
                <Link
                  rel="noreferrer"
                  href={generateUrlByHumanReadableType(item)}
                  target="_blank"
                >
                  {capitalize(objectType)} #{i + 1}
                </Link>
              </Fragment>
            ))}
          </>
        )}
      </AlertContent>
    </Alert>
  );
};
