import { formatNumber } from "../format-number";

export const formatPhone = (value: string | number | null) => {
  if (value === null) return "";

  return formatNumber(value)
    .split("")
    .reduce((acc, char, index) => {
      const isOpenParenthesis = index === 0;
      const isClosedParenthesis = index === 2;
      const isHyphen = index === 5;

      if (isOpenParenthesis) return `(${acc}${char}`;

      if (isClosedParenthesis) return `${acc}${char}) `;

      if (isHyphen) return `${acc}${char}-`;

      return `${acc}${char}`;
    }, "")
    .substring(0, 14);
};
