import React, { type ComponentProps, type ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@adaptive/design-system";
import { type UseDialogReturn, useEvent } from "@adaptive/design-system/hooks";

interface Props {
  title: ReactNode;
  message: ReactNode;
  dialog: Omit<UseDialogReturn, "show">;
  onConfirm: () => void;
  onDeny?: () => void;
  confirmButtonColor?: ComponentProps<typeof Button>["color"];
  confirmButtonText?: string;
}

export const ConfirmationDialog = ({
  title,
  message,
  dialog,
  onConfirm,
  onDeny,
  confirmButtonColor,
  confirmButtonText,
}: Props) => {
  const enhancedOnClose = useEvent(() => {
    dialog.hide();
    onDeny?.();
  });

  return (
    <Dialog
      variant="confirmation"
      show={dialog.isVisible}
      onClose={enhancedOnClose}
    >
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{message}</DialogContent>
      <DialogFooter>
        <Button variant="text" color="neutral" onClick={enhancedOnClose} block>
          Cancel
        </Button>
        <Button
          color={confirmButtonColor || "primary"}
          onClick={onConfirm}
          block
        >
          {confirmButtonText || "Confirm"}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
