import { currencySchema, idSchema } from "@shared/utils/schema";
import { z } from "zod";

export const markupTypeSchema = z.enum(["percentage", "fixed_amount"]);

export const markupSchema = z.object({
  id: z.number(),
  value: z.number().nullish(),
  ownersValue: z.number().nullish(),
  markupType: markupTypeSchema,
  customer: z.number(),
  item: idSchema.nullish(),
  account: idSchema.nullish(),
  isSeparateLine: z.boolean(),
});

export const markupFixedFeeSchema = markupSchema.omit({ id: true });

export const markupFixedFeeEditSchema = z.object({
  item: idSchema.nullish(),
  account: idSchema.nullish(),
  id: idSchema,
  value: currencySchema.nullish(),
  ownersValue: currencySchema.nullish(),
  customer: z.number(),
});

const budgetLineSchema = z.string().uuid();

export const markupBudgetLinePayloadSchema = z.object({
  id: idSchema.optional(),
  value: z.number().optional(),
  ownersValue: z.number().optional(),
  markupType: markupTypeSchema,
  customer: z.string().url().nullish(),
  budgetLineIds: z.array(budgetLineSchema),
  item: z.string().url().nullish(),
  account: z.string().url().nullish(),
  isSeparateLine: z.boolean(),
});

export const customerItemRequestSchema = z.object({
  customerId: z.number(),
});

export const budgetLinesBasePayloadSchema = z.object({
  customerId: z.number(),
});

export const budgetLinesGetPayloadSchema = budgetLinesBasePayloadSchema.extend({
  markups: z.boolean().optional(),
  params: z
    .object({
      dataIndex: z.string(),
      value: z.boolean().or(z.string()).or(z.number()).nullish(),
    })
    .array()
    .nullish(),
});

export const budgetLinesDeleteSchema = budgetLinesBasePayloadSchema.extend({
  budgetLineId: idSchema,
});

export const budgetLinesBulkCreateSchema = budgetLinesBasePayloadSchema.extend({
  budgetLines: z.array(
    z.object({
      itemId: idSchema.optional(),
      accountId: idSchema.optional(),
      builderAmount: currencySchema.optional(),
      ownersAmount: currencySchema.optional(),
      categoryDisplayName: z.string().optional(),
      changeAmount: currencySchema.optional(),
    })
  ),
});

export const markupsForCustomerGetPayload = z.object({
  customer: z.number(),
  distinct: z.boolean().optional(),
  isSeparateLine: z.boolean().optional(),
  orderBy: z.enum(["markup_type"]).optional(),
});

export const modifyBudgetLineSchema = z.object({
  id: idSchema,
  category: idSchema.nullish(),
  ownersAmount: currencySchema.nullish(),
  builderAmount: currencySchema.nullish(),
  builderRevisedAmount: currencySchema.nullish(),
});

export const budgetLinesBulkUpdateSchema = budgetLinesBasePayloadSchema.extend({
  budgetLines: z.array(modifyBudgetLineSchema),
});

export const budgetLineUpdateRequestPayloadSchema = modifyBudgetLineSchema
  .omit({ id: true })
  .extend({
    budgetLineId: idSchema,
    customerId: idSchema,
    markups: z
      .array(
        markupSchema.extend({
          id: idSchema.optional(),
          item: z.string().url().optional(),
        })
      )
      .optional(),
  });
