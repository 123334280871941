import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
} from "react";
import cn from "clsx";

import styles from "./progress-bar.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  value?: number;
  color?: "success" | "error" | "warning";
};

export const ProgressBar = forwardRef<ComponentRef<DefaultComponent>, Props>(
  ({ style, value, color = "success", className, ...props }, ref) => (
    <div
      ref={ref}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
      className={cn(className, styles["progress-bar"], {
        [styles[`-${color}`]]: color,
      })}
      style={{
        ...style,
        "--progress-bar-value": `${value}%`,
      }}
      {...props}
    />
  )
);

ProgressBar.displayName = "ProgressBar";
