export const PO_SIGNATURE_STATUSES = {
  SIGNED: "SIGNED",
  REQUEST_SENT: "REQUEST_SENT",
  REQUEST_EXPIRED: "REQUEST_EXPIRED",
  UNSIGNED: "UNSIGNED",
} as const;

export const HUMAN_READABLE_PO_SIGNATURE_STATUS = {
  SIGNED: "Signed",
  REQUEST_SENT: "Request sent",
  REQUEST_EXPIRED: "Request expired",
};

export const PO_SIGNATURE_STATUS_OPTIONS = [
  {
    label: "Sent for signature",
    value: PO_SIGNATURE_STATUSES.REQUEST_SENT,
    groupLabel: "Signature",
  },
  {
    label: "Signed",
    value: PO_SIGNATURE_STATUSES.SIGNED,
    groupLabel: "Signature",
  },
  {
    label: "Unsigned",
    value: PO_SIGNATURE_STATUSES.UNSIGNED,
    groupLabel: "Signature",
  },
];

export const TYPE_STATUSES = [
  {
    label: "QuickBooks",
    value: "qb",
  },
  {
    label: "Adaptive",
    value: "adaptive",
  },
].map((item) => ({ ...item, groupLabel: "PO type" }));
