import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Dialog,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  usePagination,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  exportPurchaseOrder,
  useConvertToAdaptiveMutation,
} from "@api/purchase-orders";
import { AdaptiveIcon } from "@components/adaptive-icon";
import { ErrorAlert } from "@components/common/error-alert";
import { DownloadButton } from "@components/download-button";
import { QuickBooksIcon } from "@components/quickbooks-icon";
import {
  MarkAsSignedDialog,
  RequestedVendorPoSignatureAlert,
  RequestVendorPoSignature,
} from "@components/request-vendor-po-signature";
import { useCurrentClientFromRealm } from "@hooks/useCurrentClientFromRealm";
import { usePurchaseOrderBills } from "@hooks/usePurchaseOrderBills";
import {
  setNeedsRefresh,
  updatePurchaseOrderList,
} from "@store/purchaseOrderListSlice";
import {
  loadPurchaseOrder,
  putPurchaseOrder,
  removePurchaseOrder,
  resetPurchaseOrder,
} from "@store/purchaseOrderSlice";
import { usePurchaseOrderDrawer } from "@store/ui";
import { useClientInfo, useClientSettings } from "@store/user";
import { BasePermissions, useUserInfo } from "@store/user";
import { humanReadableBillReviewStatus } from "@utils/usefulFunctions";

import { PurchaseOrderFormContent } from "./purchase-order-form-content";
import { PurchaseOrderFormHeader } from "./purchase-order-form-header";
import { PurchaseOrderLearnMoreLink } from "./purchase-order-learn-more-link";
import {
  selectPurchaseOrder,
  selectPurchaseOrderIsDirty,
  selectPurchaseOrderIsLoading,
  selectPurchaseOrderType,
} from "./purchase-orders-selectors";
import { promptConversionToast, promptConversionWarning } from "./utils";

const BILLS_COLUMNS = [
  {
    id: "type",
    name: "Type",
    render: (row) => (
      <Link
        rel="noreferrer"
        href={`/bills/${row.id}/`}
        target="_blank"
        variant="success"
      >
        <Text as="span" size="sm">
          Bill
        </Text>
      </Link>
    ),
  },
  {
    id: "status",
    name: "Status",
    render: (row) => (
      <Flex gap="md" align="center">
        <Text weight="bold">
          {humanReadableBillReviewStatus(row.review_status)}
        </Text>
        {(row.review_status === "DRAFT" || row.review_status == "APPROVAL") && (
          <Tooltip
            as={Icon}
            size="sm"
            name="info-circle"
            message="This bill will not impact this purchase order's balance until it has been approved"
          />
        )}
      </Flex>
    ),
  },
  {
    id: "date",
    name: "Date",
    render: (row) => formatDate(parseDate(row.date, "yyyy-MM-dd")),
  },
  {
    id: "amount",
    name: "Amount",
    render: (row) => formatCurrency(row.total_amount),
  },
];

const PurchaseOrderTypeAlert = () => {
  const type = useSelector(selectPurchaseOrderType);

  const settings = useClientSettings();

  if (!settings.adaptivePosEnabled) return null;

  if (type === "adaptive") {
    return (
      <Alert
        variant={{
          icon: <AdaptiveIcon variant="dark-circle" height={24} width={24} />,
          background: "brand-1",
        }}
      >
        <AlertTitle>This is an Adaptive purchase order</AlertTitle>
        <AlertContent as={Flex} gap="md" direction="column">
          <Text>It does not sync with QuickBooks.</Text>
          <Flex>
            <PurchaseOrderLearnMoreLink />
          </Flex>
        </AlertContent>
      </Alert>
    );
  }

  if (type === "qb") {
    return (
      <Alert
        variant={{
          icon: <QuickBooksIcon size={24} />,
          background: "success-100",
        }}
      >
        <AlertTitle>This is a QuickBooks purchase order</AlertTitle>
        <AlertContent as={Flex} gap="md" direction="column">
          <Text>
            You may not edit this purchase order or any of its linked bills in
            Adaptive.
            <br />
            Convert it to an Adaptive purchase order for full edit capabilities.
          </Text>
          <Flex margin={["none", "none", "sm"]}>
            <PurchaseOrderLearnMoreLink />
          </Flex>
          <Flex width="184px">
            <Button size="sm" block type="submit" form="purchase-order">
              Convert to Adaptive PO
            </Button>
          </Flex>
        </AlertContent>
      </Alert>
    );
  }

  return null;
};

export const PurchaseOrderForm = memo(() => {
  const { state, hide, isVisible } = usePurchaseOrderDrawer();

  const settings = useClientSettings();

  const isCreate = state.id === "new";

  const purchaseOrderId = isCreate ? null : state.id;

  const billsDialog = useDialog({ lazy: true });

  const markAsSignedDialog = useDialog({ lazy: true });

  const billsPagination = usePagination();

  const bills = usePurchaseOrderBills({
    limit: billsPagination.perPage,
    offset: billsPagination.offset,
    purchaseOrderId,
  });

  const dispatch = useDispatch();

  const { realm } = useClientInfo();

  const [status, setStatus] = useState("fulfilled");

  const { hasPermission, hasSomePermission } = useUserInfo();

  const purchaseOrder = useSelector(selectPurchaseOrder);

  const isLoading = useSelector(selectPurchaseOrderIsLoading);

  useCurrentClientFromRealm(purchaseOrder?.realm);

  const isDirty = useSelector(selectPurchaseOrderIsDirty);

  const enhancedStatus =
    status !== "rejected" && isLoading ? "pending" : status;

  const permissions = useMemo(() => {
    const canEdit = purchaseOrder.id
      ? (hasPermission(BasePermissions.ADD_PO) && purchaseOrder.is_creator) ||
        hasPermission(BasePermissions.MANAGE_POS)
      : hasSomePermission([BasePermissions.ADD_PO, BasePermissions.MANAGE_POS]);

    const canComment = hasPermission(BasePermissions.COMMENT_PO);

    const isQbPo = purchaseOrder.type === "qb" && settings.adaptivePosEnabled;

    return { isQbPo, canEdit, canComment };
  }, [
    hasPermission,
    purchaseOrder.id,
    hasSomePermission,
    purchaseOrder.type,
    purchaseOrder.is_creator,
    settings.adaptivePosEnabled,
  ]);

  const navigate = useNavigate();

  const [convertToAdaptive] = useConvertToAdaptiveMutation();

  const showVendorSignatureRequest =
    window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED &&
    purchaseOrder.vendor &&
    !purchaseOrder.is_signed;

  const onDelete = useEvent(() => {
    const id = dialog.confirmation({
      title: (
        <>
          Are you sure you want to <br />
          delete this purchase order?
        </>
      ),
      message: "You can't undo this action.",
      action: {
        primary: {
          color: "error",
          onClick: async () => {
            toast.success("Purchase order deleted");

            await Promise.all([
              dispatch(removePurchaseOrder()),
              dialog.hide(id),
              hide(true),
            ]);

            if (state.caller === "purchase-orders-page") {
              navigate("/purchase-orders");
            }
          },
          autoHide: false,
          children: "Delete",
        },
      },
    });
  });

  const onDownload = useEvent(
    async ({ params }) => await exportPurchaseOrder(purchaseOrder, params)
  );

  const onSave = useEvent(async () => {
    if (permissions.isQbPo) {
      const handle = async () => {
        try {
          setStatus("pending");
          if (state.beforeConversion) await state.beforeConversion();
          const { id } = await convertToAdaptive(purchaseOrderId).unwrap();
          promptConversionToast();
          if (state.caller === "purchase-orders-page") {
            navigate(`/purchase-orders/${id}`, { replace: true });
          } else {
            dispatch(loadPurchaseOrder(id));
          }
          dispatch(setNeedsRefresh({ status: "open", refresh: true }));
          dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
        } catch (e) {
          handleErrors(e);
        } finally {
          setStatus("fulfilled");
        }
      };

      promptConversionWarning({ onConfirm: handle });
    } else {
      try {
        setStatus("pending");
        const { id } = await dispatch(putPurchaseOrder());
        if (!purchaseOrderId && state.caller === "purchase-orders-page") {
          navigate(`/purchase-orders/${id}`);
        }
      } finally {
        setStatus("fulfilled");
      }
    }
  });

  const refetchPurchaseOrder = useCallback(
    () =>
      purchaseOrderId
        ? dispatch(loadPurchaseOrder(purchaseOrderId))
        : Promise.resolve(),
    [dispatch, purchaseOrderId]
  );

  const onSubmitRequestPo = useEvent(async () => {
    refetchPurchaseOrder();
    dispatch(updatePurchaseOrderList());
  });

  const onMarkAsSigned = useEvent(async () => {
    markAsSignedDialog.show();
  });

  useEffect(() => {
    if (purchaseOrderId) {
      setStatus("pending");
      refetchPurchaseOrder()
        .then(() => setStatus("fulfilled"))
        .catch(() => setStatus("rejected"));
    }
  }, [refetchPurchaseOrder, purchaseOrderId]);

  useEffect(() => {
    if (isCreate) {
      dispatch(resetPurchaseOrder(realm));
    }
  }, [realm, isCreate, dispatch]);

  useEffect(() => {
    if (!isVisible) dispatch(resetPurchaseOrder());
  }, [isVisible, dispatch]);

  return (
    <>
      <DialogHeader>
        <PurchaseOrderFormHeader
          status={enhancedStatus}
          permissions={permissions}
        />
      </DialogHeader>
      <DialogContent>
        <Flex width="full" minWidth="950px">
          {enhancedStatus === "pending" ? <Loader /> : null}

          {enhancedStatus === "fulfilled" && (
            <Flex width="full" direction="column" gap="2xl">
              {(purchaseOrder.errors.length > 0 ||
                purchaseOrder.related_errors.length > 0) && (
                <ErrorAlert
                  data={purchaseOrder.errors}
                  relatedData={purchaseOrder.related_errors}
                  onChange={refetchPurchaseOrder}
                  objectType="PurchaseOrder"
                />
              )}
              {showVendorSignatureRequest &&
                purchaseOrder.latest_po_signature_request && (
                  <RequestedVendorPoSignatureAlert
                    purchaseOrder={purchaseOrder}
                    onSubmitRequestPo={onSubmitRequestPo}
                    onMarkAsSigned={onMarkAsSigned}
                    disabled={
                      isDirty ||
                      (!permissions.canEdit
                        ? "You don't have permission to do this"
                        : false)
                    }
                  />
                )}
              <PurchaseOrderTypeAlert />
              <PurchaseOrderFormContent
                onSave={onSave}
                permissions={permissions}
                linkedLines={state.linkedLines ?? []}
              />
            </Flex>
          )}

          {enhancedStatus === "rejected" &&
            "This purchase order was deleted or the address of the purchase order is incorrect."}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex gap="xl" width="full" justify="space-between">
          <Flex gap="xl">
            <Button
              size="lg"
              color="neutral"
              variant="text"
              onClick={() => hide()}
              data-testid="purchase-order-cancel"
            >
              Cancel
            </Button>
            {enhancedStatus === "fulfilled" && (
              <>
                {purchaseOrder.id && permissions.canEdit ? (
                  <Button
                    size="lg"
                    color="error"
                    variant="text"
                    onClick={onDelete}
                    data-testid="delete-purchase-order"
                  >
                    Delete
                  </Button>
                ) : null}
                {bills?.data?.count > 0 && (
                  <Button
                    size="lg"
                    color="neutral"
                    variant="text"
                    onClick={billsDialog.show}
                  >
                    View linked bills
                  </Button>
                )}
              </>
            )}
          </Flex>

          {enhancedStatus === "fulfilled" && (
            <Flex gap="xl">
              <Tooltip
                message={
                  (!purchaseOrder.id || isDirty) &&
                  "You must save the purchase order before you can download it"
                }
                placement="top"
              >
                <DownloadButton
                  mode={{
                    all: { enabled: true, children: "Download" },
                    selection: { enabled: false },
                  }}
                  type={[
                    { label: "Purchase order (PDF)", value: "export_pdf" },
                  ]}
                  onDownload={onDownload}
                  data-testid="download-purchase-order"
                  initialType={{ export_pdf: true }}
                  variant="ghost"
                  disabled={!purchaseOrder.id || isDirty}
                  size="lg"
                  autoRun
                />
              </Tooltip>
              {permissions.canEdit && (
                <>
                  {showVendorSignatureRequest && (
                    <Tooltip
                      message={
                        (!purchaseOrder.id || isDirty) &&
                        "You must save the purchase order before you can send the request"
                      }
                      placement="top"
                    >
                      <RequestVendorPoSignature
                        purchaseOrder={purchaseOrder}
                        onSubmitRequestPo={onSubmitRequestPo}
                        onMarkAsSigned={onMarkAsSigned}
                        disabled={!purchaseOrder.id || isDirty}
                      />
                    </Tooltip>
                  )}
                  {permissions.isQbPo ? (
                    <Button
                      size="lg"
                      type="submit"
                      form="purchase-order"
                      data-testid="save-purchase-order"
                    >
                      Convert to Adaptive PO
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      type="submit"
                      form="purchase-order"
                      data-testid="save-purchase-order"
                    >
                      Save
                    </Button>
                  )}
                </>
              )}
            </Flex>
          )}
        </Flex>
      </DialogFooter>

      {markAsSignedDialog.isRendered && (
        <MarkAsSignedDialog
          dialog={markAsSignedDialog}
          purchaseOrder={purchaseOrder}
          onSubmit={refetchPurchaseOrder}
          disabled={permissions.canEdit}
        />
      )}
      {billsDialog.isRendered && (
        <Dialog
          show={billsDialog.isVisible}
          variant="dialog"
          onClose={billsDialog.hide}
        >
          <DialogHeader>Linked transactions</DialogHeader>
          <DialogContent>
            <Table
              id="purchase-order-linked-transactions-table"
              size="sm"
              data={bills?.data?.results || []}
              columns={BILLS_COLUMNS}
              loading={bills.status === "loading"}
              maxHeight="600px"
              pagination={{
                page: billsPagination.page,
                total: bills?.data?.count ?? 0,
                perPage: billsPagination.perPage,
                onChange: billsPagination.setPage,
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

PurchaseOrderForm.displayName = "PurchaseOrderForm";
