import React from "react";
import { Outlet } from "react-router-dom";
import { Main } from "@components/main";

import { ListView } from "./list-view";
import { Heading } from "./";

export const JobsTablePage = () => (
  <Main>
    <Heading />
    <ListView />
  </Main>
);

export const Jobs = () => <Outlet />;
