import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useEvent } from "@adaptive/design-system/hooks";

/**
 * This is an workaround to be able to navigate outside the react tree
 */
export const Navigate = () => {
  const navigate = useNavigate();

  const onAppNavigate = useEvent((e: Event) => {
    if (!(e instanceof CustomEvent)) return;

    const payload = e.detail;

    if (payload && typeof payload === "object" && !Array.isArray(payload)) {
      const to = payload.to;

      if (!to) return;

      let enhancedTo = "";

      try {
        const url = new URL(to);

        if (url.host === window.location.host) {
          enhancedTo = url.pathname;
        } else if (payload.options && payload.options.replace) {
          window.location.replace(to);
        } else {
          window.location.href = to;
        }
      } catch {
        enhancedTo = to;
      }

      if (enhancedTo) navigate(enhancedTo, payload.options);
    }
  });

  useEffect(() => {
    window.addEventListener("app-navigate", onAppNavigate);

    return () => {
      window.removeEventListener("app-navigate", onAppNavigate);
    };
  }, [onAppNavigate]);

  return null;
};
