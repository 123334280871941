import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  Button,
  dialog,
  Flex,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { deleteVendorDocRequest } from "@api/vendor-requests";
import type { VendorDocRequestType } from "@api/vendors/types";
import { isListEmpty } from "@shared/utils/usefulFunctions";
import { useAppSelector } from "@store/hooks";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import { vendorSelector } from "@store/vendors/selectors";

import {
  DocumentSelectDialog,
  type DocumentSelectDialogProps,
  type DocumentSelectDialogStep,
  formatDocumentTypes,
  STRINGS,
} from ".";

export const RequestVendorDocumentButton = () => {
  const { fetchById } = useVendorAction();

  const { info: vendor } = useAppSelector(vendorSelector);

  const { canManageNonPaymentInfo } = useVendorInfo();

  const requestDocumentsDialog = useMultiStepDialog<DocumentSelectDialogStep>({
    lazy: true,
    initialStep: "select-documents",
  });

  const documentSelectInitialValues = useMemo<
    DocumentSelectDialogProps["initialValues"]
  >(
    () => ({
      vendors: [
        { id: vendor.id, displayName: vendor.displayName, email: vendor.email },
      ],
    }),
    [vendor.id, vendor.displayName, vendor.email]
  );

  const onSubmitRequestDocuments = useEvent(() => fetchById(vendor.id));

  return (
    <>
      <Tooltip
        message={
          !canManageNonPaymentInfo ? "You don't have permission to do this" : ""
        }
        placement="left"
      >
        <Button
          size="sm"
          onClick={requestDocumentsDialog.show}
          disabled={!canManageNonPaymentInfo || !vendor.id}
        >
          {STRINGS.REQUEST_BUTTON_LABEL}
        </Button>
      </Tooltip>

      {requestDocumentsDialog.isRendered && (
        <DocumentSelectDialog
          dialog={requestDocumentsDialog}
          multiple={false}
          onSubmit={onSubmitRequestDocuments}
          initialValues={documentSelectInitialValues}
        />
      )}
    </>
  );
};

const promptCancellationConfirmation = (onConfirm: () => void) => {
  dialog.confirmation({
    title: (
      <>
        Are you sure you want to cancel
        <br />
        this document request?
      </>
    ),
    size: "auto",
    align: "center",
    variant: "dialog",
    message: (
      <Flex gap="xl" direction="column" minWidth="505px">
        <Text align="center">
          This cannot be undone. You will need to send a new
          <br />
          request to collect additional documents.
        </Text>
      </Flex>
    ),
    action: {
      primary: {
        children: "Cancel request",
        color: "error",
        onClick: onConfirm,
      },
      secondary: {
        children: "Keep request",
      },
    },
  });
};

export const RequestVendorDocumentAlert = () => {
  const { info: vendor } = useAppSelector(vendorSelector);

  const { fetchById } = useVendorAction();

  const onSubmitCanceltDocument = useEvent(() => {
    fetchById(vendor.id);
  });

  const onConfirmDocRequestCancellation = useEvent(
    async (requestId: string | number) => {
      try {
        await deleteVendorDocRequest({
          request_id: requestId,
          vendor_id: vendor.id,
        });
        toast.success("Document request cancelled");
        onSubmitCanceltDocument();
      } catch (error: any) {
        handleErrors(error);
      }
    }
  );

  if (isListEmpty(vendor.pendingDocRequests)) return null;

  return (
    <Flex direction="column" gap="xl">
      {vendor.pendingDocRequests.map(
        (request: VendorDocRequestType) =>
          !isListEmpty(request.documentTypes) && (
            <Alert variant="info" key={request.id}>
              <AlertContent
                as={Flex}
                gap="md"
                direction="column"
                align="flex-start"
              >
                <>
                  <Text>
                    You sent the vendor a request to provide their{" "}
                    {formatDocumentTypes(request.documentTypes)} on{" "}
                    {formatDate(request.createdAt, "PPPp")}
                  </Text>
                  <Button
                    onClick={() =>
                      promptCancellationConfirmation(() =>
                        onConfirmDocRequestCancellation(request.id)
                      )
                    }
                    variant="ghost"
                    size="sm"
                  >
                    {STRINGS.CANCEL_BUTTON_LABEL}
                  </Button>
                </>
              </AlertContent>
            </Alert>
          )
      )}
    </Flex>
  );
};
