import React, { useCallback } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { CardTransactionField } from "@card-feed/components";
import type { CardTransactionFieldOnChangeHandler } from "@card-feed/types";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import { expenseSelector } from "@store/expenses/selectors";
import { useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";

import { STRINGS } from "../stages/constants";

import { LinkedTransactionComboBox } from "./select-linked-transaction";
import type { SelectProps } from "./types";

export const LinkedOrCardTransactionField = () => {
  const { cardFeedEnabled } = useClientSettings();

  const expense = useAppSelector(expenseSelector);

  const selector = useCallback<SelectProps["selector"]>(
    () =>
      expense.cardTransaction
        ? {
            ...expense.cardTransaction,
            id: String(expense.cardTransaction.id),
            url: expense.cardTransaction.url ?? "",
            display_name: expense.cardTransaction.displayName || undefined,
          }
        : undefined,
    [expense.cardTransaction]
  );

  const { canEditExpense } = useExpensePermissions();

  const { fetchById, setCardTransaction } = useExpenseAction();

  const onChangeCardTransaction = useEvent<CardTransactionFieldOnChangeHandler>(
    () => {
      return fetchById(expense.id);
    }
  );

  if (!expense.cardTransaction && !expense.paymentAccount && !cardFeedEnabled) {
    return null;
  }

  return cardFeedEnabled ? (
    <CardTransactionField
      cost={expense}
      label={STRINGS.LABEL_CARD_TRANSACTION}
      onChange={onChangeCardTransaction}
      disabled={
        !canEditExpense || !expense.id || expense.isTransactionGeneratedDraft
      }
      hintMessage={STRINGS.HINT_CARD_TRANSACTION}
      helperMessage={!expense.id ? STRINGS.HELPER_CARD_TRANSACTION : ""}
      valueVariant="long"
    />
  ) : (
    <LinkedTransactionComboBox
      size="md"
      selector={selector}
      disabled={!canEditExpense || expense.isTransactionGeneratedDraft}
      onChange={setCardTransaction}
    />
  );
};
