import {
  type RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { is } from "../../utils";
import { useEvent } from "../use-event";

type UseResizeObserverOptions = ResizeObserverOptions & {
  observe?: "width" | "height";
};

export function useResizeObserver(
  ref: RefObject<HTMLElement>,
  onChange: (el: HTMLElement) => void,
  options?: UseResizeObserverOptions
): void;

export function useResizeObserver(
  ref: RefObject<Window>,
  onChange: (el: Window) => void,
  options?: UseResizeObserverOptions
): void;

export function useResizeObserver<T extends HTMLElement | Window>(
  ref: RefObject<T>,
  onChange: (el: T) => void,
  options?: UseResizeObserverOptions
) {
  const prevWidth = useRef(0);
  const prevHeight = useRef(0);
  const [el, setEl] = useState<unknown>(ref.current);

  const enhancedOnChange = useEvent(
    (entriesOrEvent: ResizeObserverEntry[] | UIEvent) => {
      let width = 0;
      let height = 0;

      if (is.array(entriesOrEvent)) {
        const [entry] = entriesOrEvent;

        const { width, height } = entry.contentRect;

        if (el instanceof HTMLElement) {
          onChange(el as T);
          prevWidth.current = width;
          prevHeight.current = height;
        }

        return;
      } else {
        width = window.innerWidth;
        height = window.innerHeight;
      }

      const hasChangedWidth =
        options?.observe === "width" && width !== prevWidth.current;

      const hasChangedHeight =
        options?.observe === "height" && height !== prevHeight.current;

      if (
        el !== null &&
        (!options?.observe || hasChangedWidth || hasChangedHeight)
      ) {
        onChange(el as T);
        prevWidth.current = width;
        prevHeight.current = height;
      }
    }
  );

  /**
   * We need it to track the latest element.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (ref.current !== el) setEl(ref.current);
  });

  useEffect(() => {
    if (!el) return;

    if (el === window) {
      window.addEventListener("resize", enhancedOnChange);

      return () => {
        window.removeEventListener("resize", enhancedOnChange);
      };
    }

    const observer = new ResizeObserver(enhancedOnChange);
    observer.observe(el as HTMLElement, options);

    return () => {
      observer.disconnect();
    };
    /**
     * We don't need to track enhancedOnChange variables
     * since we guarantee that they never changes.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);
}
