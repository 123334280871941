import React, { type ComponentProps } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"div">;

export const ShellContent = ({ className, ...props }: Props) => (
  <div className={cn(className, styles["shell-content"])} {...props} />
);
