import {
  throttle,
  type ThrottleOptions,
  type ThrottleReturn,
} from "../throttle/throttle";

export type DebounceReturn<T extends unknown[]> = ThrottleReturn<T>;

/* Based on https://github.com/github/mini-throttle */
export const debounce = <T extends unknown[]>(
  callback: (...args: T) => unknown,
  wait = 0,
  { start = false, middle = false, once = false }: ThrottleOptions = {}
): DebounceReturn<T> => throttle(callback, wait, { start, middle, once });
