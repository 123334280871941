import { api } from "@store/api-simplified";
import {
  getSearchParamsFromFilters,
  handleRequest,
  handleResponse,
} from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  getExpensesPayloadSchema,
  getExpensesResponseSchema,
  updateExpensePayloadSchema,
} from "./schemas";
import type {
  GetExpensesPayload,
  GetExpensesResponse,
  UpdateExpensePayload,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Expenses", "CardTransactions"],
});

const expensesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    updateExpense: builder.mutation<void, UpdateExpensePayload>({
      query: (args) => {
        const { id, ...data } = handleRequest(args, updateExpensePayloadSchema);

        return {
          url: `expenses/${id}/`,
          body: transformKeysToSnakeCase(data),
          method: "PUT",
        };
      },
      invalidatesTags: [
        { type: "Expenses", id: "LIST" },
        { type: "CardTransactions", id: "LIST" },
      ],
    }),
    getExpenses: builder.query<GetExpensesResponse, GetExpensesPayload>({
      query: (args) => {
        const params = handleRequest(args, getExpensesPayloadSchema);

        return {
          url: `expenses/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getExpensesResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(
                ({ id }) => ({ type: "Expenses", id }) as const
              ),
              { type: "Expenses", id: "LIST" },
            ]
          : [{ type: "Expenses", id: "LIST" }],
    }),
  }),
});

export const { useGetExpensesQuery, useUpdateExpenseMutation } = expensesApi;
