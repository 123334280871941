import { api } from "../utils/api";
import { poll } from "../utils/poll";

export type FileExportQueueResponse = {
  id: string;
};

export type FileExport = {
  document?: string;
  error?: string;
  document_title?: string;
  status: string;
  queryset_count: number;
};

export type FileExportGroup = {
  status: string;
  files: FileExport[];
};

export const getFileExport = (id: string) =>
  poll<FileExport>({
    fn: () =>
      api.get<FileExport>(`/api/fileexports/${id}/`).then(({ data }) => data),
    validate: (data) => ["done", "failed"].includes(data.status),
  });

export const getFileExportGroup = (id: string) =>
  poll<FileExportGroup>({
    fn: () =>
      api
        .get<FileExportGroup>(`/api/fileexportgroups/${id}/`)
        .then(({ data }) => data),
    validate: (data) => ["done"].includes(data.status),
  });
