import { useMemo } from "react";
import { usePagination } from "@adaptive/design-system/hooks";
import { useClientInfo } from "@store/user";

import { useGetNotificationsQuery } from "../api/api";
import type { GetNotificationsPayload, Notification } from "../api/types";

type UseNotificationsParams = {
  query?: GetNotificationsPayload;
  enabled?: boolean;
  pagination?: ReturnType<typeof usePagination>;
};

const EMPTY_DATA: Notification[] = [];

const EMPTY_QUERY: GetNotificationsPayload = [];

export const useNotifications = ({
  query = EMPTY_QUERY,
  enabled = true,
  pagination: externalPagination,
}: UseNotificationsParams = {}) => {
  const { realmId } = useClientInfo();

  const internalPagination = usePagination();
  const pagination = externalPagination ?? internalPagination;

  const params = useMemo(() => {
    const params = [...query];

    params.push({ dataIndex: "limit", value: pagination.perPage });
    params.push({ dataIndex: "realm", value: realmId });
    params.push({ dataIndex: "offset", value: pagination.offset });

    return params;
  }, [query, realmId, pagination.perPage, pagination.offset]);

  const info = useGetNotificationsQuery(params, {
    selectFromResult: ({ isFetching, data }) => ({
      data: data?.results || EMPTY_DATA,
      count: data?.count || 0,
      isLoading: isFetching,
    }),
    skip: !realmId || !enabled,
    refetchOnMountOrArgChange: true,
  });

  return { ...info, ...pagination };
};
