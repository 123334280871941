import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Flex, TextField } from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { forgotPassword } from "@shared/api/auth";
import { z } from "zod";

import { Layout } from "../../../shared/components/layout";

const schema = z.object({
  email: z.string().email("An email address is required"),
});
type Fields = z.infer<typeof schema>;

export const ForgotPassword = () => {
  const [searchParams] = useSearchParams();

  const initialEmail = searchParams.get("email") || "";

  const [isPasswordRequested, setIsPasswordRequested] = useState(false);

  const initialValues = useMemo<Fields>(
    () => ({ email: initialEmail }),
    [initialEmail]
  );

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await forgotPassword(values);
      setIsPasswordRequested(true);
    },
    initialValues,
  });

  useEffect(() => {
    setIsPasswordRequested(false);
  }, [form.values]);

  return (
    <Layout
      title="Forgot your password?"
      subtitle="Enter the email address you used when you signed up and we’ll send you instructions to reset your password."
      backUrl="/accounts/login"
      onSubmit={form.props.onSubmit}
    >
      <Flex gap="xl" direction="column">
        <TextField
          type="email"
          label="Email"
          required
          autoFocus
          placeholder="Enter your email to continue"
          {...form.register("email")}
        />
        <Button
          variant="solid"
          data-testid="reset-password"
          disabled={form.isSubmitting || !form.isValid || isPasswordRequested}
          type="submit"
          block
        >
          {isPasswordRequested
            ? "Check your email for a link to reset your password"
            : "Reset password"}
        </Button>
      </Flex>
    </Layout>
  );
};
