import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  camelToSnakeCase,
  transformKeysToCamelCase,
} from "@utils/schema/converters";

import {
  uploadAttachablePayloadSchema,
  uploadAttachableResponseSchema,
} from "./schemas";
import type {
  UploadAttachablePayload,
  UploadAttachableResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Attachables"],
});

const attachablesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadAttachable: builder.mutation<
      UploadAttachableResponse,
      UploadAttachablePayload
    >({
      query: (args) => {
        const { document, parent, realm, ...payload } = handleRequest(
          args,
          uploadAttachablePayloadSchema
        );

        const formData = new FormData();
        formData.append("document", document);
        if (realm) formData.append("realm", realm);
        if (parent) formData.append("parent", parent);

        const params = new URLSearchParams();
        Object.entries(payload).forEach(([key, value]) => {
          params.append(camelToSnakeCase(key), String(value));
        });

        return {
          url: `attachables/`,
          body: formData,
          params,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              uploadAttachableResponseSchema
            );
          },
        };
      },
    }),
  }),
});

export const { useUploadAttachableMutation } = attachablesApi;
