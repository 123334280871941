import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
} from "react";
import cn from "clsx";

import { Button } from "../button";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { type IconName } from "../icon/constants";
import { Image } from "../image";

import styles from "./empty-state.module.css";

type DefaultComponent = "div";

const inboxSrc = new URL("../../assets/images/inbox.svg", import.meta.url).href;

type ActionProps = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "children"> & {
  title: ReactNode;
  icon?: IconName | "inbox";
  action?: ActionProps | ReactNode;
  subtitle?: ReactNode;
  maxWidth?: number;
};

const isActionProps = (action: unknown): action is ActionProps =>
  typeof action === "object" &&
  action !== null &&
  "onClick" in action &&
  "children" in action;

export const EmptyState = forwardRef<ComponentRef<DefaultComponent>, Props>(
  (
    {
      title,
      action,
      subtitle,
      className,
      style,
      maxWidth = 345,
      icon = "inbox",
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={{ ...style, ["--empty-state-max-width"]: `${maxWidth}px` }}
        className={cn(className, styles["empty-state"])}
        {...props}
      >
        {icon === "inbox" ? (
          <Image src={inboxSrc} width={80} height={51} />
        ) : (
          <Icon name={icon} size="2xl" />
        )}
        <Flex gap="sm" direction="column">
          <h3 className={styles["title"]}>{title}</h3>
          {subtitle && <p className={styles["subtitle"]}>{subtitle}</p>}
        </Flex>
        {isActionProps(action) ? <Button {...action} /> : action}
      </div>
    );
  }
);

EmptyState.displayName = "EmptyState";
