import { createContext, useContext } from "react";

import { createDialog } from "../dialog/dialog-create";

export type ContextType = {
  search: { threshold: number };
  autoFocus: boolean;
  virtualScroll?: boolean;
};

export const DEFAULT_CONTEXT: ContextType = {
  search: { threshold: 0 },
  autoFocus: true,
  virtualScroll: true,
};

export const Context = createContext<ContextType | null>(DEFAULT_CONTEXT);

export const useProvider = () => {
  const context = useContext(Context);

  if (!context) throw new Error("useProvider must be used within a Provider");

  return context;
};

export const { dialog, DialogContainer } = createDialog("global");
