import type { User } from "@store/user";

export const getUserByProfile = (
  user?: User | Omit<User, "role"> | null,
  realmId?: string | number | null
) => {
  if (!user || typeof user !== "object") return null;

  const profiles =
    "profiles" in user && Array.isArray(user.profiles) ? user.profiles : [];

  const profile: Record<string, unknown> =
    profiles.find(
      (profile) =>
        ("realmId" in profile ? profile.realmId : profile?.realm_id) === realmId
    ) || {};

  const fullName = (profile.fullName ||
    profile.full_name ||
    user.fullName ||
    user.full_name) as string;

  return {
    ...(user || {}),
    fullName: fullName,
    full_name: fullName,
  } as unknown as User;
};
