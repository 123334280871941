import React from "react";
import { Link } from "@adaptive/design-system";

export const PurchaseOrderLearnMoreLink = () => (
  <Link
    rel="noreferrer"
    href="https://help.adaptive.build/en/articles/8945449-creating-and-managing-purchase-orders-pos"
    target="_blank"
  >
    Learn more
  </Link>
);
