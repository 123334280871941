import { idSchema } from "@src/shared/utils/schema";
import { z } from "zod";

export const updateQuickBooksErrorsPayload = z.object({
  ids: idSchema.array(),
  isIgnored: z.boolean(),
});

export const deleteQuickBooksErrorsPayload = z.object({
  ids: idSchema.array(),
});
