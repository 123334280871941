import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  CurrencyField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent, useForm } from "@adaptive/design-system/hooks";
import { parseCurrency } from "@adaptive/design-system/utils";
import type { Selector } from "@reduxjs/toolkit";

import type { TaxInfo } from "../../store/expenses/types";
import type { RootState } from "../../store/types";
import { useModalVisibility } from "../../store/ui/hooks";

const STRINGS = {
  HEADING: "Add sales tax",
  BUTTON_SUBMIT: "Add proportionally to lines",
  REQUIRED_ERROR: "Amount Required",
};

interface Props {
  onClose?: () => void;
  onSubmit: (e: any) => void;
  dataSelector: Selector<RootState, TaxInfo>;
}

type Fields = { amount: number };

export const TaxModal = ({ onSubmit, onClose, dataSelector }: Props) => {
  const amount = useSelector<RootState, number>((state) => {
    return dataSelector(state)?.value || 0;
  });

  const { visible, setVisible } = useModalVisibility("SalesTax");

  const enhancedOnClose = useEvent(() => {
    onClose?.();
    setVisible(false);
  });

  const enhancedOnSubmit = useEvent(({ amount }: Fields) => {
    onSubmit(amount);
    setVisible(false);
  });

  const initialValues = useDeepMemo(() => ({ amount }), [amount]);

  const { reset, ...form } = useForm<Fields>({
    onSubmit: enhancedOnSubmit,
    initialValues,
  });

  const [isInvalid, setIsInvalid] = useState(!amount);

  const onInput = useEvent((e) => {
    const parsedValue = parseCurrency(e.currentTarget.value);
    setIsInvalid(!parsedValue);
  });

  useEffect(() => {
    if (!visible) reset();
  }, [reset, visible]);

  return (
    <Dialog variant="dialog" show={visible} onClose={enhancedOnClose} size="sm">
      <DialogHeader>{STRINGS.HEADING}</DialogHeader>
      <DialogContent>
        <form {...form.props}>
          <CurrencyField
            label="Amount"
            required
            onInput={onInput}
            autoFocus
            allowNegative
            messageVariant="hidden"
            {...form.register({ name: "amount", type: "currency" })}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button block type="submit" form={form.id} disabled={isInvalid}>
          {STRINGS.BUTTON_SUBMIT}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
