import { useJobSettings } from "@src/jobs/hooks";

import { getRemaining } from "../utils";

export const useGetRemaining = (row: {
  [key: string]: any;
  builderAmount: number;
  builderRevisedAmount?: number;
  ownersAmount: number;
  spent: number;
  invoicedAmount: number;
}) => {
  const { ownersAmountEnabled, changeTrackingEnabled } = useJobSettings();

  return getRemaining({ ...row, ownersAmountEnabled, changeTrackingEnabled });
};
