import type { Colors } from "../../styles/variables";
import type { IconName } from "../icon/constants";

import type { AlertVariant } from "./alert";

export const BACKGROUNDS: Record<AlertVariant, Colors> = {
  info: "info-100",
  error: "error-100",
  warning: "warning-100",
  success: "success-100",
  neutral: "neutral-300",
};

export const ICON_COLORS: Record<AlertVariant, Colors> = {
  info: "info-200",
  error: "error-200",
  warning: "warning-200",
  success: "success-200",
  neutral: "neutral-900",
};

export const ICONS: Record<AlertVariant, IconName> = {
  info: "info-circle",
  error: "square-x",
  warning: "exclamation-triangle",
  success: "check-circle",
  neutral: "exclamation-circle",
};
