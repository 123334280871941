export const getAllFocusableElements = (parent: HTMLElement) =>
  (
    [
      ...parent.querySelectorAll(
        "input:not([type='hidden']):not([disabled]),select:not([disabled]),textarea:not([disabled]),a[href],button:not([disabled]):not([tabindex='-1']),[tabindex]:not([tabindex='-1']),iframe,object,embed,area[href],audio[controls],video[controls],[contenteditable]:not([contenteditable='false'])"
      ),
    ] as HTMLElement[]
  )
    .filter((el) => !("skipFocusable" in el.dataset))
    .sort((a, b) => {
      const autoFocusA = a.hasAttribute("autofocus");
      const autoFocusB = b.hasAttribute("autofocus");

      if (autoFocusA && !autoFocusB) {
        return -1;
      } else if (!autoFocusA && autoFocusB) {
        return 1;
      }

      return 0;
    });
