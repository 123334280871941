import { z, type ZodRawShape } from "zod";

const errors = z.string().array();

export const getBadRequestSchema = <T extends ZodRawShape>(
  schema: z.ZodObject<T>
) => {
  const fieldErrorsShape = Object.keys(
    schema.shape ?? (schema as any).innerType?.()?.shape ?? {}
  ).reduce((acc, key) => {
    return {
      ...acc,
      [key]: errors.optional().transform((val) => val?.length && val[0]),
    };
  }, {}) as z.ZodRawShape;

  return z.object({
    nonFieldErrors: errors.optional(),
    ...fieldErrorsShape,
  });
};
