import { api } from "../../utils/api";

import type {
  ChangePasswordPayload,
  GetUsersProps,
  GetUsersResponse,
  GetUsersSimplifiedResponse,
  UpdateUserPayload,
  UserResponse,
  Verify2FAPayload,
} from "./types";

export const getUser = () =>
  api.get<UserResponse>("/api/user/").then((resp) => resp.data);

export const sendSms2FA = () => api.post(`/api/user/start-verification/`);

export const verifySms2FA = (payload: Verify2FAPayload) =>
  api.post(`/api/user/check-verification/`, payload);

export const getUsers = ({ signal, ...params }: GetUsersProps = {}) =>
  api.get<GetUsersResponse>("/api/users/", { params, signal });

export const getUsersSimplified = ({ signal, ...params }: GetUsersProps = {}) =>
  api.get<GetUsersSimplifiedResponse>("/api/users/?simplified", {
    params,
    signal,
  });

export const getPotentialApprovers = ({
  signal,
  ...params
}: GetUsersProps = {}) =>
  api.get<GetUsersSimplifiedResponse>("/api/users/potential_approvers/", {
    params,
    signal,
  });

export const updateUser = ({ id, ...payload }: UpdateUserPayload) =>
  api.put(`/api/users/${id}/`, payload);

export const changePassword = (payload: ChangePasswordPayload) =>
  api.post(`/api/users/change_password/`, payload);
