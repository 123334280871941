import { api } from "@store/api-simplified";
import {
  getSearchParamsFromFilters,
  handleRequest,
  handleResponse,
} from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import {
  getNotificationsPayloadSchema,
  getNotificationsResponseSchema,
  updateNotificationPayloadSchema,
  updateNotificationResponseSchema,
  updateNotificationsPayloadSchema,
  updateNotificationsResponseSchema,
} from "./schemas";
import type {
  GetNotificationsPayload,
  GetNotificationsResponse,
  Notification,
  UpdateNotificationPayload,
  UpdateNotificationsPayload,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Notifications"],
});

export const notificationsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      GetNotificationsResponse,
      GetNotificationsPayload
    >({
      query: (args) => {
        const params = handleRequest(args, getNotificationsPayloadSchema);

        return {
          url: `notifications/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getNotificationsResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(
                ({ id }) => ({ type: "Notifications", id }) as const
              ),
              { type: "Notifications", id: "LIST" },
            ]
          : [{ type: "Notifications", id: "LIST" }],
    }),
    updateNotification: builder.mutation<
      Notification,
      UpdateNotificationPayload
    >({
      query: (args) => {
        const { id, ...payload } = handleRequest(
          args,
          updateNotificationPayloadSchema
        );
        return {
          url: `notifications/${id}/`,
          method: "PATCH",
          body: transformKeysToCamelCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              updateNotificationResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "Notifications", id: data.id },
        { type: "Notifications", id: "LIST" },
      ],
    }),
    updateNotifications: builder.mutation<
      GetNotificationsResponse,
      UpdateNotificationsPayload
    >({
      query: (args) => {
        const payload = handleRequest(args, updateNotificationsPayloadSchema);
        return {
          url: "notifications/",
          method: "PATCH",
          body: transformKeysToCamelCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              updateNotificationsResponseSchema
            );
          },
        };
      },
      invalidatesTags: () => [{ type: "Notifications", id: "LIST" }],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useUpdateNotificationsMutation,
} = notificationsApi;
