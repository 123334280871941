import React, { useEffect, useMemo } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import type {
  WorkflowApprovalMutation,
  WorkflowApprovals,
} from "@api/workflows";
import { useDrawerVisibility } from "@store/ui";
import { useWorkflowAction, useWorkflowInfo } from "@store/workflow";

import { groupApprovers } from "../utils";

import { approvalsSchema, type FieldsApprovals, type StepTypes } from "./form";
import { WorkflowSteps, type WorkflowStepsProps } from "./workflow-steps";

const transformWorkflowToForm = (workflow: WorkflowApprovals) => {
  const steps = workflow.steps.map((step) => ({
    requirementType: step.requirementType as StepTypes,
    approvers: step.approvers.map((approver) => approver.url),
  }));

  return {
    id: workflow.id,
    steps,
  };
};

export const WorkflowApprovalsForm = () => {
  const { setVisible, visible, setShouldShowHideConfirmation } =
    useDrawerVisibility("workflowApprovals");

  const { currentWorkflowApprovals } = useWorkflowInfo();

  const { resetCurrentWorkflow, persistWorkflowApprovals } =
    useWorkflowAction();

  const initialValues = useMemo(
    () => transformWorkflowToForm(currentWorkflowApprovals),
    [currentWorkflowApprovals]
  );

  const form = useForm<FieldsApprovals>({
    schema: approvalsSchema,
    async onSubmit(values) {
      if (
        !currentWorkflowApprovals.objectId ||
        !currentWorkflowApprovals.objectType
      ) {
        return;
      }

      const steps = values.steps.map((step, index) => ({
        requirementType: step.requirementType,
        order: index + 1,
        ...groupApprovers(step.approvers),
      }));

      const payload: WorkflowApprovalMutation = {
        payload: {
          ...(currentWorkflowApprovals.id
            ? { id: currentWorkflowApprovals.id }
            : {}),
          steps,
        },
        objectId: currentWorkflowApprovals.objectId,
        objectType: currentWorkflowApprovals.objectType,
      };

      await persistWorkflowApprovals(payload);
      setVisible(false, true);
    },
    initialValues,
  });

  useEffect(() => {
    if (!visible) resetCurrentWorkflow();
  }, [visible, resetCurrentWorkflow]);

  useEffect(() => {
    setShouldShowHideConfirmation(form.isDirty);
  }, [form.isDirty, setShouldShowHideConfirmation]);

  return (
    <>
      <DialogHeader>To be approved by</DialogHeader>
      <DialogContent>
        <Flex
          as="form"
          {...form.props}
          direction="column"
          separator
          shrink={false}
          gap="xl"
        >
          {visible && (
            <WorkflowSteps
              form={form as WorkflowStepsProps["form"]}
              title="The following people are supposed to approve this transaction:"
            />
          )}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          size="lg"
          color="neutral"
          variant="text"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button
          size="lg"
          type="submit"
          form={form.id}
          disabled={form.isSubmitting}
          data-testid="workflow-approval-save"
        >
          {form.isSubmitting ? <Loader /> : "Save"}
        </Button>
      </DialogFooter>
    </>
  );
};
