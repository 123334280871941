import { useDeepMemo } from "@adaptive/design-system/hooks";
import type { BudgetLineItems } from "@api/jobs";
import { useJobSettings } from "@src/jobs/hooks";

import { getLineTotals } from "../utils";

import type { Total } from "./types";

export type UseLineItemTotalsProps = { budgetLines: BudgetLineItems[] };

export type UseLineItemTotalsReturnType = Total;

export const useLineItemTotals = ({ budgetLines }: UseLineItemTotalsProps) => {
  const { ownersAmountEnabled, changeTrackingEnabled } = useJobSettings();

  return useDeepMemo(
    () =>
      getLineTotals({
        data: budgetLines,
        ownersAmountEnabled,
        changeTrackingEnabled,
      }),
    [budgetLines, ownersAmountEnabled, changeTrackingEnabled]
  );
};
