import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Loader,
  Table,
  type TableColumn,
  type TableHeaderAddon,
  type TableSelectAddon,
  TagGroup,
  Text,
  toast,
} from "@adaptive/design-system";
import { type useDialog, useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { useUploadAttachableMutation } from "@api/attachables";
import { handleErrors } from "@api/handle-errors";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandler,
} from "@components/batch-apply-object";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import { CustomersComboBox } from "@components/customers-combobox";
import { Thumbnail } from "@components/thumbnail";
import { useExpenses, useUpdateExpense } from "@expenses/hooks";
import type { Expense } from "@expenses/types";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import { useClientInfo } from "@store/user";
import { noop } from "@utils/noop";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { createContext, useContextSelector } from "use-context-selector";

import {
  CREATE_RECEIPTS_DIALOG_TABLE_ID,
  STRINGS,
} from "../constants/constants";
import type { CardTransaction } from "../types";

export type CreateReceiptsDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  onSuccess?: () => void;
  transactions: CardTransaction[];
};

type UpdateExpenseHandler = (params: {
  key: string;
  index: number;
  value: unknown;
}) => void;

type CreateReceiptsContextType = {
  isSubmitting: boolean;
  updateExpense: UpdateExpenseHandler;
};

type EnhancedAttachable = Exclude<Expense["attachables"], undefined>[number] & {
  file?: File;
};

type EnhancedExpense = Expense & { attachables?: EnhancedAttachable[] };

type EnhancedExpenseWithIndex = EnhancedExpense & { index: number };

const CreateReceiptsContext = createContext<CreateReceiptsContextType>({
  isSubmitting: false,
  updateExpense: noop,
});

const useCreateReceipts = () =>
  useContextSelector(CreateReceiptsContext, (context) => context);

const CostCodeAccountRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const info = useMemo(() => {
    const items = row.items ?? [];
    const itemsNames = items.map((value) => value.displayName || "");

    const accounts = row.accounts ?? [];
    const accountsNames = accounts.map((value) => value.displayName || "");

    const hasMultipleValues =
      items.length > 1 ||
      accounts.length > 1 ||
      (items.length && accounts.length);

    const value = hasMultipleValues
      ? ""
      : items[0]?.url || accounts[0]?.url || "";

    return {
      items: itemsNames,
      value,
      accounts: accountsNames,
      hasMultipleValues,
    };
  }, [row.items, row.accounts]);

  const onChange = useEvent((value: string) => {
    updateExpense({
      key: "items",
      index: row.index,
      value: value.includes("/items/") ? [{ url: value || null }] : [],
    });
    updateExpense({
      key: "accounts",
      index: row.index,
      value: value.includes("/accounts/") ? [{ url: value || null }] : [],
    });
  });

  return info.hasMultipleValues ? (
    <CostCodeAccountInfo items={info.items} accounts={info.accounts} />
  ) : (
    <CostCodeAccountComboBox
      size="sm"
      label=""
      value={info.value}
      onChange={onChange}
      disabled={isSubmitting}
      placeholder="Select"
      messageVariant="hidden"
    />
  );
});

CostCodeAccountRender.displayName = "CostCodeAccountRender";

const JobRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const value = row.customers?.[0]?.url || "";

  const onChange = useEvent((value: string) => {
    updateExpense({
      key: "customers",
      index: row.index,
      value: [{ url: value || null }],
    });
  });

  return (row.customers ?? []).length > 1 ? (
    <TagGroup data={row.customers} limit="auto" render="displayName" />
  ) : (
    <CustomersComboBox
      size="sm"
      label=""
      value={value}
      disabled={isSubmitting}
      onChange={onChange}
      messageVariant="hidden"
    />
  );
});

JobRender.displayName = "JobRender";

const VendorRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const vendors = useVendorsSimplified();

  const value = row.vendor?.url || "";

  const onChange = useEvent((value: string) => {
    updateExpense({
      key: "vendor",
      index: row.index,
      value: { url: value || null },
    });
  });

  return (
    <ComboBox
      size="sm"
      data={vendors.data}
      value={value}
      loading={vendors.status === "loading"}
      disabled={isSubmitting}
      onChange={onChange}
      messageVariant="hidden"
    />
  );
});

VendorRender.displayName = "VendorRender";

const AttachmentRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const onChange = useEvent(async (file: File) => {
    if (!file) return;

    updateExpense({
      key: "attachables",
      index: row.index,
      value: [{ file, thumbnail: URL.createObjectURL(file) }],
    });
  });

  return (
    <Thumbnail
      alt={`Transaction${row.docNumber ? ` #${row.docNumber}` : ""}`}
      src={row.attachables?.[0]?.thumbnail || undefined}
      onChange={onChange}
      disabled={isSubmitting}
    />
  );
});

AttachmentRender.displayName = "AttachmentRender";

const COLUMNS: TableColumn<EnhancedExpense>[] = [
  {
    id: "attachment",
    name: "Attachment",
    render: (row, index) => <AttachmentRender {...row} index={index} />,
    width: 115,
  },
  {
    id: "card_transaction",
    name: "Card transaction",
    render: "cardTransaction.displayName",
    width: 250,
  },
  {
    id: "cost_code_account",
    name: "Cost code / Account",
    render: (row, index) => <CostCodeAccountRender {...row} index={index} />,
    width: 175,
  },
  {
    id: "vendor",
    name: "Vendor name",
    render: (row, index) => <VendorRender {...row} index={index} />,
    width: 175,
  },
  {
    id: "job",
    name: "Job",
    render: (row, index) => <JobRender {...row} index={index} />,
    width: 175,
  },
];

export const CreateReceiptsDialog = ({
  dialog,
  onSuccess,
  transactions,
}: CreateReceiptsDialogProps) => {
  const { realm } = useClientInfo();

  const [reviewStatus, setReviewStatus] = useState<
    Expense["reviewStatus"] | ""
  >("");

  const [triggerUpdateExpense, infoUpdateExpense] = useUpdateExpense();

  const [triggerUploadAttachable, infoUploadAttachable] =
    useUploadAttachableMutation();

  const isSubmitting =
    infoUpdateExpense.isLoading || infoUploadAttachable.isLoading;

  const [data, setData] = useState<EnhancedExpense[]>([]);

  const query = useMemo(
    () => [
      {
        dataIndex: "include_transaction_generated_drafts",
        value: null,
      },
      ...transactions
        .filter((transaction) => transaction.expense)
        .map((transaction) => ({
          value: parseRefinementIdFromUrl(transaction.expense!),
          dataIndex: "id",
        })),
    ],
    [transactions]
  );

  const expensesInfo = useExpenses({ query });

  const [selected, setSelected] = useState<Expense[]>([]);

  const onRemove = useEvent(() => {
    setData((data) =>
      data.filter(
        (item) => !selected.some((selectedItem) => selectedItem.id === item.id)
      )
    );
    setSelected([]);
  });

  const onBatchApply = useEvent<BatchApplyObjectOnChangeHandler>((params) => {
    setData((data) =>
      data.map((item) => {
        if (selected.some((selectedItem) => selectedItem.id === item.id)) {
          if (params.fieldName === "vendor") {
            return dotObject.set(item, "vendor", { url: params.value });
          } else if (params.fieldName === "customer") {
            return dotObject.set(item, "customers", [{ url: params.value }]);
          } else if (params.value.includes("/items/")) {
            const temporaryItem = dotObject.set(item, "items", [
              { url: params.value },
            ]);
            return dotObject.set(temporaryItem, "accounts", []);
          } else if (params.value.includes("/accounts/")) {
            const temporaryItem = dotObject.set(item, "accounts", [
              { url: params.value },
            ]);
            return dotObject.set(temporaryItem, "items", []);
          }
        }

        return item;
      })
    );
  });

  const updateExpense = useEvent<UpdateExpenseHandler>(
    ({ key, value, index }) => {
      setData((data) => dotObject.set(data, `${index}.${key}`, value));
    }
  );

  const applyChanges = useCallback(
    async (reviewStatus: Expense["reviewStatus"]) => {
      if (!realm?.url) return { failed: data.length, succeeded: 0 };

      setSelected([]);

      setReviewStatus(reviewStatus);

      let failed = 0;
      let succeeded = 0;

      await Promise.all(
        data.map(async (item) => {
          try {
            const file = item.attachables?.[0]?.file;

            if (file) {
              await triggerUploadAttachable({
                realm: realm.url,
                parent: item.url,
                document: file,
              }).unwrap();
            }

            const line = {
              item: item.items?.[0]?.url ?? null,
              amount: item.totalAmount ?? 0,
              account: item.accounts?.[0]?.url ?? null,
              customer: item.customers?.[0]?.url ?? null,
            };

            await triggerUpdateExpense({
              id: item.id,
              lines: [line],
              vendor: item.vendor?.url ?? null,
              reviewStatus,
              isTransactionGeneratedDraft: false,
            }).unwrap();

            succeeded++;
            setData((data) => data.filter((item) => item.id !== item.id));
          } catch (e) {
            failed++;
            handleErrors(e);
          }
        })
      );

      setReviewStatus("");

      return { failed, succeeded };
    },
    [data, realm?.url, triggerUpdateExpense, triggerUploadAttachable]
  );

  const onSubmitForReview = useEvent(async () => {
    const { failed, succeeded } = await applyChanges("FOR_REVIEW");

    if (succeeded) {
      toast.success(
        `${succeeded} receipt${succeeded > 1 ? "s" : ""} created and submitted for review`
      );
    }

    if (!failed) {
      onSuccess?.();
      dialog.hide();
    }
  });

  const onSync = useEvent(async () => {
    for (const item of data) {
      const hasItems = !!item.items?.length;
      const hasAccounts = !!item.accounts?.length;

      if (!hasItems && !hasAccounts) {
        return toast.error(
          STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_COST_CODE_ACCOUNT_ERROR
        );
      }

      const hasCustomers = !!item.customers?.length;

      if (!hasCustomers && !hasAccounts) {
        return toast.error(STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_JOB_ERROR);
      }
    }

    const { failed, succeeded } = await applyChanges("REVIEWED");

    if (succeeded) {
      toast.success(
        `${succeeded} receipt${succeeded > 1 ? "s" : ""} created and synced to QuickBooks`
      );
    }

    if (!failed) {
      onSuccess?.();
      dialog.hide();
    }
  });

  const hasData = expensesInfo.data.length > 0;

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData }),
    [hasData]
  );

  const select = useMemo<TableSelectAddon<Expense>>(
    () => ({
      value: selected,
      onChange: setSelected,
      isDisabled: () => isSubmitting,
    }),
    [isSubmitting, selected]
  );

  useEffect(() => {
    setData(expensesInfo.data);
    setSelected([]);
    setReviewStatus("");
  }, [expensesInfo.data]);

  return (
    <Dialog
      size="auto"
      show={dialog.isVisible}
      variant="dialog"
      onClose={dialog.hide}
    >
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {STRINGS.CREATE_RECEIPTS_DIALOG_TITLE}
          </Text>
          <Flex justify="space-between" gap="xl" height="3xl">
            <Text size="md">{STRINGS.CREATE_RECEIPTS_DIALOG_SUBTITLE}</Text>
            {selected.length > 0 && (
              <Dropdown placement="bottom-end">
                <DropdownTrigger
                  as={Button}
                  size="sm"
                  style={{ marginRight: "calc(var(--spacing-4xl) * -1)" }}
                >
                  Actions <Icon name="ellipsis-vertical" variant="solid" />
                </DropdownTrigger>
                <DropdownList>
                  <DropdownItem onClick={onRemove}>Remove</DropdownItem>
                  <DropdownItem>
                    <BatchApplyObject
                      onChange={onBatchApply}
                      accountFilters={{ only_line_item_accounts: true }}
                    />
                  </DropdownItem>
                </DropdownList>
              </Dropdown>
            )}
          </Flex>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex minWidth="930px">
          <CreateReceiptsContext.Provider
            value={{ isSubmitting, updateExpense }}
          >
            <Table
              id={CREATE_RECEIPTS_DIALOG_TABLE_ID}
              size="sm"
              data={data}
              header={header}
              select={select}
              loading={expensesInfo.isLoading}
              columns={COLUMNS}
            />
          </CreateReceiptsContext.Provider>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          variant="ghost"
          onClick={onSubmitForReview}
          disabled={isSubmitting}
        >
          {isSubmitting && reviewStatus === "FOR_REVIEW" ? (
            <Loader />
          ) : (
            STRINGS.CREATE_RECEIPTS_DIALOG_FOR_REVIEW_ACTION
          )}
        </Button>
        <Button block onClick={onSync} disabled={isSubmitting}>
          {isSubmitting && reviewStatus === "REVIEWED" ? (
            <Loader />
          ) : (
            STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_ACTION
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
