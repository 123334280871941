import React, {
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type ReactNode,
  useEffect,
} from "react";
import {
  CompositeRow,
  MenuItem,
  MenuItemCheckbox,
  MenuItemRadio,
  useMenuContext,
  useStoreState,
} from "@ariakit/react";
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  Droppable,
  type OnDragEndResponder,
} from "@hello-pangea/dnd";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { suffixify } from "../../utils/suffixify";
import { Icon, type IconProps } from "../icon";
import { type IconName } from "../icon/constants";
import { Wrapper } from "../wrapper";

import { type DropdownValue } from "./dropdown";
import { useDropdown } from "./dropdown-context";
import checkboxStyles from "../checkbox/checkbox.module.css";
import radioStyles from "../radio/radio.module.css";
import styles from "./dropdown.module.css";

type DropdownSelectableItemRowProps = {
  children: ReactNode;
  snapshot: DraggableStateSnapshot;
  draggable: DraggableProvided;
  "data-testid"?: string;
};

const DropdownSelectableItemRow = ({
  children,
  snapshot,
  draggable: {
    draggableProps: { style, ...draggableProps },
    ...draggable
  },
  "data-testid": testId,
}: DropdownSelectableItemRowProps) => (
  <CompositeRow
    ref={draggable.innerRef}
    role="row"
    style={style as CSSProperties}
    className={cn(styles["dropdown-selectable-item-draggable-item"], {
      [styles["-dragging"]]: snapshot.isDragging,
    })}
    {...draggableProps}
    data-testid={testId}
  >
    {children}
    <Icon
      as={MenuItem}
      name="grip-dots-vertical"
      render={<button role={undefined} />}
      variant="solid"
      hideOnClick={false}
      className={styles["dropdown-selectable-item-draggable-handle"]}
      {...draggable.dragHandleProps}
      tabIndex={-1}
      data-testid={suffixify(testId, "handle")}
    />
  </CompositeRow>
);

type DefaultComponent = "div";

type Extra =
  | ReactNode
  | {
      icon: IconName;
      size?: IconProps["size"];
      onClick: () => void;
      variant?: IconProps["variant"];
    };

export type DropdownSelectableSingleData = {
  label: ReactNode;
  value: string;
  extra?: Extra;
  disabled?: boolean;
};

export type DropdownSelectableMultipleData = {
  label: ReactNode;
  value: string;
  extra?: Extra;
  disabled?: boolean | { change?: boolean; order?: boolean };
};

type OwnProps = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "value" | "onChange" | "children"
> & {
  name: string;
  "data-testid"?: string;
};

export type DropdownSelectableItemSingleOnChangeHandler = (
  value: string,
  option: DropdownSelectableSingleData
) => void;

export type DropdownSelectableItemSingleProps = OwnProps & {
  data: DropdownSelectableSingleData[];
  value?: string;
  multiple?: never;
  onChange?: DropdownSelectableItemSingleOnChangeHandler;
  onOrderChange?: never;
};

export type DropdownSelectableItemMultipleOnChangeHandler = (
  value: string[],
  option: DropdownSelectableMultipleData[]
) => void;

export type DropdownSelectableItemOnOrderChangeHandler = (
  data: DropdownSelectableMultipleData[]
) => void;

export type DropdownSelectableItemMultipleProps = OwnProps & {
  data: DropdownSelectableMultipleData[];
  value?: string[];
  multiple: true;
  onChange?: DropdownSelectableItemMultipleOnChangeHandler;
  onOrderChange?: DropdownSelectableItemOnOrderChangeHandler;
};

type DropdownSelectableItemProps =
  | DropdownSelectableItemSingleProps
  | DropdownSelectableItemMultipleProps;

export const DropdownSelectableItem = ({
  data = [],
  name,
  value,
  onChange,
  multiple,
  className,
  onOrderChange,
  "data-testid": testId,
}: DropdownSelectableItemProps) => {
  const menuContext = useMenuContext();

  const { hideOnClick, setValues, setOnSetValues } = useDropdown();

  const internalValue = useStoreState(menuContext, "values")?.[name] ?? [];

  const Component = multiple ? MenuItemCheckbox : MenuItemRadio;

  const onDragEnd = useEvent<OnDragEndResponder>(({ source, destination }) => {
    if (!destination) return;

    const sourceIndex = Number(source.index);
    const destinationIndex = Number(destination.index);

    const newData = [...data];

    newData.splice(destinationIndex, 0, newData.splice(sourceIndex, 1)[0]);

    onOrderChange?.(newData);
  });

  const renderContent = (
    item: DropdownSelectableSingleData | DropdownSelectableMultipleData
  ) => {
    const isChangeDisabled = is.object(item.disabled)
      ? item.disabled.change
      : item.disabled;

    return (
      <>
        <Component
          className={cn(
            className,
            styles["dropdown-selectable-item"],
            styles["-neutral"],
            styles["-interactive"]
          )}
          hideOnClick={hideOnClick ?? (multiple ? false : true)}
          role={multiple ? "checkbox" : "radio"}
          name={name}
          value={item.value}
          disabled={isChangeDisabled}
          data-testid={suffixify(testId, "select")}
        >
          <div
            className={
              multiple
                ? cn(checkboxStyles["wrapper"], {
                    [checkboxStyles["-checked"]]: (
                      internalValue as (string | number)[]
                    ).includes(item.value),
                    [checkboxStyles["-disabled"]]: item.disabled,
                  })
                : cn(radioStyles["wrapper"], {
                    [radioStyles["-checked"]]: internalValue === item.value,
                    [radioStyles["-disabled"]]: item.disabled,
                  })
            }
          >
            <div
              className={
                multiple ? checkboxStyles["checkbox"] : radioStyles["radio"]
              }
            />
          </div>
          {item.label}
        </Component>
        {is.object(item.extra) && "onClick" in item.extra ? (
          <MenuItem
            {...item.extra}
            role={undefined}
            render={
              <Icon
                as="button"
                name={item.extra.icon}
                size={item.extra.size}
                variant={item.extra.variant}
              />
            }
            className={styles["dropdown-item-extra"]}
            hideOnClick={false}
            data-testid={suffixify(testId, "extra")}
          />
        ) : (
          item.extra
        )}
      </>
    );
  };

  const setValue = useEvent((nextValue: DropdownValue) => {
    if (nextValue === false) return;

    if (value === undefined) {
      setValues?.((previousValues) => ({
        ...previousValues,
        [name]: nextValue,
      }));
    }

    if (is.array(nextValue)) {
      const enhancedNextValue = nextValue.map(String);
      (onChange as DropdownSelectableItemMultipleOnChangeHandler)?.(
        enhancedNextValue,
        data.filter((item) => enhancedNextValue.includes(item.value))
      );
    } else {
      (onChange as DropdownSelectableItemSingleOnChangeHandler)?.(
        String(nextValue),
        (data as DropdownSelectableSingleData[]).find(
          (item) => item.value === nextValue
        )!
      );
    }
  });

  useEffect(() => {
    setOnSetValues?.((previousOnSetValues) =>
      previousOnSetValues[name] === setValue
        ? previousOnSetValues
        : { ...previousOnSetValues, [name]: setValue }
    ),
      [name, setValue, setOnSetValues];
  });

  useEffect(() => {
    if (value === undefined) return;

    setValues?.((previousValues) => {
      const previousValue = previousValues[name];

      return isEqual(previousValue, value)
        ? previousValues
        : { ...previousValues, [name]: value };
    });
  }, [name, value, setValues]);

  return (
    <Wrapper
      when={!!onOrderChange}
      render={(children) => (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={suffixify(name, "droppable")!}
            renderClone={(provided, snapshot, rubric) => (
              <DropdownSelectableItemRow
                snapshot={snapshot}
                draggable={provided}
                data-testid={suffixify(testId, "drag-item-clone")}
              >
                {renderContent(data[rubric.source.index])}
              </DropdownSelectableItemRow>
            )}
          >
            {(droppable) => (
              <div
                ref={droppable.innerRef}
                className={styles["dropdown-selectable-item-droppable-area"]}
                data-testid={suffixify(testId, "droppable-area")}
              >
                {children}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    >
      {data.map((item, i) => {
        const isOrderDisabled = !!(
          is.object(item.disabled) && item.disabled.order
        );

        return (
          <Wrapper
            key={suffixify(name, i)}
            when={!!onOrderChange && !isOrderDisabled}
            render={(children) => (
              <Draggable
                index={i}
                draggableId={suffixify(name, i)!}
                disableInteractiveElementBlocking
              >
                {(draggable, snapshot) => (
                  <DropdownSelectableItemRow
                    snapshot={snapshot}
                    draggable={draggable}
                    data-testid={suffixify(testId, "drag-item")}
                  >
                    {children}
                  </DropdownSelectableItemRow>
                )}
              </Draggable>
            )}
          >
            <Wrapper
              when={!onOrderChange || isOrderDisabled}
              render={(children) => (
                <CompositeRow role="row">{children}</CompositeRow>
              )}
            >
              {renderContent(item)}
            </Wrapper>
          </Wrapper>
        );
      })}
    </Wrapper>
  );
};
