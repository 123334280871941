import { type SignatureField } from "../api/types";

type ParseToForm = (
  availableFields: SignatureField[],
  selectedFields?: string[]
) => Record<string, boolean>;
const parseToForm: ParseToForm = (availableFields, selectedFields) => {
  const signatureFields: Record<string, boolean> = {};
  availableFields.forEach((field) => {
    signatureFields[field.id] = !!field.isDefault;
  });

  if (selectedFields) {
    availableFields.forEach((field) => {
      signatureFields[field.id] = selectedFields.includes(field.url);
    });
  }

  return signatureFields;
};

type ParseToPayload = (
  availableFields: SignatureField[],
  selectedFields: Record<string, boolean>
) => string[];
const parseToPayload: ParseToPayload = (availableFields, selectedFields) =>
  Object.entries(selectedFields).reduce((acc, [key, isSelected]) => {
    const fieldUrl = availableFields.find((field) => field.id === key)?.url;
    if (isSelected && fieldUrl) {
      acc.push(fieldUrl);
    }
    return acc;
  }, [] as string[]);

type ParseToPDFPayload = (
  availableFields: SignatureField[],
  selectedFields: Record<string, boolean>
) => string[];
const parseToPDFPayload: ParseToPDFPayload = (
  availableFields,
  selectedFields
) =>
  Object.entries(selectedFields).reduce((acc, [key, isSelected]) => {
    const fieldName = availableFields.find((field) => field.id === key)?.type;
    if (isSelected && fieldName) {
      acc.push(fieldName);
    }
    return acc;
  }, [] as string[]);

export const parseSignatureFields = {
  toForm: parseToForm,
  toPayload: parseToPayload,
  toPDFPayload: parseToPDFPayload,
};
