type FormatMention = (params: {
  label: string;
  value: string;
  trigger?: string;
}) => string;

/**
 * @description Format mention from object to final string
 * @example
 * formatMention({ label: "User 1", value: "1" });
 * // returns "[@User 1](1)"
 *
 * formatMention({ label: "User 1", value: "1", trigger: "#" });
 * // returns "[#User 1](1)"
 */
export const formatMention: FormatMention = ({ label, value, trigger = "@" }) =>
  `[${trigger}${label}](${value})`;
