import { type RefObject, useEffect } from "react";

import { useEvent } from "../use-event";

export const useMutationObserver = (
  ref: RefObject<HTMLElement>,
  onChange: MutationCallback,
  options: MutationObserverInit = {
    subtree: true,
    childList: true,
    attributes: true,
    characterData: true,
  }
) => {
  const enhancedOnChange = useEvent(onChange);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new MutationObserver(enhancedOnChange);

    observer.observe(ref.current, options);

    return () => {
      observer.disconnect();
    };
    /**
     * We don't need to track ref/enhancedOnChange variables
     * since we guarantee that they never changes.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
};
