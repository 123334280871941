import React from "react";
import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { withSentryReactRouterV6Routing } from "@sentry/react";

import { CompanyName } from "./components/company-name";
import { FindTeam } from "./components/find-team";
import { LinkQuickbooks } from "./components/link-quickbooks";
import { TeamMembers } from "./components/team-members";

const Routes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Onboarding = () => {
  return (
    <Routes>
      <Route path="onboarding">
        <Route element={<CompanyName />} path="company" />
        <Route element={<TeamMembers />} path="team-members" />
        <Route element={<FindTeam />} path="find-team" />
        <Route element={<LinkQuickbooks />} path="link-accounting-software" />
        <Route
          path=""
          element={<Navigate to="/onboarding/find-team" replace />}
        />
      </Route>
      <Route
        element={<Navigate to="/onboarding/find-team" replace />}
        path="*"
      />
    </Routes>
  );
};
