import React, {
  type ComponentProps,
  type FormEvent,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  ComboBox,
  Flex,
  PercentageField,
  Table,
  type TableColumn,
  type TableRowAddon,
  Text,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent, useForm } from "@adaptive/design-system/hooks";
import { formatCurrency, parsePercentage } from "@adaptive/design-system/utils";
import { useGetBudgetLinesQuery } from "@api/budgets";
import type { BudgetLineItems } from "@api/jobs";
import { jobCostMethodSchema } from "@api/jobs/response/schema";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { useJobInfo } from "@store/jobs";
import { divide } from "@utils/divide";
import { currencySchema, idSchema } from "@utils/schema";
import { sum } from "@utils/sum";
import { z } from "zod";

import { CURRENCY_FORMAT, PERCENTAGE_FORMAT } from "../constants";

type OnInputHandler = Exclude<
  ComponentProps<typeof PercentageField>["onInput"],
  undefined
>;

const lineSchema = z.object({
  id: idSchema,
  item: z.object({ displayName: z.string() }).nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  spent: currencySchema.optional(),
  amount: currencySchema.optional(),
  hasChanges: z.boolean().optional(),
  builderAmount: currencySchema.optional(),
  builderRevisedAmount: currencySchema.optional(),
  ownersAmount: currencySchema.optional(),
});

const linesSchema = z.array(lineSchema).min(1);

const schema = z.object({
  value: z.number().refine((value) => value !== 0, "Percent is required"),
  lines: linesSchema,
  costCode: z.string().url("Item is required"),
  isSeparateLine: z.boolean(),
});

const formSchema = schema.omit({ lines: true });

type Line = z.input<typeof lineSchema>;

type Fields = z.input<typeof schema>;

type FormFields = z.input<typeof formSchema>;

const INITIAL_VALUES: Fields = {
  value: 0,
  lines: [],
  costCode: "",
  isSeparateLine: false,
};

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

export type MarkupPercentageFormProps = PropsWithChildren<{
  lines: Line[];
  initialValues?: Fields;
  formId: string;
  onSubmit: (
    values: Omit<Fields, "lines"> & { lines: z.output<typeof linesSchema> },
    e: FormEvent<HTMLFormElement>
  ) => Promise<void>;
  lineReferenceKey?: string;
  onValidityChange: (isValid: boolean) => void;
  allowedType?: "in-line" | "separate-line" | "both";
  percentageFormat?: typeof PERCENTAGE_FORMAT;
  disableBudgetLines?: boolean;
  onChange?: (values: Fields) => void;
}>;

export const MarkupPercentageForm = ({
  lines,
  formId,
  initialValues = INITIAL_VALUES,
  onSubmit,
  children,
  onChange,
  onValidityChange,
  lineReferenceKey = "id",
  disableBudgetLines = true,
  allowedType = "both",
  percentageFormat = PERCENTAGE_FORMAT,
}: MarkupPercentageFormProps) => {
  const [selected, setSelected] = useState<Fields["lines"]>(
    initialValues.lines
  );

  const form = useForm<FormFields>({
    id: formId,
    schema: formSchema,
    onSubmit: (values, e) => {
      onSubmit(schema.parse({ ...values, lines: selected }), e!);
    },
    initialValues,
  });

  const [percentage, setPercentage] = useState(initialValues.value);

  const { job } = useJobInfo();

  const { data: budgetLines = EMPTY_BUDGET_LINES } = useGetBudgetLinesQuery({
    customerId: job.id,
  });

  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified({
    disableBudgetLines: disableBudgetLines ? budgetLines : [],
  });

  const isPercentageValid = !!percentage;

  const totalAmount = useDeepMemo(() => {
    const total = selected.reduce((acc, line) => sum(acc, line.amount ?? 0), 0);
    return total * divide(percentage, 100);
  }, [selected, percentage]);

  const select = useMemo(
    () => ({
      value: selected,
      onChange: setSelected,
      reference: lineReferenceKey,
    }),
    [selected, lineReferenceKey]
  );

  const row = useMemo<TableRowAddon<Line>>(
    () => ({ isError: (row) => !row.jobCostMethod }),
    []
  );

  const columns = useMemo<TableColumn<Line>[]>(
    () => [
      {
        id: "displayName",
        name: "Cost code / Account",
        width: "fill",
        minWidth: 400,
        render: (row) =>
          row.jobCostMethod?.displayName ?? (
            <Text color="error-200">Unknown line item</Text>
          ),
        footer: "Markup total",
      },
      {
        id: "amount",
        name: "Amount",
        width: 165,
        textAlign: "right",
        render: (row) => formatCurrency(row.amount ?? 0, CURRENCY_FORMAT),
        footer: formatCurrency(totalAmount, CURRENCY_FORMAT),
      },
    ],
    [totalAmount]
  );

  const onInput = useEvent<OnInputHandler>((e) => {
    const parsedPercentage = parsePercentage(e.currentTarget.value, {
      maximumFractionDigits: percentageFormat.maximumFractionDigits,
    });
    setPercentage(parsedPercentage ?? 0);
  });

  /**
   * We do this custom validation instead of using `form.isValid` because PercentageField
   * only notifies your changes when user blur, we do it because we cannot ensure parse
   * and use the `unfinished` value on controlled way. Other libraries like `react-aria`
   * do the same on these cases.
   */
  useEffect(() => {
    onValidityChange(
      !form.errors.costCode &&
        selected.length > 0 &&
        isPercentageValid &&
        !form.isSubmitting
    );
  }, [
    form.isValid,
    selected.length,
    onValidityChange,
    form.isSubmitting,
    isPercentageValid,
    form.errors.costCode,
  ]);

  useEffect(() => {
    setPercentage(form.values.value);
  }, [form.values.value]);

  useEffect(() => {
    onChange?.({
      ...form.values,
      lines: selected,
    });
  }, [onChange, form.values, selected]);

  return (
    <form {...form.props}>
      <Flex gap="xl">
        <Flex grow={true}>
          <ComboBox
            label="Item Name"
            data={costCodeAccountsSimplified.data}
            loading={costCodeAccountsSimplified.status === "loading"}
            required
            placeholder="ex: Builder's Fee"
            data-testid="markup-percentage-form-item-name"
            {...form.register("costCode")}
          />
        </Flex>
        <Flex maxWidth="140px" data-testid="markup-percentage-form-percent">
          <PercentageField
            label="Percent"
            onInput={onInput}
            required
            placeholder="ex: 20"
            allowNegative
            min={percentageFormat.min}
            max={percentageFormat.max}
            minimumFractionDigits={percentageFormat.minimumFractionDigits}
            maximumFractionDigits={percentageFormat.maximumFractionDigits}
            {...form.register({ name: "value", type: "percentage" })}
          />
        </Flex>
      </Flex>
      <Flex gap="xl" direction="column">
        <Text>Select items to markup</Text>
        <Table
          id="markup-percentage-form-table"
          row={row}
          data={lines}
          size="sm"
          select={select}
          columns={columns}
          maxHeight={allowedType === "both" ? "247px" : "287px"}
          data-testid="markup-percentage-form-table"
        />
        {allowedType === "both" ? (
          <Checkbox
            label="Add as a separate line"
            {...form.register({
              name: "isSeparateLine",
              type: "boolean",
            })}
          />
        ) : null}
        {children}
      </Flex>
    </form>
  );
};
