type GetBase64File = (params: {
  base64: string;
  type: string;
  fileName: string;
}) => Promise<File>;

export const getBase64File: GetBase64File = async ({
  base64,
  type,
  fileName,
}) => {
  const data = await fetch(base64);
  const blob = await data.blob();
  const file = new File([blob], `${fileName}.png`, { type });

  return file;
};
