const isUUID = (str: string) =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    str
  );

export const parseRefinementIdFromUrl = (url: string | number = "") => {
  let enhancedUrl = String(url);

  const searchParams = enhancedUrl.indexOf("?");
  if (searchParams > -1) {
    enhancedUrl = enhancedUrl.substring(0, searchParams);
  }

  if (enhancedUrl.endsWith("/")) {
    enhancedUrl = enhancedUrl.slice(0, -1);
  }

  const slices = enhancedUrl.split("/");
  const id = slices[slices.length - 1];

  return isUUID(id) || !isNaN(parseInt(id)) ? id : undefined;
};
