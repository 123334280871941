import React from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Text,
} from "@adaptive/design-system";
import { QuickBooksIcon } from "@components/quickbooks-icon";

type BankTableColumnProps = {
  row: any;
  onDelete: (id: string) => void;
  onManageBank: (id: string) => void;
  onQBConnect: (id: string) => void;
};

export const InstantAuthColumns = ({
  row,
  onDelete,
  onManageBank,
  onQBConnect,
}: BankTableColumnProps) => (
  <Flex direction="column">
    <Flex align="center">
      <Flex direction="row" width="full">
        <Text size="xl" weight="bold">
          {row.bank_name}
        </Text>
      </Flex>
      <Flex align="flex-end">
        <Dropdown placement="bottom-end">
          <DropdownTrigger
            as={Button}
            color="neutral"
            variant="text"
            aria-label="Click me"
          >
            <Icon name="ellipsis-vertical" variant="solid" />
          </DropdownTrigger>
          <DropdownList>
            <DropdownItem onClick={() => onDelete(row.id)}>
              Remove bank
            </DropdownItem>
            <DropdownItem onClick={() => onManageBank(row.id)}>
              Manage accounts
            </DropdownItem>
          </DropdownList>
        </Dropdown>
      </Flex>
    </Flex>

    {row.account_balances.map((account: any) => (
      <Flex direction="column" key={account.id}>
        <Flex justify="center" gap="md">
          <Flex direction="row" width="full" gap="md" justify="space-between">
            <Flex>
              <Text size="xl" weight="bold">
                {account.name}
              </Text>
              <Text size="md" color="neutral-700">
                **** {account.mask}
              </Text>
            </Flex>
          </Flex>
          <Flex align="flex-end">
            <Dropdown placement="bottom-end">
              <DropdownTrigger
                as={Button}
                color="neutral"
                variant="text"
                aria-label="Click me"
              >
                <Icon name="ellipsis-vertical" variant="solid" />
              </DropdownTrigger>
              <DropdownList>
                <DropdownItem
                  onClick={
                    // eslint-disable-next-line
                    () => onQBConnect(account)
                  }
                >
                  Edit linked account
                </DropdownItem>
              </DropdownList>
            </Dropdown>
          </Flex>
        </Flex>
        <Flex padding={["none", "none", "2xl", "none"]} justify="space-between">
          <Flex gap="md" align="center">
            {account.payment_account?.display_name ? (
              <>
                <QuickBooksIcon />
                <Text size="sm" color="success-200">
                  {account.payment_account.display_name}
                </Text>
              </>
            ) : (
              <>
                <QuickBooksIcon />
                <Link
                  as="button"
                  size="sm"
                  type="button"
                  onClick={() => onQBConnect(account)}
                >
                  Add QuickBooks account
                </Link>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    ))}
  </Flex>
);
