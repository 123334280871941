export const STRINGS = {
  // common
  CANCEL: "Cancel",
  SEND: "Send",

  // mark as signed
  MARK_AS_SIGNED_DIALOG_HEADER:
    "Are you sure you want to mark this purchase order as signed?",
  MARK_AS_SIGNED_DIALOG_SUBHEADER:
    "You have not received an official acceptance from this vendor through Adaptive.",
  MARK_AS_SIGNED_ERROR_TOAST: "Purchase order failed to be marked as signed",
  MARK_AS_SIGNED_LABEL: "Mark as signed",
  MARK_AS_SIGNED_SUCCESS_TOAST: "Purchase order marked as signed",
  PO_ALREADY_MARKED_AS_SIGNED_ERROR_TOAST:
    "The following purchase orders couldn't be marked as signed because they have already been signed",
  PO_NOT_MARKED_AS_SIGNED_ERROR_TOAST:
    "The following purchase orders couldn't be unmarked as signed because they were not signed",
  UNMARK_AS_SIGNED_ERROR_TOAST:
    "Purchase order failed to be unmarked as signed",

  // vendor request
  ADD_COMMENT: "Add a comment for the vendor or attach additional files",
  ADD_COMMENT_PLACEHOLDER:
    "Comments are visible to other users in your workspace. The vendor will be notified directly.",
  PO_ALREADY_SIGNED_ERROR_TOAST:
    "Requests for the  following purchase orders couldn't be sent because they have already been signed",
  REQUEST_DEFAULT_MESSAGE:
    "Click the link below to view and sign the purchase order",
  SEND_REMINDER_LABEL: "Send reminder",
  REQUEST_DIALOG_HEADER_MULTIPLE:
    "Send purchase orders for signature from the following vendors",
  REQUEST_DIALOG_HEADER_SINGLE: "Send for signature",
  REQUEST_DIALOG_CONTENT_MULTIPLE:
    "Confirm which email addresses you would like to send these purchase orders to.",
  SEND_REQUEST_LABEL: "Send to vendor",
  VENDOR_EMAIL_ADDRESS: "Vendor email address",
  VENDOR_EMAIL_UPDATE: "Do you need to change the vendor's email?",
  VENDOR_EMAIL_ADD: "This email address will be added to this vendor’s profile",
  VENDOR_EMAIL_ADDRESS_PLACEHOLDER: "Enter the vendor's email address",
  CANCEL_REQUEST_LABEL: "Cancel request",
};

export const VENDOR_REQUEST_STATUS = {
  EXPIRED: "EXPIRED",
  PENDING: "PENDING",
} as const;

const VENDOR_PO_REQUEST_STATUS_VARIANT = {
  PENDING: "info",
  EXPIRED: "warning",
} as const;

export const poAlertVariant = (status: keyof typeof VENDOR_REQUEST_STATUS) =>
  VENDOR_PO_REQUEST_STATUS_VARIANT[status] || "neutral";
