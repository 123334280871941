import React, { memo } from "react";
import { DialogStep } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useDrawerVisibility } from "@store/ui";

import { JobFormCategories } from "./job-form-categories";
import { JobFormInfo } from "./job-form-info";

export const JobForm = memo(() => {
  const { setStep } = useDrawerVisibility("job");

  const onBackCategories = useEvent(() => setStep("info"));

  return (
    <>
      <DialogStep name="info">
        <JobFormInfo />
      </DialogStep>
      <DialogStep name="categories" onBack={onBackCategories}>
        <JobFormCategories />
      </DialogStep>
    </>
  );
});

JobForm.displayName = "JobForm";
