import React, {
  type ComponentRef,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  isValidElement,
  type ReactNode,
  useCallback,
  useMemo,
} from "react";
import { ComboBox, type ComboBoxProps } from "@adaptive/design-system";
import { omit } from "@adaptive/design-system/utils";
import { type AccountFilters } from "@hooks/use-accounts-simplified";
import { useCostCodesAccountsSimplified } from "@hooks/use-cost-codes-accounts-simplified";
import type { UseCostCodesSimplifiedProps } from "@hooks/use-cost-codes-simplified";

import type { Option } from "../../types";

import {
  renderCostCodeAccountLabel,
  type RenderCostCodeAccountLabelHandler,
  renderCostCodeAccountPlaceholder,
} from "./cost-code-account-combobox-utils";

type Ref = ComponentRef<typeof ComboBox>;

export type CostCodeAccountComboBoxProps<IsMultiple extends boolean> = Omit<
  ComboBoxProps<IsMultiple>,
  "label" | "placeholder"
> & {
  as?: ElementType;
  label?: string | RenderCostCodeAccountLabelHandler;
  placeholder?: string | ((data?: Option[]) => string);
  accountFilters?: AccountFilters & { enabled?: boolean };
  costCodeFilters?: UseCostCodesSimplifiedProps;
};

const CostCodeAccountComboBox = <IsMultiple extends boolean = false>(
  {
    as: Component = ComboBox,
    label,
    value,
    placeholder,
    accountFilters,
    costCodeFilters,
    ...props
  }: CostCodeAccountComboBoxProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const { data, status } = useCostCodesAccountsSimplified({
    accountFilters,
    costCodeFilters,
  });

  const enhancedData = useMemo(() => {
    const mixedData = data;

    (Array.isArray(value) ? value : [value]).forEach((option) => {
      if ((option && typeof option !== "object") || option === null) return;

      if (
        !mixedData.some((innerOption) => innerOption.value === option.value!)
      ) {
        mixedData.push(option);
      }
    });

    const hasMultipleGroups =
      [...new Set(mixedData?.map((option) => option?.groupLabel))].length > 1;

    return hasMultipleGroups
      ? mixedData
      : mixedData?.map((option) => omit(option, ["groupLabel"]));
  }, [data, value]);

  const enhancedLabel = useCallback<
    Exclude<ComboBoxProps<false>["label"], ReactNode>
  >(
    (params) => {
      if (isValidElement(label) || typeof label === "string") return label;

      return label
        ? label({ ...params, data })
        : renderCostCodeAccountLabel({ ...params, data });
    },
    [label, data]
  );

  const enhancedPlaceholder = useMemo(() => {
    if (typeof placeholder === "string") return placeholder;

    return placeholder
      ? placeholder(data)
      : renderCostCodeAccountPlaceholder({ data });
  }, [placeholder, data]);

  return (
    <Component
      ref={ref}
      data={enhancedData}
      label={enhancedLabel}
      value={value}
      loading={status === "loading"}
      placeholder={enhancedPlaceholder}
      {...props}
    />
  );
};

const ForwardedCostCodeAccountComboBox = forwardRef(
  CostCodeAccountComboBox
) as <IsMultiple extends boolean = false>(
  props: CostCodeAccountComboBoxProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof CostCodeAccountComboBox>;

export { ForwardedCostCodeAccountComboBox as CostCodeAccountComboBox };
