import { createSlice } from "@reduxjs/toolkit";

import { getRealm } from "../api/realms";

const initialState = {
  realm: null,
};

const LOCAL_STORAGE_KEY = "selectedRealmId";

const realmSlice = createSlice({
  name: "realm",
  initialState,
  reducers: {
    updateRealm: (state, action) => {
      state.realm = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEY, action.payload.id);
    },
  },
});

export const { updateRealm } = realmSlice.actions;

export const loadRealm = (realmId) => (dispatch) => {
  return getRealm(realmId).then((realm) => {
    dispatch(updateRealm(realm));
    return realm;
  });
};

export const { reducer } = realmSlice;
