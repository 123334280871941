/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertContent,
  AlertTitle,
  ComboBox,
  DateField,
  Flex,
  Link,
  Tag,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { ErrorAlert } from "@components/common/error-alert";
import { TaxModal } from "@components/common/tax-modal";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { SelectVendor } from "@components/form";
import { Form } from "@components/form";
import { Section } from "@components/section";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import { useStageName } from "@shared/sequential/context";
import { useCycle } from "@src/expenses/cycle-provider";
import {
  useExpenseAction,
  useExpensePermissions,
  useTax,
} from "@store/expenses";
import {
  expenseAssigneeOptionSelector,
  expenseDuplicatesSelector,
  expenseSelectors,
} from "@store/expenses/selectors";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import {
  DocumentNumber,
  LinkedOrCardTransactionField,
  PaymentAccountComboBox,
} from "../../forms";
import { Items } from "../../forms/items";
import { STRINGS } from "../constants";

export const Edit = () => {
  const { cardFeedEnabled } = useClientSettings();

  const stageName = useStageName();

  const navigate = useNavigate();

  const cycle = useCycle();

  const id = useSelector(expenseSelectors.id);

  const date = useSelector(expenseSelectors.date);

  const errors = useSelector(expenseSelectors.errors);

  const relatedErrors = useSelector(expenseSelectors.relatedErrors);

  const assignee = useSelector(expenseAssigneeOptionSelector);

  const duplicates = useSelector(expenseDuplicatesSelector);

  const emailBodyAttachable = useSelector(expenseSelectors.emailBodyAttachable);

  const isArchived = useSelector(expenseSelectors.isArchived);

  const isTransactionGeneratedDraft = useSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );

  const paymentAccount = useSelector(expenseSelectors.paymentAccount);

  const cardTransaction = useSelector(expenseSelectors.cardTransaction);

  const linesCount = useSelector(expenseSelectors.linesCount);

  const { canEditExpense } = useExpensePermissions();

  const { taxSelector, setTax } = useTax();

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const isEditable = !isArchived && canEditExpense;

  const emailBodyAttachableTag = emailBodyAttachable && (
    <Tag
      as="button"
      key="emailBodyAttachable"
      type="button"
      onClick={() => {
        window.open(emailBodyAttachable.document, "_blank");
      }}
      color="info"
    >
      View source email
    </Tag>
  );

  const {
    saveExpense,
    setDate,
    setAssignee,
    setDocumentNumber,
    setPaymentAccount,
    setCardTransaction,
    fetchById,
    setVendor,
    unArchive,
  } = useExpenseAction();

  const refetchExpense = useCallback(() => fetchById(id), [fetchById, id]);

  const onSubmit = useEvent(async () => {
    const data = await saveExpense({ setStatus: "FOR_REVIEW" });

    analytics.track(id ? "expenseUpdate" : "expenseCreate", {
      expenseId: data.id,
    });

    if (cardFeedEnabled && isTransactionGeneratedDraft) {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Receipt #{data.docNumber} created in Review status
          </Text>
          <Text as="span">
            Click {STRINGS.BUTTON_PUBLISH} when receipt is ready to sync
          </Text>
        </Flex>
      );
    } else {
      toast.success(`Receipt #${data.docNumber} was sent for review`);
    }

    if (!cycle || cycle.status === "ALL") return cycle.disable();

    const hasNext = cycle.hasNavigation && (await cycle.next());

    if (hasNext) return;

    if (!cardFeedEnabled || !isTransactionGeneratedDraft) {
      navigate("/expenses?status=drafts");
      toast.success("You reviewed all receipts that have missing information");
    }
  });

  const onEnterSubmit = useEvent(async () => {
    const data = await saveExpense();

    analytics.track(id ? "expenseUpdate" : "expenseCreate", {
      expenseId: data.id,
    });

    if (cardFeedEnabled && isTransactionGeneratedDraft) {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Receipt #{data.docNumber} created in Missing Info status
          </Text>
          <Text as="span">
            Click {STRINGS.BUTTON_ADVANCE_REVIEW} when all relevant fields are
            complete
          </Text>
        </Flex>
      );
    } else {
      toast.success(
        `Receipt ${data.docNumber ? `#${data.docNumber} ` : ""}${
          id ? "updated" : "created"
        }`
      );
    }
  });

  return (
    <>
      <Form id={stageName} onSubmit={onSubmit} onEnterSubmit={onEnterSubmit}>
        <Flex gap="xl" direction="column">
          {isArchived && canEditExpense && (
            <Alert variant="info">
              <AlertTitle>This receipt is not editable</AlertTitle>
              <AlertContent>
                <Link as="button" type="button" onClick={unArchive}>
                  Restore this receipt
                </Link>{" "}
                in order to make any modifications to it
              </AlertContent>
            </Alert>
          )}

          {duplicates.length > 0 && <DuplicateAlert data={duplicates} />}

          {cardTransaction && cardTransaction.pending && (
            <Alert variant="info">
              <AlertTitle>This transaction is pending</AlertTitle>
              <AlertContent>
                The amount might update once the transaction is cleared.
              </AlertContent>
            </Alert>
          )}

          <TooManyLinesAlert
            transactionType="Receipt"
            linesCount={linesCount}
          />

          {(errors.length > 0 || relatedErrors.length > 0) && (
            <ErrorAlert
              data={errors}
              onChange={refetchExpense}
              objectType="Receipt"
              relatedData={relatedErrors}
            />
          )}

          <Flex gap="5xl" direction="column">
            <Section
              heading={STRINGS.HEADING_DETAILS}
              tags={[emailBodyAttachableTag]}
            >
              <SelectVendor
                selector={expenseSelectors.vendor}
                disabled={!isEditable}
                onChange={setVendor}
                allowOtherNames={true}
                autoFocus
              />

              <Flex gap="xl">
                <DocumentNumber
                  selector={expenseSelectors.docNumber}
                  disabled={!isEditable}
                  label={STRINGS.LABEL_DOC_NUMBER}
                  onChange={setDocumentNumber}
                  onBlur={(e) => setDocumentNumber(e.target.value)}
                />
                <ComboBox
                  label="Assignee"
                  data={users.data}
                  value={assignee}
                  loading={users.status === "loading"}
                  disabled={!isEditable}
                  onChange={setAssignee}
                  hintMessage={STRINGS.ASSIGNEE_HINT}
                />
              </Flex>
              <DateField
                disabled={!isEditable}
                label="Date"
                size="md"
                value={date}
                messageVariant="absolute"
                onChange={setDate}
              />
            </Section>

            <Section heading="Payment details" hideBorder hidePadding>
              <PaymentAccountComboBox
                selector={expenseSelectors.paymentAccount}
                disabled={
                  !isEditable ||
                  isTransactionGeneratedDraft ||
                  (cardTransaction?.id && cardFeedEnabled)
                }
                helperMessage={
                  cardTransaction?.id && cardFeedEnabled
                    ? "Unmatch the card transaction to change the payment account"
                    : ""
                }
                onChange={(val, option) => {
                  if (paymentAccount?.url !== option?.value) {
                    setCardTransaction(null, null);
                  }
                  setPaymentAccount(val, option);
                }}
                size="md"
              />
              <LinkedOrCardTransactionField />
            </Section>

            <Items />
          </Flex>
        </Flex>
      </Form>

      <TaxModal dataSelector={taxSelector} onSubmit={setTax} />
    </>
  );
};
