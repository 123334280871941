import React, { type ComponentProps, useMemo } from "react";
import { TextField } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { useIntegrationType } from "@hooks/use-integration-type";
import { numberMustBeLessThan, withCustomValidation } from "@utils/validator";

type RefNumberFieldProps = Omit<
  ComponentProps<typeof TextField>,
  "maxLength" | "ref"
> & {
  maxLength: Record<"QBDT" | "QBO", number>;
};

export const RefNumberField = ({
  maxLength,
  ...props
}: RefNumberFieldProps) => {
  const integrationType = useIntegrationType();

  const length = useDeepMemo(
    () =>
      integrationType
        ? maxLength[integrationType]
        : Math.max(
            ...Object.values(maxLength).filter(
              (value) => typeof value === "number"
            )
          ),
    [integrationType, maxLength]
  );

  const Component = useMemo(() => {
    const validator = numberMustBeLessThan(
      `Ref # should have no more than ${length} characters`,
      length + 1
    );

    return withCustomValidation(TextField, validator, true);
  }, [length]);

  return <Component {...props} />;
};
