import React, { Fragment } from "react";
import { Link } from "@adaptive/design-system";

import { type InvoiceData } from "../types";

export const renderLinkedInvoices = (linkedInvoices: InvoiceData[]) =>
  linkedInvoices.map((invoice, index) => (
    <Fragment key={invoice.id}>
      {index === 0 ? "(" : ""}
      <Link
        rel="noreferrer"
        size="sm"
        target="_blank"
        href={`/jobs/${invoice.customerId}/invoices/${invoice.id}`}
        variant="success"
      >
        #{invoice.docNumber}
      </Link>
      {index < linkedInvoices.length - 1 ? ", " : ")"}
    </Fragment>
  ));
