import React, { memo } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  PercentageField,
  Table,
  type TableColumn,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatPercentage,
} from "@adaptive/design-system/utils";
import {
  type BillableExpense,
  useGetBillableExpensesQuery,
} from "@api/invoices";

import {
  CURRENCY_FORMAT,
  INVOICE_STRINGS,
  PERCENTAGE_FORMAT,
} from "../constants";

type InvoiceLinePercentageDialogProps = {
  job: { id: string | number; name: string };
  onSave: (lines: BillableExpense[]) => void;
  onSkip: () => void;
};

const PERCENTAGE_COLUMNS: TableColumn<BillableExpense>[] = [
  {
    id: "item",
    name: "Items",
    width: "fill",
    render: (row) => row.item?.displayName,
  },
  {
    id: "amount",
    name: "Contracted amount",
    textAlign: "right",
    render: () => formatCurrency(1000, CURRENCY_FORMAT),
  },
  {
    id: "invoiced",
    name: `Previously ${INVOICE_STRINGS.LOWERCASE_INVOICE}`,
    textAlign: "right",
    render: () => formatPercentage(100, PERCENTAGE_FORMAT),
  },
  {
    id: "complete",
    textAlign: "right",
    name: "Total complete",
    width: 140,
    render: () => (
      <PercentageField
        messageVariant="hidden"
        size="sm"
        value={50}
        min={PERCENTAGE_FORMAT.min}
        max={PERCENTAGE_FORMAT.max}
        minimumFractionDigits={PERCENTAGE_FORMAT.minimumFractionDigits}
        maximumFractionDigits={PERCENTAGE_FORMAT.maximumFractionDigits}
      />
    ),
  },
];

export const InvoiceLinePercentageDialog = memo(
  ({ job, onSkip, onSave }: InvoiceLinePercentageDialogProps) => {
    const { data: lines, isLoading } = useGetBillableExpensesQuery({
      customerId: job.id,
    });

    const enhancedOnSave = useEvent(() => {
      onSave([]);
    });

    return (
      <>
        <DialogHeader>{`What would you like to ${INVOICE_STRINGS.LOWERCASE_INVOICE} for?`}</DialogHeader>
        <DialogContent>
          <Flex gap="2xl" direction="column">
            <Text weight="bold">Budget Items—{job.name}</Text>
            <Table
              id="invoice-line-percentage-dialog-table"
              size="sm"
              data={lines}
              loading={isLoading}
              columns={PERCENTAGE_COLUMNS}
              maxHeight="447px"
              emptyState={{
                title: `You don't have any transactions that require a ${INVOICE_STRINGS.LOWERCASE_INVOICE} for this job`,
              }}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button block variant="text" color="neutral" onClick={onSkip}>
            Skip
          </Button>
          <Button block onClick={enhancedOnSave}>
            {`Add to ${INVOICE_STRINGS.LOWERCASE_INVOICE}`}
          </Button>
        </DialogFooter>
      </>
    );
  }
);

InvoiceLinePercentageDialog.displayName = "InvoiceLinePercentageDialog";
