import { formatDate, omit } from "@adaptive/design-system/utils";
import {
  arraySchema,
  currencySchema,
  idSchema,
  stringOrEmptySchema,
} from "@utils/schema";
import { z } from "zod";

import { invoiceStatusSchema, lineSchema, markupSchema } from "../response";
import { type InvoiceStatus } from "../types";

const transformReviewStatus = (reviewStatus: InvoiceStatus) =>
  reviewStatus.split(" ").join("_").toUpperCase();
const transformObjReviewStatus = (obj: Record<string, any>) => ({
  ...obj,
  ...(obj.reviewStatus
    ? { reviewStatus: transformReviewStatus(obj.reviewStatus) }
    : {}),
});

export const invoiceStatusPayloadSchema = invoiceStatusSchema.transform(
  transformReviewStatus
);

export const billableExpensesRequestSchema = z.object({
  params: stringOrEmptySchema,
  customerId: idSchema,
});

export const invoiceSettingsGetPayloadSchema = z.object({
  customerId: idSchema,
});

export const invoiceBillableExpensesRequestSchema = z.object({
  customerId: idSchema,
  invoiceId: idSchema,
});

export const invoiceGetPayloadSchema = z.object({
  invoiceId: idSchema,
});

export const invoiceEvaluatePayloadSchema = z
  .union([
    z.object({
      status: z.literal("APPROVED"),
      bypass: z.boolean().default(false),
      invoiceId: idSchema,
    }),
    z.object({
      status: z.literal("REJECTED"),
      comment: z.string(),
      invoiceId: idSchema,
    }),
  ])
  .transform((values) => omit(values, ["invoiceId"]));

export const invoiceDownloadPayloadSchema = z.object({
  type: z.string(),
  invoiceId: idSchema,
});

export const invoiceGetLinesPayloadSchema = invoiceGetPayloadSchema;

export const invoiceGetMarkupsPayloadSchema = invoiceGetPayloadSchema;

export const invoicesListPayloadSchema = z.object({
  limit: z.number().nullish(),
  offset: z.number().nullish(),
  customerId: idSchema,
});

export const invoiceLineMutateSchema = z.object({
  id: idSchema.nullish(),
  item: z.string().url().nullish(),
  itemAccount: z.string().url().nullish(),
  vendor: z.string().url().nullish(),
  amount: currencySchema,
  totalAmount: currencySchema,
  isExtra: z.boolean().nullish(),
  description: z.string().nullish(),
  transactionLine: z.string().url().nullish(),
});

export const invoiceStorePayloadSchema = z.object({
  realm: z.string().url(),
  lines: z.array(invoiceLineMutateSchema),
  customer: z.string().url(),
});

export const invoiceMarkupMutateSchema = z.object({
  id: idSchema.nullish(),
  item: z.string().url().nullish(),
  itemAccount: z.string().url().nullish(),
  lines: z.array(idSchema),
  amount: currencySchema,
  percent: z.number().nullish(),
  description: z.string().nullish(),
  isInlineMarkup: z.boolean().nullish(),
  vendor: z.string().url().nullish(),
});

export const invoiceDeletePayloadSchema = z.object({ id: idSchema });
export const invoiceSyncTransactionsPayloadSchema = z.object({
  id: idSchema,
  sync: z.boolean(),
});

export const invoiceInfoSchema = z.object({
  id: idSchema,
  date: z
    .date()
    .nullable()
    .transform((date) => (date ? formatDate(date, "yyyy-MM-dd") : null)),
  title: z.string(),
  toName: z.string(),
  dueDate: z
    .date()
    .nullable()
    .transform((date) => (date ? formatDate(date, "yyyy-MM-dd") : null)),
  docNumber: z.string(),
  description: z.string(),
  reviewStatus: invoiceStatusPayloadSchema,
});

export const invoiceUpdateInfoPayloadSchema = invoiceInfoSchema;

export const invoiceUpdateLinesInternalPayloadSchema = z.object({
  lines: z.array(
    invoiceLineMutateSchema.or(z.object({ isExtra: z.literal(true) }))
  ),
});

export const invoiceUpdateMarkupsInternalPayloadSchema = z.object({
  markups: z.array(invoiceMarkupMutateSchema),
});

export const invoiceUpdatePayloadSchema = z
  .object({ reviewStatus: invoiceStatusPayloadSchema })
  .and(invoiceInfoSchema)
  .and(invoiceUpdateLinesInternalPayloadSchema)
  .and(invoiceUpdateMarkupsInternalPayloadSchema)
  .transform((invoice) => ({ ...transformObjReviewStatus(invoice) }));

export const invoiceUpdatePartialPayloadSchema = z
  .object({ reviewStatus: invoiceStatusPayloadSchema })
  .partial()
  .and(invoiceInfoSchema.partial())
  .and(invoiceUpdateLinesInternalPayloadSchema.partial())
  .and(invoiceUpdateMarkupsInternalPayloadSchema.partial())
  .transform((invoice) => ({ ...transformObjReviewStatus(invoice) }));

const signalSchema = z.object({
  addEventListener: z.function(z.tuple([z.any(), z.any()]), z.void()),
});

export const invoiceUpdateLinesPayloadSchema = z.object({
  id: idSchema,
  lines: arraySchema(lineSchema.or(z.object({ isExtra: z.literal(true) }))),
  queue: z.array(z.promise(z.unknown())).optional(),
  signal: signalSchema.optional(),
});

export const invoiceUpdateMarkupsPayloadSchema = z.object({
  id: idSchema,
  markups: arraySchema(markupSchema),
});

export const downloadInvoiceFilesRequestSchema = z.object({
  id: idSchema,
  params: z.string(),
});

export const initiateInvoiceDownloadSchema = z.object({
  draw_package: z.boolean().default(false).optional(),
  cover_page: z.boolean().default(false).optional(),
  draw_request_subtotaled_vendor: z.boolean().default(false).optional(),
  draw_request_no_subtotals: z.boolean().default(false).optional(),
  draw_request_by_category: z.boolean().default(false).optional(),
  draw_schedule: z.boolean().default(false).optional(),
  changes: z.boolean().default(false).optional(),
  transaction_backup: z.boolean().default(false).optional(),
  draw_package_xlsx: z.boolean().default(false).optional(),
  transaction_backup_images_zip: z.boolean().default(false).optional(),
  sort_draw_schedule_by: z.string().default("item_account"),
  sort_line_items_by: z.string().default("item_account"),
});

export const initiateDownloadInvoiceFilesRequestSchema = z.object({
  id: idSchema,
  body: initiateInvoiceDownloadSchema,
});
