import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { vendorsGetResponseSchema } from "./response/schema";
import type { VendorsGetPayload, VendorsGetResponse } from "./types";

const enhancedApi = api.enhanceEndpoints({ addTagTypes: ["Vendors"] });

/*
These endpoints correspond to the vendors resource.
*/
export const vendorsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query<VendorsGetResponse, VendorsGetPayload>({
      query: (queryString: string) => {
        return {
          url: `vendors/?${queryString}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              vendorsGetResponseSchema
            );
          },
        };
      },
      providesTags: () => ["Vendors"],
    }),
    exportVendorDocuments: builder.query<string, URLSearchParams>({
      query: (params) => ({
        url: `vendors/export/`,
        params,
        responseHandler: async (response) => {
          const data = await response.json();
          return data?.id ? (data.id as string) : data;
        },
      }),
    }),
  }),
});

export const { useGetVendorsQuery, useLazyExportVendorDocumentsQuery } =
  vendorsApi;
