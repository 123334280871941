import React, { useEffect, useRef } from "react";
import { type ColumnSizingInstance } from "@tanstack/react-table";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { mergeRefs } from "../../utils/merge-refs";

const DEFAULT_COMPONENT = "div";

const ResizableTableColumn = forwardRefAs<
  typeof DEFAULT_COMPONENT,
  TableColumnProps
>(
  (
    { as: Component = "div", columnId, tableRef, setColumnSizing, ...props },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);

    const updateSize = useEvent(() => {
      const el = internalRef.current;

      if (!el) return;

      /**
       * This code was based on https://github.com/TanStack/table/discussions/3947#discussioncomment-9564867
       */
      setColumnSizing((prevSizes) => {
        const nextSizes = {
          ...prevSizes,
          [columnId]: el.getBoundingClientRect().width,
        };

        return isEqual(prevSizes, nextSizes) ? prevSizes : nextSizes;
      });
    });

    useEffect(() => {
      requestAnimationFrame(() => updateSize());
    }, [updateSize]);

    useResizeObserver(tableRef, () => updateSize());

    return (
      <Component
        ref={mergeRefs(ref, internalRef)}
        {...props}
        {...(is.string(Component) ? {} : { as: "div" })}
      />
    );
  }
);

ResizableTableColumn.displayName = "ResizableTableColumn";

type TableColumnProps = {
  sticky?: "left" | "right" | false;
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
  columnId: string;
  setColumnSizing: ColumnSizingInstance["setColumnSizing"];
};

export const TableColumn = forwardRefAs<
  typeof DEFAULT_COMPONENT,
  TableColumnProps
>(
  (
    {
      as: Component = "div",
      sticky,
      columnId,
      setColumnSizing,
      tableRef,
      ...props
    },
    ref
  ) => {
    return sticky ? (
      <ResizableTableColumn
        as={Component}
        ref={ref}
        tableRef={tableRef}
        columnId={columnId}
        setColumnSizing={setColumnSizing}
        {...props}
      />
    ) : (
      <Component ref={ref} {...props} />
    );
  }
);

TableColumn.displayName = "TableColumn";
