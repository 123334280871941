import React, { Fragment, useEffect, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Table,
  type TableColumn,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { TableFilterControls } from "@components/table-filter";
import { defaultArrayFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { WorkflowApprovers } from "@components/workflow-approvers";
import type { Workflow as WorkflowItem } from "@shared/api/workflows";
import { usePeopleSimplified } from "@shared/hooks/usePeopleSimplified";
import { useRolesSimplified } from "@shared/hooks/useRolesSimplified";
import { useWorkflowAction, useWorkflowInfo } from "@shared/store/workflow";
import { useDrawerVisibility } from "@store/ui";

import { WorkflowTitle } from "./workflow-title";

const READABLE_TYPE = {
  bill: "Bills",
  // expense: "Receipts",
  // invoice: "Draws",
} as const;

const TYPES_DATA = Object.entries(READABLE_TYPE).map(([key, value]) => ({
  label: value,
  value: key,
  groupLabel: "Type",
}));

const WorkflowColumnTitle = ({ title, isDefault }: WorkflowItem) => {
  const defaultTitle = (
    <Flex gap="sm" align="center">
      <Text size="md" weight="bold">
        By default{" "}
      </Text>
      <Tooltip
        as={Icon}
        size="sm"
        name="info-circle"
        message="If a transaction doesn't match one of the custom approval rules you create, it will go through this approval process"
      />
    </Flex>
  );

  if (title) {
    return (
      <Flex gap="sm" align="center">
        <Text size="md" weight="bold">
          {title}
        </Text>
        {isDefault ? <> - {defaultTitle}</> : null}
      </Flex>
    );
  }

  return isDefault ? defaultTitle : null;
};

export const ApprovalWorkflows = () => {
  const roles = useRolesSimplified(true);

  const people = usePeopleSimplified(true);

  const { setVisible } = useDrawerVisibility("workflow");

  const confirmDeleteWorkflowDialog = useDialog();

  const {
    loadWorkflows,
    deleteWorkflow,
    setCurrentWorkflow,
    duplicateWorkflow,
    resetCurrentWorkflow,
  } = useWorkflowAction();

  const { workflows, currentWorkflow } = useWorkflowInfo();

  const { filters, setFilters, rawFilters } = useTableFilters({
    storageKey: "workflow",
    formatter: defaultArrayFormatter,
  });

  const onNewWorkflowClick = useEvent(() => {
    resetCurrentWorkflow();
    setVisible(true);
  });

  const onEditWorkflow = useEvent((workflow) => {
    setCurrentWorkflow(workflow);
    setVisible(true);
  });

  const onConfirmDeleteWorkflow = useEvent((workflow) => {
    setCurrentWorkflow(workflow);
    confirmDeleteWorkflowDialog.show();
  });

  const onDeleteWorkflow = useEvent(() => {
    deleteWorkflow(currentWorkflow);
    confirmDeleteWorkflowDialog.hide();
  });

  const onDuplicateWorkflow = useEvent((workflow) => {
    duplicateWorkflow(workflow);
    setVisible(true);
  });

  const onChangeFilters = useEvent((filters) => {
    setFilters(filters);
  });

  const columns: TableColumn<WorkflowItem>[] = useMemo(
    () => [
      {
        id: "workflow",
        width: "fill",
        minWidth: "content",
        render: (row) => (
          <Flex gap="md" direction="column">
            <WorkflowColumnTitle {...row} />
            <WorkflowTitle workflow={row} />
            <WorkflowApprovers steps={row.steps} />
            <div>
              Applies to{" "}
              {row.types
                .filter((type) => type in READABLE_TYPE)
                .map((type, i) => (
                  <Fragment key={type}>
                    {i !== 0 ? " and " : ""}
                    <Tag color="warning">
                      {READABLE_TYPE[type as keyof typeof READABLE_TYPE]}
                    </Tag>
                  </Fragment>
                ))}
            </div>
          </Flex>
        ),
      },
      {
        id: "action",
        render: (row) => (
          <Flex justify="flex-end">
            <Button
              color="neutral"
              variant="text"
              aria-label="Edit workflow"
              onClick={() => onEditWorkflow(row)}
            >
              <Icon name="pen" />
            </Button>
            <Button
              color="neutral"
              variant="text"
              aria-label="Copy workflow"
              onClick={() => onDuplicateWorkflow(row)}
            >
              <Icon name="copy" />
            </Button>
            <Button
              color="neutral"
              variant="text"
              aria-label="Delete workflow"
              data-testid="delete-workflow-icon"
              onClick={() => onConfirmDeleteWorkflow(row)}
            >
              <Icon name="trash" />
            </Button>
          </Flex>
        ),
      },
    ],
    [onConfirmDeleteWorkflow, onDuplicateWorkflow, onEditWorkflow]
  );

  const extraData = useMemo(
    () => [...people.data, ...roles.data, ...TYPES_DATA],
    [people.data, roles.data]
  );

  useEffect(() => {
    if (filters) loadWorkflows(filters);
  }, [loadWorkflows, filters]);

  return (
    <Main>
      <MainHeader>
        <Flex align="center" height="full" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Approval workflows</MainTitle>
            <MainSubtitle>
              Create rules for how all types of spend should get approved
              <br />
              Rules allow you to automatically send bills to the right people
              for approval based on vendor, cost code, and/or transaction amount
            </MainSubtitle>
          </Flex>
          <Button onClick={onNewWorkflowClick}>New workflow</Button>
        </Flex>
      </MainHeader>
      <MainContent>
        <Flex direction="column" gap="lg" shrink={false}>
          <TableFilterControls
            filters={rawFilters}
            extraData={extraData}
            withFilterTags
            includeFilters={["costCodes", "customers"]}
            onFiltersChange={onChangeFilters}
          />
          <Table
            id="approval-workflows-table"
            data={workflows}
            header={{ hide: true }}
            columns={columns}
            bordered={false}
            emptyState={{
              title: "There are no workflows that match your search terms",
            }}
          />
        </Flex>
        <Dialog
          variant="confirmation"
          show={confirmDeleteWorkflowDialog.isVisible}
          onClose={confirmDeleteWorkflowDialog.hide}
        >
          <DialogHeader>Delete workflow?</DialogHeader>
          <DialogFooter>
            <Button
              block
              color="neutral"
              variant="text"
              onClick={confirmDeleteWorkflowDialog.hide}
            >
              Cancel
            </Button>
            <Button
              block
              color="error"
              data-testid="confirm-delete-workflow"
              onClick={onDeleteWorkflow}
            >
              Delete workflow
            </Button>
          </DialogFooter>
        </Dialog>
      </MainContent>
    </Main>
  );
};
