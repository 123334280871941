import { useEffect, useId, useRef, useState } from "react";
import { isEqual } from "@adaptive/design-system/utils";

export const useObjectInternalVersion = (object: any): string => {
  const id = useId();
  const objectRef = useRef(object);
  const [internalVersion, setInternalVersion] = useState(0);

  useEffect(() => {
    if (!isEqual(object, objectRef.current)) {
      setInternalVersion((v) => v + 1);
    }
    objectRef.current = object;
  }, [object]);

  return `${id}-${internalVersion}`;
};
