import { getApi, getUrlBuilder, invalidate5xx } from "@utils/api";

import { type Identifiable } from "../expenses";

import { documentPutSchema } from "./request/schema";
import { documentResponseSchema } from "./response/schema";
import { handleResponse } from "./old-api";
import type { DocumentResponse, OutgoingDocumentPayload } from "./types";

type Realm = { realm: string };

type Payload = OutgoingDocumentPayload & Realm;

export const api = getApi([invalidate5xx]);

const urlBuilder = getUrlBuilder("/api/documents/");

export const get = async (opts: Identifiable) => {
  const resp = await api.get<DocumentResponse>(urlBuilder.forItem(opts));
  return handleResponse(resp, documentResponseSchema);
};

export const put = async (doc: Payload & Identifiable) => {
  const data = documentPutSchema.parse(doc) as object;

  const payload = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    payload.append(key, value);
  });

  const config = { headers: { "content-type": "multipart/form-data" } };
  const resp = await api.put<DocumentResponse>(
    urlBuilder.forItem(doc),
    payload,
    config
  );

  return handleResponse(resp, documentResponseSchema);
};

export const create = async (doc: Payload) => {
  const data = documentPutSchema.parse(doc) as object;

  const payload = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    payload.append(key, value);
  });

  const config = { headers: { "content-type": "multipart/form-data" } };
  const resp = await api.post<DocumentResponse>(
    urlBuilder.forCollection(),
    payload,
    config
  );

  return handleResponse(resp, documentResponseSchema);
};

export const remove = async (doc: Identifiable) => {
  return await api.delete(urlBuilder.forItem(doc));
};

export const documentsApi = {
  create,
  get,
  put,
  remove,
};
