export const DATE_FORMAT = "MM/dd/yyyy";
export const FORMATTED_DATE_LENGTH = 10;
export const RANGE_DATE_SEPARATOR = " - ";
export const FORMATTED_RANGE_DATE_LENGTH = 23;
export const BREAKPOINTS = {
  mobile: 0, // 0 to 991
  tablet: 992, // 992 to 1199
  desktop: 1200, // 1200 to 1439
  largeDesktop: 1440, // 1440 and beyond
} as const;
export const BREAKPOINTS_KEYS = Object.keys(
  BREAKPOINTS
) as (keyof typeof BREAKPOINTS)[];
