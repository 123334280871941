import React from "react";
import { Button, Flex, Icon, Loader, Text } from "@adaptive/design-system";
import { type PDF_BASE64_TYPE, PdfViewer } from "@components/pdf-viewer";

import { STRINGS } from "../constants";

type TemplatePreviewProps = {
  docSource?: string;
  docType?: typeof PDF_BASE64_TYPE;
  onRefresh?: () => void;
  emptyMessage?: string;
  loading?: boolean;
  disableRefresh?: boolean;
};

export const TemplatePreview = ({
  docSource,
  docType,
  onRefresh,
  emptyMessage = STRINGS.PREVIEW_UNAVAILABLE,
  loading,
  disableRefresh,
}: TemplatePreviewProps) => {
  return (
    <Flex
      background="neutral-200"
      height="full"
      align="center"
      justify="center"
      direction="column"
      padding="xl"
      gap="lg"
    >
      {onRefresh && (
        <Flex width="full" justify="flex-end">
          <Button
            size="sm"
            onClick={onRefresh}
            disabled={loading || disableRefresh}
          >
            <Icon name="refresh" animate={loading ? "spin" : undefined} />
            {STRINGS.PREVIEW_REFRESH}
          </Button>
        </Flex>
      )}
      {loading ? (
        <Flex grow height="full" width="full" justify="center">
          <Loader size="2xl" />
        </Flex>
      ) : docSource ? (
        <Flex grow height="full" width="full">
          <PdfViewer src={`${docType ?? ""}${docSource}`} />
        </Flex>
      ) : (
        <Flex
          width="350px"
          height="full"
          direction="column"
          align="center"
          justify="center"
          gap="xl"
        >
          <Icon name="file-image" size="2xl" color="neutral-500" />
          <Text align="center">{emptyMessage}</Text>
        </Flex>
      )}
    </Flex>
  );
};
