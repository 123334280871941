import React from "react";
import {
  Button,
  Card,
  ComboBox,
  DateField,
  Flex,
  Icon,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useForm } from "@adaptive/design-system/hooks";
import type { DocumentType } from "@api/vendors/types";
import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";
import { useDocumentAction } from "@store/vendors";
import { z } from "zod";

import { DropZone } from "../draggable";

export type VendorDocument = {
  id: string | number;
  type: DocumentType;
  document?: File | null;
  expirationDate: Date | null;
};

export type VendorDocumentEditFormProps = {
  onSubmit: (values: VendorDocument) => Promise<void>;
  initialValues: Omit<VendorDocument, "document">;
};

const schema = z.object({
  type: z.enum([
    "INSURANCE",
    "W9",
    "CONTRACT",
    "OTHER",
    "WORKERS_COMP",
    "GENERAL_LIABILITY",
    "COMBINED_COI",
  ]),
  document: z.instanceof(File).nullish(),
  expirationDate: z.date().nullable(),
});

type Fields = z.infer<typeof schema>;

export const VendorDocumentEditForm = ({
  onSubmit,
  initialValues,
}: VendorDocumentEditFormProps) => {
  const id = initialValues?.id ?? "";

  const { toggleEditDocument } = useDocumentAction();

  const form = useForm<Fields>({
    schema,
    onSubmit: (values) => onSubmit({ id, ...values }),
    initialValues,
  });

  const onDrop = useEvent((files: File[]) =>
    form.setValue("document", files[0])
  );

  const onCancel = useEvent(() => toggleEditDocument(id));

  return (
    <Card>
      <Flex direction="column" gap="xl">
        <DropZone
          showBorder
          onDrop={onDrop}
          hasPermission={!form.isSubmitting}
          justify="space-between"
          gap="2xl"
          flip="horizontal"
          padding="2xl"
          idleMessage="Upload or drag and drop a file"
          pendingMessage="We are processing your file"
          draggingMessage="Drop a file here to upload it!"
        >
          {form.values.document ? (
            <>
              <Flex align="center" justify="flex-start">
                <Icon name="image" size="2xl" />
              </Flex>
              <Flex grow direction="column" gap="sm">
                <Text weight="bold">Filename</Text>
                <Text truncate>{form.values.document.name}</Text>
              </Flex>
            </>
          ) : null}
        </DropZone>
        <Flex justify="space-between" align="center" gap="xl">
          <ComboBox
            {...form.register("type")}
            required
            label="Document type"
            size="sm"
            disabled={form.isSubmitting}
            data={DOCUMENT_TYPE_OPTIONS}
          />
          <DateField
            size="sm"
            label="Expiration Date"
            {...form.register({
              name: "expirationDate",
              type: "date",
            })}
            disabled={form.isSubmitting}
          />
        </Flex>
      </Flex>
      <Flex gap="xl">
        <Button
          block
          color="neutral"
          variant="ghost"
          size="sm"
          disabled={form.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          block
          size="sm"
          disabled={!form.isValid || form.isSubmitting}
          onClick={() => form.submit()}
        >
          {form.isSubmitting ? <Loader /> : "Save"}
        </Button>
      </Flex>
    </Card>
  );
};
