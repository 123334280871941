import React, { useMemo } from "react";
import { Button, Icon, Tooltip } from "@adaptive/design-system";
import { useMultiStepDialog } from "@adaptive/design-system/hooks";
import { useJobSettings } from "@src/jobs";
import {
  type ChangeDialogStep,
  ChangesDialog,
} from "@src/jobs/detail-view/budget/changes-dialog";

type AddChangeButtonProps = {
  size?: "sm" | "md";
  jobCostMethods: string[];
};

export const AddChangeButton = ({
  jobCostMethods,
  size = "sm",
}: AddChangeButtonProps) => {
  const addChangeDialog = useMultiStepDialog<ChangeDialogStep>({
    lazy: true,
    initialStep: "create-change",
  });

  const { changeTrackingEnabled } = useJobSettings();

  const enhancedJobCostMethods = useMemo(
    () =>
      jobCostMethods.map((jobCostMethod) => ({
        amount: 0,
        jobCostMethod,
        externalAmount: 0,
      })),
    [jobCostMethods]
  );

  return (
    <>
      {addChangeDialog.isRendered && (
        <ChangesDialog
          mode="create"
          dialog={addChangeDialog}
          onSubmitChange={addChangeDialog.hide}
          jobCostMethods={enhancedJobCostMethods}
        />
      )}
      <Tooltip
        message={
          changeTrackingEnabled
            ? undefined
            : "Enable change tracking in the job settings to add change"
        }
        placement="top"
      >
        <Button
          variant="ghost"
          color="primary"
          size={size}
          disabled={!changeTrackingEnabled}
          onClick={addChangeDialog.show}
          data-testid="budget-add-change-button"
        >
          <Icon name="plus" />
          Add a change
        </Button>
      </Tooltip>
    </>
  );
};
