import React, { type PropsWithChildren } from "react";
import { Text } from "@adaptive/design-system";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import cn from "clsx";

import styles from "./main.module.css";

const TITLE_SIZES = {
  sm: "lg",
  md: "2xl",
} as const;

type Props = PropsWithChildren<{
  size?: ResponsiveProp<keyof typeof TITLE_SIZES>;
  truncate?: boolean;
  className?: string;
}>;

export const MainTitle = ({ className, size, ...props }: Props) => {
  const titleSize = useResponsiveProp(size, "md");

  return (
    <Text
      as="h2"
      size={TITLE_SIZES[titleSize]}
      weight="bold"
      className={cn(styles["main-title"], className)}
      {...props}
    />
  );
};
