import React, { type PropsWithChildren, type RefObject } from "react";

import { useBreakpoints } from "../../hooks/use-breakpoints";

import { Context } from "./responsive-provider-context";

type Props = PropsWithChildren<{
  breakpoints: Record<string, number>;
  containerRef: RefObject<Window> | RefObject<HTMLElement>;
  initialBreakpoint: string;
}>;

export const ResponsiveProvider = ({
  children,
  breakpoints,
  containerRef,
  initialBreakpoint,
}: Props) => {
  const value = useBreakpoints({
    breakpoints,
    containerRef,
    initialBreakpoint,
  });

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
