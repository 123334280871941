import { formatNumber } from "../format-number";

type ParsePercentageOptions = {
  maximumFractionDigits?: number;
};

export const parsePercentage = (
  str: string,
  options: ParsePercentageOptions = {}
): number | undefined => {
  const enhancedStr = formatNumber(str, true);

  return enhancedStr.length
    ? Number(Number(enhancedStr).toFixed(options.maximumFractionDigits ?? 2))
    : undefined;
};
