import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  dialog,
  Flex,
  Icon,
  Link,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  formatDate,
  parseDate,
  parseStringCopy,
  sortBy,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  useDeleteLienWaiverTemplateMutation,
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useSaveLienWaiverTemplateMutation,
} from "@lien-waiver/api";
import type { LienWaiverTemplate } from "@lien-waiver/types";
import * as analytics from "@utils/analytics";

import { STRINGS } from "../constants";
import { getBills } from "../utils/get-bill-error-data";
import {
  lienWaiverTemplatesSelector,
  lienWaiverTemplateTypesSelector,
} from "../utils/selectors";

type LienWaiverTemplateWithTypeName = LienWaiverTemplate & { typeName: string };

export const LienWaiverTemplatesList = () => {
  const navigate = useNavigate();

  useGetLienWaiverTemplatesQuery();
  useGetLienWaiverTypesListQuery();

  const lienWaiverTemplatesData = useSelector(
    lienWaiverTemplatesSelector
  ) as LienWaiverTemplate[];
  const lienWaiverTypesData = useSelector(lienWaiverTemplateTypesSelector);

  const getTypeName = useCallback(
    (typeUrl: string) => {
      const type = lienWaiverTypesData.find((type) => type.url === typeUrl);
      return type?.name;
    },
    [lienWaiverTypesData]
  );

  const lienWaiverTemplates = useMemo<LienWaiverTemplateWithTypeName[]>(() => {
    const enhancedTemplates = lienWaiverTemplatesData.map((template) => ({
      ...template,
      typeName: getTypeName(template.type) || "Lien Waiver Template",
    }));

    return sortBy(enhancedTemplates, "typeName");
  }, [getTypeName, lienWaiverTemplatesData]);

  const [saveLienWaiverTemplateTrigger, saveLienWaiverTemplateInfo] =
    useSaveLienWaiverTemplateMutation();
  const [deleteLienWaiverTemplateTrigger, deleteLienWaiverMutationInfo] =
    useDeleteLienWaiverTemplateMutation();

  const isLoading =
    saveLienWaiverTemplateInfo.isLoading ||
    deleteLienWaiverMutationInfo.isLoading;

  const toggleDefaultLienWaiver = async (
    template: LienWaiverTemplateWithTypeName
  ) => {
    const isNewDefault = !template.isDefault;
    const oldDefaultTemplate = lienWaiverTemplatesData.find(
      (temp) => temp.isDefault
    );

    analytics.track(
      isNewDefault
        ? "markLienWaiverTemplateAsDefault"
        : "unmarkLienWaiverTemplateAsDefault",
      { templateType: template.typeName }
    );

    await saveLienWaiverTemplateTrigger({
      id: template.id,
      type: template.type,
      contentHtml: template.contentHtml,
      isDefault: !template.isDefault,
      signatureFields: template.signatureFields,
    });
    toast.success(
      parseStringCopy(
        isNewDefault
          ? STRINGS.TEMPLATE_SET_AS_DEFAULT
          : STRINGS.TEMPLATE_REMOVED_AS_DEFAULT,
        {
          template: template.typeName,
        }
      )
    );
    if (isNewDefault && oldDefaultTemplate) {
      await saveLienWaiverTemplateTrigger({
        id: oldDefaultTemplate.id,
        type: oldDefaultTemplate.type,
        contentHtml: oldDefaultTemplate.contentHtml,
        isDefault: false,
        signatureFields: oldDefaultTemplate.signatureFields,
      });
    }
  };

  const deleteLienWaiverTemplate = (
    template: LienWaiverTemplateWithTypeName
  ) => {
    dialog.confirmation({
      title: STRINGS.DELETE_DIALOG_TITLE,
      message: STRINGS.DELETE_DIALOG_MESSAGE,
      action: {
        primary: {
          onClick: async () => {
            analytics.track("deleteLienWaiverTemplate", {
              templateType: template.typeName,
            });
            try {
              await deleteLienWaiverTemplateTrigger({
                id: template.id,
              }).unwrap();
              toast.success(
                <Flex as="span" direction="column">
                  <Text as="strong" weight="bold">
                    {parseStringCopy(STRINGS.TEMPLATE_DELETED_TITLE, {
                      template: template.typeName,
                    })}
                  </Text>
                  <Text as="span">{STRINGS.TEMPLATE_DELETED_MESSAGE}</Text>
                </Flex>
              );
            } catch (e) {
              const bills = getBills(e);
              if (bills.length > 0) {
                toast.error(
                  <Flex as="span" direction="column">
                    <Text>
                      {parseStringCopy(STRINGS.TEMPLATE_DELETED_ERROR_TITLE, {
                        template: template.typeName,
                        bills: bills.length,
                      })}{" "}
                      <Link href="/bills?status=all">
                        {STRINGS.TEMPLATE_DELETED_ERROR_SEE_BILLS}
                      </Link>
                    </Text>
                  </Flex>
                );
              } else {
                handleErrors(e);
              }
            }
          },
          color: "error",
          children: STRINGS.DELETE_DIALOG_CONFIRM,
        },
      },
    });
  };

  return (
    <>
      <Tooltip
        message={
          lienWaiverTemplatesData.length === lienWaiverTypesData.length &&
          STRINGS.DISABLED_ADD_TEMPLATE
        }
      >
        <Button
          variant="ghost"
          onClick={() =>
            navigate("/settings/company/lien-waiver-template/create")
          }
          disabled={
            lienWaiverTemplatesData.length === lienWaiverTypesData.length
          }
        >
          <Icon name="plus" size="sm" />
          {STRINGS.ADD_TEMPLATE}
        </Button>
      </Tooltip>
      <Flex width="full" gap="xl" wrap>
        {lienWaiverTemplates.map((template) => (
          <Card
            key={template.id}
            style={{ width: "calc(50% - var(--spacing-md)" }}
            size="sm"
          >
            <Flex
              gap="md"
              direction={{ mobile: "column", desktop: "row" }}
              align={{ mobile: "flex-start", desktop: "center" }}
              justify="space-between"
            >
              <div>
                <Text weight="bold">
                  {template.typeName}
                  <br />
                  <Text as="small" size="sm" color="neutral-600">
                    {parseStringCopy(STRINGS.TEMPLATE_LAST_SAVED, {
                      date: formatDate(
                        parseDate(template.updatedAt, "iso"),
                        "P"
                      ),
                      time: formatDate(
                        parseDate(template.updatedAt, "iso"),
                        "H:mmaaa"
                      ),
                    })}
                  </Text>
                </Text>
              </div>
              <Flex>
                <Tooltip
                  message={
                    template.isDefault
                      ? STRINGS.REMOVE_DEFAULT
                      : STRINGS.SET_DEFAULT
                  }
                >
                  <Button
                    variant="text"
                    block
                    color={template.isDefault ? "primary" : "neutral"}
                    disabled={isLoading}
                    onClick={() => toggleDefaultLienWaiver(template)}
                  >
                    <Icon
                      name="star"
                      size="lg"
                      variant={template.isDefault ? "solid" : "light"}
                    />
                  </Button>
                </Tooltip>

                <Tooltip message="Edit template">
                  <Button
                    variant="text"
                    block
                    color="neutral"
                    disabled={isLoading}
                    onClick={() =>
                      navigate(
                        `/settings/company/lien-waiver-template/${template.id}`
                      )
                    }
                  >
                    <Icon name="pen" size="lg" />
                  </Button>
                </Tooltip>

                <Tooltip message={STRINGS.DELETE_DIALOG_CONFIRM}>
                  <Button
                    variant="text"
                    block
                    color="neutral"
                    disabled={isLoading}
                    onClick={() => deleteLienWaiverTemplate(template)}
                  >
                    <Icon name="trash" size="lg" />
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    </>
  );
};
