import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  toast,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { formatPercentage } from "@adaptive/design-system/utils";
import type { InvoiceGetResponse } from "@api/invoices";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { divide } from "@utils/divide";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";

import { PERCENTAGE_FORMAT } from "../constants";
import {
  MarkupPercentageForm,
  type MarkupPercentageFormProps,
} from "../markup";

import {
  useInvoiceActions,
  useInvoiceLines,
  useInvoiceMarkups,
} from "./invoice-context";
import type { ItemOption, ManageMarkupDialogProps, Markup } from "./types";

const FORM_ID = "invoice-edit-separate-percent-markup";
const getSelectedLines = (
  invoiceLines: InvoiceGetResponse["lines"],
  markup: Markup
) => {
  return invoiceLines.filter((line) =>
    markup.lines.some((lineId) => lineId === line.id)
  );
};

export const InvoiceEditSeparatePercentMarkupDialog = ({
  markup,
  dialog,
}: ManageMarkupDialogProps) => {
  const invoiceLines = useInvoiceLines();

  const invoiceMarkups = useInvoiceMarkups();

  const [isValid, setIsValid] = useState(false);

  const { updateInvoiceMarkups } = useInvoiceActions();

  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified();

  const onSubmit = useEvent<MarkupPercentageFormProps["onSubmit"]>(
    async (values) => {
      if (!markup) return;

      const costCode = costCodeAccountsSimplified.data.find(
        (costCode) => costCode.value === values.costCode
      ) as ItemOption;

      const nextMarkups = invoiceMarkups.map((invoiceMarkup) => {
        if (invoiceMarkup.id === markup.id) {
          const percentage = values.value;
          const total = values.lines.reduce((acc, line) => {
            const invoiceLine = invoiceLines.find(
              (item) => item.id === line.id
            );
            return sum(acc, invoiceLine?.amount ?? 0);
          }, 0);
          const newAmount = total * divide(percentage, 100);
          return {
            ...invoiceMarkup,
            percent: percentage,
            jobCostMethod: {
              id: Number(parseRefinementIdFromUrl(costCode.value)!),
              url: costCode.value,
              parent: costCode.parent,
              displayName: costCode.label,
            },
            lines: values.lines.map((line) => line.id),
            amount: newAmount,
          };
        }
        return invoiceMarkup;
      });

      updateInvoiceMarkups(nextMarkups);

      toast.success(
        `Markup ${costCode?.label} updated for ${formatPercentage(
          values.value,
          PERCENTAGE_FORMAT
        )}`
      );

      dialog.hide();
    }
  );

  const selectedLines = useDeepMemo(
    () => (markup ? getSelectedLines(invoiceLines, markup) : []),
    [markup, invoiceLines]
  );

  const initialValues = useDeepMemo(
    () => ({
      lines: selectedLines,
      value: markup?.percent ?? 0,
      costCode: markup?.jobCostMethod?.url ?? "",
      isSeparateLine: false,
    }),
    [markup, selectedLines]
  );

  return (
    <>
      {dialog.isRendered && (
        <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
          <DialogHeader>Edit draw markup</DialogHeader>
          <DialogContent>
            <Flex height="420px">
              <MarkupPercentageForm
                lines={invoiceLines}
                formId={FORM_ID}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onValidityChange={setIsValid}
                allowedType="separate-line"
              />
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button block color="neutral" onClick={dialog.hide} variant="text">
              Cancel
            </Button>
            <Button block type="submit" form={FORM_ID} disabled={!isValid}>
              Save
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
