import { useCallback, useEffect } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatDate, parseDate } from "@adaptive/design-system/utils";

import { useIntegrationType } from "../hooks/use-integration-type";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useClientInfo, useUserInfo } from "../store/user";
import { toggleChatVisibility } from "../store/user/slice";

const historyMonkeyPatch = () => {
  const pushState = window.history.pushState;

  window.history.pushState = (...args: any) => {
    pushState.apply(window.history, args);
    window.dispatchEvent(new Event("location-change"));
  };

  return () => {
    window.history.pushState = pushState;
  };
};

export const Chat = () => {
  const dispatch = useAppDispatch();

  const { user } = useUserInfo();

  const { realmId, client } = useClientInfo();

  const integrationType = useIntegrationType();

  const isChatVisible = useAppSelector((state) => state.user.isChatVisible);

  const hide = useCallback(() => {
    window.Intercom?.("hide");
    dispatch(toggleChatVisibility(false));
    document.querySelector("#intercom-container")?.classList.remove("-show");
  }, [dispatch]);

  const show = useCallback(() => {
    window.Intercom?.("show");
    dispatch(toggleChatVisibility(true));
    document.querySelector("#intercom-container")?.classList.add("-show");
  }, [dispatch]);

  const onLocationChange = useEvent(() => {
    window.Intercom?.("update");
  });

  useEffect(() => {
    const revertHistoryMonkeyPatch = historyMonkeyPatch();
    window.addEventListener("location-change", onLocationChange);

    return () => {
      window.removeEventListener("location-change", onLocationChange);
      revertHistoryMonkeyPatch();
    };
  }, [onLocationChange]);

  useEffect(() => {
    window.Intercom?.("boot", {
      ...(window.intercomSettings ? window.intercomSettings : {}),
      name: user.full_name,
      email: user.email,
      user_id: String(user.id),
      realm_id: realmId,
      user_hash: user.hash,
      realm_name: client?.name || "Unknown",
      created_at: formatDate(parseDate(user.created), "t"),
      qb_integration_type: integrationType,
      hide_default_launcher: true,
    });
    window.Intercom?.("onHide", hide);
    window.Intercom?.("onShow", show);

    return () => {
      window.Intercom?.("shutdown");
    };
  }, [
    hide,
    show,
    user.id,
    realmId,
    user.hash,
    user.email,
    user.created,
    client?.name,
    user.full_name,
    integrationType,
  ]);

  useEffect(() => {
    isChatVisible ? show() : hide();
  }, [hide, show, isChatVisible]);

  return null;
};
