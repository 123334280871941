import { useMemo } from "react";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import {
  useVendorsSimplified,
  type UseVendorsSimplifiedProps,
} from "@hooks/use-vendors-simplified";
import { useClientInfo } from "@store/user";

import type { Option } from "../types";

import {
  useAccountsSimplified,
  type UseAccountsSimplifiedProps,
} from "./use-accounts-simplified";
import {
  useCostCodesSimplified,
  type UseCostCodesSimplifiedProps,
} from "./use-cost-codes-simplified";
import {
  useCustomersSimplified,
  type UseCustomersSimplifiedProps,
} from "./useCustomersSimplified";

type VendorsFilter =
  | "vendors"
  | (Omit<UseVendorsSimplifiedProps, "includeLabel" | "enabled"> & {
      name: "vendors";
    });
type AccountsFilter =
  | "accounts"
  | (Omit<UseAccountsSimplifiedProps, "includeLabel" | "enabled"> & {
      name: "accounts";
    });
type CustomersFilter =
  | "customers"
  | (Omit<UseCustomersSimplifiedProps, "includeLabel" | "enabled"> & {
      name: "customers";
    });
type CostCodesFilter =
  | "costCodes"
  | (Omit<UseCostCodesSimplifiedProps, "includeLabel" | "enabled"> & {
      name: "costCodes";
    });

export type UseTableFilterOptionsProps = {
  extraData?: Option[];
  includeFilters?: (
    | VendorsFilter
    | AccountsFilter
    | CustomersFilter
    | CostCodesFilter
  )[];
  extraDataLoading?: boolean;
};

const isFilterEnabled = (
  filters: Exclude<UseTableFilterOptionsProps["includeFilters"], undefined>,
  filter: string
) =>
  filters.some((item) =>
    typeof item === "string" ? filter === item : item.name === filter
  );

const parseFilter = (
  filters: Exclude<UseTableFilterOptionsProps["includeFilters"], undefined>,
  filter: string
) => {
  const item = filters.find((item) =>
    typeof item === "string" ? item === filter : item.name === filter
  );

  return typeof item === "string" ? {} : (item ?? {});
};

export const useTableFilterOptions = ({
  extraData = [],
  includeFilters = ["vendors", "costCodes", "accounts", "customers"],
  extraDataLoading = false,
}: UseTableFilterOptionsProps) => {
  const includeVendors = isFilterEnabled(includeFilters, "vendors");
  const vendors = useVendorsSimplified({
    enabled: includeVendors,
    includeLabel: true,
    ...parseFilter(includeFilters, "vendors"),
  });

  const includeCustomers = isFilterEnabled(includeFilters, "customers");

  const { client } = useClientInfo();
  const customers = useCustomersSimplified({
    active: client?.settings?.should_show_all_customers_in_dropdowns
      ? null
      : true,
    enabled: includeCustomers,
    includeLabel: true,
    showDisableAsEnabled: true,
    ...parseFilter(includeFilters, "customers"),
  });

  const includeAccounts = isFilterEnabled(includeFilters, "accounts");
  const accounts = useAccountsSimplified({
    enabled: includeAccounts,
    filters: {
      only_line_item_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
    includeLabel: true,
    ...parseFilter(includeFilters, "accounts"),
  });

  const includeCostCodes = isFilterEnabled(includeFilters, "costCodes");
  const costCodes = useCostCodesSimplified({
    enabled: includeCostCodes,
    includeLabel: true,
    showDisableAsEnabled: true,
    canItemsLinkToLinesDesktop: true,
    ...parseFilter(includeFilters, "costCodes"),
  });

  const filters = useDeepMemo(
    () => [
      ...(includeCostCodes ? [costCodes] : []),
      ...(includeAccounts ? [accounts] : []),
      ...(includeCustomers ? [customers] : []),
      ...(includeVendors ? [vendors] : []),
    ],
    [
      accounts,
      costCodes,
      customers,
      includeAccounts,
      includeCostCodes,
      includeCustomers,
      includeVendors,
      vendors,
    ]
  );

  const data = useMemo(
    () =>
      filters
        .reduce((acc, filter) => [...acc, ...filter.data], [] as Option[])
        .concat(extraData),
    [extraData, filters]
  );

  const status = useMemo(() => {
    const statuses = [
      ...filters.map((filter) => filter.status),
      extraDataLoading ? "loading" : "success",
    ];

    if (statuses.some((status) => status === "failed")) return "failed";

    if (statuses.some((status) => status === "loading")) return "loading";

    return "success";
  }, [filters, extraDataLoading]);

  return { data, status };
};
