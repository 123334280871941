import { useCallback, useState } from "react";

type UseLocalStorageState = <T>(
  key?: string,
  fallbackValue?: T
) => [T, (value: T) => void];

export const useLocalStorageState: UseLocalStorageState = (
  key,
  fallbackValue
) => {
  const [state, setState] = useState(() => {
    if (!key) return fallbackValue;

    const storedValue = localStorage.getItem(key);

    try {
      if (storedValue) {
        return JSON.parse(storedValue);
      }

      localStorage.setItem(key, JSON.stringify(fallbackValue));
      return fallbackValue;
    } catch (error) {
      /**
       * If there's an error parsing the stored value, we'll log
       * a warning and set the state to the fallback value.
       */
      // eslint-disable-next-line no-console
      console.warn(
        `Error parsing local storage value for key "${key}". Falling back to "${fallbackValue}".`,
        error
      );
      localStorage.setItem(key, JSON.stringify(fallbackValue));
      return fallbackValue;
    }
  });

  const enhancedSetState = useCallback<typeof setState>(
    (state) => {
      setState((previousState: typeof state) => {
        const nextState =
          typeof state === "function" ? state(previousState) : state;

        if (key) {
          localStorage.setItem(key, JSON.stringify(nextState));
        }

        return nextState;
      });
    },
    [key]
  );

  return [state, enhancedSetState];
};
