import React from "react";
import { Alert, AlertContent, AlertTitle } from "@adaptive/design-system";

export const PasswordRulesAlert = () => (
  <Alert variant="info">
    <AlertTitle>Password rules</AlertTitle>
    <AlertContent as="div">
      <ul>
        <li>Must be at least 8 characters long</li>
        <li>Must contain at least one letter</li>
        <li>Cannot be too similar to your name or email</li>
        <li>Cannot be a common password</li>
      </ul>
    </AlertContent>
  </Alert>
);
