import React, { useMemo } from "react";
import { dialog } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  VendorDocumentEditForm,
  type VendorDocumentEditFormProps,
} from "@components/vendor-document-edit-form";
import { VendorDocumentPreview } from "@components/vendor-document-preview";
import { useAppSelector } from "@store/hooks";
import { useDocumentAction, useVendorInfo } from "@store/vendors";
import { vendorSelector } from "@store/vendors/selectors";
import type { Document, SavedDocument } from "@store/vendors/types";

export const DocumentPreview = (props: Document & { isVirtual: boolean }) => {
  const { info } = useAppSelector(vendorSelector);
  const { type: documentType, expirationDate, isExpired, id } = props;
  const { removeDocument, toggleEditDocument, syncDocuments } =
    useDocumentAction();
  const { canManageNonPaymentInfo } = useVendorInfo();

  const onSubmit = useEvent<VendorDocumentEditFormProps["onSubmit"]>(
    async ({ type, document, expirationDate }) => {
      const payload = {
        id,
        type,
        ...(document ? { document } : {}),
        expirationDate,
      };

      if (info.id) await syncDocuments({ edited: [payload] });
    }
  );

  const editFormInitialValues = useMemo(
    () => ({
      id,
      type: documentType,
      expirationDate,
    }),
    [documentType, expirationDate, id]
  );

  const onDelete = useEvent(async () => {
    if (!info.id) return removeDocument(props);

    dialog.confirmation({
      title: "Remove document from vendor?",
      action: {
        primary: {
          color: "error",
          onClick: () => {
            const { payload } = removeDocument(props);
            syncDocuments({ removed: [payload as SavedDocument] });
          },
          children: "Remove",
        },
      },
    });
  });

  const onUpdate = useEvent(async () => {
    toggleEditDocument(props.id);
  });

  return props.isEditing ? (
    <VendorDocumentEditForm
      onSubmit={onSubmit}
      initialValues={editFormInitialValues}
    />
  ) : (
    <VendorDocumentPreview
      url={!props.isVirtual ? (props.document as string) : undefined}
      type={documentType}
      onDelete={
        canManageNonPaymentInfo && !props.isVirtual ? onDelete : undefined
      }
      onUpdate={canManageNonPaymentInfo ? onUpdate : undefined}
      isExpired={isExpired ? isExpired : undefined}
      expirationDate={expirationDate ? expirationDate : undefined}
    />
  );
};
