import { useDeepMemo } from "@adaptive/design-system/hooks";
import type { MarkupResponse } from "@api/budgets";
import { useJobSettings } from "@src/jobs/hooks";

import { getMarkupTotals } from "../utils";

import type { UseLineItemTotalsReturnType } from "./use-line-item-totals";

export type UseMarkupLineTotalsProps = { markupLines: MarkupResponse[] };

export const useMarkupLineTotals = ({
  markupLines,
}: UseMarkupLineTotalsProps): UseLineItemTotalsReturnType => {
  const { ownersAmountEnabled, changeTrackingEnabled } = useJobSettings();

  return useDeepMemo(
    () =>
      getMarkupTotals({
        data: markupLines,
        ownersAmountEnabled,
        changeTrackingEnabled,
      }),
    [markupLines, ownersAmountEnabled, changeTrackingEnabled]
  );
};
