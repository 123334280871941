import { useMemo, useState } from "react";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import type { StrictValuesFilters } from "@components/table-filter";
import type { QueryItem } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useClientInfo } from "@store/user";

import { useGetCardTransactionsQuery } from "../api/api";
import type { CardTransaction, GetCardTransactionsPayload } from "../api/types";
import { cardTransactionsFilterFormatter } from "../utils/card-transactions-filter-formatter";

type UseCardTransactionsParams = {
  order?: { order: string; setOrder: (order: string) => void };
  query?: GetCardTransactionsPayload;
  filters?: ReturnType<typeof useTableFilters<QueryItem[]>>;
  enabled?: boolean;
  enablePolling?: boolean;
  pagination?: ReturnType<typeof usePagination>;
  initialFilters?: GetCardTransactionsPayload;
};

const FIVE_MINUTES_IN_MS = 300000;

const EMPTY_DATA: CardTransaction[] = [];

const EMPTY_QUERY: GetCardTransactionsPayload = [];

const EMPTY_INITIAL_FILTERS: GetCardTransactionsPayload = [];

export const useCardTransactions = ({
  order: externalOrder,
  query = EMPTY_QUERY,
  enabled = true,
  enablePolling = false,
  filters: externalFilters,
  initialFilters = EMPTY_INITIAL_FILTERS,
  pagination: externalPagination,
}: UseCardTransactionsParams = {}) => {
  const { realmId } = useClientInfo();

  const internalPagination = usePagination();
  const pagination = externalPagination ?? internalPagination;

  const [internalOrder, setInternalOrder] = useState("-transaction_date");
  const order = externalOrder?.order ?? internalOrder;
  const setOrder = externalOrder?.setOrder ?? setInternalOrder;

  const internalFilters = useTableFilters({
    formatter: cardTransactionsFilterFormatter,
  });
  const {
    filters = initialFilters,
    rawFilters,
    setFilters,
  } = externalFilters && !Array.isArray(externalFilters)
    ? externalFilters
    : internalFilters;

  const enhancedSetFilters = useEvent((filters) => {
    setFilters(filters as StrictValuesFilters);
  });

  const clearFilters = useEvent(() => enhancedSetFilters({}));

  const enhancedFilters = useMemo(() => {
    const params = [
      ...filters.filter(
        (item) =>
          !query.some((queryItem) => queryItem.dataIndex === item.dataIndex)
      ),
      ...query,
    ];

    params.push({ dataIndex: "limit", value: pagination.perPage });
    params.push({ dataIndex: "offset", value: pagination.offset });
    params.push({ dataIndex: "ordering", value: order });
    params.push({ dataIndex: "cost__isnull", value: false });

    return params;
  }, [filters, query, pagination.perPage, pagination.offset, order]);

  const transactions = useGetCardTransactionsQuery(enhancedFilters, {
    selectFromResult: ({ isFetching, data, isError }) => ({
      data: data?.results || EMPTY_DATA,
      count: data?.count || 0,
      isLoading: isFetching,
      isError,
    }),
    skip: !realmId || !enabled,
    refetchOnMountOrArgChange: true,
    pollingInterval: enablePolling ? FIVE_MINUTES_IN_MS : undefined,
  });

  return {
    ...pagination,
    ...transactions,
    order,
    filters: enhancedFilters,
    setOrder,
    rawFilters,
    setFilters: enhancedSetFilters,
    clearFilters,
  };
};
