import {
  type Formatter,
  getArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";

export const filterFormatter: Formatter<QueryItem[]> = (realmId, filters) => {
  let params = getArrayFormatter(realmId, filters).map((query) => {
    if (query.dataIndex === "status") {
      if (query.value === "archived") {
        return { ...query, dataIndex: "is_archived" };
      } else {
        return { ...query, dataIndex: "review_status" };
      }
    } else if (query.dataIndex === "card_link") {
      if (query.value === "card_link_true") {
        return { ...query, dataIndex: "card_link", value: "true" };
      } else if (query.value === "card_link_false") {
        return { ...query, dataIndex: "card_link", value: "false" };
      }
    } else if (query.dataIndex === "receipt") {
      if (query.value === "receipt_true") {
        return { ...query, dataIndex: "receipt", value: "true" };
      } else if (query.value === "receipt_false") {
        return { ...query, dataIndex: "receipt", value: "false" };
      }
    }

    return query;
  });

  /**
   * This is a small workaround over "user" and "my transactions" filters.
   * Since they conflict with each other, we need to handle in this way:
   * - if "user" is set and "my transactions" is off, we use users filter
   * - if "user" is set and "my transactions" is on, we create an empty state
   * - if "user" is not set and "my transactions" is on, we use my transactions filter
   */
  const hasUser = params.some((query) => query.dataIndex === "user");
  const myReceiptsUser = params.find(
    (query) => query.dataIndex === "my_receipts_user"
  )?.value;
  const hasMyReceiptsUser = !!myReceiptsUser;

  if (hasUser && hasMyReceiptsUser) {
    params = params.filter((query) => query.dataIndex !== "user");
    params.push({ dataIndex: "user", value: -1 });
  } else if (!hasUser && hasMyReceiptsUser) {
    params = params.filter((query) => query.dataIndex !== "user");
    params.push({ dataIndex: "user", value: myReceiptsUser });
  }

  return params.filter((query) => query.dataIndex !== "my_receipts_user");
};
