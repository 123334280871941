import React, { useEffect, useRef, useState } from "react";
import { useClientSettings } from "@store/user";

import { PDF_BASE64_TYPE } from "./constants";
import styles from "./pdf-viewer.module.css";

export type PdfViewerProps = {
  src: string;
};

export const PdfViewer = ({ src }: PdfViewerProps) => {
  const { internalPdfReaderEnabled } = useClientSettings();
  const containerRef = useRef<HTMLDivElement>(null);

  const [handshake, setHandshake] = useState<any>();

  useEffect(() => {
    if (!internalPdfReaderEnabled || !containerRef.current) return;

    const containerElement = containerRef.current;

    const newHandshake = new window.Postmate({
      url: `/static/pdf_viewer/viewer.html`,
      name: "pdfViewer",
      container: containerRef.current,
      classListArray: [styles["pdf-viewer-iframe"]],
    });

    setHandshake(newHandshake);

    return () => {
      setHandshake(undefined);
      newHandshake.then((child: any) => {
        if (child && child.listener) {
          window.removeEventListener("message", child.listener, false);
        }
      });
      if (containerElement && containerElement.firstChild) {
        containerElement.removeChild(containerElement.firstChild);
      }
    };
  }, [internalPdfReaderEnabled]);

  useEffect(() => {
    if (!internalPdfReaderEnabled || !handshake) return;

    const isBase64 = src.includes(PDF_BASE64_TYPE);

    const rawSrc = isBase64 ? src.split(PDF_BASE64_TYPE)[1] : src;

    handshake.then((child: any) => {
      child.call(isBase64 ? "loadPdfBase64" : "loadPdfUrl", rawSrc);
    });
  }, [handshake, src, internalPdfReaderEnabled]);

  if (!internalPdfReaderEnabled) {
    return (
      <embed
        data-skip-focusable=""
        src={`${src}#view=FitH&toolbar=1&navpanes=0`}
        className={styles["pdf-viewer-iframe"]}
        type="application/pdf"
      />
    );
  }

  return (
    <div
      data-skip-focusable=""
      ref={containerRef}
      className={styles["pdf-viewer-iframe"]}
    />
  );
};
