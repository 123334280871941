import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertContent,
  Button,
  Flex,
  TextField,
} from "@adaptive/design-system";
import { useDeepMemo, useForm } from "@adaptive/design-system/hooks";
import {
  getUnstandardizedErrors,
  isUnstandardizedErrors,
} from "@api/handle-errors";
import { saveCompany } from "@shared/api/onboarding";
import { useClientAction, useClientInfo } from "@shared/store/user";
import { z } from "zod";

import { Layout } from "../layout";

const schema = z.object({
  name: z.string().min(1, "Company name is required"),
});

type Fields = z.infer<typeof schema>;

export const CompanyName = () => {
  const navigate = useNavigate();

  const { client } = useClientInfo();

  const { reloadClients } = useClientAction();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const initialValues = useDeepMemo(
    () => ({
      name: client?.name || "",
    }),
    [client]
  );

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        await saveCompany(values.name);
        await reloadClients();
        navigate("/onboarding/link-accounting-software");
      } catch (e) {
        if (isUnstandardizedErrors(e)) {
          const errors = getUnstandardizedErrors(e);

          if (Array.isArray(errors)) setErrorMessage(errors[0]);
        } else {
          setErrorMessage("Error saving company name");
        }
      }
    },
    initialValues,
  });

  useEffect(() => {
    setErrorMessage("");
  }, [form.values]);

  return (
    <>
      <Layout
        title="What’s the name of your company?"
        progress={25}
        {...form.props}
      >
        <Flex gap="xl" direction="column">
          <Flex direction="column">
            <TextField
              label="Enter the name of your company"
              required
              autoFocus
              placeholder="Company name"
              {...form.register("name")}
            />
            {errorMessage && (
              <Alert variant="error">
                <AlertContent>{errorMessage}</AlertContent>
              </Alert>
            )}
          </Flex>
          <Button
            variant="solid"
            data-testid="save-company-name"
            type="submit"
            disabled={form.isSubmitting || !form.isValid || !!errorMessage}
            block
          >
            Next
          </Button>
        </Flex>
      </Layout>
    </>
  );
};
