import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

import { accountSchema, itemSchema } from "../jobs/response";

const userSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  email: z.string().email(),
  fullName: z.string(),
});

const customerSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const capitalOSCardSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  paymentAccount: accountSchema.nullish(),
  customer: customerSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  mask: z.string().nullish(),
  user: userSchema.nullish(),
  status: z.string(),
  capitalOsId: idSchema,
  cardHolderFirstName: z.string().nullish(),
  cardHolderLastName: z.string().nullish(),
  cardNickname: z.string().nullish(),
});

export const getCapitalOSCardsResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(capitalOSCardSchema),
  previous: z.string().url().nullish(),
});

export const getCapitalOSInitiateLoginResponseSchema = z.object({
  token: z.string(),
  url: z.string().url(),
});
