import { idSchema } from "@utils/schema";
import { z } from "zod";

export const getCapitalOSCardsPayloadSchema = z.object({
  realm: idSchema.nullish(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  ordering: z.string().optional(),
});

export const updateCapitalOSCardPayloadSchema = z.object({
  id: idSchema,
  user: z.string().nullish(),
  paymentAccount: z.string().nullish(),
  customer: z.string().nullish(),
  item: z.string().nullish(),
  account: z.string().nullish(),
});
