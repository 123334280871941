const LEIN_WAIVERS_HELP_LINK =
  "https://help.adaptive.build/en/articles/9912426-september-2024-setting-up-and-managing-lien-waivers";

export const STRINGS = {
  /** Common */
  LIEN_WAIVER: "Lien waiver",
  DELETE: "Delete",
  CANCEL: "Cancel",
  SEND: "Send",
  SAVE_TEMPLATE: "Save template",
  UPLOAD: "Upload",

  /** Lien Waiver template settings */
  CREATE_LIEN_WAIVER_TEMPLATE: "Create lien waiver template",
  EDIT_LIEN_WAIVER_TEMPLATE: "Update lien waiver template",
  DEFAULT_TEMPLATE: "Default template",
  DEFAULT_TEMPLATE_HINT: "This template will be used by default",
  SAMPLE_DATA: "Sample data",
  ADD_TEMPLATE: "Add lien waiver template",
  REMOVE_DEFAULT: "Remove default",
  SET_DEFAULT: "Set as default",
  DISABLED_ADD_TEMPLATE:
    "You can only have one template per lien waiver type.\nEdit or delete an existing template below.",

  /** Lien Waiver template settings - Template Type */
  LIEN_WAIVER_TYPE: "Lien waiver type",
  LIEN_WAIVER_TYPE_DESCRIPTION:
    "You can only create one template per lien waiver type.",
  LIEN_WAIVER_TYPE_LEARN_MORE: "Learn more about lien waiver types.",
  LIEN_WAIVER_TYPE_LEARN_MORE_LINK: LEIN_WAIVERS_HELP_LINK,
  LIEN_WAIVER_TYPE_IN_USE: "A template for this type already exists",

  /** Lien Waiver template settings - E-signature Fields */
  E_SIGNATURE_FIELDS: "E-signature fields",
  E_SIGNATURE_FIELDS_DESCRIPTION:
    "Select the required information you need from vendors when they sign the document.",

  /** Lien Waiver template settings - Template Details */
  TEMPLATE_DETAILS: "Template details",
  TEMPLATE_DETAILS_DESCRIPTION:
    'Automatically insert data from your Vendors, Jobs, Bills, and more into your lien waiver template by typing "+".',
  TEMPLATE_DETAILS_LEARN_MORE: "Learn more about inserting data",
  TEMPLATE_DETAILS_LEARN_MORE_LINK: LEIN_WAIVERS_HELP_LINK,
  TEMPLATE_DETAILS_EMPTY_CONTENT:
    "Copy and paste your existing lien waiver into the Template Details text field to the right or begin typing to start fresh.",
  TEMPLATE_DETAILS_INTERCOM_CTA:
    "Don't see the field you're looking for? Click here to let us know.",

  /** Preview Placeholders */
  PREVIEW_UNAVAILABLE: "No preview available",
  PREVIEW_REFRESH: "Refresh preview",

  /** Close Editing Lien Waiver Template Warning */
  CLOSE_DIALOG_TITLE: "You have unsaved changes",
  CLOSE_DIALOG_MESSAGE:
    "Are you sure you want to leave this page without saving?",
  CLOSE_DIALOG_LEAVE: "Leave without saving",
  CLOSE_DIALOG_STAY: "Stay on page",

  /** Delete Lien Waiver Template Dialog */
  DELETE_DIALOG_TITLE:
    "Are you sure you want to delete this lien waiver template?",
  DELETE_DIALOG_MESSAGE: `This action cannot be undone.\n\nLien waivers that have already been sent to vendors will\nnot be affected.`,
  DELETE_DIALOG_CONFIRM: "Delete template",

  /** Toasts */
  TEMPLATE_SET_AS_DEFAULT: "{template} set as the default template",
  TEMPLATE_REMOVED_AS_DEFAULT:
    "{template} is no longer set as the default template",
  TEMPLATE_CREATE_TITLE: "{template} template created",
  TEMPLATE_UPDATED_TITLE: "{template} template updated",
  TEMPLATE_UPDATED_MESSAGE:
    "Lien waivers that have already been sent to vendors have not been affected",
  TEMPLATE_DELETED_TITLE: "{template} template deleted",
  TEMPLATE_DELETED_MESSAGE:
    "Lien waivers that have already been sent to vendors have not been affected",
  TEMPLATE_DELETED_ERROR_TITLE:
    "The {template} template is being used in {bills} bills, and cannot be deleted",
  TEMPLATE_DELETED_ERROR_SEE_BILLS: "View bills",
  TEMPLATE_LAST_SAVED: "Last saved on {date} at {time}",

  /** Field */
  LIEN_WAIVER_PREVIEW: "Lien waiver preview",
  CLOSE_PREVIEW: "Close",
  EDIT_PREVIEW_TEMPLATE: "Edit template",
  LIEN_WAIVER_ACTIVITY_TIME: "{date} at {time}",

  /** Field button tooltips */
  VIEW_LIEN_WAIVER: "View lien waiver",
  VIEW_LIEN_WAIVER_TEMPLATE: "View lien waiver template",
  VIEW_SIGNED_LIEN_WAIVER: "View signed lien waiver",
  DOWNLOAD_LIEN_WAIVER: "Download lien waiver",
  DOWNLOAD_SIGNED_LIEN_WAIVER: "Download signed lien waiver",
  UPLOAD_SIGNED_LIEN_WAIVER: "Upload signed lien waiver",
  MARK_AS_REQUESTED_FIRST: 'Click "Mark as requested" to upload a lien waiver',
  DELETE_SIGNED_LIEN_WAIVER: "Delete signed lien waiver",

  /** Field - Template Selector */
  REQUEST_LIEN_WAIVER: "Request lien waiver",
  MARK_AS_REQUESTED: "Mark as requested",
  SUPPORT_EDIT_TEMPLATES: "Edit lien waiver templates",
  SUPPORT_ADD_TEMPLATES: "Add a lien waiver template",
  MARKED_AS_REQUESTED: "Lien waiver marked as requested",

  /** Field - Requested */
  LIEN_WAIVER_REQUESTED_TITLE:
    "Lien waiver request sent to {receiver} by {sender}",
  LIEN_WAIVER_MARKED_AS_REQUESTED_TITLE:
    "Lien waiver marked as requested by {sender}",
  LIEN_WAIVER_SEND_REMINDER: "Send reminder",
  LIEN_WAIVER_CANCEL_REQUEST: "Cancel request",
  CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TITLE:
    "Are you sure you want to cancel this lien waiver request?",
  CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TEXT:
    "This cannot be undone. You will need to request a new lien waiver.",
  CANCEL_LIEN_WAIVER_REQUEST_KEEP: "Keep request",
  CANCEL_LIEN_WAIVER_REQUEST_CONFIRM: "Cancel request",
  CANCEL_LIEN_WAIVER_SUCCESS: "Lien waiver request canceled",
  REMINDER_SENT: "Lien waiver reminder sent",
  SAVE_BILL_BEFORE_SEND_REQUEST:
    "Save bill changes before sending a lien waiver request",
  SAVE_BILL_BEFORE_MARKING_AS_REQUESTED:
    "Save bill changes before marking a lien waiver as requested",
  MISSING_FIELDS_TITLE: "The following required data is missing:",
  MISSING_FIELDS_REQUEST_TOOLTIP:
    "Complete the missing fields to request the lien waiver",
  MISSING_FIELDS_MARK_AS_REQUESTED_TOOLTIP:
    "Complete the missing fields to mark the lien waiver as requested",
  MISSING_FIELDS_PREVIEW_PDF_TITLE: "Missing required information",
  MISSING_FIELDS_PREVIEW_PDF_BODY:
    "All required fields must be filled out before the lien waiver can be sent. Please complete the fields highlighted in red and click “Save” on the bill to update the lien waiver template.",
  /** Field - Signed */
  LIEN_WAIVER_SIGNED_TITLE: "Lien waiver signed by {vendor}",
  LIEN_WAIVER_SIGNED_UPLOAD_TITLE: "Signed lien waiver uploaded by {user}",

  /** Request Dialog */
  REQUEST_DIALOG_TITLE: "Request a lien waiver signature",
  VENDOR_EMAIL_ADDRESS: "Vendor email address",
  VENDOR_EMAIL_ADDRESS_PLACEHOLDER: "Enter the vendor's email address",
  ADD_COMMENT: "Add a comment for the vendor or attach additional files:",
  ADD_COMMENT_PLACEHOLDER:
    "Comments are visible to other users in your workspace. The vendor will be notified directly.",
  REQUEST_SENT: "Lien waiver request sent",
  ATTACH_FILES: "Attach files",

  /** Upload Signature Dialog */
  UPLOAD_SIGNATURE_DIALOG_TITLE:
    "Are you sure you want to mark this lien waiver as signed?",
  UPLOAD_SIGNATURE_DIALOG_TEXT:
    "You have not received an official signature from this vendor through Adaptive.",
  UPLOAD_SIGNATURE_DIALOG_CONFIRM: "Mark as signed",
  UPLOAD_SIGNATURE_DIALOG_CONFIRM_PAYMENT: "Mark as signed & pay",
  UPLOAD_SIGNATURE_PAYMENT_ALERT: "Payment already initiated",
  UPLOAD_SIGNATURE_PAYMENT_ALERT_TEXT:
    "If you mark this lien waiver as signed we will automatically send the associated bill payment to the vendor",
  UPLOAD_SIGNATURE_DROPZONE_TITLE: "Upload or drag and drop a file",
  UPLOAD_SIGNATURE_DROPZONE_DROP: "Drop a file here to upload it!",
  LIEN_WAIVER_MARKED_AS_SIGNED: "Lien waiver marked as signed",

  /** Delete Lien Waiver Request Dialog */
  DELETE_LIEN_WAIVER_REQUEST_DIALOG_TITLE:
    "Are you sure you want to delete this lien waiver?",
  DELETE_LIEN_WAIVER_REQUEST_DIALOG_TEXT:
    "This cannot be undone. You will need to request a new lien waiver.",
  LIEN_WAIVER_REQUEST_DELETED: "Lien waiver request deleted",

  /** External */
  LIEN_WAIVER_FROM: "Lien waiver from {requester}",
  LIEN_WAIVER_JOB: "Job",
  LIEN_WAIVER_PAYMENT_AMOUNT: "Payment amount",
  LIEN_WAIVER_ASSOCIATED_INVOICE: "Associated invoice",
  LIEN_WAIVER_SIGNED_SUCCESS: "Lien waiver signed successfully",
  LIEN_WAIVER_FIELD_PRINTED_NAME: "Printed name",
  LIEN_WAIVER_FIELD_TITLE: "Job title",
  LIEN_WAIVER_SIGN_SUBMIT_BUTTON: "Sign lien waiver",
  LIEN_WAIVER_REPLY_COMMENT_ADDED: "Comment added",
  LIEN_WAIVER_REPLY_PLACEHOLDER: "Reply to",

  /** 404 Not Found */
  NOT_FOUND_TITLE: "Lien waiver request not found",
  NOT_FOUND_SUBTITLE: `We're sorry, this lien waiver request could not be found.
  Please reach out to the requestor if you think that this is a mistake.`,
} as const;

export const TEMPLATE_NOT_REQUESTED_ID = "not_required";

export const LIEN_WAIVER_STATUS = {
  NOT_SELECTED: "not_selected",
  NOT_REQUIRED: "not_required",
  NOT_REQUESTED: "not_requested",
  REQUESTED: "requested",
  MARKED_AS_REQUESTED: "marked_as_requested",
  SIGNED: "signed",
  MARKED_AS_SIGNED: "marked_as_signed",
} as const;

export const LIEN_WAIVER_REQUESTED_STATUS = [
  LIEN_WAIVER_STATUS.REQUESTED,
  LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
] as string[];

export const LIEN_WAIVER_SIGNED_STATUS = [
  LIEN_WAIVER_STATUS.SIGNED,
  LIEN_WAIVER_STATUS.MARKED_AS_SIGNED,
] as string[];

export const LIEN_WAIVER_LINKED_STATUS = [
  ...LIEN_WAIVER_REQUESTED_STATUS,
  ...LIEN_WAIVER_SIGNED_STATUS,
];
