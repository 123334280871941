import React, {
  type ComponentProps,
  type ComponentRef,
  type ComponentType,
  forwardRef,
  memo,
} from "react";
import { Popover, type PopoverProps } from "@ariakit/react";
import cn from "clsx";

import styles from "./combobox.module.css";

type DefaultComponent = "div";

type Ref = ComponentRef<DefaultComponent>;

type Props = ComponentProps<DefaultComponent> &
  PopoverProps & { inline?: boolean; flip?: boolean; loading?: boolean };

export const ComboBoxList = memo(
  forwardRef<Ref, Props>(
    ({ inline, portal, store, flip, loading, className, ...props }, ref) => {
      let Component: keyof JSX.IntrinsicElements | ComponentType = "div";

      let enhancedProps: Props = {
        ...props,
        role: "listbox",
        className: cn(className, styles["listbox"], {
          [styles["-inline"]]: inline,
          [styles["-loading"]]: loading,
        }),
      };

      if (!inline) {
        enhancedProps = {
          ...enhancedProps,
          flip,
          modal: false,
          store,
          portal,
          gutter: 8,
          sameWidth: true,
          autoFocusOnShow: false,
          autoFocusOnHide: false,
        };
        Component = Popover as ComponentType;
      }

      return <Component ref={ref} {...enhancedProps} />;
    }
  )
);

ComboBoxList.displayName = "ComboBoxList";
