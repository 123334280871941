import { useEffect, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { useStageActions, useStageName } from "@shared/sequential/context";
import { useAppSelector } from "@store/hooks";
import { selectErrorsGroups } from "@store/vendors/selectors-errors";

export const useShowNextError = () => {
  const currentStageName = useStageName();

  const { setStage } = useStageActions();

  const stagesWithErrors = useAppSelector(selectErrorsGroups);

  const [seenStages, setSeenStages] = useState<string[]>([]);

  useEffect(() => {
    setSeenStages([]);
  }, [stagesWithErrors]);

  useEffect(() => {
    const [nextErrorStage] = Object.keys(stagesWithErrors || {}).filter(
      (stageName) =>
        stageName === currentStageName || !seenStages.includes(stageName)
    );

    if (nextErrorStage) {
      setSeenStages((prev) => {
        return prev.includes(nextErrorStage) ? prev : [...prev, nextErrorStage];
      });
      setStage(nextErrorStage);
      return;
    }
  }, [seenStages, setStage, currentStageName, stagesWithErrors]);
};

// hopefully useForm works and I won't need this
export const useErrorField = (key: string) => {
  const errors = useAppSelector((state) => {
    const groups = selectErrorsGroups(state);
    const match = Object.values(groups || {}).find((group) =>
      Object.keys(group).includes(key)
    );

    if (!match) {
      return null;
    }

    return match ? (match as Record<string, any>)[key] : null;
  });

  // const errKey = key as NonNullable<keyof NonNullable<typeof errors>>;
  const [error, setError] = useState(errors);
  useEffect(() => {
    setError(errors);
  }, [errors]);

  const resetError = useEvent(() => {
    setError(undefined);
  });
  return [error, resetError] as const;
};
