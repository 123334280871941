import React, { useCallback, useMemo } from "react";
import { Flex, Text, Textarea, TextField } from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { useInvoiceSettings } from "@src/jobs/invoice";
import { z } from "zod";

const schema = z.object({
  defaultTitle: z.string(),
  defaultDescription: z.string(),
});

const INITIAL_VALUES = {
  defaultTitle: "",
  defaultDescription: "",
};

export const DrawSettings = () => {
  const { invoiceSettings, isLoading } = useInvoiceSettings();
  return !isLoading && invoiceSettings?.id ? (
    <DrawSettingsForm invoiceSettings={invoiceSettings} />
  ) : null;
};

const DrawSettingsForm = ({
  invoiceSettings,
}: {
  invoiceSettings: z.infer<typeof schema>;
}) => {
  const { updateInvoiceSettings } = useInvoiceSettings();
  const initialValues = useMemo<z.infer<typeof schema>>(
    () => ({
      ...INITIAL_VALUES,
      ...invoiceSettings,
    }),
    [invoiceSettings]
  );

  const { submit, ...form } = useForm({
    schema,
    onSubmit(values) {
      updateInvoiceSettings(values);
    },
    initialValues,
  });

  const onBlur = useCallback(() => {
    const previousValue = initialValues;
    const currentValue = form.values;
    const isDirty = !isEqual(previousValue, currentValue);
    if (isDirty && form.isValid) submit();
  }, [form, initialValues, submit]);

  return (
    <Flex
      as="form"
      {...form.props}
      gap="2xl"
      maxWidth="592px"
      direction="column"
    >
      <Text as="h2" size="xl" weight="bold">
        Draw settings
      </Text>
      <Flex maxWidth="592px" direction="column">
        <TextField
          label="Default title"
          {...form.register({ name: "defaultTitle", type: "string", onBlur })}
        />
        <Textarea
          minHeight={126}
          label="Default description"
          {...form.register({
            name: "defaultDescription",
            type: "string",
            onBlur,
          })}
        />
      </Flex>
      <button
        type="submit"
        form={form.id}
        style={{ display: "none" }}
        disabled={form.isSubmitting || form.isDirty}
      />
    </Flex>
  );
};
