export const STRINGS = {
  REQUEST_SUCCESS: "Vendor information request sent",
  REQUEST_ERROR: "Error occurred",
  RESEND_BUTTON_LABEL: "Request ACH from vendor",
  SEND_BUTTON_LABEL: "Request ACH from vendor",
  DEFAULT_PENDING_ACH_REQUEST_ALERT_CONTENT:
    "Payment will be made once we have ACH details for this vendor.",
  DEFAULT_EXPIRED_ACH_REQUEST_ALERT_CONTENT:
    "Any pending payments for this vendor have been cancelled.",
  REQUEST_ACH_LABEL: "Send request",
  CANCEL_ACH_LABEL: "Cancel request",
  REQUEST_DIALOG_HEADER_MULTIPLE:
    "Request missing ACH details from the selected vendors",
  REQUEST_DIALOG_HEADER_SINGLE: "Confirm details of request",
  REQUEST_DIALOG_CONTENT_MULTIPLE:
    "Confirm which email addresses you would like to send these requests to",
};

export const VENDOR_REQUEST_STATUS = {
  EXPIRED: "EXPIRED",
  PENDING: "PENDING",
} as const;

const VENDOR_ACH_REQUEST_STATUS_VARIANT = {
  PENDING: "warning",
  EXPIRED: "error",
} as const;

export const achAlertVariant = (status: keyof typeof VENDOR_REQUEST_STATUS) =>
  VENDOR_ACH_REQUEST_STATUS_VARIANT[status] || "neutral";
