import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  TextField,
} from "@adaptive/design-system";
import { useDeepMemo, useDialog, useForm } from "@adaptive/design-system/hooks";
import { z } from "zod";

import {
  type Permission,
  type PermissionCategory,
  type Role,
  useRoleAction,
} from "../../../../shared/store/role";
import { useClientAction } from "../../../../shared/store/user";
import { type Dialog as DialogProps } from "../types";

const schema = z.object({
  name: z.string().min(1, "The name is required"),
  description: z.string(),
});
type Fields = z.infer<typeof schema>;
type Props = {
  dialog: DialogProps;
  role?: Role | undefined;
};

export const EditRole = ({ dialog, role }: Props) => {
  const { loadPermissions, permissionCategories, saveRole, deleteRole } =
    useRoleAction();
  const { currentClient } = useClientAction();
  const confirmDeleteRoleDialog = useDialog();

  useEffect(() => {
    if (!permissionCategories.length) {
      loadPermissions();
    }
  }, [permissionCategories, loadPermissions]);

  const [permissions, setPermissions] = useState<number[]>([
    ...(role?.permissions || []),
  ]);

  useEffect(() => {
    setPermissions(role?.permissions || []);
  }, [role?.permissions]);

  const initialValues = useDeepMemo(
    () => ({
      name: role?.name || "",
      description: role?.description || "",
    }),
    [role]
  );

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      const payload = {
        ...role,
        ...values,
        permissions,
      };
      if (!role?.id && currentClient?.id) {
        payload.client = currentClient?.id;
      }
      saveRole(payload);
      reset();
      dialog.setStep("role-list");
    },
    initialValues,
  });

  useEffect(() => {
    if (dialog.step === "role-edit" && !role) {
      setPermissions([]);
    } else if (dialog.step === "role-edit" && role) {
      setPermissions(role?.permissions || []);
      reset();
    }
  }, [reset, dialog.step, role]);

  const curriedOnChangePermission = useCallback(
    (checked: boolean, permission: Permission) => {
      if (!checked) {
        setPermissions((oldPermissions) =>
          oldPermissions.filter(
            (permissionId) => permissionId !== permission.id
          )
        );
      } else {
        setPermissions((oldPermissions) => [...oldPermissions, permission.id]);
      }
    },
    []
  );

  const renderPermissions = useCallback(
    (permissionCategories: PermissionCategory[]) => {
      return (
        <Flex direction="column" gap="lg">
          {permissionCategories.map((category) => (
            <Flex key={category.id} gap="sm" direction="column">
              <Text weight="bold">{category.name}</Text>
              {category.permissions.map((permission) => (
                <Checkbox
                  id={permission.id.toString()}
                  key={permission.id}
                  label={permission.name}
                  checked={permissions.some(
                    (permissionId) => permission.id === permissionId
                  )}
                  disabled={role?.base}
                  onChange={(checked: boolean) =>
                    curriedOnChangePermission(checked, permission)
                  }
                />
              ))}
            </Flex>
          ))}
        </Flex>
      );
    },
    [role, curriedOnChangePermission, permissions]
  );

  const onDelete = useCallback(() => {
    if (!role) return;
    deleteRole(role)
      .then(() => {
        dialog.setStep("role-list");
      })
      .catch(() => {
        dialog.setStep("role-delete");
        confirmDeleteRoleDialog.hide();
      });
  }, [role, dialog, deleteRole, confirmDeleteRoleDialog]);

  const oddPermissions = useMemo(
    () =>
      renderPermissions(
        permissionCategories.filter((_, index: number) => !(index % 2))
      ),
    [permissionCategories, renderPermissions]
  );

  const evenPermissions = useMemo(
    () =>
      renderPermissions(
        permissionCategories.filter((_, index: number) => index % 2)
      ),
    [permissionCategories, renderPermissions]
  );

  const confirmDeleteUser = useMemo(
    () => (
      <Dialog
        variant="confirmation"
        show={confirmDeleteRoleDialog.isVisible}
        onClose={confirmDeleteRoleDialog.hide}
      >
        <DialogHeader>
          Are you sure you want <br />
          to delete this role?
        </DialogHeader>
        <DialogFooter>
          <Button
            block
            color="neutral"
            variant="text"
            onClick={confirmDeleteRoleDialog.hide}
          >
            Cancel
          </Button>
          <Button block color="error" onClick={onDelete}>
            Delete role
          </Button>
        </DialogFooter>
      </Dialog>
    ),
    [confirmDeleteRoleDialog.hide, confirmDeleteRoleDialog.isVisible, onDelete]
  );

  return (
    <>
      <DialogHeader>
        {!role ? "Create role" : role.base ? "View role:" : "Edit role:"}{" "}
        {role?.name}
      </DialogHeader>
      <DialogContent>
        <Flex direction="column" shrink={false} gap="2xl">
          {role?.base ? (
            <Flex direction="column" gap="sm">
              <Text size="sm" weight="bold">
                {role?.name}
              </Text>
              <Text size="sm" align="justify">
                {role?.description}
              </Text>
            </Flex>
          ) : (
            <Flex direction="column" as="form" {...form.props}>
              <TextField
                label="Role name"
                required
                {...form.register("name")}
              />
              <TextField
                label="Description"
                {...form.register("description")}
              />
            </Flex>
          )}
          <Flex direction="row" justify="space-between" gap="xl">
            <Flex grow width="full">
              {oddPermissions}
            </Flex>
            <Flex grow width="full">
              {evenPermissions}
            </Flex>
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex grow width="full">
          {role?.id && !role?.base ? (
            <Button
              variant="solid"
              color="error"
              disabled={role?.base}
              onClick={confirmDeleteRoleDialog.show}
              block
            >
              Delete role
            </Button>
          ) : (
            <Button block color="neutral" variant="text" onClick={dialog.back}>
              Cancel
            </Button>
          )}
        </Flex>
        {!role?.base && (
          <Flex grow width="full">
            <Button
              disabled={form.isSubmitting}
              type="submit"
              form={form.id}
              block
            >
              Save role
            </Button>
          </Flex>
        )}
      </DialogFooter>
      {confirmDeleteUser}
    </>
  );
};
