import React, { type ComponentProps } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"footer">;

export const ShellSidebarFooter = ({ className, ...props }: Props) => (
  <footer
    className={cn(className, styles["shell-sidebar-footer"])}
    {...props}
  />
);
