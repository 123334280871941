import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  TextField,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  type UseDialogReturn,
  useForm,
} from "@adaptive/design-system/hooks";
import { z } from "zod";

type Props = {
  dialog: UseDialogReturn;
  signature: string;
  defaultSignature: string;
  onChange: (value: string) => void;
};

const schema = z.object({
  signature: z.string(),
});
type Fields = z.infer<typeof schema>;

export const UpdateSignatureDialog = ({
  dialog,
  signature,
  defaultSignature,
  onChange,
}: Props) => {
  const [useDefault, setUseDefault] = useState(false);

  const initialValues = useDeepMemo(() => ({ signature }), [signature]);

  const form = useForm<Fields>({
    schema,
    onSubmit(value) {
      onChange(value.signature);
      dialog.hide();
    },
    initialValues,
  });

  useEffect(() => {
    if (useDefault) form.reset({ signature: defaultSignature });

    setUseDefault(false);
  }, [defaultSignature, form, signature, useDefault]);

  return (
    <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
      <DialogHeader>Update signature</DialogHeader>
      <DialogContent>
        <Flex
          direction="row"
          as="form"
          justify="center"
          gap="xl"
          {...form.props}
        >
          <TextField {...form.register("signature")} />
          <Button color="primary" onClick={() => setUseDefault(true)}>
            Restore default
          </Button>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" color="neutral" onClick={dialog.hide} block>
          Cancel
        </Button>
        <Button type="submit" form={form.id} block>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
