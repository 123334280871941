import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import type { Expense } from "@api/expenses";
import { useCycle } from "@src/expenses/cycle-provider";
import { DynamicActions } from "@src/expenses/dynamic-actions";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import { useStageName } from "../../../shared/sequential/context";
import {
  useExpenseAction,
  useExpensePermissions,
} from "../../../shared/store/expenses";
import { expenseSelectors } from "../../../shared/store/expenses/selectors";
import { STRINGS } from "../constants";

export const Actions = () => {
  const cycle = useCycle();

  const stageName = useStageName();

  const { cardFeedEnabled } = useClientSettings();

  const id = useSelector(expenseSelectors.id);

  const isArchived = useSelector(expenseSelectors.isArchived);

  const { canEditExpense } = useExpensePermissions();

  const isTransactionGeneratedDraft = useSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );

  const { saveExpense, unArchive } = useExpenseAction();

  const linesCount = useSelector(expenseSelectors.linesCount);

  const tooManyLines = linesCount > window.MAX_LINES_PAGE_SIZE;

  const curriedSave = useCallback(
    (status: Expense["reviewStatus"]) => async () => {
      const data = await saveExpense({ setStatus: status });

      analytics.track(id ? "expenseUpdate" : "expenseCreate", {
        expenseId: data.id,
      });

      if (cardFeedEnabled && isTransactionGeneratedDraft) {
        toast.success(
          <Flex as="span" direction="column">
            <Text as="strong" weight="bold">
              Receipt #{data.docNumber} created in{" "}
              {status === "DRAFT" ? "Missing Info" : "Review"} status
            </Text>
            <Text as="span">
              Click{" "}
              {status === "DRAFT"
                ? `${STRINGS.BUTTON_ADVANCE_REVIEW} when all relevant fields are complete`
                : `${STRINGS.BUTTON_PUBLISH} when receipt is ready to sync`}
            </Text>
          </Flex>
        );
      } else {
        toast.success(
          `Receipt ${data.docNumber ? `#${data.docNumber} ` : ""}${
            id ? "updated" : "created"
          }`
        );
      }
    },
    [cardFeedEnabled, id, isTransactionGeneratedDraft, saveExpense]
  );

  return (
    <>
      <DynamicActions />
      {isArchived ? (
        <Button size="lg" onClick={unArchive}>
          Restore this receipt
        </Button>
      ) : (
        <Flex justify="flex-end" align="center" gap="xl">
          <Tooltip
            message={
              canEditExpense
                ? tooManyLines
                  ? STRINGS.BUTTON_TOO_MANY_LINES
                  : undefined
                : STRINGS.ACTION_NOT_ALLOWED
            }
            placement="left"
          >
            <Button
              variant="ghost"
              disabled={!canEditExpense || tooManyLines}
              size="lg"
              onClick={curriedSave("DRAFT")}
              data-testid="expense-save-button"
            >
              {STRINGS.BUTTON_SAVE_DRAFT}
            </Button>
          </Tooltip>
          <Tooltip
            message={
              canEditExpense
                ? tooManyLines
                  ? STRINGS.BUTTON_TOO_MANY_LINES
                  : undefined
                : STRINGS.ACTION_NOT_ALLOWED
            }
            placement="left"
          >
            <ButtonGroup size="lg" disabled={!canEditExpense || tooManyLines}>
              {cycle.status && cycle.status !== "ALL" ? (
                <>
                  <Button type="submit" form={stageName}>
                    {STRINGS.BUTTON_REVIEW}
                  </Button>
                  <Dropdown placement="bottom-end">
                    <DropdownTrigger
                      as={Button}
                      aria-label="Actions"
                      data-testid="expense-edit-actions-trigger"
                    >
                      <Icon name="chevron-down" />
                    </DropdownTrigger>
                    <DropdownList>
                      <DropdownItem onClick={curriedSave("FOR_REVIEW")}>
                        {STRINGS.BUTTON_ADVANCE_REVIEW}
                      </DropdownItem>
                    </DropdownList>
                  </Dropdown>
                </>
              ) : (
                <Button onClick={curriedSave("FOR_REVIEW")}>
                  {STRINGS.BUTTON_ADVANCE_REVIEW}
                </Button>
              )}
            </ButtonGroup>
          </Tooltip>
        </Flex>
      )}
    </>
  );
};
