import React from "react";
import { Flex, Text } from "@adaptive/design-system";

type LinkedCardProps = { mask?: string | null; name?: string | null };

export const LinkedCard = ({ mask, name }: LinkedCardProps) => {
  if (!mask && !name) return null;

  const enhancedMask = mask ? `••••${mask}` : null;

  return mask && name ? (
    <Flex gap="sm">
      <Text truncate>{name}</Text> ({enhancedMask})
    </Flex>
  ) : (
    <Text truncate>{name || enhancedMask}</Text>
  );
};
