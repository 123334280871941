import React from "react";
import { Flex, Image } from "@adaptive/design-system";
import adaptiveLogoUrl from "@shared/assets/images/adaptive-logo-green-full.svg";
import { useClientSettings } from "@shared/store/user";

export type BrandingLogoProps = {
  to?: string;
  maxWidth?: string;
  maxHeight?: string;
};

export const BrandingLogo = ({
  maxWidth = "130px",
  maxHeight = "50px",
}: BrandingLogoProps) => {
  const { brandingLogo } = useClientSettings();

  return (
    <Flex
      as={Image}
      src={brandingLogo || adaptiveLogoUrl}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    />
  );
};
