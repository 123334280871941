import React, { memo, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Icon,
} from "@adaptive/design-system";
import { useDeepMemo, useDialog } from "@adaptive/design-system/hooks";
import type { InvoiceMarkupMutate } from "@api/invoices";

import {
  MarkupEditInlineForm,
  type MarkupEditInlineFormProps,
} from "../markup";

import type { Line } from ".";
import { useInvoiceActions, useInvoiceMarkups } from "./invoice-context";
import { transformInvoiceGetMarkupsToInvoicePayload } from "./utils";

const FORM_ID = "invoice-edit-inline-markup";

type InvoiceEditInlineMarkupButtonProps = Line;

export const InvoiceEditInlineMarkupButton = memo(
  ({ id, item, markups }: InvoiceEditInlineMarkupButtonProps) => {
    const dialog = useDialog({ lazy: true });

    const invoiceMarkups = useInvoiceMarkups();

    const { updateInvoice } = useInvoiceActions();

    const [isValid, setIsValid] = useState(false);

    const onSubmit = useCallback<MarkupEditInlineFormProps["onSubmit"]>(
      async (values) => {
        const markups =
          transformInvoiceGetMarkupsToInvoicePayload(invoiceMarkups);

        await updateInvoice({
          markups: [
            ...markups.reduce((acc, markup) => {
              if (!markup.isInlineMarkup || !markup.lines.includes(id)) {
                return [...acc, markup];
              }

              if (markup.lines.length === 0) return acc;

              return [
                ...acc,
                {
                  ...markup,
                  lines: markup.lines.filter((line) => line !== id),
                },
              ];
            }, [] as InvoiceMarkupMutate[]),
            ...values.markups.map((markup) => ({
              item: markup.costCode,
              lines: [id],
              percent: markup.value,
              isInlineMarkup: true,
            })),
          ],
        });

        dialog.hide();
      },
      [dialog, id, invoiceMarkups, updateInvoice]
    );

    const initialValues = useDeepMemo<
      MarkupEditInlineFormProps["initialValues"]
    >(
      () => ({
        markups: markups.map((markup) => ({
          value: markup.percent,
          costCode: markup.item?.url ?? "",
        })),
      }),
      [markups]
    );

    return (
      <>
        {dialog.isRendered && (
          <Dialog
            show={dialog.isVisible}
            variant="dialog"
            onClose={dialog.hide}
          >
            <DialogHeader>
              Edit draw markup: {item?.displayName ?? "Unknown cost code"}
            </DialogHeader>
            <DialogContent>
              <MarkupEditInlineForm
                formId={FORM_ID}
                onSubmit={onSubmit}
                initialValues={initialValues}
                onValidityChange={setIsValid}
              />
            </DialogContent>
            <DialogFooter>
              <Button
                block
                color="neutral"
                onClick={dialog.hide}
                variant="text"
              >
                Cancel
              </Button>
              <Button block type="submit" form={FORM_ID} disabled={!isValid}>
                Save
              </Button>
            </DialogFooter>
          </Dialog>
        )}
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          aria-label="Edit inline markups"
          onClick={dialog.show}
        >
          <Icon name="pen" />
        </Button>
      </>
    );
  }
);

InvoiceEditInlineMarkupButton.displayName = "InvoiceEditInlineMarkupButton";
