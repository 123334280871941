import React, { type ComponentProps } from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type Props = ComponentProps<"header">;

export const ShellSidebarSeparator = ({ className, ...props }: Props) => (
  <header
    className={cn(className, styles["shell-sidebar-separator"])}
    {...props}
  />
);
