import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Button,
  Flex,
  Table,
  type TableEmptyState,
  type TableRowAddon,
  toast,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { debounce } from "@adaptive/design-system/utils";
import type { Expense } from "@api/expenses";
import { handleErrors } from "@api/handle-errors";
import { DropZone } from "@components/draggable/draggable";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import { useClientSettings } from "@store/user";
import { noop } from "@utils/noop";
import { scrollMainTop } from "@utils/scroll-main-top";

import type { StatusMapKey } from "../types";
import {
  DROPZONE_MESSAGES,
  LIST_IMAGE_FORMATS,
  TABLE_MESSAGES,
} from "../utils/constants";

import { getColumns, isError, isPending } from "./columns";
import { useExpenseStatus, useFetchTableData } from "./hooks";

type ExpenseTableProps = {
  selection: Expense[];
  stickyOffset: number;
  setSelection: (expenses: Expense[]) => void;
  hasFilters: boolean;
};

export const ExpenseTable = ({
  selection,
  stickyOffset,
  setSelection,
  hasFilters,
}: ExpenseTableProps) => {
  const uploadsQueueRef = useRef(noop);

  const { cardFeedEnabled } = useClientSettings();

  const { canAddExpense } = useExpensePermissions();

  const { createNew, uploadAttachable } = useExpenseAction();

  const { param, status, setStatus } = useExpenseStatus();

  const {
    data,
    isLoading,
    pagination,
    filters,
    setQueryFilters,
    isError: isRequestError,
  } = useFetchTableData(status);

  const enhancedPagination = useMemo(
    () => ({
      ...pagination,
      onChange: async (nextPage: number) => {
        scrollMainTop(0);
        return pagination.onChange(nextPage);
      },
    }),
    [pagination]
  );

  const rowRender = useCallback<
    Exclude<TableRowAddon<Expense>["render"], undefined>
  >(
    ({ row, props }) => {
      if (!isPending(row)) {
        return (
          <ReactRouterLink
            {...props}
            to={`/expenses/${row.id}?status=${param}`}
            state={{ prev: window.location.pathname + window.location.search }}
          >
            <VisuallyHidden>Open #{row.docNumber}</VisuallyHidden>
          </ReactRouterLink>
        );
      }

      return (
        <button
          {...props}
          onClick={() =>
            toast.warning(
              "Our AI is still working! Please wait a few seconds for it to finish"
            )
          }
        >
          <VisuallyHidden>Open #{row.docNumber}</VisuallyHidden>
        </button>
      );
    },
    [param]
  );

  const tableTestId = `${status}-expenses-table`;

  const row = useMemo<TableRowAddon<Expense>>(
    () => ({
      render: rowRender,
      isLoading: isPending,
      isError,
    }),
    [rowRender]
  );

  const hasData = data.length > 0;

  const columns = useMemo(
    () =>
      getColumns({
        testId: tableTestId,
        status: status === "ALL",
        cardFeedEnabled,
        cardHolderVisibility: status === "FOR_REVIEW" ? "hidden" : "visible",
      }),
    [status, cardFeedEnabled, tableTestId]
  );

  const myReceiptsConflict =
    filters.assignee instanceof Set ? filters.assignee.has(-1) : false;

  const emptyState = useMemo<TableEmptyState>(() => {
    if (isRequestError) return "error";

    if (myReceiptsConflict) {
      return {
        title: TABLE_MESSAGES.EMPTY_STATE_TITLE_WITH_FILTERS,
        action: (
          <Flex gap="lg">
            <Button as="label" htmlFor="clear-users-filter">
              Remove User filter
            </Button>
            <Button as="label" htmlFor="my-expenses-only">
              Disable My receipts toggle
            </Button>
          </Flex>
        ),
        maxWidth: 385,
        subtitle: TABLE_MESSAGES.EMPTY_STATE_SUBTITLE_CONFLICT,
      };
    }

    if (hasFilters) {
      return {
        title: TABLE_MESSAGES.EMPTY_STATE_TITLE_WITH_FILTERS,
        action: (
          <Button as="label" htmlFor="clear-all-filters">
            Clear filters
          </Button>
        ),
        subtitle: TABLE_MESSAGES.LIST_EMPTY_STATE_SUBTITLE_WITH_FILTERS,
      };
    }

    return {
      title: TABLE_MESSAGES.EMPTY_STATE_TITLE,
      action: canAddExpense ? (
        <Button
          as={ReactRouterLink}
          to="/expenses/new"
          state={{ prev: window.location.pathname + window.location.search }}
          data-testid="table-new-expense-button"
          onClick={createNew}
        >
          {TABLE_MESSAGES.EMPTY_STATE_ACTION}
        </Button>
      ) : undefined,
      subtitle: TABLE_MESSAGES.EMPTY_STATE_SUBTITLE,
    };
  }, [
    canAddExpense,
    createNew,
    myReceiptsConflict,
    hasFilters,
    isRequestError,
  ]);

  const header = useMemo(
    () => ({
      hide: !hasData,
      sticky: { offset: stickyOffset },
    }),
    [hasData, stickyOffset]
  );

  const onDrop = useEvent(async (files: File[]) => {
    uploadsQueueRef.current = async () => {
      const results = await Promise.allSettled(
        files.map((file) => uploadAttachable(file))
      );

      results.forEach((item) => {
        if (item.status !== "rejected") return;

        handleErrors(item.reason);
      });
    };
    setStatus("DRAFT");
    enhancedPagination.onChange(0);
  });

  const onSortChange = useEvent((value: string) => {
    scrollMainTop(0);
    ["ALL", "DRAFT", "FOR_REVIEW"].forEach((status) =>
      setQueryFilters(
        { ordering: value, offset: 0 },
        { status: status as StatusMapKey }
      )
    );
  });

  const select = useMemo(
    () => ({
      onChange: setSelection,
      value: selection,
    }),
    [selection, setSelection]
  );

  const runUploadQueue = useCallback(() => {
    uploadsQueueRef.current?.();
    uploadsQueueRef.current = noop;
  }, []);

  const debouncedRunUploadQueue = useMemo(
    () => debounce(runUploadQueue, 300),
    [runUploadQueue]
  );

  const sort = useMemo(
    () => ({ value: filters.ordering, onChange: onSortChange }),
    [filters.ordering, onSortChange]
  );

  /**
   * We need to run the upload queue when the fetching is done
   * so to achieve this, when we drop files to update, we put these
   * files in a queue and run the queue when the fetching is done
   */
  useEffect(() => {
    if (!isLoading) debouncedRunUploadQueue();
  }, [isLoading, debouncedRunUploadQueue]);

  return (
    <DropZone
      portal
      onDrop={onDrop}
      showBorder={false}
      onError={handleErrors}
      idleMessage={DROPZONE_MESSAGES.IDLE}
      imageFormats={LIST_IMAGE_FORMATS}
      hasPermission={canAddExpense}
      pendingMessage={DROPZONE_MESSAGES.PENDING}
      draggingMessage={DROPZONE_MESSAGES.DRAGGING}
    >
      <Table
        row={row}
        size="sm"
        sort={sort}
        data={data}
        header={header}
        select={select}
        columns={columns}
        loading={isLoading}
        pagination={enhancedPagination}
        emptyState={emptyState}
        data-testid={tableTestId}
      />
    </DropZone>
  );
};
