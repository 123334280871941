import React, { type ReactNode } from "react";
import { Text } from "@adaptive/design-system";
import { capitalize } from "@utils/capitalize";

import type { Option } from "../../types";

export type RenderCostCodeAccountLabelHandler = (props: {
  data?: Option[];
  group: string;
  option?: Option | Option[];
  transform?: "lowercase" | "capitalize";
}) => ReactNode;

export type RenderCostCodeAccountPlaceholderHandler = (props: {
  data?: Option[];
  transform?: "lowercase" | "capitalize";
}) => string;

const textTransform = (str: string, transform: "capitalize" | "lowercase") => {
  if (transform === "lowercase") return str.toLowerCase();

  if (transform === "capitalize") return capitalize(str);

  return str;
};

export const renderCostCodeAccountLabel: RenderCostCodeAccountLabelHandler = ({
  data = [],
  option,
  group,
  transform = "capitalize",
}) => {
  const accountGroup = "Account";
  const costCodeGroup = "Cost code";

  const groups = [
    ...(Array.isArray(option) ? option : [option]),
    ...(data ?? []),
  ].map((option) => option?.groupLabel);

  const hasCostCode = groups.includes(costCodeGroup);
  const hasAccount = groups.includes(accountGroup);

  if (!hasCostCode && hasAccount) {
    return textTransform(accountGroup, transform);
  }

  if (!hasAccount && hasCostCode) {
    return textTransform(costCodeGroup, transform);
  }

  const isAccount = group === accountGroup;
  const isCostCode = group === costCodeGroup;

  return (
    <Text as="span">
      <Text
        as={isCostCode ? "strong" : "span"}
        weight={isCostCode ? "bolder" : "regular"}
      >
        {textTransform(costCodeGroup, transform)}
      </Text>{" "}
      /{" "}
      <Text
        as={isAccount ? "strong" : "span"}
        weight={isAccount ? "bolder" : "regular"}
      >
        {textTransform(accountGroup, transform)}
      </Text>
    </Text>
  );
};

export const renderCostCodeAccountPlaceholder: RenderCostCodeAccountPlaceholderHandler =
  ({ data, transform = "capitalize" }) => {
    const accountGroup = "Account";
    const costCodeGroup = "Cost code";

    const groups = (data ?? []).map((option) => option?.groupLabel);

    const hasCostCode = groups.includes(costCodeGroup);
    const hasAccount = groups.includes(accountGroup);

    if (!hasCostCode && hasAccount) {
      return textTransform(accountGroup, transform);
    }

    if (!hasAccount && hasCostCode) {
      return textTransform(costCodeGroup, transform);
    }

    return textTransform(`${costCodeGroup} / ${accountGroup}`, transform);
  };
