import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { type TablePaginationAddon } from "@adaptive/design-system";
import { useLocalStorageState } from "@adaptive/design-system/hooks";
import { DEFAULT_PAGINATION_LIMIT } from "@src/shared/constants";
import { useExpenseQuery } from "@store/expenses";
import type { CollectionQueries } from "@store/expenses/types";
import * as analytics from "@utils/analytics";

import type { StatusMapEntry, StatusMapKey } from "../types";
import { StatusMap } from "../utils/constants";

export const useFetchTableData = (status: CollectionQueries) => {
  const {
    state: { results, count, response },
    filters,
    setFilters,
    setQueryFilters,
  } = useExpenseQuery(status);
  const [statePerPage, setStatePerPage] = useLocalStorageState(
    "expenses-table-limit",
    DEFAULT_PAGINATION_LIMIT
  );

  const pagination = useMemo<Required<TablePaginationAddon>>(() => {
    const perPage =
      statePerPage !== filters.limit
        ? statePerPage
        : filters.limit || DEFAULT_PAGINATION_LIMIT;

    const page = Math.floor(Math.max((filters.offset || 1) / perPage));

    return {
      perPage,
      perPageVariant: "lg",
      page,
      total: count,
      maxCount: window.MAX_COUNT_PAGINATION_LIMIT,
      onChange: (nextPage: number) =>
        setQueryFilters({ offset: nextPage * perPage }),
      onPerPageChange: (nextPerPage: number) => {
        setFilters({ limit: nextPerPage }, status);
        setQueryFilters({ offset: 0 });
        setStatePerPage(nextPerPage);
        analytics.track("perPageLimitChange", {
          location: "expenses-tables",
          limit: nextPerPage,
        });
      },
    };
  }, [
    filters.offset,
    filters.limit,
    count,
    setQueryFilters,
    setFilters,
    status,
    setStatePerPage,
    statePerPage,
  ]);

  return {
    data: results,
    total: count,
    setFilters,
    setQueryFilters,
    filters,
    pagination,
    isLoading: response === "pending" || response === "canceled" || !response,
    isError: response === "rejected",
  };
};

const getStatus = (s: string): StatusMapEntry => {
  const normalized = s.toLowerCase();

  const status = Object.entries(StatusMap).find(
    ([, val]) => val === normalized
  ) as StatusMapEntry | undefined;

  const defKey: StatusMapKey = "DRAFT";

  return status ? status : [defKey, StatusMap[defKey]];
};

export const useExpenseStatus = () => {
  const [params, setSearchParams] = useSearchParams();

  const [status, param] = getStatus(params.get("status") || "");

  const setStatus = useCallback(
    (status: StatusMapKey) => setSearchParams({ status: StatusMap[status] }),
    [setSearchParams]
  );

  return { param, status, setStatus };
};
