import { createContext, useContext } from "react";

import type { RadioGroupProps } from "./radio-group";

type ContextType = Pick<
  RadioGroupProps,
  "name" | "value" | "onChange" | "errorMessage" | "disabled" | "required"
>;

export const RadioGroupContext = createContext<ContextType>({});

export const useRadioGroup = () => useContext(RadioGroupContext);
