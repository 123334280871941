import { currencySchema, idSchema } from "@src/shared/utils/schema";
import { format } from "date-fns";
import { z } from "zod";

const changeLineCreatePayloadSchema = z.object({
  id: idSchema.optional(),
  amount: currencySchema,
  externalAmount: currencySchema,
  jobCostMethod: z.string().url(),
});

export const markupChangeSchema = z.object({
  id: idSchema.nullish(),
  value: z.number(),
  markupType: z.string(),
  jobCostMethod: z.string().url(),
  changeLineJobCostMethods: z.array(z.string().url()).nullish(),
});

export const changeCreatePayloadSchema = z.object({
  lines: z.array(changeLineCreatePayloadSchema),
  markups: z.array(
    markupChangeSchema.transform(({ changeLineJobCostMethods, ...data }) => ({
      ...data,
      ...(data.markupType === "percentage" ? { changeLineJobCostMethods } : {}),
    }))
  ),
  description: z.string().nullish(),
  docNumber: z.string().max(45).nullish(),
  date: z.date().transform((value) => format(value, "yyyy-MM-dd")),
  customerId: idSchema,
});

export const changesGetPayloadSchema = z.object({
  customerId: idSchema,
  budgetLineId: z.string().uuid().optional(),
  page: z.number().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  ordering: z.string().optional(),
});

export const getChangePayloadSchema = z.object({
  customerId: idSchema,
  changeId: idSchema,
});

export const changeUpdateSchema = getChangePayloadSchema.merge(
  changeCreatePayloadSchema
);
