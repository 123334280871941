import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  TableConfigurationButton,
  TableProvider,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  getNonFieldErrors,
  handleErrors,
  isNonFieldErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import {
  updatePurchaseOrder,
  useBatchConvertToAdaptiveMutation,
} from "@api/purchase-orders";
import {
  markAsSignedPurchaseOrder,
  unmarkAsSignedPurchaseOrder,
} from "@api/purchase-orders";
import { useUpdateQuickBooksErrorsMutation } from "@api/quickbooks";
import { BatchApplyObject } from "@components/batch-apply-object";
import { PoRequestDialog } from "@components/request-vendor-po-signature";
import { STRINGS } from "@components/request-vendor-po-signature/constants";
import { Sticky, StickyMeasurer, StickyProvider } from "@components/sticky";
import { TableFilterControls } from "@components/table-filter";
import { useApplyObject } from "@hooks/use-apply-object";
import { useIntegrationType } from "@hooks/use-integration-type";
import { setNeedsRefresh } from "@store/purchaseOrderListSlice";
import { useClientSettings } from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { scrollMainTop } from "@utils/scroll-main-top";

import { PO_SIGNATURE_STATUS_OPTIONS, TYPE_STATUSES } from "./constants";
import { PurchaseOrdersTable } from "./purchase-orders-table";
import { promptConversionToast, promptConversionWarning } from "./utils";

const Actions = ({ status, selected, setSelected }) => {
  const dispatch = useDispatch();

  const integrationType = useIntegrationType();

  const [isLoading, setIsLoading] = useState(false);

  const [updateQuickBooksErrorsMutation] = useUpdateQuickBooksErrorsMutation();

  const [batchConvertToAdaptive] = useBatchConvertToAdaptiveMutation();

  const applyObject = useApplyObject({
    items: selected,
    resource: "purchaseorders",
  });

  const settings = useClientSettings();

  const onBatchApply = useEvent(async ({ value, fieldName }) => {
    if (!value) return;

    try {
      const { success, errorResponses } = await applyObject.execute({
        value,
        method: "apply_object",
        fieldName,
      });

      if (errorResponses.length) {
        errorResponses.forEach((error) => {
          analytics.track("purchaseOrderBatchActionsError", {
            value,
            action: fieldName,
            location: `${status}_tab`,
            purchaseOrderIds: selected.map(({ id }) => id),
          });
          handleErrors(error);
        });
      }

      if (success) {
        toast.success(
          `${success} Purchase order${success > 1 ? "s" : ""} updated!`
        );
      }

      analytics.track("purchaseOrderBatchActions", {
        value,
        action: fieldName,
        location: `${status}_tab`,
        purchaseOrderIds: selected.map(({ id }) => id),
      });

      dispatch(
        setNeedsRefresh({
          status: status === "Open" ? "open" : "closed",
          refresh: true,
        })
      );
    } catch (e) {
      toast.error(`Error updating purchase orders`);
    }
  });

  const onUpdateStatus = useEvent(async () => {
    setIsLoading(true);

    const requests = selected.map(async ({ id, doc_number }) => {
      try {
        await updatePurchaseOrder({
          id,
          po_status: status === "Closed" ? "Open" : "Closed",
        });
      } catch (e) {
        if (isNonFieldErrors(e)) {
          const errors = getNonFieldErrors(e).map(
            (error) => `Purchase order #${doc_number}: ${error}`
          );

          throw { non_field_errors: errors };
        }

        throw e;
      }
    });

    const { success, errorResponses } = summarizeResults(
      await Promise.allSettled(requests)
    );

    if (errorResponses.length) {
      errorResponses.forEach((error) => handleErrors(error));
    }

    if (success) {
      toast.success(
        `${success} Purchase order${success > 1 ? "s" : ""} ${
          status === "Closed" ? "opened" : "closed"
        }!`
      );
    }

    analytics.track("purchaseOrderBatchActions", {
      action: status === "Closed" ? "open" : "closed",
      location: `${status}_tab`,
      purchaseOrderIds: selected.map(({ id }) => id),
    });
    setSelected([]);
    setIsLoading(false);
    dispatch(setNeedsRefresh({ status: "open", refresh: true }));
    dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
  });

  const onIgnoreSyncErrors = useEvent(async () => {
    setIsLoading(true);

    const ids = selected
      .map((item) => item.errors.map((error) => error.id))
      .flat();

    try {
      await updateQuickBooksErrorsMutation({ ids, isIgnored: true }).unwrap();
      analytics.track("purchaseOrderBatchActions", {
        action: "ignore-sync-errors",
        location: `${status}_tab`,
        purchaseOrderIds: selected.map(({ id }) => id),
      });
      toast.success(
        `${ids.length} Purchase order${
          ids.length > 1 ? "s" : ""
        } with sync errors ignored!`
      );
      setSelected([]);
      setIsLoading(false);
      dispatch(setNeedsRefresh({ status: "open", refresh: true }));
      dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
    } catch (e) {
      handleErrors(e);
      setIsLoading(false);
    }
  });

  const isDisabledSyncErrors = selected.some(
    (item) =>
      item.errors?.length === 0 ||
      item.errors?.some((error) => error.is_ignored)
  );

  const isDisabledConversion = selected.some(
    (item) => item.type === "adaptive"
  );

  const requestSignatureDialog = useDialog({
    lazy: true,
  });

  const onRequestSubmit = useEvent(async () => {
    dispatch(setNeedsRefresh({ status: "open", refresh: true }));
    dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
  });

  const onConvert = useEvent(() => {
    const handle = async () => {
      try {
        const ids = selected.map(({ id }) => id);
        await batchConvertToAdaptive(ids).unwrap();
        promptConversionToast({ count: ids.length });
        dispatch(setNeedsRefresh({ status: "open", refresh: true }));
        dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
      } catch (e) {
        handleErrors(e);
      }
    };

    promptConversionWarning({ onConfirm: handle });
  });

  const onUnmarkAsSigned = useEvent(async (purchaseOrders) => {
    const requests = purchaseOrders.map(async (purchaseOrder) => {
      try {
        await unmarkAsSignedPurchaseOrder(purchaseOrder);
      } catch (error) {
        throw transformErrorToCustomError({
          error,
          extra: { doc_number: `PO#${purchaseOrder.doc_number}` },
          render: (message) =>
            message == "PO not marked as signed"
              ? `${STRINGS.PO_NOT_MARKED_AS_SIGNED_ERROR_TOAST}:`
              : `${STRINGS.UNMARK_AS_SIGNED_ERROR_TOAST}:`,
        });
      }
    });
    const { success, errorResponses } = summarizeResults(
      await Promise.allSettled(requests)
    );

    const enhancedErrors = parseCustomErrors({
      errors: errorResponses,
      render: ({ isFirst, message, doc_number }) =>
        `${message}${isFirst ? "" : ","} ${doc_number}`,
    });

    if (enhancedErrors.length) {
      enhancedErrors.forEach((error) =>
        handleErrors(error, { maxWidth: 800, truncate: 2 })
      );
    }
    if (success) {
      const plural = success > 1;
      toast.success(
        `${success} purchase order${plural ? "s" : ""} ${plural ? "were" : "was"} unmarked as signed`
      );
    }
    onRequestSubmit();
    setSelected([]);
  });

  const onMarkAsSigned = useEvent(async () => {
    const markedAsSignedPurchaseOrders = [];
    const requests = selected.map(async (purchaseOrder) => {
      const formData = new FormData();
      try {
        await markAsSignedPurchaseOrder(purchaseOrder, formData);
        analytics.track("purchaseOrderBatchActions", {
          action: "mark-as-signed",
          location: `${status}_tab`,
          purchaseOrderIds: selected.map(({ id }) => id),
        });
      } catch (error) {
        throw transformErrorToCustomError({
          error,
          extra: { doc_number: `PO#${purchaseOrder.doc_number}` },
          render: (message) =>
            message == "PO already signed"
              ? `${STRINGS.PO_ALREADY_MARKED_AS_SIGNED_ERROR_TOAST}:`
              : `${STRINGS.MARK_AS_SIGNED_ERROR_TOAST}:`,
        });
      }
      markedAsSignedPurchaseOrders.push(purchaseOrder);
    });

    const { success, errorResponses } = summarizeResults(
      await Promise.allSettled(requests)
    );

    const enhancedErrors = parseCustomErrors({
      errors: errorResponses,
      render: ({ isFirst, message, doc_number }) =>
        `${message}${isFirst ? "" : ","} ${doc_number}`,
    });

    if (enhancedErrors.length) {
      enhancedErrors.forEach((error) =>
        handleErrors(error, { maxWidth: 800, truncate: 2 })
      );
    }

    if (success) {
      const plural = success > 1;

      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            {success} purchase order{plural ? "s" : ""}{" "}
            {plural ? "were" : "was"} marked as signed.
          </Text>
          <Flex gap="lg">
            <Link
              as="button"
              type="button"
              onClick={() => onUnmarkAsSigned(markedAsSignedPurchaseOrders)}
            >
              Undo
            </Link>
          </Flex>
        </Flex>
      );
    }
    onRequestSubmit();
    setSelected([]);
  });

  return (
    <>
      {(applyObject.isLoading || isLoading) && <Loader position="fixed" />}
      {selected.length > 0 ? (
        <>
          <Dropdown>
            <DropdownTrigger
              as={Button}
              color="primary"
              data-testid={`${status}-actions-trigger`}
            >
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              {window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED && (
                <>
                  <DropdownItem
                    onClick={requestSignatureDialog.show}
                    data-testid="request-signature"
                  >
                    Send for signature
                  </DropdownItem>

                  <DropdownItem
                    onClick={onMarkAsSigned}
                    data-testid="mark-po-as-signed"
                  >
                    Mark as signed
                  </DropdownItem>
                </>
              )}
              {settings.adaptivePosEnabled && (
                <Tooltip
                  message={
                    isDisabledConversion
                      ? "This is already an Adaptive purchase order"
                      : ""
                  }
                  placement="left"
                >
                  <DropdownItem
                    onClick={onConvert}
                    disabled={isDisabledConversion}
                  >
                    Convert to Adaptive PO
                  </DropdownItem>
                </Tooltip>
              )}
              <DropdownItem onClick={onUpdateStatus}>
                {status === "Closed" ? "Open" : "Close"}
              </DropdownItem>
              <Tooltip
                message={
                  isDisabledSyncErrors
                    ? "You can only ignore sync errors on purchase orders that have sync errors"
                    : ""
                }
                placement="left"
              >
                <DropdownItem
                  onClick={onIgnoreSyncErrors}
                  disabled={isDisabledSyncErrors}
                  data-testid="ignore-sync-errors"
                >
                  Ignore sync errors
                </DropdownItem>
              </Tooltip>
              <DropdownList label="Edit properties">
                <DropdownItem>
                  <BatchApplyObject
                    onChange={onBatchApply}
                    accountFilters={{
                      enabled: integrationType !== "QBDT",
                      only_purchase_order_line_accounts: true,
                    }}
                  />
                </DropdownItem>
              </DropdownList>
            </DropdownList>
          </Dropdown>
          {requestSignatureDialog.isRendered ? (
            <PoRequestDialog
              dialog={requestSignatureDialog}
              multiple
              onSubmit={onRequestSubmit}
              purchaseOrders={selected}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export const PurchaseOrdersContent = ({
  data,
  total,
  order,
  status,
  filters,
  loading,
  isError,
  pagination,
  onOrderChange,
  onFiltersChange,
  onPerPageChange,
  selected,
  setSelected,
}) => {
  const [stickyOffset, setStickyOffset] = useState(0);

  const setPage = useEvent((page) => pagination.setPage(page));

  const integrationType = useIntegrationType();

  const settings = useClientSettings();

  const includeFilters = useMemo(() => {
    const filters = [
      "vendors",
      "customers",
      "accounts",
      "costCodes",
      "signature",
    ];

    return integrationType == "QBDT"
      ? filters.filter((item) => item !== "accounts")
      : filters;
  }, [integrationType]);

  const extraData = useMemo(() => {
    let data = [];

    if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
      data = [...data, ...PO_SIGNATURE_STATUS_OPTIONS];
    }

    if (settings.adaptivePosEnabled) {
      data = [...data, ...TYPE_STATUSES];
    }

    return data;
  }, [settings.adaptivePosEnabled]);

  const hasFilters = Object.keys(filters).length > 0;

  const onClearFiltersClick = useEvent(() => onFiltersChange({}));

  const enhancedPagination = useMemo(
    () => ({
      page: pagination.page,
      total,
      perPage: pagination.perPage,
      perPageVariant: pagination.perPageVariant,
      onChange: (page) => {
        scrollMainTop(0);
        setPage(page);
      },
      onPerPageChange,
    }),
    [
      pagination.page,
      pagination.perPage,
      pagination.perPageVariant,
      total,
      onPerPageChange,
      setPage,
    ]
  );

  const onResize = useEvent(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const sort = useMemo(
    () => ({ value: order, onChange: onOrderChange }),
    [order, onOrderChange]
  );

  /**
   * Sometimes after doing some batch actions, the results is empty because we
   * moved all the purchase orders to another status, so we need to adjust the offset
   * to fetch the previous page.
   */
  useEffect(() => {
    if (data.length === 0 && pagination.page > 0) {
      setPage(pagination.page - 1);
    }
  }, [data, status, pagination.page, pagination.limit, setPage]);

  return (
    <Flex direction="column">
      <TableProvider id="purchase-orders-table">
        <StickyProvider onResize={onResize}>
          <Sticky
            style={{
              paddingTop: "var(--spacing-2xl)",
              paddingBottom: "var(--spacing-lg)",
            }}
          >
            <Flex gap="md" direction="column">
              <TableFilterControls
                filters={filters}
                withFilterTags
                includeFilters={includeFilters}
                onFiltersChange={onFiltersChange}
                extraData={extraData}
              >
                {hasFilters && (
                  <Button onClick={onClearFiltersClick}>Clear filters</Button>
                )}

                <Flex grow justify="flex-end" gap="md">
                  <TableConfigurationButton />
                  <Actions
                    order={order}
                    status={status}
                    selected={selected}
                    setSelected={setSelected}
                    onOrderChange={onOrderChange}
                  />
                </Flex>
              </TableFilterControls>
            </Flex>
          </Sticky>
          <StickyMeasurer>
            <PurchaseOrdersTable
              data={data}
              hasFilters={hasFilters}
              sort={sort}
              status={status}
              loading={loading}
              isError={isError}
              selected={selected}
              pagination={enhancedPagination}
              setSelected={setSelected}
              stickyOffset={stickyOffset}
              clearFilters={onClearFiltersClick}
            />
          </StickyMeasurer>
        </StickyProvider>
      </TableProvider>
    </Flex>
  );
};
