import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetOtherNameSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export type UseOtherNameSimplifiedProps = {
  enabled?: boolean;
  includeLabel?: boolean;
};

export const useOtherNamesSimplified = ({
  enabled = true,
  includeLabel = false,
}: UseOtherNameSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const query = useGetOtherNameSimplifiedQuery(
    { realm: realmId as number },
    { skip: !realmId || !enabled }
  );

  return useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Other" : undefined,
  });
};
