import React, { type ComponentProps, useEffect, useRef, useState } from "react";
import {
  Flex,
  SignaturePad,
  type SignaturePadRef,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  TextField,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import { SIGNATURE_TABS, STRINGS } from "./constants";

export type SignatureSavedValue = {
  drawSignature?: string;
  paths?: string[];
  typeSignature?: string;
};

type SignatureFieldProps = {
  onChange?: (values: SignatureSavedValue) => void;
  height?: ComponentProps<typeof Flex>["height"];
  initialValue?: SignatureSavedValue;
};

export const SignatureField = ({
  height = "200px",
  onChange,
  initialValue,
}: SignatureFieldProps) => {
  const signaturePadRef = useRef<SignaturePadRef>(null);
  const [activeTab, setActiveTab] = useState(
    initialValue?.typeSignature ? SIGNATURE_TABS.TYPE : SIGNATURE_TABS.DRAW
  );
  const [values, setValues] = useState<SignatureSavedValue>(initialValue || {});

  const setDrawSignature = useEvent(({ url, paths }) => {
    setValues((prev) => ({ ...prev, drawSignature: url, paths }));
  });

  const setTypeSignature = useEvent((typeSignature: string) => {
    setValues((prev) => ({ ...prev, typeSignature }));
  });

  useEffect(() => {
    const { drawSignature, typeSignature, paths } = values;
    const value =
      activeTab === SIGNATURE_TABS.DRAW
        ? { drawSignature, paths }
        : { typeSignature };
    onChange?.(value);
  }, [activeTab, onChange, values]);

  const fillInitialValue = useEvent(() => {
    if (signaturePadRef.current && initialValue?.paths) {
      signaturePadRef.current.setPaths?.(initialValue.paths);
    }
  });

  useEffect(() => fillInitialValue(), [fillInitialValue]);

  return (
    <Flex direction="column" gap="xl" width="full">
      <Tabs value={activeTab} onChange={setActiveTab}>
        <TabsList>
          <TabsTab value={SIGNATURE_TABS.DRAW}>
            {STRINGS.DRAW_SIGNATURE}
          </TabsTab>
          <TabsTab value={SIGNATURE_TABS.TYPE}>
            {STRINGS.TYPE_SIGNATURE}
          </TabsTab>
        </TabsList>

        <TabsPanel value={SIGNATURE_TABS.DRAW}>
          <Flex height={height}>
            <SignaturePad onChange={setDrawSignature} ref={signaturePadRef} />
          </Flex>
        </TabsPanel>

        <TabsPanel value={SIGNATURE_TABS.TYPE}>
          <Flex height={height}>
            <TextField
              value={values.typeSignature}
              onChange={setTypeSignature}
            />
          </Flex>
        </TabsPanel>
      </Tabs>
    </Flex>
  );
};
