export const divide = (
  dividend: number,
  divisor: number,
  fallback?: number
) => {
  if (divisor === 0) {
    if (fallback === undefined) throw new Error("Divide by zero");

    return fallback;
  }

  return dividend / divisor;
};
