import React from "react";

import { Documents } from "./documents/documents";
import { Accounting } from "./accounting";
import { Info } from "./info";

export const ROUTE_LABEL = {
  INFO: "Basic information",
  ACCOUNTING: "Payment details",
  DOCUMENTS: "Documents",
};

export const getRouteConfigurations = (hasExpiredDocuments?: boolean) => {
  return [
    {
      element: <Info />,
      index: true,
      path: "info",
      label: ROUTE_LABEL.INFO,
    },
    {
      element: <Accounting />,
      path: "payments",
      label: ROUTE_LABEL.ACCOUNTING,
    },
    {
      element: <Documents />,
      path: "docs",
      label: ROUTE_LABEL.DOCUMENTS,
      error: hasExpiredDocuments && "Some documents have expired",
    },
  ];
};
