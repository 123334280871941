import React, {
  type ComponentProps,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Dialog,
  Flex,
  Icon,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDeepMemo, useDialog } from "@adaptive/design-system/hooks";
import type { BillableExpense, InvoiceLineMutate } from "@api/invoices";
import { INVOICE_STRINGS } from "@src/jobs";
import { useJobPermissions } from "@src/jobs";

import { useInvoice, useInvoiceActions } from "./invoice-context";
import { InvoiceLineCostDialog } from ".";

type InvoiceAddLineButtonProps = Pick<
  ComponentProps<typeof Button>,
  "size" | "variant" | "block" | "children"
>;

export const InvoiceAddLineButton = memo(
  ({
    size = "sm",
    block,
    variant = "ghost",
    children = "Add line from costs",
    ...props
  }: InvoiceAddLineButtonProps) => {
    const [, setSearchParams] = useSearchParams();

    const { hide: dialogHide, ...dialog } = useDialog({ lazy: true });

    const { customer } = useInvoice(["customer"]);

    const { createInvoiceLinesFromTransactions } = useInvoiceActions();

    const { canManage } = useJobPermissions();

    const [transactions, setTransactions] = useState<BillableExpense[]>([]);

    const job = useDeepMemo(
      () => ({
        id: customer.id,
        name: customer.displayNameWithoutCompany,
      }),
      [customer]
    );

    const onSave = useCallback(
      async (lines: InvoiceLineMutate[]) => {
        if (lines.length) {
          await createInvoiceLinesFromTransactions(
            (lines || []).map(({ transactionLine }) => ({ transactionLine }))
          );
          toast.success(
            `Line${lines.length > 1 ? "s" : ""} added to the ${
              INVOICE_STRINGS.LOWERCASE_INVOICE
            }`
          );
        }

        dialogHide();
        setSearchParams({ status: "line-items" });
      },
      [dialogHide, setSearchParams, createInvoiceLinesFromTransactions]
    );

    useEffect(() => {
      if (dialog.isVisible) setTransactions([]);
    }, [dialog.isVisible]);

    return (
      <>
        {dialog.isRendered && (
          <Dialog
            show={dialog.isVisible}
            size="auto"
            variant="dialog"
            onClose={dialogHide}
          >
            <InvoiceLineCostDialog
              job={job}
              onSave={onSave}
              onCancel={dialogHide}
              selected={transactions}
              onSelect={setTransactions}
            />
          </Dialog>
        )}
        <Tooltip
          as={Flex}
          grow={block}
          width={block ? "full" : undefined}
          message={!canManage ? "You don't have permission to do this" : ""}
        >
          <Button
            size={size}
            block={block}
            variant={variant}
            onClick={dialog.show}
            disabled={!canManage}
            {...props}
          >
            {variant === "ghost" && <Icon name="plus" />}
            {children}
          </Button>
        </Tooltip>
      </>
    );
  }
);

InvoiceAddLineButton.displayName = "InvoiceAddLineButton";
