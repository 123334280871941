/**
 * @todo Enable eslint/tslint and fix all issues
 */
/* eslint-disable */
// @ts-nocheck
import { toast } from "@adaptive/design-system";
import { dotObject, omit } from "@adaptive/design-system/utils";
import { handleErrors, UNKNOWN_ERROR_MESSAGE } from "@api/handle-errors";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { switchClient } from "@store/user/thunks";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

import { expenseItemSchema } from "../../api/expenses/response/schema";
import type {
  Attachment,
  AddCommentPayload,
  Expense,
  LineItem as LineItemData,
} from "../../api/expenses/types";
import { RootState } from "../types";
import { selectClientSettings } from "../user/selectors-raw";

import { getLineItem } from "./line";
import { LineCollection } from "./line-types";
import {
  commitExpense,
  fetchExpenseById,
  queryExpenses,
  refetchCurrentExpense,
  syncExpense,
  syncInvoiceChanges,
} from "./thunks";
import type {
  CollectionQueries,
  CommonFilters,
  Entry,
  ExpenseMapping,
  QueryFilters,
} from "./types";
import {
  getInitialCommonFilters,
  getInitialExpense,
  getInitialQuery,
  initialState,
} from "./utils";

//todo move this to utils
export const withPayloadType =
  <T>() =>
  (payload: T) => ({ payload });

type LineItemMapping<T extends keyof LineItemData> = {
  [Key in T]: LineItemData[Key];
};

const withFieldPayloadType =
  <T extends keyof Entry<Expense> | keyof LineItemData>() =>
  (
    payload: T extends keyof Entry<Expense>
      ? Partial<ExpenseMapping<T>>
      : Partial<LineItemMapping<T>>
  ) => ({ payload });

const withFieldPayloadByLineId =
  <T extends keyof LineItemData>() =>
  (payload: { id: keyof LineCollection } & Partial<LineItemMapping<T>>) => ({
    payload,
  });

export const addComment = createAction(
  "expense/comments/add",
  withPayloadType<AddCommentPayload>()
);

export const addLine = createAsyncThunk<void, void, { state: RootState }>(
  "expense/lines/add",
  (_, { dispatch, getState }) => {
    const { is_billable_default_expense } = selectClientSettings(getState());
    dispatch(
      internalAddLine({
        billableStatus: is_billable_default_expense
          ? "Billable"
          : "NotBillable",
      })
    );
  }
);

export const createNewExpense = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("expense/new", (_, { dispatch, getState }) => {
  const { is_billable_default_expense } = selectClientSettings(getState());
  const expense = { ...getInitialExpense() };

  const [firstItemKey] = Object.keys(expense.lines);

  if (firstItemKey) {
    expense.lines[firstItemKey].billableStatus = is_billable_default_expense
      ? "Billable"
      : "NotBillable";
  }

  dispatch(internalCreateNewExpense(expense));
});

export const addAttachment = createAction(
  "expense/attachment/save",
  withPayloadType<Attachment>()
);

export const setField = createAction(
  "expense/setField",
  withFieldPayloadType<keyof Entry<Expense>>()
);

export const setActiveExpense = createAction(
  "expense/setActive",
  withPayloadType<unknown>()
);

export const adjustTax = createAction(
  "expense/tax/adjust",
  withPayloadType<number | null>()
);

const internalAddLine = createAction(
  "expense/lines/internal/add",
  withPayloadType<Partial<LineItemData>>()
);

export const removeLine = createAction(
  "expense/lines/remove",
  withPayloadType<keyof LineCollection>()
);
export const setLineField = createAction(
  "expense/lines/setField",
  withFieldPayloadByLineId<keyof LineItemData>()
);

export const setCommonFilters = createAction(
  "expense/query/setFilter",
  withPayloadType<Partial<CommonFilters>>()
);

export const setController = createAction(
  "expense/query/setController",
  withPayloadType<
    T extends CollectionQueries
      ? {
          status: T;
          controller: AbortController;
        }
      : never
  >()
);

export const setFilterByStatus = createAction(
  "expense/query/setFilterByStatus",
  withPayloadType<
    T extends CollectionQueries
      ? {
          status: T;
          replace?: boolean;
          filters: Partial<Omit<QueryFilters<T>, "review_status">>;
        }
      : never
  >()
);

export const addExpenseInList = createAction(
  "expense/query/add",
  withPayloadType<
    T extends CollectionQueries
      ? {
          data: Entry<Expense>;
          status: T;
        }
      : never
  >()
);

export const updateExpenseInList = createAction(
  "expense/query/update",
  withPayloadType<
    T extends CollectionQueries
      ? {
          data: Entry<Expense>;
          status: T;
        }
      : never
  >()
);

export const removeExpenseFromList = createAction(
  "expense/query/remove",
  withPayloadType<
    T extends CollectionQueries
      ? {
          id: string;
          status: T;
        }
      : never
  >()
);

export const setErrorLength = createAction(
  "expense/setErrorLength",
  withPayloadType<
    T extends CollectionQueries
      ? {
          errorsLength: number;
        }
      : never
  >()
);

const internalCreateNewExpense = createAction(
  "expense/internal/new",
  withPayloadType<Entry<Expense>>()
);

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setField, (state, { payload }) => {
    state.entry = {
      ...state.entry,
      ...payload,
    };
  });

  builder.addCase(adjustTax, ({ entry }, { payload }) => {
    const value = payload || 0;
    entry.tax = {
      isSet: value !== 0,
      value,
    };

    entry.totalAmount = sum(entry.totalAmount, value);
  });

  builder.addCase(addExpenseInList, (state, { payload }) => {
    const { status, data } = payload;
    state.query[status].results = [data, ...state.query[status].results];
    state.query[status].count = state.query[status].count + 1;
  });

  builder.addCase(updateExpenseInList, (state, { payload }) => {
    const { status, data } = payload;
    state.query[status].results = state.query[status].results.map((expense) =>
      expense.id === data.id ? data : expense
    );
  });

  builder.addCase(removeExpenseFromList, (state, { payload }) => {
    const { status, id } = payload;
    state.query[status].results = state.query[status].results.filter(
      (expense) => expense.id !== id
    );
    state.query[status].count = state.query[status].count - 1;
  });

  builder.addCase(setErrorLength, (state, { payload }) => {
    state.errorsLength = payload;
  });

  builder.addCase(internalCreateNewExpense, (state, { payload }) => {
    state.entry = {
      ...payload,
      fetchStatus: "fulfilled",
    };
  });

  builder.addCase(addAttachment, (state, { payload }) => {
    state.entry.attachables = [...(state.entry.attachables || []), payload];
  });

  builder.addCase(addComment, (state, action) => {
    const { payload } = action;
    const newComment = {
      ...payload,
      createdAt: new Date(),
      timelineEventType: "COMMENT",
    };
    state.entry.comments = payload.parentCommentUrl
      ? state.entry.comments.map((comment) => {
          if (comment.url === payload.parentCommentUrl) {
            return {
              ...comment,
              replies: [...(comment.replies || []), newComment],
            };
          }
          return comment;
        })
      : [...state.entry.comments, newComment];
  });

  builder.addCase(commitExpense.pending, (state) => {
    state.entry.fetchStatus = "pending";
  });

  builder.addCase(commitExpense.fulfilled, (state, { payload }) => {
    state.entry.fetchStatus = "fulfilled";
    state.entry.id = payload.id;
    state.entry.docNumber = payload.docNumber;
    state.entry.reviewStatus = payload.reviewStatus;
  });

  builder.addCase(commitExpense.rejected, (state) => {
    state.entry.fetchStatus = "fulfilled";
  });

  builder.addCase(internalAddLine, (state, { payload = {} }) => {
    const defaultBaseLine = getLineItem({
      order: Object.keys(state.entry.lines).length,
    });
    const line = { ...defaultBaseLine, ...payload };

    if (!state.entry.lines || !line.id) return;
    state.entry.lines[line.id] = line;
  });

  builder.addCase(removeLine, (state, { payload }) => {
    delete state.entry.lines[payload];
    state.entry.totalAmount = sumBy(Object.values(state.entry.lines), "amount");
  });

  builder.addCase(setLineField, (state, { payload }) => {
    const { id, ...field } = payload;

    const line = state.entry.lines[id];

    if (line) {
      const accountHasChanged =
        field?.attribution?.groupLabel === "Account" &&
        line.attribution?.url !== field?.attribution?.url;

      const costCodeHasChanged =
        field?.attribution?.groupLabel === "Cost code" &&
        line.attribution?.url !== field?.attribution?.url;

      const customerHasChanged = field?.customer
        ? line.customer?.url !== field?.customer?.url
        : false;

      const amountHasChanged = field?.amount
        ? line.amount != field?.amount
        : false;

      const blockRemainingBudget =
        amountHasChanged ||
        accountHasChanged ||
        costCodeHasChanged ||
        customerHasChanged;

      state.entry.lines[id] = {
        ...line,
        ...field,
        ...(blockRemainingBudget
          ? { remainingBudgetAfterThisExpenseBlocked: true }
          : {}),
      };
    }

    state.entry.totalAmount = sumBy(
      Object.values(state.entry.lines || {}),
      "amount"
    );
  });

  builder.addCase(fetchExpenseById.pending, (state, action) => {
    if (state.entry.id != action.meta.arg) {
      state.entry = getInitialExpense();
    }
    state.entry.fetchStatus = "pending";
  });

  builder.addCase(fetchExpenseById.fulfilled, (state, action) => {
    state.entry = { ...action.payload, fetchStatus: "fulfilled" };
    state.staticEntry = {
      ...action.payload,
    };
  });

  builder.addCase(fetchExpenseById.rejected, (state) => {
    state.entry.fetchStatus = "rejected";
  });

  builder.addCase(refetchCurrentExpense.fulfilled, (state, action) => {
    if (!action.payload) return;

    const fieldsToUpdate = action.meta.arg;

    if (Array.isArray(fieldsToUpdate)) {
      const entry = {};
      fieldsToUpdate.forEach((field) => {
        entry[field] = action.payload[field];
      });
      state.entry = { ...state.entry, ...entry };
      state.staticEntry = { ...state.staticEntry, ...entry };
    } else if (typeof fieldsToUpdate === "function") {
      fieldsToUpdate(action.payload);
    }
  });

  builder.addCase(syncInvoiceChanges.pending, (state, action) => {
    if (state.entry.id != action.meta.arg) {
      state.entry = getInitialExpense();
    }
  });

  builder.addCase(syncInvoiceChanges.fulfilled, (state, action) => {
    const { entry, staticEntry, data } = action.payload;
    const getFieldValue = (dynamicLine, newLine, field, isObject = false) => {
      const dynamicValue = dotObject.get(dynamicLine, field);
      const enhancedDynamicValue = isObject
        ? dotObject.get(dynamicValue, "url")
        : dotObject.get(dynamicLine, field);

      const staticLine = staticEntry.lines[dynamicLine.id];

      if (staticLine) {
        const staticValue = dotObject.get(staticLine, field);
        const enhancedStaticValue = isObject
          ? dotObject.get(staticValue, "url")
          : dotObject.get(staticLine, field);

        const newValue = dotObject.get(newLine, field);
        const enhancedNewValue = isObject
          ? dotObject.get(newValue, "url")
          : dotObject.get(newLine, field);

        const isDirty = enhancedDynamicValue !== enhancedStaticValue;
        const isNewValue = enhancedDynamicValue !== enhancedNewValue;

        if (!isDirty && isNewValue) {
          return newValue;
        }
      }
      return dynamicValue;
    };
    const newLines = {};

    Object.values(entry.lines).forEach((currentLine) => {
      const newLine = data.lines[currentLine.id];
      if (newLine) {
        newLines[currentLine.id] = {
          ...currentLine,
          linkedToDraftInvoice: newLine.linkedToDraftInvoice || false,
          description: getFieldValue(currentLine, newLine, "description"),
          attribution: getFieldValue(currentLine, newLine, "attribution", true),
          account: getFieldValue(currentLine, newLine, "account", true),
          billableStatus: getFieldValue(currentLine, newLine, "billableStatus"),
        };
      } else {
        newLines[currentLine.id] = { ...currentLine };
      }
    });

    state.entry = {
      ...entry,
      vendor: { ...data.vendor },
      linkedInvoices: [...data.linkedInvoices],
      lines: { ...newLines },
      fetchStatus: "fulfilled",
    };

    const newLinesStatic = {};
    Object.values(staticEntry.lines).forEach((staticLine) => {
      const newLine = data.lines[staticLine.id];
      newLinesStatic[staticLine.id] = {
        ...staticLine,
        linkedToDraftInvoice: newLine?.linkedToDraftInvoice || false,
      };
    });

    state.staticEntry = {
      ...staticEntry,
      lines: { ...newLinesStatic },
    };
  });

  builder.addCase(syncInvoiceChanges.rejected, (state) => {
    state.entry.fetchStatus = "rejected";
  });

  builder.addCase(queryExpenses.rejected, (state, action) => {
    if (action.payload !== "CanceledError: canceled") {
      state.query[action.meta.arg].response = "rejected";
      toast.error(UNKNOWN_ERROR_MESSAGE);
    } else {
      state.query[action.meta.arg].response = "canceled";
    }
  });

  builder.addCase(queryExpenses.pending, (state, action) => {
    state.query[action.meta.arg].response = "pending";
  });

  builder.addCase(queryExpenses.fulfilled, (state, action) => {
    const {
      queryStatus: status,
      result: { results, count },
    } = action.payload;

    state.query[status].results = results;
    state.query[status].count = count;
    state.query[status].response = "fulfilled";
    state.query[status].controller = undefined;
  });

  builder.addCase(syncExpense.fulfilled, (state, action) => {
    if (!action.payload) return;

    state.query.ALL.results = state.query.ALL.results.map((item) =>
      item.id === action.payload.id ? action.payload : item
    );
    state.query.DRAFT.results = state.query.DRAFT.results.map((item) =>
      item.id === action.payload.id ? action.payload : item
    );
  });

  builder.addCase(setCommonFilters, (state, { payload }) => {
    state.query.filters = {
      ...state.query.filters,
      ...payload,
    };
  });

  builder.addCase(setController, (state, { payload }) => {
    const { status, controller } = payload;
    state.query[status].controller = controller;
  });

  builder.addCase(setFilterByStatus, (state, { payload }) => {
    const { status, filters, replace = false } = payload;

    const ordering = state.query[status].filters.ordering;

    state.query[status].filters = replace
      ? {
          ...omit(getInitialQuery(status).filters, "ordering"),
          ordering,
          ...filters,
        }
      : { ...state.query[status].filters, ...filters };
  });

  builder.addCase(setActiveExpense, (state, { payload }) => {
    try {
      state.entry = expenseItemSchema.parse(payload);
    } catch (err) {
      handleErrors(err);
    }
  });

  builder.addCase(switchClient.fulfilled, (state) => {
    state.query = {
      DRAFT: getInitialQuery("DRAFT"),
      FOR_REVIEW: getInitialQuery("FOR_REVIEW"),
      ALL: getInitialQuery("ALL"),
      filters: getInitialCommonFilters(),
    };
  });
});
