export const groupApprovers = (approvers: string[]) =>
  approvers.reduce(
    (acc, approver) => ({
      ...acc,
      ...(approver.includes("users")
        ? { users: [...acc.users, approver] }
        : { roles: [...acc.roles, approver] }),
    }),
    { roles: [], users: [] } as Record<"users" | "roles", string[]>
  );
