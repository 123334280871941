export const isField = (el: any) => {
  try {
    return (
      el?.tagName === "INPUT" ||
      el?.tagName === "TEXTAREA" ||
      (el?.tagName === "DIV" &&
        el?.getAttribute?.("contenteditable") === "true")
    );
  } catch {
    return false;
  }
};
