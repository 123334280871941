import React, { useMemo } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Icon,
} from "@adaptive/design-system";
import { useDialog } from "@adaptive/design-system/hooks";
import { useVendorInfo } from "@store/vendors";

import { STRINGS } from "./constants";
import { VENDOR_REQUEST_STATUS } from "./constants";
import { PoRequestDialog } from "./po-signature-request-dialog";

type Status =
  (typeof VENDOR_REQUEST_STATUS)[keyof typeof VENDOR_REQUEST_STATUS];

export type PurchaseOrder = {
  total_amount: string;
  doc_number: string;
  vendor: {
    display_name: string;
    email: string;
    id: string | number;
    url: string;
  };
  id: string | number;
  po_signature_can_be_requested: boolean;
  latest_po_signature_request?: {
    id: string | number;
    created_at: string;
    status: Status;
    vendor_email: string;
  };
};

type Props = {
  purchaseOrder: PurchaseOrder;
  onSubmitRequestPo: () => void;
  onMarkAsSigned: () => void;
  disabled?: boolean;
};
export const RequestVendorPoSignature = ({
  purchaseOrder,
  onSubmitRequestPo,
  onMarkAsSigned,
  disabled,
}: Props) => {
  const { canManageNonPaymentInfo } = useVendorInfo();

  const requestPoDialog = useDialog({ lazy: true });
  const { vendor } = purchaseOrder;

  const selectedPurchaseOrders = useMemo(
    () => [purchaseOrder],
    [purchaseOrder]
  );

  const requestIsPending =
    purchaseOrder.latest_po_signature_request?.status ===
    VENDOR_REQUEST_STATUS.PENDING;

  return (
    <>
      {!canManageNonPaymentInfo ? null : (
        <ButtonGroup size="lg" disabled={disabled || !vendor.id}>
          <Button
            type="submit"
            form="edit-purchase-order-form"
            onClick={!requestIsPending ? requestPoDialog.show : onMarkAsSigned}
          >
            {!requestIsPending
              ? STRINGS.SEND_REQUEST_LABEL
              : STRINGS.MARK_AS_SIGNED_LABEL}
          </Button>
          {!requestIsPending && (
            <Dropdown placement="bottom-end">
              <DropdownTrigger
                as={Button}
                aria-label="Actions"
                data-testid="purchase-order-edit-actions-trigger"
              >
                <Icon name="chevron-down" />
              </DropdownTrigger>
              <DropdownList>
                <DropdownItem onClick={onMarkAsSigned}>
                  {STRINGS.MARK_AS_SIGNED_LABEL}
                </DropdownItem>
              </DropdownList>
            </Dropdown>
          )}
        </ButtonGroup>
      )}

      {requestPoDialog.isRendered ? (
        <PoRequestDialog
          dialog={requestPoDialog}
          multiple={false}
          onSubmit={onSubmitRequestPo}
          purchaseOrders={selectedPurchaseOrders}
        />
      ) : null}
    </>
  );
};
