import React, {
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Loader,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Tooltip,
} from "@adaptive/design-system";
import { ErrorAlert } from "@components/common/error-alert";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import { useCurrentClientFromRealm } from "@hooks/useCurrentClientFromRealm";
import { createSelector } from "@reduxjs/toolkit";
import { RecordStageProvider } from "@shared/sequential";
import { useStageActions, useStageName } from "@shared/sequential/context";
import { asStageContent } from "@shared/sequential/factory";
import type { RootState } from "@store/types";
import { useDrawerVisibility } from "@store/ui";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import {
  selectVendorFetchStatus,
  vendorSelectors,
} from "@store/vendors/selectors";

import { GridContent } from "./vendor/content";
import { Footer } from "./vendor/footer";
import { getRouteConfigurations } from "./vendor/tabs";

const TabMapping = getRouteConfigurations().reduce((map, config) => {
  return {
    ...map,
    [config.label]: config.element,
  };
}, {});

const VendorTab = asStageContent(TabMapping);

const selectVendorFormTitle = createSelector(
  [
    selectVendorFetchStatus,
    (state: RootState) => vendorSelectors.info(state).id,
    (state: RootState) =>
      vendorSelectors.initialSnapshot(state).info?.displayName,
  ],
  (fetchStatus, id, displayName) => {
    if (fetchStatus === "pending") {
      return "Loading...";
    }

    return id ? displayName : "New Vendor";
  }
);

const ProxyContainer = (({ children }) => children) as FC<{
  children: ReactNode | ReactNode[];
}>;

export const VendorFormContent = ({
  tabs,
}: {
  tabs: ReturnType<typeof getRouteConfigurations>;
}) => {
  const fetchStatus = useSelector(selectVendorFetchStatus);

  const currentStage = useStageName();

  const { setStage } = useStageActions();

  const { hasUnsavedChanges } = useVendorInfo();

  const { id, publishedToQuickbooks } = useSelector(vendorSelectors.info);

  const { visible, setVisible, setShouldShowHideConfirmation } =
    useDrawerVisibility("vendor");

  const title = useSelector(selectVendorFormTitle);

  const { realm } = useSelector(vendorSelectors.info);

  const { errors } = useSelector(vendorSelectors.info);

  const { fetchById } = useVendorAction();

  const refetchVendor = useCallback(() => {
    id ? fetchById(id) : Promise.resolve();
  }, [id, fetchById]);

  useCurrentClientFromRealm(visible ? realm : undefined);

  useEffect(() => {
    setShouldShowHideConfirmation(
      ["fulfilled", "idle"].includes(fetchStatus) && hasUnsavedChanges
    );
  }, [fetchStatus, hasUnsavedChanges, setShouldShowHideConfirmation]);

  return (
    <Tabs value={currentStage} onChange={setStage}>
      <DialogHeader>
        <Flex gap="md" justify="flex-start" align="center">
          {fetchStatus === "rejected" ? "Vendor not found" : title}
          {publishedToQuickbooks && <QuickBooksSyncedIcon />}
        </Flex>

        {["fulfilled", "idle"].includes(fetchStatus) && (
          <TabsList>
            {tabs.map((tab) => (
              <TabsTab key={tab.label} value={tab.label}>
                <Flex gap="sm" align="center" justify="center">
                  {tab.label}
                  {tab.error && (
                    <Tooltip
                      as={Icon}
                      size="2xs"
                      name="circle"
                      color="error-200"
                      variant="solid"
                      message={tab.error}
                    />
                  )}
                </Flex>
              </TabsTab>
            ))}
          </TabsList>
        )}
      </DialogHeader>
      <DialogContent>
        <Flex gap="xl" direction="column" shrink={false} minHeight="full">
          {errors.length > 0 && (
            <ErrorAlert
              data={errors}
              onChange={refetchVendor}
              objectType="Vendor"
            />
          )}

          {fetchStatus === "pending" && (
            <GridContent>
              <Flex grow justify="center" align="center">
                <Loader />
              </Flex>
            </GridContent>
          )}

          {["fulfilled", "idle"].includes(fetchStatus) && (
            <TabsPanel>
              <VendorTab Container={GridContent} />
            </TabsPanel>
          )}

          {fetchStatus === "rejected" &&
            "This vendor was deleted or the address of the vendor is incorrect."}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Footer Container={ProxyContainer} onCancel={() => setVisible(false)} />
      </DialogFooter>
    </Tabs>
  );
};

export const VendorForm = () => {
  const { visible } = useDrawerVisibility("vendor");

  const initialStage = useSelector(vendorSelectors.initialStage);

  const { hasExpiredDocuments } = useSelector(vendorSelectors.info);

  const availableTabs = useMemo(
    () => getRouteConfigurations(hasExpiredDocuments),
    [hasExpiredDocuments]
  );

  const availableTabNames = useMemo(
    () => availableTabs.map(({ label }) => label),
    [availableTabs]
  );

  const currentStage = useMemo(
    () =>
      availableTabNames.find(
        (tab) =>
          tab ===
          getRouteConfigurations().find((route) => route.path === initialStage)
            ?.label
      ) ?? "info",
    [availableTabNames, initialStage]
  );

  return visible ? (
    <RecordStageProvider
      names={availableTabNames as any}
      currentStage={currentStage as any}
      finishedStage={availableTabNames[availableTabNames.length - 1]}
    >
      <VendorFormContent tabs={availableTabs} />
    </RecordStageProvider>
  ) : null;
};
