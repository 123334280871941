import React, {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { dotObject } from "../../utils/dot-object";
import { is } from "../../utils/is";
import { suffixify } from "../../utils/suffixify";
import { Tag } from "../tag";
import { Tooltip } from "../tooltip";

import styles from "./tag-group.module.css";

type DefaultComponent = "div";
type Ref = ComponentRef<DefaultComponent>;

type TagProps = ComponentProps<typeof Tag>;
type Size = TagProps["size"];
type Color = TagProps["color"];
type Variant = TagProps["variant"];

type RenderHandler<Data> = (
  item: Data
) => string | number | { hidden: string | number; visible: string | number };

type RenderResult<Data> = {
  item: Data;
  hidden: string | number;
  visible: string | number;
};

type Props<Data> = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "children" | "onClick" | "color"
> & {
  size?: Size;
  data?: Data[];
  limit?: number | "auto";
  color?: Color | ((item: Data) => Color);
  render?: string | RenderHandler<Data>;
  variant?: Variant;
  onClick?: (value: Data) => void;
  onRemove?: (value: Data) => void;
  "data-testid"?: string;
};

const GAP_SIZE = 4;

const MAX_COUNTER = 99;

type GetTagPropsParams<Data> = Pick<
  Props<Data>,
  "size" | "color" | "variant"
> & {
  item: Data;
  onClick?: (item: Data) => void;
  onRemove?: (item: Data) => void;
};

const getTagProps = <Data,>({
  item,
  size,
  color,
  variant,
  onClick,
  onRemove,
}: GetTagPropsParams<Data>) => {
  const isDisabled = is.object(item) && item.disabled === true;

  const enhancedOnClick =
    !isDisabled && onClick ? () => onClick(item) : undefined;

  const enhancedOnRemove =
    !isDisabled && onRemove ? () => onRemove(item) : undefined;

  return {
    as: enhancedOnClick ? "button" : undefined,
    type: enhancedOnClick ? "button" : undefined,
    size,
    color: typeof color === "function" ? color(item) : color,
    variant,
    onClick: enhancedOnClick,
    onRemove: enhancedOnRemove,
  } as TagProps;
};

export const TagGroup = <Data,>({
  data = [],
  size = "md",
  limit,
  color = "neutral",
  render,
  variant = "square",
  onClick,
  onRemove,
  className,
  "data-testid": testId,
  ...props
}: Props<Data>) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const internalRef = useRef<Ref>(null);

  const hiddenWrapperRef = useRef<HTMLDivElement>(null);

  const [internalLimit, setInternalLimit] = useState<number | undefined>(1);

  const enhancedLimit = limit !== "auto" ? limit : internalLimit;

  const adjustVisibleItems = useEvent(() => {
    if (limit !== "auto" || !internalRef.current || !hiddenWrapperRef.current) {
      return;
    }

    hiddenWrapperRef.current.removeAttribute("hidden");

    const items = hiddenWrapperRef.current.querySelectorAll("span");
    const wrapperWidth = internalRef.current.getBoundingClientRect().width;

    let contentWidth =
      (tooltipRef.current?.getBoundingClientRect().width || 0) + GAP_SIZE * 2;
    let visibleLength = 0;

    for (const item of items) {
      const itemWidth = item.getBoundingClientRect().width;
      const nextContentWidth = contentWidth + GAP_SIZE + itemWidth;

      if (nextContentWidth > wrapperWidth) break;

      contentWidth = nextContentWidth;
      visibleLength++;
    }

    hiddenWrapperRef.current.setAttribute("hidden", "");

    setInternalLimit(visibleLength);
  });

  const enhanceItem = useCallback(
    (item: Data): RenderResult<Data> => {
      let rendered: ReturnType<RenderHandler<Data>> = "";

      if (typeof render === "function") {
        rendered = render(item);
      } else if (render && is.object(item)) {
        const value = dotObject.get(item, render);
        if (is.string(value) || is.number(value)) {
          rendered = value;
        }
      }

      const hidden = !rendered
        ? is.string(item) || is.number(item)
          ? item
          : ""
        : is.object(rendered)
          ? rendered.hidden
          : rendered;

      const visible = !rendered
        ? is.string(item) || is.number(item)
          ? item
          : ""
        : is.object(rendered)
          ? rendered.visible
          : rendered;

      return { item, hidden, visible };
    },
    [render]
  );

  const [enhancedData, hiddenData, visibleData] = useDeepMemo(() => {
    const enhancedData = data.reduce((acc, item) => {
      const enhancedItem = enhanceItem(item);
      return !enhancedItem.visible ? acc : [...acc, enhancedItem];
    }, [] as RenderResult<Data>[]);

    return [
      enhancedData,
      enhancedData.slice(
        enhancedLimit ?? enhancedData.length,
        enhancedData.length
      ),
      enhancedData.slice(0, enhancedLimit ?? undefined),
    ];
  }, [data, enhanceItem, enhancedLimit]);

  const hiddenCounter =
    hiddenData.length > MAX_COUNTER ? MAX_COUNTER : hiddenData.length;

  useResizeObserver(internalRef, () => queueMicrotask(adjustVisibleItems), {
    observe: "width",
  });

  useLayoutEffect(() => {
    queueMicrotask(adjustVisibleItems);
  }, [adjustVisibleItems]);

  return (
    <div
      ref={internalRef}
      data-testid={testId}
      className={cn(styles["tag-group"], className, {
        [styles["-limit"]]: limit !== undefined,
      })}
      {...props}
    >
      {limit === "auto" && (
        <div
          ref={hiddenWrapperRef}
          className={cn(styles["wrapper"], styles["-hidden"])}
          hidden
        >
          {enhancedData.map(({ visible, item }, index) => (
            <Tag
              key={index}
              {...getTagProps({
                item,
                size,
                color,
                variant,
                onClick,
                onRemove,
              })}
            >
              {visible}
            </Tag>
          ))}
        </div>
      )}
      <div className={styles["wrapper"]}>
        {visibleData.map(({ visible, item }, index) => (
          <Tag
            key={index}
            data-testid={suffixify(testId, "tag")}
            {...getTagProps({ item, size, color, variant, onClick, onRemove })}
          >
            {visible}
          </Tag>
        ))}
        {hiddenCounter > 0 && (
          <Tooltip
            as={Tag}
            ref={tooltipRef}
            size={size}
            align="left"
            color={typeof color === "string" ? color : undefined}
            variant={variant}
            message={hiddenData.map(({ hidden }) => hidden).join("\n")}
            data-testid={suffixify(testId, "tag")}
            className={cn(styles["remaining"], {
              [styles["-one-char"]]: hiddenCounter < 10,
              [styles["-two-chars"]]: hiddenCounter >= 10,
            })}
          >
            + {hiddenCounter}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
