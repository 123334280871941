import { type RefObject, useEffect, useRef, useState } from "react";

type Ref = HTMLFormElement | Omit<HTMLDivElement, "align">;

const setVisible = (node: Ref, visible: boolean) => {
  node.style.visibility = visible ? "visible" : "hidden";
};

/**
 * Returns if a node is scrollable
 * and the scrollbar's width
 *
 */
export const useScrollableNode = <T extends Ref>(node: RefObject<T>) => {
  const [scrollable, setScrollable] = useState<boolean>();
  const [scrollerWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    if (!node.current) {
      return;
    }

    const observed = node.current;

    const ro = new ResizeObserver(() => {
      const { clientHeight, clientWidth, offsetWidth, scrollHeight } = observed;
      setScrollWidth(offsetWidth - clientWidth);
      setScrollable(scrollHeight > clientHeight);
    });

    ro.observe(observed);

    return () => {
      setScrollable(false);
      ro.unobserve(observed);
    };
  }, [node]);

  return { scrollable, scrollerWidth } as const;
};

/**
 * Automatically adjust scrollbar-side (right) padding
 * by the width of the scrollbar when the node is scrollable
 */
export const useStableScrollbar = <T extends Ref>() => {
  const scrollRef = useRef<T>(null);

  const [noScrollPadding, setNoScrollPadding] = useState<number>();
  const { scrollable, scrollerWidth } = useScrollableNode(scrollRef);

  useEffect(() => {
    if (scrollRef.current && !noScrollPadding) {
      const paddingString =
        window
          .getComputedStyle(scrollRef.current, null)
          // use padding from the left since they should be
          // even though variable padding will be on the right
          ?.getPropertyValue("padding-left") || "0";
      setNoScrollPadding(parseFloat(paddingString));
    }
  }, [noScrollPadding]);

  useEffect(() => {
    if (!scrollRef.current || noScrollPadding === undefined) {
      return;
    }

    setVisible(scrollRef.current, false);

    const scrollerAwarePadding = scrollable
      ? noScrollPadding - scrollerWidth
      : noScrollPadding;

    scrollRef.current.style.paddingRight = `${scrollerAwarePadding}px`;

    requestAnimationFrame(() => {
      if (!scrollRef.current) {
        return;
      }
      setVisible(scrollRef.current, true);
    });
  }, [noScrollPadding, scrollable, scrollerWidth]);

  return scrollRef;
};
