import React from "react";
import { Flex } from "@adaptive/design-system";

import { List } from "./list";
import { DrawSettings } from "./settings";

export const Tab = () => {
  return (
    <Flex gap="4xl" direction="column">
      <List />
      <DrawSettings />
    </Flex>
  );
};
