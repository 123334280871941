import { noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import type { Mode } from ".";

export const DEFAULT_LINE_ITEMS_SORT_BY = "item_account";

export const DEFAULT_DRAW_SCHEDULE_SORT_BY = "jobCostMethod";

export const DEFAULT_DRAW_SCHEDULE_VIEW_MODE = "jobCostMethod";

type InvoiceFormContextType = {
  mode: Mode;
  lineItemsSortBy: string;
  drawScheduleSortBy: string;
  drawScheduleViewMode: string;
  setLineItemsSortBy: (value: string) => void;
  setDrawScheduleSortBy: (value: string) => void;
  setDrawScheduleViewMode: (value: string) => void;
};

export const InvoiceFormContext = createContext<InvoiceFormContextType>({
  mode: "view",
  lineItemsSortBy: DEFAULT_LINE_ITEMS_SORT_BY,
  drawScheduleSortBy: DEFAULT_DRAW_SCHEDULE_SORT_BY,
  drawScheduleViewMode: DEFAULT_DRAW_SCHEDULE_VIEW_MODE,
  setLineItemsSortBy: noop,
  setDrawScheduleSortBy: noop,
  setDrawScheduleViewMode: noop,
});

export const useInvoiceFormMode = () =>
  useContextSelector(InvoiceFormContext, (context) => context.mode);

export const useInvoiceFormLineItemsSortBy = () =>
  useContextSelector(InvoiceFormContext, (context) => context.lineItemsSortBy);

export const useInvoiceFormDrawScheduleSortBy = () =>
  useContextSelector(
    InvoiceFormContext,
    (context) => context.drawScheduleSortBy
  );

export const useInvoiceFormSetLineItemsSortBy = () =>
  useContextSelector(
    InvoiceFormContext,
    (context) => context.setLineItemsSortBy
  );

export const useInvoiceFormSetDrawScheduleSortBy = () =>
  useContextSelector(
    InvoiceFormContext,
    (context) => context.setDrawScheduleSortBy
  );

export const useInvoiceFormDrawScheduleViewMode = () =>
  useContextSelector(
    InvoiceFormContext,
    (context) => context.drawScheduleViewMode
  );

export const useInvoiceFormSetDrawScheduleViewMode = () =>
  useContextSelector(
    InvoiceFormContext,
    (context) => context.setDrawScheduleViewMode
  );
