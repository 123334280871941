import React from "react";
import { Flex, Icon, Tag, Text } from "@adaptive/design-system";
import type {
  WorkflowRoleApprover,
  WorkflowSteps,
  WorkflowUserApprover,
} from "@shared/api/workflows";

type Props = {
  steps: WorkflowSteps[];
  showStatus?: boolean;
};

const getColor = (approver: WorkflowRoleApprover | WorkflowUserApprover) => {
  if (approver.status === "APPROVED") {
    return "success";
  } else if (approver.status === "REJECTED") {
    return "error";
  } else {
    return approver.type === "role" ? "info" : "neutral";
  }
};

export const WorkflowApprovers = ({ steps, showStatus }: Props) => {
  return (
    <Flex wrap={true} gap="md">
      {steps.map((step, index) => (
        <Flex
          gap="sm"
          align="center"
          key={`step-${index}`}
          data-testid="approvers"
        >
          {index > 0 ? <Icon name="arrow-right" /> : null}
          {step.approvers.map((approver, indexApprover) => (
            <Flex key={approver.id} gap="sm" align="center">
              <Tag color={getColor(approver)}>
                <Flex align="center" gap="md">
                  {showStatus &&
                  !!approver.status &&
                  approver.status !== "PENDING" ? (
                    <Icon
                      size="sm"
                      name={approver.status === "APPROVED" ? "check" : "x"}
                      color={
                        approver.status === "APPROVED" ? "brand-2" : "error-200"
                      }
                    />
                  ) : null}

                  {approver.type === "user"
                    ? approver.fullName || approver.email
                    : approver.name}
                </Flex>
              </Tag>{" "}
              {indexApprover < step.approvers.length - 1 ? (
                <Text>{step.requirementType === "ALL_OF" ? "and" : "or"}</Text>
              ) : null}
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
