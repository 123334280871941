import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  type TableColumn,
  TableConfigurationButton,
  type TableEmptyState,
  type TableHeaderAddon,
  type TablePaginationAddon,
  TableProvider,
  type TableSelectAddon,
  type TableSortAddon,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  dotObject,
  formatCurrency,
  formatDate,
} from "@adaptive/design-system/utils";
import { TableFilterControls } from "@components/table-filter";
import type { UseTableFilterOptionsProps } from "@hooks/useTableFilterOptions";
import { renderStatus } from "@src/bills/utils";
import { StatusColumnRender } from "@src/expenses/table/columns-components";

import type { CardTransaction, Cost } from "../api/types";
import {
  MATCH_STEP_FORM_ID,
  MATCH_STEP_TABLE_ID,
  SELECT_COST_STEP_STATUS_FILTER,
  SELECT_COST_STEP_TYPE_FILTER,
  STRINGS,
  UNMATCHED_COSTS_FILTERS,
} from "../constants/constants";
import { useCosts } from "../hooks/use-costs";
import { useMatchCardTransaction } from "../hooks/use-match-card-transaction";
import { getConflicts } from "../utils/get-conflicts";
import { transformCardTransactionToFilters } from "../utils/transform-card-transaction-to-filters";

import { LinkedCostLink } from "./misc";
import type { MatchingTransactionOnChangeHandler } from "./types";

const COLUMNS: TableColumn<Cost>[] = [
  {
    id: "ref",
    name: "Ref #",
    render: (row) => <LinkedCostLink {...(row.parent ? row.parent : row)} />,
    minWidth: 200,
  },
  {
    id: "vendor__display_name",
    name: "Vendor name",
    sortable: "asc",
    render: ({ vendor }) => <Text truncate>{vendor?.displayName}</Text>,
    minWidth: 240,
  },
  {
    id: "date",
    width: 140,
    sortable: true,
    name: "Date",
    render: (row) => {
      const date = row.parent?.date || row.date;
      return date ? formatDate(date) : null;
    },
  },
  {
    id: "type",
    width: 155,
    name: "Type",
    render: "humanReadableType",
  },
  {
    id: "status",
    name: "Status",
    minWidth: 155,
    /**
     * @todo map review status correctly, it should be a mix of
     * bill status render and expense status render
     */
    render: (row) => {
      if (["Bill", "Bill payment"].includes(row.humanReadableType)) {
        return renderStatus(row);
      }

      if (row.humanReadableType === "Receipt") {
        return <StatusColumnRender isArchived={false} errors={[]} {...row} />;
      }

      return null;
    },
  },
  {
    id: "total_amount",
    sortable: true,
    name: "Amount",
    width: 145,
    textAlign: "right",
    render: ({ totalAmount }) =>
      formatCurrency(totalAmount ?? 0, {
        allowNegative: true,
        currencySign: true,
      }),
  },
];

const DATE_PROPS = { grow: true };

const INCLUDE_FILTERS: UseTableFilterOptionsProps["includeFilters"] = [
  "vendors",
  /**
   * @todo add accounts and costCodes to the filter as soon as we support
   * those filters on backend side
   * "accounts",
   * "costCodes",
   */
];

export type MatchingTransactionDialogSelectCostStepProps = {
  onError: (payload: {
    cost: Cost;
    conflicts: ReturnType<typeof getConflicts>;
  }) => void;
  onCancel: () => void;
  onSuccess: MatchingTransactionOnChangeHandler;
  cardTransaction: CardTransaction;
};

export const MatchingTransactionDialogSelectCostStep = ({
  onError,
  onCancel,
  onSuccess,
  cardTransaction,
}: MatchingTransactionDialogSelectCostStepProps) => {
  const [enabled, setEnabled] = useState(false);

  const {
    data,
    page,
    count,
    order,
    setPage,
    perPage,
    setOrder,
    isLoading,
    setFilters,
    rawFilters,
    setPerPage,
    clearFilters,
  } = useCosts({ enabled, query: UNMATCHED_COSTS_FILTERS });

  const hasTagFilters = Object.keys(rawFilters).some(
    (key) => !key.includes("date")
  );

  const hasFilters = Object.values(rawFilters ?? {}).some((filter) => !!filter);

  const [cost, setCost] = useState<Cost | undefined>();

  const [matchCardTransactionTrigger, matchCardTransactionInfo] =
    useMatchCardTransaction();

  const hasData = !!data.length;

  const extraData = useMemo(
    () => [...SELECT_COST_STEP_TYPE_FILTER, ...SELECT_COST_STEP_STATUS_FILTER],
    []
  );

  const sort = useMemo<TableSortAddon>(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData }),
    [hasData]
  );

  const pagination = useMemo<TablePaginationAddon>(
    () => ({
      page,
      total: count,
      perPage,
      onChange: (page) => setPage(page),
      onPerPageChange: (perPage) => {
        setPage(0);
        setPerPage(perPage);
      },
    }),
    [count, page, perPage, setPerPage, setPage]
  );

  const select = useMemo<TableSelectAddon<Cost>>(
    () => ({
      value: cost,
      multiple: false,
      onChange: setCost,
    }),
    [cost]
  );

  const emptyState = useMemo<TableEmptyState>(
    () => ({
      title:
        STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_TITLE,
      subtitle:
        STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_SUBTITLE,
      action: {
        onClick: clearFilters,
        children:
          STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_ACTION,
      },
    }),
    [clearFilters]
  );

  const onFilterChange = useEvent((filters) => {
    setFilters(filters);
    setPage(0);
  });

  const onSubmit = useEvent(async (e) => {
    e.preventDefault();

    if (!cost) return;

    try {
      const matchedCost = await matchCardTransactionTrigger({
        cost: cost.url,
        cardTransactionId: cardTransaction.id,
      }).unwrap();

      const id = dotObject.get(matchedCost, "id", cost.id);
      const url = dotObject.get(matchedCost, "url", cost.url);
      const humanReadableType = dotObject.get(
        matchedCost,
        "humanReadableType",
        cost.humanReadableType
      );

      onSuccess({
        cost: { ...cost, id, url, humanReadableType },
        cardTransaction,
      });
    } catch (e) {
      onError({ cost, conflicts: getConflicts(e) });
    }
  });

  useEffect(() => {
    setFilters(transformCardTransactionToFilters(cardTransaction));
    setEnabled(true);
  }, [cardTransaction, setFilters]);

  useEffect(() => {
    if (!enabled || isLoading || cost || !cardTransaction.match) {
      return;
    }

    const matchedCost = data.find(({ id }) => id == cardTransaction.match?.id);

    if (matchedCost) setCost(matchedCost);
  }, [data, enabled, isLoading, cost, cardTransaction.match]);

  if (!enabled) return null;

  return (
    <>
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_TITLE}
          </Text>
          <Text size="md">{cardTransaction.displayName}</Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <TableProvider id={MATCH_STEP_TABLE_ID}>
          <Flex direction="column" minWidth="890px" gap="lg" height="450px">
            <div>
              <TableFilterControls
                filters={rawFilters}
                dateProps={DATE_PROPS}
                extraData={extraData}
                withFilterTags
                withDateFilter
                includeFilters={INCLUDE_FILTERS}
                onFiltersChange={onFilterChange}
              >
                <Flex width="full" gap="xl" align="center">
                  {hasFilters && clearFilters && (
                    <Button onClick={clearFilters}>
                      {STRINGS.CLEAR_FILTERS_ACTION}
                    </Button>
                  )}
                  <Flex justify="flex-end" grow>
                    <TableConfigurationButton />
                  </Flex>
                </Flex>
              </TableFilterControls>
            </div>
            <Flex as="form" id={MATCH_STEP_FORM_ID} onSubmit={onSubmit}>
              <Table
                size="sm"
                data={data}
                sort={sort}
                header={header}
                select={select}
                loading={isLoading}
                columns={COLUMNS}
                maxHeight={hasTagFilters ? "350px" : "390px"}
                pagination={pagination}
                emptyState={emptyState}
              />
            </Flex>
          </Flex>
        </TableProvider>
      </DialogContent>
      <DialogFooter>
        <Flex width="full">
          <Button block color="neutral" variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
        <Tooltip
          as={Flex}
          width="full"
          message={
            !cardTransaction
              ? STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_VALIDATION_ERROR
              : ""
          }
        >
          <Button
            block
            type="submit"
            form={MATCH_STEP_FORM_ID}
            disabled={matchCardTransactionInfo.isLoading || !cardTransaction}
          >
            {matchCardTransactionInfo.isLoading ? <Loader /> : "Match"}
          </Button>
        </Tooltip>
      </DialogFooter>
    </>
  );
};
