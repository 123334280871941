import React, { useEffect } from "react";
import {
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  PhoneField,
  TextField,
} from "@adaptive/design-system";
import { type UseDialogReturn, useForm } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { z } from "zod";

import { useClientAction, usePeopleAction } from "../../../shared/store/user";

import { RoleOption } from "./role-option";
import { type RoleData } from "./types";

const schema = z.object({
  full_name: z.string().min(1, "The name is required"),
  phone_number: z.string().min(1, "A phone number is required"),
  email: z.string().email("An email address is required"),
  role: z.string().min(1, "The role is required"),
});
type Fields = z.infer<typeof schema>;

type Props = {
  dialog: UseDialogReturn;
  roleData: RoleData[];
};

const INITIAL_VALUES: Fields = {
  full_name: "",
  email: "",
  phone_number: "",
  role: "",
};

export const InvitePersonDialog = ({ dialog, roleData }: Props) => {
  const { invitePerson } = usePeopleAction();

  const { currentClient } = useClientAction();

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        await invitePerson({
          ...values,
          clientId: currentClient?.id || "",
        });
        reset();
        dialog.hide();
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (!dialog.isVisible) reset();
  }, [reset, dialog.isVisible]);

  return (
    <>
      {form.isSubmitting && <Loader position="fixed" />}
      <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
        <DialogHeader>Invite people</DialogHeader>
        <DialogContent>
          <Flex direction="column" as="form" justify="center" {...form.props}>
            <TextField label="Name" required {...form.register("full_name")} />
            <TextField
              type="email"
              label="Email"
              required
              {...form.register("email")}
            />
            <PhoneField
              label="Phone number"
              required
              {...form.register("phone_number")}
            />
            <ComboBox
              required
              data={roleData}
              label="Role"
              renderOption={(option) => (
                <RoleOption
                  {...option}
                  description={option.description as string}
                />
              )}
              {...form.register("role")}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" color="neutral" onClick={dialog.hide} block>
            Cancel
          </Button>
          <Button
            type="submit"
            form={form.id}
            block
            disabled={form.isSubmitting}
          >
            Send invite
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
