import React, { type ComponentProps } from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import styles from "./prose.module.css";

const DEFAULT_COMPONENT = "span";

type Props = ComponentProps<typeof DEFAULT_COMPONENT>;

export const Prose = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  ({ as: Component = DEFAULT_COMPONENT, className, ...props }, ref) => (
    <Component
      ref={ref}
      className={cn(styles["prose"], className)}
      {...props}
    />
  )
);

Prose.displayName = "Prose";
