import React from "react";
import { Flex, Tag, Text } from "@adaptive/design-system";
import type { AccountBalanceV1 } from "@api/bank-accounts";
import lodashStartCase from "lodash.startcase";

import { isBankVerified } from "../utils";

export const Statuses = ({
  payment_account,
  verification_status,
}: AccountBalanceV1) => {
  const isConnectedToQuickbooks = !!payment_account?.display_name;
  return (
    <Flex direction="row" align="center" width="full" gap="lg">
      {isBankVerified(verification_status) && isConnectedToQuickbooks ? (
        <>
          <Text size="lg" color="success-200">
            {payment_account?.display_name}
          </Text>
          <PaymentsAvailableStatus variant="available" />
        </>
      ) : (
        <>
          <ActionRequiredStatus />
          <PaymentsAvailableStatus variant="unavailable" />
        </>
      )}
      {isConnectedToQuickbooks && !isBankVerified(verification_status) && (
        <VerificationStatus verification_status={verification_status} />
      )}
    </Flex>
  );
};

export const VerificationStatus = ({
  verification_status,
}: {
  verification_status: string;
}) => (
  <Tag color="neutral">
    {lodashStartCase(verification_status).replaceAll("_", " ")}
  </Tag>
);

export const ActionRequiredStatus = () => (
  <Text size="lg" color="error-200">
    Action required
  </Text>
);

export const PaymentsAvailableStatus = ({ variant }: { variant: string }) =>
  variant === "available" ? (
    <Text size="lg" color="success-200">
      Payments available
    </Text>
  ) : (
    <Text size="lg" color="error-200">
      Payments unavailable
    </Text>
  );
