import { useMemo } from "react";
import { useSelector } from "react-redux";
import { type z } from "zod";

import {
  useGetDynamicVariablesQuery,
  useGetLienWaiverTemplateQuery,
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useGetSignatureFieldsQuery,
} from "../api/api";
import { type lienWaiverTemplateFormSchema } from "../api/schemas";
import { parseSignatureFields } from "../utils/parse-signature-fields";
import { signatureFieldsSelector } from "../utils/selectors";

export const useTemplateInitialValues = (id?: string) => {
  const { isLoading: isDynamicVariablesLoading } =
    useGetDynamicVariablesQuery();
  const { isLoading: isSignatureFieldsLoading } = useGetSignatureFieldsQuery();
  const { isLoading: isLienWaiverTemplatesLoading } =
    useGetLienWaiverTemplatesQuery();
  const { isLoading: isLienWaiverTypesLoading } =
    useGetLienWaiverTypesListQuery();

  const signatureFieldsResult = useSelector(signatureFieldsSelector);
  const {
    data: lienWaiverTemplate,
    isError: isLienWaiverTemplateError,
    isLoading: isLienWaiverTemplateLoading,
  } = useGetLienWaiverTemplateQuery(
    { id: id || "" },
    { skip: id === "create" }
  );

  const isLoading =
    isDynamicVariablesLoading ||
    isSignatureFieldsLoading ||
    isLienWaiverTemplatesLoading ||
    isLienWaiverTypesLoading ||
    isLienWaiverTemplateLoading;

  const initialValues = useMemo<
    z.output<typeof lienWaiverTemplateFormSchema>
  >(() => {
    const isNewTemplate = id !== "create";

    const signatureFields = parseSignatureFields.toForm(
      signatureFieldsResult,
      lienWaiverTemplate?.signatureFields
    );

    const values = {
      isDefault: false,
      id: isNewTemplate ? id : undefined,
      signatureFields,
      contentHtml: "",
      type: "",
    };

    if (lienWaiverTemplate) {
      values.contentHtml = lienWaiverTemplate.contentHtml;
      values.type = lienWaiverTemplate.type;
      values.isDefault = lienWaiverTemplate.isDefault;
    }

    return values;
  }, [id, signatureFieldsResult, lienWaiverTemplate]);

  return {
    initialValues,
    isNotFound: !!isLienWaiverTemplateError,
    isLoading,
  };
};
