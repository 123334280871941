import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Text,
} from "@adaptive/design-system";
import { formatDate, parseDate } from "@adaptive/design-system/utils";
import type { DocumentType } from "@api/vendors/types";
import { useVendorAction } from "@store/vendors";

import { formatDocumentTypes } from ".";

type AlertProps = {
  vendor?: {
    id: string | number;
    pending_doc_requests?: {
      id: string | number;
      created_at: string;
      document_types: DocumentType[];
    }[];
  };
};

const getDate = (dateString: string | null) =>
  parseDate(dateString ?? "", ["yyyy-MM-dd", "iso"]);

export const RequestedVendorDocumentAlert = ({ vendor }: AlertProps) => {
  const { showVendorById } = useVendorAction();

  const { id: vendorId, pending_doc_requests: pendingDocRequests } =
    vendor ?? {};

  const allUniqueRequestedDocs = useMemo(() => {
    const requestedDocs = (pendingDocRequests || [])
      .map((request) =>
        request.document_types.map((doc, index) => ({
          name: doc as DocumentType,
          index: index,
          createdAt: request.created_at,
        }))
      )
      .flat();

    return Object.values(
      requestedDocs.reduce(
        (acc, doc) => {
          if (doc.createdAt && doc.name && doc.name in acc) {
            const docDate = getDate(doc.createdAt);
            const currentDoc = acc[doc.name];
            const currentDocDate = getDate(currentDoc.createdAt);

            if (currentDocDate && docDate && currentDocDate > docDate) {
              return { ...acc };
            }
          }
          return { ...acc, [doc.name as DocumentType]: doc };
        },
        {} as Record<
          string,
          { name: DocumentType; index: number; createdAt: string }
        >
      )
    );
  }, [pendingDocRequests]);

  if (allUniqueRequestedDocs.length === 0) return null;

  return (
    <Alert variant="info">
      <AlertTitle>
        You sent the vendor a request to provide these documents
      </AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          <ul>
            {allUniqueRequestedDocs.map((doc, index) => {
              const docName = doc.name && formatDocumentTypes([doc.name]);
              const sentOn =
                doc.createdAt &&
                `(Sent on ${formatDate(
                  parseDate(doc.createdAt ?? "", [
                    "yyyy-MM-dd hh:mm",
                    "iso",
                  ]) as Date
                )})`;
              return (
                <li key={index}>
                  <Text as="span" size="sm">
                    {docName} {sentOn}
                  </Text>
                </li>
              );
            })}
          </ul>

          <Flex>
            <Button
              size="sm"
              onClick={() => showVendorById(vendorId as string, "docs")}
            >
              Open vendor profile
            </Button>
          </Flex>
        </Flex>
      </AlertContent>
    </Alert>
  );
};
