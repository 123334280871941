import { useMemo } from "react";
import { noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import {
  DEFAULT_INFO,
  DEFAULT_PERMISSIONS,
  DEFAULT_STATUS,
} from "../constants/constants";

import type { Context as ContextType } from "./types";

const Context = createContext<ContextType>({
  all: DEFAULT_INFO,
  status: DEFAULT_STATUS,
  filters: [],
  setPage: noop,
  setOrder: noop,
  unmatched: DEFAULT_INFO,
  setStatus: noop,
  setPerPage: noop,
  rawFilters: {},
  setFilters: noop,
  tableOffset: 0,
  permissions: DEFAULT_PERMISSIONS,
  clearFilters: noop,
  setTableOffset: noop,
  myTransactions: false,
  removeUsersFilter: noop,
  setMyTransactions: noop,
  selectedTransactions: [],
  setSelectedTransactions: noop,
  disableMyTransactionsFilter: noop,
});

export const Provider = Context.Provider;

export const useHasFilters = () => {
  const filters = useRawFilters();
  const myTransactions = useMyTransactions();

  const hasFilters = useMemo(() => {
    return (
      Object.values(filters ?? {}).some((filter) => !!filter) || myTransactions
    );
  }, [filters, myTransactions]);

  return hasFilters;
};

export const useHasFilterConflict = () =>
  useFilters().some(
    (query) => query.dataIndex === "user" && query.value === -1
  );

export const usePermissions = () =>
  useContextSelector(Context, (context) => context.permissions);

export const usePerPage = () =>
  useFilters().find((query) => query.dataIndex === "limit")?.value as number;

export const usePage = () => {
  const filters = useFilters();

  const offset = filters.find((query) => query.dataIndex === "offset")
    ?.value as number;

  const perPage = filters.find((query) => query.dataIndex === "limit")
    ?.value as number;

  return Math.floor(offset / perPage);
};

export const useOrder = () =>
  useFilters().find((query) => query.dataIndex === "ordering")?.value as string;

export const useInfo = () =>
  useContextSelector(Context, (context) =>
    context.status === "all" ? context.all : context.unmatched
  );

export const useUnmatchedInfo = () =>
  useContextSelector(Context, (context) => context.unmatched);

export const useStatus = () =>
  useContextSelector(Context, (context) => context.status);

export const useTableOffset = () =>
  useContextSelector(Context, (context) => context.tableOffset);

export const useFilters = () =>
  useContextSelector(Context, (context) => context.filters);

export const useRawFilters = () =>
  useContextSelector(Context, (context) => context.rawFilters);

export const useMyTransactions = () =>
  useContextSelector(Context, (context) => context.myTransactions);

export const useSelectedTransactions = () =>
  useContextSelector(Context, (context) => context.selectedTransactions);

export const useActions = () =>
  useContextSelector(Context, (context) => ({
    setPage: context.setPage,
    setOrder: context.setOrder,
    setStatus: context.setStatus,
    setPerPage: context.setPerPage,
    setFilters: context.setFilters,
    clearFilters: context.clearFilters,
    setTableOffset: context.setTableOffset,
    removeUsersFilter: context.removeUsersFilter,
    setMyTransactions: context.setMyTransactions,
    setSelectedTransactions: context.setSelectedTransactions,
    disableMyTransactionsFilter: context.disableMyTransactionsFilter,
  }));
