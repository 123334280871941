import { useEffect, useState } from "react";

import { getPurchaseOrderBills } from "../api/purchase-orders";

type State = {
  data: any;
  status: "loading" | "success" | "failed";
};

type Bill = {
  id: number;
  review_status: string;
};

type Props = {
  purchaseOrderId: number;
  limit?: number;
  offset?: number;
};

type PurchaseOrderBillsResponse = {
  next: unknown;
  count: number;
  previous: unknown;
  results: Bill[];
};

const EMPTY_DATA: PurchaseOrderBillsResponse = {
  next: null,
  count: 0,
  previous: null,
  results: [],
};

export const usePurchaseOrderBills = ({
  purchaseOrderId,
  limit,
  offset,
  ...params
}: Props) => {
  const [state, setState] = useState<State>({
    data: EMPTY_DATA,
    status: "loading",
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setState((state) => ({ ...state, status: "loading" }));

    if (purchaseOrderId) {
      getPurchaseOrderBills({
        purchaseOrderId,
        signal,
        limit,
        offset,
        ...params,
      })
        .then((response) => {
          setState({
            status: "success",
            data: response.data,
          });
        })
        .catch(() => setState({ status: "failed", data: EMPTY_DATA }));
    }

    return () => {
      controller.abort();
    };
    /**
     * @todo Enable this rule and fix useEffect dependencies issue
     */
  }, [purchaseOrderId, limit, offset]); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};
