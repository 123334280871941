import { isValidElement, type ReactElement } from "react";

import { Icon } from "../../components/icon";
import { type IconName, ICONS } from "../../components/icon/constants";
import { Loader } from "../../components/loader";

export const isIcon = (icon: string): icon is IconName =>
  ICONS.includes(icon as IconName);

export const isIconComponent = (
  children: React.ReactNode
): children is ReactElement =>
  isValidElement(children) && [Icon, Loader].includes(children.type as any);
