import { decimalAmountSchema, idSchema } from "@src/shared/utils/schema";
import { z } from "zod";

import { jobCostMethodSchema, paginatedBaseSchema } from "../../response";

export const changeLine = z.object({
  id: idSchema,
  budgetLine: z.string().url(),
  jobCostMethod: jobCostMethodSchema,
  builderAmount: decimalAmountSchema,
  externalAmount: decimalAmountSchema.default(0),
});

export const changeLineGetResponseSchema = changeLine.extend({
  change: z.object({
    id: idSchema,
    description: z.string().nullish(),
    docNumber: z.string(),
  }),
});

export const changeLinesGetListResponseSchema = paginatedBaseSchema.extend({
  results: z.array(changeLineGetResponseSchema),
  totalAmount: decimalAmountSchema,
});
