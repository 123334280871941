import { createContext, useContext } from "react";
import type {
  ColumnOrderState,
  ColumnPinningState,
  VisibilityState,
} from "@tanstack/react-table";

import type {
  Column,
  SetColumnsHandler,
  SetOrderHandler,
  SetStickyHandler,
  SetVisibilityHandler,
} from "./types";

export type Value = {
  id?: string;
  order?: ColumnOrderState;
  sticky?: ColumnPinningState;
  columns?: Column<any>[];
  setOrder?: SetOrderHandler;
  setSticky?: SetStickyHandler;
  visibility?: VisibilityState;
  setColumns?: SetColumnsHandler<any>;
  setVisibility?: SetVisibilityHandler;
  stickyEnabled?: boolean;
  setStickyEnabled?: (enabled: boolean) => void;
  setDescendantsIds?: (cb: (descendantsIds: string[]) => string[]) => void;
};

export const DEFAULT_TABLE_CONTEXT: Value = {
  id: undefined,
  order: undefined,
  sticky: undefined,
  columns: undefined,
  setOrder: undefined,
  setSticky: undefined,
  visibility: undefined,
  setColumns: undefined,
  stickyEnabled: undefined,
  setVisibility: undefined,
  setStickyEnabled: undefined,
};

export const TableContext = createContext<Value>(DEFAULT_TABLE_CONTEXT);

export const useTableContext = () => useContext(TableContext);
