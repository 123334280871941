import type { Vendor } from "@api/vendors/types";
import { createAction } from "@reduxjs/toolkit";

type VendorMapping<T extends keyof Vendor> = {
  [Key in T]: Vendor[Key];
};

const withFieldPayloadType =
  <T extends keyof Vendor>() =>
  (payload: Partial<VendorMapping<T>>) => ({ payload });

export const createVendor = createAction(
  "vendors/create",
  (
    data: Partial<VendorMapping<"displayName">>,
    meta: { creationId: string }
  ) => ({ payload: { data, meta } })
);

export const setIsSubmitting = createAction(
  "vendor/isSubmitting",
  (payload: boolean) => ({ payload })
);

export const setField = createAction(
  "vendor/setField",
  withFieldPayloadType<keyof Vendor>()
);

export const setAddressField = createAction(
  "vendor/address/setField",
  withFieldPayloadType<keyof Vendor["address"]>()
);

export const setAchField = createAction(
  "vendor/bankingAchs/setField",
  withFieldPayloadType<keyof Vendor["bankingAchs"]>()
);

export const resetLastCommit = createAction("vendor/resetLastCommit");
