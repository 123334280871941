function fallbackCopyTextToClipboard(str: string) {
  const textArea = document.createElement("textarea");
  textArea.value = str;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  let succeeded: boolean;

  try {
    succeeded = document.execCommand("copy");
  } catch (e) {
    succeeded = false;
  }

  document.body.removeChild(textArea);

  if (!succeeded) throw new Error("Failed to copy to clipboard");
}

export const copyTextToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str);
  } catch (e) {
    fallbackCopyTextToClipboard(str);
  }
};
