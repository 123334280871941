import { useCallback, useState } from "react";

import { type Stack } from "./context";

export const useStack = <T,>(): Stack<T> => {
  const [stack, setStack] = useState<T[]>([]);

  const isInStack = useCallback(
    (stack: T[], name: T) => stack.includes(name),
    []
  );

  const isTop = useCallback(
    (stack: T[], name: T) => stack[stack.length - 1] === name,
    []
  );

  const push = useCallback(
    (name: T) => {
      setStack((prev) =>
        !isInStack(prev, name) && !isTop(prev, name) ? [...prev, name] : prev
      );
    },
    [isInStack, isTop]
  );

  const pop = useCallback(() => {
    setStack((prev) => (prev.length > 0 ? prev.slice(0, -1) : prev));
  }, []);

  return {
    pop,
    push,
    items: stack,
    isTop: useCallback((name: T) => isTop(stack, name), [isTop, stack]),
    isEmpty: useCallback(() => stack.length === 0, [stack]),
  };
};
