import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import {
  Dropdown,
  DropdownList,
  DropdownSelectableItem,
  DropdownTrigger,
} from "../dropdown";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Loader } from "../loader";
import { Text } from "../text";

import styles from "./pagination.module.css";

type DefaultComponent = "div";

type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "onChange"> & {
  page?: number;
  total: number;
  perPage: number;
  onChange?: (pageNumber: number) => void;
  "data-testid"?: string;
  onPerPageChange?: (perPage: number) => void;
  maxCount?: number;
  disabled?: boolean;
  perPageVariant?: "sm" | "md" | "lg";
};

const PAGE_LIMIT_SM = [10, 25];
const PAGE_LIMIT_MD = [...PAGE_LIMIT_SM, 50, 100];
const PAGE_LIMIT_LG = [...PAGE_LIMIT_MD, 250, 500];
const PAGE_LIMITS_RANGES = {
  sm: PAGE_LIMIT_SM,
  md: PAGE_LIMIT_MD,
  lg: PAGE_LIMIT_LG,
};

const PAGE_RANGE = 3;

export const Pagination = forwardRef<ComponentRef<DefaultComponent>, Props>(
  (
    {
      page,
      total,
      perPage,
      onChange,
      disabled,
      className,
      "data-testid": testId,
      onPerPageChange,
      maxCount,
      perPageVariant = "md",
      ...props
    },
    ref
  ) => {
    const [internalPage, setInternalPage] = useState(0);

    const enhancedPage = page ?? internalPage;

    const totalPages = Math.ceil(total / perPage);

    const enhancedOnPerPageChange = useEvent((value: string) => {
      onPerPageChange?.(Number(value));
    });

    const curriedOnChange = useCallback(
      (page: number) => () => {
        onChange?.(page);
        setInternalPage(page);
      },
      [onChange]
    );

    const limitRanges = useMemo(
      () =>
        PAGE_LIMITS_RANGES[perPageVariant].map((value) => ({
          value: String(value),
          label: `${value} per page`,
        })),
      [perPageVariant]
    );

    useEffect(() => {
      if (!PAGE_LIMITS_RANGES[perPageVariant].includes(perPage)) {
        // eslint-disable-next-line no-console
        console.warn(
          `Invalid 'perPage' attribute value ${perPage} value for given 'perPageVariant' ${perPageVariant} range. Valid values: ${PAGE_LIMITS_RANGES[perPageVariant].join(", ")}`
        );
      }
    }, [perPage, perPageVariant]);

    const to = Math.min((enhancedPage + 1) * perPage, total);

    const from = enhancedPage * perPage + 1;

    return (
      <div ref={ref} className={cn(className, styles["pagination"])} {...props}>
        <Flex align="center" gap="sm">
          <Button
            size="sm"
            variant="ghost"
            onClick={curriedOnChange(0)}
            disabled={0 >= enhancedPage - PAGE_RANGE || disabled}
            aria-label="Go to first page"
            data-testid={suffixify(testId, "first-page")}
          >
            <Icon name="chevrons-left" />
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={curriedOnChange(enhancedPage - 1)}
            disabled={enhancedPage == 0 || disabled}
            aria-label="Go to previous page"
            data-testid={suffixify(testId, "previous-page")}
          >
            <Icon name="chevron-left" />
          </Button>
          {[...Array(totalPages)].map((_, i) => {
            const isOutsideRange =
              i < enhancedPage - PAGE_RANGE || i > enhancedPage + PAGE_RANGE;

            return isOutsideRange ? null : (
              <Button
                key={i}
                size="sm"
                color={enhancedPage == i ? "primary" : "neutral"}
                onClick={curriedOnChange(i)}
                variant={enhancedPage == i ? "solid" : "ghost"}
                aria-label={`Go to page ${i + 1}`}
                data-testid={suffixify(testId, "page")}
                aria-current={enhancedPage == i ? "page" : undefined}
                disabled={disabled}
              >
                {i + 1}
              </Button>
            );
          })}
          <Button
            size="sm"
            variant="ghost"
            onClick={curriedOnChange(enhancedPage + 1)}
            disabled={enhancedPage + 1 == totalPages || disabled}
            aria-label="Go to next page"
            data-testid={suffixify(testId, "next-page")}
          >
            <Icon name="chevron-right" />
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={curriedOnChange(totalPages - 1)}
            disabled={enhancedPage + PAGE_RANGE >= totalPages || disabled}
            aria-label="Go to last page"
            data-testid={suffixify(testId, "last-page")}
          >
            <Icon name="chevrons-right" />
          </Button>
        </Flex>
        <Flex align="center" direction="column">
          <Text size="sm" data-testid={suffixify(testId, "description")}>
            Showing{" "}
            {disabled ? (
              <Loader size="sm" style={{ display: "inline-block" }} />
            ) : (
              from
            )}{" "}
            to{" "}
            {disabled ? (
              <Loader size="sm" style={{ display: "inline-block" }} />
            ) : (
              to
            )}{" "}
            of{" "}
            {maxCount && total === maxCount ? (
              `${maxCount}+ results (filter list to see more)`
            ) : (
              <>
                {disabled ? (
                  <Loader size="sm" style={{ display: "inline-block" }} />
                ) : (
                  total
                )}{" "}
                results
              </>
            )}
          </Text>
          {onPerPageChange && (
            <Dropdown>
              <DropdownTrigger
                animated
                disabled={disabled}
                className={styles["dropdown"]}
              >
                <Text size="sm">{perPage} rows per page</Text>
              </DropdownTrigger>
              <DropdownList>
                <DropdownSelectableItem
                  data={limitRanges}
                  name="table-per-page"
                  value={String(perPage)}
                  onChange={enhancedOnPerPageChange}
                />
              </DropdownList>
            </Dropdown>
          )}
        </Flex>
      </div>
    );
  }
);

Pagination.displayName = "Pagination";
