import React, { memo } from "react";
import { Flex, Icon, Tag, Tooltip } from "@adaptive/design-system";
import { formatPercentage } from "@adaptive/design-system/utils";
import { PERCENTAGE_FORMAT } from "@src/jobs";

import type { Markup } from ".";

type InvoiceCostTableMarkupProps = Markup;

export const InvoiceCostTableMarkup = memo(
  ({
    item,
    color,
    percent,
    isCustomMarkup,
    isInlineMarkup,
  }: InvoiceCostTableMarkupProps) => {
    const formattedPercentage = formatPercentage(percent, PERCENTAGE_FORMAT);

    if (isInlineMarkup === true) {
      return (
        <Tooltip
          as={Tag}
          size="sm"
          color={isCustomMarkup ? "warning" : "neutral"}
          message={`${
            item?.displayName ?? "Unknown item"
          } | ${formattedPercentage}`}
        >
          {formattedPercentage}
        </Tooltip>
      );
    }

    return (
      <Flex width="10px" style={{ overflow: "hidden" }}>
        <Tooltip
          as={Icon}
          size="xs"
          name="circle"
          color={color}
          style={{ marginLeft: "-10px" }}
          offset={{ x: -3 }}
          variant="solid"
          message={`${
            item?.displayName ?? "Unknown item"
          } | ${formattedPercentage}`}
        />
      </Flex>
    );
  }
);

InvoiceCostTableMarkup.displayName = "InvoiceCostTableMarkup";
