import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { handleErrors } from "@api/handle-errors";
import {
  type NotificationSettingsPayload,
  updateNotificationSettings as updateNotificationSettingsApi,
} from "@api/notifications";
import { partialUpdateUser } from "@store/user/slice";

type MutateHandler = (payload: NotificationSettingsPayload) => Promise<void>;

export const useUserNotificationSettingsMutation = () => {
  const dispatch = useDispatch();

  const mutate = useCallback<MutateHandler>(
    async (payload) => {
      try {
        const { data } = await updateNotificationSettingsApi(payload);

        dispatch(partialUpdateUser({ notifications: data }));
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [dispatch]
  );

  return { mutate };
};
