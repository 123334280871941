import { api } from "../../utils/api";
import type { Account } from "../accounts";
import type { User } from "../users";

export type PlaidAccountOwnerV1 = {
  id: string;
  url: string;
  account_balance: string;
  account_owner?: string;
  user?: User;
  payment_account?: Account;
};

export type AccountBalanceV1 = {
  id: string;
  url: string;
  name: string;
  balance: string;
  currency: string;
  type: string;
  mask?: string;
  plaid_account_id: string;
  payment_account: Account;
  bank_name: string;
  verification_status: string;
  users?: User[];
  plaid_account_owners?: PlaidAccountOwnerV1[];
  checkbook_account_is_active: boolean;
};

export type BankAccountV1 = {
  id: string;
  bank_name: string;
  synced_at: string;
  plaid_login_required: boolean;
  account_balances: AccountBalanceV1[];
  type?: string;
};

type BankAccountClient = {
  id: string;
  name: string;
  email: string;
  bank_accounts: BankAccountV1[];
};

type GetBankAccountsResponse = {
  next: unknown;
  count: number;
  previous: unknown;
  results: BankAccountClient[];
};

type GetBankAccountsPollProps = {
  client: string;
  refresh_balance: boolean;
  onSuccess: (options: {
    data: GetBankAccountsResponse;
    stopPoll: () => void;
  }) => void;
};

type GetBankAccountsProps = {
  client: string;
  refresh_balance: boolean;
  verification_status?: string[];
};

type PostBankAccountProps = {
  public_token: string;
  account_balances: [];
  institution: {
    institution_id: string;
    name: string;
  };
  client_id: string;
  refresh_balance: boolean;
  type: string;
};

type PostBankAccountResponse = {
  account_balances: AccountBalanceV1[];
  bank_name: string;
  id: string;
  plaid_institution_id: string;
  sync_status: string;
};

type PutBankAccountProps = PostBankAccountProps;

type PutBankAccountResponse = PostBankAccountResponse;

type DestroyBankAccountProps = {
  client: string;
};

export type PatchAccountBalanceProps = {
  signal?: AbortSignal;
  account: AccountBalanceV1;
  payment_account?: string;
  users?: string[];
  is_transaction_pull_enabled?: boolean;
};

export type PatchPlaidAccountOwnerProps = {
  signal?: AbortSignal;
  plaidAccountOwner: PlaidAccountOwnerV1;
  payment_account?: string;
  user?: string;
};

type PatchAccountBalancesProps = {
  account_balance: string;
  payment_account: string;
}[];

type UpdateBankAccountVerificationStatusProps = {
  id: string;
  verification_string: string;
  client_id: string;
};

export const getBankAccounts = async (params: GetBankAccountsProps) => {
  return await api
    .get<GetBankAccountsResponse>("/api/bankaccounts/", { params })
    .then((response) => response.data);
};

export const putBankAccount = (id: string, params: PutBankAccountProps) => {
  return api
    .put<PutBankAccountResponse>(`/api/bankaccounts/${id}/`, { ...params })
    .then((response) => response.data);
};

export const postBankAccount = (params: PostBankAccountProps) => {
  return api
    .post<PostBankAccountResponse>("/api/bankaccounts/", { ...params })
    .then((response) => response.data);
};

export const patchAccountBalance = ({
  signal,
  account,
  ...params
}: PatchAccountBalanceProps) =>
  api
    .patch(`/api/accountbalances/${account.id}/`, {
      signal,
      ...params,
    })
    .then((response) => response.data);

export const patchAccountBalances = (params: PatchAccountBalancesProps) => {
  api
    .patch(`/api/accountbalances/batch_update/`, params)
    .then((response) => response.data);
};

export const destroyBankAccount = (
  id: string,
  params: DestroyBankAccountProps
) =>
  api
    .delete<void>(`/api/bankaccounts/${id}/`, { params })
    .then((response) => response);

export const destroyAccountBalance = (id: string) =>
  api.delete<void>(`/api/accountbalances/${id}/`).then((response) => response);

export const getBankAccountsPoll = ({
  onSuccess,
  ...params
}: GetBankAccountsPollProps) => {
  const intervalId = setInterval(async () => {
    onSuccess({ data: await getBankAccounts({ ...params }), stopPoll });
  }, 1000);

  const stopPoll = () => clearInterval(intervalId);
  return () => stopPoll();
};

export const createLinkTokenForExistingBank = (bankId: string) =>
  api.post(`/api/banking/create_link_token/${bankId}/`);

export const putBankAccountVerificationStatus = (
  id: string,
  params: UpdateBankAccountVerificationStatusProps
) => {
  return api
    .put<PutBankAccountResponse>(`/api/banking/${id}/verification-status/`, {
      ...params,
    })
    .then((response) => response.data);
};

export const patchPlaidAccountOwner = ({
  signal,
  plaidAccountOwner,
  ...params
}: PatchPlaidAccountOwnerProps) =>
  api
    .patch(`/api/plaidaccountowners/${plaidAccountOwner.id}/`, {
      signal,
      ...params,
    })
    .then((response) => response.data);
