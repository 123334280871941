type PollProps<Result> = {
  fn: () => Promise<Result>;
  validate: (data: Result) => boolean;
  interval?: number;
  maxAttempts?: number;
};

export class PollError extends Error {
  constructor(message = "") {
    super(message);
    this.name = "PollError";
  }
}

export const poll = <Result>({
  fn,
  validate,
  interval = window.FRONTEND_POLLING_INTERVAL || 1000,
  maxAttempts = window.FRONTEND_POLLING_MAX_ATTEMPTS || 100,
}: PollProps<Result>) => {
  let stop = false;
  let timeout: ReturnType<typeof setTimeout>;
  let attempts = 0;

  const execute = async (
    resolve: (result: Result) => void,
    reject: (reason: Error) => void
  ) => {
    try {
      const res = await fn();
      attempts += 1;

      if (validate(res)) {
        resolve(res);
      } else if (maxAttempts === attempts) {
        reject(new PollError("Hit max poll attempts"));
      } else if (!stop) {
        timeout = setTimeout(execute, interval, resolve, reject);
      }
    } catch (e) {
      reject(e as Error);
    }
  };

  const run = () => {
    stop = false;
    attempts = 0;
    return new Promise(execute);
  };

  const clear = () => {
    stop = true;
    clearTimeout(timeout);
  };

  return { run, stop: clear };
};
