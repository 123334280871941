import React, { type ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dialog, Flex, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import { useStableScrollbar } from "@hooks/useStableScrollbar";
import { useStageName } from "@shared/sequential/context";
import { useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import { vendorSelectors } from "@store/vendors/selectors";
import cn from "clsx";

import { STRINGS } from "../constants";

import { useShowNextError } from "./use-show-next-error";
import styles from "./vendor.module.css";
const Container = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { id, hasPendingRequests } = useAppSelector((state) =>
    vendorSelectors.info(state)
  );

  const location = useLocation();

  const navigate = useNavigate();

  const scrollRef = useStableScrollbar<HTMLFormElement>();

  const stageName = useStageName();

  const { saveVendor } = useVendorAction();

  const { hasUnsavedInfoOrBankingChanges, hasEmailChange } = useVendorInfo();

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  useShowNextError();

  const formId = suffixify("vendor", stageName || "unknown-stage");

  const onSubmit = useEvent(async (event: React.FormEvent<HTMLFormElement>) => {
    const isCorrectForm =
      formId === (event.target instanceof HTMLElement ? event.target.id : "");

    if (isCorrectForm) {
      event.preventDefault();
      if (hasEmailChange && hasPendingRequests) {
        dialog.confirmation({
          title: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_TITLE,
          message: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_MESSAGE,
          action: {
            primary: {
              color: "error",
              onClick: () =>
                checkTwoFactorAuth(async () => {
                  await saveVendor();
                  toast.info(STRINGS.REQUESTS_WERE_EXPIRED_TOAST);
                }),
              children: STRINGS.EMAIL_CHANGE_CONFIRMATION_DIALOG_BUTTON,
            },
          },
        });
      } else if (
        hasUnsavedInfoOrBankingChanges &&
        hasUnsavedInfoOrBankingChanges.banking
      ) {
        checkTwoFactorAuth(async () => {
          await saveVendor();
        });
      } else {
        await saveVendor();
      }
    }
  });

  useEffect(() => {
    if (location.pathname.endsWith("/vendors") && id) {
      navigate(`/vendors/${id}`);
    }
  }, [id, location.pathname, navigate]);

  return (
    <form id={formId} ref={scrollRef} onSubmit={onSubmit} noValidate>
      <Flex direction="column" gap="2xl" grow>
        {children}
      </Flex>
    </form>
  );
};

export const GridContent = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => (
  <Flex
    as="main"
    className={cn(styles["content"])}
    direction="column"
    gap="2xl"
    grow
    shrink={false}
  >
    <Container>{children}</Container>
  </Flex>
);

export const GridFooter = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  return (
    <Flex as="footer" className={cn(styles["footer"])}>
      {children}
    </Flex>
  );
};
