/* eslint-disable @typescript-eslint/ban-types */
import React, { type CSSProperties, type PropsWithChildren } from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import type {
  BorderRadii,
  BorderWidths,
  Colors,
  Spacings,
} from "../../styles/variables";
import { parseClockwise } from "../../utils/parse-clockwise";

import styles from "./flex.module.css";

type EnhancedSpacings = Spacings | (string & {});

type Props = PropsWithChildren<{
  gap?: ResponsiveProp<Exclude<EnhancedSpacings, "screen">>;
  grow?: ResponsiveProp<boolean>;
  wrap?: ResponsiveProp<boolean>;
  width?: ResponsiveProp<EnhancedSpacings>;
  align?: ResponsiveProp<CSSProperties["alignItems"]>;
  basis?: ResponsiveProp<CSSProperties["flexBasis"]>;
  height?: ResponsiveProp<EnhancedSpacings>;
  shrink?: ResponsiveProp<boolean>;
  margin?: ResponsiveProp<
    | EnhancedSpacings
    | [EnhancedSpacings, EnhancedSpacings]
    | [EnhancedSpacings, EnhancedSpacings, EnhancedSpacings]
    | [EnhancedSpacings, EnhancedSpacings, EnhancedSpacings, EnhancedSpacings]
  >;
  padding?: ResponsiveProp<
    | EnhancedSpacings
    | [EnhancedSpacings, EnhancedSpacings]
    | [EnhancedSpacings, EnhancedSpacings, EnhancedSpacings]
    | [EnhancedSpacings, EnhancedSpacings, EnhancedSpacings, EnhancedSpacings]
  >;
  justify?: ResponsiveProp<CSSProperties["justifyContent"]>;
  overflow?: CSSProperties["overflow"];
  minWidth?: EnhancedSpacings;
  maxWidth?: EnhancedSpacings;
  separator?: boolean;
  minHeight?: ResponsiveProp<EnhancedSpacings>;
  maxHeight?: EnhancedSpacings;
  direction?: ResponsiveProp<"row" | "column">;
  background?: Colors;
  borderWidth?: ResponsiveProp<
    | BorderWidths
    | [BorderWidths, BorderWidths]
    | [BorderWidths, BorderWidths, BorderWidths]
    | [BorderWidths, BorderWidths, BorderWidths, BorderWidths]
  >;
  borderColor?: Colors;
  borderRadius?: BorderRadii;
}>;

const DEFAULT_COMPONENT = "div";

export const Flex = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      children,
      gap: rawGap,
      grow: rawGrow,
      wrap: rawWrap,
      style,
      width: rawWidth,
      align: rawAlign,
      basis: rawBasis,
      shrink: rawShrink,
      margin: rawMargin,
      height: rawHeight,
      padding: rawPadding,
      justify: rawJustify,
      overflow,
      minWidth,
      maxWidth,
      separator,
      minHeight: rawMinHeight,
      maxHeight,
      className,
      direction: rawDirection,
      background,
      borderWidth: rawBorderWidth,
      borderColor: rawBorderColor,
      borderRadius: rawBorderRadius,
      ...props
    },
    ref
  ) => {
    const align = useResponsiveProp(rawAlign);
    const basis = useResponsiveProp(rawBasis);
    const direction = useResponsiveProp(rawDirection, "row");
    const gap = useResponsiveProp(rawGap);
    const grow = useResponsiveProp(rawGrow);
    const height = useResponsiveProp(rawHeight);
    const justify = useResponsiveProp(rawJustify);
    const margin = useResponsiveProp(rawMargin);
    const padding = useResponsiveProp(rawPadding);
    const shrink = useResponsiveProp(rawShrink, true);
    const width = useResponsiveProp(rawWidth);
    const minHeight = useResponsiveProp(rawMinHeight);
    const wrap = useResponsiveProp(rawWrap, false);
    const borderWidth = useResponsiveProp(rawBorderWidth);
    const borderColor = useResponsiveProp(rawBorderColor);
    const borderRadius = useResponsiveProp(rawBorderRadius);

    const enhancedWidth = width === "screen" ? "screen-x" : width;
    const enhancedHeight = height === "screen" ? "screen-y" : height;
    const enhancedMargin = margin ? parseClockwise(margin) : undefined;
    const enhancedPadding = padding ? parseClockwise(padding) : undefined;
    const enhancedMinWidth = minWidth === "screen" ? "screen-x" : minWidth;
    const enhancedMaxWidth = maxWidth === "screen" ? "screen-x" : maxWidth;
    const enhancedMinHeight = minHeight === "screen" ? "screen-y" : minHeight;
    const enhancedMaxHeight = maxHeight === "screen" ? "screen-y" : maxHeight;
    const enhancedBorderWidth = borderWidth
      ? parseClockwise(borderWidth)
      : undefined;

    const enhancedStyle = {
      ...style,
      "--flex-wrap": wrap ? "wrap" : "nowrap",
      "--flex-shrink": Number(shrink),
      "--flex-direction": direction,
      ...(gap && {
        "--flex-gap": `var(--spacing-${gap}, ${gap})`,
      }),
      ...(grow && {
        "--flex-grow": Number(grow),
      }),
      ...(basis !== undefined && {
        "--flex-basis": basis,
      }),
      ...(borderRadius && {
        "--flex-border-radius": `var(--border-radii-${borderRadius})`,
      }),
      ...(borderColor && {
        "--flex-border-color": `var(--color-${borderColor})`,
      }),
      ...(borderWidth && {
        "--flex-border-style": "solid",
      }),
      ...(enhancedBorderWidth && {
        "--flex-border-width": `var(--border-width-${enhancedBorderWidth.top}) var(--border-width-${enhancedBorderWidth.right}) var(--border-width-${enhancedBorderWidth.bottom}) var(--border-width-${enhancedBorderWidth.left})`,
      }),
      ...(align && {
        "--flex-align": align,
      }),
      ...(justify && {
        "--flex-justify": justify,
      }),
      ...(overflow && {
        "--flex-overflow": overflow,
      }),
      ...(background && {
        "--flex-background": `var(--color-${background})`,
      }),
      ...(enhancedWidth && {
        "--flex-width": `var(--spacing-${enhancedWidth}, ${enhancedWidth})`,
      }),
      ...(enhancedHeight && {
        "--flex-height": `var(--spacing-${enhancedHeight}, ${enhancedHeight})`,
      }),
      ...(enhancedMargin && {
        "--flex-margin": `var(--spacing-${enhancedMargin.top}, ${enhancedMargin.top}) var(--spacing-${enhancedMargin.right}, ${enhancedMargin.right}) var(--spacing-${enhancedMargin.bottom}, ${enhancedMargin.bottom}) var(--spacing-${enhancedMargin.left}, ${enhancedMargin.left})`,
      }),
      ...(enhancedPadding && {
        "--flex-padding": `var(--spacing-${enhancedPadding.top}, ${enhancedPadding.top}) var(--spacing-${enhancedPadding.right}, ${enhancedPadding.right}) var(--spacing-${enhancedPadding.bottom}, ${enhancedPadding.bottom}) var(--spacing-${enhancedPadding.left}, ${enhancedPadding.left})`,
      }),
      ...(enhancedMinWidth && {
        "--flex-min-width": `var(--spacing-${enhancedMinWidth}, ${enhancedMinWidth})`,
      }),
      ...(enhancedMaxWidth && {
        "--flex-max-width": `var(--spacing-${enhancedMaxWidth}, ${enhancedMaxWidth})`,
      }),
      ...(enhancedMinHeight && {
        "--flex-min-height": `var(--spacing-${enhancedMinHeight}, ${enhancedMinHeight})`,
      }),
      ...(enhancedMaxHeight && {
        "--flex-max-height": `var(--spacing-${enhancedMaxHeight}, ${enhancedMaxHeight})`,
      }),
    };

    return (
      <Component
        ref={ref}
        style={enhancedStyle}
        className={cn(className, styles.flex, {
          [styles[`-separator-${direction}`]]: separator,
        })}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

Flex.displayName = "Flex";
