import { api as baseApi } from "@shared/utils/api";
import { poll } from "@shared/utils/poll";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@src/shared/utils/schema/converters";
import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";

import {
  getFidelCardsPayloadSchema,
  pollFidelCardPayloadSchema,
} from "./requests";
import { getFidelCardsResponseSchema } from "./response";
import type {
  GetFidelCardsPayload,
  GetFidelCardsResponse,
  PollFidelCardPayload,
  UpdateFidelCardPayload,
} from "./types";

const enhancedApi = api.enhanceEndpoints({ addTagTypes: ["FidelCards"] });

export const fidelCardsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    updateFidelCard: builder.mutation<void, UpdateFidelCardPayload>({
      query: (fidelCard) => ({
        url: `fidelcards/${fidelCard.id}/`,
        body: transformKeysToSnakeCase(fidelCard),
        method: "PATCH",
      }),
      invalidatesTags: ["FidelCards"],
    }),
    deleteFidelCard: builder.mutation<void, UpdateFidelCardPayload>({
      query: (fidelCard) => ({
        url: `fidelcards/${fidelCard.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["FidelCards"],
    }),
    getFidelCards: builder.query<GetFidelCardsResponse, GetFidelCardsPayload>({
      query: (args) => {
        const params = handleRequest(args, getFidelCardsPayloadSchema);

        return {
          url: "fidelcards/",
          params: transformKeysToSnakeCase(params),
          responseHandler: async (response) =>
            handleResponse(
              transformKeysToCamelCase(await response.json()),
              getFidelCardsResponseSchema
            ),
        };
      },
      providesTags: ["FidelCards"],
    }),
  }),
});

export const pollFidelCard = (params: PollFidelCardPayload) => {
  const enhancedParams = handleRequest(params, pollFidelCardPayloadSchema);

  return poll<GetFidelCardsResponse>({
    fn: () =>
      baseApi
        .get<GetFidelCardsResponse>("/api/fidelcards/", {
          params: transformKeysToSnakeCase(enhancedParams),
        })
        .then(({ data }) => data),
    validate: (data) => data.count > 0,
  });
};

export const {
  useGetFidelCardsQuery,
  useUpdateFidelCardMutation,
  useDeleteFidelCardMutation,
} = fidelCardsApi;
