import React from "react";
import { Flex, Text } from "@adaptive/design-system";

type Props = {
  label: string;
  description: string;
  selected: boolean;
};

export const RoleOption = ({ label, description, selected }: Props) => {
  return (
    <Flex gap="md" direction="column">
      <Text size="md" weight="bolder">
        {label}
      </Text>
      <Text size="xs" color={selected ? undefined : "neutral-700"}>
        {description as string}
      </Text>
    </Flex>
  );
};
