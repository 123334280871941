import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ComboBox,
  Flex,
  LabelValueGroup,
  Link,
  Loader,
  Text,
  Textarea,
  type TextareaMentionAddon,
} from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { fuzzySearch } from "@adaptive/design-system/utils";
import { useAppDispatch } from "@store/hooks";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";

import type { SignatureField } from "../api/types";
import { STRINGS } from "../constants";
import { useFormContext } from "../hooks/use-template-form";
import {
  dynamicVariablesSelector,
  lienWaiverTemplatesSelector,
  lienWaiverTemplateTypesSelector,
  signatureFieldsSelector,
} from "../utils/selectors";

export const TemplateForm = ({ loading }: { loading?: boolean }) => {
  const form = useFormContext();

  const dispatch = useAppDispatch();

  const lienWaiverTemplates = useSelector(lienWaiverTemplatesSelector);
  const lienWaiverTypes = useSelector(lienWaiverTemplateTypesSelector);
  const signatureFields = useSelector(signatureFieldsSelector);
  const dynamicVariables = useSelector(dynamicVariablesSelector);

  const lienWaiverTypeComboboxData = useDeepMemo(
    () =>
      lienWaiverTypes?.map((item) => ({
        label: item.name,
        value: item.url,
        disabled:
          form.values.type !== item.url &&
          !!lienWaiverTemplates.find((temp) => temp.type === item.url) &&
          STRINGS.LIEN_WAIVER_TYPE_IN_USE,
      })),
    [form.values.type, lienWaiverTemplates, lienWaiverTypes]
  );

  const dynamicVariablesList = useMemo(
    () =>
      dynamicVariables.map((variable) => ({
        label: variable.label,
        value: variable.mockValue,
      })),
    [dynamicVariables]
  );

  const [defaultSignatureFields, optionalSignatureFields] = useMemo(() => {
    const fields: [SignatureField[], SignatureField[]] = [
      [signatureFields[0], signatureFields[1]],
      [signatureFields[2], signatureFields[3]],
    ];

    return fields;
  }, [signatureFields]);

  const suggestionsData = useMemo(
    () =>
      dynamicVariables.map((variable) => ({
        value: variable.type,
        ...variable,
      })),
    [dynamicVariables]
  );

  const mentionAddon = useMemo<TextareaMentionAddon | undefined>(() => {
    if (!suggestionsData.length) return undefined;

    return {
      data: suggestionsData,
      trigger: "+",
      renderSuggestion: ({ label, mockValue }) => (
        <Text
          size="sm"
          color={mockValue === "support-item" ? "brand-2" : undefined}
        >
          {label}
        </Text>
      ),
      search: (data, query) => {
        const fuzzyInstance = fuzzySearch(data, {
          keys: ["label"],
          threshold: 0,
        });

        const foundItems = query
          ? fuzzyInstance.search(query).slice(0, 5)
          : data;

        return [
          ...foundItems,
          {
            label: STRINGS.TEMPLATE_DETAILS_INTERCOM_CTA,
            value: "support-item",
            mockValue: "support-item",
          },
        ];
      },
      onAdd: (item) => {
        if (item.value === "support-item") {
          dispatch(toggleChatVisibility(true));
          analytics.track("chatOpen", {
            source: "lien-waiver-template-editor",
          });
          return false;
        }
      },
      generateMentionProps: (item) => ({
        color: "var(--color-neutral-700)",
        background: "var(--color-neutral-300)",
        children: item.label,
      }),
    };
  }, [dispatch, suggestionsData]);

  return (
    <Flex
      gap="5xl"
      padding="5xl"
      direction="column"
      height="full"
      width="full"
      overflow="hidden scroll"
    >
      <Flex direction="column" gap="sm" shrink={false}>
        <Text size="xl" weight="bold">
          {STRINGS.LIEN_WAIVER_TYPE}
        </Text>
        <Text size="sm">
          {STRINGS.LIEN_WAIVER_TYPE_DESCRIPTION}{" "}
          <Link
            size="sm"
            target="_blank"
            rel="noopener noreferrer"
            href={STRINGS.LIEN_WAIVER_TYPE_LEARN_MORE_LINK}
          >
            {STRINGS.LIEN_WAIVER_TYPE_LEARN_MORE}
          </Link>
        </Text>
        <ComboBox
          data={lienWaiverTypeComboboxData}
          messageVariant="absolute"
          loading={loading}
          required
          errorMessage={form.errors.type}
          disabled={form.isSubmitting || loading}
          {...form.register({ name: "type", type: "string" })}
        />
      </Flex>

      <Flex direction="column" gap="sm" shrink={false}>
        <Text size="xl" weight="bold">
          {STRINGS.E_SIGNATURE_FIELDS}
        </Text>
        <Text size="sm">{STRINGS.E_SIGNATURE_FIELDS_DESCRIPTION}</Text>

        <Card style={{ minHeight: 114 }}>
          {loading ? (
            <Flex height="full" width="full" justify="center">
              <Loader />
            </Flex>
          ) : (
            <Flex width="full" gap="xl">
              <Flex direction="column" gap="xl" grow>
                {defaultSignatureFields.map((field) => (
                  <Checkbox
                    key={field.id}
                    label={field.type}
                    disabled={!field.isEditable || form.isSubmitting}
                    {...form.register({
                      name: `signatureFields.${field.id}`,
                      type: "boolean",
                    })}
                  />
                ))}
              </Flex>
              <Flex direction="column" gap="xl" grow>
                {optionalSignatureFields.map((field) => (
                  <Checkbox
                    key={field.id}
                    label={field.type}
                    disabled={!field.isEditable || form.isSubmitting}
                    {...form.register({
                      name: `signatureFields.${field.id}`,
                      type: "boolean",
                    })}
                  />
                ))}
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>

      <Flex direction="column" gap="sm" shrink={false}>
        <Text size="xl" weight="bold">
          {STRINGS.TEMPLATE_DETAILS}
        </Text>
        <Text size="sm">
          {STRINGS.TEMPLATE_DETAILS_DESCRIPTION}{" "}
          <Link
            size="sm"
            target="_blank"
            rel="noopener noreferrer"
            href={STRINGS.TEMPLATE_DETAILS_LEARN_MORE_LINK}
          >
            {STRINGS.TEMPLATE_DETAILS_LEARN_MORE}
          </Link>
        </Text>

        <Card>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Card
                size="sm"
                style={{ width: "100%", marginBottom: "var(--spacing-xl)" }}
              >
                <CardHeader>{STRINGS.SAMPLE_DATA}</CardHeader>
                <CardContent hidden>
                  <Flex direction="row" wrap>
                    <LabelValueGroup compact data={dynamicVariablesList} />
                  </Flex>
                </CardContent>
              </Card>

              <Textarea
                minHeight={400}
                wysiwyg
                messageVariant="absolute"
                mention={mentionAddon}
                disabled={form.isSubmitting}
                {...form.register({ name: "contentHtml", type: "string" })}
              />
            </>
          )}
        </Card>
      </Flex>
    </Flex>
  );
};
