import React, {
  type ComponentProps,
  type FormEventHandler,
  type PropsWithChildren,
  type ReactNode,
} from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Icon, Text } from "@adaptive/design-system";
import { Loader } from "@shared/components/common/Loader";

import { BrandingLogo } from "../branding-logo";

type Props = PropsWithChildren<{
  title: ReactNode;
  backUrl?: string;
  loading?: boolean;
  subtitle?: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  gap?: ComponentProps<typeof Flex>["gap"];
  height?: "full" | "screen";
  contentMaxWidth?: ComponentProps<typeof Flex>["maxWidth"];
  padding?: ComponentProps<typeof Flex>["padding"];
}>;

export const Layout: React.FC<Props> = ({
  children,
  title,
  subtitle,
  backUrl,
  onSubmit,
  gap = "96px",
  height = "screen",
  loading = false,
  contentMaxWidth = "580px",
  padding = ["5xl", "5xl", "none"],
}: Props) => {
  return loading ? (
    <Loader />
  ) : (
    <Flex
      padding={padding}
      height={height === "full" ? "full" : undefined}
      minHeight={height === "screen" ? "screen" : undefined}
      direction="column"
      shrink={false}
    >
      {backUrl ? (
        <Flex justify="flex-start">
          <Button as={Link} variant="ghost" to={backUrl} aria-label="Back">
            <Icon name="arrow-left" />
          </Button>
        </Flex>
      ) : null}
      <Flex direction="column" grow align="center" justify="center">
        <Flex
          direction="column"
          gap={gap}
          padding={["none", "none", "3xl"]}
          width="full"
          maxWidth={contentMaxWidth}
        >
          <Flex gap="2xl" direction="column">
            <Flex align="center" direction="column">
              <BrandingLogo />
              <Text
                size="3xl"
                as="h2"
                family="brand"
                weight="regular"
                align="center"
              >
                {title}
              </Text>
            </Flex>
            {subtitle ? (
              <Text size="md" align="center">
                {subtitle}
              </Text>
            ) : null}
          </Flex>
          <Flex
            justify="center"
            direction="column"
            {...(onSubmit
              ? ({
                  as: "form",
                  onSubmit,
                  noValidate: true,
                } as any)
              : {})}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
