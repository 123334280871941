import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import cn from "clsx";

import { mergeRefs } from "../../utils/merge-refs";

import { useDialogContext } from "./dialog-context";
import styles from "./dialog.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  variant?: "spaced" | "unspaced";
};

export const DialogContent = forwardRef<ComponentRef<DefaultComponent>, Props>(
  ({ children, variant = "spaced", className, ...props }, ref) => {
    const internalRef = useRef<HTMLDivElement>(null);

    const { step, show, hasHeader, hasFooter, setHasContent } =
      useDialogContext();

    useEffect(() => {
      setHasContent?.(true);

      return () => {
        setHasContent?.(false);
      };
    }, [setHasContent]);

    useEffect(() => {
      requestAnimationFrame(() => {
        if (!internalRef.current || !show) return;

        internalRef.current.scrollTop = 0;
      });
    }, [step, show]);

    return (
      <div
        ref={mergeRefs(ref, internalRef)}
        className={cn(className, styles["dialog-content"], {
          [styles["-spaced"]]: variant === "spaced",
          [styles["-has-header"]]: hasHeader,
          [styles["-has-footer"]]: hasFooter,
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
);

DialogContent.displayName = "DialogContent";
