import React, { memo, type PropsWithChildren } from "react";
import { Button, Flex, Icon, TextField } from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import {
  TableFilterControls,
  type TableFilterControlsProps,
} from "@components/table-filter";
import type { Option } from "@shared/types";
import {
  useJobCategories,
  useJobCategoriesIsLoading,
  useJobSettings,
} from "@src/jobs/hooks";
import { useClientSettings } from "@store/user";

import {
  useBudgetsHasFilters,
  useBudgetsQuery,
  useBudgetsRawFilters,
  useBudgetsTableActions,
} from "./budgets-table-context";

const TRANSACTION_TYPES = [
  { label: "Bill", value: "bill", groupLabel: "Transaction type" },
  { label: "Receipt", value: "expense", groupLabel: "Transaction type" },
  { label: "Labor", value: "labor", groupLabel: "Transaction type" },
  {
    label: "Vendor credit",
    value: "vendor_credit",
    groupLabel: "Transaction type",
  },
] as const;

const BILLABLE_STATUSES = [
  {
    label: "Has been billed",
    value: "HasBeenBilled",
    groupLabel: "Billable status",
  },
  { label: "Billable", value: "Billable", groupLabel: "Billable status" },
  {
    label: "Not billable",
    value: "NotBillable",
    groupLabel: "Billable status",
  },
] as const;

const BUDGET_STATUSES = [
  {
    label: "Over-drawn lines",
    value: "over_drawn",
    groupLabel: "Budget status",
  },
  {
    label: "Over-budget lines",
    value: "over_budget",
    groupLabel: "Budget status",
  },
  { label: "Unpaid bills", value: "unpaid_bills", groupLabel: "Budget status" },
  {
    label: "Undrawn costs",
    value: "undrawn_costs",
    groupLabel: "Budget status",
  },
] as const;

const DRAW_STATUS = [
  { label: "Paid", value: "paid", groupLabel: "Draw status" },
  { label: "Unpaid", value: "unpaid", groupLabel: "Draw status" },
] as const;

export const BudgetsFilters = memo(({ children }: PropsWithChildren) => {
  const query = useBudgetsQuery();

  const settings = useClientSettings();

  const hasFilters = useBudgetsHasFilters();

  const rawFilters = useBudgetsRawFilters();

  const categoriesData = useJobCategories();

  const { setQuery, setFilters } = useBudgetsTableActions();

  const categoriesIsLoading = useJobCategoriesIsLoading();

  const { categoriesEnabled } = useJobSettings();

  const extraData = useDeepMemo(() => {
    let data: Option[] = [
      ...TRANSACTION_TYPES,
      ...BUDGET_STATUSES,
      ...DRAW_STATUS,
      ...BILLABLE_STATUSES,
    ];

    if (categoriesEnabled) {
      data = [
        ...categoriesData.map((category) => ({
          ...category,
          groupLabel: "Category",
        })),
        ...data,
      ];
    }

    return data;
  }, [categoriesData, categoriesEnabled]);

  const includeFilters = useDeepMemo(
    () =>
      [
        ...(settings.canItemsLinkToLinesDesktop ? ["costCodes"] : []),
        ...(window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED ? ["accounts"] : []),
        "vendors",
      ] as Exclude<TableFilterControlsProps["includeFilters"], undefined>,
    [
      settings.canAccountsLinkToLinesDesktop,
      settings.canItemsLinkToLinesDesktop,
    ]
  );

  const onClear = useEvent(() => {
    setQuery("");
    setFilters({});
  });

  return (
    <TableFilterControls
      filters={rawFilters}
      extraData={extraData}
      filterProps={{
        addonAfter: null,
        suffix: undefined,
        maxWidth: "240px",
        "data-testid": "budgets-filter",
      }}
      renderBefore={() => (
        <Flex maxWidth="240px" width="full">
          <TextField
            value={query}
            onChange={setQuery}
            addonAfter={<Icon name="search" />}
            placeholder="Search"
            data-testid="budgets-search"
            messageVariant="hidden"
          />
        </Flex>
      )}
      withFilterTags
      includeFilters={includeFilters}
      onFiltersChange={
        setFilters as TableFilterControlsProps["onFiltersChange"]
      }
      withDateFilter
      extraDataLoading={categoriesIsLoading}
    >
      <Flex width="full" gap="xl" align="center">
        {hasFilters && (
          <>
            <button id="budgets-filters-clear" onClick={onClear} hidden />
            <Button as="label" htmlFor="budgets-filters-clear">
              Clear filters
            </Button>
          </>
        )}
        <Flex width="full" gap="2xl" justify="flex-end">
          {children}
        </Flex>
      </Flex>
    </TableFilterControls>
  );
});

BudgetsFilters.displayName = "BudgetsFilters";
