import { type Option } from "@shared/types/objects";

import { type ValidationRule } from "./types";

export const valueIsNotFalsyOrEmptyString = (value: unknown) =>
  !!value && value !== "";

export const stringExistsWithMessage: <T>(m: string) => ValidationRule<T> =
  (reason: string) => (value) => ({
    ok: valueIsNotFalsyOrEmptyString(value),
    reason,
  });

export const numberMustBeLessThan: <T extends number | string | undefined>(
  message: string,
  length: number
) => ValidationRule<T> = (reason, length) => (value) => {
  const valueLength = value ? String(value).length : 0;

  return {
    ok: valueLength < length,
    reason,
  };
};

export const numberMustBePositive: <T extends number | string | undefined>(
  m: string
) => ValidationRule<T> = (reason: string) => (val) => {
  const num = typeof val === "string" ? parseFloat(val) : val;

  return {
    ok: ((num as number) || 0) > 0,
    reason,
  };
};

export const selectedOptionHasValue: (m: string) => ValidationRule<Option> =
  (reason) => (option) => ({
    ok: option?.value !== null && option?.value !== undefined,
    reason,
  });
