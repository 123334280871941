import React, {
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
} from "react";

import { suffixify } from "../../utils/suffixify";
import { VisuallyHidden } from "../visually-hidden";

import styles from "./table.module.css";

type TableCellActionBaseProps = PropsWithChildren<{ "data-testid"?: string }>;

type TableCellActionProps =
  | (TableCellActionBaseProps & { render?: never; onClick?: () => void })
  | (TableCellActionBaseProps & {
      render?: (props: HTMLAttributes<HTMLElement>) => ReactNode;
      onClick?: never;
    });

export const TableCellAction = ({
  render,
  children,
  "data-testid": testId,
  ...props
}: TableCellActionProps) => {
  const sharedProps = {
    className: styles["row-action"],
    "data-testid": suffixify(testId, "action-row"),
  };

  return (
    <div role="cell" data-cell="" data-action="">
      {render ? (
        render(sharedProps)
      ) : (
        <button type="button" {...sharedProps} {...props}>
          <VisuallyHidden>{children}</VisuallyHidden>
        </button>
      )}
    </div>
  );
};
