export const STRINGS = {
  HEADING_PAGE: "Create receipt",
  HEADING_DETAILS: "Receipt details",
  HEADING_LINE: "Items",
  LABEL_OTHER: "Other name",
  LABEL_VENDOR: "Vendor name",
  LABEL_ACCOUNT: "Payment account",
  LABEL_JOB: "Job",
  LABEL_PMT_ACCT: "Payment account",
  LABEL_LINKED_TRANSACTION: "Linked transaction",
  LABEL_CC_ACCT: "Cost code / Account",
  LABEL_DOC_NUMBER: "Ref #",
  LABEL_CARD_TRANSACTION: "Card transaction",
  ASSIGNEE_HINT: `This receipt will be added to the assignee’s\n to-do list for review and submission for approval`,
  HINT_CARD_TRANSACTION:
    "The transaction from your card history that matches this receipt",
  HELPER_CARD_TRANSACTION: "Click 'Save draft' to enable card matching",
  ERROR_VENDOR: "Vendor name should not be empty",
  ERROR_VENDOR_OTHER: "Vendor name / Other name should not be empty",
  ERROR_LINE_AMOUNT: "Amount should not be empty",
  ERROR_PMT_ACCT: "Payment Account should not be empty",
  ERROR_LINKED_TRANSACTION: "Linked transaction should not be empty",
  ERROR_CC_ACCT: "Items must be attributed",
  ERROR_JOB:
    "Job is required if a cost code is selected or the billable flag is set",
  PLACEHOLDER_PMT_ACCT: "Select an account",
  PLACEHOLDER_LINKED_TRANSACTION: "Select a transaction",
  BUTTON_PUBLISH: "Sync to QuickBooks",
  BUTTON_PENDING: "Pending sync to QuickBooks",
  BUTTON_UNPUBLISH: "Remove from QuickBooks",
  BUTTON_RESTORE: "Restore this receipt",
  BUTTON_REVIEW: "Send for review",
  BUTTON_ADVANCE_REVIEW: "Advance to review",
  BUTTON_EDIT: "Edit",
  BUTTON_CANCEL: "Cancel",
  BUTTON_SAVE: "Save",
  BUTTON_SAVE_DRAFT: "Save draft",
  TOOLTIP_PMT_ACCT:
    "The account used for this receipt. We’ll update the account\n balance in QuickBooks once the receipt is synced",
  DISABLED_EXPLANATION_LINKED_TRANSACTION:
    "This receipt is a placeholder based on your card history. Fill in the details and save it to make it permanent",
  SELECT_EXPLANATION_LINKED_TRANSACTION:
    "Select a transaction from your card history that matches with this receipt",
  ACTION_NOT_ALLOWED: "Sorry, you don't have permission to perform this action",
  BUTTON_TOO_MANY_LINES: "This receipt has too many lines",
  NO_TRANSACTIONS_EXPLANATION:
    "There are no unmatched transactions from this payment account or this payment account isn't linked to a card",
  SALES_TAX_INFO:
    "Sales tax will be allocated proportionally to each line item in QuickBooks",
};
