import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import { checkExpenseDuplicationRequestSchema } from "./request";
import {
  checkExpenseDuplicationResponseSchema,
  linkedInvoicesResponseSchema,
} from "./response";
import type {
  CheckExpenseDuplicationPayload,
  CheckExpenseDuplicationResponse,
  LinkedInvoicesPayload,
  LinkedInvoicesResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["CheckExpenseDuplication", "GetExpenseLinkedInvoices"],
});

/**
 * @todo move it to live on expenses/api folder
 */
export const expensesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    checkExpenseDuplication: builder.query<
      CheckExpenseDuplicationResponse,
      CheckExpenseDuplicationPayload
    >({
      query: (params) => ({
        url: `expenses/check_duplicated/`,
        params: {
          ...transformKeysToSnakeCase(
            handleRequest(params, checkExpenseDuplicationRequestSchema)
          ),
          total_amount: 0,
        },
        responseHandler: async (response) => {
          const data = await response.json();

          return handleResponse(
            transformKeysToCamelCase(data),
            checkExpenseDuplicationResponseSchema
          );
        },
      }),
    }),
    getExpenseLinkedInvoices: builder.query<
      LinkedInvoicesResponse,
      LinkedInvoicesPayload
    >({
      query: (args) => {
        const params = args.expenseIds.reduce(
          (acc, id, index) => acc + `${index > 0 ? "&" : ""}expense_ids=${id}`,
          ""
        );
        return {
          url: `expenses/linked_invoices/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              linkedInvoicesResponseSchema
            );
          },
        };
      },
      providesTags: ["GetExpenseLinkedInvoices"],
    }),
  }),
});

export const {
  useLazyCheckExpenseDuplicationQuery,
  useLazyGetExpenseLinkedInvoicesQuery,
} = expensesApi;
