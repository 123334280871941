/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React from "react";
import { useSelector } from "react-redux";
import { ComboBox as BaseComboBox, Text } from "@adaptive/design-system";
import { useCardTransactionsSimplified } from "@card-feed/hooks";
import { type Option } from "@shared/types/objects";
import { expenseSelectors } from "@store/expenses/selectors";
import { asOption } from "@utils/converters/utils";

import type { RootState } from "../../shared/store/types";
import {
  stringExistsWithMessage,
  useCustomValidationComponent,
} from "../../shared/utils/validator";
import { STRINGS } from "../stages/constants";

import type { SelectProps } from "./types";

const defaultValidator = stringExistsWithMessage(
  STRINGS.ERROR_LINKED_TRANSACTION
);

export const LinkedTransactionComboBox = ({
  validator = defaultValidator,
  selector,
  onChange,
  ...props
}: SelectProps) => {
  const paymentAccount = useSelector(expenseSelectors.paymentAccount);

  const { data, status } = useCardTransactionsSimplified({
    filters: {
      is_linked_to_expense: false,
      payment_account: paymentAccount?.id || -1,
    },
  });

  const { disabled } = props;

  const value: Option | undefined = useSelector<RootState>((s) => {
    //should be a referenceNode
    const value = selector?.(s);
    if (!value) {
      return;
    }

    return asOption<string>(value);
  });

  const ComboBox = useCustomValidationComponent(BaseComboBox, validator);

  return value?.value || (data && data.length > 0) ? (
    <ComboBox
      data={data}
      value={value || ""}
      onChange={(value, option) => {
        onChange?.(value, option);
      }}
      name="plaid-transaction"
      loading={status === "loading"}
      placeholder={STRINGS.PLACEHOLDER_LINKED_TRANSACTION}
      label={
        disabled
          ? STRINGS.DISABLED_EXPLANATION_LINKED_TRANSACTION
          : STRINGS.SELECT_EXPLANATION_LINKED_TRANSACTION
      }
      size="md"
      {...props}
    />
  ) : (
    paymentAccount && (
      <Text size="sm">
        {disabled
          ? STRINGS.DISABLED_EXPLANATION_LINKED_TRANSACTION
          : STRINGS.NO_TRANSACTIONS_EXPLANATION}
      </Text>
    )
  );
};
