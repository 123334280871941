import React, {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEvent,
  forwardRef,
  type InvalidEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ComboBox,
  CurrencyField,
  type DateField,
  type TextField,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { mergeRefs } from "@adaptive/design-system/utils";

import { type ValidationResult, type ValidationRule } from "./types";
import { type Validator } from "./validator";

export type CustomValidationField =
  | typeof TextField
  | typeof DateField
  | typeof CurrencyField
  | typeof ComboBox;

type CustomValidator<T extends CustomValidationField> =
  | ValidationRule<ComponentPropsWithoutRef<T>["value"]>
  | Validator<ComponentPropsWithoutRef<T>["value"]>;

type Ref = ComponentRef<CustomValidationField>;

export const withCustomValidation = <T extends CustomValidationField>(
  InputField: T,
  validator:
    | ValidationRule<ComponentPropsWithoutRef<T>["value"]>
    | Validator<ComponentPropsWithoutRef<T>["value"]>,
  alwaysValidate = false
) => {
  if (!validator) {
    return InputField;
  }

  const ValidatedInputField = forwardRef<Ref, ComponentPropsWithoutRef<T>>(
    ({ value, onChange, onInput, onBlur, required, ...props }, ref) => {
      const shouldValidate = alwaysValidate || required;

      const [validation, setValidation] = useState<ValidationResult>({
        ok: true,
        reason: "Initial",
      });

      const validatorRef = useRef<Ref>(null);

      const validate = useMemo(
        () =>
          typeof validator === "function" ? validator : validator.validate,
        []
      );

      useEffect(() => {
        if (!validatorRef.current) return;

        const result = validate(value);

        validatorRef.current.setCustomValidity(
          shouldValidate && !result.ok ? result.reason : ""
        );
      }, [validatorRef, shouldValidate, validation, value, validate]);

      const doValidate = useCallback(
        (val: typeof value) => {
          if (!shouldValidate) {
            return {
              ok: true,
              reason: "Not required",
            };
          }
          const validationResult = validate(val);
          setValidation(validationResult);
          return validationResult;
        },
        [shouldValidate, validate]
      );

      useEffect(() => {
        if (validation.reason !== "Initial") doValidate(value);
      }, [doValidate, validation.reason, value]);

      const disableNativeValidationHandler = useEvent<
        (e: InvalidEvent) => void
      >((e) => {
        e.preventDefault();
        doValidate(value);
      });

      const decoratedOnChange = useMemo(() => {
        return InputField === ComboBox
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (value: never, option) => {
              doValidate(option);
              onChange?.(value, option);
            }
          : onChange;
      }, [doValidate, onChange]);

      const decoratedOnInput = useMemo(() => {
        return InputField === CurrencyField
          ? (e: ChangeEvent<HTMLInputElement>) => {
              doValidate(e.target.value);
              onInput?.(e);
            }
          : onInput;
      }, [doValidate, onInput]);

      const decoratedOnBlur = useMemo(() => {
        return (e: FocusEvent<HTMLInputElement>) => {
          InputField === ComboBox
            ? doValidate(value)
            : doValidate(e.target.value);
          onBlur?.(e);
        };
      }, [doValidate, onBlur, value]);

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InputField
          errorMessage={!validation.ok ? validation.reason : undefined}
          ref={mergeRefs(ref, validatorRef)}
          value={value || ""}
          {...props}
          onInvalid={disableNativeValidationHandler}
          onInput={decoratedOnInput}
          onChange={decoratedOnChange}
          onBlur={decoratedOnBlur}
          required={required}
        />
      );
    }
  );

  ValidatedInputField.displayName = `Validating${
    "displayName" in InputField ? InputField.displayName : "Unknown"
  }`;

  return React.memo(ValidatedInputField);
};

export const useCustomValidationComponent = <T extends CustomValidationField>(
  FieldToValidate: T,
  validator: CustomValidator<T>
) => {
  const [managed] = useState({ validator });
  return useMemo(() => {
    return withCustomValidation(FieldToValidate, managed.validator);
  }, [FieldToValidate, managed.validator]);
};
