import { dotObject, formatCurrency } from "@adaptive/design-system/utils";
import { capitalize } from "@utils/capitalize";
import { snakeToCamelCase } from "@utils/schema/converters";

export const transformConflicts = (fields: string[]) =>
  fields.map((field) => {
    const accessor = snakeToCamelCase(field);
    const accessors = [accessor];

    if (accessor === "createdBy") {
      accessors.push("user");
    } else if (accessor === "totalAmount") {
      accessors.push("amount");
    }

    const parse = (data: unknown) => {
      let value = undefined;

      if (!data || typeof data !== "object") return value;

      for (const accessor of accessors) {
        value = dotObject.get(data, accessor);

        if (value) return value;
      }

      return value;
    };

    return {
      key: accessor,
      name: capitalize(field.replace(/_/g, " ")),
      parse,
      format: (data: unknown) => {
        const value = parse(data);

        if (value) {
          if (accessor === "totalAmount") {
            return formatCurrency(value, {
              currencySign: true,
              allowNegative: true,
            });
          } else if (accessor === "createdBy") {
            return value.fullName;
          } else if (accessor === "vendor") {
            return value.displayName ?? value.name;
          }
        }

        return value;
      },
    };
  });
