import React, { memo } from "react";
import { Navigate, useParams } from "react-router";

import type { Params } from "./types";

export const Redirect = memo(() => {
  const params = useParams<Params>();

  return <Navigate to={`/${params.type}/${params.id}`} replace />;
});

Redirect.displayName = "ConversationRedirect";
