import { formatMention } from "@adaptive/design-system/utils";

import { type LienWaiverVendor } from "../types";

type GetMessageToVendor = (
  vendor: LienWaiverVendor,
  message?: string
) => string;

export const getMessageToVendor: GetMessageToVendor = (vendor, message) => {
  let messageToVendor = formatMention({
    label: vendor.displayName || "Vendor",
    value: vendor.url,
  });

  if (message) {
    messageToVendor += `\r\n\r${message}`;
  }

  return messageToVendor;
};
