import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  useEffect,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";

import { DEFAULT_FALLBACK } from "./constants";
import styles from "./image.module.css";

type Status = "loading" | "failed" | "loaded";

type DefaultElement = "img";

type Props = ComponentPropsWithoutRef<"img"> & {
  fit?: "cover" | "contain" | "fill";
  fallback?: string;
};

export const Image = forwardRef<ComponentRef<DefaultElement>, Props>(
  ({ alt, src, fit = "fill", fallback, className, ...props }, ref) => {
    const [status, setStatus] = useState<Status>("loading");

    const onLoad = useEvent(() => setStatus("loaded"));

    const onError = useEvent(() => setStatus("failed"));

    const enhancedFit = status === "failed" && !fallback ? "cover" : fit;

    useEffect(() => setStatus(src ? "loading" : "failed"), [src]);

    return (
      <img
        ref={ref}
        src={status === "failed" ? fallback || DEFAULT_FALLBACK : src}
        alt={!alt ? "" : alt}
        role={!alt ? "presentation" : "img"}
        onLoad={status === "failed" ? undefined : onLoad}
        onError={status === "failed" ? undefined : onError}
        loading="lazy"
        decoding="async"
        className={cn(className, styles["image"], {
          [styles[`-${enhancedFit}`]]: enhancedFit,
        })}
        {...props}
      />
    );
  }
);

Image.displayName = "Image";
