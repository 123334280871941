import React, { memo, useCallback } from "react";
import { Flex, TagGroup } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { formatPercentage } from "@adaptive/design-system/utils";

import { PERCENTAGE_FORMAT } from "../constants";

import { useInvoiceFormMode } from "./invoice-form-context";
import {
  InvoiceCostTableMarkup,
  InvoiceEditInlineMarkupButton,
  type Line,
  type Markup,
} from ".";

type InvoiceCostTableMarkupsProps = Line;

export const InvoiceCostTableMarkups = memo(
  (props: InvoiceCostTableMarkupsProps) => {
    const mode = useInvoiceFormMode();

    const referenceMarkups = useDeepMemo(
      () => props.markups.filter((markup) => markup.isInlineMarkup === false),
      [props.markups]
    );

    const [markupOne, ...markups] = useDeepMemo(
      () => props.markups.filter((markup) => markup.isInlineMarkup),
      [props.markups]
    );

    const inlineMarkups = useDeepMemo(
      () => [markupOne, ...markups].filter((markup) => markup?.isInlineMarkup),
      [markupOne, markups]
    );

    const tagGroupRender = useCallback(({ item, percent }: Markup) => {
      const formattedPercentage = formatPercentage(percent, PERCENTAGE_FORMAT);

      return {
        hidden: `${
          item?.displayName ?? "Unknown item"
        } | ${formattedPercentage}`,
        visible: formattedPercentage,
      };
    }, []);

    return (
      <Flex gap="md" align="center">
        {referenceMarkups.length > 0 && (
          <Flex gap="sm">
            {referenceMarkups.map((markup) => (
              <InvoiceCostTableMarkup key={markup.id} {...markup} />
            ))}
          </Flex>
        )}
        {window.BUDGET_INLINE_MARKUP_ENABLED && (
          <>
            {markupOne && <InvoiceCostTableMarkup {...markupOne} />}
            {markups.length > 0 ? (
              <TagGroup
                data={markups}
                size="sm"
                limit={0}
                render={tagGroupRender}
              />
            ) : null}
            {mode === "edit" && inlineMarkups.length > 0 && (
              <InvoiceEditInlineMarkupButton
                {...props}
                markups={inlineMarkups}
              />
            )}
          </>
        )}
      </Flex>
    );
  }
);

InvoiceCostTableMarkups.displayName = "InvoiceCostTableMarkups";
