import dateFnsFormat from "date-fns-tz/format";

import {
  DATE_FORMAT,
  FORMATTED_DATE_LENGTH,
  FORMATTED_RANGE_DATE_LENGTH,
  RANGE_DATE_SEPARATOR,
} from "../../constants";
import { formatNumber } from "../format-number";

export const formatDate = (
  value: string | number | null | Date | { from?: Date; to?: Date },
  format: string = DATE_FORMAT
) => {
  if (value instanceof Date) {
    return dateFnsFormat(value, format);
  } else if (value instanceof Object) {
    const from = value.from ? dateFnsFormat(value.from, format) : undefined;
    const to = value.to ? dateFnsFormat(value.to, format) : undefined;

    return from && to ? `${from}${RANGE_DATE_SEPARATOR}${to}` : "";
  }

  if (value === null) return "";

  const formattedDate = formatNumber(value)
    .split("")
    .reduce((acc, char, index) => {
      const isSlash = [2, 4, 10, 12].includes(index);
      const isSeparator = index === 8;

      if (isSlash) return `${acc}/${char}`;

      if (isSeparator) return `${acc}${RANGE_DATE_SEPARATOR}${char}`;

      return `${acc}${char}`;
    }, "");

  return formattedDate.substring(
    0,
    formattedDate.includes(RANGE_DATE_SEPARATOR)
      ? FORMATTED_RANGE_DATE_LENGTH
      : FORMATTED_DATE_LENGTH
  );
};
