import { formatDate } from "@adaptive/design-system/utils";
import {
  pruneNullishOrEmpty,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import { z } from "zod";

import { documentType } from "../response/schema";

const addressPutSchema = z.object({
  line1: z.string().nullish().optional(),
  line2: z.string().nullish().optional(),
  city: z.string().nullish().optional(),
  state: z.string().nullish().optional(),
  postalCode: z.string().nullish().optional(),
});

const defaultCostCode = z.object({
  displayName: z.string().nullish().optional(),
  url: z.string().nullish().optional(),
});

export const baseVendorPayload = z.object({
  taxId: z.string().nullish().optional(),
  displayName: z.string(),
  phoneNumber: z.string().nullable(),
  email: z
    .string()
    .email()
    .nullish()
    .or(z.literal(""))
    .transform((val) => (!val ? "" : val)),
  address: addressPutSchema,
  defaultItem: defaultCostCode.nullish(),
  commonVendor: z.string().nullish().optional(),
  defaultAccount: defaultCostCode.nullish(),
  defaultPaymentDays: z.number().nullish().optional(),
  types: z.array(z.string()).nullish().optional(),
  averageTransaction: z.number().nullish().optional(),
  totalPayables: z.number().nullish().optional(),
  hasBankingAch: z.boolean().nullish().optional(),
  defaultItems: z.array(defaultCostCode).optional(),
  defaultAccounts: z.array(defaultCostCode).optional(),
});

export const vendorCreatePayload = z
  .preprocess(
    pruneNullishOrEmpty,
    baseVendorPayload.deepPartial().augment({
      displayName: z.string().min(1, { message: "Vendor name is required" }),
      realm: z.string().url({ message: "Realm is Required" }),
    })
  )
  .transform((vendor) => ({
    ...vendor,
    defaultItem: vendor.defaultItem?.url ?? null,
    defaultAccount: vendor.defaultAccount?.url ?? null,
    defaultItems: (vendor.defaultItems || []).map((costCode) => costCode.url),
    defaultAccounts: (vendor.defaultAccounts || []).map(
      (costCode) => costCode.url
    ),
  }));

export const vendorPutPayload = baseVendorPayload
  .deepPartial()
  .augment({ realm: z.string().url({ message: "Realm is Required" }) })
  .transform((vendor) => ({
    ...vendor,
    defaultItem: vendor.defaultItem?.url ?? null,
    defaultAccount: vendor.defaultAccount?.url ?? null,
    defaultItems: (vendor.defaultItems || []).map((costCode) => costCode.url),
    defaultAccounts: (vendor.defaultAccounts || []).map(
      (costCode) => costCode.url
    ),
  }));

// vendor ACH is on a different endpoint, so isolate separately
export const achSchema = z.object({
  archived: z.boolean(),
  routingNumber: z.string(),
  accountNumber: z.string(),
  url: z.string().url(),
});

export const vendorAchPutPayload = achSchema
  .partial()
  .augment({ vendor: z.string().url() })
  .transform(transformKeysToSnakeCase);

// vendor documents are on a different endpoint, so isolate separately
export const documentPutSchema = z
  .object({
    name: z.string().nullish().optional(),
    type: documentType,
    expirationDate: z
      .optional(
        z
          .date()
          .transform((val) => (val ? formatDate(val, "yyyy-MM-dd") : null))
      )
      .nullish(),
    document: z.instanceof(File).optional(),
    parent: z.string().url().optional(),
    realm: z.string().url(),
    url: z.string().url().optional(),
  })
  .transform(transformKeysToSnakeCase)
  .transform(pruneNullishOrEmpty);

export const vendorsGetPayloadSchema = z.string();
