import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import {
  arraySchema,
  currencySchema,
  dateSchema,
  idSchema,
  referenceSchema,
  userSchema,
} from "@utils/schema";
import { z } from "zod";

import { transformConflicts } from "../utils/transform-conflicts";

const parentSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema.nullable(),
  docNumber: z.string().nullish(),
  reviewStatus: z.string(),
  humanReadableType: z.string(),
});

const cardTransactionSchema = z.object({
  id: z.string(),
  url: z.string().url(),
  date: dateSchema,
  name: z.string().nullish(),
  amount: currencySchema.nullish(),
  pending: z.boolean(),
  expense: z.string().url().nullish(),
  isArchived: z.boolean(),
  description: z.string().nullish(),
  displayName: z.string().nullish(),
  paymentAccount: referenceSchema
    .extend({
      isCreditCard: z.boolean(),
      isBankAccount: z.boolean(),
      isPaymentAccount: z.boolean(),
    })
    .nullish()
    .catch(null),
  mask: z.string().nullish(),
  plaidAccountOwner: z.object({ accountOwner: z.string().nullish() }).nullish(),
  card: z
    .object({
      id: idSchema,
      url: z.string().url(),
      name: z.string(),
      fullName: z.string(),
      customer: referenceSchema.nullish(),
      item: referenceSchema.nullish(),
      account: referenceSchema.nullish(),
    })
    .nullish(),
  vendor: referenceSchema.nullish(),
  user: userSchema.nullish(),
  match: z
    .object({
      id: idSchema,
      url: z.string().url(),
      date: dateSchema.nullish(),
      parent: parentSchema.nullish(),
      docNumber: z.string().nullish(),
      reviewStatus: z.string().nullish(),
      humanReadableType: z.string(),
    })
    .nullish(),
});

const costSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema.nullable(),
  vendor: referenceSchema
    .nullish()
    .transform((value) => (value?.id ? value : null)),
  parent: parentSchema.nullish(),
  docNumber: z.string().nullish(),
  createdBy: userSchema.nullish(),
  totalAmount: currencySchema,
  reviewStatus: z.string(),
  humanReadableType: z.string(),
});

export const getCostsPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const getCostsResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(costSchema),
});

export const getCardTransactionsPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const getCardTransactionsResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(cardTransactionSchema),
});

/**
 * @todo refactor it as soon as we merge :cardTransaction/unmatch endpoint
 */
export const unmatchCardTransactionPayloadSchema = idSchema;

export const unmatchCardTransactionResponseSchema = z.void();

export const matchCardTransactionPayloadSchema = z
  .object({
    cost: z.string().url().or(idSchema),
    cardTransactionId: idSchema,
  })
  .and(z.record(z.string(), z.unknown()))
  .transform(({ cost, ...data }) => {
    let key = "";

    if (cost.includes("/bills/")) {
      key = "bill";
    } else if (cost.includes("/expenses/")) {
      key = "expense";
    } else if (cost.includes("/billpayments/")) {
      key = "bill_payment";
    }

    if (!key) throw new Error("Invalid cost URL");

    return { ...data, [key]: cost };
  });

export const matchCardTransactionErrorResponseSchema = z.object({
  conflicts: z.array(z.string()).transform(transformConflicts),
});

export const matchCardTransactionSuccessResponseSchema = z
  .object({
    bill: z.string().url().nullish(),
    vendor: z.string().url().nullish(),
    expense: z.string().url().nullish(),
    createdBy: dateSchema.nullish(),
    totalAmount: currencySchema.nullish(),
    billPayment: z.string().nullish(),
  })
  .transform((data) => {
    let id: string | number | undefined;
    let url: undefined | string;
    let humanReadableType: undefined | string;

    if (data.bill) {
      id = parseRefinementIdFromUrl(data.bill);
      url = data.bill;
      humanReadableType = "Bill";
    } else if (data.expense) {
      id = parseRefinementIdFromUrl(data.expense);
      url = data.expense;
      humanReadableType = "Receipt";
    }

    return { ...data, id, url, humanReadableType };
  });

export const matchCardTransactionResponse = z.union([
  matchCardTransactionErrorResponseSchema,
  matchCardTransactionSuccessResponseSchema,
]);

const updateCardTransactionFields = z.object({
  vendor: z.string().url().nullish(),
  isArchived: z.boolean().nullish(),
});

export const updateCardTransactionPayloadSchema =
  updateCardTransactionFields.extend({ cardTransactionId: idSchema });

export const updateCardTransactionResponseSchema = z.void();

export const batchUpdateCardTransactionsPayloadSchema =
  updateCardTransactionFields.extend({
    cardTransactionIds: z.array(idSchema),
  });

export const batchUpdateCardTransactionsResponseSchema = z.void();
