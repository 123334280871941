import { createContext, useContext } from "react";

import { BREAKPOINTS_KEYS } from "../../constants";

export type ContextType = {
  current: string;
  matches: string[];
};

export const Context = createContext<ContextType | null>({
  current: "largeDesktop",
  matches: BREAKPOINTS_KEYS,
});

export const useResponsiveProvider = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useResponsiveProvider must be used within a Provider");
  }

  return context;
};
