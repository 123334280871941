import React from "react";
import { Button, Flex, Tooltip } from "@adaptive/design-system";

import { MainHeader, MainTitle } from "../shared/components/main";
import { useVendorAction, useVendorInfo } from "../shared/store/vendors";

import { STRINGS } from "./utils/strings";

const NewVendorButton = () => {
  const { create } = useVendorAction();

  const { canManageNonPaymentInfo } = useVendorInfo();

  return (
    <Tooltip
      message={
        !canManageNonPaymentInfo ? "You don't have permission to do this" : ""
      }
      placement="left"
    >
      <Button disabled={!canManageNonPaymentInfo} onClick={() => create()}>
        {STRINGS.new}
      </Button>
    </Tooltip>
  );
};

export const Heading = () => {
  return (
    <MainHeader>
      <Flex align="center" gap="xl">
        <Flex direction="column" grow>
          <MainTitle>{STRINGS.title}</MainTitle>
        </Flex>
        <NewVendorButton />
      </Flex>
    </MainHeader>
  );
};
