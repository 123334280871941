import type { NavigateOptions, To } from "react-router";

/**
 * This is an workaround to be able to navigate outside the react tree
 */
export const navigate = (to: To, options: NavigateOptions) => {
  dispatchEvent(new CustomEvent("app-navigate", { detail: { to, options } }));
};

/**
 * This is an workaround to show the re authentication dialog outside the react tree
 */
export const reAuth = () => {
  window.DISABLE_TOASTS = true;
  dispatchEvent(new CustomEvent("re-auth"));
};
