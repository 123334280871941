import { formatNumber } from "../format-number";

type FormatCurrencyOptions = {
  currencySign?: boolean;
  maximumDecimalDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  allowNegative?: boolean;
  showZeroDecimals?: boolean;
};

const DEFAULT_OPTIONS: Required<FormatCurrencyOptions> = {
  currencySign: false,
  maximumDecimalDigits: 8,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  allowNegative: false,
  showZeroDecimals: true,
};

export const formatCurrency = (
  str: string | number,
  options?: FormatCurrencyOptions
) => {
  const enhancedOptions = {
    ...DEFAULT_OPTIONS,
    ...(options ? options : {}),
  };

  const enhancedStr = formatNumber(str, enhancedOptions.allowNegative);

  const [head, tail] = enhancedStr.split(".");

  const enhancedHead = head.slice(0, enhancedOptions.maximumDecimalDigits);

  const number = Number.parseFloat(`${enhancedHead}.${tail}`);

  if (Number.isNaN(number)) return "";

  const formatted = number.toLocaleString("en-US", {
    style: options?.currencySign ? "currency" : undefined,
    currency: "USD",
    minimumFractionDigits: enhancedOptions.minimumFractionDigits,
    maximumFractionDigits: enhancedOptions.maximumFractionDigits,
  });

  const [formattedHead, formattedTail = ""] = formatted.split(".");

  if (!enhancedStr.includes(".")) {
    if (
      !enhancedOptions.showZeroDecimals &&
      parseInt(head) === 0 &&
      parseInt(formattedTail) === 0
    ) {
      return formattedHead;
    }

    return formatted;
  }

  const neededZeros = Math.max(
    Math.min(enhancedOptions.maximumFractionDigits, tail.length) -
      formattedTail.length,
    enhancedOptions.minimumFractionDigits - formattedTail.length
  );

  const zeroPadTail = new Array((neededZeros > 0 ? neededZeros : 0) + 1).join(
    "0"
  );

  if (
    !enhancedOptions.showZeroDecimals &&
    parseInt(head) === 0 &&
    parseInt(formattedTail) === 0
  ) {
    return formattedHead;
  }

  return enhancedOptions.maximumFractionDigits === 0
    ? formattedHead
    : `${formattedHead}.${formattedTail}${zeroPadTail}`;
};
