import { omit } from "@adaptive/design-system/utils";
import { vendorApi } from "@api/vendors/old-api";
import type { Vendor } from "@api/vendors/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

import type { RootState } from "../types";
import { selectRealmUrl } from "../user/selectors-raw";

import { creationId as creationIdSelector, vendorSelector } from "./selectors";
import { type Stage, SubmitErrorCodes } from "./types";

type FetchByIdPayload = {
  id: string | number;
  initialStage: Stage;
};

export const fetchById = createAsyncThunk<
  Awaited<ReturnType<typeof vendorApi.get>> & { initialStage: Stage },
  FetchByIdPayload,
  { state: RootState }
>("vendors/fetchById", async ({ id, initialStage }, { rejectWithValue }) => {
  try {
    const data = await vendorApi.get({ id });

    if (!data?.id) throw new Error("Not found");
    return { ...data, initialStage };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export type CommitVendorPayload =
  | {
      commit: "updated" | "created";
      vendor: Vendor;
      creationId: string | null;
    }
  | undefined;

export const commitVendor = createAsyncThunk<
  CommitVendorPayload,
  void,
  { state: RootState }
>(
  "vendors/persistToDB",
  async (actionPayload, { getState, rejectWithValue }) => {
    const state = getState();

    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    let { info: vendor } = vendorSelector(state);
    const creationId = creationIdSelector(state);

    // the 'create' response doesn't contain the ID outside of the URL,
    // so use that to determine if we're creating or updating
    const persistToDatabase = !vendor.url ? vendorApi.create : vendorApi.put;

    /**
     * This is a workaround to remove invalid email from the
     * request. We need it since in the case when our users have
     * something different from a valid email on their QB side
     */
    if (!vendor.isStoredEmailValid) {
      vendor = omit(vendor, ["email"]) as any;
    }

    try {
      const response = await persistToDatabase({ ...vendor, realm });

      return {
        commit: vendor.url ? "updated" : "created",
        vendor: response as Vendor,
        creationId: creationId || null,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateVendor = createAsyncThunk<
  Vendor,
  Vendor,
  { state: RootState }
>("vendors/updateVendor", async (actionPayload, { rejectWithValue }) => {
  try {
    const response = await vendorApi.put(actionPayload);

    return response as Vendor;
  } catch (e) {
    return rejectWithValue(e);
  }
});
