import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
} from "react";
import { CompositeRow, MenuItem } from "@ariakit/react";
import cn from "clsx";

import { Icon } from "../icon";
import type { IconName } from "../icon/constants";

import { useDropdown } from "./dropdown-context";
import styles from "./dropdown.module.css";

type DefaultComponent = "div";

type Ref = ComponentRef<DefaultComponent>;

type OwnProps = ComponentPropsWithoutRef<DefaultComponent> & {
  icon?: IconName;
  color?: "neutral" | "error";
  hideOnClick?: boolean;
};

type StaticProps = Omit<OwnProps, "onClick">;

type InteractiveProps = OwnProps & {
  disabled?: boolean;
};

export type DropdownItemProps = InteractiveProps | StaticProps;

export const DropdownItem = forwardRef<Ref, DropdownItemProps>(
  (
    { icon, color = "neutral", hideOnClick, children, className, ...props },
    ref
  ) => {
    const { trigger, hideOnClick: dropdownHideOnClick } = useDropdown();

    const enhancedHideOnClick = hideOnClick ?? dropdownHideOnClick;

    const isInteractive = "onClick" in props;

    return (
      <CompositeRow role="row">
        <MenuItem
          ref={ref}
          role={undefined}
          render={<button />}
          className={cn(className, styles["dropdown-item"], {
            [styles[`-${color}`]]: color,
            [styles["-interactive"]]: isInteractive,
          })}
          hideOnClick={isInteractive ? enhancedHideOnClick : false}
          focusOnHover={trigger === "click"}
          blurOnHoverEnd={false}
          {...props}
        >
          {icon && <Icon name={icon} className={styles.icon} />}
          {children}
        </MenuItem>
      </CompositeRow>
    );
  }
);

DropdownItem.displayName = "DropdownItem";
