import { formatDate, parseDate } from "@adaptive/design-system/utils";
import { addDays, subDays } from "date-fns";

import type { LinkedCost } from "../components/types";

export const transformCostToFilters = (cost: Partial<LinkedCost>) => {
  const enhancedFilters: Record<string, unknown> = {};

  if ("paymentAccount" in cost && cost.paymentAccount?.url) {
    enhancedFilters[cost.paymentAccount.url] = {
      label: cost.paymentAccount.displayName,
      value: cost.paymentAccount.url,
      groupLabel: "Payment account",
    };
  }

  if (cost.vendor?.url) {
    enhancedFilters[cost.vendor.url] = {
      label: cost.vendor.displayName,
      value: cost.vendor.url,
      groupLabel: "Vendor",
    };
  }

  if ("date" in cost && cost.date) {
    enhancedFilters["date_after"] = formatDate(
      subDays(cost.date, 1),
      "yyyy-MM-dd"
    );
    enhancedFilters["date_before"] = formatDate(
      addDays(cost.date, 1),
      "yyyy-MM-dd"
    );
  }

  if ("transactionDate" in cost && cost.transactionDate) {
    const date = parseDate(cost.transactionDate, "yyyy-MM-dd") as Date | null;

    if (date) {
      enhancedFilters["date_after"] = formatDate(
        subDays(date, 1),
        "yyyy-MM-dd"
      );
      enhancedFilters["date_before"] = formatDate(
        addDays(date, 1),
        "yyyy-MM-dd"
      );
    }
  }

  return enhancedFilters;
};
