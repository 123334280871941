import React from "react";
import { DisclosureContent } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

const DEFAULT_COMPONENT = "div";

import styles from "./card.module.css";

export const CardContent = forwardRefAs<typeof DEFAULT_COMPONENT>(
  ({ as: Component = DEFAULT_COMPONENT, hidden, className, ...props }, ref) => {
    const content = (
      <Component
        ref={ref}
        className={cn(styles["card-content"], className)}
        {...props}
      />
    );

    return hidden ? <DisclosureContent render={content} /> : content;
  }
);
