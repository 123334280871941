import { createSelector } from "@reduxjs/toolkit";

import { type RootState } from "../types";

import { selectUserSlice } from "./selectors-raw";
import { type Client } from "./slice";

export const selectCurrentClient = createSelector(
  selectUserSlice,
  (user) => user.currentClient
);

export const selectOtherClients = createSelector(
  selectCurrentClient,
  ({ user }: RootState) => user.clients,
  (currentClient, allClients) =>
    allClients.filter((client) => client.id !== currentClient?.id)
);

export const selectClients = createSelector(
  selectUserSlice,
  (user) => user.clients
);

export const selectClientById = createSelector(
  (state: RootState) => selectUserSlice(state).clients,
  (clients: Client[]) => (id: string) =>
    clients.find((client) => client.id === id)
);

export const selectRealm = createSelector(
  selectCurrentClient,
  (client) => client?.realm
);

export const selectRealmId = createSelector(
  selectRealm,
  (realm) => realm?.id as unknown as number | null
);
