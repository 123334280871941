import { createContext, useContext } from "react";
import type { UIState } from "@store/ui/slice";
import { noop } from "@utils/noop";

export type DrawerName = keyof UIState["drawers"];

export type Stack<T> = {
  push: (name: T) => void;
  pop: () => void;
  isEmpty: () => boolean;
  isTop: (name: T) => boolean;
  items: T[];
};

export const DrawerStackContext = createContext<Stack<DrawerName>>({
  push: noop,
  pop: noop,
  isEmpty: () => true,
  isTop: () => false,
  items: [],
});

export const useDrawerStack = () => {
  return useContext(DrawerStackContext);
};
