import React, { type PropsWithChildren } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Flex,
  Text,
} from "@adaptive/design-system";
import { parseStringCopy } from "@adaptive/design-system/utils";
import { Layout } from "@components/layout";

import { STRINGS, VENDOR_REQUEST_TYPES } from "./constants";

export const RequestFormExpired = ({
  companyName,
  type,
}: {
  companyName: string;
  type?: string;
}) => {
  return type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Alert variant="warning">
      <AlertTitle>{STRINGS.EXPIRED_REQUEST_ALERT_TITLE}</AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          {parseStringCopy(STRINGS.EXPIRED_REQUEST_ALERT_CONTENT, {
            companyName: companyName,
          })}
        </Flex>
      </AlertContent>
    </Alert>
  ) : (
    <Layout
      title={STRINGS.EXPIRED_LINK_TITLE}
      subtitle={`To make changes to your information, contact ${companyName}`}
    />
  );
};

export const RequestError = ({ type }: { type?: string }) => {
  return type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" minHeight="150px">
      <Text weight="bold">{STRINGS.REQUEST_ERROR_TITLE}</Text>
    </Flex>
  ) : (
    <Layout title={STRINGS.REQUEST_ERROR_TITLE} />
  );
};

export const RequestFormSubmitted = ({
  message,
  type,
  children,
}: PropsWithChildren<{
  message?: string;
  type?: string;
}>) => {
  return type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" gap="xl">
      {children}
    </Flex>
  ) : (
    <Layout title={STRINGS.REQUEST_FORM_SUBMITTED_TITLE} subtitle={message}>
      {/* This is for "Add document" button */}
      {children}
    </Layout>
  );
};
