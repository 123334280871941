import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ComboBox as BaseComboBox,
  type ComboBoxActionAddon,
  type ComboBoxRef,
  Text,
} from "@adaptive/design-system";
import { isEqual } from "@adaptive/design-system/utils";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import { useOtherNamesSimplified } from "@shared/hooks/useOtherNamesSimplified";
import type { Option } from "@shared/types/objects";
import type { SelectProps } from "@src/expenses/forms";
import { STRINGS } from "@src/expenses/stages/constants";
import type { RootState } from "@store/types";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import { asOption } from "@utils/converters/utils";
import {
  selectedOptionHasValue,
  useCustomValidationComponent,
} from "@utils/validator";

export const SelectVendor = forwardRef<ComboBoxRef, SelectProps>(
  (
    // eslint-disable-next-line react/prop-types
    { selector, validator, allowOtherNames = false, onChange, ...props },
    ref
  ) => {
    const vendorData = useVendorsSimplified({ includeLabel: true });
    const otherNameData = useOtherNamesSimplified({
      includeLabel: true,
      enabled: allowOtherNames,
    });

    const onlyVendors = useMemo(
      () => otherNameData.data.length === 0 || !allowOtherNames,
      [otherNameData.data.length, allowOtherNames]
    );

    const enhancedValidator = useMemo(
      () =>
        (validator ??
          selectedOptionHasValue(
            onlyVendors ? STRINGS.ERROR_VENDOR : STRINGS.ERROR_VENDOR_OTHER
          )) as any,
      [onlyVendors, validator]
    );

    const ComboBox = useCustomValidationComponent(
      BaseComboBox,
      enhancedValidator
    );

    const status = useMemo(() => {
      if (vendorData.status !== "success" || onlyVendors) {
        return vendorData.status;
      }
      return otherNameData.status;
    }, [vendorData.status, otherNameData.status, onlyVendors]);

    const data = useMemo(() => {
      if (status !== "success") {
        return [];
      }
      if (onlyVendors) {
        // remove group label field if there are no other names
        return vendorData.data.map((vendor) => ({
          ...vendor,
          groupLabel: undefined,
        }));
      }
      return vendorData.data.concat(otherNameData.data);
    }, [vendorData.data, otherNameData.data, onlyVendors, status]);

    const { create, creationId } = useVendorAction();
    const { needsRefresh, drawerOpen, byCreationId, canManageNonPaymentInfo } =
      useVendorInfo();
    const vendorCreation = useSelector(byCreationId(creationId));

    const vendor = useSelector<RootState>((s) => {
      //should be a referenceNode
      const value = selector(s);
      if (!value) {
        return;
      }

      return asOption<string>(value);
    }, isEqual) as Option | undefined;

    const action = useMemo<ComboBoxActionAddon>(
      () => ({
        icon: "plus",
        mode: ({ group, query }) => {
          if (drawerOpen || !canManageNonPaymentInfo) return false;

          if (onlyVendors || group === "Vendor") return true;

          return !!query;
        },
        onClick: create,
        children: `Add new${!onlyVendors ? " vendor" : ""}`,
      }),
      [create, drawerOpen, canManageNonPaymentInfo, onlyVendors]
    );

    const renderVendorOtherLabel = useCallback(
      ({ group }: { group: string }) => {
        if (onlyVendors) return STRINGS.LABEL_VENDOR;

        const isOther = group === "Other";
        const isVendor = group === "Vendor";

        return (
          <Text as="span">
            <Text
              as={isVendor ? "strong" : "span"}
              weight={isVendor ? "bolder" : "regular"}
            >
              {STRINGS.LABEL_VENDOR}
            </Text>{" "}
            /{" "}
            <Text
              as={isOther ? "strong" : "span"}
              weight={isOther ? "bolder" : "regular"}
            >
              {STRINGS.LABEL_OTHER}
            </Text>
          </Text>
        );
      },
      [onlyVendors]
    );

    useEffect(() => {
      if (vendorCreation?.action !== "created") return;

      const newOption = data.find(
        (d) => d.value === vendorCreation.vendor?.url
      );
      if (!newOption || vendor?.value === newOption.value) return;

      onChange?.(newOption.value, newOption);
    }, [
      creationId,
      data,
      needsRefresh,
      onChange,
      vendorCreation,
      vendor?.value,
    ]);

    return (
      <ComboBox
        ref={ref}
        data={data}
        name="vendor"
        label={renderVendorOtherLabel as any}
        value={(vendor?.value ? vendor : "") as any}
        loading={status === "loading"}
        onChange={onChange as any}
        action={action}
        {...props}
      />
    );
  }
);

SelectVendor.displayName = "SelectVendor";
