import { curriedNoop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import type { InvoiceCostTableContextType } from ".";

export const InvoiceCostTableContext =
  createContext<InvoiceCostTableContextType>({
    hasMarkups: false,
    curriedOnChangeLine: curriedNoop,
    curriedOnDeleteMarkup: curriedNoop,
    curriedOnChangeMarkup: curriedNoop,
    curriedOnEditMarkup: curriedNoop,
    curriedOnDeleteTransaction: curriedNoop,
  });

export const useInvoiceCostTableActions = () => ({
  curriedOnChangeLine: useContextSelector(
    InvoiceCostTableContext,
    (context) => context.curriedOnChangeLine
  ),
  curriedOnDeleteMarkup: useContextSelector(
    InvoiceCostTableContext,
    (context) => context.curriedOnDeleteMarkup
  ),
  curriedOnChangeMarkup: useContextSelector(
    InvoiceCostTableContext,
    (context) => context.curriedOnChangeMarkup
  ),
  curriedOnEditMarkup: useContextSelector(
    InvoiceCostTableContext,
    (context) => context.curriedOnEditMarkup
  ),
  curriedOnDeleteTransaction: useContextSelector(
    InvoiceCostTableContext,
    (context) => context.curriedOnDeleteTransaction
  ),
});

export const useInvoiceCostTableHasMarkups = () =>
  useContextSelector(InvoiceCostTableContext, (context) => context.hasMarkups);
