import { toast } from "@adaptive/design-system";
import { updateStatus } from "@api/customers";
import {
  getNonFieldErrors,
  handleErrors,
  isNonFieldErrors,
} from "@api/handle-errors";
import type { Filter, Job } from "@api/jobs";
import { summarizeResults } from "@utils/all-settled";

export const checkFilters = (filters: Filter[]): boolean =>
  ["realm", "offset"].every(
    (index) =>
      filters.find(
        (filter) => filter.index === index && filter.value !== undefined
      ) !== undefined
  );

export const batchStatusUpdate = async (data: Job[], active: boolean) => {
  const requests = data.map(async ({ id, display_name }) => {
    try {
      await updateStatus(String(id), active);
    } catch (e) {
      if (isNonFieldErrors(e)) {
        const errors = getNonFieldErrors(e).map(
          (error) => `Job ${display_name}: ${error}`
        );

        throw { non_field_errors: errors };
      }

      throw e;
    }
  });

  const { success, errorResponses } = summarizeResults(
    await Promise.allSettled(requests)
  );

  if (errorResponses.length) {
    errorResponses.forEach((error) => handleErrors(error));
  }

  if (success) {
    toast.success(
      `${success} Job${success > 1 ? "s" : ""} ${
        active ? "activated" : "deactivated"
      }!`
    );
  }
};
