const OVERFLOW_REGEX = /(auto|scroll)/;

export const getScrollableParent = (
  el: HTMLElement | null,
  axe: "x" | "y" = "y"
): HTMLElement | null => {
  while (el && el.parentElement) {
    const { overflow, overflowY, overflowX } = getComputedStyle(
      el.parentElement
    );

    const isXScrollable = OVERFLOW_REGEX.test(overflowX);
    const isYScrollable = OVERFLOW_REGEX.test(overflowY);
    const isBothScrollable = OVERFLOW_REGEX.test(overflow);

    if (
      (isYScrollable && axe === "y") ||
      (isXScrollable && axe === "x") ||
      isBothScrollable
    ) {
      return el.parentElement;
    }

    el = el.parentElement;
  }

  return null;
};
