import { memo, useEffect } from "react";
import { logout } from "@api/auth";

export const Logout = memo(() => {
  useEffect(() => {
    logout().then(() => window.location.reload());
  }, []);

  return null;
});

Logout.displayName = "Logout";
