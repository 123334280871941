/**
 * @todo Enable eslint/tslint and fix all issues
 */
/* eslint-disable */
// @ts-nocheck
import type { BankingACH, Vendor } from "@src/shared/api/vendors/types";

import type { Form, VendorState } from "./slice";

export const getInitialAddress = () => ({
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
});

export const getInitialVendor = (name?: string): Vendor => ({
  address: getInitialAddress(),
  bankingAchs: null,
  defaultAttribution: null,
  displayName: name || "",
  email: "",
  id: null,
  url: null,
  hasExpiredDocuments: false,
  hasDocRequests: false,
  hasOverdueBills: false,
  paymentTerm: null,
  phoneNumber: "",
  taxId: "",
  defaultItem: null,
  commonVendor: null,
  defaultAccount: null,
  types: [],
  errors: [],
  isStoredEmailValid: true,
  averageTransaction: null,
  totalPayables: null,
  hasBankingAch: false,
  publishedToQuickBooks: false,
});

export const getInitialBanking = (): BankingACH => ({
  accountNumber: "",
  id: null,
  routingNumber: "",
});

export const getInitialDocuments = (): Document[] => [];

export const getInitialForm = (displayName?: string): Form => ({
  info: getInitialVendor(displayName),
  banking: getInitialBanking(),
  documents: getInitialDocuments(),
  isSubmitting: false,
  errors: null,
  initialStage: "info",
  initialSnapshot: {
    info: getInitialVendor(),
    banking: getInitialBanking(),
    documents: getInitialDocuments(),
  },
});

export const getInitialState = (): VendorState => ({
  form: getInitialForm(),
  transactions: {},
  needsRefresh: true,
  lastAction: null,
  lastCommit: null,
  fetchStatus: "idle",
});

export const initialState = getInitialState();
