import React from "react";
import {
  Button,
  Flex,
  Icon,
  type TableRenderColumn,
  Tag,
  TagGroup,
  Text,
  Tooltip,
  VisuallyHidden,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  suffixify,
} from "@adaptive/design-system/utils";
import type { Expense } from "@api/expenses/types";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import { LinkedCard } from "@components/linked-card/linked-card";
import { Thumbnail } from "@components/thumbnail";
import { isObjectEmpty } from "@utils/usefulFunctions";

import { STRINGS } from "../stages/constants";

import {
  AssigneeColumnRender,
  MatchingTransactionRender,
  StatusColumnRender,
} from "./columns-components";

interface AsyncRecord {
  fileSyncStatus?: "done" | string | null;
}

type GetColumnsProps = {
  testId?: string;
  status?: boolean;
  cardFeedEnabled?: boolean;
  cardHolderVisibility: TableRenderColumn<Expense>["visibility"];
};

const getLineAttributions = (e: Expense) => {
  // Aggregate response from `/expenses` doesn't include lines in each record
  // so we have to do individual aggregations on accounts and items
  const accounts = (e.accounts || [])
    .map((c) => c.displayName as string)
    .filter((val) => !!val);

  const costCodes = (e.items || [])
    .map((c) => c.displayName as string)
    .filter((val) => !!val);

  return { accounts, costCodes };
};

const getJobAttributions = (e: Expense): string[] => {
  // Aggregate response from `/expenses` doesn't include lines in each record
  // so get it from the collection exposed at the parent entity level
  return (e.customers || [])
    .map((job) => job.displayName)
    .filter((val) => !!val) as string[];
};

export const isPending = (record: AsyncRecord): boolean =>
  isObjectEmpty(record) ||
  ["deferred", "requested", "in-progress"].includes(
    record.fileSyncStatus || ""
  );

export const isError = (
  expense: Pick<Expense, "errorsNotIgnoredExist">
): boolean => !!expense.errorsNotIgnoredExist;

export const getColumns = ({
  testId,
  status,
  cardFeedEnabled,
  cardHolderVisibility,
}: GetColumnsProps) => {
  const columns: TableRenderColumn<Expense>[] = [
    {
      id: "attachment",
      name: "Receipt",
      visibility: {
        name: "Attachment",
        mode: "always-visible",
      },
      width: 80,
      render: (row) => (
        <>
          <Thumbnail
            alt={`Transaction${row.docNumber ? ` #${row.docNumber}` : ""}`}
            src={row.attachables?.[0]?.thumbnail || undefined}
          />
          {isPending(row) && (
            <VisuallyHidden data-testid={suffixify(testId, "processing")}>
              Processing...
            </VisuallyHidden>
          )}
          <VisuallyHidden data-testid={suffixify(testId, "number")}>
            {row.docNumber}
          </VisuallyHidden>
        </>
      ),
    },
    {
      id: "vendor__display_name",
      sortable: "asc",
      minWidth: 200,
      width: "fill",
      name: "Vendor name",
      visibility: "always-visible",
      render: (row) =>
        !isPending(row) && (
          <Text
            as="span"
            truncate={2}
            data-testid={suffixify(testId, "vendor")}
          >
            {!row.vendor &&
            row.isTransactionGeneratedDraft &&
            row.cardTransaction
              ? row.cardTransaction?.name
              : row.vendor?.displayName}
          </Text>
        ),
    },
    {
      id: "total_amount",
      sortable: true,
      name: "Amount",
      width: 150,
      textAlign: "right",
      render: (row) =>
        !isPending(row) && (
          <span data-testid={suffixify(testId, "amount")}>
            {formatCurrency(row.totalAmount || 0, {
              currencySign: true,
              allowNegative: true,
            })}
          </span>
        ),
    },
    {
      id: "date",
      sortable: true,
      width: 175,
      name: "Transaction date",
      render: (row) =>
        !isPending(row) ? (
          <span data-testid={suffixify(testId, "date")}>
            {formatDate(row.date as Date)}
          </span>
        ) : null,
    },
  ];

  columns.push(
    cardFeedEnabled
      ? {
          id: "card",
          width: 250,
          name: (
            <Flex gap="sm" align="center">
              <Text weight="bold">Matched transaction</Text>
              <Tooltip message="View all card transactions">
                <Button
                  as="a"
                  href="/card-feed"
                  size="sm"
                  color="neutral"
                  variant="text"
                  target="_blank"
                >
                  <Icon name="arrow-up-right-from-square" />
                </Button>
              </Tooltip>
            </Flex>
          ),
          visibility: { name: "Matched transaction", mode: "visible" },
          render: (row) => <MatchingTransactionRender {...row} />,
        }
      : {
          id: "card",
          width: 200,
          name: "Linked card",
          render: (row) =>
            !isPending(row) &&
            row.cardTransaction && (
              <Flex direction="column" key="card-transaction">
                {row.cardTransaction?.pending && (
                  <Text color="warning-200">Pending transaction</Text>
                )}
                <Flex gap="md" align="center">
                  <Tooltip
                    as={Icon}
                    name="credit-card"
                    message="This receipt is linked to a transaction from one of your cards"
                  />
                  <LinkedCard
                    mask={row.cardTransaction.mask}
                    name={row.cardTransaction.plaidAccountOwner?.accountOwner}
                  />
                </Flex>
              </Flex>
            ),
        }
  );

  columns.push(
    {
      id: "paymentAccount",
      name: "Payment account",
      minWidth: 160,
      render: (row) =>
        !isPending(row) && row.paymentAccount ? (
          <Flex gap="sm" direction="column" width="120px">
            <Tag data-testid="paid-with">{row.paymentAccount.displayName}</Tag>
          </Flex>
        ) : null,
    },
    {
      id: "job_cost_method_display_name",
      sortable: "asc",
      name: "Cost codes / Accounts",
      width: "fill",
      minWidth: 215,
      render: (row) => {
        const { accounts, costCodes } = getLineAttributions(row);

        return (
          !isPending(row) && (
            <CostCodeAccountInfo
              items={costCodes}
              accounts={accounts}
              data-testid={suffixify(testId, "cost-codes-accounts")}
            />
          )
        );
      },
    },
    {
      id: "first_customer",
      sortable: "asc",
      name: "Jobs",
      width: "fill",
      minWidth: 215,
      render: (row) => {
        const data = getJobAttributions(row);

        return !isPending(row) && data.length > 0 ? (
          <TagGroup
            data={data}
            limit="auto"
            data-testid={suffixify(testId, "jobs-customers")}
          />
        ) : null;
      },
    },
    {
      id: "submittedBy",
      name: (
        <Flex gap="sm">
          Assignee{" "}
          <Tooltip
            as={Icon}
            name="info-circle"
            size="sm"
            message={STRINGS.ASSIGNEE_HINT}
          />
        </Flex>
      ),
      minWidth: 250,
      render: (row) => !isPending(row) && <AssigneeColumnRender {...row} />,
    },
    {
      id: "card_user_name",
      name: "Card holder",
      sortable: "asc",
      visibility: cardHolderVisibility,
      minWidth: 150,
      render: (row) =>
        !isPending(row) && row.cardTransaction?.card?.user?.fullName
          ? row.cardTransaction?.card?.user?.fullName
          : null,
    }
  );

  if (status) {
    columns.push({
      id: "status",
      name: "Status",
      render: (row) => <StatusColumnRender {...row} />,
    });
  }

  columns.push(
    {
      id: "doc_number",
      name: "Ref #",
      sortable: true,
      visibility: "hidden",
      render: (row) => (row.docNumber ? `#${row.docNumber}` : null),
      width: 210,
    },
    {
      id: "true_created_at",
      name: "Created date",
      visibility: "hidden",
      width: 150,
      sortable: true,
      render: (row) =>
        !isPending(row) ? formatDate(row.createdAt as Date) : null,
    }
    /**
     * @todo add draw status and billable status columns
     */
  );

  return columns;
};
