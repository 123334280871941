import type { AccountBalanceV1 } from "@api/bank-accounts";
import { type ValidationResult, Validator } from "@utils/validator";

import {
  accountRules,
  clientRules,
  formRules,
  vendorRules,
} from "./rules-bill-payment";
import { type BillPayment } from "./types";

export const getFailures = (results: ValidationResult[]): string[] => {
  return results.filter((r) => !r.ok).map((r) => r.reason);
};

const billVendorValidator = new Validator<BillPayment>(vendorRules);

export const validatePayBillVendor: (billPayment: BillPayment) => {
  ok: boolean;
  failures: string[];
} = (billPayment) => {
  const result = billVendorValidator.validateAll(billPayment);

  const failures = [...getFailures(result)];

  return {
    ok: failures.length === 0,
    failures,
  };
};

const billFormValidator = new Validator<BillPayment>(formRules);

export const validatePayBillForm: (billPayment: BillPayment) => {
  ok: boolean;
  failures: string[];
} = (billPayment) => {
  const result = billFormValidator.validateAll(billPayment);

  const failures = [...getFailures(result)];

  return {
    ok: failures.length === 0,
    failures,
  };
};

const billClientValidator = new Validator(clientRules);

export const validatePayBillClient: (accountBalances: []) => {
  ok: boolean;
  failures: string[];
} = (accountBalances) => {
  const result = billClientValidator.validate(accountBalances);

  const failures = [...getFailures([result])];

  return {
    ok: failures.length === 0,
    failures,
  };
};

const accountBalanceValidator = new Validator<AccountBalanceV1>(accountRules);

export const validatePayBillAccountBalance: (
  selectedAccountBalance: AccountBalanceV1
) => {
  ok: boolean;
  failures: string[];
} = (selectedAccountBalance) => {
  const result = accountBalanceValidator.validate(selectedAccountBalance);
  const failures = [...getFailures([result])];

  return {
    ok: failures.length === 0,
    failures,
  };
};
