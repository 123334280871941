import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  PercentageField,
  Text,
  toast,
} from "@adaptive/design-system";
import { type useDialog, useForm } from "@adaptive/design-system/hooks";
import { PERCENTAGE_FORMAT } from "@src/jobs/constants";
import { handleErrors } from "@src/shared/api/handle-errors";
import { divide } from "@utils/divide";
import { z } from "zod";

import {
  useInvoiceActions,
  useInvoiceLines,
  useInvoiceLinesSelected,
} from "./invoice-context";

type InvoiceCalculateMarkupDialogProps = {
  dialog: ReturnType<typeof useDialog>;
};

const INITIAL_VALUES = {
  percentMarkup: 0,
};

const schema = z.object({
  percentMarkup: z.number().max(2000),
});

export const InvoiceCalculateMarkupDialog = ({
  dialog,
}: InvoiceCalculateMarkupDialogProps) => {
  const { updateInvoiceLines } = useInvoiceActions();

  const invoiceLinesSelected = useInvoiceLinesSelected();

  // TODO: We use the transaction line data to update the local state
  // for the optimistic render, but we are omitting the url
  // because the url is not necessary for this update. We should
  // remove unnecessary data from the optimistic update
  // payload. Ticket: BOB-6086
  const invoiceLines = useInvoiceLines({ withTransactionLineUrl: false });

  const form = useForm({
    schema,
    async onSubmit(values) {
      try {
        const percentMultiplier = !values.percentMarkup
          ? 1
          : divide(values.percentMarkup, 100) + 1;

        const nextLines = invoiceLines.map((invoiceLine) => {
          const lineSelected = invoiceLinesSelected.some(
            (line) => line.id === invoiceLine.id
          );

          if (!lineSelected || !invoiceLine.transactionLine) return invoiceLine;

          return {
            ...invoiceLine,
            amount: invoiceLine.transactionLine.totalAmount * percentMultiplier,
          };
        });

        updateInvoiceLines(nextLines);
        dialog.hide();
        toast.success(`Inline markup added to draw`);
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  return (
    <Dialog
      show={dialog.isVisible}
      variant="dialog"
      onClose={dialog.hide}
      size="sm"
    >
      <DialogHeader>Calculate price markup</DialogHeader>
      <DialogContent>
        <Text>Note: This is a one time calculation.</Text>
        <Flex as="form" {...form.props} padding={["2xl", "none"]}>
          <PercentageField
            {...form.register({ name: "percentMarkup", type: "percentage" })}
            width="100px"
            messageVariant="relative"
            placeholder="0"
            min={PERCENTAGE_FORMAT.min}
            max={PERCENTAGE_FORMAT.max}
            autoFocus
            allowNegative
            minimumFractionDigits={PERCENTAGE_FORMAT.minimumFractionDigits}
            maximumFractionDigits={PERCENTAGE_FORMAT.maximumFractionDigits}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block variant="text" color="neutral" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button block variant="solid" type="submit" form={form.id}>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
