import React, { memo } from "react";

import type { EnhancedOption } from "./types";
import { getMatch } from "./utils";

export const ComboBoxHighlight = memo((option: EnhancedOption) => {
  const match = getMatch(option);

  if (!match) return <span>{option.label}</span>;

  let endIndex = 0;
  let startIndex = 0;

  const sections = [];

  match.indices.forEach(([matchStart, matchStop]) => {
    if (matchStart > endIndex) {
      sections.push({
        as: "span",
        children: option.label.slice(startIndex, matchStart),
      });
    }

    startIndex = matchStart;
    endIndex = matchStop + 1;

    sections.push({
      as: "strong",
      children: option.label.slice(startIndex, endIndex),
    });

    startIndex = endIndex;
  });

  if (endIndex < option.label.length) {
    sections.push({
      as: "span",
      children: option.label.slice(endIndex),
    });
  }

  return (
    <span>
      {sections.map(({ as: Component, ...props }, index) => (
        <Component key={index} {...props} />
      ))}
    </span>
  );
});

ComboBoxHighlight.displayName = "ComboBoxHighlight";
