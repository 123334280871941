import React from "react";
import { TagGroup } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";

type CostCodeAccountInfoProps = {
  items?: string[];
  accounts?: string[];
  "data-testid"?: string;
};

export const CostCodeAccountInfo = ({
  items = [],
  accounts = [],
  "data-testid": testId,
}: CostCodeAccountInfoProps) => {
  const hasCostCode = items.length > 0;
  const hasAccounts = accounts.length > 0;

  const data = useDeepMemo(
    () => [
      ...items.map((name) => `${name} (Cost code)`),
      ...accounts.map((name) => `${name} (Account)`),
    ],
    [items, accounts]
  );

  if (!hasCostCode && !hasAccounts) return null;

  return <TagGroup data={data} limit="auto" data-testid={testId} />;
};
