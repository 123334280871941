import { useClientInfo } from "@store/user";

import { useGetUsersSimplifiedQuery } from "../store/api-simplified";

import { useAsSimplifiedData } from "./useSimplifiedData";

type UsersSimplifiedProps = {
  filters?: {
    is_staff?: boolean;
  };
  enabled?: boolean;
  includeLabel?: boolean | string;
};

export const useUsersSimplified = ({
  filters,
  enabled = true,
  includeLabel = false,
}: UsersSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const params = { ...filters, realm: realmId as number };

  const skip = !realmId || !enabled;

  const query = useGetUsersSimplifiedQuery(params, { skip });

  const { status, data, ...results } = useAsSimplifiedData(query, {
    groupLabel:
      typeof includeLabel === "string"
        ? includeLabel
        : includeLabel
          ? "User"
          : undefined,
  });

  return {
    ...results,
    ...query,
    data: data || [],
    status: skip ? "success" : status,
  };
};
