import { useCallback } from "react";
import { budgetLinesApi } from "@api/budgets";
import type {
  JobDetailState,
  UpdateBuilderBudgetLockedStatusPayload,
} from "@api/jobs";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import { updateBudgedLockedStatus as updateBudgedLockedStatusAction } from "./thunks";

export const useBudgetActions = () => {
  const dispatch = useAppDispatch();
  const { job } = useJobInfo();

  const updateBudgedLockedStatus = useCallback(
    async (payload: UpdateBuilderBudgetLockedStatusPayload) => {
      await dispatch(updateBudgedLockedStatusAction(payload)).unwrap();
    },
    [dispatch]
  );

  const copyBuilderBudgetToRevisedBudget = useCallback(() => {
    dispatch(
      budgetLinesApi.util.updateQueryData(
        "getBudgetLines",
        { customerId: job.id },
        (draft) => {
          for (const budgetLine of draft) {
            budgetLine.builderRevisedAmount = budgetLine.builderAmount;
          }
        }
      )
    );
  }, [dispatch, job.id]);

  return { updateBudgedLockedStatus, copyBuilderBudgetToRevisedBudget };
};

export const useJobInfo = () => {
  const { status, job } = useAppSelector(
    (state) => state.jobs.job as JobDetailState
  );

  return { status, job };
};
