import React, { type ReactNode } from "react";
import { Provider } from "react-redux";
import { createStore, store } from "@shared/store";

type ReduxProviderProps = { children: ReactNode };

export const ReduxProvider = ({ children }: ReduxProviderProps) => (
  <Provider store={store}>{children}</Provider>
);

type TestReduxProviderProps = ReduxProviderProps & {
  preloadedState?: Record<string, unknown>;
};

const DEFAULT_REALM = {
  id: 1,
  url: "http://localhost/api/realms/1/",
  company_name: "Adaptive Realm",
  last_synced_at: new Date().toISOString(),
};

const DEFAULT_CURRENT_CLIENT = {
  id: "ea682a13-c5d0-49c9-a0e6-afb0b5a54a37",
  name: "Adaptive Client",
  email: "admin@adaptive.build",
  realm: DEFAULT_REALM,
  realms: [DEFAULT_REALM],
  settings: {
    can_items_link_to_lines_desktop: true,
    can_accounts_link_to_lines_desktop: true,
    is_sales_tax_account_setting_enabled: true,
  },
};

const DEFAULT_PRELOADED_STATE = {
  user: {
    clients: [DEFAULT_CURRENT_CLIENT],
    currentClient: DEFAULT_CURRENT_CLIENT,
  },
  realm: { realm: DEFAULT_REALM },
};

export const TestReduxProvider = ({
  children,
  preloadedState,
}: TestReduxProviderProps) => (
  <Provider
    store={createStore({
      preloadedState: { ...DEFAULT_PRELOADED_STATE, ...preloadedState },
    })}
  >
    {children}
  </Provider>
);
