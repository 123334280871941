/**
 * Wrapper around native `setSelectionRange` applying a fix to prevent
 * unexpected focus/blur on Safari. You could check this bug on
 * this link: https://bugs.webkit.org/show_bug.cgi?id=224425
 */
export const setSelectionRange = (
  el: HTMLInputElement | null,
  start: number | null,
  end: number | null,
  direction?: "forward" | "backward" | "none"
) => {
  if (!el || document.activeElement !== el) return;

  requestAnimationFrame(() => el.setSelectionRange(start, end, direction));
};
