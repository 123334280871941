import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Text } from "../text";
import { Tooltip } from "../tooltip";

import type { SuggestionListProps, SuggestionListRef } from "./types";
import styles from "./textarea.module.css";

export const SuggestionList = forwardRef<
  SuggestionListRef,
  SuggestionListProps
>(({ items, command, render, onAdd }, ref) => {
  const [isVisible, setIsVisible] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const move = useEvent((index: number, scroll = true) => {
    setSelectedIndex(index);

    if (scroll) {
      document
        .querySelector(`.${styles["suggestion"]}:nth-child(${index + 1})`)
        ?.scrollIntoView({
          block: "nearest",
        });
    }

    return true;
  });

  const select = useEvent((index?: number) => {
    const item = items[index ?? selectedIndex];

    if (!item) return false;

    const onAddResult = onAdd?.(item);

    const prevent = onAddResult !== undefined ? !onAddResult : !!item?.disabled;

    if (!prevent) command({ id: item.value, label: item.label });

    return true;
  });

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  useImperativeHandle(ref, () => ({
    hide: () => {
      setIsVisible(false);

      return true;
    },
    onKeyDown: (e) => {
      if (!isVisible || !items.length) return false;

      if (e.key === "Enter") return select();

      if (e.key === "ArrowUp") {
        return move((selectedIndex + items.length - 1) % items.length);
      }

      if (e.key === "ArrowDown") {
        return move((selectedIndex + 1) % items.length);
      }

      return false;
    },
  }));

  if (!items.length || !isVisible) return null;

  return (
    <div className={styles["suggestion-list"]} role="listbox" tabIndex={-1}>
      {items.map((item, index) => {
        const suggestion = (
          <Flex
            shrink={false}
            direction="column"
            className={cn(styles["suggestion-item"], {
              [styles["-disabled"]]: item.disabled,
            })}
          >
            <Flex gap="sm" align="center">
              {item.label}
              {item.disabled && typeof item.disabled !== "boolean" ? (
                <Tooltip
                  as={Icon}
                  size="sm"
                  name="exclamation-circle"
                  message={item.disabled}
                />
              ) : item.hintMessage ? (
                <Tooltip
                  as={Icon}
                  size="sm"
                  name="info-circle"
                  message={item.hintMessage}
                />
              ) : null}
            </Flex>
            {item.groupLabel && (
              <Text color="neutral-700" size="xs">
                {item.groupLabel}
              </Text>
            )}
          </Flex>
        );

        return (
          <button
            key={index}
            role="option"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              select(index);
            }}
            tabIndex={-1}
            className={styles["suggestion"]}
            onMouseDown={(e) => e.preventDefault()}
            onMouseEnter={
              item.disabled && !onAdd ? undefined : () => move(index, false)
            }
            aria-selected={index === selectedIndex}
            data-interactive={item.disabled && !onAdd ? "false" : "true"}
          >
            {render ? render({ ...item, suggestion }) : suggestion}
          </button>
        );
      })}
    </div>
  );
});

SuggestionList.displayName = "SuggestionList";
