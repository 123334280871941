import { createSelector } from "@reduxjs/toolkit";

import { lienWaiverTemplatesApi } from "../api/api";

const selectLienWaiverTemplates =
  lienWaiverTemplatesApi.endpoints.getLienWaiverTemplates.select();
export const lienWaiverTemplatesSelector = createSelector(
  selectLienWaiverTemplates,
  (lienWaiverTemplates) => lienWaiverTemplates?.data || []
);

const selectSimplifiedLienWaiverTemplates =
  lienWaiverTemplatesApi.endpoints.getLienWaiverTemplates.select({
    withStatus: true,
  });
export const simplifiedLienWaiverTemplatesSelector = createSelector(
  selectSimplifiedLienWaiverTemplates,
  (lienWaiverTemplates) => lienWaiverTemplates?.data || []
);

const selectDynamicvariables =
  lienWaiverTemplatesApi.endpoints.getDynamicVariables.select();
export const dynamicVariablesSelector = createSelector(
  selectDynamicvariables,
  (dynamicVariables) => dynamicVariables?.data?.results || []
);

const selectSignatureFields =
  lienWaiverTemplatesApi.endpoints.getSignatureFields.select();
export const signatureFieldsSelector = createSelector(
  selectSignatureFields,
  (signatureFields) => signatureFields?.data?.results || []
);

const selectLienWaiverTypesList =
  lienWaiverTemplatesApi.endpoints.getLienWaiverTypesList.select();
export const lienWaiverTemplateTypesSelector = createSelector(
  selectLienWaiverTypesList,
  (lienWaiverTemplateTypes) => lienWaiverTemplateTypes?.data?.results || []
);
