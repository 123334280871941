import { arraySchema, currencySchema, idSchema } from "@utils/schema";
import { z } from "zod";

const userSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  email: z.string().email(),
  fullName: z.string(),
});

const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
});

export const plaidAccountOwnerSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  user: userSchema.nullish(),
  accountOwner: z.string().nullish(),
  accountBalance: currencySchema,
  paymentAccount: accountSchema.nullish(),
});

export const accountBalanceSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  name: z.string(),
  type: z.string(),
  mask: z.string().nullish(),
  users: arraySchema(userSchema),
  balance: currencySchema,
  bankName: z.string(),
  currency: currencySchema,
  paymentAccount: accountSchema.nullish(),
  plaidAccountId: z.string(),
  verificationStatus: z.string().nullish(),
  plaidAccountOwners: arraySchema(plaidAccountOwnerSchema),
  checkbookAccountIsActive: z.boolean().nullish().default(false),
});

export const bankAccountSchema = z.object({
  id: idSchema,
  type: z.string().nullish(),
  syncedAt: z.string(),
  bankName: z.string(),
  accountBalances: arraySchema(accountBalanceSchema),
  plaidLoginRequired: z.boolean(),
});

export const getBankAccountsResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(bankAccountSchema),
  previous: z.string().url().nullish(),
});
