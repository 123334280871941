import {
  arraySchema,
  commentSchema,
  dateSchema,
  idSchema,
} from "@shared/utils/schema";
import { z } from "zod";

export const notificationTypeSchema = z.enum([
  "COMMENT_MADE",
  "COMMENT_MENTIONED",
]);

export const notificationSchema = z.object({
  id: idSchema,
  read: z.boolean(),
  sender: z.string().url(),
  createdAt: dateSchema,
  notificationType: notificationTypeSchema,
  referencedObject: commentSchema.extend({
    parent: z.object({
      id: idSchema,
      docNumber: z.string().nullish(),
      humanReadableType: z.string(),
    }),
  }),
});

export const getNotificationsResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(notificationSchema),
});

export const getNotificationsPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const updateNotificationResponseSchema = notificationSchema;

export const updateNotificationPayloadSchema = z.object({
  id: idSchema,
  read: z.boolean(),
});

export const updateNotificationsResponseSchema = getNotificationsResponseSchema;

export const updateNotificationsPayloadSchema = z.object({
  read: z.boolean(),
});
