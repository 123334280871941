import { useCallback, useMemo } from "react";
import type { BudgetLineItems } from "@api/jobs";
import {
  type AccountFilters,
  useAccountsSimplified,
} from "@hooks/use-accounts-simplified";
import {
  useCostCodesSimplified,
  type UseCostCodesSimplifiedProps,
} from "@hooks/use-cost-codes-simplified";
import type { MarkupResponse } from "@src/shared/api/budgets";

type Payload = Partial<{
  type: string;
  item: { display_name: string | null; url: string | null };
  account: { display_name: string | null; url: string | null };
  category: string | null;
  billable_status: string;
}>;

export type UseCostCodesAccountsSimplifiedProps = {
  accountFilters?: AccountFilters & { enabled?: boolean };
  costCodeFilters?: UseCostCodesSimplifiedProps;
  disableBudgetLines?: Pick<
    BudgetLineItems,
    "jobCostMethod" | "builderAmount" | "changeAmount" | "builderRevisedAmount"
  >[];
  disableMarkups?: Pick<MarkupResponse, "jobCostMethod">[];
};

export const useCostCodesAccountsSimplified = ({
  accountFilters,
  costCodeFilters,
}: UseCostCodesAccountsSimplifiedProps = {}) => {
  const accounts = useAccountsSimplified({
    enabled:
      accountFilters?.enabled === undefined ? true : accountFilters.enabled,
    filters: accountFilters || {},
    includeLabel: true,
  });

  const costCodes = useCostCodesSimplified({
    includeLabel: true,
    ...(costCodeFilters || {}),
  });

  const data = useMemo(
    () => [...costCodes.data, ...accounts.data],
    [accounts.data, costCodes.data]
  );

  const status = useMemo(() => {
    const statuses = [accounts.status, costCodes.status];

    if (statuses.some((status) => status === "failed")) return "failed";

    if (statuses.some((status) => status === "loading")) return "loading";

    return "success";
  }, [accounts.status, costCodes.status]);

  const transform = useCallback(
    (value: string) => {
      const option = data.find((item) => item.value === value);

      const payload: Payload = {
        item: { display_name: null, url: null },
        account: { display_name: null, url: null },
        category: null,
      };

      if (option) {
        payload.category = option.groupLabel;

        if (payload.category === "Account") {
          payload.type = "AccountBasedExpenseLineDetail";
          payload.account = { display_name: option.label, url: option.value };
        } else {
          payload.type = "ItemBasedExpenseLineDetail";
          payload.item = { display_name: option.label, url: option.value };
        }
      }

      return payload;
    },
    [data]
  );

  return { data, status, transform } as const;
};
