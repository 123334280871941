import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosRequestTransformer,
  type AxiosResponseTransformer,
  type InternalAxiosRequestConfig,
} from "axios";
import cookie from "cookie";

import { reAuth } from "../custom-events";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "../schema/converters";

const interceptor = (config: AxiosRequestConfig) => {
  const { csrftoken } = cookie.parse(document.cookie);
  const selectedRealmId = localStorage.getItem("selectedRealmId");
  if (config.headers) {
    if (csrftoken) config.headers["X-CSRFTOKEN"] = csrftoken;
    if (selectedRealmId) config.headers["Realm"] = selectedRealmId;
  }
  return config;
};

export const invalidate5xx = (config: AxiosRequestConfig) => {
  config.validateStatus = (status) => status < 500;
  return config;
};

export const transformPayloads = (config: AxiosRequestConfig) => {
  config.transformRequest = [
    transformKeysToSnakeCase,
    ...((axios.defaults.transformRequest || []) as AxiosRequestTransformer[]),
  ];

  config.transformResponse = [
    ...((axios.defaults.transformResponse || []) as AxiosResponseTransformer[]),
    transformKeysToCamelCase,
  ];

  return config;
};

export const getApi = (
  configs: ((config: AxiosRequestConfig) => AxiosRequestConfig)[] = []
): AxiosInstance => {
  const api = axios.create();
  const requestConfig = (config: AxiosRequestConfig) => {
    [...configs, interceptor].forEach((func) => {
      config = func(config);
    });
    return config as InternalAxiosRequestConfig;
  };
  api.interceptors.request.use(requestConfig);
  api.interceptors.response.use(
    (resp) => resp,
    async (error) => {
      if (
        error?.response?.status === 401 ||
        (error?.response?.status === 403 &&
          (error?.response?.data as { detail?: string })?.detail ===
            "Authentication credentials were not provided.")
      ) {
        reAuth();
      }

      return Promise.reject(error);
    }
  );
  return api;
};

export const api = getApi();
