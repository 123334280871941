import type { DocumentType } from "@api/vendors/types";
import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";
import { arrayToStringWithSeparators } from "@utils/usefulFunctions";

export const formatDocumentTypes = (documentTypes: DocumentType[]) => {
  const result = documentTypes
    .map(
      (type) => DOCUMENT_TYPE_OPTIONS.find((obj) => obj.value === type)?.label
    )
    .filter((el) => el !== undefined);
  return arrayToStringWithSeparators(result);
};
