const has = Object.prototype.hasOwnProperty;

function find(iter: Set<any> | Map<any, any>, tar: any): any | undefined {
  for (const key of iter.keys()) {
    if (isEqual(key, tar)) return key;
  }
  return undefined;
}

export function isEqual(foo: any, bar: any): boolean {
  const stack: { foo: any; bar: any }[] = [{ foo, bar }];
  let ctor: any, tmp: any;

  while (stack.length) {
    ({ foo, bar } = stack.pop() as { foo: any; bar: any });

    if (foo === bar) continue;

    if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
      if (ctor === Date) {
        if (foo.getTime() !== bar.getTime()) return false;
        continue;
      }

      if (ctor === RegExp) {
        if (foo.toString() !== bar.toString()) return false;
        continue;
      }

      if (ctor === Array) {
        if (foo.length !== bar.length) return false;
        for (let i = 0; i < foo.length; i++) {
          stack.push({ foo: foo[i], bar: bar[i] });
        }
        continue;
      }

      if (ctor === Set) {
        if (foo.size !== bar.size) return false;
        for (const item of foo) {
          if (typeof item === "object") {
            tmp = find(bar as Set<any>, item);
            if (!tmp) return false;
            stack.push({ foo: item, bar: tmp });
          } else if (!bar.has(item)) {
            return false;
          }
        }
        continue;
      }

      if (ctor === Map) {
        if (foo.size !== bar.size) return false;
        for (const [key, value] of foo) {
          if (typeof key === "object") {
            tmp = find(bar as Map<any, any>, key);
            if (!tmp || !isEqual(value, bar.get(tmp))) return false;
            stack.push({ foo: value, bar: bar.get(tmp) });
          } else if (!isEqual(value, bar.get(key))) {
            return false;
          }
        }
        continue;
      }

      if (ctor === ArrayBuffer) {
        foo = new Uint8Array(foo);
        bar = new Uint8Array(bar);
        if (foo.byteLength !== bar.byteLength) return false;
        for (let i = 0; i < foo.byteLength; i++) {
          if (foo[i] !== bar[i]) return false;
        }
        continue;
      }

      if (ArrayBuffer.isView(foo)) {
        if (foo.byteLength !== bar.byteLength) return false;
        for (let i = 0; i < foo.byteLength; i++) {
          if ((foo as Uint8Array)[i] !== (bar as Uint8Array)[i]) return false;
        }
        continue;
      }

      if (!ctor || typeof foo === "object") {
        const keysFoo = Object.keys(foo);
        const keysBar = Object.keys(bar);

        if (keysFoo.length !== keysBar.length) return false;

        for (const key of keysFoo) {
          if (has.call(foo, key) && !has.call(bar, key)) return false;
          stack.push({ foo: foo[key], bar: bar[key] });
        }
        continue;
      }
    }

    if (foo !== foo || bar !== bar) return foo !== foo && bar !== bar;
    return false;
  }

  return true;
}
