import type { Option } from "@shared/types";

type ItemOption = Option & { id?: number | string };

export const getCostCodeAccountValues = (item: ItemOption) => {
  const isAccount = item.value.includes("account");
  return {
    item: !isAccount ? item?.id?.toString() : null,
    account: isAccount ? item?.id?.toString() : null,
  };
};

export const getItemUrls = (url: string) => {
  const isAccount = url.includes("account");
  return { item: !isAccount ? url : null, account: isAccount ? url : null };
};
