import cookie from "cookie";

import { api } from "../utils/api";

type LoginProps = { username: string; password: string } | { token: string };

type SignupProps = {
  fullName: string;
  phoneNumber: string;
  email: string;
  password: string;
  token: string;
};

type ForgotPasswordProps = {
  email: string;
};

type ResetPasswordProps = {
  uid: string;
  token: string;
  password: string;
};

type ValidateSignUpTokenProps = {
  email: string;
  token: string;
};

export const login = (params: LoginProps) => {
  let resource: string;

  let payload: FormData | Record<string, string>;

  if ("token" in params) {
    resource = "/api/user/magic-link/validate/";
    payload = { token: params.token };
  } else {
    resource = "/accounts/login/";
    payload = new FormData();
    payload.append("username", params.username.toLocaleLowerCase());
    payload.append("password", params.password);
  }

  return api.post(resource, payload).then((response) => {
    const invalidCredentials =
      response.request.responseURL.endsWith("/accounts/login/");

    if (invalidCredentials) throw new Error("Invalid credentials");
  });
};

export const signUp = ({
  email,
  password,
  fullName,
  phoneNumber,
  token,
}: SignupProps) => {
  const formData = new FormData();
  formData.append("email", email.toLocaleLowerCase());
  formData.append("full_name", fullName);
  formData.append("phone_number", phoneNumber);
  formData.append("password", password);
  formData.append("token", token);

  return api.post("/api/user/sign-up/", formData);
};

export const logout = () => api.get("/accounts/logout");

export const forgotPassword = ({ email }: ForgotPasswordProps) => {
  const formData = new FormData();
  formData.append("email", email.toLocaleLowerCase());

  return api.post("/api/auth/users/reset_password/", formData);
};

export const resetPassword = ({ uid, token, password }: ResetPasswordProps) => {
  const formData = new FormData();
  formData.append("uid", uid);
  formData.append("token", token);
  formData.append("new_password", password);

  return api.post("/api/auth/users/reset_password_confirm/", formData);
};

export const validateSignUpToken = ({
  token,
  email,
}: ValidateSignUpTokenProps) => {
  const formData = new FormData();
  formData.append("token", token);
  formData.append("email", email);

  return api.post("/api/user/sign-up/validate/", formData);
};

export const isAuthorized = () => {
  try {
    const serverAuthorized =
      document.querySelector("#root")?.getAttribute("data-is-authenticated") ===
      "true";

    const { csrftoken } = cookie.parse(document.cookie);
    return serverAuthorized && !!csrftoken;
  } catch (e) {
    return false;
  }
};
