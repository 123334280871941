import React, { type PropsWithChildren, useReducer, useRef } from "react";
import { useMutationObserver } from "@adaptive/design-system/hooks";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import { Loader } from "../common/Loader";

const Fallback = () => null;

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  const bodyRef = useRef<HTMLElement>(document.body);

  const [isLoading, hideLoading] = useReducer(() => false, true);

  /**
   * Since Sentry dialog doesn't provide any callback when dialog
   * is closed/submitted we need to track with Mutation Observer
   * when the dialog is removed from the document.
   */
  useMutationObserver(bodyRef, (mutations) => {
    mutations.forEach((mutation) => {
      const hasRemovedSentryDialog = [...mutation.removedNodes].some(
        (node) =>
          node instanceof Element &&
          node.classList.contains("sentry-error-embed-wrapper")
      );

      if (hasRemovedSentryDialog) window.location.replace("/");
    });
  });

  return (
    <SentryErrorBoundary
      fallback={isLoading ? <Loader /> : <Fallback />}
      showDialog
      dialogOptions={{
        onLoad: hideLoading,
        labelClose: "Go to bills",
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};
