import { createContext, type ReactNode, useContext } from "react";

import type { Colors } from "../../styles/variables";

type AlertContextType = {
  icon: ReactNode;
  background: Colors;
};

export const AlertContext = createContext<AlertContextType | undefined>(
  undefined
);

export const useAlertContext = () => useContext(AlertContext);
