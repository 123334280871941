import Fuse, {
  type Expression,
  type FuseOptionKey,
  type FuseResultMatch,
} from "fuse.js";

type FuzzySearchOptions<Data> = (Data extends string
  ? { keys?: FuseOptionKey<Data>[] }
  : { keys: FuseOptionKey<Data>[] }) & {
  threshold?: number;
};

export type FuzzySearchSearchReturn<Data> = Data & {
  matches: FuseResultMatch[];
};

export const fuzzySearch = <Data>(
  haystack: Data[],
  options: FuzzySearchOptions<Data>
) => {
  const fuse = new Fuse(haystack, {
    shouldSort: false,
    ignoreLocation: true,
    includeMatches: true,
    ...options,
  });

  return {
    all: haystack,
    search: (needle: string | Expression) =>
      fuse
        .search(needle)
        .map((result) => ({ ...result.item, matches: result.matches })),
  };
};
