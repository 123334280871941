import { documentsApi } from "@api/vendors/api-documents";
import type { OutgoingDocumentPayload } from "@api/vendors/types";
import { createAction, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import type { Identifiable } from "@shared/types";
import type { RootState } from "@store/types";
import { withPayloadType } from "@store/ui";
import {
  type PromisesSettledSummary,
  summarizeResults,
} from "@utils/all-settled";

import { selectRealmUrl } from "../user/selectors-raw";

import {
  type ChangeSet,
  type EditDocument,
  type SavedDocument,
  SubmitErrorCodes,
  type VirtualDocument,
} from "./types";

export const addDocument = createAction(
  "vendor/document/add",
  (payload: Omit<VirtualDocument, "id">) => ({
    payload: {
      ...payload,
      id: nanoid(),
    },
  })
);

export const toggleEditDocument = createAction(
  "vendor/document/toggle-edit",
  (id: string | number) => ({
    payload: { id },
  })
);

export const removeDocument = createAction(
  "vendor/document/remove",
  withPayloadType<Identifiable>()
);

export const createDocument = createAsyncThunk<
  Awaited<ReturnType<typeof documentsApi.create>> | undefined,
  OutgoingDocumentPayload | { id: string | number },
  { state: RootState }
>(
  "vendors/documents/create",
  async (document, { getState, rejectWithValue }) => {
    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    try {
      return await documentsApi.create({
        ...document,
        realm,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const updateDocument = createAsyncThunk<
  Awaited<ReturnType<typeof documentsApi.put>> | undefined,
  OutgoingDocumentPayload & Identifiable,
  { state: RootState }
>(
  "vendors/documents/update",
  async (document, { getState, rejectWithValue }) => {
    const realm = selectRealmUrl(getState());
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    try {
      return await documentsApi.put({
        ...document,
        realm,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const deleteDocument = createAsyncThunk<
  Identifiable | void,
  Identifiable,
  { state: RootState }
>("vendors/documents/delete", async (doc, { rejectWithValue }) => {
  try {
    const resp = await documentsApi.remove(doc);
    if (resp.status === 204) {
      return doc;
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const syncDocuments = createAsyncThunk<
  Awaited<{
    added?: PromisesSettledSummary;
    removed?: PromisesSettledSummary;
    edited?: PromisesSettledSummary;
  }>,
  ChangeSet<VirtualDocument, SavedDocument, EditDocument>,
  { state: RootState }
>(
  "vendors/documents/sync",
  async (diff, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const realm = selectRealmUrl(state);

    const vendorUrl = state.vendors.form.info?.url;
    if (!realm) {
      return rejectWithValue(
        "Unable to sync documents.  Associated realm not found"
      );
    }

    if (!vendorUrl) {
      return rejectWithValue(
        "Unable to sync documents.  Associated vendor not found"
      );
    }

    const result: {
      added?: PromisesSettledSummary;
      removed?: PromisesSettledSummary;
      edited?: PromisesSettledSummary;
    } = {};

    const { added, removed, edited } = diff;

    if (added) {
      const addedResults = await Promise.allSettled(
        added.map(
          async (document) =>
            await dispatch(
              createDocument({ ...document, parent: vendorUrl })
            ).unwrap()
        )
      );

      result.added = summarizeResults(addedResults);
    }

    if (removed) {
      const removedResults = await Promise.allSettled(
        removed.map(
          async (document) => await dispatch(deleteDocument(document)).unwrap()
        )
      );

      result.removed = summarizeResults(removedResults);
    }

    if (edited) {
      const editedResults = await Promise.allSettled(
        edited.map(
          async (document) => await dispatch(updateDocument(document)).unwrap()
        )
      );
      result.edited = summarizeResults(editedResults);
    }

    return result;
  }
);
