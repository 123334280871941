import { HUMAN_READABLE_EXPENSE_REVIEW_STATUS } from "@src/expenses/utils/constants";

import {
  APPROVER_STATUS_COLOR,
  APPROVER_STATUS_HIDDEN_ICON,
  BILL_STATUS,
  BILL_STATUS_COLOR,
  HUMAN_READABLE_BILL_STATUS,
  HUMAN_READABLE_PAYMENT_METHOD,
} from "../../bills/constants";

export const isNull = (value) => {
  return value === null || typeof value === "undefined";
};

export const nullToEmptyString = (value) => {
  return isNull(value) ? "" : value;
};

export const isNullProperty = (obj, property) => {
  return (
    isObjectEmpty(obj) ||
    !Object.prototype.hasOwnProperty.call(obj, property) ||
    isNull(obj[property])
  );
};

export const isListEmpty = (value) => {
  return isNull(value) || value.length === 0;
};

export const isObjectEmpty = (value) => {
  return isNull(value) || Object.keys(value).length === 0;
};

export const nullIfIsNullProperty = (obj, property) => {
  return isNullProperty(obj, property) ? null : obj[property];
};

export const getFloat = (value) => {
  // Remove non digits and only allow one decimal dot
  return value.replace(/[^\d.]/g, "").replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
};

export const incompleteLines = (lines, reviewStatus) => {
  const shouldValidateItemAccountAndJob = ![
    BILL_STATUS.DRAFT,
    /**
     * @todo we should include `BILL_STATUS.APPROVAL` as soon as we remove
     * this validation from the backend side
     */
  ].includes(reviewStatus);

  return lines.filter((line) => {
    if (line.deleted) return false;

    const missingAmount = !Number(line.amount);

    const missingItem = !line.item?.url;
    const missingAccount = !line.account?.url;
    const missingItemOrAccount = missingItem && missingAccount;

    const missingJob = !missingItem && !line.customer?.url;

    return (
      missingAmount ||
      (shouldValidateItemAccountAndJob && (missingItemOrAccount || missingJob))
    );
  });
};

export const humanReadableBillReviewStatus = (status) =>
  HUMAN_READABLE_BILL_STATUS[status] || "Unknown";

export const humanReadableExpenseReviewStatus = (status) =>
  HUMAN_READABLE_EXPENSE_REVIEW_STATUS[status] || "Unknown";

export const humanReadableReviewStatus = (humanReadableType, status) =>
  humanReadableType === "Bill" || humanReadableType === "Vendor Credit"
    ? humanReadableBillReviewStatus(status)
    : humanReadableExpenseReviewStatus(status);

export const reviewStatusColor = (status) =>
  BILL_STATUS_COLOR[status] || "neutral";

export const approverStatusColor = (status) =>
  APPROVER_STATUS_COLOR[status] || "neutral";

export const approverStatusHiddenIcon = (status) =>
  APPROVER_STATUS_HIDDEN_ICON[status];

export const vendorTagColor = (status) => {
  if (status === "Active") {
    return "success";
  } else if (["Expired", "Overdue bills"].includes(status)) {
    return "warning";
  } else if (status.startsWith("Request sent")) {
    return "info";
  } else {
    return "neutral";
  }
};

export const PO_SIGNATURE_STATUS_VARIANT = {
  SIGNED: "success",
  REQUEST_SENT: "info",
  REQUEST_EXPIRED: "neutral",
};

export const poSignatureTagColor = (status) =>
  PO_SIGNATURE_STATUS_VARIANT[status] || "neutral";

export const humanReadablePaymentMethod = (method) =>
  HUMAN_READABLE_PAYMENT_METHOD[method] || "Unknown";

export const flattenedApprovers = (approvalWorkflows, approvals) => {
  const steps = approvalWorkflows.flatMap(
    (approvalWorkflow) => approvalWorkflow.steps
  );

  const approvers = [
    ...new Map(
      steps
        .flatMap((step) => step.approvers)
        .map((approver) => [approver.id, approver])
    ).values(),
  ];
  return approvers
    .map((approver) => {
      const approval = approvals.find(
        (approval) =>
          approval.approved_by.id === approver.id ||
          approval.roles.some((role) => role.id === approver.id)
      );

      return {
        ...approver,
        name: approver.name ?? approver.full_name,
        status: approval?.status || "PENDING",
      };
    })
    .filter((approval) => approval.status === "PENDING");
};

export const arrayToStringWithSeparators = (array) => {
  if (array.length > 1) {
    return array.slice(0, -1).join(", ") + " and " + array.slice(-1);
  } else {
    return array.toString();
  }
};
