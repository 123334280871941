import { type RefObject, useEffect, useState } from "react";

import { useEvent } from "../use-event";
import { useResizeObserver } from "../use-resize-observer";

type UseBreakpointsParams = {
  breakpoints: Record<string, number>;
  containerRef: RefObject<HTMLElement> | RefObject<Window>;
  initialBreakpoint: string;
};

export const useBreakpoints = ({
  breakpoints,
  containerRef,
  initialBreakpoint,
}: UseBreakpointsParams) => {
  const [breakpoint, setBreakpoint] = useState({
    matches: [initialBreakpoint],
    current: initialBreakpoint,
  });

  const onResize = useEvent((el) => {
    const width = el === window ? el.innerWidth : el.clientWidth;

    const matches = Object.entries(breakpoints).reduce(
      (acc, [key, value]) => (width > value ? [...acc, key] : acc),
      [] as (keyof typeof breakpoints)[]
    );

    setBreakpoint({
      matches,
      /** Spread necessary to not mutate matches. Change to toReversed() once is safe with tests */
      current: [...matches].reverse()[0],
    });
  });

  useEffect(() => {
    if (containerRef.current) onResize(containerRef.current);
  }, [containerRef, onResize]);

  useResizeObserver(containerRef as any, onResize, { observe: "width" });

  return breakpoint;
};
