import React, {
  type ComponentRef,
  type ForwardedRef,
  forwardRef,
  type KeyboardEventHandler,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CompositeRow, useMenuContext } from "@ariakit/react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { mergeRefs } from "../../utils/merge-refs";
import { ComboBox, type ComboBoxProps } from "../combobox";

import styles from "./dropdown.module.css";

export type DropdownComboboxItemProps<IsMultiple extends boolean = false> =
  Omit<
    ComboBoxProps<IsMultiple>,
    "inline" | "messageVariant" | "size" | "suffix" | "label"
  >;

type Ref = ComponentRef<typeof ComboBox>;

const getInitialInternalValue = <IsMultiple extends boolean = false>(
  multiple?: IsMultiple
) => (multiple ? [] : "") as DropdownComboboxItemProps<IsMultiple>["value"];

const DropdownComboBoxItem = <IsMultiple extends boolean = false>(
  {
    value,
    className,
    multiple,
    onChange,
    ...props
  }: DropdownComboboxItemProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const internalRef = useRef<Ref>(null);
  const timeoutMsRef = useRef<number>();

  const [internalValue, setInternalValue] = useState(
    value ?? getInitialInternalValue<IsMultiple>(multiple)
  );

  const menuStore = useMenuContext()!;

  timeoutMsRef.current = menuStore.useState("timeout");

  const open = menuStore.useState("open");

  const enhancedValue = useMemo(
    () => value ?? internalValue,
    [value, internalValue]
  );

  const enhancedOnChange = useEvent((value, option) => {
    !multiple && value && menuStore.hide();
    onChange?.(value, option);
    setInternalValue(value);
  });

  const onBlur = useEvent(() => menuStore.hide());

  const onKeyDown = useEvent<KeyboardEventHandler>((event) => {
    if (event.key === "Escape") menuStore.hide();
  });

  useLayoutEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (!open) {
      setInternalValue(getInitialInternalValue<IsMultiple>(multiple));
    } else {
      timeout = setTimeout(() => {
        if (!internalRef.current) return;

        internalRef.current.focus();
        internalRef.current.scrollLeft = 0;
      }, timeoutMsRef.current);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [multiple, open]);

  return (
    <CompositeRow role="row">
      {open && (
        <ComboBox
          ref={mergeRefs(internalRef, ref)}
          size="sm"
          value={enhancedValue}
          inline
          suffix={null}
          multiple={multiple}
          onBlur={onBlur}
          onChange={enhancedOnChange}
          onKeyDown={onKeyDown}
          className={{
            list: styles["dropdown-combobox-item-list"],
            wrapper: cn(className, styles["dropdown-combobox-item"]),
          }}
          placeholder="Select"
          messageVariant="hidden"
          {...props}
        />
      )}
    </CompositeRow>
  );
};

const ForwardedDropdownComboBoxItem = forwardRef(DropdownComboBoxItem) as <
  IsMultiple extends boolean = false,
>(
  props: DropdownComboboxItemProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof DropdownComboBoxItem>;

export { ForwardedDropdownComboBoxItem as DropdownComboBoxItem };
