import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { searchGetResponseSchema } from "./response";
import type { SearchPayload, SearchResponse } from "./types";

const enhancedApi = api.enhanceEndpoints({ addTagTypes: ["Search"] });

/*
These endpoints correspond to the global search.
*/
export const searchApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearch: builder.query<SearchResponse, SearchPayload>({
      query: (params) => ({
        url: `search/`,
        params,
        responseHandler: async (response) => {
          const data = await response.json();

          return handleResponse(
            transformKeysToCamelCase(data),
            searchGetResponseSchema
          );
        },
      }),
    }),
  }),
});

export const { useLazyGetSearchQuery } = searchApi;
