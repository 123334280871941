import { useCallback, useState } from "react";
import { api as reduxApi } from "@store/api-simplified";
import { api, handleRequest } from "@utils/api";
import { transformKeysToSnakeCase } from "@utils/schema/converters";

import { updateQuickBooksErrorsPayload } from "./request";
import type {
  DeleteQuickBooksErrorsPayload,
  DeleteQuickBooksErrorsResponse,
  UpdateQuickBooksErrorsPayload,
  UpdateQuickBooksErrorsResponse,
} from "./types";

type UseUnlinkMutationReturn = [
  () => { unwrap: () => Promise<void> },
  { isLoading: boolean },
];

type UseTriggerSyncMutationReturn = [
  () => { unwrap: () => Promise<void> },
  { isLoading: boolean },
];

/**
 * Since RTK baseUrl point to `/api/` we cannot use it on
 * this QBO endpoint, so here we basically implements the
 * same API of RTK to make it easy to refactor as soon as
 * we adjust RTK baseUrl.
 */
export const useUnlinkMutation = (): UseUnlinkMutationReturn => {
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(() => {
    setIsLoading(true);
    const promise = api.post("/qbo/unlink/");
    promise.finally(() => setIsLoading(false));
    return { unwrap: () => promise.then(() => undefined) };
  }, []);

  return [mutate, { isLoading }];
};

/**
 * Since RTK baseUrl point to `/api/` we cannot use it on
 * this QBO endpoint, so here we basically implements the
 * same API of RTK to make it easy to refactor as soon as
 * we adjust RTK baseUrl.
 */
export const useTriggerSyncMutation = (): UseTriggerSyncMutationReturn => {
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(() => {
    setIsLoading(true);
    const promise = api.post("/qbo/trigger_sync/");
    promise.finally(() => setIsLoading(false));
    return { unwrap: () => promise.then(() => undefined) };
  }, []);

  return [mutate, { isLoading }];
};

const quickbooksApi = reduxApi.injectEndpoints({
  endpoints: (builder) => ({
    updateQuickBooksErrors: builder.mutation<
      UpdateQuickBooksErrorsResponse,
      UpdateQuickBooksErrorsPayload
    >({
      queryFn: async (payload, _, __, baseQuery) => {
        await Promise.all(
          payload.ids.map((id) =>
            baseQuery({
              url: `/quickbooksmodelerrors/${id}/`,
              method: "PUT",
              body: transformKeysToSnakeCase(
                handleRequest(payload, updateQuickBooksErrorsPayload)
              ),
            })
          )
        );

        return { data: undefined };
      },
    }),
    deleteQuickBooksErrors: builder.mutation<
      DeleteQuickBooksErrorsResponse,
      DeleteQuickBooksErrorsPayload
    >({
      queryFn: async (payload, _, __, baseQuery) => {
        await Promise.all(
          payload.ids.map((id) =>
            baseQuery({
              url: `/quickbooksmodelerrors/${id}/`,
              method: "DELETE",
            })
          )
        );

        return { data: undefined };
      },
    }),
  }),
});

export const {
  useUpdateQuickBooksErrorsMutation,
  useDeleteQuickBooksErrorsMutation,
} = quickbooksApi;
