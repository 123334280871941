export const scrollTop = (el: HTMLElement | null, offset: number) =>
  new Promise<void>((resolve) => {
    if (!el) return resolve();

    const fixedOffset = offset.toFixed();
    const onScroll = () => {
      if (el.scrollTop.toFixed() === fixedOffset) {
        el.removeEventListener("scroll", onScroll);
        resolve();
      }
    };
    el.addEventListener("scroll", onScroll);
    onScroll();
    el.scrollTo({ top: offset, behavior: "smooth" });
  });
