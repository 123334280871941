import { api } from "../utils/api";

type Status = "PENDING" | "APPROVED" | "REJECTED";

type StoreApprovalProps = {
  status: Status;
  approved_by: string;
};

type StoreApprovalResponse = {
  id: string;
  url: string;
  status: Status;
  approved_by: string;
};

type UpdateApprovalPayload = {
  status: Status;
  amount?: number;
  bypass: boolean;
  comment?: string;
};

// TODO(BOB-2166): Delete this when removing old approvals logic.
export const createApproval = (payload: StoreApprovalProps) =>
  api
    .post<StoreApprovalResponse>("/api/approvals/", payload)
    .then((response) => response.data);

export const updateApproval = (
  billId: number,
  payload: UpdateApprovalPayload
) => api.put<void>(`/api/bills/${billId}/approve/`, payload);

export const destroyApproval = (approvalId: string) =>
  api
    .delete<void>(`/api/approvals/${approvalId}/`)
    .then((response) => response.data);
