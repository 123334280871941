import { createContext, useContext } from "react";

import type { DialogProps } from ".";

export type Context = {
  show?: boolean;
  step?: string;
  onBack?: () => void;
  hasTabs?: boolean;
  variant?: DialogProps["variant"];
  onClose?: DialogProps["onClose"];
  hasHeader?: boolean;
  setHasTabs?: (isVisible: boolean) => void;
  setHasHeader?: (isVisible: boolean) => void;
  hasFooter?: boolean;
  setHasFooter?: (isVisible: boolean) => void;
  hasContent?: boolean;
  setHasContent?: (isVisible: boolean) => void;
};

export const DialogContext = createContext<Context>({});

export const useDialogContext = () => useContext(DialogContext);
