import { dotObject } from "../dot-object";
import { is } from "../is";

type Direction = "asc" | "desc";

type Options = {
  direction: Direction;
  nullishOrder?: "first" | "last";
};

type SortBy = <T extends Record<string, unknown>>(
  collection: T[],
  key: string,
  directionOrOptions?: Direction | Options
) => T[];

export const sortBy: SortBy = (collection, key, directionOrOptions = "asc") =>
  [...collection].sort((a, b) => {
    const valueA = dotObject.get(a, key);
    const valueB = dotObject.get(b, key);
    const direction = is.string(directionOrOptions)
      ? directionOrOptions
      : directionOrOptions.direction;
    const nullishOrder = is.object(directionOrOptions)
      ? directionOrOptions.nullishOrder
      : undefined;

    const isAscending = direction === "asc";

    if (nullishOrder) {
      if (is.nullish(valueA) && is.nullish(valueB)) return 0;
      if (is.nullish(valueA)) return nullishOrder === "first" ? -1 : 1;
      if (is.nullish(valueB)) return nullishOrder === "first" ? 1 : -1;
    }

    if (is.number(valueA) && is.number(valueB)) {
      return isAscending ? valueA - valueB : valueB - valueA;
    }

    if (is.string(valueA) && is.string(valueB)) {
      return isAscending
        ? valueA.localeCompare(valueB, "en", { numeric: true })
        : valueB.localeCompare(valueA, "en", { numeric: true });
    }

    if (isAscending) return valueA > valueB ? 1 : -1;

    return valueA < valueB ? 1 : -1;
  });
