import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetCommonVendorsSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export const useCommonVendorsSimplified = () => {
  const { realmId } = useClientInfo();

  const query = useGetCommonVendorsSimplifiedQuery({
    realm: realmId as number,
  });
  return useAsSimplifiedData(query);
};
