/**
 * @todo Enable eslint/tslint and fix all issues
 */
/* eslint-disable */
// @ts-nocheck
import { Expense } from "@src/shared/api/expenses";
import type { ReferenceNode } from "@src/shared/types";

import { getLineItem } from "./line";
import type {
  CollectionQueries,
  CommonFilters,
  Entry,
  ExpenseState,
  Query,
} from "./types";

export const getInitialCommonFilters = (): CommonFilters => ({
  limit: localStorage.getItem("expenses-table-limit") || 10,
  is_archived: false,
});

export const getInitialQuery = <T extends CollectionQueries>(
  status: T
): Query<T> => {
  return {
    count: 0,
    filters: {
      offset: 0,
      ordering: "-true_created_at",
      review_status: new Set(status === "ALL" ? [] : [status]),
      ...(status === "ALL" ? { is_archived: undefined } : {}),
    },
    results: [],
    response: null,
    controller: undefined,
  };
};

export const getEmptyReferenceNode = (): ReferenceNode => ({
  id: undefined,
  url: undefined,
  displayName: undefined,
});

export const getInitialExpense = (id?: string): Entry<Expense> => {
  const defaultLine = getLineItem({ order: 0 });

  return {
    id: id || null,
    realm: null,
    amount: undefined,
    attachables: [],
    createdAt: null,
    createdBy: null,
    assignee: null,
    comments: [],
    errors: [],
    relatedErrors: [],
    docNumber: "",
    date: new Date(),
    fileSyncStatus: undefined,
    humanReadableType: "Receipt",
    emailBodyAttachable: null,
    linesCount: 0,
    lines: {
      [defaultLine.id]: defaultLine,
    },
    linkedInvoices: [],
    paymentAccount: undefined,
    cardTransaction: getEmptyReferenceNode(),
    paymentAccounts: [],
    isArchived: false,
    isCreator: true,
    isTransactionGeneratedDraft: false,
    duplicate: [],
    isManualOcrPending: false,
    publishedToQuickbooks: false,
    reviewStatus: "DRAFT",
    initialReviewStatus: "DRAFT",
    vendor: getEmptyReferenceNode(),
    tax: {
      isSet: false,
      value: undefined,
    },
    total_amount: 0,
    url: null,
    approvalWorkflows: [],
    fetchStatus: "pending",
  };
};

export const getInitialState = (): ExpenseState => ({
  entry: getInitialExpense(),
  staticEntry: getInitialExpense(),
  fetchStatus: "idle",
  nextExpense: getInitialExpense(),
  status: "DRAFT",
  expenses: [],
  query: {
    DRAFT: getInitialQuery("DRAFT"),
    FOR_REVIEW: getInitialQuery("FOR_REVIEW"),
    ALL: getInitialQuery("ALL"),
    filters: getInitialCommonFilters(),
  },
});

export const initialState = getInitialState();
