import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@src/shared/utils/schema/converters";
import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";

import { handleErrors } from "../handle-errors";

import { getCapitalOSCardsPayloadSchema } from "./requests";
import {
  getCapitalOSCardsResponseSchema,
  getCapitalOSInitiateLoginResponseSchema,
} from "./response";
import type {
  GetCapitalOSCardsPayload,
  GetCapitalOSCardsResponse,
  GetCapitalOSInitiateLoginResponse,
  UpdateCapitalOSCardPayload,
} from "./types";

const enhancedApi = api.enhanceEndpoints({ addTagTypes: ["CapitalOSCards"] });

export const capitalOSCardsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCapitalOSCard: builder.mutation<void, UpdateCapitalOSCardPayload>({
      query: (capitalOSCard) => ({
        url: `capitaloscards/${capitalOSCard.id}/`,
        body: transformKeysToSnakeCase(capitalOSCard),
        method: "PATCH",
      }),
      invalidatesTags: ["CapitalOSCards"],
    }),
    getCapitalOSCards: builder.query<
      GetCapitalOSCardsResponse,
      GetCapitalOSCardsPayload
    >({
      query: (args) => {
        const params = handleRequest(args, getCapitalOSCardsPayloadSchema);

        return {
          url: "capitaloscards/",
          params: transformKeysToSnakeCase(params),
          responseHandler: async (response) =>
            handleResponse(
              transformKeysToCamelCase(await response.json()),
              getCapitalOSCardsResponseSchema
            ),
        };
      },
      providesTags: ["CapitalOSCards"],
    }),
    getCapitalOSInitiateLogin: builder.query<
      GetCapitalOSInitiateLoginResponse,
      void
    >({
      query: () => {
        return {
          url: "banking/initiate_capital_os_login/",
          responseHandler: async (response) => {
            const data = await response.json();
            if (response.status >= 400) {
              return handleErrors(data);
            }

            return handleResponse(
              transformKeysToCamelCase(data),
              getCapitalOSInitiateLoginResponseSchema
            );
          },
        };
      },
    }),
  }),
});

export const {
  useGetCapitalOSCardsQuery,
  useUpdateCapitalOSCardMutation,
  useGetCapitalOSInitiateLoginQuery,
} = capitalOSCardsApi;
