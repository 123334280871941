import { useEffect, useRef, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";

type UseIsDraggingProps = {
  onOver?: (e: DragEvent) => void;
  onDrop?: (e: DragEvent) => void;
  enabled?: boolean;
};

export const useIsDragging = ({
  onDrop,
  onOver,
  enabled,
}: UseIsDraggingProps = {}) => {
  const targetRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);

  const onStop = useEvent((e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  });

  const enhancedOnDrop = useEvent((e: DragEvent) => {
    e.preventDefault();

    onStop(e);
    if (onDrop) onDrop(e);
  });

  const onDragEnter = useEvent((e) => {
    targetRef.current = e.target;
    setIsDragging(true);
  });

  const onDragOver = useEvent((e) => {
    e.preventDefault();

    onOver?.(e);
  });

  const onDragLeave = useEvent((e) => {
    if (e.target === targetRef.current) onStop(e);
  });

  useEffect(() => {
    if (enabled === false) return;

    document.documentElement.addEventListener("drop", enhancedOnDrop);
    document.documentElement.addEventListener("dragover", onDragOver);
    document.documentElement.addEventListener("dragenter", onDragEnter);
    document.documentElement.addEventListener("dragleave", onDragLeave);

    return () => {
      document.documentElement.removeEventListener("drop", enhancedOnDrop);
      document.documentElement.removeEventListener("dragover", onDragOver);
      document.documentElement.removeEventListener("dragenter", onDragEnter);
      document.documentElement.removeEventListener("dragleave", onDragLeave);
    };
  }, [enabled, enhancedOnDrop, onDragEnter, onDragLeave, onDragOver]);

  return isDragging;
};
