import React, { useEffect, useMemo } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { type TableRowAddon, VisuallyHidden } from "@adaptive/design-system";
import { isEqual } from "@adaptive/design-system/utils";
import type { ActionType, Job } from "@api/jobs";
import { CustomersTable } from "@components/customers-table";
import { useAppSelector } from "@store/hooks";
import { scrollMainTop } from "@utils/scroll-main-top";

export type Props = { action: ActionType };

export const Tab = ({ action }: Props) => {
  const { sortBy, activeFilters, inactiveFilters } = useAppSelector(
    (state) => ({
      data: state.jobs[`${action}Jobs`].jobs,
      sortBy: state.jobs.sortOption,
      activeFilters: state.jobs.activeFilters,
      inactiveFilters: state.jobs.inactiveFilters,
    }),
    isEqual
  );

  const trackDataToAutoScroll = useMemo(() => {
    const filters = action === "active" ? activeFilters : inactiveFilters;
    const page = filters.find((filter) => filter.index === "offset")?.value;

    return `${page}-${sortBy}`;
  }, [action, activeFilters, inactiveFilters, sortBy]);

  const row = useMemo<TableRowAddon<Job>>(
    () => ({
      render: ({ row, props }) => (
        <ReactRouterLink
          {...props}
          to={`/jobs/${row.id}`}
          state={{ prev: window.location.pathname + window.location.search }}
        >
          <VisuallyHidden>Open {row.display_name}</VisuallyHidden>
        </ReactRouterLink>
      ),
    }),
    []
  );

  useEffect(() => {
    scrollMainTop(0);
  }, [trackDataToAutoScroll]);

  return (
    <CustomersTable
      id={`customers-table-${action}-jobs-table`}
      key={action}
      row={row}
      active={action === "active"}
      paginationPerPageVariant="lg"
    />
  );
};
