import React, { useState } from "react";
import {
  Button,
  Card,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";

import { type Role, useRoleAction } from "../../../../shared/store/role";
import type { Dialog } from "../types";

type OnRoleChangeHandler = (role?: Role) => void;
type Props = {
  dialog: Dialog;
  onRoleChange?: OnRoleChangeHandler;
};

export const List = ({ dialog, onRoleChange }: Props) => {
  const { roles, loadRole } = useRoleAction();

  const [isLoadingRoleId, setIsLoadingRoleId] = useState("");

  const onEditRole = useEvent(async (role?: Role) => {
    if (role?.id && !role?.permissions?.length) {
      setIsLoadingRoleId(role.id);
      await loadRole(role.id);
      setIsLoadingRoleId("");
    }
    onRoleChange?.(role);
    dialog.setStep("role-edit");
  });

  return (
    <>
      <DialogHeader>Manage roles</DialogHeader>
      <DialogContent>
        <Flex direction="column" justify="center" gap="md">
          {roles.map((role) => (
            <Card
              as="button"
              key={role.id}
              type="button"
              onClick={() => onEditRole(role)}
              disabled={!!isLoadingRoleId}
            >
              <Flex gap="2xl" align="center" justify="space-between">
                <Flex direction="column" gap="sm">
                  <Text size="sm" weight="bold">
                    {role.name}
                  </Text>
                  <Text size="sm" align="left">
                    {role.description}
                  </Text>
                </Flex>
                {isLoadingRoleId === role.id ? (
                  <Loader />
                ) : (
                  <Icon name={!role?.base ? "gear" : "chevron-right"} />
                )}
              </Flex>
            </Card>
          ))}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" color="neutral" onClick={dialog.hide} block>
          Cancel
        </Button>
        <Button block onClick={() => onEditRole()}>
          Create role
        </Button>
      </DialogFooter>
    </>
  );
};
