import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Link,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatDate, parseDate } from "@adaptive/design-system/utils";
import type { DocumentType } from "@api/vendors/types";
import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch } from "@store/hooks";
import { useVendorAction } from "@store/vendors";

type Document = {
  id: string | number;
  type: string;
  document?: string;
  expiration_date?: string;
};

type AlertProps = {
  vendor?: {
    id: string | number;
    expired_documents?: Document[];
    pending_doc_requests: {
      created_at: string;
      document_types: DocumentType[];
    }[];
  };
};

const getDocumentLabel = (type: string) =>
  DOCUMENT_TYPE_OPTIONS.find((option) => option.value === type)?.label;

export const ExpiredDocumentsAlert = ({ vendor }: AlertProps) => {
  const dispatch = useAppDispatch();

  const onDrawerClose = useEvent(() =>
    dispatch(refetchCurrentBill(["vendor"]))
  );
  const { showVendorById } = useVendorAction({
    onDrawerClose,
  });

  const {
    id: vendorId,
    expired_documents: documents,
    pending_doc_requests: pendingDocRequests,
  } = vendor ?? {};

  const onAddVendorComplianceDocument = useEvent(() =>
    showVendorById(vendorId as string, "docs")
  );

  const unrequestedExpiredDocuments = useMemo(() => {
    const types = Array.from(
      new Set(
        (pendingDocRequests || [])
          .map((request) => request.document_types)
          .flat()
      )
    );

    return (documents || []).filter(
      (document) => !types.includes(document.type as DocumentType)
    );
  }, [pendingDocRequests, documents]);

  if (unrequestedExpiredDocuments.length === 0) return null;

  return (
    <Alert variant="warning">
      <AlertTitle>
        This vendor&apos;s compliance documents are expired
      </AlertTitle>
      <AlertContent as="div">
        <Flex gap="xl" direction="column">
          <ul>
            {unrequestedExpiredDocuments.map((document) => (
              <li key={document.id}>
                {document.document ? (
                  <>
                    <Link
                      rel="noreferrer"
                      size="sm"
                      href={document.document}
                      target="_blank"
                      download
                    >
                      {getDocumentLabel(document.type)}
                    </Link>{" "}
                  </>
                ) : (
                  <Text as="span" size="sm">
                    {getDocumentLabel(document.type)}{" "}
                  </Text>
                )}
                {document.expiration_date && (
                  <Text as="span" size="sm">
                    (Expired{" "}
                    {formatDate(
                      parseDate(document.expiration_date, "yyyy-MM-dd")
                    )}
                    )
                  </Text>
                )}
              </li>
            ))}
          </ul>

          <Flex>
            <Button
              size="sm"
              color="warning"
              onClick={onAddVendorComplianceDocument}
            >
              Update documents
            </Button>
          </Flex>
        </Flex>
      </AlertContent>
    </Alert>
  );
};
