import { api } from "../utils/api";

type PeopleResponse = {
  full_name: string;
  id: number;
  is_user: boolean;
  role: { id: number; name: string };
}[];

export const getPeople = async (clientId: string) => {
  return await api
    .get<PeopleResponse>(`/api/clients/${clientId}/users/`)
    .then((resp) => resp.data);
};

export const deleteUser = async (clientId: string, userId: string) => {
  return await api.delete(`/api/clients/${clientId}/users/${userId}/`);
};

export const deleteInvitation = async (clientId: string, inviteId: string) => {
  return await api.delete(`/api/clients/${clientId}/invites/${inviteId}/`);
};

export const resendInvitation = async (clientId: string, inviteId: string) => {
  return await api.post(`/api/clients/${clientId}/invites/${inviteId}/send/`);
};
