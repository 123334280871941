import { useMemo } from "react";
import type {
  UseQuery,
  UseQueryHookResult,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
  QueryStatus,
} from "@reduxjs/toolkit/query";
import type { DataAccessState } from "@shared/hooks/types";
import type { Option } from "@shared/types";

const STATUS_MAP: Record<QueryStatus, DataAccessState["status"]> = {
  pending: "loading",
  fulfilled: "success",
  rejected: "failed",
  uninitialized: "loading",
} as const;

export type ReturnDataAccessStatus = (typeof STATUS_MAP)[QueryStatus];

export const asDataAccessStatus = (
  status: QueryStatus
): ReturnDataAccessStatus => STATUS_MAP[status] || STATUS_MAP.uninitialized;

export const useFormattedOptions = (
  data: Option[] | undefined,
  groupLabel?: string
) => {
  return useMemo(() => {
    if (!data) return [] as Option[];
    return groupLabel
      ? data.map((option) => ({ ...option, groupLabel }))
      : data;
  }, [groupLabel, data]);
};

type SimplifiedData = Omit<ReturnType<UseQuery<never>>, "data" | "status"> & {
  status: DataAccessState["status"];
  data: Option[];
};

type RtkQueryResult = UseQueryHookResult<
  QueryDefinition<
    any,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      Record<string, unknown>,
      FetchBaseQueryMeta
    >,
    | "Vendors"
    | "CustomerItems"
    | "BudgetLines"
    | "Customer"
    | "CustomerMarkup"
    | "BudgetLineItems"
    | "VendorsSimplified"
    | "CommonVendorsSimplified"
    | "CustomersSimplified",
    Option<string>[],
    "api"
  >
>;

export const useAsSimplifiedData = (
  query: RtkQueryResult,
  options: { groupLabel?: string } = {}
): SimplifiedData => {
  const formattedData = useFormattedOptions(query.data, options.groupLabel);

  const status = useMemo(() => {
    if (query.isSuccess || query.isUninitialized) return "success";
    if (query.isError) return "failed";
    if (query.isFetching) return "loading";
    return "loading";
  }, [query.isError, query.isFetching, query.isSuccess, query.isUninitialized]);

  return {
    ...query,
    data: formattedData,
    status,
  };
};
