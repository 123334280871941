import React, {
  type ComponentProps,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  ComboBox,
  Flex,
  Icon,
  PercentageField,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { parsePercentage } from "@adaptive/design-system/utils";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { idSchema } from "@shared/utils/schema";
import { z } from "zod";

import { PERCENTAGE_FORMAT } from "../constants";

type CurriedOnInputHandler = (
  index: number
) => Exclude<ComponentProps<typeof PercentageField>["onInput"], undefined>;

const markupFormLineSchema = z.object({
  id: idSchema.optional(),
  value: z.number().refine((value) => value !== 0, "Percent is required"),
  isSeparateLine: z.boolean().optional(),
  costCode: z.string().url("Item is required"),
});

const schema = z.object({
  markups: z.array(markupFormLineSchema),
});

type Fields = z.input<typeof schema>;

const NEW_BLANK_MARKUP_FIELD = {
  value: 0,
  isSeparateLine: false,
  costCode: "",
};

export type MarkupEditInlineFormProps = {
  initialValues: Fields;
  formId: string;
  onSubmit: (values: Fields) => Promise<void>;
  onValidityChange: (isValid: boolean) => void;
  percentageFormat?: typeof PERCENTAGE_FORMAT;
};

export const MarkupEditInlineForm = ({
  initialValues,
  formId,
  onSubmit,
  onValidityChange,
  percentageFormat = PERCENTAGE_FORMAT,
}: MarkupEditInlineFormProps) => {
  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified();

  const form = useForm<Fields>({
    id: formId,
    schema,
    initialValues,
    onSubmit: async (values) => {
      await onSubmit(values);
      form.reset();
    },
  });

  const [isPercentagesValid, setIsPercentagesValid] = useState(
    initialValues.markups.map(() => true)
  );

  const onAddMarkup = useEvent(() => {
    form.append("markups", NEW_BLANK_MARKUP_FIELD);
    setIsPercentagesValid((percentages) => [...percentages, false]);
  });

  const curriedOnRemoveMarkup = useCallback(
    (index: number) => () => {
      form.remove("markups", index);
      setIsPercentagesValid((percentages) =>
        percentages.filter((_, i) => i !== index)
      );
    },
    [form]
  );

  const curriedOnInput = useCallback<CurriedOnInputHandler>(
    (index) => (e) => {
      setIsPercentagesValid((percentages) =>
        percentages.map((isValid, i) =>
          i !== index
            ? isValid
            : !!parsePercentage(e.currentTarget.value, {
                maximumFractionDigits: percentageFormat.maximumFractionDigits,
              })
        )
      );
    },
    [percentageFormat.maximumFractionDigits]
  );

  /**
   * We do this custom validation instead of using `form.isValid` because PercentageField
   * only notifies your changes when user blur, we do it because we cannot ensure parse
   * and use the `unfinished` value on controlled way. Other libraries like `react-aria`
   * do the same on these cases.
   */
  useEffect(() => {
    const hasCostCodes = form.values.markups.every(
      (markup) => !!markup.costCode
    );
    const hasPercentages = isPercentagesValid.every((isValid) => isValid);

    onValidityChange(hasCostCodes && hasPercentages && !form.isSubmitting);
  }, [
    form.isValid,
    onValidityChange,
    form.isSubmitting,
    isPercentagesValid,
    form.values.markups,
  ]);

  return (
    <form {...form.props}>
      {form.values.markups.map((_, index) => (
        <Flex
          gap="xl"
          align={index === 0 ? "center" : "flex-start"}
          justify="center"
          key={index}
        >
          <Flex grow={true}>
            <ComboBox
              placeholder="ex: Builder's Fee"
              label={index === 0 && "Item Name"}
              data={costCodeAccountsSimplified.data}
              loading={costCodeAccountsSimplified.status === "loading"}
              required
              {...form.register(`markups.${index}.costCode`)}
            />
          </Flex>
          <Flex width="140px">
            <PercentageField
              label={index === 0 && "Percent"}
              placeholder="ex: 20"
              onInput={curriedOnInput(index)}
              required
              min={percentageFormat.min}
              max={percentageFormat.max}
              allowNegative
              minimumFractionDigits={percentageFormat.minimumFractionDigits}
              maximumFractionDigits={percentageFormat.maximumFractionDigits}
              {...form.register({
                name: `markups.${index}.value`,
                type: "percentage",
              })}
            />
          </Flex>
          <Button
            aria-label={`Remove markup ${index + 1}`}
            size="md"
            color="neutral"
            variant="ghost"
            onClick={curriedOnRemoveMarkup(index)}
          >
            <Icon name="trash" />
          </Button>
        </Flex>
      ))}
      <Button
        size="md"
        variant="ghost"
        onClick={onAddMarkup}
        data-testid="budget-add-markup-button"
      >
        <Icon name="plus" />
        Add markup
      </Button>
    </form>
  );
};
