import React from "react";
import cn from "clsx";

import { getPlatform } from "../../utils/get-platform";
import { Icon } from "../icon";
import { Text } from "../text";

import styles from "./shortcut.module.css";

type Props = {
  meta?: boolean;
  shift?: boolean;
  letter: string;
  inverse?: boolean;
};

export const Shortcut = ({ meta, shift, letter, inverse }: Props) => {
  const platform = getPlatform();

  return (
    <div className={cn(styles["shortcut"], { [styles["-inverse"]]: inverse })}>
      {meta ? (
        platform === "mac" ? (
          <Icon size="sm" name="command" />
        ) : (
          <Text>CTRL</Text>
        )
      ) : null}
      {shift && <Icon size="sm" name="up" />}
      <Text>{letter}</Text>
    </div>
  );
};
