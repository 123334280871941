import React from "react";
import {
  Button,
  Card,
  Flex,
  Icon,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { formatDate } from "@adaptive/design-system/utils";
import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";

type VendorDocumentPreviewProps = {
  url?: string;
  type: string;
  onDelete?: () => void;
  onUpdate?: () => void;
  isExpired?: boolean;
  expirationDate?: Date | null;
  onDeleteDisabledTooltipMessage?: string;
};

export const VendorDocumentPreview = ({
  url,
  type,
  onDelete,
  onUpdate,
  isExpired,
  expirationDate,
  onDeleteDisabledTooltipMessage,
}: VendorDocumentPreviewProps) => {
  const enhancedType = DOCUMENT_TYPE_OPTIONS.find(
    (option) => option.value === type
  )?.label as string;

  return (
    <Card
      as={Flex}
      gap="xl"
      align="center"
      height="80px"
      padding="xl"
      justify="space-between"
    >
      <Flex justify="flex-start" padding="md">
        <Icon name="file-lines" />
      </Flex>
      <Flex grow direction="column">
        <Text weight="bold" transform="capitalize">
          {enhancedType}
        </Text>
        {expirationDate &&
          (isExpired ? (
            <Tag color="warning">Expired {formatDate(expirationDate)}</Tag>
          ) : (
            <Text>Expires {formatDate(expirationDate)}</Text>
          ))}
      </Flex>
      <Flex justify="flex-end">
        {onUpdate && (
          <Button color="neutral" variant="text" onClick={onUpdate}>
            <Icon name="pen" />
          </Button>
        )}
        {onDelete && (
          <Tooltip
            message={
              onDeleteDisabledTooltipMessage
                ? onDeleteDisabledTooltipMessage
                : ""
            }
          >
            <Button
              color="neutral"
              variant="text"
              onClick={onDelete}
              aria-label="Delete"
              disabled={!!onDeleteDisabledTooltipMessage}
            >
              <Icon name="trash" />
            </Button>
          </Tooltip>
        )}
        {url && (
          <Button
            as="a"
            rel="noreferrer"
            href={url}
            color="neutral"
            variant="text"
            target="_blank"
            download
            aria-label="Download"
          >
            <Icon name="arrow-down-to-bracket" />
          </Button>
        )}
      </Flex>
    </Card>
  );
};
