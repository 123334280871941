import { omit } from "@adaptive/design-system/utils";
import { idSchema } from "@utils/schema";
import { z } from "zod";

export const getBankAccountsPayloadSchema = z
  .object({
    /**
     * We don't need this `clientId` field in the request, but we need it to trigger
     * a refetch when the client changes.
     */
    clientId: idSchema,
    accountType: z.string().optional(),
    accountBalancesType: z.string().optional(),
  })
  .transform((data) => omit(data, ["clientId"]));
