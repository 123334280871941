import { formatDate } from "@adaptive/design-system/utils";
import { addDays, subDays } from "date-fns";

import { type CardTransaction } from "../types";

export const transformCardTransactionToFilters = (
  cardTransaction: Pick<CardTransaction, "vendor" | "date">
) => {
  const enhancedFilters: Record<string, unknown> = {};

  if (cardTransaction.vendor?.url) {
    enhancedFilters[cardTransaction.vendor.url] = {
      label: cardTransaction.vendor.displayName,
      value: cardTransaction.vendor.url,
      groupLabel: "Vendor",
    };
  }

  if (cardTransaction.date) {
    enhancedFilters["date_after"] = formatDate(
      subDays(cardTransaction.date, 1),
      "yyyy-MM-dd"
    );
    enhancedFilters["date_before"] = formatDate(
      addDays(cardTransaction.date, 1),
      "yyyy-MM-dd"
    );
  }

  return enhancedFilters;
};
