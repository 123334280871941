import React from "react";
import { Icon } from "@adaptive/design-system";
import cn from "clsx";

import styles from "./main.module.css";

type Props = {
  onClick?: () => void;
  className?: string;
};

export const MainHeaderBack = ({ className, ...props }: Props) => (
  <Icon
    as="button"
    name="arrow-left"
    type="button"
    aria-label="Back"
    className={cn(className, styles["main-header-back"])}
    data-skip-focusable=""
    {...props}
  />
);
