import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

export const reviewStatusSchema = z.enum([
  "ALL",
  "PAID",
  "DRAFT",
  "APPROVAL",
  "FOR_PAYMENT",
  "PAYMENT_FAILED",
  "PARTIALLY_PAID",
  "ACH_PROCESSING",
  "ACH_INFO_REQUESTED",
]);

export const checkBillDuplicationResponseSchema = z.object({
  duplicate: arraySchema(
    z.object({
      id: idSchema,
      url: z.string().url(),
      docNumber: z.string(),
      reviewStatus: reviewStatusSchema,
      humanReadableType: z.enum(["Bill", "Vendor Credit"]),
    })
  ),
});

export const linkedInvoicesResponseSchema = z.object({
  invoices: arraySchema(
    z.object({
      id: idSchema,
      docNumber: z.string(),
      customerId: idSchema,
      humanReadableType: z.enum(["Draw"]),
      url: z.string().url(),
    })
  ),
});
