import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  dialog,
  Flex,
  Icon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import {
  DynamicActions as SharedDynamicActions,
  type DynamicActionsProps,
} from "@components/dynamic-actions";
import { type Selector } from "@reduxjs/toolkit";
import { useExpenseAction, useExpensePermissions } from "@store/expenses";
import { expenseSelectors } from "@store/expenses/selectors";
import { useAppSelector } from "@store/hooks";
import { BasePermissions, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";

import { useCycle } from "../cycle-provider";
import { REVIEW_STATUS, STRINGS } from "../utils/constants";
import { getTableRouterUrlForStatus } from "../utils/table";

export const DynamicActions = () => {
  const cycle = useCycle();

  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const expenseId = useAppSelector(expenseSelectors.id);
  const docNumber = useAppSelector(expenseSelectors.docNumber as Selector);
  const isArchived = useAppSelector(expenseSelectors.isArchived);
  const reviewStatus = useAppSelector(expenseSelectors.reviewStatus);
  const isTransactionGeneratedDraft = useAppSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );
  const initialReviewStatus = useAppSelector(
    (state) =>
      expenseSelectors.initialReviewStatus(state) as ReturnType<
        typeof expenseSelectors.reviewStatus
      >
  );

  const { hasPermission } = useUserInfo();

  const canAddBill = hasPermission(BasePermissions.ADD_BILL);

  const { canEditExpense } = useExpensePermissions();

  const { convertToBill, deleteExpense, archive, setStatus } =
    useExpenseAction();

  const onArchive = useEvent(async () => {
    await archive();
    toast.success(`Receipt ${docNumber ? `#${docNumber} ` : ""}archived!`);
    analytics.track("expenseDynamicActions", {
      action: "archive",
      expenseId,
    });
  });

  const goToPreviousPage = useEvent(() => {
    const url = getTableRouterUrlForStatus(initialReviewStatus);
    navigate(dotObject.get(locationState as object, "prev", url));
  });

  const onDelete = useEvent(() => {
    const handler = async () => {
      await deleteExpense();
      goToPreviousPage();
      toast.success(`Receipt ${docNumber ? `#${docNumber} ` : ""}deleted!`);
      analytics.track("expenseDynamicActions", {
        action: "delete",
        expenseId,
      });
    };

    dialog.confirmation({
      title: (
        <>
          Are you sure you want to <br />
          delete this transaction?
        </>
      ),
      message: "You can't undo this action.",
      action: {
        primary: { color: "error", onClick: handler, children: "Delete" },
      },
    });
  });

  const onConvert = useEvent(async () => {
    await convertToBill(expenseId);
    toast.success(
      `Receipt ${docNumber ? `#${docNumber} ` : ""}re-categorized as a bill!`
    );
    analytics.track("expenseDynamicActions", {
      action: "re-categorize-as-bill",
      expenseId,
    });
  });

  const onEdit = useEvent(() => {
    cycle.disable();
    setStatus("DRAFT");
    analytics.track("expenseDynamicActions", {
      action: "send-back-to-card-holder",
      expenseId,
    });
  });

  if (!expenseId) return <Flex />;

  let actions: Exclude<DynamicActionsProps["data"], undefined> = [];

  if (
    (initialReviewStatus === REVIEW_STATUS.DRAFT || isArchived) &&
    canEditExpense
  ) {
    actions = [
      ...actions,
      { onClick: onDelete, children: "Delete", color: "error" },
    ];
  }

  if (
    !isArchived &&
    canAddBill &&
    (initialReviewStatus === REVIEW_STATUS.FOR_REVIEW ||
      initialReviewStatus === REVIEW_STATUS.DRAFT) &&
    !isTransactionGeneratedDraft
  ) {
    actions = [
      ...actions,
      { onClick: onConvert, children: "Re-categorize as a bill" },
    ];
  }

  if (!isArchived) {
    actions = [
      ...actions,
      {
        onClick: onArchive,
        children: "Archive",
      },
    ];
  }

  if (
    canEditExpense &&
    initialReviewStatus === "FOR_REVIEW" &&
    reviewStatus !== REVIEW_STATUS.DRAFT
  ) {
    actions = [
      ...actions,
      {
        onClick: onEdit,
        children: (
          <Flex gap="sm" align="center">
            <Text>Send back to card holder</Text>
            <Tooltip
              as={Icon}
              name="info-circle"
              size="sm"
              message={STRINGS.sendBackTooltip}
            />
          </Flex>
        ),
      },
    ];
  }

  return (
    <SharedDynamicActions
      data={actions}
      fallback={<Flex />}
      data-testid="expense-actions"
    />
  );
};
