import { idSchema } from "@utils/schema";
import { z } from "zod";

export const getFidelCardsPayloadSchema = z.object({
  realm: idSchema.nullish(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  ordering: z.string().optional(),
});

export const pollFidelCardPayloadSchema = z.object({
  fidelId: idSchema,
  verificationStatus: z.string().optional(),
});

export const updateFidelCardPayloadSchema = z.object({
  id: idSchema,
  user: z.string().nullish(),
  paymentAccount: z.string().nullish(),
});
