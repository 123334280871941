import type { InvoiceGetResponse, InvoiceUpdatePayload } from "@api/invoices";
import { DEFAULT_VENDOR_INVOICE_OPTION_VALUE } from "@src/jobs/constants";
import { divide } from "@utils/divide";
import { sum } from "@utils/sum";

import { STRINGS } from "./constants";
import type { EnhancedSummaryLine } from "./invoice-summary";

export const groupLinesByCategory = (
  lines: EnhancedSummaryLine[],
  amountKey: keyof Pick<
    EnhancedSummaryLine,
    "budget" | "ownersAmount" | "ownersRevisedBudget" | "revisedBudget"
  > = "budget"
): EnhancedSummaryLine[] => {
  return lines
    .reduce((acc, line) => {
      const categoryIndex = acc.findIndex(
        (item) =>
          item.category === line.category &&
          line.category !== STRINGS.UNKNOWN_CATEGORY
      );

      if (acc[categoryIndex]) {
        acc[categoryIndex].budget = sum(
          acc[categoryIndex].budget,
          line.budget || 0
        );
        acc[categoryIndex].changeAmount = sum(
          acc[categoryIndex].changeAmount,
          line.changeAmount || 0
        );
        acc[categoryIndex].revisedBudget = sum(
          acc[categoryIndex].revisedBudget,
          line.revisedBudget || 0
        );
        acc[categoryIndex].ownersAmount = sum(
          acc[categoryIndex].ownersAmount,
          line.ownersAmount || 0
        );
        acc[categoryIndex].externalChangeAmount = sum(
          acc[categoryIndex].externalChangeAmount,
          line.externalChangeAmount || 0
        );
        acc[categoryIndex].ownersRevisedBudget = sum(
          acc[categoryIndex].ownersRevisedBudget,
          line.ownersRevisedBudget || 0
        );
        acc[categoryIndex].thisDraw = sum(
          acc[categoryIndex].thisDraw,
          line.thisDraw || 0
        );
        acc[categoryIndex].previousDraws = sum(
          acc[categoryIndex].previousDraws,
          line.previousDraws || 0
        );
        acc[categoryIndex].totalInvoiced = sum(
          acc[categoryIndex].totalInvoiced,
          line.totalInvoiced || 0
        );
        acc[categoryIndex].balanceToFinish = sum(
          acc[categoryIndex].balanceToFinish,
          line.balanceToFinish || 0
        );
      } else {
        acc.push({
          budget: line.budget || 0,
          changeAmount: line.changeAmount || 0,
          revisedBudget: line.revisedBudget || 0,
          ownersAmount: line.ownersAmount || 0,
          externalChangeAmount: line.externalChangeAmount || 0,
          ownersRevisedBudget: line.ownersRevisedBudget || 0,
          category: line.category,
          thisDraw: line.thisDraw || 0,
          previousDraws: line.previousDraws || 0,
          totalInvoiced: line.totalInvoiced || 0,
          invoicedPercent: line.invoicedPercent || 0,
          balanceToFinish: line.balanceToFinish || 0,
          jobCostMethod: line.jobCostMethod,
        });
      }

      return acc;
    }, [] as EnhancedSummaryLine[])
    .map((category) => ({
      ...category,
      invoicedPercent:
        category.totalInvoiced && category[amountKey]
          ? divide(category.totalInvoiced, category[amountKey]) * 100
          : 0,
    }));
};

export const transformInvoiceGetLinesToInvoicePayload = (
  lines: InvoiceGetResponse["lines"]
) =>
  lines.map((line) => {
    const url = line.jobCostMethod?.url;
    const isAccount = line.jobCostMethod?.url.includes("account");

    return {
      ...line,
      item: !isAccount ? url : null,
      itemAccount: isAccount ? url : null,
      transactionLine: line.transactionLine?.url,
      vendor:
        line.vendor?.url === DEFAULT_VENDOR_INVOICE_OPTION_VALUE
          ? null
          : line.vendor?.url,
    };
  });

export const transformInvoiceGetMarkupsToInvoicePayload = (
  markups: InvoiceGetResponse["markups"]
) =>
  markups.map((markup) => {
    const url = markup.jobCostMethod?.url;
    const isAccount = markup.jobCostMethod?.url.includes("account");

    return {
      ...markup,
      id: markup.id || undefined,
      item: !isAccount ? url : null,
      itemAccount: isAccount ? url : null,
      percent: markup.percent || undefined,
      vendor:
        markup.vendor?.url === DEFAULT_VENDOR_INVOICE_OPTION_VALUE
          ? null
          : markup.vendor?.url,
    };
  });

export const transformInvoiceGetLinesAndMarkupsToInvoicePayload = (
  values: Pick<InvoiceGetResponse, "lines" | "markups">
) => ({
  lines: transformInvoiceGetLinesToInvoicePayload(values.lines),
  markups: transformInvoiceGetMarkupsToInvoicePayload(values.markups),
});

export const transformInvoiceGetToInvoicePayload = (
  values: InvoiceGetResponse
): InvoiceUpdatePayload => ({
  id: values.id,
  date: values.date,
  title: values.title,
  toName: values.toName || values.customer?.displayNameWithoutCompany,
  dueDate: values.dueDate,
  docNumber: values.docNumber,
  description: values.description,
  reviewStatus: values.reviewStatus,
  ...transformInvoiceGetLinesAndMarkupsToInvoicePayload(values),
});
