import type {
  AddLineItemPayload,
  BatchUpdateCustomerBudgetLinesCategoriesPayload,
  BudgetLineItems,
  BudgetLineItemsResponse,
  CloneCustomerCategoryPayload,
  CustomerSchemaGetRequest,
  DeleteBudgetLineItemsPayload,
  DeleteCustomerCategoryPayload,
  GetCustomerCategoriesPayload,
  GetCustomerCategoriesResponse,
  GetCustomerDrawnToDatePayload,
  GetDrawnToDateResponse,
  LinkedInvoicesPayload,
  LinkedInvoicesResponse,
  StoreCustomerCategoryPayload,
  StoreCustomerCategoryResponse,
  UpdateBuilderBudgetLockedStatusPayload,
  UpsertCustomerCategoriesPayload,
} from "@api/jobs";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@src/shared/utils/schema/converters";
import { api as apiSimplified } from "@store/api-simplified";
import {
  getApi,
  getSearchParamsFromFilters,
  getUrlBuilder,
  handleRequest,
  handleResponse,
  transformPayloads,
} from "@utils/api";

import {
  batchUpdateCustomerBudgetLinesCategoriesPayloadSchema,
  cloneCustomerCategoryPayloadSchema,
  deleteCustomerCategoryPayloadSchema,
  getCustomerCategoriesPayloadSchema,
  getCustomerDrawnToDatePayloadSchema,
  storeCustomerCategoryPayloadSchema,
  upsertCustomerCategoriesPayloadSchema,
} from "./request";
import {
  drawnToDateResponseSchema,
  getCustomerCategoriesResponseSchema,
  linkedInvoicesResponseSchema,
  saveBudgetLineItemsResponseSchema,
  storeCustomerCategoryResponseSchema,
} from "./response";

export const api = getApi([transformPayloads]);
const urlBuilderBudgetLines = getUrlBuilder("/api/customers/");

type ExportJobProps = {
  id: string | number;
  params?: URLSearchParams;
};

export const exportJob = ({ id, params }: ExportJobProps) =>
  api
    .get(`/api/customers/${id}/lines/export/`, { params })
    .then(({ data }) => (data?.id ? (data.id as string) : undefined));

export const addBudgedLineItem = async (payload: AddLineItemPayload) =>
  await api
    .post<
      BudgetLineItems[]
    >(`${urlBuilderBudgetLines.forCollection()}${payload.customerId}/budgetlines/bulk_create/`, payload)
    .then((resp) => {
      const newItems: BudgetLineItems[] = [];
      Object.keys(resp.data).forEach((attr: any) => {
        newItems.push(resp.data[attr]);
      });
      return handleResponse(newItems, saveBudgetLineItemsResponseSchema);
    });

export const getBudgedLineItems = async (customerId: number) =>
  await api.get<BudgetLineItemsResponse>(
    `${urlBuilderBudgetLines.forCollection()}${customerId}/budgetlines/`
  );

export const deleteBudgedLineItem = async (
  payload: DeleteBudgetLineItemsPayload
) =>
  await api.delete<BudgetLineItemsResponse>(
    `${urlBuilderBudgetLines.forCollection()}${
      payload.customerId
    }/budgetlines/${payload.budgetLineId}`
  );

export const updateBudgedLockedStatus = async (
  payload: UpdateBuilderBudgetLockedStatusPayload
) => {
  const response = await api.put(
    `${urlBuilderBudgetLines.forCollection()}${payload.customerId}/`,
    { ...payload }
  );
  return response.data;
};

export const deleteBudgedLine = async (
  payload: UpdateBuilderBudgetLockedStatusPayload
) => {
  const response = await api.put(
    `${urlBuilderBudgetLines.forCollection()}${payload.customerId}/`,
    { ...payload }
  );
  return response.data;
};

export type CustomerItemLineQueryOptions = {
  customerId?: string;
  itemId?: number | string;
  orderBy: string;
  limit: number;
  offset: number;
};

export const jobsSimplifiedApi = apiSimplified.injectEndpoints({
  endpoints: (builder) => ({
    batchUpdateCustomerBudgetLinesCategories: builder.mutation<
      void,
      BatchUpdateCustomerBudgetLinesCategoriesPayload
    >({
      query: (body) => {
        const { customerId, categoryId, ...payload } = handleRequest(
          body,
          batchUpdateCustomerBudgetLinesCategoriesPayloadSchema
        );

        return {
          url: `customers/${customerId}/categories/${
            categoryId ? `${categoryId}/budget_lines/` : "clear/"
          }`,
          body: transformKeysToSnakeCase(payload),
          method: "PUT",
        };
      },
      invalidatesTags: ["BudgetLines"],
    }),
    cloneCustomerCategories: builder.mutation<
      void,
      CloneCustomerCategoryPayload
    >({
      query: (body) => {
        const payload = handleRequest(body, cloneCustomerCategoryPayloadSchema);

        return {
          url: `customers/${payload.customerId}/categories/clone/`,
          method: "POST",
          body: transformKeysToSnakeCase(payload),
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error ? [{ type: "Categories", customerId }] : [],
    }),
    deleteCustomerCategory: builder.mutation<
      void,
      DeleteCustomerCategoryPayload
    >({
      query: (body) => {
        const payload = handleRequest(
          body,
          deleteCustomerCategoryPayloadSchema
        );

        return {
          url: `customers/${payload.customerId}/categories/${payload.categoryId}/`,
          params: { switch_to: payload.switchTo },
          method: "DELETE",
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error
          ? [
              { type: "Categories", customerId },
              { type: "Customer", customerId },
              "BudgetLines",
              "CustomerMarkup",
            ]
          : [],
    }),
    storeCustomerCategory: builder.mutation<
      StoreCustomerCategoryResponse,
      StoreCustomerCategoryPayload
    >({
      query: (body) => {
        const payload = handleRequest(body, storeCustomerCategoryPayloadSchema);

        return {
          url: `customers/${payload.customerId}/categories/`,
          method: "POST",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              storeCustomerCategoryResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error ? [{ type: "Categories", customerId }] : [],
    }),
    upsertCustomerCategories: builder.mutation<
      void,
      UpsertCustomerCategoriesPayload
    >({
      queryFn: async (body, { signal }) => {
        const payload: UpsertCustomerCategoriesPayload = handleRequest(
          body,
          upsertCustomerCategoriesPayloadSchema
        );

        const requests = payload.categories.map((category) =>
          category.id
            ? api.put(
                `/api/customers/${payload.customerId}/categories/${category.id}/`,
                { displayName: category.displayName },
                { signal }
              )
            : api.post(
                `/api/customers/${payload.customerId}/categories/`,
                {
                  displayName: category.displayName,
                },
                { signal }
              )
        );

        await Promise.all(requests);

        return { data: undefined };
      },
      invalidatesTags: (_, error, { customerId }) =>
        !error
          ? [
              { type: "Categories", customerId },
              { type: "Customer", customerId },
            ]
          : [],
    }),
    getCustomerCategories: builder.query<
      GetCustomerCategoriesResponse,
      GetCustomerCategoriesPayload
    >({
      query: (args) => {
        const { customerId } = handleRequest(
          args,
          getCustomerCategoriesPayloadSchema
        );

        return {
          url: `customers/${customerId}/categories/`,
          params: { simplified: true },
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              data.map(transformKeysToCamelCase),
              getCustomerCategoriesResponseSchema
            );
          },
        };
      },
      providesTags: (_, __, { customerId }) => [
        { type: "Categories", customerId },
      ],
    }),
    getCustomer: builder.query({
      query: (options: CustomerSchemaGetRequest) => {
        return {
          url: `customers/${options.customerId}/`,
          responseHandler: async (resp) => {
            /*
             * @todo: Add parsing when jobs endpoints are migrated to rtk query
             * https://adaptive-realestate.atlassian.net/browse/BOB-3578
             */
            const data = await resp.json();
            return data;
          },
        };
      },
      providesTags: (_, __, { customerId }) => [
        { type: "Customer", customerId },
      ],
    }),
    getCustomerItemLines: builder.query({
      query: (options: CustomerItemLineQueryOptions) => {
        return {
          url: `customers/${options.customerId}/items/${options.itemId}/lines/`,
          params: {
            limit: options.limit,
            order_by: options.orderBy,
            offset: options.offset,
          },
          responseHandler: async (resp) => {
            const data = await resp.json();
            return data;
          },
        };
      },
    }),
    getCustomerAccountLines: builder.query({
      query: (options) => {
        return {
          url: `customers/${options.customerId}/accounts/${options.accountId}/lines/`,
          params: {
            limit: options.limit,
            order_by: options.orderBy,
            offset: options.offset,
          },
          responseHandler: async (resp) => {
            const data = await resp.json();
            return data;
          },
        };
      },
    }),
    getLinkedInvoices: builder.query<
      LinkedInvoicesResponse,
      LinkedInvoicesPayload
    >({
      query: (args) => {
        return {
          url: `lines/linked_invoices/`,
          method: "POST",
          body: transformKeysToSnakeCase(args),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              linkedInvoicesResponseSchema
            );
          },
        };
      },
      providesTags: ["GetLinkedInvoices"],
    }),
    getDrawnToDate: builder.query<
      GetDrawnToDateResponse,
      GetCustomerDrawnToDatePayload
    >({
      query: (args) => {
        const { customerId } = handleRequest(
          args,
          getCustomerDrawnToDatePayloadSchema
        );
        const params = new URLSearchParams(
          [...getSearchParamsFromFilters(args.filters).entries()].map(
            ([key, value]) => [key === "draw_#" ? "invoices" : key, value]
          )
        ).toString();

        return {
          url: `customers/${customerId}/invoiced-to-date/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              drawnToDateResponseSchema
            );
          },
        };
      },
      providesTags: (_, __, { customerId }) => [
        { type: "DrawnToDate", customerId },
      ],
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomerItemLinesQuery,
  useGetCustomerCategoriesQuery,
  useGetCustomerAccountLinesQuery,
  useStoreCustomerCategoryMutation,
  useDeleteCustomerCategoryMutation,
  useCloneCustomerCategoriesMutation,
  useUpsertCustomerCategoriesMutation,
  useBatchUpdateCustomerBudgetLinesCategoriesMutation,
  useLazyGetLinkedInvoicesQuery,
  useGetDrawnToDateQuery,
} = jobsSimplifiedApi;

export const jobsApi = {
  addBudgedLineItem,
  getBudgedLineItems,
  updateBudgedLockedStatus,
  deleteBudgedLineItem,
};
