import React, { memo, type ReactNode } from "react";
import { Button, Flex, Icon, Text } from "@adaptive/design-system";
import { useResponsiveProp } from "@adaptive/design-system/hooks";
import { Attachable, type AttachableProps } from "@components/attachable";
import { BrandingLogo } from "@components/branding-logo";
import { Main, MainContent, MainHeader } from "@components/main";
import { TransactionSplitView } from "@components/transaction-split-view";

import { adaptiveUrl, STRINGS } from "./constants";

export type ExternalLayoutProps = {
  children: ReactNode;
  attachable?: AttachableProps["attachable"];
  snapToggleId?: string;
};

export const ExternalLayout = memo(
  ({ attachable, children, snapToggleId }: ExternalLayoutProps) => {
    const isMobile = useResponsiveProp({ mobile: true, tablet: false }, false);

    return (
      <Main style={attachable ? { height: "100vh" } : {}}>
        <MainHeader
          size={{ mobile: "xs", tablet: "sm" }}
          style={{ position: "sticky", top: 0 }}
        >
          <Flex wrap gap="md" justify="space-between">
            <BrandingLogo />
            <Flex gap="2xl" wrap align="center">
              {!isMobile && <Text size="sm">{STRINGS.HEADLINE}</Text>}
              <Button
                size="sm"
                as="a"
                variant="text"
                href={adaptiveUrl}
                target="_blank"
                data-skip-focusable=""
              >
                {STRINGS.HEADLINE_ACTION}
              </Button>
            </Flex>
          </Flex>
        </MainHeader>
        <MainContent scrollable={false} variant="unspaced">
          {attachable ? (
            <TransactionSplitView
              snapToggleId={snapToggleId}
              left={
                <Flex
                  width="full"
                  align="center"
                  height="full"
                  padding="3xl"
                  justify="center"
                >
                  <Attachable
                    attachable={attachable}
                    action={
                      snapToggleId && isMobile ? (
                        <Button
                          as="label"
                          size="sm"
                          color="neutral"
                          variant="ghost"
                          htmlFor={snapToggleId}
                        >
                          <Icon name="x" />
                        </Button>
                      ) : undefined
                    }
                  />
                </Flex>
              }
              right={children}
            />
          ) : (
            children
          )}
        </MainContent>
      </Main>
    );
  }
);

ExternalLayout.displayName = "ExternalLayout";
