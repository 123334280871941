import { is } from "../is";

export const hasModifierKey = (event: KeyboardEvent): boolean => {
  return (
    is.true(event.metaKey) ||
    is.true(event.ctrlKey) ||
    is.true(event.shiftKey) ||
    is.true(event.altKey)
  );
};
