import React from "react";
import {
  type CreateDialogOptions,
  dialog,
  Flex,
  Text,
} from "@adaptive/design-system";

export const confirmSyncLinkedCost = ({
  action,
}: Pick<CreateDialogOptions, "action">) => {
  dialog.confirmation({
    title:
      "You made edits to a line item that is linked to an underlying cost.",
    message: (
      <Flex direction="column" gap="sm">
        <Text align="center">
          By default, edits to these line items only affect the invoice.
        </Text>
        <Text align="center">
          Would you also like these edits to update the cost?
        </Text>
      </Flex>
    ),
    action,
  });
};
