const loadedFonts = new Set();

export const loadFont = ({ family, url }: { family: string; url: string }) => {
  if (loadedFonts.has(family)) return Promise.resolve();

  const font = new FontFace(family, `url(${url})`);

  return font.load().then((loadedFace) => {
    loadedFonts.add(family);
    document.fonts.add(loadedFace);
  });
};
