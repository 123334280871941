import React, { type ComponentProps, useState } from "react";
import {
  Button,
  ButtonField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  SignatureField,
  type SignatureSavedValue,
} from "@components/signature-field/signature-field";

import { STRINGS } from "./constants";

type SignatureFieldProps = {
  placeholder?: string;
  signedPlaceholder?: string;
  disabled?: boolean;
} & ComponentProps<typeof SignatureField>;

export const SignatureFieldButton = ({
  height = "200px",
  onChange,
  placeholder = STRINGS.PLACEHOLDER,
  signedPlaceholder = STRINGS.SIGNED_PLACEHOLDER,
  disabled,
}: SignatureFieldProps) => {
  const dialog = useDialog({ lazy: true });
  const [localValue, setLocalValue] = useState<SignatureSavedValue>({});
  const [savedValue, setSavedValue] = useState<SignatureSavedValue>({});

  const haveValue = !!savedValue.drawSignature || !!savedValue.typeSignature;

  const onCancel = useEvent(() => {
    setLocalValue(savedValue);
    dialog.hide();
  });

  const onSave = useEvent(() => {
    setSavedValue(localValue);
    onChange?.(localValue);
    dialog.hide();
  });

  return (
    <>
      <Flex width="full" maxWidth="430px">
        <ButtonField
          required
          label={STRINGS.SIGNATURE_LABEL}
          onClick={dialog.show}
          disabled={dialog.isRendered || disabled}
          placeholder={placeholder}
          value={haveValue ? signedPlaceholder : undefined}
          suffix={<Icon name="pen" color="neutral-500" />}
          loading={dialog.isRendered}
          messageVariant="absolute"
        />
      </Flex>

      {dialog.isRendered && (
        <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
          <DialogHeader>{STRINGS.ADD_YOUR_SIGNATURE}</DialogHeader>

          <DialogContent>
            <SignatureField
              onChange={setLocalValue}
              height={height}
              initialValue={savedValue}
            />
          </DialogContent>

          <DialogFooter>
            <Button
              block
              variant="text"
              color="neutral"
              type="button"
              onClick={onCancel}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button block type="button" onClick={onSave}>
              {STRINGS.SAVE}
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
