import { createContext, useContextSelector } from "use-context-selector";

import { noop } from "../utils/noop";

import type { IStageContext } from "./types";

export const StageContext = createContext<IStageContext<any> | undefined>(
  undefined
);

export const useStageName = () =>
  useContextSelector(StageContext, (ctx) => ctx?.stage.name);

export const useStageStages = <T>() =>
  useContextSelector(StageContext, (ctx) => (ctx as IStageContext<T>).stages);

export const useStageIndex = () =>
  useContextSelector(StageContext, (ctx) => ctx?.stage.index);

export const useStageCompleted = () =>
  useContextSelector(StageContext, (ctx) => ctx?.completed);

export const useStageActions = () =>
  useContextSelector(StageContext, (ctx) => ({
    recede: ctx?.recede ?? noop,
    advance: ctx?.advance ?? noop,
    setStage: ctx?.setStage ?? noop,
  }));
