import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  dialog,
  Flex,
  Switch,
  Text,
  toast,
} from "@adaptive/design-system";
import { parseStringCopy } from "@adaptive/design-system/utils";
import { PDF_BASE64_TYPE } from "@components/pdf-viewer";
import { TransactionSplitView } from "@components/transaction-split-view";
import { handleErrors } from "@shared/api/handle-errors";
import {
  Main,
  MainContent,
  MainFooter,
  MainHeader,
  MainHeaderBack,
  MainTitle,
} from "@shared/components/main";
import { NotFound } from "@shared/components/not-found";
import * as analytics from "@utils/analytics";

import {
  useDeleteLienWaiverTemplateMutation,
  useRenderLienWaiverPdfMutation,
  useSaveLienWaiverTemplateMutation,
} from "../api/api";
import { lienWaiverTemplateFormSchema } from "../api/schemas";
import { STRINGS } from "../constants/constants";
import { FormProvider, useForm } from "../hooks/use-template-form";
import { useTemplateInitialValues } from "../hooks/use-template-initial-values";
import { parseSignatureFields } from "../utils/parse-signature-fields";
import {
  lienWaiverTemplateTypesSelector,
  signatureFieldsSelector,
} from "../utils/selectors";

import { TemplateForm } from "./template-form";
import { TemplatePreview } from "./template-preview";

export const TemplatePage = () => {
  const navigate = useNavigate();

  const navigateToSettings = () => navigate("/settings/company/general");
  const returnBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigateToSettings();
    }
  };

  const { id } = useParams();
  const isNewTemplate = id === "create";

  const {
    initialValues,
    isNotFound,
    isLoading: isInitialValuesLoading,
  } = useTemplateInitialValues(id);

  const [saveLienWaiverTemplateTrigger] = useSaveLienWaiverTemplateMutation();

  const [deleteLienWaiverTemplateTrigger, deleteLienWaiverMutationInfo] =
    useDeleteLienWaiverTemplateMutation();

  const signatureFields = useSelector(signatureFieldsSelector);
  const lienWaiverTemplateTypes = useSelector(lienWaiverTemplateTypesSelector);

  const [
    renderLienWaiverPDF,
    { data: lienWaiverPDFData, isLoading: isLienWaiverPDFLoading },
  ] = useRenderLienWaiverPdfMutation();

  const form = useForm({
    schema: lienWaiverTemplateFormSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          ...values,
          signatureFields: parseSignatureFields.toPayload(
            signatureFields,
            values.signatureFields
          ),
        };

        const typeName =
          lienWaiverTemplateTypes.find((type) => type.url === values.type)
            ?.name || "Lien Waiver Template";

        analytics.track("saveLienWaiverTemplate", { templateType: typeName });
        await saveLienWaiverTemplateTrigger(payload).unwrap();

        toast.success(
          isNewTemplate ? (
            parseStringCopy(STRINGS.TEMPLATE_CREATE_TITLE, {
              template: typeName,
            })
          ) : (
            <Flex direction="column">
              <Text as="strong" weight="bold">
                {parseStringCopy(STRINGS.TEMPLATE_UPDATED_TITLE, {
                  template: typeName,
                })}
              </Text>
              <Text as="span">{STRINGS.TEMPLATE_UPDATED_MESSAGE}</Text>
            </Flex>
          )
        );
        navigateToSettings();
      } catch (error) {
        handleErrors(error);
      }
    },
    initialValues,
  });

  const isLoading =
    form.isSubmitting ||
    isInitialValuesLoading ||
    deleteLienWaiverMutationInfo.isLoading;

  const { reset } = form;
  useEffect(() => {
    reset();
  }, [reset]);

  const onClose = () => {
    returnBack();
  };

  const refreshPreview = useCallback(() => {
    renderLienWaiverPDF({
      htmlContent: form.values.contentHtml,
      signatureFields: parseSignatureFields.toPDFPayload(
        signatureFields,
        form.values.signatureFields
      ),
    });
  }, [
    form.values.contentHtml,
    form.values.signatureFields,
    renderLienWaiverPDF,
    signatureFields,
  ]);

  const getInitialPdf = useCallback(() => {
    if (
      !isNewTemplate &&
      form.values.contentHtml &&
      signatureFields.length &&
      !lienWaiverPDFData
    ) {
      refreshPreview();
    }
  }, [
    form.values.contentHtml,
    isNewTemplate,
    lienWaiverPDFData,
    refreshPreview,
    signatureFields.length,
  ]);

  useEffect(() => getInitialPdf(), [getInitialPdf]);

  if (isNotFound) {
    return <NotFound />;
  }

  const onCloseConfirmation = () => {
    if (form.isDirty) {
      return dialog.confirmation({
        title: STRINGS.CLOSE_DIALOG_TITLE,
        message: STRINGS.CLOSE_DIALOG_MESSAGE,
        action: {
          primary: { color: "primary", children: STRINGS.CLOSE_DIALOG_STAY },
          secondary: { onClick: onClose, children: STRINGS.CLOSE_DIALOG_LEAVE },
        },
      });
    }

    onClose();
  };

  const onDelete = () => {
    const typeName =
      lienWaiverTemplateTypes.find((type) => type.url === form.values.type)
        ?.name || "Lien Waiver Template";
    dialog.confirmation({
      title: STRINGS.DELETE_DIALOG_TITLE,
      message: STRINGS.DELETE_DIALOG_MESSAGE,
      action: {
        primary: {
          onClick: async () => {
            analytics.track("deleteLienWaiverTemplate", {
              templateType: typeName,
            });
            await deleteLienWaiverTemplateTrigger({ id: id! });
            toast.success(
              <Flex as="span" direction="column">
                <Text as="strong" weight="bold">
                  {parseStringCopy(STRINGS.TEMPLATE_DELETED_TITLE, {
                    template: typeName,
                  })}
                </Text>
                <Text as="span">{STRINGS.TEMPLATE_DELETED_MESSAGE}</Text>
              </Flex>
            );
            navigateToSettings();
          },
          color: "error",
          children: STRINGS.DELETE_DIALOG_CONFIRM,
        },
      },
    });
  };

  return (
    <FormProvider form={form}>
      <Main as="form" {...form.props}>
        <MainHeader variant="unspaced">
          <Flex gap="xl" height="full">
            <Flex gap="xl" width="full" align="center" grow>
              <MainHeaderBack
                onClick={onCloseConfirmation}
                data-testid="back-button"
              />
              <MainTitle>
                {isNewTemplate
                  ? STRINGS.CREATE_LIEN_WAIVER_TEMPLATE
                  : STRINGS.EDIT_LIEN_WAIVER_TEMPLATE}
              </MainTitle>
            </Flex>
            <Flex
              shrink={false}
              gap="xl"
              align="center"
              padding={["none", "2xl"]}
            >
              <Switch
                hintMessage={STRINGS.DEFAULT_TEMPLATE_HINT}
                label={STRINGS.DEFAULT_TEMPLATE}
                placement="left"
                disabled={isLoading}
                {...form.register({ name: "isDefault", type: "boolean" })}
              />
            </Flex>
          </Flex>
        </MainHeader>

        <MainContent variant="unspaced" scrollable={false}>
          <Flex width="full" height="full">
            <TransactionSplitView
              left={
                <TemplatePreview
                  onRefresh={refreshPreview}
                  docType={PDF_BASE64_TYPE}
                  docSource={lienWaiverPDFData?.pdf}
                  emptyMessage={STRINGS.TEMPLATE_DETAILS_EMPTY_CONTENT}
                  loading={isInitialValuesLoading || isLienWaiverPDFLoading}
                  disableRefresh={!form.values.contentHtml || isLoading}
                />
              }
              right={
                <Flex direction="column" height="full">
                  <TemplateForm loading={isLoading} />
                  <MainFooter>
                    <Flex width="full">
                      <Flex width="full" grow align="center">
                        <Button
                          variant="text"
                          color="neutral"
                          disabled={isLoading}
                          onClick={onCloseConfirmation}
                        >
                          {STRINGS.CANCEL}
                        </Button>
                        {!isNewTemplate && (
                          <Button
                            variant="text"
                            color="error"
                            disabled={isLoading}
                            onClick={onDelete}
                          >
                            {STRINGS.DELETE}
                          </Button>
                        )}
                      </Flex>
                      <Button
                        variant="solid"
                        color="primary"
                        type="submit"
                        form={form.id}
                        disabled={isLoading}
                      >
                        {STRINGS.SAVE_TEMPLATE}
                      </Button>
                    </Flex>
                  </MainFooter>
                </Flex>
              }
            />
          </Flex>
        </MainContent>
      </Main>
    </FormProvider>
  );
};

TemplatePage.displayName = "LienWaiver";
