import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetCardTransactionsSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

type UseCardTransactionsSimplifiedParams = {
  filters?: { is_linked_to_expense?: boolean };
};

export const useCardTransactionsSimplified = ({
  filters,
}: UseCardTransactionsSimplifiedParams) => {
  const { realmId } = useClientInfo();

  const params = { ...filters, realm: realmId as number };

  const query = useGetCardTransactionsSimplifiedQuery(params);

  return useAsSimplifiedData(query);
};
