import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertContent,
  Button,
  Flex,
  TextField,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { PasswordRulesAlert } from "@components/password-rules-alert";
import { resetPassword } from "@shared/api/auth";
import { generatePasswordSchema } from "@utils/schema";
import { z } from "zod";

import { Layout } from "../../../shared/components/layout";

const schema = z
  .object({
    password: generatePasswordSchema("Your password doesn't follow the rules"),
    confirm: generatePasswordSchema(
      "Your confirm password doesn't follow the rules"
    ),
  })
  .refine((values) => values.password === values.confirm, {
    message: "These passwords don't match, try again",
    path: ["confirm"],
  });

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = {
  password: "",
  confirm: "",
};

const LOGIN_URL = "/accounts/login";

export const ResetPassword = () => {
  const [errors, setErrors] = useState<string[] | string[][]>([]);
  const hasErrors = errors.length > 0;

  const navigate = useNavigate();

  const { uid = "", token = "" } = useParams();

  const form = useForm<Fields>({
    onSubmit(values) {
      return resetPassword({ password: values.password, token, uid })
        .then(() => {
          navigate(LOGIN_URL);
        })
        .catch((response) => {
          const newErrors = [];

          if (response.response.data.uid) {
            newErrors.push(response.response.data.uid);
          }

          if (response.response.data.token) {
            newErrors.push(response.response.data.token);
          }

          if (response.response.data.new_password) {
            newErrors.push(response.response.data.new_password);
          }

          setErrors(newErrors);
        });
    },
    schema,
    initialValues: INITIAL_VALUES,
  });

  useEffect(() => {
    setErrors([]);
  }, [form.values]);

  return (
    <Layout title="Enter a new password" backUrl={LOGIN_URL} {...form.props}>
      <Flex gap="xl" direction="column">
        <Flex direction="column">
          <TextField
            label="New password"
            type="password"
            required
            autoFocus
            placeholder="Enter your password"
            {...form.register("password")}
          />
          <TextField
            label="Reenter password"
            type="password"
            required
            placeholder="Enter your password"
            {...form.register("confirm")}
          />

          <Flex gap="xl" direction="column">
            <PasswordRulesAlert />

            {hasErrors && (
              <Alert variant="error">
                <AlertContent as="ul">
                  {errors.map((error) =>
                    Array.isArray(error) ? (
                      error.map((innerError) => (
                        <li key={innerError}>{innerError}</li>
                      ))
                    ) : (
                      <li key={error}>{error}</li>
                    )
                  )}
                </AlertContent>
              </Alert>
            )}
          </Flex>
        </Flex>

        <Button
          data-testid="reset-password"
          type="submit"
          disabled={form.isSubmitting || !form.isValid || hasErrors}
          block
        >
          Save
        </Button>
      </Flex>
    </Layout>
  );
};
