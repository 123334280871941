import { useEffect } from "react";

import { useEvent } from "../use-event";

export const useVisibilityChange = (onChange: (isVisible: boolean) => void) => {
  const enhanceOnChange = useEvent(() => {
    onChange(document.visibilityState === "visible");
  });

  useEffect(() => {
    window.addEventListener("visibilitychange", enhanceOnChange);

    return () => {
      window.removeEventListener("visibilitychange", enhanceOnChange);
    };
  }, [enhanceOnChange]);
};
