import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  dialog,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Loader,
  TableConfigurationButton,
  TabsList,
  TabsTab,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import {
  useDeleteBudgetLineMutation,
  useGetBudgetLinesQuery,
} from "@api/budgets";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { invoicesApi, useDeleteInvoiceMutation } from "@api/invoices";
import type { BudgetLineItems, StoreCustomerCategoryResponse } from "@api/jobs";
import {
  exportJob,
  useBatchUpdateCustomerBudgetLinesCategoriesMutation,
} from "@api/jobs/jobs";
import { useUpdateQuickBooksErrorsMutation } from "@api/quickbooks";
import { AddChangeButton } from "@components/add-change-button";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import { MainHeader, MainHeaderBack, MainTitle } from "@components/main";
import { captureMessage } from "@sentry/react";
import type { Option } from "@shared/types";
import {
  INVOICE_STRINGS,
  useJobActions,
  useJobBudgetSelectedLines,
  useJobInvoiceIsLoading,
  useJobInvoiceSelectedDraws,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import {
  BasePermissions,
  useClientInfo,
  useClientSettings,
  useUserInfo,
} from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";

import { BudgetsCategoriesCombobox } from "./detail-view/budget/budgets-categories-combobox";
import {
  type ChangeDialogStep,
  ChangesDialog,
} from "./detail-view/budget/changes-dialog";
import { CalculateMarkupDialog } from "./detail-view/manage-markup-dialog";

const BATCH_ERROR_MESSAGES: Record<string, string> = {
  "Cannot delete budget line that has an amount spent against it":
    "The following lines cannot be deleted because they have spent costs:",
  "Cannot delete budget line that has an amount invoiced against it":
    "The following lines cannot be deleted because they have drawn costs:",
  "Cannot delete budget line that has a changes applied":
    "The following lines cannot be deleted because they have changes applied:",
};

const UNCATEGORIZED_OPTION = {
  label: "Clear category",
  value: Symbol("UNCATEGORIZED").toString(),
};

const INITIAL_TYPE_V2 = {
  draw_schedule_pdf: true,
  draw_schedule: true,
  draw_schedule_by_category: false,
  draw_schedule_changes: true,
  draw_schedule_transaction_backup: false,
  draw_schedule_xlsx: true,
  draw_schedule_transaction_zip: false,
};

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

const Actions = () => {
  const { job } = useJobInfo();

  const [searchParams] = useSearchParams();

  const invoiceIsLoading = useJobInvoiceIsLoading();

  const { invoiceCreate } = useJobActions();

  const currentTab = searchParams.get("status") ?? "budget";

  const dispatch = useDispatch();

  const addChangeDialog = useMultiStepDialog<ChangeDialogStep>({
    lazy: true,
    initialStep: "create-change",
  });

  const calculateMarkupDialog = useDialog({ lazy: true });

  const onShowCalculateMarkupDialog = useEvent(() => {
    calculateMarkupDialog.show();
    analytics.track("budgetBatchActions", {
      action: "show-calculate-markup-dialog",
      budgetLineIds: budgetSelectedLines.map((line) => line.id),
    });
  });

  const { realmId, client } = useClientInfo();

  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;

  const { canManage } = useJobPermissions();

  const [deleteInvoice] = useDeleteInvoiceMutation();

  const [deleteBudgetLine] = useDeleteBudgetLineMutation();

  const budgetSelectedLines = useJobBudgetSelectedLines(true);

  const invoiceSelectedDraws = useJobInvoiceSelectedDraws();

  const { categoriesEnabled, ownersAmountEnabled, changeTrackingEnabled } =
    useJobSettings();

  const { drawExportsV2Enabled } = useClientSettings();

  const [updateQuickBooksErrorsMutation] = useUpdateQuickBooksErrorsMutation();

  const { setBudgetSelectedLines, setInvoiceSelectedDraws } = useJobActions();

  const [batchUpdateCustomerBudgetLinesCategories] =
    useBatchUpdateCustomerBudgetLinesCategoriesMutation();

  const onBudgetDownload = useEvent<OnDownloadHandler>(async ({ params }) => {
    if (realmId) params.append("realm", String(realmId));

    return exportJob({ id: job.id, params });
  });

  const {
    data: budgetLines = EMPTY_BUDGET_LINES,
    isSuccess: isBudgetLinesSuccess,
  } = useGetBudgetLinesQuery({
    customerId: job.id,
  });

  const extraCategories = useMemo(() => [UNCATEGORIZED_OPTION], []);

  /**
   * @todo we need to remove this try catch as soon as we figure out the real issue behind `jobCostMethod.url` usage https://adaptive-real-estate.sentry.io/issues/4494809495/?environment=production&project=4503937907687424&query=is%3Aunresolved+level%3A%5Bfatal%2Cerror%5D+error.handled%3Afalse&referrer=issue-stream&stream_index=0
   */
  const jobCostMethodUrls = useMemo(() => {
    try {
      return budgetSelectedLines.map((line) => line.jobCostMethod.url);
    } catch (e) {
      captureMessage(`Error getting job cost method urls on job: ${e}`);
      return [];
    }
  }, [budgetSelectedLines]);

  const onDeleteLines = useEvent(async () => {
    const handler = async () => {
      const requests = budgetSelectedLines.map(async (line) => {
        try {
          await deleteBudgetLine({
            customerId: job.id,
            budgetLineId: line.id,
          }).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { jobCostMethodName: line.jobCostMethod.displayName },
            render: (message) => BATCH_ERROR_MESSAGES[message] ?? `${message}:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ jobCostMethodName: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, jobCostMethodName }) =>
          `${message}${isFirst ? "" : ","} ${jobCostMethodName}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(
          `${success} budget line${success > 1 ? "s" : ""} deleted!`
        );
      }

      analytics.track("budgetBatchActions", {
        action: "delete",
        budgetLineIds: budgetSelectedLines.map((line) => line.id),
      });

      setBudgetSelectedLines([]);
    };

    dialog.confirmation({
      title: `Delete ${budgetSelectedLines.length} budget line${
        budgetSelectedLines.length > 1 ? "s" : ""
      }?`,
      action: {
        primary: { color: "error", onClick: handler, children: "Delete" },
      },
    });
  });

  const isDisabledSyncErrors = invoiceSelectedDraws.some(
    (item) =>
      item.errors?.length === 0 || item.errors?.some((error) => error.isIgnored)
  );

  const onIgnoreSyncErrors = useEvent(async () => {
    const ids = invoiceSelectedDraws
      .map((item) => item.errors.map((error) => error.id))
      .flat();

    try {
      await updateQuickBooksErrorsMutation({ ids, isIgnored: true }).unwrap();
      dispatch(invoicesApi.util.invalidateTags(["Invoices"]));
      analytics.track("invoiceBatchActions", {
        action: "ignore-sync-errors",
        invoiceIds: invoiceSelectedDraws.map((draw) => draw.id),
      });
      toast.success(
        `${ids.length} Draw${
          ids.length > 1 ? "s" : ""
        } with sync errors ignored!`
      );
      setInvoiceSelectedDraws([]);
    } catch (e) {
      handleErrors(e);
    }
  });

  const onDeleteDraws = useEvent(() => {
    const handler = async () => {
      const requests = invoiceSelectedDraws.map(async (draw) => {
        try {
          await deleteInvoice({ id: draw.id }).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { docNumber: draw.docNumber },
            render: (message) => `${message} on the following draws:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ docNumber: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, docNumber }) =>
          `${message}${isFirst ? "" : ","} #${docNumber}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(`${success} draw${success > 1 ? "s" : ""} deleted!`);
      }

      analytics.track("invoiceBatchActions", {
        action: "delete",
        invoiceIds: invoiceSelectedDraws.map((draw) => draw.id),
      });

      setInvoiceSelectedDraws([]);
    };

    dialog.confirmation({
      title: `Delete ${invoiceSelectedDraws.length} draw${
        invoiceSelectedDraws.length > 1 ? "s" : ""
      }?`,
      action: {
        primary: { color: "error", onClick: handler, children: "Delete" },
      },
    });
  });

  const onAddCategory = useEvent(
    async (category: StoreCustomerCategoryResponse) => {
      try {
        await batchUpdateCustomerBudgetLinesCategories({
          customerId: job.id,
          categoryId: category.id,
          budgetLines: budgetSelectedLines.map((line) => line.id),
        }).unwrap();

        toast.success(
          `${budgetSelectedLines.length} line${
            budgetSelectedLines.length > 1 ? "s" : ""
          } categorized as ${category.displayName}!`
        );
      } catch (e) {
        handleErrors(e);
      }
    }
  );
  const onAddChange = useEvent(() => {
    addChangeDialog.show();
  });

  const onChangeCategory = useEvent(async (_, option?: Option) => {
    if (!option) return;

    try {
      await batchUpdateCustomerBudgetLinesCategories({
        customerId: job.id,
        categoryId:
          option.value === UNCATEGORIZED_OPTION.value ? null : option.value,
        budgetLines: budgetSelectedLines.map((line) => line.id),
      }).unwrap();

      analytics.track("budgetBatchActions", {
        value: option.value,
        action: "category",
        budgetLineIds: budgetSelectedLines.map((line) => line.id),
      });

      toast.success(
        `${budgetSelectedLines.length} line${
          budgetSelectedLines.length > 1 ? "s" : ""
        } categorized as ${option.label}!`
      );
    } catch (e) {
      handleErrors(e);
    }
  });

  useEffect(() => {
    return () => {
      setBudgetSelectedLines([]);
      setInvoiceSelectedDraws([]);
    };
  }, [setBudgetSelectedLines, setInvoiceSelectedDraws]);

  if (currentTab === "budget") {
    return (
      <Flex grow justify="flex-end">
        {addChangeDialog.isRendered && (
          <ChangesDialog
            mode="create"
            dialog={addChangeDialog}
            jobCostMethods={jobCostMethodUrls}
            onSubmitChange={addChangeDialog.hide}
          />
        )}
        {calculateMarkupDialog.isRendered && (
          <CalculateMarkupDialog
            dialog={calculateMarkupDialog}
            budgetLines={budgetSelectedLines}
          />
        )}
        {isBudgetLinesSuccess && budgetLines.length > 0 && (
          <Flex gap="md">
            {clientChangeTrackingEnabled && (
              <AddChangeButton jobCostMethods={jobCostMethodUrls} size="md" />
            )}
            <TableConfigurationButton />
            {drawExportsV2Enabled && !drawExportsV2Enabled ? (
              <DownloadButton
                mode={{
                  all: { enabled: true, children: "Download" },
                  selection: { enabled: false },
                }}
                withDate
                onDownload={onBudgetDownload}
                data-testid="budget"
                size="md"
                initialType={INITIAL_TYPE_V2}
                type={[
                  {
                    label: "Draw schedule (PDF)",
                    value: "draw_schedule_pdf",
                    data: [
                      {
                        label: "Draw schedule",
                        value: "draw_schedule",
                      },
                      {
                        label: "Draw schedule (by category)",
                        value: "draw_schedule_by_category",
                      },
                      {
                        label: "Changes",
                        value: "draw_schedule_changes",
                      },
                      {
                        label: "Transaction backup",
                        value: "draw_schedule_transaction_backup",
                      },
                    ],
                  },
                  {
                    label: "Draw schedule (XLSX)",
                    value: "draw_schedule_xlsx",
                  },
                  {
                    label: "Transaction backup (ZIP)",
                    value: "draw_schedule_transaction_zip",
                  },
                ]}
              />
            ) : (
              <DownloadButton
                mode={{
                  all: { enabled: true, children: "Download" },
                  selection: { enabled: false },
                }}
                size="md"
                withDate
                onDownload={onBudgetDownload}
                data-testid="budget"
                type={[
                  {
                    label: "Budget + transactions (XLSX)",
                    value: "export_xlsx",
                  },
                  {
                    label: "Transaction backup (PDF)",
                    value: "export_pdf",
                  },
                  {
                    label: "Transaction backup (ZIP)",
                    value: "export_zip",
                  },
                ]}
              />
            )}
            {budgetSelectedLines.length > 0 ? (
              categoriesEnabled ||
              changeTrackingEnabled ||
              ownersAmountEnabled ? (
                <Dropdown placement="bottom-end">
                  <DropdownTrigger
                    as={Button}
                    data-testid="budgets-actions-trigger"
                  >
                    Actions
                    <Icon name="ellipsis-vertical" variant="solid" />
                  </DropdownTrigger>
                  <DropdownList>
                    <Tooltip
                      message={
                        !canManage ? "You don't have permission to do this" : ""
                      }
                      placement="left"
                    >
                      <DropdownItem
                        color="error"
                        disabled={!canManage}
                        onClick={onDeleteLines}
                      >
                        Delete lines
                      </DropdownItem>
                    </Tooltip>
                    {ownersAmountEnabled && (
                      <>
                        <Tooltip
                          message={
                            !canManage
                              ? "You don't have permission to do this"
                              : !ownersAmountEnabled
                                ? "Enable external budget in the job settings to add markup"
                                : ""
                          }
                          placement="left"
                        >
                          <DropdownItem
                            disabled={!canManage || !ownersAmountEnabled}
                            onClick={onShowCalculateMarkupDialog}
                          >
                            Calculate external budget
                          </DropdownItem>
                        </Tooltip>
                      </>
                    )}
                    {changeTrackingEnabled && (
                      <Tooltip
                        message={
                          !canManage
                            ? "You don't have permission to do this"
                            : !changeTrackingEnabled
                              ? "Enable change tracking in the job settings to add change"
                              : ""
                        }
                        placement="left"
                      >
                        <DropdownItem
                          disabled={!canManage || !changeTrackingEnabled}
                          onClick={onAddChange}
                        >
                          Add a change
                        </DropdownItem>
                      </Tooltip>
                    )}
                    {categoriesEnabled && (
                      <DropdownItem>
                        <BudgetsCategoriesCombobox
                          value=""
                          extra={extraCategories}
                          portal
                          onChange={onChangeCategory}
                          onAddCategory={onAddCategory}
                        />
                      </DropdownItem>
                    )}
                  </DropdownList>
                </Dropdown>
              ) : (
                <Tooltip
                  message={
                    !canManage ? "You don't have permission to do this" : ""
                  }
                  placement="left"
                >
                  <Button
                    color="error"
                    onClick={onDeleteLines}
                    disabled={!canManage}
                  >
                    Delete lines
                  </Button>
                </Tooltip>
              )
            ) : null}
          </Flex>
        )}
      </Flex>
    );
  }

  if (currentTab === "invoice") {
    return (
      <Flex grow gap="md" justify="flex-end">
        <TableConfigurationButton />
        {invoiceSelectedDraws.length > 0 && (
          <Dropdown>
            <DropdownTrigger as={Button} data-testid="invoices-actions-trigger">
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              <Tooltip
                message={
                  isDisabledSyncErrors
                    ? "You can only ignore sync errors on draws that have sync errors"
                    : ""
                }
                placement="left"
              >
                <DropdownItem
                  onClick={onIgnoreSyncErrors}
                  disabled={isDisabledSyncErrors}
                  data-testid="ignore-sync-errors"
                >
                  Ignore sync errors
                </DropdownItem>
              </Tooltip>
              <DropdownItem
                color="error"
                onClick={onDeleteDraws}
                data-testid="delete-draws"
              >
                Delete draws
              </DropdownItem>
            </DropdownList>
          </Dropdown>
        )}
        <Tooltip
          message={!canManage ? "You don't have permission to do this" : ""}
          placement="left"
        >
          <Button
            onClick={() => {
              invoiceCreate();
              analytics.track("invoiceCreate", { customerId: job.id });
            }}
            disabled={!canManage || invoiceIsLoading}
          >
            {INVOICE_STRINGS.NEW_INVOICE}
          </Button>
        </Tooltip>
      </Flex>
    );
  }

  return null;
};

export const HeadingDetail = () => {
  const { job, status } = useJobInfo();

  const navigate = useNavigate();

  const jobIsLoading = status !== "loaded";

  const { hasPermission } = useUserInfo();

  const canViewAllInvoices = hasPermission(BasePermissions.VIEW_ALL_INVOICES);

  const { state: locationState } = useLocation();

  const onClose = useEvent(() =>
    navigate(dotObject.get(locationState as object, "prev", "/jobs"))
  );

  return (
    <>
      <MainHeader variant="unspaced" size="lg">
        <Flex align="center" justify="space-between" gap="xl" height="full">
          <Flex direction="column" height="full">
            <Flex gap="xl" align="center" height="full">
              <Flex gap="xl" width="full" align="center" height="full">
                <MainHeaderBack onClick={onClose} />
                <MainTitle truncate data-testid="job-display-name">
                  {jobIsLoading ? (
                    <Flex>
                      <Loader />
                    </Flex>
                  ) : (
                    job.display_name
                  )}
                </MainTitle>
              </Flex>
            </Flex>
            <Flex padding={["none", "none", "none", "92px"]} shrink={false}>
              <TabsList>
                <TabsTab value="budget" disabled={jobIsLoading}>
                  Budgets
                </TabsTab>
                {canViewAllInvoices && (
                  <TabsTab value="invoice" disabled={jobIsLoading}>
                    {INVOICE_STRINGS.INVOICES}
                  </TabsTab>
                )}
                <TabsTab value="settings" disabled={jobIsLoading}>
                  Settings
                </TabsTab>
              </TabsList>
            </Flex>
          </Flex>
          <Flex
            shrink={false}
            padding={["none", "5xl", "none", "none"]}
            gap="xl"
          >
            {!jobIsLoading && <Actions />}
          </Flex>
        </Flex>
      </MainHeader>
    </>
  );
};
