import React, { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@adaptive/design-system";

import { AmountForm } from "./amount-form";
import type { ManageMarkupDialogProps } from "./types";

const FORM_ID = "invoice-edit-fixed-markup";

export const InvoiceEditFixedMarkupDialog = ({
  dialog,
  markup,
}: ManageMarkupDialogProps) => {
  const [isAmountFormValid, setIsAmountFormValid] = useState<boolean>(false);

  const onClose = () => {
    dialog.hide();
  };

  const initialValues = useMemo(
    () => ({
      value: markup?.amount ?? 0,
      costCode: markup?.jobCostMethod?.url ?? "",
    }),
    [markup]
  );

  return (
    <Dialog
      show={dialog.isVisible}
      size="md"
      onClose={onClose}
      variant="dialog"
    >
      <DialogHeader>Edit draw markup</DialogHeader>
      <DialogContent>
        <AmountForm
          formId={FORM_ID}
          markup={markup}
          onClose={onClose}
          onFormChange={setIsAmountFormValid}
          initialValues={initialValues}
        />
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          block
          type="submit"
          form={FORM_ID}
          disabled={!isAmountFormValid}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
