export const getInitials = (str: string) => {
  const [head, tail] = str.split(" ");

  return (
    head && tail
      ? `${head.charAt(0)}${tail.charAt(0)}`
      : head.length > 1
        ? `${head.charAt(0)}${head.charAt(1)}`
        : `${head.charAt(0)}`
  ).toUpperCase();
};
