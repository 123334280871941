import { isAuthorized } from "@api/auth";
import { logout } from "@api/auth";
import { getClients } from "@api/clients";
import { getUser } from "@api/users";
import { captureException } from "@sentry/browser";
import * as analytics from "@utils/analytics";

import { getBrowserInfo } from "../utils/get-browser-info";

import { getCurrentClient, updateClients, updateUser } from "./user/slice";
import { store } from "./index";
import { loadRealm } from "./realmSlice";

const loadUser = () =>
  getUser().then((user) => {
    if (!user) return Promise.reject("Failed retrieving User Data");

    store.dispatch(updateUser(user));
    return user;
  });

const loadClients = () =>
  getClients().then(async (clients) => {
    if (!clients || !clients.length) {
      await logout();
      window.location.href = "accounts/login?next=/404";
    }
    store.dispatch(updateClients(clients));
    return clients;
  });

export const initializeState = async () => {
  const resolveClientRealmId = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return store.getState()?.user?.currentClient?.realm.id;
  };

  const loadInitialData = async () => {
    const realmId = resolveClientRealmId();

    if (realmId) await store.dispatch(loadRealm(realmId));
  };

  try {
    if (isAuthorized()) {
      const [user, clients] = await Promise.all([loadUser(), loadClients()]);
      const currentClient = clients ? getCurrentClient(clients) : null;

      analytics.identify(user.id, {
        type: user.type,
        name: user.full_name,
        email: user.email,
        roleId: currentClient?.role.id,
        realmId: currentClient?.realm.id,
        roleName: currentClient?.role.name,
        phoneNumber: user.phone_number,
        ...getBrowserInfo(),
      });

      currentClient &&
        analytics.group(currentClient.id, { name: currentClient.name });

      if (user.type === "standard") {
        await loadInitialData();
      }
    }
  } catch (e: any) {
    captureException(e);
  }
};
