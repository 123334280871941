import { api } from "@utils/api";

const baseURL = "/api/bills/";

export const getBills = async ({ signal, filters }) => {
  const queryParams = new URLSearchParams();

  filters.forEach((filter) => {
    queryParams.append(filter.dataIndex, filter.value);
  });

  const response = await api.get(`${baseURL}?${queryParams.toString()}`, {
    signal,
  });

  return response.data;
};

export const getBill = async (id, { signal } = {}) =>
  api.get(`${baseURL}${id}`, { cancelToken: signal }).then(({ data }) => data);

export const getConvertedBill = async (id, { signal } = {}) =>
  api
    .get(`${baseURL}${id}/converted/`, { cancelToken: signal })
    .then(({ data }) => data);

export const updateBill = async (id, data) =>
  api.put(`${baseURL}${id}/`, data).then(({ data }) => data);

export const restoreBill = async (id) => api.put(`${baseURL}${id}/restore/`);

export const ignoreSyncErrors = async (id) =>
  api.put(`${baseURL}${id}/ignore_sync_errors/`);

export const convert = (id) =>
  api.put(`${baseURL}${id}/convert/`).then(({ data }) => data);

export const convertToPurchaseOrder = (id) =>
  api
    .put(`${baseURL}${id}/convert_to_purchase_order/`)
    .then(({ data }) => data);

export const autoSplit = (id) =>
  api.put(`${baseURL}${id}/auto_split_attachable/`).then(({ data }) => data);

export const runOcrWithLines = (id) =>
  api.put(`${baseURL}${id}/run_ocr_with_lines/`).then(({ data }) => data);

export const collapseLines = async (id) =>
  api.put(`${baseURL}${id}/collapse_lines/`).then(({ data }) => data);

export const exportBills = (params) =>
  api.get(`${baseURL}export/`, { params }).then(({ data }) => data?.id);

export const archiveBill = (id, unlinkInvoiceLineOption) =>
  api.put(
    `${baseURL}${id}/archive/?unlink_invoice_line_option=${unlinkInvoiceLineOption}`
  );

export const downloadExport = (params) =>
  window.open(`${baseURL}export/?${params}`, "_blank");

export const approveBill = async (id) =>
  api.put(`${baseURL}${id}/approve/`, { status: "APPROVED" });

export const rejectBill = async (id) =>
  api.put(`${baseURL}${id}/approve/`, { status: "REJECTED" });

export const getBillAsFetch = async (id, { signal } = {}) =>
  api.get(`${baseURL}${id}`, { signal }).then(({ data }) => data);

export const deleteBill = async (id) => api.delete(`${baseURL}${id}/`);
