import { formatNumber } from "../format-number";

type FormatPercentageOptions = {
  percentSign?: boolean;
  allowNegative?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const DEFAULT_OPTIONS: Required<FormatPercentageOptions> = {
  percentSign: false,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  allowNegative: false,
};

export const formatPercentage = (
  str: string | number,
  options?: FormatPercentageOptions
) => {
  const enhancedStr = formatNumber(str, options?.allowNegative);
  const [head, tail] = enhancedStr.split(".");
  const number = Number.parseFloat(`${head}.${tail}`);

  if (Number.isNaN(number)) return "";

  const formatted = number.toLocaleString("en-US", {
    minimumFractionDigits:
      options?.minimumFractionDigits ?? DEFAULT_OPTIONS.minimumFractionDigits,
    maximumFractionDigits:
      options?.maximumFractionDigits ?? DEFAULT_OPTIONS.maximumFractionDigits,
  });

  if (!enhancedStr.includes(".")) {
    return `${formatted}${options?.percentSign ? "%" : ""}`;
  }

  const [formattedHead, formattedTail = ""] = formatted.split(".");

  const neededZeros = Math.max(
    Math.min(
      options?.maximumFractionDigits ?? DEFAULT_OPTIONS.maximumFractionDigits,
      tail.length
    ) - formattedTail.length,
    (options?.minimumFractionDigits ?? DEFAULT_OPTIONS.minimumFractionDigits) -
      formattedTail.length
  );
  const zeroPadTail = new Array((neededZeros > 0 ? neededZeros : 0) + 1).join(
    "0"
  );

  return `${formattedHead}${
    formattedTail || zeroPadTail ? `.${formattedTail}${zeroPadTail}` : ""
  }${options?.percentSign ? "%" : ""}`;
};
