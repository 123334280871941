import React, { type ComponentProps, type FunctionComponent } from "react";
import cn from "clsx";

import QuickBooksConnectSvg from "./quickbooks-button-connect.svg?react";
import QuickBooksConnectHoverSvg from "./quickbooks-button-connect-hover.svg?react";
import styles from "./quickbooks-button.module.css";

type Props = Omit<ComponentProps<"button">, "children" | "aria-label"> & {
  variant: "connect";
};

const VARIANT: Record<
  Props["variant"],
  {
    idle: FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
    hover: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }
> = {
  connect: {
    idle: QuickBooksConnectSvg,
    label: "Connect to QuickBooks",
    hover: QuickBooksConnectHoverSvg,
  },
};

export const QuickBooksButton = ({
  type = "button",
  variant,
  className,
  ...props
}: Props) => {
  const { label, idle: Idle, hover: Hover } = VARIANT[variant];

  return (
    <button
      type={type}
      className={cn(className, styles["button"])}
      aria-label={label}
      {...props}
    >
      <Idle aria-hidden="true" />
      <Hover className={styles["-hover"]} aria-hidden="true" />
    </button>
  );
};
