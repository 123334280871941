import React, { type ComponentPropsWithoutRef } from "react";

import styles from "./visually-hidden.module.css";

type DefaultComponent = "span";

type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "className">;

export const VisuallyHidden = (props: Props) => (
  <span className={styles["visually-hidden"]} {...props} />
);
