import React from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";

import styles from "./link.module.css";

const DEFAULT_COMPONENT = "a";

type Props = {
  size?: ResponsiveProp<"sm" | "md">;
  variant?: "neutral" | "success" | "warning";
};

export const Link = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      variant,
      size: rawSize,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");

    return (
      <Component
        className={cn(className, styles.link, {
          [styles[`-${variant}`]]: variant,
          [styles[`-${size}`]]: size,
        })}
        {...props}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

Link.displayName = "Link";
