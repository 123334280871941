import type { Identifiable } from "@shared/types";
import {
  getApi,
  getUrlBuilder,
  invalidate5xx,
  transformPayloads,
} from "@utils/api";

import { vendorAchPutPayload } from "./request/schema";
import { achSchema } from "./response/schema";
import { handleResponse } from "./old-api";
import type { ACHInfo } from "./types";

export type ACHCreatePayload = Omit<ACHInfo, "id"> & {
  realm: string;
};

export const api = getApi([invalidate5xx, transformPayloads]);

const urlBuilder = getUrlBuilder("/api/vendorbankingachs/");

export const get = async ({ id }: Identifiable) => {
  const resp = await api.get<ACHInfo>(urlBuilder.forItem({ id }));
  return handleResponse(resp, achSchema);
};

export const removeACH = async (ach: Identifiable) => {
  const resp = await api.delete(urlBuilder.forItem(ach));
  if (resp.status !== 204) {
    throw new Error(`Unexpected response: ${resp.status}`);
  }
  return resp;
};

export const create = async (ach: ACHCreatePayload) => {
  const payload = vendorAchPutPayload.parse(ach);

  const resp = await api.post<ACHInfo>(urlBuilder.forCollection(), payload);
  return handleResponse(resp, achSchema);
};

export const vendorBankingAchApi = {
  create,
  get,
  delete: removeACH,
};
