import React, { useCallback, useMemo, useState } from "react";
import { Flex, Loader, Text, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import {
  postVendorPublicDocRequest,
  putVendorPublicDocRequest,
} from "@api/vendor-requests";
import { Layout } from "@components/layout";
import {
  VendorDocumentForm,
  type VendorDocumentFormProps,
} from "@components/vendor-document-form";
import { VendorDocumentPreview } from "@components/vendor-document-preview";

import { RequestFormExpired } from "./common";
import { STRINGS } from "./constants";
import { Footers } from "./footers";
import type { FormProps, VendorDocRequestType } from "./types";
import { VendorRequest } from "./vendor-request";

export const VendorDocRequest = () => {
  return <VendorRequest type="DOC" form={DocRequestForm} />;
};

import { getNonFieldErrors, isNonFieldErrors } from "@api/handle-errors";

export const DocRequestForm = ({
  vendor,
  companyName,
  queryParams,
  request,
}: FormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [requestedDocuments, setRequestedDocuments] = useState(
    (request as VendorDocRequestType).requested_documents || []
  );

  const requestedDocumentsTypes = useMemo(
    () =>
      (requestedDocuments ?? [])
        .filter((doc) => !doc.document)
        .map((doc) => doc.type)
        .flat(),
    [requestedDocuments]
  );

  const uploadedDocs = useMemo(
    () => (requestedDocuments ?? []).filter((doc) => doc.document),
    [requestedDocuments]
  );

  const [requestExpired, setRequestExpired] = useState<boolean>(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const onSuccess = useCallback((responseData: VendorDocRequestType) => {
    setRequestedDocuments(responseData?.requested_documents);
  }, []);

  const onError = useCallback((responseData: unknown) => {
    if (isNonFieldErrors(responseData)) {
      getNonFieldErrors(responseData).map((error) => {
        if (error === STRINGS.REQUEST_EXPIRED) setRequestExpired(true);
      });
    }
  }, []);

  const onDelete = useEvent(async (vendorDocument) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("requested_document_id", vendorDocument.id);

    try {
      const { data } = await putVendorPublicDocRequest(queryParams, formData);
      setIsFormSubmitted(false);
      onSuccess(data);
      toast.success("Document removed");
    } catch (e) {
      onError(e);
      toast.error("Failed to remove the document");
    }
    setIsLoading(false);
  });

  const onSave = useEvent<VendorDocumentFormProps["onSave"]>(
    async (vendorDocument) => {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("document", vendorDocument.file);
      formData.append("type", vendorDocument.type);
      formData.append("realm", vendor.realm);
      formData.append("parent", vendor.url);
      formData.append(
        "expiration_date",
        formatDate(vendorDocument.expirationDate, "yyyy-MM-dd")
      );

      try {
        const { data } = await postVendorPublicDocRequest(
          queryParams,
          formData
        );
        setIsFormSubmitted(true);
        onSuccess(data);
        toast.success("Document uploaded");
      } catch (e) {
        onError(e);
        toast.error("Failed to upload the document");
      }
      setIsLoading(false);
      document.documentElement.scrollTop = 0;
    }
  );

  return requestExpired ? (
    <RequestFormExpired companyName={companyName} />
  ) : (
    <Layout
      title={
        isFormSubmitted
          ? STRINGS.REQUEST_FORM_SUBMITTED_TITLE
          : "Upload your documents"
      }
      subtitle={isFormSubmitted ? STRINGS.DOC_SUBMITTED_MESSAGE : ""}
      gap={{ mobile: "3xl", tablet: "96px" }}
      padding={{ mobile: "2xl", tablet: ["5xl", "5xl", "none"] }}
    >
      {isLoading && <Loader position="fixed" />}
      <Flex gap="4xl" direction="column">
        <Flex gap="xl" direction="column">
          <Text size="sm">
            Request from:{" "}
            <Text as="strong" weight="bold">
              {companyName}
            </Text>
          </Text>
          {uploadedDocs.map((doc) => (
            <VendorDocumentPreview
              url={
                !(doc.document instanceof File)
                  ? (doc.document.document as string)
                  : undefined
              }
              key={doc.url}
              type={doc.type}
              onDelete={() => onDelete(doc)}
              onDeleteDisabledTooltipMessage={
                (!doc.can_be_deleted &&
                  "You can only delete documents that you uploaded") ||
                ""
              }
            />
          ))}
          {requestedDocumentsTypes.length ? (
            requestedDocumentsTypes.map((type) => (
              <VendorDocumentForm
                key={type}
                onSave={onSave}
                initialValues={{ type }}
              />
            ))
          ) : (
            <VendorDocumentForm onSave={onSave} />
          )}
        </Flex>
        <Footers companyName={companyName}></Footers>
      </Flex>
    </Layout>
  );
};
