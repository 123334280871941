import { idSchema } from "@utils/schema";
import { z } from "zod";

export const getCustomerSchemaRequestSchema = z.object({
  customerId: idSchema,
});

export const addLineItemPayloadSchema = z.object({
  budgetLines: z.array(z.object({ itemId: z.string() })),
  customerId: z.number(),
});

export const updateBuilderBudgetLockedStatusPayloadSchema = z.object({
  isBuilderBudgetLocked: z.boolean(),
  customerId: z.number(),
});

export const updateOwnerBudgedLockedStatusPayloadSchema = z.object({
  isOwnerBudgetLocked: z.boolean(),
  customerId: z.number(),
});

export const deleteBudgedLineItemPayloadSchema = z.object({
  budgetLineId: z.string().uuid(),
  customerId: z.number(),
});

export const updateBulkBudgetLinesPayloadSchema = z.object({
  budgetLines: z.array(
    z.object({
      id: z.string(),
      ownersAmount: z.string(),
      builderAmount: z.string(),
      builderRevisedAmount: z.string().nullish(),
    })
  ),
  customerId: z.number(),
});

export const storeCustomerCategoryPayloadSchema = z.object({
  customerId: idSchema,
  displayName: z.string(),
});

export const deleteCustomerCategoryPayloadSchema = z.object({
  switchTo: idSchema.nullish(),
  categoryId: idSchema,
  customerId: idSchema,
});

export const cloneCustomerCategoryPayloadSchema = z.object({
  customerId: idSchema,
  cloneCustomerId: idSchema,
});

export const upsertCustomerCategoriesPayloadSchema = z.object({
  customerId: idSchema,
  categories: z.array(
    z.object({
      id: idSchema.nullish(),
      displayName: z.string(),
    })
  ),
});

export const getCustomerCategoriesPayloadSchema = z.object({
  customerId: idSchema,
});

export const batchUpdateCustomerBudgetLinesCategoriesPayloadSchema = z.object({
  customerId: idSchema,
  categoryId: idSchema.nullish(),
  budgetLines: z.array(idSchema),
});

export const getLinkedInvoicesRequestSchema = z.object({
  expenseIds: z.array(idSchema).optional(),
  billIds: z.array(idSchema).optional(),
});
export const getCustomerDrawnToDatePayloadSchema = z.object({
  customerId: idSchema,
});
