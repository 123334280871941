import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { getTransactionResponseSchema } from "./schemas";
import type { GetTransactionPayload, GetTransactionResponse } from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Transaction"],
});

export const conversationsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransaction: builder.query<
      GetTransactionResponse,
      GetTransactionPayload
    >({
      query: ({ id, type }) => ({
        url: `${type}/${id}/external`,
        responseHandler: async (response) => {
          const data = await response.json();

          if (response.status >= 400) return;

          return handleResponse(
            transformKeysToCamelCase(data),
            getTransactionResponseSchema
          );
        },
      }),
      providesTags: (_, __, params) => [{ type: "Transaction", params }],
    }),
  }),
});

export const { useGetTransactionQuery } = conversationsApi;
