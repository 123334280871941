export const getPlatform = () => {
  const userAgent = navigator.userAgent;

  return (
    /Mac/.test(userAgent)
      ? "mac"
      : /Win/.test(userAgent)
        ? "windows"
        : /Linux/.test(userAgent)
          ? "linux"
          : undefined
  ) as "mac" | "linux" | "windows" | undefined;
};
