type FormatCardParams =
  | { mask?: string | null; name?: string | null }
  | null
  | undefined;

export const formatCard = (params: FormatCardParams) => {
  if (!params) return "";

  const { mask, name } = params;

  if (!mask && !name) return "";

  const enhancedMask = mask ? `••••${mask}` : "";

  return mask && name ? `${name} (${enhancedMask})` : name || enhancedMask;
};
