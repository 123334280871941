import {
  arraySchema,
  attachmentSchema,
  commentSchema,
  currencySchema,
  dateSchema,
  fileSchema,
  idSchema,
  referenceSchema,
} from "@utils/schema";
import { z } from "zod";

export const getTransactionPayloadSchema = z.object({
  id: idSchema,
  type: z.string(),
});

export const getTransactionResponseSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema,
  vendor: referenceSchema.extend({
    email: z.string().nullish(),
  }),
  client: z.object({
    id: idSchema,
    name: z.string(),
    email: z.string().nullish(),
  }),
  comments: arraySchema(commentSchema),
  totalAmount: currencySchema,
  attachables: z.union([
    arraySchema(attachmentSchema),
    arraySchema(fileSchema),
  ]),
  latestRequestParams: z
    .object({
      type: z.enum(["ACH", "PO_SIGNATURE", "DOC"]),
      realmUid: z.string(),
      vendorUid: z.string(),
      token: z.string(),
    })
    .nullish(),
  humanReadableType: z.enum(["Bill", "Purchase Order", "Receipt"]),
  realm: z.object({
    id: idSchema,
    url: z.string().url(),
    companyName: z.string().nullish(),
  }),
});
