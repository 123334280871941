import React from "react";
import { Card, Flex, Icon, Link, Text } from "@adaptive/design-system";
import MaverickLogoSvg from "@shared/assets/images/maverick-logo.svg?react";

import { adaptiveUrl, maverickUrl } from "./constants";

type Props = { companyName: string };

const MaverickFooter = () => (
  <Card size={{ mobile: "sm", tablet: "md" }}>
    <Flex align="center" justify="space-between">
      <Flex direction="column" gap={{ mobile: "2xl", tablet: "md" }}>
        <Text weight="bold">Insurance shouldn&apos;t be so confusing</Text>
        <Flex direction="column">
          <Text align="left">
            Maverick Insurance makes it easy by protecting contractors like you.
          </Text>
          <Flex
            as="a"
            gap="sm"
            rel="noreferrer"
            href={maverickUrl}
            width="fit-content"
            align="center"
            target="_blank"
          >
            <Text color="brand-2" weight="bold">
              Learn more
            </Text>
            <Icon name="arrow-right" color="brand-2" size="sm" />
          </Flex>
        </Flex>
        <Flex
          gap="2xl"
          direction={{ mobile: "column", tablet: "row" }}
          align="center"
        >
          <a rel="noreferrer" href={maverickUrl} target="_blank">
            <MaverickLogoSvg title="Maverick Insurance and Warranties" />
          </a>
          <Flex direction="column">
            <Text as="a" href="tel:(603)3402161">
              (603) 340-2161
            </Text>
            <Text as="a" href="mail:sales@maverickbuilders.com">
              sales@maverickbuilders.com
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Card>
);

export const AdaptiveFooter = ({ companyName }: Props) => (
  <Card color="success" size={{ mobile: "sm", tablet: "md" }}>
    <Flex gap="md" direction="column">
      <Text size="sm">
        {companyName} uses Adaptive, the construction cost management platform.{" "}
        <Text
          size="sm"
          as={Link}
          href={adaptiveUrl}
          variant="success"
          style={{ textDecoration: "none" }}
        >
          Learn more
        </Text>
        .
      </Text>

      <Text size="sm">
        Adaptive’s all-in-one budgets, accounts payable, and bill pay platform
        helps construction teams save time and money. Purpose-built software +
        painless onboarding + expert customer service = more time on the job
        site.
      </Text>
      <Text size="sm">
        Copyright © 2022. Adaptive Real Estate, Inc. All rights reserved
      </Text>
    </Flex>
  </Card>
);

export const Footers = ({ companyName }: Props) => (
  <Flex gap="4xl" direction="column">
    <MaverickFooter />
    <AdaptiveFooter companyName={companyName} />
  </Flex>
);
