import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
} from "react";
import cn from "clsx";

import styles from "./table.module.css";

type DefaultElement = "div";

type Props = ComponentPropsWithoutRef<DefaultElement> & {
  label?: string;
  offset?: { y?: number };
  placement?: "center" | "top";
};

export const TableAddonLeft = forwardRef<ComponentRef<DefaultElement>, Props>(
  ({ placement = "center", offset, className, style, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(className, styles["addon-left"], {
        [styles[`-${placement}`]]: placement,
      })}
      style={{
        ...style,
        "--table-addon-left-offset-y": `${offset?.y ?? 0}px`,
      }}
      {...props}
    />
  )
);

TableAddonLeft.displayName = "TableAddonLeft";
