import React, { type ComponentProps } from "react";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import cn from "clsx";

import styles from "./main.module.css";

type Props = {
  size?: ResponsiveProp<"xs" | "sm" | "md" | "lg">;
} & ComponentProps<"footer">;

export const MainFooter = ({ className, size, ...props }: Props) => {
  const sizeValue = useResponsiveProp(size, "md");

  return (
    <footer
      className={cn(className, styles["main-footer"], {
        [styles[`-${sizeValue}`]]: sizeValue,
      })}
      {...props}
    />
  );
};
