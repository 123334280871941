export enum BasePermissionCategory {
  BILLS = 1,
  EXPENSES = 2,
  POS = 3,
  COMPANY_SETTINGS = 4,
  JOBS = 5,
  INVOICES = 6,
  VENDORS = 7,
}

export enum BasePermissions {
  ADD_BILL = 1,
  COMMENT_BILL = 2,
  VIEW_ALL_BILLS = 3,
  EDIT_ALL_BILLS = 4,
  APPROVE_BILLS = 5,
  PAY_BILLS = 6,
  ADD_EXPENSE = 7,
  COMMENT_EXPENSE = 8,
  VIEW_ALL_EXPENSES = 9,
  EDIT_ALL_EXPENSES = 10,
  PUBLISH_EXPENSES = 11,
  FLAG_EXPENSES = 12,
  ADD_PO = 13,
  VIEW_ALL_POS = 14,
  MANAGE_POS = 15,
  COMPANY_ADMIN = 16,
  VIEW_ALL_JOBS = 17,
  MANAGE_JOBS = 18,
  VIEW_ALL_INVOICES = 19,
  COMMENT_INVOICE = 25,
  MANAGE_INVOICES = 20,
  SYNC_INVOICES_TO_ACCOUNTING_SOFTWARE = 21, // Formerly known as `APPROVE_INVOICES`
  VIEW_ALL_VENDORS = 22,
  MANAGE_NON_PAYMENT_VENDORS = 23,
  MANAGE_PAYMENT_VENDORS = 24,
  MANAGE_APPROVAL_WORKFLOWS = 26,
  BYPASS_APPROVAL_WORKFLOWS = 27,
  EDIT_INITIAL_BUDGET = 28,
  VIEW_ALL_REPORTS = 29,
  COMMENT_PO = 30,
  CREATE_LIEN_WAIVER_TEMPLATE = 31,
  CREATE_LIEN_WAIVER_REQUEST = 32,
}

export enum BaseRoles {
  BOSS = "Boss",
  MANAGER = "Manager",
  APPROVER = "Approver",
  VIEWER = "Viewer",
}
