import { api } from "../../utils/api";
import { handleErrors } from "../handle-errors";

import type { EntityWithComments, PutArgs } from "./types";

const put = async ({
  text,
  author,
  parent,
  files,
  parent_comment,
}: PutArgs) => {
  try {
    const payload = new FormData();
    payload.append("text", text);
    payload.append("author", author);
    payload.append("parent", parent);
    if (parent_comment) payload.append("parent_comment", parent_comment);
    files?.forEach((file) => {
      payload.append("files", file);
    });

    const config = { headers: { "content-type": "multipart/form-data" } };
    return await api.post<{
      id: string;
      url: string;
      text: string;
      has_external_mention: boolean;
      files: { id: string; file: string; name: string }[];
    }>("/api/comments/", payload, config);
  } catch (e) {
    handleErrors(e);
  }
};

const putAll = async (entity: EntityWithComments) => {
  try {
    (entity.comments || []).every(async ({ text, author }) => {
      await put({
        text,
        author: author.url,
        parent: entity.url,
      });
    });
  } catch (e) {
    handleErrors(e);
  }
};

export { put as putComment, putAll as putComments };
