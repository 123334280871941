export const summarizeResults = (results: PromiseSettledResult<unknown>[]) => {
  return results.reduce(
    (acc, result) => {
      const isError = result.status === "rejected";

      return {
        error: acc.error + (isError ? 1 : 0),
        errorResponses: isError
          ? [...acc.errorResponses, result.reason]
          : acc.errorResponses,
        success: acc.success + (!isError ? 1 : 0),
        successResponses: !isError
          ? [...acc.successResponses, result.value]
          : acc.successResponses,
      };
    },
    {
      error: 0,
      success: 0,
      errorResponses: [] as unknown[],
      successResponses: [] as unknown[],
    }
  );
};

export type PromisesSettledSummary = ReturnType<typeof summarizeResults>;
