import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dialog, Flex } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import type { InvoiceGetResponse } from "@api/invoices";
import {
  DynamicActions as SharedDynamicActions,
  type DynamicActionsProps,
} from "@components/dynamic-actions";
import { useJobPermissions } from "@src/jobs";

import { useInvoiceActions } from "./invoice-context";

type InvoiceDynamicActionsProps = {
  jobId: InvoiceGetResponse["customer"]["id"];
  onEdit: () => void;
  reviewStatus: InvoiceGetResponse["reviewStatus"];
};

export const InvoiceDynamicActions = memo(
  ({ jobId, onEdit, reviewStatus }: InvoiceDynamicActionsProps) => {
    const location = useLocation();

    const navigate = useNavigate();
    const { canManage } = useJobPermissions();

    const { deleteInvoice } = useInvoiceActions();

    const onArchive = useEvent(() => {
      /**
       * @todo implement it as soon as we implement it on backend side
       */
    });

    const onDelete = useEvent(() => {
      const handler = async () => {
        await deleteInvoice();
        navigate(
          dotObject.get(location, "state.prev", `/jobs/${jobId}?status=invoice`)
        );
      };

      dialog.confirmation({
        title: (
          <>
            Are you sure you want to <br />
            delete this transaction?
          </>
        ),
        message: "You can't undo this action.",
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Delete",
          },
        },
      });
    });

    let actions: Exclude<DynamicActionsProps["data"], undefined> = [];

    /**
     * @todo remove it as soon as we implement it on backend side
     */
    const isArchiveEnabled = false;

    if (reviewStatus !== "Paid") {
      actions = [
        ...actions,
        {
          onClick: onDelete,
          children: "Delete",
          color: "error",
          disabled: !canManage,
        },
      ];
    }

    if (reviewStatus !== "Paid" && isArchiveEnabled) {
      actions = [
        ...actions,
        { onClick: onArchive, children: "Delete", disabled: !canManage },
      ];
    }

    if (reviewStatus === "Paid") {
      actions = [
        ...actions,
        { onClick: onEdit, children: "Edit", disabled: !canManage },
      ];
    }

    return (
      <SharedDynamicActions
        data={actions}
        fallback={<Flex />}
        data-testid="invoice-actions"
      />
    );
  }
);

InvoiceDynamicActions.displayName = "InvoiceDynamicActions";
