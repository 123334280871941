import React, { useMemo } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  type TableColumn,
  type TableRow,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";

import type { CardTransaction } from "../api/types";
import { EDIT_STEP_TABLE_ID, STRINGS } from "../constants/constants";
import { useUnmatchCardTransaction } from "../hooks/use-unmatch-card-transaction";

import { LinkedCard, LinkedCostLink } from "./misc";
import type { LinkedCost } from "./types";

export type MatchingTransactionDialogEditStepProps = {
  cost?: LinkedCost;
  onUnmatch: () => void;
  onChangeMatch: () => void;
  cardTransaction?: CardTransaction;
};

export const MatchingTransactionDialogEditStep = ({
  cost,
  onUnmatch,
  onChangeMatch,
  cardTransaction,
}: MatchingTransactionDialogEditStepProps) => {
  const [unmatchCardTransaction, unmatchCardTransactionInfo] =
    useUnmatchCardTransaction();

  const info = useMemo(() => {
    if (cost?.cardTransaction) {
      const amount = formatCurrency(cost.cardTransaction.amount ?? 0, {
        currencySign: true,
        allowNegative: true,
      });

      let date = "";

      if ("transactionDate" in cost) {
        const transactionDate = parseDate(cost.transactionDate, "yyyy-MM-dd");

        if (transactionDate) {
          date = formatDate(transactionDate, "yyyy-MM-dd");
        }
      } else {
        date = formatDate(cost.date, "yyyy-MM-dd");
      }

      return {
        data: [cost.cardTransaction] as TableRow<typeof cost.cardTransaction>[],
        date,
        name: cost.cardTransaction.name,
        type: "transaction",
        amount,
        columns: [
          {
            id: "vendor",
            name: "Vendor name",
            render: (row) => row.description ?? row.name,
            minWidth: 240,
          },
          {
            id: "date",
            width: 140,
            name: "Date",
            render: ({ date }) => (date ? formatDate(date) : null),
          },
          {
            id: "card",
            width: 155,
            name: "Card",
            render: (row) => <LinkedCard {...row} />,
          },
          {
            id: "amount",
            name: "Amount",
            render: () => amount,
            textAlign: "right",
          },
        ] as TableColumn<typeof cost.cardTransaction>[],
      };
    } else if (cardTransaction?.match) {
      const amount = formatCurrency(cardTransaction.amount || 0, {
        currencySign: true,
        allowNegative: true,
      });

      return {
        data: [cardTransaction.match] as TableRow<
          typeof cardTransaction.match
        >[],
        date: formatDate(cardTransaction.date, "yyyy-MM-dd"),
        name: cardTransaction.name,
        type: "cost",
        amount,
        columns: [
          {
            id: "humanReadableType",
            name: "Cost type",
            render: "humanReadableType",
          },
          {
            id: "docNumber",
            name: "Ref #",
            render: (row) => (
              <LinkedCostLink {...(row.parent ? row.parent : row)} />
            ),
          },
          {
            id: "date",
            name: "Date",
            render: (row) => {
              const date = row.parent?.date || row.date;
              return date ? formatDate(date) : null;
            },
          },
          {
            id: "amount",
            name: "Amount",
            render: () => amount,
            textAlign: "right",
          },
        ] as TableColumn<typeof cardTransaction.match>[],
      };
    }

    return { data: [], columns: [] };
  }, [cardTransaction, cost]);

  const enhancedOnUnmatch = useEvent(async () => {
    const cardTransactionId = cardTransaction?.id ?? cost?.cardTransaction?.id;

    if (cardTransactionId) await unmatchCardTransaction(cardTransactionId);

    onUnmatch();
  });

  return (
    <>
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {STRINGS.MATCHING_TRANSACTION_DIALOG_EDIT_STEP_TITLE}
          </Text>
          <Text size="md">
            {info.date || "Unknown date"}: {info.amount} {info.name}
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex direction="column" minWidth="890px" gap="lg">
          <Text weight="bold">Current matched {info.type}</Text>
          <Table
            id={EDIT_STEP_TABLE_ID}
            size="sm"
            data={info.data as TableRow<Record<string, unknown>>[]}
            columns={info.columns as TableColumn<Record<string, unknown>>[]}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block variant="ghost" onClick={onChangeMatch}>
          {STRINGS.MATCHING_TRANSACTION_DIALOG_EDIT_STEP_CHANGE_ACTION}
        </Button>
        <Button
          block
          type="submit"
          onClick={enhancedOnUnmatch}
          disabled={unmatchCardTransactionInfo.isLoading}
        >
          {unmatchCardTransactionInfo.isLoading ? (
            <Loader />
          ) : (
            STRINGS.MATCHING_TRANSACTION_DIALOG_EDIT_STEP_UNMATCH_ACTION
          )}
        </Button>
      </DialogFooter>
    </>
  );
};
