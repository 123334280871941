import type { ValidationResult, ValidationRule } from "./index";

export class Validator<T> {
  public readonly rules: ValidationRule<T>[];

  constructor(rules?: ValidationRule<T>[]) {
    this.rules = rules || [];
  }

  validate(item: T): ValidationResult {
    let result: ValidationResult = { ok: true, reason: "" };

    for (const rule of this.rules) {
      result = rule(item);
      if (!result.ok) {
        break;
      }
    }

    return result;
  }

  validateAll(item: T): ValidationResult[] {
    return this.rules.map((apply) => apply(item));
  }
}
