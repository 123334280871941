import React, {
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  type DialogOnCloseHandler,
  type DialogProps,
} from "@adaptive/design-system";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import { useDrawerVisibility } from "@store/ui/hooks";

import { type DrawerName, useDrawerStack } from "./context";

type DrawerSize = Extract<DialogProps, { variant: "drawer" }>["size"];

interface Props {
  name: DrawerName;
  size?: ResponsiveProp<DrawerSize>;
  children: ReactNode;
  autoFocusOnHide?: boolean;
}

const useDrawer = (name: DrawerName) => {
  const drawerStack = useDrawerStack();

  if (!drawerStack) throw new Error("Drawer must be child node of Dresser");

  const { push, pop } = drawerStack;

  const { step, visible, setVisible } = useDrawerVisibility(name);

  useEffect(() => {
    if (visible) {
      push(name);
    } else {
      pop();
    }
  }, [visible, push, pop, name]);

  /**
   * @todo We might need to handle the popstate changes confirmation
   * dialog. Since it is not implemented yet, we are ignoring it.
   */
  const onClose = useCallback<DialogOnCloseHandler>(
    (trigger) => {
      const ignoreShouldShowHideConfirmation = trigger === "popstate";
      setVisible(false, ignoreShouldShowHideConfirmation);
    },
    [setVisible]
  );

  return { step, show: visible, onClose };
};

export const Drawer = memo(
  ({ children, name, size: rawSize, autoFocusOnHide }: Props) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const { step, show, onClose } = useDrawer(name);

    const [isRendered, setIsRendered] = useState(show);

    const size = useResponsiveProp(rawSize, "md");

    const enhancedProps: DialogProps = {
      show,
      size,
      onClose,
      autoFocusOnHide,
      ...(step
        ? { step, variant: "multi-step-drawer" }
        : { variant: "drawer" }),
    };

    useEffect(() => {
      timeoutRef.current = setTimeout(
        () => setIsRendered(show),
        show ? 0 : 300
      );

      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }, [show]);

    return isRendered && <Dialog {...enhancedProps}>{children}</Dialog>;
  }
);

Drawer.displayName = "Drawer";
