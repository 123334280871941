import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@adaptive/design-system";

import { AmountForm } from "./amount-form";
import type { ManageMarkupDialogProps } from "./types";

const FORM_ID = "budget-edit-fixed-markup";

export const EditFixedMarkupDialog = ({
  dialog,
  markup,
}: ManageMarkupDialogProps) => {
  const [isAmountFormValid, setIsAmountFormValid] = useState<boolean>(false);

  const onClose = () => {
    dialog.hide();
  };

  return (
    <Dialog
      show={dialog.isVisible}
      size="md"
      onClose={dialog.hide}
      variant="dialog"
    >
      <DialogHeader>Edit budget markup</DialogHeader>
      <DialogContent>
        <AmountForm
          formId={FORM_ID}
          markup={markup}
          onClose={onClose}
          onFormChange={setIsAmountFormValid}
        />
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" onClick={dialog.hide} variant="text">
          Cancel
        </Button>
        <Button
          block
          type="submit"
          form={FORM_ID}
          disabled={!isAmountFormValid}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
