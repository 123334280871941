import React, {
  memo,
  type ReactNode,
  type TransitionEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useEvent, useResizeObserver } from "@adaptive/design-system/hooks";
import { Allotment, type AllotmentHandle } from "allotment";
import cn from "clsx";

const MIN_LEFT_SIZE = 300;
const MIN_RIGHT_SIZE = 650;

import { Icon, Tooltip } from "@adaptive/design-system";

import "./transaction-split-view.css";
import styles from "./transaction-split-view.module.css";

export type TransactionSplitViewProps = {
  left: ReactNode;
  right: ReactNode;
  onResize?: (sizes: { left: number; right: number }) => void;
  /**
   * If provided, mobile snap button will not be displayed and
   * snaping will be controlled by redirected click to this ID
   */
  snapToggleId?: string;
};

const getSidebarWidth = () => {
  const el = document.querySelector(".app-shell");

  if (!el) return 0;

  return parseFloat(
    getComputedStyle(el).getPropertyValue("--shell-content-padding-left")
  );
};

export const TransactionSplitView = memo(
  ({ left, right, onResize, snapToggleId }: TransactionSplitViewProps) => {
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLElement>(
      document.querySelector("[data-sidebar]")
    );
    const allotmentRef = useRef<AllotmentHandle>(null);

    const [snap, setSnap] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [visible, setVisible] = useState<"left" | "right">("right");
    const [showTooltip, setShowTooltip] = useState(false);

    const onChange = useEvent(([left, right]) => onResize?.({ left, right }));

    const onToggle = useEvent(() => {
      setShowTooltip(false);

      if (leftRef.current) {
        leftRef.current.style.visibility = "visible";
      }

      if (rightRef.current) {
        rightRef.current.style.visibility = "visible";
      }

      setVisible((visible) => {
        const next = visible === "left" ? "right" : "left";

        onResize?.({ left: 0, right: 0 });

        return next;
      });
    });

    const onTransitionEnd = useEvent<TransitionEventHandler<HTMLDivElement>>(
      (e) => {
        if (e.currentTarget.getAttribute("aria-hidden") === "true") {
          e.currentTarget.style.visibility = "hidden";
        } else {
          e.currentTarget.style.visibility = "visible";
        }
      }
    );

    const adjustViewMode = useCallback(() => {
      const snap =
        window.innerWidth <= MIN_LEFT_SIZE + MIN_RIGHT_SIZE + getSidebarWidth();

      setSnap(snap);

      if (snap) {
        onResize?.({ left: 0, right: 0 });
        setVisible("right");
      } else {
        allotmentRef.current?.reset();
      }
    }, [onResize]);

    useEffect(() => {
      adjustViewMode();

      setMounted(true);

      window.addEventListener("resize", adjustViewMode);

      return () => {
        window.removeEventListener("resize", adjustViewMode);
      };
    }, [adjustViewMode]);

    useResizeObserver(sidebarRef, adjustViewMode);

    if (!mounted) return null;

    return snap ? (
      <div className={styles["wrapper"]}>
        <div
          ref={leftRef}
          className={cn(styles["inner"], styles["-left"])}
          aria-hidden={visible === "right"}
          onTransitionEnd={onTransitionEnd}
        >
          {left}
        </div>
        <button
          type="button"
          onClick={onToggle}
          className={cn(styles["toggle"], {
            [styles["-left"]]: visible === "right",
            [styles["-right"]]: visible === "left",
            [styles["-hidden"]]: !!snapToggleId,
          })}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          id={snapToggleId}
          aria-hidden={!!snapToggleId}
        >
          <Tooltip
            as={Icon}
            size="lg"
            show={showTooltip}
            name={visible === "right" ? "chevron-right" : "chevron-left"}
            message={visible === "right" ? "Show attachment" : "Show items"}
            className={styles["icon"]}
            placement={visible === "right" ? "left" : "right"}
          />
        </button>
        <div
          ref={rightRef}
          className={cn(styles["inner"], styles["-right"])}
          aria-hidden={visible === "left"}
          onTransitionEnd={onTransitionEnd}
        >
          {right}
        </div>
      </div>
    ) : (
      <Allotment ref={allotmentRef} onChange={onChange}>
        <Allotment.Pane minSize={MIN_LEFT_SIZE}>{left}</Allotment.Pane>
        <Allotment.Pane minSize={MIN_RIGHT_SIZE}>{right}</Allotment.Pane>
      </Allotment>
    );
  }
);

TransactionSplitView.displayName = "TransactionSplitView";
