import {
  type Formatter,
  getArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";

export const costsFilterFormatter: Formatter<QueryItem[]> = (
  realmId,
  filters
) => {
  const params = getArrayFormatter(realmId, filters).map((query) => {
    if (query.dataIndex === "status") {
      return { ...query, dataIndex: "review_status" };
    } else if (query.dataIndex === "type") {
      return { ...query, dataIndex: "cost_type" };
    }

    return query;
  });

  return params;
};
