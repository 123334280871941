import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
} from "@adaptive/design-system";
import { PDF_BASE64_TYPE } from "@components/pdf-viewer";

import { useRenderBillLienWaiverPdfMutation } from "../api/api";
import { STRINGS } from "../constants";
import { TEMPLATE_NOT_REQUESTED_ID } from "../constants/constants";
import {
  lienWaiverTemplateTypesSelector,
  simplifiedLienWaiverTemplatesSelector,
} from "../utils/selectors";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";
import { TemplatePreview } from "./template-preview";

type BillLienWaiverPreviewDrawerProps = {
  show: boolean;
  onClose: () => void;
  value?: string;
  pdf?: string;
} & Pick<
  BillLienWaiverFieldProps,
  "paymentAmount" | "billId" | "billPaymentId"
>;

export const BillLienWaiverPreviewDrawer = ({
  show,
  onClose,
  value,
  paymentAmount,
  billId,
  billPaymentId,
  pdf,
}: BillLienWaiverPreviewDrawerProps) => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState(value);
  const lienWaiverTemplates = useSelector(
    simplifiedLienWaiverTemplatesSelector
  );
  const liewnWaiverTemplateTypes = useSelector(lienWaiverTemplateTypesSelector);

  const [renderLienWaiverPDF, { data, isLoading }] =
    useRenderBillLienWaiverPdfMutation();

  const templatesData = useMemo(() => {
    return lienWaiverTemplates
      .filter((template) => !!template.url)
      .map((template) => ({
        label:
          liewnWaiverTemplateTypes.find((type) => type.url === template.type)
            ?.name || template.type,
        value: template.url || TEMPLATE_NOT_REQUESTED_ID,
      }));
  }, [lienWaiverTemplates, liewnWaiverTemplateTypes]);

  useEffect(() => {
    if (pdf) return;
    const template = lienWaiverTemplates.find(
      (template) => template.url === selectedTemplate
    );
    if (billId && template && show) {
      renderLienWaiverPDF({
        billId,
        lienWaiverTemplateId: template.id,
        billPaymentId: billPaymentId!,
        paymentAmount: paymentAmount ?? null,
      });
    }
  }, [
    paymentAmount,
    billId,
    lienWaiverTemplates,
    renderLienWaiverPDF,
    selectedTemplate,
    billPaymentId,
    show,
    pdf,
  ]);

  const navigateToEditTemplate = useCallback(() => {
    const templateId = lienWaiverTemplates.find(
      (template) => template.url === selectedTemplate
    )?.id;

    if (!templateId) return;

    navigate(`/settings/company/lien-waiver-template/${templateId}`);
  }, [lienWaiverTemplates, navigate, selectedTemplate]);

  return (
    <Dialog variant="drawer" show={show} onClose={() => onClose()}>
      <DialogHeader>{STRINGS.LIEN_WAIVER_PREVIEW}</DialogHeader>

      <DialogContent>
        {!pdf && (
          <Flex width="full" maxWidth="420px" padding={["none", "none", "2xl"]}>
            <ComboBox
              data={templatesData}
              messageVariant="hidden"
              value={selectedTemplate}
              onChange={setSelectedTemplate}
            />
          </Flex>
        )}
        {data?.missingFields ? (
          <Alert variant="warning">
            <AlertTitle>{STRINGS.MISSING_FIELDS_PREVIEW_PDF_TITLE}</AlertTitle>
            <AlertContent>
              {STRINGS.MISSING_FIELDS_PREVIEW_PDF_BODY}
            </AlertContent>
          </Alert>
        ) : null}
        <TemplatePreview
          docType={!pdf ? PDF_BASE64_TYPE : undefined}
          docSource={pdf ?? data?.pdf}
          loading={!pdf && isLoading}
        />
      </DialogContent>
      <DialogFooter>
        <Flex width="full" justify="space-between" align="center">
          <Button variant="text" color="neutral" onClick={() => onClose()}>
            {STRINGS.CLOSE_PREVIEW}
          </Button>
          {!pdf && (
            <Button variant="ghost" onClick={navigateToEditTemplate}>
              {STRINGS.EDIT_PREVIEW_TEMPLATE}
            </Button>
          )}
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
