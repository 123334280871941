import React, {
  memo,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ComboBox,
  DateField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  TagGroup,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  formatDate,
  is,
  isEqual,
  omit,
  parseDate,
  pick,
  suffixify,
} from "@adaptive/design-system/utils";
import type { Option } from "@shared/types";

import { useTableFilterOptions } from "../../hooks/useTableFilterOptions";
import { Form } from "../form";

import { pickOptionValues } from "./formatters";
import { useFilters } from "./table-filter-hooks";
import type {
  TableFilterControlsProps,
  TableFilterOnChangeHandler,
  TableFilterOnRemoveHandler,
  ValuesFilters,
} from "./types";

const DATE_KEYS = ["date_after", "date_before"];

export const TableFilterControls = memo(
  ({
    size = "md",
    filters,
    children,
    dateProps = {},
    extraData = [],
    fixedTags = [],
    withFilterTags = true,
    withDateFilter = false,
    includeFilters = ["vendors", "customers", "accounts", "costCodes"],
    onFiltersChange,
    extraDataLoading = false,
    filterProps = {},
    renderAfter,
    renderBefore,
  }: TableFilterControlsProps) => {
    const id = useId();

    const dateDialog = useDialog({ lazy: true });

    const [dateOption, setDateOption] = useState<Option | undefined>();

    const [dateValue, setDateValue] = useState<
      { from?: Date; to?: Date } | undefined
    >();

    const {
      filters: currentFilters,
      addFilter,
      removeFilter,
    } = useFilters(filters);

    const enhancedExtraData = useMemo(
      () => extraData.concat(is.array(withDateFilter) ? withDateFilter : []),
      [withDateFilter, extraData]
    );

    const options = useTableFilterOptions({
      extraData: enhancedExtraData,
      includeFilters,
      extraDataLoading,
    });

    /**
     * We need it to guarantee that we only dispatch change event
     * if user interact with it and not on first render
     */
    const hasInteractedRef = useRef(false);
    const previousFiltersRef = useRef<ValuesFilters | undefined>();

    useEffect(() => {
      if (
        hasInteractedRef.current &&
        !isEqual(previousFiltersRef.current, currentFilters)
      ) {
        onFiltersChange(currentFilters);
        previousFiltersRef.current = currentFilters;
      }
    }, [currentFilters, onFiltersChange]);

    const data = useMemo(
      () =>
        Object.values(pickOptionValues(currentFilters)).map(
          (item) =>
            options.data.find((option) => option.value === item.value) ?? item
        ),
      [currentFilters, options.data]
    );

    const tags = useMemo(
      () => [
        ...fixedTags.map((tag) => ({
          ...tag,
          value: tag.label,
          disabled: true,
        })),
        ...data,
      ],
      [data, fixedTags]
    );

    const hasTags = withFilterTags && tags.length > 0;

    const date = useMemo(() => {
      if (!currentFilters.date_after || !currentFilters.date_before) {
        return { from: undefined, to: undefined };
      }

      return {
        to: parseDate(currentFilters.date_before, "yyyy-MM-dd") as Date,
        from: parseDate(currentFilters.date_after, "yyyy-MM-dd") as Date,
      };
    }, [currentFilters.date_after, currentFilters.date_before]);

    const onChange = useEvent<TableFilterOnChangeHandler>((_, option) => {
      hasInteractedRef.current = true;

      const isDateOption = (
        is.array(withDateFilter) ? withDateFilter : []
      ).find((item) => item.value == option?.value);

      if (isDateOption) {
        setDateOption(option);
        return dateDialog.show();
      }

      addFilter(option);
    });

    const onRemove = useEvent<TableFilterOnRemoveHandler>((option) => {
      hasInteractedRef.current = true;
      removeFilter(option);
    });

    const closeDateDialog = useEvent(async () => {
      await dateDialog.hide();
      setDateValue(undefined);
      setDateOption(undefined);
    });

    const onSubmitDate = useEvent(() => {
      if (!dateValue) return;

      const formattedDate = formatDate(dateValue);

      (is.array(withDateFilter) ? withDateFilter : []).forEach((item) => {
        const option = Object.values(currentFilters).find(
          (filter) => filter?.groupLabel === item.label
        );

        if (option) removeFilter(option);
      });

      /**
       * To be able to adjust the format later, we need to use `label` as key
       */
      addFilter({
        label: dateOption?.value ?? "",
        value: formattedDate,
        groupLabel: dateOption?.label ?? "Unknown",
      });
      closeDateDialog();
    });

    const onDateChange = useEvent((value) => {
      hasInteractedRef.current = true;

      const filters = {
        date_after: formatDate(value.from, "yyyy-MM-dd"),
        date_before: formatDate(value.to, "yyyy-MM-dd"),
      };

      const currentDateFilters = {
        date_after: "",
        date_before: "",
        ...pick(currentFilters, DATE_KEYS),
      };

      const hasChanged = !isEqual(currentDateFilters, filters);

      if (!hasChanged) return;

      addFilter([
        { key: "date_after", value: filters.date_after },
        { key: "date_before", value: filters.date_before },
      ]);
    });

    return (
      <>
        <Flex align="left" direction="column" grow gap="md">
          <Flex
            align="center"
            justify="space-between"
            direction="row"
            gap={size === "md" ? "xl" : "md"}
            height="fit-content"
          >
            {renderBefore?.()}

            <Flex
              width="full"
              grow={filterProps?.grow}
              maxWidth={filterProps?.maxWidth ?? "270px"}
              minWidth={filterProps?.minWidth ?? "120px"}
            >
              <ComboBox
                size={size}
                placeholder="Filter"
                value=""
                data={options.data}
                loading={options.status === "loading"}
                onChange={onChange}
                suffix={false}
                messageVariant="hidden"
                addonAfter={
                  <Icon
                    size={size}
                    name="search"
                    aria-labelledby={suffixify("search-&-filter", "label")}
                  />
                }
                data-testid="filter"
                {...omit(filterProps, ["maxWidth", "grow"])}
              />
            </Flex>

            {withDateFilter === true && (
              <Flex
                width="full"
                grow={dateProps.grow}
                maxWidth={dateProps.maxWidth ?? "275px"}
              >
                <DateField
                  size={size}
                  mode="range"
                  value={date}
                  onChange={onDateChange}
                  placeholder="Filter by date"
                  messageVariant="hidden"
                  {...omit(dateProps, ["maxWidth", "grow"])}
                />
              </Flex>
            )}

            {children && (
              <Flex
                align="center"
                justify="space-between"
                height="full"
                grow
                gap={size === "md" ? "xl" : "md"}
                shrink={false}
              >
                {children}
              </Flex>
            )}

            {renderAfter?.()}
          </Flex>
          {hasTags && (
            <TagGroup
              size={size}
              data={tags}
              render={(item) => {
                const date = parseDate(item.value);
                /**
                 * To show label correctly on the tag when it's a date
                 * we need to use `value` instead of `label`, value in that case
                 * will be the date formatted
                 */
                const label = date ? item.value : item.label;
                return `${item.groupLabel}: ${label}`;
              }}
              onRemove={onRemove}
            />
          )}
        </Flex>
        {dateDialog.isRendered && (
          <Dialog
            show={dateDialog.isVisible}
            variant="dialog"
            size="auto"
            onClose={closeDateDialog}
          >
            <DialogHeader>{dateOption?.label ?? "Unknown"}</DialogHeader>
            <DialogContent>
              <Flex
                id={suffixify(id, "date-form")}
                as={Form}
                width="624px"
                onSubmit={onSubmitDate}
              >
                <DateField
                  mode="range"
                  value={dateValue}
                  autoFocus
                  onChange={setDateValue}
                  placeholder="Filter by date"
                  messageVariant="hidden"
                />
              </Flex>
            </DialogContent>
            <DialogFooter>
              <Button
                block
                color="neutral"
                variant="text"
                onClick={closeDateDialog}
              >
                Cancel
              </Button>
              <Button
                block
                form={suffixify(id, "date-form")}
                type="submit"
                disabled={!dateValue?.from && !dateValue?.to}
              >
                Confirm
              </Button>
            </DialogFooter>
          </Dialog>
        )}
      </>
    );
  }
);

TableFilterControls.displayName = "TableFilterControls";
