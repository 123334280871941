import { createContext, useContext } from "react";
import type { Status } from "@src/bills/types";
import { noop } from "@utils/noop";

type ContextType = {
  next: () => Promise<boolean>;
  total: number;
  enable: (status: Status) => void;
  status: Status | "";
  disable: () => void;
  current?: number;
  previous: () => Promise<boolean>;
  isLoading: boolean;
  hasNavigation: boolean;
};

export const CycleContext = createContext<ContextType>({
  next: noop,
  total: 0,
  status: "",
  enable: noop,
  disable: noop,
  previous: noop,
  isLoading: false,
  hasNavigation: false,
});

export const useCycle = () => useContext(CycleContext);
