import React, {
  type ComponentPropsWithoutRef,
  forwardRef,
  type MouseEventHandler,
} from "react";
import { Badge, Icon } from "@adaptive/design-system";
import { useEvent, useKeydown } from "@adaptive/design-system/hooks";
import { getPlatform, isField } from "@adaptive/design-system/utils";
import * as analytics from "@utils/analytics";

import { SHORTCUT, STRINGS } from "../constants/constants";
import { useNotifications } from "../hooks/use-notifications";
import { useNotificationsDrawer } from "../hooks/use-notifications-drawer";

type Ref = HTMLButtonElement;

type SidebarItemProps = ComponentPropsWithoutRef<"button"> & {
  compact?: boolean;
};

export const SidebarItem = forwardRef<Ref, SidebarItemProps>(
  ({ compact, onClick, ...props }, ref) => {
    const platform = getPlatform();

    const { show, isVisible } = useNotificationsDrawer();

    const { count } = useNotifications({
      query: [{ dataIndex: "read", value: false }],
    });

    const label =
      STRINGS.SIDEBAR_ITEM_LABEL + (platform ? ` (${SHORTCUT[platform]})` : "");

    const enhancedOnClick = useEvent<MouseEventHandler<Ref>>((...args) => {
      show();
      onClick?.(...args);
      analytics.track("notificationsShow", { trigger: "click" });
    });

    useKeydown((e) => {
      if (isField(e.target)) return;

      if (e.code === "KeyI" && (e.metaKey || e.ctrlKey) && !isVisible) {
        show();
        analytics.track("notificationsShow", { trigger: "shortcut" });
      }
    });

    return (
      <button
        id="notifications-button"
        ref={ref}
        type="button"
        onClick={enhancedOnClick}
        {...props}
      >
        <Badge value={count} data-testid="notifications-count">
          <Icon name="bell" />
        </Badge>
        {!compact && label}
      </button>
    );
  }
);

SidebarItem.displayName = "SidebarItem";
