import React, { memo } from "react";
import {
  Button,
  Flex,
  Icon,
  Link,
  type TableRow,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatPercentage,
} from "@adaptive/design-system/utils";
import { useJobPermissions, useJobSettings } from "@src/jobs";

import { CURRENCY_FORMAT, MARKUP_PERCENTAGE_FORMAT } from "../../../constants";
import { EditSeparatePercentMarkupButton } from "../../manage-markup-dialog/edit-separate-percent-markup-button";

import {
  useBudgetsTableActions,
  useBudgetsTableViewMode,
} from "./budgets-table-context";
import { TransactionsCategoryColumn } from "./lines-components";
import type { Markup } from "./markups";

type MarkupsRow = TableRow<Markup>;

export {
  CostsActualColumn,
  CostsRemainingColumn,
  CostsUnpaidColumn,
  EmptyColumn,
} from "./lines-components";

const DeleteButton = memo((row: MarkupsRow) => {
  const { canManage } = useJobPermissions();

  const { curriedOnDeleteMarkup } = useBudgetsTableActions();

  const canDelete = row.id && !row.changeAmount && !row.externalChangeAmount;

  return (
    <Tooltip
      message={
        !canManage
          ? "You don't have permission to do this"
          : !canDelete
            ? "Budget lines that have change amounts cannot be deleted"
            : ""
      }
    >
      <Button
        aria-label={`Delete ${row.jobCostMethod?.displayName}`}
        size="sm"
        disabled={!canManage || !canDelete}
        color="neutral"
        variant="ghost"
        onClick={curriedOnDeleteMarkup(row)}
      >
        <Icon name="trash" />
      </Button>
    </Tooltip>
  );
});

DeleteButton.displayName = "DeleteButton";

/**
 * Transactions components
 */
export const TransactionsColumn = memo((row: MarkupsRow) => {
  const { canManage } = useJobPermissions();

  const viewMode = useBudgetsTableViewMode();

  const { onEditFixedAmountMarkup } = useBudgetsTableActions();

  const { categoriesEnabled, ownersAmountEnabled, changeTrackingEnabled } =
    useJobSettings();

  if ((row.data ?? []).length > 0) return null;

  return (
    <Flex gap="md" align="center" justify="space-between">
      <Flex gap="md" align="center">
        <Text>
          {row.jobCostMethod.displayName}
          {` `}
          {row.id !== "0" &&
            row.markupType === "percentage" &&
            !changeTrackingEnabled && (
              <Text as="span">
                (
                {formatPercentage(
                  (row.ownersValue && ownersAmountEnabled
                    ? row.ownersValue
                    : row.value) ?? 0,
                  MARKUP_PERCENTAGE_FORMAT
                )}
                )
              </Text>
            )}
          {row.markupType === "fixed_amount" && (
            <Text as="span">(Flat fee)</Text>
          )}
        </Text>
      </Flex>
      <Flex gap="md">
        {row.markupType === "percentage" ? (
          <EditSeparatePercentMarkupButton markup={row} />
        ) : row.markupType === "fixed_amount" ? (
          <Tooltip
            message={!canManage ? "You don't have permission to do this" : ""}
          >
            <Button
              size="sm"
              disabled={!canManage}
              color="neutral"
              variant="ghost"
              onClick={() => onEditFixedAmountMarkup(row)}
            >
              <Icon name="pen" />
            </Button>
          </Tooltip>
        ) : null}
        {(!categoriesEnabled || viewMode === "categories") && (
          <DeleteButton {...row} />
        )}
      </Flex>
    </Flex>
  );
});

TransactionsColumn.displayName = "TransactionsColumn";

export const TransactionsColumnControl = memo((row: MarkupsRow) => {
  const viewMode = useBudgetsTableViewMode();

  const { categoriesEnabled } = useJobSettings();

  const hasGroup = (row.data?.length ?? 0) > 0;

  return (
    <Flex gap="md" align="center" shrink={false}>
      {categoriesEnabled && row.budgetLine && (
        <TransactionsCategoryColumn
          mode={hasGroup ? "group" : "markup"}
          {...row.budgetLine}
        />
      )}
      {!hasGroup && viewMode === "items" && <DeleteButton {...row} />}
    </Flex>
  );
});

TransactionsColumnControl.displayName = "TransactionsColumnControl";

/**
 * Costs components
 */
export const CostsBudgetColumn = memo((row: MarkupsRow) => {
  return (
    <Text align="right">
      {formatCurrency(row.builderAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsBudgetColumn.displayName = "CostsBudgetColumn";

export const CostsChangeColumn = memo((row: MarkupsRow) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  const isStatic = !row.changeAmount || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" data-testid="changes">
        {formatCurrency(row.changeAmount, CURRENCY_FORMAT)}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row.budgetLine!)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm" align="right">
          {formatCurrency(row.changeAmount, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

CostsChangeColumn.displayName = "CostsChangeColumn";

export const CostsRevisedBudgetColumn = memo((row: MarkupsRow) => {
  return (
    <Text align="right">
      {formatCurrency(row.builderRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRevisedBudgetColumn.displayName = "CostsRevisedBudgetColumn";

/**
 * Revenues components
 */
export const RevenuesOwnersAmountColumn = memo((row: MarkupsRow) => {
  return (
    <Text align="right">
      {formatCurrency(row.ownersAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesOwnersAmountColumn.displayName = "RevenuesOwnersAmountColumn";

export const RevenuesChangeColumn = memo((row: MarkupsRow) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  const isStatic = !row.externalChangeAmount || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" data-testid="changes">
        {formatCurrency(row.externalChangeAmount, CURRENCY_FORMAT)}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row.budgetLine!)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm" align="right">
          {formatCurrency(row.externalChangeAmount, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesChangeColumn.displayName = "RevenuesChangeColumn";

export const RevenuesRevisedBudgetColumn = memo((row: MarkupsRow) => {
  return (
    <Text align="right">
      {formatCurrency(row.ownersRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesRevisedBudgetColumn.displayName = "RevenuesRevisedBudgetColumn";

/* Invoiced amount */
export const RevenuesInvoicedAmountColumn = memo((row: MarkupsRow) => {
  const { curriedOnSeeDrawnToDate } = useBudgetsTableActions();

  const invoicedAmount = formatCurrency(
    row.budgetLine?.invoicedAmount || 0,
    CURRENCY_FORMAT
  );

  const isStatic =
    !row.budgetLine ||
    row.budgetLine.invoicedAmount <= 0 ||
    (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return <Text align="right">{invoicedAmount}</Text>;
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeDrawnToDate(row.budgetLine)}
        variant="success"
      >
        <Text as="span" size="sm" align="right">
          {invoicedAmount}
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesInvoicedAmountColumn.displayName = "RevenuesInvoicedAmountColumn";

export const RevenuesInvoiceRemainingAmountColumn = memo((row: MarkupsRow) => (
  <Text
    align="right"
    color={row.budgetLine?.remaining >= 0 ? "neutral-800" : "warning-200"}
  >
    {formatCurrency(row.budgetLine?.remaining, CURRENCY_FORMAT)}
  </Text>
));

RevenuesInvoiceRemainingAmountColumn.displayName =
  "RevenuesInvoiceRemainingAmountColumn";
