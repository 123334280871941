import React, { memo, useCallback, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Command,
  CommandGroup,
  CommandItem,
  Link,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { debounce } from "@adaptive/design-system/utils";
import {
  CATEGORIES,
  type SearchPayload,
  type SearchResponse,
  useLazyGetSearchQuery,
} from "@api/search";
import { useAppDispatch } from "@store/hooks";
import { useClientInfo } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";

import { useGlobalSearch } from "./global-search-hooks";

const EMPTY_DATA: SearchResponse = [];

export const GlobalSearch = memo(() => {
  const [value, setValue] = useState("");

  const dispatch = useAppDispatch();

  const [isTyping, setIsTyping] = useState(false);

  const { realmId } = useClientInfo();

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "global-search" });
  });

  const [recentSearches, setRecentSearches] = useState<string[]>(() => {
    const recentSearches = window.IS_E2E
      ? undefined
      : localStorage.getItem("recent-searches");
    return recentSearches ? JSON.parse(recentSearches) : [];
  });

  const [trigger, { data = EMPTY_DATA, isFetching }] = useLazyGetSearchQuery();

  const { isVisible, hide } = useGlobalSearch();

  const debouncedTrigger = useMemo(
    () =>
      debounce(async (payload: SearchPayload) => {
        if (payload.q) await trigger(payload);
        setIsTyping(false);
      }, 300),
    [trigger]
  );

  const onChange = useEvent((value: string) => {
    setValue(value);
    setIsTyping(true);
    debouncedTrigger({ q: value, realm: realmId! });
  });

  const onItemClick = useCallback(
    (url: string) => () => {
      analytics.track("searchGoTo", { url, query: value });
      hide();
      setRecentSearches((previousRecentSearches) => {
        const newRecentSearches = [
          value,
          ...previousRecentSearches.filter(
            (recentSearchValue) => recentSearchValue !== value
          ),
        ];

        localStorage.setItem(
          "recent-searches",
          JSON.stringify(newRecentSearches.slice(0, 5))
        );

        return newRecentSearches;
      });
    },
    [hide, value]
  );

  const curriedOnRecentSearchClick = useEvent((value: string) => () => {
    analytics.track("searchByRecent", { query: value });
    onChange(value);
  });

  return (
    <Command
      show={isVisible}
      value={value}
      onClose={hide}
      loading={isFetching || isTyping}
      onChange={onChange}
      emptyState={{
        title: "No results found",
        subtitle: (
          <>
            Can&apos;t find what you&apos;re looking for?{" "}
            <Link
              as="button"
              type="button"
              variant="success"
              onClick={openChat}
            >
              Tell us here
            </Link>
          </>
        ),
      }}
    >
      {value ? (
        data.length > 0 ? (
          data.map(([key, values = []]) => (
            <CommandGroup key={key} label={CATEGORIES[key]}>
              {values.map(({ to, searchDisplayName }, i) => (
                <CommandItem
                  as={ReactRouterLink}
                  to={to}
                  key={i}
                  onClick={onItemClick(to)}
                >
                  {searchDisplayName}
                </CommandItem>
              ))}
            </CommandGroup>
          ))
        ) : null
      ) : recentSearches.length > 0 ? (
        <CommandGroup label="Recent searches">
          {recentSearches.map((value) => (
            <CommandItem
              key={value}
              onClick={curriedOnRecentSearchClick(value)}
            >
              {value}
            </CommandItem>
          ))}
        </CommandGroup>
      ) : null}
    </Command>
  );
});

GlobalSearch.displayName = "GlobalSearch";
