import React, { type ComponentPropsWithoutRef } from "react";
import { Flex, Text } from "@adaptive/design-system";
import cn from "clsx";

import styles from "./circle.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent>;

export const Circle = ({ children, className, ...props }: Props) => (
  <Flex
    shrink={false}
    justify="center"
    className={cn(className, styles["circle"])}
    {...props}
  >
    <Text size="sm">{children}</Text>
  </Flex>
);
