import React, { useLayoutEffect, useRef } from "react";
import {
  forwardRefAs,
  getAllFocusableElements,
  mergeRefs,
} from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./main.module.css";

type Props = { focusable?: boolean };

const DEFAULT_COMPONENT = "div";

export const Main = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    { as: Component = DEFAULT_COMPONENT, className, autoFocus, ...props },
    ref
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      requestAnimationFrame(() => {
        if (!internalRef.current || window.IS_E2E) return;

        if (autoFocus) {
          internalRef.current.tabIndex = -1;
          internalRef.current.focus();
          return;
        }

        const autoFocusableEl =
          internalRef.current.querySelector("[autofocus]");

        if (autoFocusableEl) return;

        const [first] = getAllFocusableElements(internalRef.current);

        if (first) first.focus();
      });
    }, [autoFocus]);

    return (
      <Component
        ref={mergeRefs(ref, internalRef)}
        className={cn(className, styles["main"])}
        {...props}
      />
    );
  }
);
