import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { Link as ReactRouterLink, Outlet, useParams } from "react-router-dom";
import { Button, Loader } from "@adaptive/design-system";
import { pick } from "@adaptive/design-system/utils";
import {
  getNonFieldErrors,
  handleErrors,
  isNonFieldErrors,
} from "@api/handle-errors";
import {
  type InvoiceGetMarkupsResponse,
  invoicesApi,
  isAbortError,
  useCreateInvoiceLinesFromTransactionsMutation,
  useDeleteInvoiceMutation,
  useEvaluateInvoiceMutation,
  useGetInvoiceLinesQuery,
  useGetInvoiceMarkupsQuery,
  useGetInvoiceQuery,
  useSyncTransactionsMutation,
  useUpdateInvoiceInfoMutation,
  useUpdateInvoiceLinesMutation,
  useUpdateInvoiceMarkupsMutation,
  useUpdateInvoiceMutation,
} from "@api/invoices";
import { Layout } from "@components/layout";
import { NotFound } from "@components/not-found";
import { BasePermissions, useUserInfo } from "@src/shared/store/user";
import { useAppDispatch } from "@store/hooks";
import { noop } from "@utils/noop";
import type { AnyAction } from "redux";

import type { Comment, Line } from ".";
import {
  type AddInvoiceCommentHandler,
  type CreateInvoiceLinesFromTransactionsHandler,
  type DeleteInvoiceHandler,
  type EvaluateInvoiceHandler,
  InvoiceContext,
  type SyncTransactionsHandler,
  type UpdateInvoiceHandler,
  type UpdateInvoiceInfoHandler,
  type UpdateInvoiceLinesHandler,
  type UpdateInvoiceMarkupsHandler,
} from "./invoice-context";

const EMPTY_INVOICE_LINES: Line[] = [];

const EMPTY_INVOICE_MARKUPS: InvoiceGetMarkupsResponse = [];

export const Invoice = memo(() => {
  const abortUpdateInvoiceLinesRef = useRef<() => void>(noop);

  const updateInvoiceLinesQueueRef = useRef<Promise<unknown>[]>([]);

  const dispatch = useAppDispatch();

  const { hasPermission } = useUserInfo();

  const { jobId, invoiceId } = useParams();

  const [linesSelected, setLinesSelected] = useState<Line[]>([]);

  const [invoiceLinesRequests, setInvoiceLinesRequests] = useState(0);

  const { refetch: invoiceQueryRefetch, ...invoiceQuery } = useGetInvoiceQuery(
    { invoiceId: invoiceId as string },
    { skip: invoiceId === undefined, refetchOnMountOrArgChange: true }
  );

  const {
    data: invoiceLines = EMPTY_INVOICE_LINES,
    isLoading: invoiceLinesIsLoading,
  } = useGetInvoiceLinesQuery(
    { invoiceId: invoiceId as string },
    { skip: invoiceId === undefined, refetchOnFocus: true }
  );

  const {
    data: invoiceMarkups = EMPTY_INVOICE_MARKUPS,
    isLoading: invoiceMarkupsIsLoading,
  } = useGetInvoiceMarkupsQuery(
    { invoiceId: invoiceId as string },
    { skip: invoiceId === undefined }
  );

  const [updateInvoiceLinesMutation] = useUpdateInvoiceLinesMutation();

  const [updateInvoiceMarkupsMutation] = useUpdateInvoiceMarkupsMutation();

  const [deleteInvoiceMutation, { isLoading: deleteInvoiceMutationIsLoading }] =
    useDeleteInvoiceMutation({
      selectFromResult: ({ isLoading }) => ({ isLoading }),
    });

  const [updateInvoiceMutation, { isLoading: updateInvoiceMutationIsLoading }] =
    useUpdateInvoiceMutation({
      selectFromResult: ({ isLoading }) => ({ isLoading }),
    });

  const [updateInvoiceInfoMutation] = useUpdateInvoiceInfoMutation();
  const [syncTransactionsMutation, { isLoading: syncTransactionsIsLoading }] =
    useSyncTransactionsMutation();

  const [
    evaluateInvoiceMutation,
    { isLoading: evaluateInvoiceMutationIsLoading },
  ] = useEvaluateInvoiceMutation({
    selectFromResult: ({ isLoading }) => ({ isLoading }),
  });

  const permissions = useMemo(
    () => ({
      canApproveInvoices: hasPermission(
        BasePermissions.SYNC_INVOICES_TO_ACCOUNTING_SOFTWARE
      ),
      canBypassApprovalWorkflows: hasPermission(
        BasePermissions.BYPASS_APPROVAL_WORKFLOWS
      ),
    }),
    [hasPermission]
  );

  const isLoading =
    deleteInvoiceMutationIsLoading ||
    updateInvoiceMutationIsLoading ||
    syncTransactionsIsLoading ||
    evaluateInvoiceMutationIsLoading;

  const invoiceIsLoading = invoiceQuery.isFetching || invoiceLinesRequests > 0;

  const refetchInvoice = useCallback(async () => {
    await invoiceQueryRefetch();
  }, [invoiceQueryRefetch]);

  const updateInvoice = useCallback<UpdateInvoiceHandler>(
    async (invoice) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");
      try {
        return await updateInvoiceMutation({
          id: invoiceQuery.data.id,
          reviewStatus:
            invoiceQuery.data.reviewStatus !== "Paid"
              ? "Draft"
              : invoiceQuery.data.reviewStatus,
          ...invoice,
        }).unwrap();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [invoiceQuery.data, updateInvoiceMutation]
  );

  const updateInvoiceLines = useCallback<UpdateInvoiceLinesHandler>(
    async (payload) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      const lines = "lines" in payload ? payload.lines : payload;

      let promise: Promise<unknown>;

      try {
        const controller = new AbortController();
        const signal = controller.signal;

        setInvoiceLinesRequests((prev) => prev + 1);

        abortUpdateInvoiceLinesRef.current();

        promise = updateInvoiceLinesMutation({
          id: invoiceQuery.data.id,
          lines,
          queue: [...updateInvoiceLinesQueueRef.current],
          signal,
        }).unwrap();

        updateInvoiceLinesQueueRef.current.push(promise);
        abortUpdateInvoiceLinesRef.current = () => controller.abort();

        await promise;
      } catch (e) {
        if (!isAbortError(e)) {
          handleErrors(e);
          throw e;
        }
      } finally {
        updateInvoiceLinesQueueRef.current =
          updateInvoiceLinesQueueRef.current.filter((p) => p !== promise);
        setInvoiceLinesRequests((prev) => prev - 1);
      }
    },
    [invoiceQuery.data, updateInvoiceLinesMutation]
  );

  const updateInvoiceMarkups = useCallback<UpdateInvoiceMarkupsHandler>(
    async (markups) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      try {
        return await updateInvoiceMarkupsMutation({
          id: invoiceQuery.data.id,
          markups,
        }).unwrap();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [invoiceQuery.data, updateInvoiceMarkupsMutation]
  );

  const updateInvoiceInfo = useCallback<UpdateInvoiceInfoHandler>(
    async (invoice) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      try {
        return await updateInvoiceInfoMutation({
          id: invoiceQuery.data.id,
          reviewStatus:
            invoiceQuery.data.reviewStatus !== "Paid"
              ? "Draft"
              : invoiceQuery.data.reviewStatus,
          ...pick(invoice, [
            "date",
            "title",
            "toName",
            "dueDate",
            "docNumber",
            "description",
          ]),
        }).unwrap();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [invoiceQuery.data, updateInvoiceInfoMutation]
  );

  const addInvoiceComment = useCallback<AddInvoiceCommentHandler>(
    ({ author, text, id, url, parentCommentUrl, files }) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      const action = invoicesApi.util.updateQueryData(
        "getInvoice",
        { invoiceId: invoiceQuery.data.id },
        (invoice) => {
          const newComment: Comment = {
            text,
            author,
            id: id || "",
            url,
            files,
            createdAt: new Date(),
            timelineEventType: "COMMENT",
            attachable: "",
          };
          if (parentCommentUrl) {
            invoice.comments = invoice.comments.map((comment) =>
              comment.url === parentCommentUrl
                ? {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  }
                : comment
            );
          } else {
            invoice.comments.push({ ...newComment, replies: [] });
          }
        }
      ) as unknown as AnyAction;

      dispatch(action);
    },
    [dispatch, invoiceQuery.data]
  );

  const deleteInvoice = useCallback<DeleteInvoiceHandler>(async () => {
    if (!invoiceQuery.data) throw new Error("Invalid invoice");

    try {
      await deleteInvoiceMutation({ id: invoiceQuery.data.id }).unwrap();
    } catch (e) {
      handleErrors(e);
      throw e;
    }
  }, [deleteInvoiceMutation, invoiceQuery.data]);

  const syncTransactions = useCallback<SyncTransactionsHandler>(
    async (sync: boolean) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      try {
        return await syncTransactionsMutation({
          id: invoiceQuery.data.id,
          sync,
        }).unwrap();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [syncTransactionsMutation, invoiceQuery.data]
  );
  const onSetLinesSelected = useCallback((lines: Line[]) => {
    setLinesSelected(lines);
  }, []);

  const evaluateInvoice = useCallback<EvaluateInvoiceHandler>(
    async (payload) => {
      if (!invoiceQuery.data) throw new Error("Invalid invoice");

      try {
        await evaluateInvoiceMutation({
          ...payload,
          invoiceId: invoiceQuery.data.id,
        }).unwrap();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [evaluateInvoiceMutation, invoiceQuery.data]
  );

  const [createInvoiceLinesFromTransactionsMutation] =
    useCreateInvoiceLinesFromTransactionsMutation();

  const createInvoiceLinesFromTransactions =
    useCallback<CreateInvoiceLinesFromTransactionsHandler>(
      async (invoiceLines) => {
        if (!invoiceQuery.data) throw new Error("Invalid invoice");
        try {
          await createInvoiceLinesFromTransactionsMutation({
            invoiceLines,
            invoiceId: invoiceQuery.data.id,
          }).unwrap();
        } catch (e) {
          handleErrors(e);
          throw e;
        }
      },
      [createInvoiceLinesFromTransactionsMutation, invoiceQuery.data]
    );

  if (invoiceQuery.isLoading) return <Loader position="absolute" />;

  if (isNonFieldErrors(invoiceQuery.error)) {
    const errors = getNonFieldErrors(invoiceQuery.error);

    if (errors.includes("Not supported")) {
      return (
        <Layout
          title="Unsupported transactions"
          subtitle="We do not yet support invoices with account based transactions. Please view these invoices directly in QuickBooks."
        >
          <Button as={ReactRouterLink} to={`/jobs/${jobId}`} replace>
            Go to jobs
          </Button>
        </Layout>
      );
    }
  }

  if (!invoiceId || invoiceQuery.isError || !invoiceQuery.data) {
    return <NotFound to={`/jobs/${jobId}`} resource="jobs" />;
  }

  return (
    <InvoiceContext.Provider
      value={{
        invoice: invoiceQuery.data,
        permissions,
        invoiceLines,
        invoiceLinesIsLoading,
        invoiceMarkupsIsLoading,
        invoiceLinesSelected: linesSelected,
        setInvoiceLinesSelected: onSetLinesSelected,
        updateInvoice,
        deleteInvoice,
        invoiceMarkups,
        refetchInvoice,
        evaluateInvoice,
        invoiceIsLoading,
        addInvoiceComment,
        updateInvoiceInfo,
        updateInvoiceLines,
        updateInvoiceMarkups,
        syncTransactions,
        createInvoiceLinesFromTransactions,
      }}
    >
      {isLoading && <Loader position="absolute" />}
      <Outlet />
    </InvoiceContext.Provider>
  );
});

Invoice.displayName = "Invoice";
