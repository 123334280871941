import React, { memo } from "react";
import {
  Button,
  Flex,
  Icon,
  type TableRow,
  TagGroup,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { type Change } from "@api/jobs/changes";
import { CURRENCY_FORMAT, PERMISSION_STRINGS, useJobSettings } from "@src/jobs";
import { useJobPermission } from "@src/jobs";
import { useChange } from "@src/jobs/detail-view/budget/changes-dialog/changes-dialog-context";
import { sum } from "@utils/sum";

type ChangeRow = TableRow<Change>;

export const CostCodeAccountsColumn = memo((row: ChangeRow) => {
  const budgetLine = useChange("budgetLine");

  const linesAndMarkups = [...row.lines, ...row.markups].filter(
    (line, index, self) =>
      index ===
      self.findIndex(
        (selfLine) => selfLine.jobCostMethod.url === line.jobCostMethod.url
      )
  );

  const lineMatchesBudgetLine = linesAndMarkups.find(
    (line) => line.jobCostMethod.url === budgetLine?.jobCostMethod?.url
  );

  const props = {
    data: linesAndMarkups,
    limit: 1,
    render: "jobCostMethod.displayName",
  };

  if (lineMatchesBudgetLine) {
    const index = linesAndMarkups.indexOf(lineMatchesBudgetLine);

    props.data = [
      lineMatchesBudgetLine,
      ...linesAndMarkups.slice(0, index),
      ...linesAndMarkups.slice(index + 1),
    ];

    return (
      <TagGroup
        {...props}
        color={(line) =>
          line.jobCostMethod.url === budgetLine?.jobCostMethod?.url
            ? "info"
            : "neutral"
        }
      />
    );
  }

  return <TagGroup {...props} />;
});

CostCodeAccountsColumn.displayName = "CostCodeAccountsColumn";

export const DescriptionColumn = memo((row: ChangeRow) => {
  return (
    <Flex width="200px">
      <Text truncate>{row.description || "-"}</Text>
    </Flex>
  );
});

DescriptionColumn.displayName = "DescriptionColumn";

export const ChangeAmountColumn = memo((row: ChangeRow) => {
  const { ownersAmountEnabled: externalBudgetEnabled } = useJobSettings();

  const changeTotalAmount = row.lines.reduce(
    (acc, line) => sum(acc, line.builderAmount),
    0
  );

  let changeMarkupTotalAmount = 0;

  if (!externalBudgetEnabled) {
    changeMarkupTotalAmount = row.markups.reduce((acc, markup) => {
      let value = markup.value;

      if (markup.markupType === "percentage") {
        value = (markup.changeLineJobCostMethods ?? []).reduce(
          (total, jobCostMethodUrl) => {
            const matchingLinesSum = row.lines.reduce(
              (acc, matchingLine) =>
                matchingLine.jobCostMethod.url === jobCostMethodUrl
                  ? sum(acc, matchingLine?.builderAmount)
                  : acc,
              0
            );

            return sum(total, (matchingLinesSum * value) / 100);
          },
          0
        );
      }

      return sum(acc, value);
    }, 0);
  }

  return (
    <Text align="right">
      {formatCurrency(
        sum(changeTotalAmount, changeMarkupTotalAmount),
        CURRENCY_FORMAT
      )}
    </Text>
  );
});

ChangeAmountColumn.displayName = "ChangeAmountColumn";

export const ChangeAmountFooter = memo(() => {
  const totalAmount = useChange("totalAmount");

  return formatCurrency(totalAmount, CURRENCY_FORMAT);
});

ChangeAmountFooter.displayName = "ChangeAmountFooter";

export const ChangeRevisedAmountColumn = memo((row: ChangeRow) => {
  const changeTotalAmount = row.lines.reduce(
    (acc, line) => sum(acc, line.externalAmount),
    0
  );

  const changeMarkupTotalAmount = row.markups.reduce((acc, markup) => {
    let value = markup.value;

    if (markup.markupType === "percentage") {
      value = (markup.changeLineJobCostMethods ?? []).reduce(
        (total, jobCostMethodUrl) => {
          const matchingLinesSum = row.lines.reduce(
            (acc, matchingLine) =>
              matchingLine.jobCostMethod.url === jobCostMethodUrl
                ? sum(acc, matchingLine?.externalAmount)
                : acc,
            0
          );

          return sum(total, (matchingLinesSum * value) / 100);
        },
        0
      );
    }

    return sum(acc, value);
  }, 0);

  return (
    <Text align="right">
      {formatCurrency(
        sum(changeTotalAmount, changeMarkupTotalAmount),
        CURRENCY_FORMAT
      )}
    </Text>
  );
});

ChangeRevisedAmountColumn.displayName = "ChangeRevisedAmountColumn";

export const DateColumn = memo((row: ChangeRow) => {
  return <Text>{formatDate(row.date)}</Text>;
});

DateColumn.displayName = "DateColumn";

export const ChangeRevisedAmountFooter = memo(() => {
  const totalExternalAmount = useChange("totalExternalAmount");

  return formatCurrency(totalExternalAmount, CURRENCY_FORMAT);
});

ChangeRevisedAmountFooter.displayName = "ChangeRevisedAmountFooter";

/* Actions components */
export const ActionColumn = memo((row: ChangeRow) => {
  const onOpenChangeForStep = useChange("onOpenChangeForStep");
  const { canManageJobs } = useJobPermission();

  const change = {
    id: row.id,
    docNumber: row.docNumber,
    description: row.description ?? "",
    date: row.date,
    lines: row.lines.map((row) => ({
      ...(row.id ? { id: row.id } : {}),
      amount: row.builderAmount,
      externalAmount: row.externalAmount,
      jobCostMethod: row.jobCostMethod.url,
    })) ?? [{ jobCostMethod: "", amount: 0 }],
    markups: row.markups.map((row) => ({
      ...row,
      jobCostMethod: row.jobCostMethod.url,
    })),
  };

  return (
    <Flex gap="md">
      <Button
        color="neutral"
        size="sm"
        variant="ghost"
        onClick={onOpenChangeForStep(change, "edit-change")}
      >
        <Icon name={!canManageJobs ? "eye" : "pen"} />
      </Button>
      <Tooltip
        message={canManageJobs ? undefined : PERMISSION_STRINGS.NO_PERMISSION}
        placement="left"
      >
        <Button
          color="neutral"
          disabled={!canManageJobs}
          size="sm"
          variant="ghost"
          onClick={onOpenChangeForStep(change, "delete-change")}
        >
          <Icon name="trash" />
        </Button>
      </Tooltip>
    </Flex>
  );
});

ActionColumn.displayName = "ActionColumn";
