import { expenseItemSchema, reviewStatus } from "@api/expenses/response";
import { idSchema } from "@utils/schema";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { z } from "zod";

export const getExpensesPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

/**
 * @todo we should remove preprocess as soon as we refactor the expenses code
 */
export const getExpensesResponseSchema = z.preprocess(
  (obj) => transformKeysToSnakeCase(obj as object),
  z.object({
    count: z.number(),
    results: expenseItemSchema.array(),
  })
);

export const updateExpensePayloadSchema = z.object({
  id: idSchema,
  lines: z
    .object({
      item: z.string().url().nullish(),
      amount: z.number(),
      account: z.string().url().nullish(),
      customer: z.string().url().nullish(),
    })
    .array()
    .nullish(),
  vendor: z.string().url().nullish(),
  reviewStatus: reviewStatus.nullish(),
  cardTransaction: z.string().url().nullish(),
  isTransactionGeneratedDraft: z.boolean().nullish(),
});
