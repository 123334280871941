import React, { memo, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  Link,
  Table,
  type TableColumn,
  Tag,
  Text,
} from "@adaptive/design-system";
import { useDeepMemo, useDialog } from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import {
  type BillableExpense,
  type BillableExpenseLine,
  useGetInvoiceBillableExpensesQuery,
} from "@api/invoices";
import { getTransactionRoute } from "@src/bills/utils";
import { CURRENCY_FORMAT } from "@src/jobs";
import { humanReadableReviewStatus } from "@src/shared/utils/usefulFunctions";

const isLine = (data: unknown): data is BillableExpenseLine =>
  typeof data === "object" && data !== null && !("data" in data);

const COLUMNS: TableColumn<BillableExpense>[] = [
  {
    id: "reference",
    name: "Ref #",
    render: (row) => (
      <Text
        as={Link}
        rel="noreferrer"
        href={getTransactionRoute(row.parent.url)}
        target="_blank"
        truncate={2}
      >
        <Text as="span" size="sm">
          {row.parent.docNumber}
        </Text>
      </Text>
    ),
  },
  {
    id: "vendor",
    name: "Vendor name",
    render: (row) => row.parent.vendor?.displayName ?? "Unknown vendor",
  },
  {
    id: "jobCostMethod",
    name: "Cost code / Account",
    render: (row) =>
      row.item?.displayName ||
      row.account?.displayName ||
      "Unknown Cost code / Account",
  },
  {
    id: "date",
    name: "Date",
    render: (row) => formatDate(row.parent.date),
  },
  {
    id: "type",
    name: "Type",
    render: "parent.humanReadableType",
  },
  {
    id: "status",
    name: "Status",
    render: (row) => (
      <Tag color={row.parent.reviewStatus === "Paid" ? "success" : "neutral"}>
        {humanReadableReviewStatus(
          row.parent.humanReadableType,
          row.parent.reviewStatus
        )}
      </Tag>
    ),
  },
  {
    id: "amount",
    name: "Cost",
    width: 140,
    textAlign: "right",
    render: (row) =>
      formatCurrency(isLine(row) ? row.amount : row.amount, CURRENCY_FORMAT),
  },
];

type InvoiceTransactionDialogProps = {
  jobId: number | string;
  onClose: () => void;
  invoiceId?: number | string;
};

export const InvoiceTransactionsDialog = memo(
  ({ jobId, onClose, invoiceId }: InvoiceTransactionDialogProps) => {
    const { set, ...dialog } = useDialog({ lazy: true });

    const { data, isLoading } = useGetInvoiceBillableExpensesQuery(
      {
        invoiceId: invoiceId as string,
        customerId: jobId,
      },
      { refetchOnMountOrArgChange: true, skip: !invoiceId }
    );

    const memoizedData = useDeepMemo(() => data ?? [], [data]);

    useEffect(() => {
      set(!!invoiceId);
    }, [set, invoiceId]);

    return dialog.isRendered ? (
      <Dialog
        size="auto"
        show={dialog.isVisible}
        variant="dialog"
        onClose={onClose}
      >
        <DialogHeader>Transactions</DialogHeader>
        <DialogContent>
          <Flex minWidth="800px" direction="column">
            <Table
              id="invoice-transactions-dialog-table"
              size="sm"
              data={memoizedData}
              maxHeight="600px"
              loading={isLoading}
              columns={COLUMNS}
            />
          </Flex>
        </DialogContent>
      </Dialog>
    ) : null;
  }
);

InvoiceTransactionsDialog.displayName = "InvoiceTransactionsDialog";
