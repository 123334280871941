type NoopHandler = (...args: any[]) => any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const noop: NoopHandler = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

export const curriedNoop = () => noop;

export const asyncNoop = (): Promise<void> => {
  return new Promise((resolve) => {
    resolve();
  });
};
