import React, {
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { Label } from "../label";
import { VisuallyHidden } from "../visually-hidden";

import styles from "./checkbox.module.css";

type DefaultComponent = "input";

type Ref = ComponentRef<DefaultComponent>;

type Props = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "onChange" | "value"
> & {
  label?: ReactNode;
  value?: string;
  onChange?: (checked: boolean) => void;
  placement?: "left" | "right" | "top" | "bottom";
  hintMessage?: ReactNode;
  errorMessage?: ReactNode;
  indeterminate?: boolean;
  "data-testid"?: string;
};

export const Checkbox = forwardRef<Ref, Props>(
  (
    {
      id,
      style,
      label,
      checked,
      disabled,
      onChange,
      required,
      placement = "right",
      className,
      hintMessage,
      errorMessage,
      indeterminate,
      "aria-hidden": hidden,
      "data-testid": testId,
      "aria-labelledby": labelledBy,
      ...props
    },
    ref
  ) => {
    const internalId = useId();
    const internalRef = useRef<Ref>(null);

    const [internalChecked, setInternalChecked] = useState(false);

    const enhancedId = id ?? internalId;

    const enhancedChecked = checked ?? internalChecked;

    const enhancedOnChange = useEvent<ChangeEventHandler<Ref>>((e) => {
      setInternalChecked(e.currentTarget.checked);
      onChange?.(e.currentTarget.checked);
    });

    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.indeterminate = Boolean(indeterminate);
      }
    }, [indeterminate]);

    return (
      <div
        style={style}
        className={cn(className, styles["wrapper"], {
          [styles["-checked"]]: enhancedChecked,
          [styles["-disabled"]]: disabled,
          [styles["-no-events"]]: hidden === "true",
          [styles["-indeterminate"]]: indeterminate,
          [styles[`-${placement}`]]: placement,
        })}
      >
        {label && (
          <Label
            id={suffixify(enhancedId, "label")}
            htmlFor={enhancedId}
            variant={errorMessage && !disabled ? "error" : "neutral"}
            required={required}
            hintMessage={hintMessage}
            data-testid={suffixify(testId, "label")}
            className={styles["label"]}
          >
            {label}
          </Label>
        )}
        <label
          className={styles["checkbox"]}
          data-testid={!label ? suffixify(testId, "label") : undefined}
        >
          <VisuallyHidden>
            <input
              id={enhancedId}
              ref={mergeRefs(ref, internalRef)}
              onChange={enhancedOnChange}
              type="checkbox"
              checked={enhancedChecked}
              required={required}
              disabled={disabled}
              aria-hidden={hidden}
              data-testid={testId}
              aria-checked={enhancedChecked ? "true" : "false"}
              aria-invalid={!!errorMessage}
              aria-labelledby={labelledBy ?? suffixify(enhancedId, "label")}
              {...props}
            />
          </VisuallyHidden>
        </label>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";
