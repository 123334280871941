import React, { useEffect } from "react";
import { Flex, Text } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  RequestVendorDocumentAlert,
  RequestVendorDocumentButton,
} from "@components/request-vendor-document";
import {
  VendorDocumentForm,
  type VendorDocumentFormProps,
} from "@components/vendor-document-form";
import { useAppSelector } from "@store/hooks";
import { useDocumentAction, useVendorInfo } from "@store/vendors";
import {
  selectVendorDocumentItems,
  vendorSelector,
} from "@store/vendors/selectors";

import { STRINGS } from "../constants";

import { DocumentPreview } from "./item";

const DocumentPreviews = () => {
  const documents = useAppSelector(selectVendorDocumentItems);

  return documents.map((document) => (
    <DocumentPreview key={document.id} {...document} />
  ));
};

export const Documents = () => {
  const { info } = useAppSelector(vendorSelector);

  const { canManageNonPaymentInfo, setHasUnsavedDocuments } = useVendorInfo();

  const { addDocument, syncDocuments } = useDocumentAction();

  const onSave = useEvent<VendorDocumentFormProps["onSave"]>(
    async ({ file, type, expirationDate }) => {
      setHasUnsavedDocuments(false);

      const { payload } = addDocument({
        id: file?.name || "",
        type,
        document: file,
        expirationDate,
      });

      if (info.id) await syncDocuments({ added: [payload] });
    }
  );

  useEffect(() => {
    return () => setHasUnsavedDocuments(false);
  }, [setHasUnsavedDocuments]);

  return (
    <Flex gap="xl" direction="column">
      <RequestVendorDocumentAlert />

      <DocumentPreviews />

      <Flex gap="xl" direction="column">
        <Flex gap="xl" justify="space-between" align="center">
          <Text weight="bold">{STRINGS.HEADER_ADD_DOCS}</Text>
          <RequestVendorDocumentButton />
        </Flex>
        <VendorDocumentForm
          onSave={onSave}
          onChange={(values) => {
            const hasChanges = !!(
              values.file ||
              values.type ||
              values.expirationDate
            );
            setHasUnsavedDocuments(hasChanges);
          }}
          disabled={!canManageNonPaymentInfo}
        />
      </Flex>
    </Flex>
  );
};
