import React, {
  type ComponentPropsWithoutRef,
  createContext,
  type MutableRefObject,
  type ReactNode,
  useMemo,
  useRef,
} from "react";
import {
  useEvent,
  useMutationObserver,
  useResizeObserver,
} from "@adaptive/design-system/hooks";
import { debounce } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./sticky.module.css";

const StickContext = createContext<{
  enabled: boolean;
  stickRef: MutableRefObject<HTMLDivElement | null>;
  measureRef: MutableRefObject<HTMLDivElement | null>;
}>({
  enabled: true,
  stickRef: { current: null },
  measureRef: { current: null },
});

export type StickyProviderOnResizeHandler = (props: {
  sticky: DOMRect;
  measurer: DOMRect;
}) => void;

export type StickyProviderProps = {
  enabled?: boolean;
  children: ReactNode;
  onResize?: StickyProviderOnResizeHandler;
};

export const StickyProvider = ({
  enabled = true,
  onResize,
  ...props
}: StickyProviderProps) => {
  const stickRef = useRef<HTMLDivElement>(null);

  const measureRef = useRef<HTMLDivElement>(null);

  const latestVirtualWidth = useRef(0);

  const enhancedOnResize = useEvent<StickyProviderOnResizeHandler>((params) => {
    onResize?.(params);
  });

  const updateVirtualWidth = useMemo(
    () =>
      debounce((el: HTMLElement) => {
        const width = el.scrollWidth;
        const hasChanged =
          !latestVirtualWidth.current || latestVirtualWidth.current !== width;

        if (!stickRef.current || !hasChanged) return;

        enhancedOnResize?.({
          sticky: stickRef.current.getBoundingClientRect(),
          measurer: el.getBoundingClientRect(),
        });

        latestVirtualWidth.current = width;
        stickRef.current.style.setProperty(
          "--sticky-virtual-width",
          `${width}px`
        );
      }, 100),
    [enhancedOnResize]
  );

  useResizeObserver(measureRef, updateVirtualWidth);

  useResizeObserver(stickRef, (el) => {
    if (!measureRef.current) return;

    enhancedOnResize?.({
      sticky: el.getBoundingClientRect(),
      measurer: measureRef.current.getBoundingClientRect(),
    });
  });

  useMutationObserver(
    measureRef,
    () => measureRef.current && updateVirtualWidth(measureRef.current)
  );

  return (
    <StickContext.Provider
      value={{ enabled, stickRef, measureRef }}
      {...props}
    />
  );
};

export type StickyProps = ComponentPropsWithoutRef<"div">;

export const Sticky = ({ className, ...props }: StickyProps) => (
  <StickContext.Consumer>
    {({ stickRef, enabled }) => (
      <div
        ref={stickRef}
        className={cn(className, styles["sticky"], {
          [styles["-enabled"]]: enabled,
        })}
        {...props}
      />
    )}
  </StickContext.Consumer>
);

export type StickyMeasurerProps = ComponentPropsWithoutRef<"div">;

export const StickyMeasurer = (props: StickyMeasurerProps) => (
  <StickContext.Consumer>
    {({ measureRef }) => <div ref={measureRef} {...props} />}
  </StickContext.Consumer>
);
