import React, { forwardRef } from "react";

import AdaptiveCircleSvg from "./adaptive-circle-icon.svg?react";
import AdaptiveDarkCircleSvg from "./adaptive-dark-circle-icon.svg?react";
import AdaptiveTextSvg from "./adaptive-text-icon.svg?react";

type AdaptiveIconProps = {
  width?: number;
  height?: number;
  variant?: "circle" | "dark-circle" | "text";
};

export const AdaptiveIcon = forwardRef<SVGSVGElement, AdaptiveIconProps>(
  ({ width = 24, height = 24, variant = "text", ...props }, ref) => {
    const enhancedProps = {
      ...props,
      ref,
      role: "img",
      style: { verticalAlign: "middle" },
      "aria-label": "Adaptive",
    };

    if (variant === "circle") {
      return (
        <AdaptiveCircleSvg width={width} height={height} {...enhancedProps} />
      );
    }

    if (variant === "dark-circle") {
      return (
        <AdaptiveDarkCircleSvg
          width={width}
          height={height}
          {...enhancedProps}
        />
      );
    }

    return (
      <AdaptiveTextSvg
        width={width ?? 94}
        height={height ?? 24}
        {...enhancedProps}
      />
    );
  }
);

AdaptiveIcon.displayName = "AdaptiveIcon";
