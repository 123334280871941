import React, {
  type ComponentProps,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Flex,
  Table as DSTable,
  type TableColumn,
  TableConfigurationButton,
  type TableEmptyState,
  type TableHeaderAddon,
  TableProvider,
  type TableRowAddon,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { DropZone } from "@components/draggable/draggable";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import type { ValuesFilters } from "@components/table-filter/types";
import { DROPZONE_MESSAGES, LIST_IMAGE_FORMATS } from "@src/bills/constants";
import { uploadAttachable } from "@store/billSlice";
import type { AnyAction } from "redux";

import type { Bill, Filter } from "../../types";
import { isLoadingBillOcr } from "../../utils";
import { isErrorBill } from "../../utils";
import { BatchActions } from "../batch-actions";
import { BillsTabHeader } from "../bills-tab-header";

export type TableProps = {
  data: Bill[];
  order: {
    value: string;
    onChange: (value: string) => void;
  };
  filter: Filter;
  status: string;
  onDrop?: () => void;
  columns: TableColumn<Bill>[];
  loading: boolean;
  isError: boolean;
  onSelect: (data: Bill[]) => void;
  rowRender: Exclude<TableRowAddon<Bill>["render"], undefined>;
  droppable?: boolean;
  pagination: ComponentProps<typeof DSTable>["pagination"];
  selectedRows: Bill[];
  onFiltersChange: (filters: ValuesFilters) => void;
  emptyStateAction?: ReactNode;
  requiresYourApproval?: boolean;
  onRequiresYourApprovalChange?: (requiresYourApproval: boolean) => void;
  onGetBillsWithErrors?: () => void;
};

export const Table = ({
  data,
  order,
  filter,
  status,
  onDrop,
  columns,
  isError,
  loading,
  onSelect,
  droppable = false,
  rowRender,
  pagination,
  selectedRows,
  onFiltersChange,
  emptyStateAction,
  requiresYourApproval,
  onRequiresYourApprovalChange,
  onGetBillsWithErrors,
}: TableProps) => {
  const dispatch = useDispatch();

  const [stickOffset, setStickyOffset] = useState(0);

  const hasData = data.length > 0;

  const hasFilters =
    Object.values(filter.values).some((filter) => !!filter) ||
    requiresYourApproval;

  const header = useMemo<TableHeaderAddon>(
    () => ({
      hide: !hasData,
      sticky: { offset: stickOffset },
    }),
    [hasData, stickOffset]
  );

  const select = useMemo(
    () => ({ value: selectedRows, onChange: onSelect }),
    [onSelect, selectedRows]
  );

  const row = useMemo(
    () => ({
      render: rowRender,
      isLoading: isLoadingBillOcr,
      isError: isErrorBill,
    }),
    [rowRender]
  );

  const emptyState = useMemo<TableEmptyState>(() => {
    if (isError) return "error";

    if (hasFilters) {
      return {
        title: "No bills match your filters",
        subtitle:
          "Try adjusting your search or filters to find what you're looking for",
        action: {
          children: "Clear filters",
          onClick: () => onFiltersChange({}),
        },
      };
    }

    return {
      title: "No results found",
      action: emptyStateAction,
      subtitle: emptyStateAction
        ? "Create a bill manually or drag and drop multiple bills here"
        : undefined,
    };
  }, [emptyStateAction, hasFilters, onFiltersChange, isError]);

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const enhancedOnDrop = useCallback(
    async (files: File[]) => {
      if (!droppable) return;

      onDrop?.();

      const results = await Promise.allSettled(
        files.map((file, index) =>
          dispatch(
            uploadAttachable(
              { file, index },
              { onUploadSave: ["parent"] }
            ) as unknown as AnyAction
          )
        )
      );

      results.forEach((item) => {
        if (item.status !== "rejected") return;

        handleErrors(item.reason);
      });
    },
    [droppable, onDrop, dispatch]
  );

  const sort = useMemo(
    () => ({ value: order.value, onChange: order.onChange }),
    [order.value, order.onChange]
  );

  return (
    <TableProvider id={`${status}-bills-table`}>
      <StickyProvider onResize={onResize}>
        <Sticky
          style={{
            paddingTop: "var(--spacing-2xl)",
            paddingBottom: "var(--spacing-lg)",
          }}
        >
          <BillsTabHeader
            filters={filter.values}
            status={status}
            switchValue={requiresYourApproval}
            onSwitchChange={onRequiresYourApprovalChange}
            onFiltersChange={onFiltersChange}
          >
            <Flex gap="md" justify="flex-end">
              <TableConfigurationButton />
              <BatchActions
                bills={selectedRows}
                status={status}
                filters={filter.query}
                onAction={(clearSelections = true) => {
                  clearSelections && onSelect([]);
                  onGetBillsWithErrors?.();
                }}
              />
            </Flex>
          </BillsTabHeader>
        </Sticky>
        <StickyMeasurer>
          <DropZone
            portal
            onDrop={enhancedOnDrop}
            onError={handleErrors}
            showBorder={false}
            hasPermission={droppable}
            idleMessage={DROPZONE_MESSAGES.IDLE}
            imageFormats={LIST_IMAGE_FORMATS}
            pendingMessage={DROPZONE_MESSAGES.PENDING}
            draggingMessage={DROPZONE_MESSAGES.DRAGGING}
          >
            <DSTable
              row={row}
              size="sm"
              data={data}
              sort={sort}
              select={select}
              header={header}
              loading={loading}
              columns={columns}
              emptyState={emptyState}
              pagination={pagination}
              data-testid={`${status}-bills-table`}
            />
          </DropZone>
        </StickyMeasurer>
      </StickyProvider>
    </TableProvider>
  );
};
