import type { ForwardedRef } from "react";

/**
 * Merges multiple refs into one. Works with either callback or object refs.
 *
 * Credit https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/mergeRefs.ts
 */
export const mergeRefs =
  <T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (value: T) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    }
  };
