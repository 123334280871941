import { idSchema } from "@utils/schema";
import { z } from "zod";

const uploadAttachableBasePayloadSchema = z.object({
  realm: z.string().url(),
  parent: z.string().url().nullish(),
  document: z.instanceof(File),
});

export const uploadAttachablePayloadSchema =
  uploadAttachableBasePayloadSchema.or(
    uploadAttachableBasePayloadSchema.and(
      z.record(z.string().startsWith("save"), z.boolean())
    )
  );

export const uploadAttachableResponseSchema = z.object({
  id: idSchema,
  pdf: z.string().url().nullish(),
  url: z.string().url(),
  realm: z.string().url(),
  parent: z.string().url().nullish(),
  document: z.string().url(),
});
