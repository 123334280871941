import { useEffect } from "react";

import { useEvent } from "../use-event";

export const useKeyup = (onChange: (e: KeyboardEvent) => void) => {
  const enhancedOnChange = useEvent(onChange);

  useEffect(() => {
    window.addEventListener("keyup", enhancedOnChange);

    return () => {
      window.removeEventListener("keyup", enhancedOnChange);
    };
  }, [enhancedOnChange]);
};
