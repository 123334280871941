import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

const userSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  email: z.string().email(),
  fullName: z.string(),
});

const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
});

export const fidelCardSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  paymentAccount: accountSchema.nullish(),
  mask: z.string().nullish(),
  scheme: z.string().nullish(),
  user: userSchema.nullish(),
  verificationStatus: z.string(),
  fidelId: idSchema,
  consentId: idSchema.nullish(),
});

export const getFidelCardsResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(fidelCardSchema),
  previous: z.string().url().nullish(),
});
