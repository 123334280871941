import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
} from "react";
import cn from "clsx";

import styles from "./shell.module.css";

type DefaultComponent = "aside";

type Ref = ComponentRef<DefaultComponent>;

type Props = ComponentPropsWithoutRef<DefaultComponent> & { compact?: boolean };

export const ShellSidebar = forwardRef<Ref, Props>(
  ({ className, compact, ...props }, ref) => (
    <aside
      ref={ref}
      data-sidebar=""
      className={cn(className, styles["shell-sidebar"], {
        [styles["-compact"]]: compact,
      })}
      {...props}
    />
  )
);

ShellSidebar.displayName = "ShellSidebar";
