import React, { useCallback, useEffect, useMemo, useState } from "react";

import { StageContext } from "./context";
import type { Stage, StageContextProviderProps } from "./types";

export const RecordStageProvider = <T,>({
  children,
  names,
  currentStage,
  finishedStage,
  onStageChange,
}: StageContextProviderProps<T>) => {
  const [stage, setStage] = useState<Stage<T> | undefined>();

  const completed = useMemo(
    () => currentStage === finishedStage,
    [currentStage, finishedStage]
  );

  useEffect(() => {
    if (!names) return;

    const finalStage = names[names.length - 1];

    if (completed && finalStage) {
      setStage(finalStage as Stage<T>);
      return;
    }

    if (names?.includes(currentStage)) {
      setStage(currentStage);
    }
  }, [completed, currentStage, names]);

  const activeIdx = useMemo(
    () => names.findIndex((stageName) => stageName === stage),
    [names, stage]
  );
  const nextStage = useMemo(
    () => (activeIdx >= 0 ? names[activeIdx + 1] : undefined),
    [activeIdx, names]
  ) as Stage<T> | undefined;

  const prevStage = useMemo(
    () => (activeIdx >= 0 ? names[activeIdx - 1] : undefined),
    [activeIdx, names]
  ) as Stage<T> | undefined;

  const advance = useCallback(() => {
    if (nextStage) {
      setStage(nextStage);
    }
  }, [nextStage]);

  const recede = useCallback(() => {
    if (prevStage) {
      setStage(prevStage);
    }
  }, [prevStage]);

  useEffect(() => {
    if (stage) onStageChange?.(stage);
  }, [stage, onStageChange]);

  return (
    <StageContext.Provider
      value={{
        stages: names,
        stage: {
          name: stage,
          index: activeIdx,
        },
        setStage,
        completed,
        nextStage,
        prevStage,
        advance,
        recede,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};
