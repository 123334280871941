type Line = {
  billableStatus?: string;
  billable_status?: string;
  linkedInvoiceLine?: unknown;
  linked_invoice_line?: unknown;
};

const BILLABLE_STATUS = {
  BILLABLE: "Billable",
  NOT_BILLABLE: "Not billable",
  HAS_BEEN_BILLED: "Has been billed",
};

type BillableData = {
  label: string;
  checked: boolean;
  disabled: boolean;
};

const convertToNormalizedLine = (line: Line): Line => {
  const billableStatus = line.billableStatus || line.billable_status;
  const linkedInvoiceLine = line.linkedInvoiceLine || line.linked_invoice_line;

  return {
    billableStatus,
    billable_status: billableStatus,
    linkedInvoiceLine,
    linked_invoice_line: linkedInvoiceLine,
  };
};

const getCheckboxLabel = (line: Line): string => {
  const normalizedLine = convertToNormalizedLine(line);

  const isLinked = !!normalizedLine.linkedInvoiceLine;
  return isLinked || normalizedLine.billableStatus === "HasBeenBilled"
    ? BILLABLE_STATUS.HAS_BEEN_BILLED
    : BILLABLE_STATUS.BILLABLE;
};

export const isBillable = (line: Line): boolean => {
  const normalizedLine = convertToNormalizedLine(line);

  return ["Billable", "HasBeenBilled"].includes(
    normalizedLine.billableStatus ?? ""
  );
};

export const getBillableData = (
  line: Line,
  isDisabled: boolean
): BillableData => {
  const label = getCheckboxLabel(line);
  return {
    label,
    checked: isBillable(line) || label === BILLABLE_STATUS.HAS_BEEN_BILLED,
    disabled: isDisabled || label === BILLABLE_STATUS.HAS_BEEN_BILLED,
  };
};
