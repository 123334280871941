import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api/handle-response";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import { markupBudgetLinePayloadSchema } from "./request";
import { markupsSchema } from "./response";
import type {
  MarkupBudgetLinePayload,
  MarkupFixedFeeEditPayload,
  MarkupFixedFeePayload,
  MarkupResponse,
  MarkupsForCustomerGetPayload,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["CustomerMarkup"],
});

export const markupsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    addMarkups: builder.mutation<MarkupResponse, MarkupFixedFeePayload>({
      query: (payload) => {
        const body = transformKeysToSnakeCase(payload);
        return {
          url: `customers/${payload.customer}/markups/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    addBudgetLineMarkups: builder.mutation<
      MarkupResponse,
      MarkupBudgetLinePayload
    >({
      query: (payload) => {
        const body = transformKeysToSnakeCase(
          handleRequest(payload, markupBudgetLinePayloadSchema)
        );
        return {
          url: "markups/budget-lines/",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    updateBudgetLineMarkups: builder.mutation<
      MarkupResponse,
      MarkupBudgetLinePayload
    >({
      query: (payload) => {
        const body = transformKeysToSnakeCase(payload);
        return {
          url: "markups/budget-lines/",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    updateFixedBudgetLineMarkups: builder.mutation<
      MarkupResponse,
      MarkupFixedFeeEditPayload
    >({
      query: (payload) => {
        const body = transformKeysToSnakeCase(payload);
        return {
          url: `markups/customer/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    deleteMarkup: builder.mutation({
      query: (options) => ({
        url: `customers/${options.customer}/markups/${options.markupId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    deleteBudgetLineMarkups: builder.mutation({
      query: (options) => ({
        url: `customers/${options.customer}/markups/${options.markupId}/budget-lines/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CustomerMarkup", "BudgetLines"],
    }),
    getMarkupsForCustomer: builder.query<
      MarkupResponse[],
      MarkupsForCustomerGetPayload
    >({
      query: (params: MarkupsForCustomerGetPayload) => {
        return {
          url: `customers/${params.customer}/markups/`,
          params: {
            is_separate_line: params.isSeparateLine,
            distinct: params.distinct,
            order_by: params.orderBy,
          },
          responseHandler: async (response) => {
            const { results } = await response.json();
            return handleResponse(
              results.map(transformKeysToCamelCase),
              markupsSchema
            );
          },
        };
      },
      transformResponse: (data: MarkupResponse[]) => {
        return data.map((markup, i) => {
          const color = ["#00FFFF", "#EE00FF", "#FFB902"][i];
          return { ...markup, color };
        });
      },
      providesTags: ["CustomerMarkup"],
    }),
  }),
});

export const {
  useAddMarkupsMutation,
  useAddBudgetLineMarkupsMutation,
  useUpdateBudgetLineMarkupsMutation,
  useDeleteBudgetLineMarkupsMutation,
  useDeleteMarkupMutation,
  useUpdateFixedBudgetLineMarkupsMutation,
  useGetMarkupsForCustomerQuery,
} = markupsApi;
