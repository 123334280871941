import React, { useEffect, useState } from "react";
import { Dialog, DialogStep } from "@adaptive/design-system";

import { type Role, useRoleAction } from "../../../../shared/store/role";
import { type Dialog as WizardDialog } from "../types";

import { DeleteRole } from "./delete-role";
import { EditRole } from "./edit-role";
import { List } from "./list";

type Props = {
  dialog: WizardDialog;
};

export const Roles: React.FC<Props> = ({ dialog }) => {
  const [currentRole, setCurrentRole] = useState<Role | undefined>();

  const { roles } = useRoleAction();

  useEffect(() => {
    if (currentRole) {
      setCurrentRole(roles.find((r) => r.id === currentRole.id));
    }
  }, [roles, currentRole]);

  return (
    <Dialog
      show={dialog.isVisible}
      variant="multi-step-dialog"
      step={dialog.step}
      onClose={dialog.hide}
    >
      <DialogStep name="role-list">
        <List
          onRoleChange={(role: Role | undefined) => setCurrentRole(role)}
          dialog={dialog}
        />
      </DialogStep>
      <DialogStep name="role-edit" onBack={dialog.back}>
        <EditRole
          key={currentRole?.id ?? "create"}
          dialog={dialog}
          role={currentRole}
        />
      </DialogStep>
      <DialogStep name="role-delete" onBack={dialog.back}>
        {currentRole ? <DeleteRole role={currentRole} dialog={dialog} /> : null}
      </DialogStep>
    </Dialog>
  );
};
