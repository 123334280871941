import { createContext, useContext } from "react";

type CardContextType = {
  hasHeader: boolean;
  setHasHeader: (hasHeader: boolean) => void;
};

export const CardContext = createContext<CardContextType>({
  hasHeader: false,
  setHasHeader: () => {},
});

export const useCardContext = () => useContext(CardContext);
