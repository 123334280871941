import type {
  GetCustomersProps,
  GetCustomersSimplifiedProps,
  GetCustomersSimplifiedResponse,
  PostCustomerProps,
  PutCustomerProps,
} from "@api/jobs";

import { api } from "../utils/api";

const baseUrl = "/api/customers/";

export const getCustomersSimplified = async ({
  filters,
  signal,
}: GetCustomersSimplifiedProps = {}) => {
  const queryParams = new URLSearchParams();

  if (filters) {
    filters.forEach((filter) => {
      queryParams.append(filter.dataIndex, filter.value);
    });
  }
  return await api.get<GetCustomersSimplifiedResponse>(
    `${baseUrl}?simplified`,
    {
      params: queryParams,
      signal,
    }
  );
};

export const getCustomers = async ({ filters, signal }: GetCustomersProps) => {
  const queryParams = new URLSearchParams();

  filters.forEach((filter) => {
    queryParams.append(filter.index, filter.value.toString());
  });

  const response = await api.get(baseUrl, { params: queryParams, signal });

  return response.data;
};

export const getCustomer = async (id: string) => {
  const response = await api.get(`${baseUrl}${id}/`);
  return response.data;
};

export const postCustomer = async (params: PostCustomerProps) => {
  const response = await api.post(baseUrl, { ...params });
  return response.data;
};

export const putCustomer = async (id: string, params: PutCustomerProps) => {
  const response = await api.put(`${baseUrl}${id}/`, { ...params });
  return response.data;
};

export const updateStatus = async (id: string, active: boolean) => {
  return api
    .put(`${baseUrl}${id}/activate/`, { active })
    .then((response) => response.data);
};
