import { useMemo } from "react";
import {
  type GetBankAccountsPayload,
  useGetBankAccountsQuery,
} from "@api/bank-accounts";
import { useClientInfo } from "@store/user";

type UseBankAccountsV2Props = Omit<GetBankAccountsPayload, "clientId"> & {
  enabled?: boolean;
};

export const useBankAccountsV2 = ({
  enabled = true,
  ...params
}: UseBankAccountsV2Props) => {
  const { client } = useClientInfo();

  const { data, ...bankAccounts } = useGetBankAccountsQuery(
    { clientId: client?.id as string, ...params },
    { skip: !client?.id || !enabled }
  );

  const enhancedBankAccounts = useMemo(
    () =>
      (data?.results || [])
        .map((bankAccount) => ({
          ...bankAccount,
          data: bankAccount.accountBalances.map((balance) => ({
            ...balance,
            ...(balance.plaidAccountOwners.length
              ? { data: balance.plaidAccountOwners }
              : {}),
          })),
        }))
        .filter((bankAccount) => bankAccount.data.length),
    [data]
  );

  const isPlaidLoginRequired = useMemo(
    () => enhancedBankAccounts.some((account) => account.plaidLoginRequired),
    [enhancedBankAccounts]
  );

  return {
    data: enhancedBankAccounts,
    isPlaidLoginRequired,
    ...bankAccounts,
  };
};
