import React from "react";
import { dialog, Flex, Text } from "@adaptive/design-system";

import { renderLinkedInvoices } from "../render-linked-invoices";
import { type DialogProps } from "../types";

export const confirmBackToEdit = ({
  linkedInvoices = [],
  isPlural = false,
  action,
}: DialogProps) => {
  dialog.confirmation({
    title: `${
      isPlural ? "These transactions have" : "This transaction has"
    } line items that are already added to a draw`,
    message: (
      <Flex direction="column" gap="xl">
        <Text align="center">
          Only approved transactions can be linked to draws.
        </Text>
        <Text align="center">
          {"Would you like to delete the line on the associated draw "}
          {renderLinkedInvoices(linkedInvoices)}
          {" or keep it and unlink this transaction?"}
        </Text>
        <Text as="i" size="sm" align="center">
          Note: You can now edit transactions directly from any status by
          closing this modal.
        </Text>
      </Flex>
    ),
    action,
  });
};
