import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
  useMemo,
} from "react";
import { DisclosureProvider, useDisclosureStore } from "@ariakit/react";
import cn from "clsx";

import type { Colors } from "../../styles/variables";
import { is } from "../../utils/is";
import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { type IconName, ICONS as DESIGN_SYSTEM_ICONS } from "../icon/constants";

import { BACKGROUNDS, ICON_COLORS, ICONS } from "./alert-constants";
import { AlertContext } from "./alert-context";
import styles from "./alert.module.css";

export type AlertVariant = "info" | "error" | "success" | "warning" | "neutral";

export type CustomAlertVariant = {
  icon: IconName | Omit<ReactNode, IconName>;
  color?: Colors;
  iconColor?: Colors;
  background: Colors;
};

type DefaultComponent = "div";

export type AlertProps = ComponentPropsWithoutRef<DefaultComponent> & {
  show?: boolean;
  variant: AlertVariant | CustomAlertVariant;
  onClose?: () => void;
  expanded?: boolean;
  onExpand?: (expanded: boolean) => void;
  "data-testid"?: string;
};

export const Alert = forwardRef<ComponentRef<DefaultComponent>, AlertProps>(
  (
    {
      show = true,
      style = {},
      variant,
      onClose,
      expanded,
      children,
      onExpand,
      className,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const disclosureStore = useDisclosureStore({
      open: expanded,
      setOpen: onExpand,
    });

    const icon = useMemo(() => {
      if (is.string(variant)) {
        return <Icon size="lg" name={ICONS[variant]} />;
      }

      if (
        is.string(variant.icon) &&
        DESIGN_SYSTEM_ICONS.includes(variant.icon as IconName)
      ) {
        return <Icon size="lg" name={variant.icon as IconName} />;
      }

      return variant.icon as ReactNode;
    }, [variant]);

    const color = is.object(variant) ? variant.color : "neutral-800";

    const background = is.object(variant)
      ? variant.background
      : BACKGROUNDS[variant];

    const iconColor = is.object(variant)
      ? variant.iconColor
      : ICON_COLORS[variant];

    const isExpandable = !!disclosureStore.useState("contentElement");

    return show ? (
      <DisclosureProvider store={disclosureStore}>
        <div
          ref={ref}
          role="alert"
          className={cn(className, styles["alert"], {
            [styles["-expandable"]]: isExpandable,
          })}
          style={{
            ...style,
            ...(color ? { "--alert-color": `var(--color-${color})` } : {}),
            "--alert-background": `var(--color-${background})`,
            "--alert-icon-color": `var(--color-${iconColor})`,
          }}
          data-testid={testId}
          {...props}
        >
          {!isExpandable ? (
            <span className={styles["icon"]}>{icon}</span>
          ) : null}
          {onClose && (
            <Icon
              as="button"
              name="xmark"
              type="button"
              color="neutral-900"
              data-testid={suffixify(testId, "close-button")}
              onClick={() => onClose?.()}
              className={styles["close-button"]}
              aria-label="Close"
            />
          )}
          <AlertContext.Provider value={{ icon, background }}>
            {children}
          </AlertContext.Provider>
        </div>
      </DisclosureProvider>
    ) : null;
  }
);

Alert.displayName = "Alert";
