type GenerateSignature = (
  value: string,
  params?: {
    fontSize?: number;
    padding?: number;
  }
) => { url: string; name: string; width: number; height: number };

export const generateSignature: GenerateSignature = (
  value,
  { fontSize = 56, padding = 10 } = {}
) => {
  const font = `${fontSize}px 'Kristi', cursive`;
  const contentHeight = 30;
  const contentStartOffset = padding;
  const contentEndOffset = padding;
  const contentBottomOffset = padding;

  // We need to create a "virtual element" to get rendered dimensions
  const el = document.createElement("div");
  el.innerHTML = value;
  el.style.font = font;
  el.style.width = "max-content";
  document.documentElement.append(el);
  const { width, height } = el.getBoundingClientRect();
  document.documentElement.removeChild(el);

  const canvas = document.createElement("canvas");
  canvas.width = width + contentStartOffset + contentEndOffset;
  canvas.height = height + contentBottomOffset;
  const ctx = canvas.getContext("2d");
  ctx!.font = font;
  ctx!.fillText(value, contentStartOffset, height / 2 + contentHeight / 2);

  return { url: canvas.toDataURL(), name: value, width, height };
};
