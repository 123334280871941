import { type RefObject, useEffect } from "react";

import { debounce } from "../../utils/debounce";
import { useEvent } from "../use-event";

export const useOnInteractOutside = (
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  onInteractOutside: () => void
) => {
  const enhancedOnInteractOutside = useEvent(onInteractOutside);

  useEffect(() => {
    /**
     * With debounce we guarantee that we execute the callback once
     */
    const onInteract = debounce((el: HTMLElement) => {
      const isOutside = !(Array.isArray(ref) ? ref : [ref]).some(
        (r) => !r.current || r.current.contains(el)
      );

      if (isOutside) enhancedOnInteractOutside();
    });

    const onFocus = () => onInteract(document.activeElement as HTMLElement);
    const onPress = (e: Event) => onInteract(e.target as HTMLElement);

    document.addEventListener("focus", onFocus, true);
    document.addEventListener("mousedown", onPress);
    document.addEventListener("touchstart", onPress);

    return () => {
      document.addEventListener("focusin", onFocus);
      document.removeEventListener("mousedown", onPress);
      document.removeEventListener("touchstart", onPress);
    };
    /**
     * We don't need to track ref/onInteractOutside since
     * we guarantee that they reference doesn't change
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
