import React, { type MouseEventHandler } from "react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { Text, type TextProps } from "../text";

import styles from "./tag.module.css";

const DEFAULT_COMPONENT = "span";

export type TagProps = Pick<TextProps, "truncate"> & {
  size?: ResponsiveProp<"sm" | "md">;
  color?: "neutral" | "success" | "error" | "warning" | "info";
  variant?: "square" | "pill";
  onRemove?: () => void;
  "data-testid"?: string;
};

export const Tag = forwardRefAs<typeof DEFAULT_COMPONENT, TagProps>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      size: rawSize,
      color = "neutral",
      variant = "square",
      truncate = true,
      onRemove,
      children,
      className,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");

    const onRemoveMouseDown = useEvent<MouseEventHandler>((e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    return (
      <span
        ref={ref}
        className={cn(styles["tag"], className, {
          [styles[`-${size}`]]: size,
          [styles[`-${color}`]]: color,
          [styles[`-${variant}`]]: variant,
        })}
        data-testid={testId}
      >
        <Text
          as={Component}
          truncate={truncate}
          className={cn(styles["content"], {
            [styles["-only"]]: !onRemove,
            [styles["-string"]]: typeof children === "string",
          })}
          data-testid={suffixify(testId, "content")}
          data-no-outline=""
          {...props}
        >
          {children}
        </Text>

        {onRemove && (
          <div>
            <Icon
              as="button"
              name="xmark"
              size={size === "sm" ? "xs" : "sm"}
              onClick={onRemove}
              className={styles["remove"]}
              aria-label="Remove"
              onMouseDown={onRemoveMouseDown}
              data-testid={suffixify(testId, "remove")}
              type="button"
            />
          </div>
        )}
      </span>
    );
  }
);

Tag.displayName = "Tag";
