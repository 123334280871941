import React, { Fragment, type ReactNode } from "react";
import reactStringReplace from "react-string-replace";

import { dotObject } from "../dot-object";

type NestedRecord = { [key: string]: NestedRecordField };
type NestedRecordField = ReactNode | NestedRecord;

/**
 * @name parseStringCopy
 * @description Replaces placeholders between brackets in a string template with values from a data object.
 *
 * @example
 * parseStringCopy('Hello, {name}!', { name: 'John' }); // 'Hello, John!'
 *
 * parseStringCopy(
 *   'Greetings, {user.name}!!',
 *   { user: { name: 'John' } }
 * ); // 'Greetings, John!!'
 *
 * parseStringCopy(
 *   'Showing {length} {unit}',
 *   { length: results.length, unit: results.length === 1 ? 'result' : 'results' }
 * ); // 'Showing 1 result' or 'Showing 5 results'
 *
 * // Missing key
 * parseStringCopy('Hello, {name}!', {}); // 'Hello, {name}!'
 */
export const parseStringCopy = (copy: string, data: NestedRecord) => {
  return reactStringReplace(copy, /{([^}]+)}/g, (key, i) => (
    <Fragment key={i}>{dotObject.get(data, key, `{${key}}`)}</Fragment>
  ));
};
