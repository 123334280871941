import { useState } from "react";

import { useLocalStorageState } from "../use-local-storage-state";

type UsePagination = {
  initialPage?: number;
  initialPerPage?: number;
  perPageVariant?: "sm" | "md" | "lg";
  localStorageKey?: string;
};

const DEFAULT_PROPS = {
  initialPage: 0,
  initialPerPage: 10,
};

export const usePagination = ({
  initialPage = DEFAULT_PROPS.initialPage,
  initialPerPage = DEFAULT_PROPS.initialPerPage,
  perPageVariant,
  localStorageKey,
}: UsePagination = DEFAULT_PROPS) => {
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useLocalStorageState(
    localStorageKey,
    initialPerPage || 10
  );

  return {
    page,
    offset: page * perPage,
    setPage,
    perPage,
    setPerPage,
    perPageVariant,
  };
};
