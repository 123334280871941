import React, { type PropsWithChildren, useState } from "react";

import { useEvent } from "../../hooks/use-event";

import { RadioGroupContext } from "./radio-group-context";

type Value = string;

type OnChangeHandler = (value: Value) => void;

export type RadioGroupProps = PropsWithChildren<{
  name?: string;
  value?: Value;
  disabled?: boolean;
  required?: boolean;
  onChange?: OnChangeHandler;
  errorMessage?: string;
}>;

export const RadioGroup = ({
  value,
  children,
  onChange,
  ...props
}: RadioGroupProps) => {
  const [internalValue, setInternalValue] = useState("");

  const enhancedOnChange = useEvent<OnChangeHandler>((value) => {
    setInternalValue(value ?? "");
    onChange?.(value);
  });

  return (
    <RadioGroupContext.Provider
      value={{
        value: value ?? internalValue,
        onChange: enhancedOnChange,
        ...props,
      }}
    >
      {children}
    </RadioGroupContext.Provider>
  );
};
