import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@adaptive/design-system";

import { type RootState } from "../../store/types";
import { toggleChatVisibility } from "../../store/user/slice";
import * as analytics from "../../utils/analytics";

type Props = {
  compact?: boolean;
  visibility?: boolean;
};

export const SidebarChat = memo(({ compact }: Props) => {
  const dispatch = useDispatch();

  const liveChatWidgetOpen = useSelector<RootState, boolean>(
    (state) => state.user.isChatVisible
  );

  return (
    <button
      type="button"
      onClick={() => {
        dispatch(toggleChatVisibility(!liveChatWidgetOpen));

        if (!liveChatWidgetOpen) {
          analytics.track("chatOpen", { source: "sidebar" });
        }
      }}
    >
      <Icon name="circle-question" />
      {!compact && "Need help?"}
    </button>
  );
});

SidebarChat.displayName = "SidebarChat";
