import React, {
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
  useId,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { Label } from "../label";
import { useRadioGroup } from "../radio-group/radio-group-context";
import { VisuallyHidden } from "../visually-hidden";

import styles from "./radio.module.css";

type DefaultComponent = "input";

type Ref = ComponentRef<DefaultComponent>;

type Props = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "onChange" | "value"
> & {
  label?: ReactNode;
  value?: string;
  onChange?: (checked: boolean) => void;
  placement?: "left" | "right" | "top" | "bottom";
  hintMessage?: ReactNode;
  errorMessage?: ReactNode;
  "data-testid"?: string;
};

export const Radio = forwardRef<Ref, Props>(
  (
    {
      id,
      name,
      style,
      value,
      label,
      checked,
      disabled,
      onChange,
      required,
      placement = "right",
      className,
      hintMessage,
      errorMessage,
      "data-testid": testId,
      "aria-labelledby": labelledBy,
      ...props
    },
    ref
  ) => {
    const internalId = useId();
    const internalRef = useRef<Ref>(null);

    const [internalChecked, setInternalChecked] = useState(false);

    const group = useRadioGroup();

    const enhancedId = id ?? internalId;

    const enhancedName = name ?? group.name;

    const enhancedChecked =
      checked ?? (group.value ? group.value === value : internalChecked);

    const enhancedDisabled = disabled ?? group.disabled;

    const enhancedRequired = required ?? group.required;

    const enhancedErrorMessage = errorMessage ?? group.errorMessage;

    const enhancedOnChange = useEvent<ChangeEventHandler<Ref>>((e) => {
      setInternalChecked(e.currentTarget.checked);
      onChange?.(e.currentTarget.checked);
      group.onChange?.(value ?? "");
    });

    return (
      <div
        style={style}
        className={cn(className, styles["wrapper"], {
          [styles["-checked"]]: enhancedChecked,
          [styles["-disabled"]]: enhancedDisabled,
          [styles[`-${placement}`]]: placement,
        })}
      >
        {label && (
          <Label
            id={suffixify(enhancedId, "label")}
            htmlFor={enhancedId}
            variant={
              enhancedErrorMessage && !enhancedDisabled ? "error" : "neutral"
            }
            required={enhancedRequired}
            hintMessage={hintMessage}
            data-testid={suffixify(testId, "label")}
          >
            {label}
          </Label>
        )}
        <label className={styles["radio"]}>
          <VisuallyHidden>
            <input
              id={enhancedId}
              ref={mergeRefs(ref, internalRef)}
              type="radio"
              name={enhancedName}
              value={value}
              checked={enhancedChecked}
              disabled={enhancedDisabled}
              required={enhancedRequired}
              onChange={enhancedOnChange}
              data-testid={testId}
              aria-checked={enhancedChecked ? "true" : "false"}
              aria-invalid={!!enhancedErrorMessage}
              aria-labelledby={labelledBy ?? suffixify(enhancedId, "label")}
              {...props}
            />
          </VisuallyHidden>
        </label>
      </div>
    );
  }
);

Radio.displayName = "Radio";
