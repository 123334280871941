import { api } from "../utils/api";
export type Realm = {
  id: number;
  company_name: string;
  url: string;
  codat_company_id: string;
  codat_connection_id: string;
  codat_link_url: string;
  last_synced_at: string;
};

export type RealmSyncMode =
  | "BASE"
  | "PUSH_CLEANUP"
  | "PULL_ALL"
  | "FORCE_PULL_ALL"
  | "CLEANUP_AND_PULL_ALL";

export const getRealm = async (id: number) => {
  return await api.get<Realm>(`/api/realms/${id}`).then((resp) => resp.data);
};

export const updateRealm = async (id: number, nextSyncMode: RealmSyncMode) => {
  return await api
    .put<Realm>(`/api/realms/${id}/`, { next_sync_mode: nextSyncMode })
    .then((resp) => resp.data);
};
