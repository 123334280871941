import React, {
  type ComponentRef,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
} from "react";
import { ComboBox, type ComboBoxProps } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import type { Option } from "@shared/types";
import { useRoleAction } from "@store/role";
import {
  BasePermissions,
  useClientAction,
  usePeopleAction,
  usePeopleInfo,
  useUserInfo,
} from "@store/user";
import { getUrlBuilder } from "@utils/api";

type Ref = ComponentRef<typeof ComboBox>;

type ApproversComboboxProps = ComboBoxProps<true>;

const ApproversCombobox = (
  props: ApproversComboboxProps,
  ref: ForwardedRef<Ref>
) => {
  const { people } = usePeopleInfo();

  const { loadPeople } = usePeopleAction();

  const { currentClient } = useClientAction();

  const { hasSomePermission } = useUserInfo();

  const { loadRoles, roles } = useRoleAction();

  const approvers = useDeepMemo(() => {
    const rolesData = roles.reduce(
      (acc, role) =>
        !role?.url
          ? acc
          : [
              ...acc,
              {
                label: role.name,
                value: role.url,
                groupLabel: "Role",
              },
            ],
      [] as Option[]
    );

    const peopleData = people
      .filter((person) => person.is_user)
      .map((person) => ({
        label: person.full_name,
        value: `${getUrlBuilder(`/api/users/`).forItem(person)}`,
        groupLabel: "User",
      }));

    return [...rolesData, ...peopleData];
  }, [roles, people]);

  const canFetchUsers = useMemo(
    () =>
      hasSomePermission([
        BasePermissions.COMPANY_ADMIN,
        BasePermissions.MANAGE_APPROVAL_WORKFLOWS,
        BasePermissions.BYPASS_APPROVAL_WORKFLOWS,
      ]),
    [hasSomePermission]
  );

  useEffect(() => {
    if (currentClient && canFetchUsers) {
      loadRoles(currentClient.id);
      loadPeople(currentClient.id);
    }
  }, [loadRoles, loadPeople, currentClient, canFetchUsers]);

  return (
    <ComboBox
      ref={ref}
      data={approvers}
      multiple
      placeholder="Choose approver"
      {...props}
    />
  );
};

const ForwardedApproversCombobox = forwardRef(ApproversCombobox);

export { ForwardedApproversCombobox as ApproversCombobox };
