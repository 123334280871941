import type { UseMultiStepDialogReturn } from "@adaptive/design-system/hooks";
import { curriedNoop, noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import type { ChangeDialogStep, ChangesDialogContextType } from ".";

export const ChangesDialogContext = createContext<ChangesDialogContextType>({
  onOpenChangeForStep: curriedNoop,
  onSetChangeForStep: noop,
  totalAmount: 0,
  totalExternalAmount: 0,
  budgetLine: undefined,
  isEdit: false,
  editMarkup: noop,
});

export const useChange = <Fields extends keyof ChangesDialogContextType>(
  field: Fields
) => {
  const result = useContextSelector(
    ChangesDialogContext,
    (context) => context[field]
  );
  return result;
};

export const useEditMarkup = () =>
  useContextSelector(ChangesDialogContext, (context) => context.editMarkup);

export const useIsEdit = () =>
  useContextSelector(ChangesDialogContext, (context) => context.isEdit);

export const useOnSetChangeForStep = () =>
  useContextSelector(
    ChangesDialogContext,
    (context) => context.onSetChangeForStep
  );

export const isMultiStep = (
  dialog: unknown
): dialog is UseMultiStepDialogReturn<ChangeDialogStep> =>
  dialog !== null && typeof dialog === "object" && "step" in dialog;
