import React from "react";
import { Button, Flex, Loader } from "@adaptive/design-system";
import { suffixify } from "@adaptive/design-system/utils";
import { useStageName } from "@shared/sequential/context";
import { asStageFooter } from "@shared/sequential/factory";
import { useAppSelector } from "@store/hooks";
import { useVendorInfo } from "@store/vendors";
import { selectVendorFetchStatus } from "@store/vendors/selectors";

import { ROUTE_LABEL } from "./tabs/routes";

const FooterAction = () => {
  const stageName = useStageName();

  const fetchStatus = useAppSelector(selectVendorFetchStatus);

  const { hasUnsavedChanges, isSubmitting } = useVendorInfo();

  const isLoading = fetchStatus === "pending" || isSubmitting;

  return (
    <>
      <Flex />
      {fetchStatus !== "rejected" && (
        <Flex justify="flex-end" align="center" gap="2xl">
          <Button
            disabled={!hasUnsavedChanges || isLoading}
            variant="solid"
            type="submit"
            size="lg"
            form={suffixify("vendor", stageName || "")}
          >
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </Flex>
      )}
    </>
  );
};

const FooterMapping = {
  [ROUTE_LABEL.INFO]: FooterAction,
  [ROUTE_LABEL.ACCOUNTING]: FooterAction,
  [ROUTE_LABEL.DOCUMENTS]: FooterAction,
};

export const Footer = asStageFooter(FooterMapping);
