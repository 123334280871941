import type { DocumentType } from "@api/vendors/types";
import type { Option } from "@shared/types/objects";

export const STRINGS = {
  REQUEST_BUTTON_LABEL: "Request documents from vendor",
  REQUEST_DOCUMENT_TYPES_LABEL: "Confirm",
  REQUEST_DOCUMENT_EMAIL_LABEL: "Send request",
  CANCEL_BUTTON_LABEL: "Cancel request",
};

import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";

// for now we accept all options as selectable
export const SELECTABLE_DOC_TYPE_OPTIONS = DOCUMENT_TYPE_OPTIONS;

const SELECTABLE_DOC_TYPE_OPTIONS_ODD: Option<DocumentType>[] = [];

const SELECTABLE_DOC_TYPE_OPTIONS_EVEN: Option<DocumentType>[] = [];

SELECTABLE_DOC_TYPE_OPTIONS.forEach((option, index) => {
  if (!(index % 2)) {
    SELECTABLE_DOC_TYPE_OPTIONS_ODD.push(option as Option<DocumentType>);
  } else {
    SELECTABLE_DOC_TYPE_OPTIONS_EVEN.push(option as Option<DocumentType>);
  }
});

export const SELECTABLE_DOC_TYPE_OPTIONS_FORMATTED = [
  SELECTABLE_DOC_TYPE_OPTIONS_ODD,
  SELECTABLE_DOC_TYPE_OPTIONS_EVEN,
];
