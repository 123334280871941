export const onlyShowFocusOnTabs = () => {
  const className = "-focus-disabled";
  const container = document.body;

  const reset = () => {
    container.classList.remove(className);
    container.removeEventListener("keydown", handleKeyDown);
    container.removeEventListener("mousedown", handleMouseDown);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Tab") {
      reset();
      container.addEventListener("mousedown", handleMouseDown);
    }
  };

  const handleMouseDown = () => {
    reset();
    container.classList.add(className);
    container.addEventListener("keydown", handleKeyDown);
  };

  container.addEventListener("mousedown", handleMouseDown);

  return reset;
};
