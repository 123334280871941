import React, {
  type ComponentProps,
  type ReactNode,
  useMemo,
  useState,
} from "react";
import {
  ComboBox,
  type ComboBoxProps,
  Loader,
  Tag,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { api } from "@api/expenses";
import type { Expense } from "@api/expenses/types";
import { handleErrors } from "@api/handle-errors";
import { CardTransactionField } from "@card-feed/components";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import type { Option } from "@shared/types";
import { useExpenseAction } from "@store/expenses";
import { asOption, asReferenceNode } from "@utils/converters/utils";

import { isError, isPending } from "./columns";

type Status = Expense["reviewStatus"] | "SYNCED" | "ERROR" | "IGNORED_ERROR";

const REVIEW_STATUS: Record<
  Status,
  {
    color: ComponentProps<typeof Tag>["color"];
    content: string;
    extra?: ReactNode;
  }
> = {
  DRAFT: {
    color: "neutral",
    content: "Draft",
  },
  REVIEWED: {
    color: "warning",
    content: "Pending sync",
  },
  SYNCED: {
    color: "success",
    content: "Synced",
  },
  ERROR: {
    color: "error",
    content: "Sync errors",
  },
  IGNORED_ERROR: {
    color: "neutral",
    content: "Ignored sync errors",
  },
  FOR_REVIEW: {
    color: "info",
    content: "For review",
  },
};

export const StatusColumnRender = (
  row: Pick<
    Expense,
    | "errors"
    | "isArchived"
    | "fileSyncStatus"
    | "publishedToQuickbooks"
    | "errorsNotIgnoredExist"
  > & { reviewStatus: string }
) => {
  let enhancedStatus = row.reviewStatus as unknown as Status;

  if (row.publishedToQuickbooks) {
    enhancedStatus = "SYNCED";
  }

  if (row.errors.length) {
    enhancedStatus = isError(row) ? "ERROR" : "IGNORED_ERROR";
  }

  const { extra, ...match } = REVIEW_STATUS[enhancedStatus];
  let { color, content } = match;

  if (row.isArchived) {
    color = "neutral";

    if (enhancedStatus !== "REVIEWED") {
      content = `Archived ${content.toLowerCase()}`;
    } else {
      content = "Archived";
    }
  }

  return (
    !isPending(row) && (
      <Tag color={color}>
        {content}
        {extra ? (
          <>
            {` `} {extra}
          </>
        ) : (
          ""
        )}
      </Tag>
    )
  );
};

export const MatchingTransactionRender = (row: Expense) => {
  const { loadTableData } = useExpenseAction();

  return !isPending(row) ? (
    <CardTransactionField
      size="sm"
      cost={row}
      onChange={loadTableData}
      messageVariant="hidden"
    />
  ) : null;
};

export const AssigneeColumnRender = (row: Expense) => {
  const [isLoading, setIsLoading] = useState(false);

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const { loadTableData } = useExpenseAction();

  const value = useMemo(() => {
    const assignee = (row.assignee || {}) as Exclude<
      Expense["assignee"],
      null | undefined
    >;

    return asOption({
      displayName: assignee.fullName,
      ...assignee,
    }) as ComboBoxProps<false>["value"];
  }, [row.assignee]);

  const onChange = useEvent(async (_, option?: Option) => {
    try {
      setIsLoading(true);
      await api.put({
        id: row.id,
        realm: row.realm,
        assignee: asReferenceNode(option) as Expense["assignee"],
        reviewStatus: row.reviewStatus,
      });
      loadTableData();
    } catch (e) {
      handleErrors(e);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <ComboBox
        size="sm"
        value={value}
        data={users.data}
        loading={users.status === "loading"}
        onChange={onChange}
        messageVariant="hidden"
      />
      {isLoading && <Loader position="fixed" />}
    </>
  );
};
