import React, { type ComponentProps, useEffect, useState } from "react";
import { ComboBox, CurrencyField, Flex } from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { parseCurrency } from "@adaptive/design-system/utils";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { z } from "zod";

import { CURRENCY_FIELD_FORM_FORMAT_PROPS } from "../constants";

type OnInputHandler = Exclude<
  ComponentProps<typeof CurrencyField>["onInput"],
  undefined
>;

const schema = z.object({
  value: z.number().refine((value) => value !== 0, "Amount is required"),
  costCode: z.string().url("Item is required"),
});

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = { value: 0, costCode: "" };

export type MarkupCostFormProps = {
  formId: string;
  onSubmit: (values: Fields) => Promise<void>;
  initialValues?: Fields;
  onValidityChange: (isValid: boolean) => void;
};

export const MarkupCostForm = ({
  formId,
  onSubmit,
  onValidityChange,
  initialValues = INITIAL_VALUES,
}: MarkupCostFormProps) => {
  const form = useForm<Fields>({
    id: formId,
    schema,
    onSubmit,
    initialValues,
  });

  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified();

  const [isValueValid, setIsValueValid] = useState(false);

  const onInput = useEvent<OnInputHandler>((e) => {
    const parsedValue = parseCurrency(e.currentTarget.value);
    setIsValueValid(!!parsedValue);
  });

  /**
   * We do this custom validation instead of using `form.isValid` because CurrencyField
   * only notifies your changes when user blur, we do it because we cannot ensure parse
   * and use the `unfinished` value on controlled way. Other libraries like `react-aria`
   * do the same on these cases.
   */
  useEffect(() => {
    onValidityChange(
      !form.errors.costCode && isValueValid && !form.isSubmitting
    );
  }, [form.errors.costCode, isValueValid, form.isSubmitting, onValidityChange]);

  return (
    <form {...form.props}>
      <Flex wrap gap="xl" align="center" width="full">
        <Flex grow={true}>
          <ComboBox
            label="Item Name"
            data={costCodeAccountsSimplified.data}
            loading={costCodeAccountsSimplified.status === "loading"}
            required
            placeholder="ex: Builder's Fee"
            {...form.register("costCode")}
          />
        </Flex>
        <Flex maxWidth="200px">
          <CurrencyField
            {...CURRENCY_FIELD_FORM_FORMAT_PROPS}
            label="Amount"
            onInput={onInput}
            required
            placeholder="ex: 10,000.00"
            messageVariant="relative"
            {...form.register({ name: "value", type: "currency" })}
          />
        </Flex>
      </Flex>
    </form>
  );
};
