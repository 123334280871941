import React, { useEffect } from "react";
import {
  Checkbox,
  Flex,
  Radio,
  RadioGroup,
  Text,
  toast,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent, useForm } from "@adaptive/design-system/hooks";
import { type NotificationSettingsPayload } from "@api/notifications";
import { useUserMutation } from "@hooks/useUserMutation";
import { useUserNotificationSettingsMutation } from "@hooks/useUserNotificationSettingsMutation";
import { useClientInfo, useUserInfo } from "@store/user";
import { z } from "zod";

const schema = z.object({
  notifications: z.record(z.record(z.boolean())),
  batch_email_time: z.enum(["daily", "instantly"]),
});

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = {
  notifications: {},
  batch_email_time: "instantly",
};

export const ProfileNotificationForm = () => {
  const { realmId } = useClientInfo();

  const userMutation = useUserMutation();

  const userNotificationSettingsMutation =
    useUserNotificationSettingsMutation();

  const { user, notificationCategories } = useUserInfo();

  const notifications = useDeepMemo(
    () => notificationCategories.flatMap((item) => item.notifications),
    [notificationCategories]
  );

  const notificationTypes = useDeepMemo(
    () => [...new Set(notifications.flatMap((item) => item.types))],
    [notifications]
  );

  const values = useDeepMemo(() => {
    const initialNotifications = notifications.reduce(
      (result, notification) => ({
        ...result,
        [notification.id]: notificationTypes.reduce((acc, type) => {
          const typeChecked = user.notifications.some(
            (userNotification) =>
              userNotification.notification === notification.id &&
              userNotification.type === type
          );

          return { ...acc, [type]: typeChecked };
        }, {}),
      }),
      {}
    );

    return {
      notifications: initialNotifications,
      batch_email_time: user.batch_email_time === null ? "instantly" : "daily",
    } as Fields;
  }, [
    notifications,
    notificationTypes,
    user.notifications,
    user.batch_email_time,
  ]);

  const form = useForm<Fields>({
    schema,
    initialValues: INITIAL_VALUES,
    validationMode: "onChange",
  });

  const reset = useEvent(() => form.reset(values));

  const save = useEvent(async () => {
    const notificationSettingsPayload: NotificationSettingsPayload =
      Object.keys(form.values.notifications).flatMap((notificationId) => {
        const notification = form.values.notifications[notificationId];
        return Object.keys(notification).map((type) => ({
          channel: type,
          type: notificationId,
          enabled: notification[type],
        }));
      });

    await userMutation.mutate({
      id: user.id,
      batch_email_time:
        form.values.batch_email_time === "daily" ? "09:00:00" : null,
    });

    await userNotificationSettingsMutation.mutate(notificationSettingsPayload);

    toast.success("Settings saved");
  });

  useEffect(() => {
    if (form.isDirty && form.isTouched) save();
  }, [save, form.isDirty, form.values, form.isTouched]);

  useEffect(() => {
    if (user.realmId === realmId) reset();
  }, [reset, user.realmId, realmId]);

  return (
    <Flex as="form" gap="5xl" direction="column" {...form.props}>
      <Flex
        gap="3xl"
        role="group"
        direction="column"
        aria-labelledby="email-notifications"
      >
        <Text
          id="email-notifications"
          size="lg"
          weight="bold"
          aria-hidden="true"
        >
          Manage your notifications
        </Text>
        <Flex direction="column" gap="4xl" separator width="600px">
          <Flex
            role="group"
            gap="xl"
            direction="column"
            aria-labelledby="delivery-frequency"
          >
            <Text
              id="delivery-frequency"
              size="sm"
              weight="bold"
              aria-hidden="true"
            >
              Delivery frequency
            </Text>
            <RadioGroup {...form.register("batch_email_time")}>
              <Flex gap="sm" direction="column">
                <Radio
                  id="daily"
                  value="daily"
                  label="Daily (9am Eastern)"
                  placement="right"
                />
                <Radio
                  id="instantly"
                  value="instantly"
                  label="Instantly"
                  placement="right"
                />
              </Flex>
            </RadioGroup>
          </Flex>

          {notificationCategories.map((notificationCategory, index) => (
            <Flex gap="xl" direction="column" key={notificationCategory.id}>
              <Flex gap="xl">
                <Flex grow>
                  <Text size="sm" weight="bold">
                    {notificationCategory.name}
                  </Text>
                </Flex>
                {index == 0
                  ? notificationTypes.map((notificationType) => (
                      <Flex
                        direction="column"
                        width="56px"
                        key={`title-${notificationType}`}
                        align="center"
                      >
                        <Text size="sm" weight="bold">
                          {notificationType}
                        </Text>
                      </Flex>
                    ))
                  : null}
              </Flex>
              {notificationCategory.notifications.map((notification) => (
                <Flex gap="xl" key={notification.description}>
                  <Flex direction="column" grow>
                    <Text size="sm" weight="semi-bold">
                      {notification.name}
                    </Text>
                    <Text size="sm" align="justify">
                      {notification.description}
                    </Text>
                  </Flex>
                  {notificationTypes.map((notificationType) => (
                    <Flex
                      direction="column"
                      width="56px"
                      shrink={false}
                      justify="center"
                      key={`control-${notificationType}`}
                      align="center"
                    >
                      {notification.types.some(
                        (type) => notificationType === type
                      ) ? (
                        <Checkbox
                          aria-label={notification.name}
                          {...form.register({
                            name: `notifications.${notification.id}.${notificationType}`,
                            type: "boolean",
                          })}
                        />
                      ) : (
                        "-"
                      )}
                    </Flex>
                  ))}
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
