import React from "react";
import { useDispatch } from "react-redux";
import { Alert, AlertContent, AlertTitle, Link } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { toggleChatVisibility } from "@shared/store/user/slice";
import * as analytics from "@utils/analytics";

type Props = {
  transactionType: string;
  linesCount: number;
  maxLines: number;
};

export const TooManyLinesAlert = ({
  transactionType,
  linesCount,
  maxLines = window.MAX_LINES_PAGE_SIZE,
}: Props) => {
  const dispatch = useDispatch();

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "too-many-lines-alert" });
  });

  return (
    linesCount > maxLines && (
      <Alert variant="warning">
        <AlertTitle>
          This {transactionType} has {linesCount} lines
        </AlertTitle>
        <AlertContent>
          You can only edit the first {maxLines} lines on Adaptive. Please
          process this {transactionType} in QuickBooks or reach out to Adaptive
          support for help by{" "}
          <Link as="button" type="button" onClick={openChat}>
            clicking here
          </Link>
          .
        </AlertContent>
      </Alert>
    )
  );
};
