// kinda sucks to need two different selectors, a "pure" selector
// and a memoized one, but memoized selectors can't be used in thunks
// so unfortunately that means duplication here :sob:
import type { Realm } from "../../api/realms";
import type { RootState } from "../types";

import type { Client, UserState } from "./slice";

type Selector<T> = (s: RootState) => T;

export const selectUserSlice: Selector<UserState> = (s) => s.user;

export const selectClientSettings: Selector<Partial<Client["settings"]>> = (
  s
) => s.user.currentClient?.settings || {};

export const selectRealmId: Selector<number | null> = (s: any) =>
  s.realm.realm?.id as unknown as number | null;

export const selectRealm: Selector<Realm | undefined> = (s: any) => {
  return s.realm.realm;
};

export const selectRealmUrl: Selector<Realm["url"] | undefined> = (s) => {
  const realm = selectRealm(s);
  return realm?.url;
};

export const selectClientIntegrationType = (state: RootState) => {
  return selectUserSlice(state).currentClient?.integration_type;
};
