import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatPhone } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { updateUser, type UpdateUserPayload } from "@api/users";
import { partialUpdateUser, userSelector } from "@store/user/slice";

type MutateHandler = (payload: UpdateUserPayload) => Promise<void>;

export const useUserMutation = () => {
  const user = useSelector(userSelector) as unknown as UpdateUserPayload;

  const dispatch = useDispatch();

  const mutate = useCallback<MutateHandler>(
    async (payload) => {
      try {
        await updateUser(payload);

        dispatch(
          partialUpdateUser({
            ...payload,
            ...(payload.phone_number
              ? {
                  phone_number: formatPhone(payload.phone_number),
                  two_factor_verified:
                    user.phone_number !== payload.phone_number
                      ? false
                      : user.two_factor_verified,
                  phone_number_verified:
                    user.phone_number !== payload.phone_number
                      ? false
                      : user.phone_number_verified,
                }
              : {}),
          })
        );
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    },
    [user, dispatch]
  );

  return { mutate };
};
