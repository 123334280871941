import {
  type ACHCreatePayload,
  vendorBankingAchApi,
} from "@api/vendors/api-banking";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { Identifiable } from "@shared/types";

import type { RootState } from "../types";
import { selectRealmUrl } from "../user/selectors-raw";

import { vendorSelector } from "./selectors";
import { SubmitErrorCodes } from "./types";

export const removeAchInfo = createAsyncThunk<
  Awaited<boolean> | undefined,
  Identifiable,
  { state: RootState }
>("vendors/vendorAchInfo/delete", async (data, { rejectWithValue }) => {
  try {
    const resp = await vendorBankingAchApi.delete(data);
    return resp.status === 204;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const commitAchInfo = createAsyncThunk<
  unknown,
  void,
  { state: RootState }
>(
  "vendors/vendorAchInfo/commit",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();

    const realm = selectRealmUrl(state);
    if (!realm) {
      return rejectWithValue({ status: SubmitErrorCodes.MISSING_REALM });
    }

    const vendor = vendorSelector(state);
    if (!vendor?.banking || !vendor?.info?.url) {
      // url is required to create a new ACH,
      // but we don't have one until the vendor is created
      return;
    }

    // just plucking ID out of the payload, since we never need it
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...banking } = vendor.banking;

    const payload = {
      ...banking,
      realm,
      vendor: vendor.info.url,
    };

    try {
      if (id && !banking.accountNumber && !banking.routingNumber) {
        return await dispatch(removeAchInfo({ id }));
      }
      return await vendorBankingAchApi.create(payload as ACHCreatePayload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
