import { fuzzySearch } from "../../utils/fuzzy-search";

import type { AttachmentAddonFile, MentionAddon } from "./types";
import styles from "./textarea.module.css";

export const getErrorMessageFromFile = (file: AttachmentAddonFile) => {
  return file.status === "error" && file.meta && typeof file.meta === "object"
    ? "message" in file.meta && typeof file.meta.message === "string"
      ? file.meta.message || ""
      : ""
    : "";
};

export const isValidAcceptType = (file: File, accept: string) => {
  const fileExtensionParts = file.name.split(".");

  const fileExtension =
    (fileExtensionParts.length > 1
      ? fileExtensionParts.pop()?.toLowerCase()
      : "") ?? "";

  const acceptTypes = accept.split(",");

  for (let type of acceptTypes) {
    type = type.trim();

    // Match all files
    if (type === "*/*") return true;

    // Match by extension
    if (type.startsWith(".") && fileExtension === type.slice(1).toLowerCase()) {
      return true;
    }

    // Match by MIME type pattern
    if (type.endsWith("/*") && file.type.startsWith(type.slice(0, -2))) {
      return true;
    }

    // Match by exact MIME type
    if (file.type === type) return true;
  }

  return false;
};

export const valueToHtml = (value: string) =>
  nl2br(value).replace(
    /\[([^[\]]+)\]\(([^)]+)\)/g,
    (_, label, url) =>
      `<span class="${styles["mention"]}" data-id="${url}" data-label="${label.slice(1)}" data-type="mention-${label.charAt(0).toLowerCase()}"></span>`
  );

export const htmlToValue = (value: string) =>
  value.replace(
    /<span[^>]*?data-type="([^"]+)"[^>]*?data-id="([^"]+)"[^>]*?data-label="([^"]+)"[^>]*?>([^<]+)<\/span>/g,
    (_, mentionName, link, label) =>
      `[${mentionName.replace("mention-", "")}${label}](${link})`
  );

export const nl2br = (value: string) => value.replace(/\n/g, "<br>");

export const br2nl = (value: string) => {
  // Replace <br> tags with newline characters
  let text = value.replace(/<br\s*\/?>/gi, "\n");

  // Replace <p> tags with newline characters
  text = text.replace(/<\/p>/gi, "\n"); // End of <p> tag
  text = text.replace(/<p[^>]*>/gi, ""); // Start of <p> tag

  // Trim leading and trailing whitespace
  return text.trim();
};

export const search = (haystack: MentionAddon["data"], needle: string) => {
  if (!needle) return haystack.slice(0, 5);

  const fuzzyInstance = fuzzySearch(haystack, {
    keys: ["label"],
    threshold: 0,
  });

  return (fuzzyInstance.search(needle) as MentionAddon["data"]).slice(0, 5);
};
