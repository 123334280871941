import type { IconProps } from "@adaptive/design-system";
import { type getPlatform } from "@adaptive/design-system/utils";

import { notificationTypeSchema } from "../api/schemas";

export const SHORTCUT: Record<
  Exclude<ReturnType<typeof getPlatform>, undefined>,
  string
> = {
  mac: "CMD + I",
  linux: "CTRL + I",
  windows: "CTRL + I",
};

export const NOTIFICATION_TAB_STORAGE_KEY = "notifications-default-tab";

export const NOTIFICATION_TYPE = notificationTypeSchema.Enum;

export const UNREAD_FILTER = {
  UNREAD_ONLY: true,
  ALL: false,
};

export const ALL_TAB_VALUE = "all";

export const MENTIONS_TAB_VALUE = "mentions";

export const STRINGS = {
  SIDEBAR_ITEM_LABEL: "Notifications",
  LIST_TITLE: "Notifications",
  LIST_SUBTITLE: "Catch up on the comments from your coworkers and vendors",
  LIST_SWITCH: "Unread",
  LIST_ALL_TAB: "All comments",
  LIST_MENTIONS_TAB: "Mentions & replies",
  LIST_MARK_ALL_AS_READ_ACTION: "Mark all as read",
  LIST_EMPTY_STATE_TITLE: "You’re all caught up!",
  LIST_EMPTY_STATE_SUBTITLE: "New notifications will show up here",
  LIST_COMMENT_MENTIONED_TITLE: "{author} mentioned you on the {reference}",
  LIST_COMMENT_MADE_TITLE: "{author} commented on the {reference}",
  LIST_SHOW_TOOLTIP: "Show notification",
  LIST_NOTIFICATION_MARKED_AS_READ_TOOLTIP: "Mark as read",
  LIST_NOTIFICATION_MARKED_AS_UNREAD_TOOLTIP: "Mark as unread",
};

export const NOTIFICATION_TITLE: Record<
  keyof typeof NOTIFICATION_TYPE,
  { icon: IconProps["name"]; template: string }
> = {
  [NOTIFICATION_TYPE.COMMENT_MADE]: {
    icon: "message",
    template: STRINGS.LIST_COMMENT_MADE_TITLE,
  },
  [NOTIFICATION_TYPE.COMMENT_MENTIONED]: {
    icon: "at",
    template: STRINGS.LIST_COMMENT_MENTIONED_TITLE,
  },
};
